import clsx from 'clsx';
import React from 'react';
import { Button } from 'common';
import { GatewayType } from './connectorHelpers';

type Props = {
  gatewayType?: GatewayType;
  handleActivate: (gatewayType?: GatewayType) => void;
  isConnected: boolean;
  isDisabled?: boolean;
  isProcessing?: boolean;
};

const IACConnectButton = ({
  isConnected,
  isDisabled,
  isProcessing,
  handleActivate,
  gatewayType,
}: Props) => {
  const gatewayRequired = 'cacheflow';

  const isButtonDisabled =
    isDisabled ||
    isProcessing ||
    (isConnected && (gatewayType === gatewayRequired || !gatewayType));

  const classes = clsx({
    'iac-connect-button': true,
    'is-disabled': isButtonDisabled,
  });

  let buttonLabel = isProcessing ? 'Loading' : 'Activate';
  if (gatewayType && gatewayType !== gatewayRequired) {
    buttonLabel = 'Reactivate';
  }

  return (
    <Button
      className={classes}
      type="secondary"
      isLoading={isProcessing}
      label={buttonLabel}
      isDisabled={isButtonDisabled}
      onClick={() => handleActivate(gatewayRequired)}
    />
  );
};

export default IACConnectButton;
