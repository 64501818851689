import { useQueryClient } from '@tanstack/react-query';
import {
  AssistantConfig,
  Button,
  Drawer,
  FormField,
  Loading,
  OrgConfig,
  TextArea,
  useToast,
} from 'common';
import React, { useEffect } from 'react';
import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../../services/orgDefaults';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  generate: () => void;
}

export const CoverLetterPrompt = ({ isOpen, onClose, generate }: Props) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { data, isLoading, isError, error } =
    useFindOrgDefault('assistantConfig');

  const [draft, setDraft] = React.useState<AssistantConfig | undefined>(
    undefined
  );

  const { mutate: saveAssistantConfig } = useSetOrgConfigDefault(
    'assistantConfig',
    () => {
      toast.success('Cover letter prompt saved.');
    },
    () => {
      toast.error('Error saving cover letter prompt.');
    },
    queryClient
  );

  const save = () => {
    saveAssistantConfig({
      configKey: 'assistantConfig',
      configValue: draft,
    } as OrgConfig);
    onClose();
  };

  const saveAndRegenerate = () => {
    save();
    generate();
  };

  useEffect(() => {
    if (data) {
      setDraft(data.configValue as AssistantConfig);
    }
  }, [data]);

  const getContent = () => {
    if (isLoading || !data) return <Loading />;
    if (isError || error) return <div>Error loading config</div>;
    return (
      <>
        <FormField label="Prompt">
          <TextArea
            id="prompt"
            autoSize={{ minRows: 20 }}
            onChange={(value) =>
              setDraft({ ...draft, coverLetterPrompt: value })
            }
            value={draft?.coverLetterPrompt}
          />
        </FormField>
        <Button className="w-[100%] mt-[14px]" label="Save" onClick={save} />
        <Button
          className="w-[100%] mt-[14px]"
          label="Save & Regenerate"
          onClick={saveAndRegenerate}
        />
      </>
    );
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header="Default cover letter prompt"
    >
      {getContent()}
    </Drawer>
  );
};
