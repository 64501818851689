import React from 'react';
import {
  formatDateOrDefault,
  InfoToolTip,
  useCurrentProposal,
  useRowItem,
  useTranslation,
} from 'common';
import { isFuture, parseISO } from 'date-fns';
import styles from './OneTimeProductRemovalTooltip.module.scss';

export const OneTimeProductRemovalTooltip = () => {
  const { tk } = useTranslation();

  const item = useRowItem();
  const proposal = useCurrentProposal();

  return (
    <div className={styles.icon}>
      <InfoToolTip
        title={tk(
          `One-time products cannot be removed or have quantities reduced. ${
            item.startDate && isFuture(parseISO(item.startDate))
              ? `This item is scheduled for invoicing on ${formatDateOrDefault(
                  item.startDate
                )}`
              : `Invoiced on: ${formatDateOrDefault(proposal.effectiveStartDate)}`
          }`
        )}
      />
    </div>
  );
};
