import React from 'react';

import styles from './EmptyTaskInbox.module.scss';

export const EmptyTaskInbox = () => {
  return (
    <div className={styles.taskItemEmpty}>
      <div className={styles.taskItemEmptyMessage}>
        You have no approval requests
      </div>
    </div>
  );
};
