export enum ConnectorTabs {
  accountContacts = 'account-contacts',
  closeConfiguration = 'closeConfiguration',
  configuration = 'configuration',
  deals = 'deals',
  events = 'events',
  locationCode = 'locationCode',
  opportunities = 'opportunities',
  proposalEquivalent = 'proposal-equivalent',
  proposalFieldMappings = 'proposalFieldMappings',
  proposalStageMappings = 'proposalStageMappings',
  proposalDealSettigs = 'proposalDealSettigs',
  reconciliation = 'reconciliation',
  salesforceConfiguration = 'salesforceConfiguration',
  status = 'status',
}
