import { useQueryClient } from '@tanstack/react-query';
import {
  arrayTop,
  Button,
  ButtonBar,
  Card,
  Config,
  ConfirmModal,
  ConnectorTestResult,
  ExternalFieldMapping,
  Flags,
  ForceUser,
  formatDateOrDefault,
  formatTimeAgo,
  formatTimeOrDefault,
  getErrorMessage,
  HubspotConfig,
  Icon,
  Modal,
  NavigationIcon,
  OrgConfig,
  PopOutMenu,
  PopOutMenuOption,
  SalesforceConfig,
  spreadIf,
  StageMapping,
  Tabs,
  ToolTip,
  useFlags,
  useToast,
  useTranslation,
} from 'common';
import React, { SVGProps, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENVIRONMENT_CONFIG } from '../../../../config/hosts';
import { useAuth } from '../../../Auth';
import { ConnectionStatus, SandboxLabel } from '../../../components';

import { ConnectorTabs } from '../../../core-utils/enums/ConnectorTabs';
import { useIsSuperAdmin } from '../../../core-utils/helperFunctions/userServiceHelper';
import { INTEGRATIONS_AND_CONNECTORS } from '../../../core-utils/routes';
import {
  ConnectResult,
  disableConnectorConfig,
  useRefreshAuthConnector,
} from '../../../services/connectors';
import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';
import {
  AccountContactsConfig,
  ConnectorConfiguration,
  ConnectorInfoObject,
} from './ConnectorConstants/constants';
import ConnectorError from './ConnectorError';
import ConnectorStatus from './ConnectorStatus';

import AccountContacts from './ConnectorTabs/AccountContacts';
import ConnectorConfigurationEditor from './ConnectorTabs/ConnectorConfigurationEditor';
import ConnectorEvents from './ConnectorTabs/ConnectorEvents';
import ConnectorProposalEquivalent from './ConnectorTabs/ConnectorProposalEquivalent';
import ConnectorReconciliation from './ConnectorTabs/ConnectorReconciliation';
import './connectorStyles.css';
import LogoCardHeader from './LogoCardHeader';
import ProposalFieldMappings from './ProposalFieldMappings';
import CloseForm from '../Close/CloseForm';
import ConnectorLocation from './ConnectorLocation';
import { Alert } from 'antd';
import { ConnectionState, useConnection } from './ConnectionProvider';
import ConnectorStageMappigs from './ConnectorTabs/ConnectorStageMappings';
import SalesforceDetails from '../Salesforce/SalesforceDetails';
import SalesforceConnectionExtras from '../Salesforce/SalesforceConnectionExtras';

export interface ConnectorTab {
  flag?: keyof Flags;
  key: string;
  label: string;
  systemOnly: boolean;
}

interface Props {
  accountContactsConfig: AccountContactsConfig;
  configuration?: ConnectorConfiguration;
  connectorInfo: ConnectorInfoObject;
  logo?: React.ComponentType<SVGProps<SVGSVGElement>>;
  LogoElement?: React.ReactElement;
  tabs: ConnectorTab[];
  isAvalaraSaveButtonDisabled?: boolean;
}

export interface ConnectorPipelineType {
  initial: string;
  renewal: string;
  change: string;
  template: string;
}

const ConnectorSettings = ({
  connectorInfo,
  configuration,
  accountContactsConfig,
  tabs,
  logo,
  LogoElement,
  isAvalaraSaveButtonDisabled = false,
}: Props) => {
  const navigate = useNavigate();
  const flags = useFlags();
  const [activeTab, setActiveTab] = useState<string>(
    arrayTop(tabs)?.key ?? ConnectorTabs.configuration
  );
  const [savableChanges, setSavableChanges] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(
    isAvalaraSaveButtonDisabled
  );
  const [enabled, setEnabled] = useState(false);
  const [syncSubscriptionsDraft, setSyncSubscriptionsDraft] = useState(false);
  const [syncSignedDocumentDraft, setSyncSignedDocumentDraft] = useState(false);
  const [connectorStageMappings, setConnectorStageMappings] = useState<
    StageMapping[]
  >([]);
  const [proposalFieldMappings, setProposalFieldMappings] = useState<
    ExternalFieldMapping[]
  >([]);
  const [isConfimModalOpen, setIsConfimModalOpen] = useState<boolean>(false);
  const [connectorPipeline, setConnectorPipeline] =
    useState<ConnectorPipelineType>({
      initial: '',
      renewal: '',
      change: '',
      template: '',
    });
  const [isReconnecting, setIsReconnecting] = useState<boolean>(false);
  const [isConnectionDetailsModelOpen, setIsConnectionDetailsModelOpen] =
    useState<boolean>(false);

  const [connectorConfigValue, setConnectorConfigValue] = useState<
    OrgConfig['configValue']
  >({});
  const [isConfigLoading, setIsConfigLoading] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<string | undefined>(undefined);
  const showToast = useToast();
  const queryClient = useQueryClient();
  const { tk } = useTranslation();
  const {
    data: connectorData,
    isError,
    error,
  } = useFindOrgDefault(connectorInfo.configType);

  const onClose = (connectResult?: ConnectResult) => {
    if (connectResult === 'disconnected') {
      navigate(INTEGRATIONS_AND_CONNECTORS);
    }
  };

  const onError = (e: unknown) => {
    showToast.error(getErrorMessage(e) ?? tk('There was an error'));
  };

  const auth = useAuth();

  const handleReconnect = async () => {
    auth.user?.id &&
      (await disableConnectorConfig(
        connectorInfo.source,
        auth.user.id,
        onClose,
        onError,
        queryClient
      ));
    setIsReconnecting(false);
  };

  const connection = useConnection();

  const getConnectorInfo = useCallback(() => {
    if (
      !connection ||
      !connection[
        (connectorInfo.source + 'Connection') as keyof typeof connection
      ]
    ) {
      return null;
    }
    return connection[
      (connectorInfo.source + 'Connection') as keyof typeof connection
    ] as ConnectionState;
  }, [connectorInfo.source, connection]);

  const { mutate: refreshAuth } = useRefreshAuthConnector(
    connectorInfo.source,
    () => {
      showToast.success(tk('Token refreshed'));
    },
    () => {
      showToast.error(tk('Failed to authenticate. Please try again!'));
    }
  );

  useEffect(() => {
    if (connectorData?.configKey === 'hubspotConfig') {
      const hsConfigValue: HubspotConfig =
        connectorData.configValue as HubspotConfig;

      if (
        hsConfigValue.proposalFieldMappings &&
        hsConfigValue.proposalFieldMappings.length > 0
      ) {
        setProposalFieldMappings(hsConfigValue.proposalFieldMappings);
      }
    }
  }, [connectorData?.configValue]);

  const showSystemTab = useIsSuperAdmin() || !ENVIRONMENT_CONFIG.isProduction;

  useEffect(() => {
    if (connectorData) {
      const configValue: OrgConfig['configValue'] = connectorData.configValue;
      // Previously set, but now removed mappings should be excluded
      // TODO: remove the key check once the hubspot connector is g2g
      const stageMappings =
        configValue && 'stageMappings' in configValue
          ? configValue.stageMappings?.filter(
              (mapping: StageMapping) => !!mapping.toStage
            )
          : [];

      setConnectorStageMappings(stageMappings || []);

      let enabledConf = enabled;

      if (Object.getOwnPropertyDescriptor(configValue, 'enabled')) {
        enabledConf = !!(
          configValue &&
          'enabled' in configValue &&
          configValue.enabled
        );
        setEnabled(enabledConf);
      }
      if (enabledConf && getConnectorInfo()?.connectionStatus) {
        enabledConf = !!getConnectorInfo()?.isConnected;
        setEnabled(enabledConf);
      }
      if (!enabledConf) {
        currentTab
          ? setActiveTab(currentTab)
          : setActiveTab(
              connectorInfo.configType === 'closeConfig'
                ? ConnectorTabs.closeConfiguration
                : connectorInfo.configType === 'salesforceConfig'
                  ? ConnectorTabs.salesforceConfiguration
                  : ConnectorTabs.configuration
            );
      }

      // @ts-ignore TODO: remove the ts-ignore once syncSubscriptions is not just on Salesforce
      setSyncSubscriptionsDraft(configValue?.syncSubscriptions || false);

      // @ts-ignore TODO: remove the ts-ignore once syncSignedDocument is not just on Salesforce
      setSyncSignedDocumentDraft(configValue?.syncSignedDocument || false);

      setConnectorConfigValue(configValue);
      setIsConfigLoading(false);
    }

    if (connectorData === null) {
      setIsConfigLoading(false);
    }
  }, [connectorData, getConnectorInfo]);

  const getTabContent = () => {
    switch (activeTab) {
      case ConnectorTabs.configuration:
        return (
          <ConnectorConfigurationEditor
            configuration={configuration!}
            connectorConfigValue={connectorConfigValue}
            connectorInfo={connectorInfo}
            setConnectorConfigValue={setConnectorConfigValue}
            handleDisableStatus={handleDisableStatus}
            setSaveableChanges={setSavableChanges}
          />
        );
      case ConnectorTabs.proposalFieldMappings:
        return (
          <ProposalFieldMappings
            connectorInfo={connectorInfo}
            fieldMappings={proposalFieldMappings}
            setFieldMappings={setProposalFieldMappings}
            setSaveableChanges={setSavableChanges}
          />
        );
      case ConnectorTabs.opportunities:
      case ConnectorTabs.proposalDealSettigs:
        return (
          <ConnectorProposalEquivalent
            configuration={configuration}
            connectorConfigValue={connectorConfigValue as SalesforceConfig}
            connectorInfo={connectorInfo}
            setConnectorConfigValue={setConnectorConfigValue}
            setSaveableChanges={setSavableChanges}
          />
        );
      case ConnectorTabs.proposalStageMappings:
        return (
          <ConnectorStageMappigs
            setNewConnectorMapping={setConnectorStageMappings}
            newConnectorMapping={connectorStageMappings}
            setSaveableChanges={setSavableChanges}
            connectorInfo={connectorInfo}
            setConnectorPipeline={setConnectorPipeline}
            connectorPipeline={connectorPipeline}
          />
        );
      case ConnectorTabs.accountContacts:
        return (
          <AccountContacts
            accountContactsConfig={accountContactsConfig}
            connectorConfigValue={connectorConfigValue}
            setConnectorConfigValue={setConnectorConfigValue}
            setSavableChanges={setSavableChanges}
          />
        );
      case ConnectorTabs.status:
        return <ConnectorStatus connectorName={connectorInfo.configType} />;
      case ConnectorTabs.events:
        return <ConnectorEvents connectorName={connectorInfo.source} />;
      case ConnectorTabs.locationCode:
        return (
          <ConnectorLocation
            connectorConfigValue={connectorConfigValue}
            setConnectorConfigValue={setConnectorConfigValue}
            setSavableChanges={setSavableChanges}
          />
        );
      case ConnectorTabs.reconciliation:
        return <ConnectorReconciliation connectorName={connectorInfo.source} />;
      case ConnectorTabs.salesforceConfiguration:
        return (
          <SalesforceConnectionExtras
            connectorConfigValue={connectorConfigValue}
            setSavableChanges={setSavableChanges}
            setConnectorConfigValue={setConnectorConfigValue}
            syncSubscriptions={
              // @ts-ignore TODO: remove the ts-ignore once the syncSubscriptions is universal
              connectorData?.configValue?.syncSubscriptions || false
            }
            syncSubscriptionsDraft={syncSubscriptionsDraft}
            setSyncSubscriptionsDraft={setSyncSubscriptionsDraft}
            syncSignedDocumentDraft={syncSignedDocumentDraft}
            syncSignedDocument={
              // @ts-ignore TODO: remove the ts-ignore once the syncSignedDocument is universal
              connectorData?.configValue?.syncSignedDocument || false
            }
            setSyncSignedDocumentDraft={setSyncSignedDocumentDraft}
            salesforceUser={
              (
                getConnectorInfo()?.connectionStatus as ConnectorTestResult &
                  Record<'salesforceUser', ForceUser>
              )?.salesforceUser
            }
          />
        );
      case ConnectorTabs.closeConfiguration:
        return (
          <CloseForm
            connectorConfigValue={connectorConfigValue}
            isLoading={isConfigLoading}
            setConnectorConfigValue={setConnectorConfigValue}
            setSaveableChanges={setSavableChanges}
          />
        );
      default:
        return null;
    }
  };

  const { mutate: updateConnectorConfig } = useSetOrgConfigDefault(
    connectorInfo.configType,
    () => {
      showToast.info(tk('Connector settings saved.'));
    },
    (err: unknown) => {
      const errorMessage = getErrorMessage(err);
      showToast.error(
        connectorInfo.configType === 'avalaraConfig'
          ? 'Invalid credentials! We are not able to authenticate the account in Avalara. Please enter valid credentials.'
          : tk(
              errorMessage ??
                'Failed to save Connector settings. Please try again.'
            )
      );
    },
    queryClient
  );

  const isValidPipelines = () => {
    return (
      Object.keys(connectorPipeline) as Array<'initial' | 'change' | 'renewal'>
    ).every((key) => {
      const hasPipeline =
        !!connectorPipeline[key] && connectorPipeline[key] !== '';
      const noStageMappings =
        connectorStageMappings.filter((mapping) => mapping.proposalType === key)
          .length === 0;
      return !hasPipeline || !noStageMappings;
    });
  };

  const saveConnectorConfig = () => {
    const configValueObj: OrgConfig['configValue'] = {
      configType: connectorInfo.name as Config['configType'],
      ...connectorConfigValue,
    };

    updateConnectorConfig({ configValue: configValueObj });
    setSavableChanges(false);
    setSaveButtonDisabled(true);
  };

  const saveMapping = () => {
    const mapping: OrgConfig['configValue'] = {
      ...connectorConfigValue,
      configType: connectorInfo.configType as Config['configType'],
      enabled: configuration?.toggleSwitchForEnablement ? enabled : true,
      syncSubscriptions: syncSubscriptionsDraft,
      proposalFieldMappings: [...proposalFieldMappings],
      stageMappings: [...connectorStageMappings],
      ...spreadIf(connectorInfo.source === 'salesforce', {
        syncSignedDocument: syncSignedDocumentDraft,
      }),
    };

    updateConnectorConfig({ configValue: mapping });
    setSavableChanges(false);
  };

  const handleDisableStatus = () => {
    setSaveButtonDisabled(false);
  };
  const getPipelinesWithNoStages = () => {
    return Object.keys(connectorPipeline).filter((key) => {
      return (
        !!connectorPipeline[key as 'initial' | 'renewal' | 'change'] &&
        connectorStageMappings.filter((mapping) => {
          return (
            mapping.pipeline ===
              connectorPipeline[key as 'initial' | 'renewal' | 'change'] &&
            mapping.proposalType === key
          );
        }).length === 0
      );
    });
  };

  const handleConnectorReconnect = async () => {
    setIsReconnecting(true);
    if (
      getConnectorInfo()?.connectionStatus?.errorCode &&
      getConnectorInfo()?.connectionStatus?.errorCode ===
        'QUICKBOOKS_CUSTOM_TXN_NUMBERS_DISABLED'
    ) {
      await getConnectorInfo()
        ?.refetch()
        .then((res) => {
          if (
            res.data?.errorCode === 'QUICKBOOKS_CUSTOM_TXN_NUMBERS_DISABLED'
          ) {
            showToast.error(
              'Failed to enable integration. Please enable “Custom Transaction Numbers” in QuickBooks and try again.'
            );
          } else if (
            res.data?.status === 'active' &&
            res.data.issues?.length === 0
          ) {
            showToast.success('Integrations enabled successfully');
          } else if (
            (res.data?.issues?.length ?? 0) > 0 &&
            !!res.data?.message
          ) {
            showToast.error(
              `Failed to enable integration - ${res.data.message}`
            );
          }
        })
        .finally(() => {
          setIsReconnecting(false);
        });
    } else {
      await handleReconnect();
    }
  };

  const getLastSyncedTooltip = () => {
    return (
      <div className="flex flex-col w-full font-bold">
        <div className="w-full flex justify-center">Last synced at</div>
        <div className="w-full flex justify-between gap-2">
          <div>
            {formatDateOrDefault(
              getConnectorInfo()?.connectionStatus?.lastConnectedAt
            )}
          </div>{' '}
          -{' '}
          <div>
            {formatTimeOrDefault(
              getConnectorInfo()?.connectionStatus?.lastConnectedAt
            )}
          </div>
        </div>
      </div>
    );
  };

  const shouldShowPopoutMenuSettings =
    connectorInfo.source === 'quickbooks' ||
    connectorInfo.source === 'avalara' ||
    connectorInfo.source === 'salesforce' ||
    connectorInfo.source === 'hubspot';

  const getBannerMessage = () => {
    return (
      getConnectorInfo()?.error?.message ||
      getConnectorInfo()?.connectionStatus?.message ||
      'Some error occurred while connecting'
    );
  };

  return (
    <>
      {isError && <ConnectorError error={error || undefined} />}
      <ConfirmModal
        isOpen={isConfimModalOpen}
        onConfirm={() => {
          getPipelinesWithNoStages().forEach((proposalType) => {
            setConnectorPipeline((prev) => ({
              ...prev,
              [proposalType]: '',
            }));
          });
          saveMapping();
          setIsConfimModalOpen(false);
        }}
        confirmText="Save without mapping"
        cancelText="Cancel"
        onClose={() => {
          setIsConfimModalOpen(false);
        }}
      >
        Selected pipeline will be ignored in Stage mapping configuration as none
        of the proposal status is mapped to the deal stages in Hubspot
      </ConfirmModal>

      <Modal
        onClose={() => setIsConnectionDetailsModelOpen(false)}
        header={tk('View connection details')}
        isOpen={isConnectionDetailsModelOpen}
      >
        <SalesforceDetails
          salesforceUser={
            (
              getConnectorInfo()?.connectionStatus as ConnectorTestResult &
                Record<'salesforceUser', ForceUser>
            )?.salesforceUser
          }
          connectorConfigValue={connectorConfigValue}
        />
      </Modal>
      <Card>
        <LogoCardHeader
          LogoElement={LogoElement}
          logo={logo}
          name={connectorInfo.name}
          connectionComponent={
            !!getConnectorInfo() && (
              <ConnectionStatus
                isConnected={!!getConnectorInfo()?.isConnected}
                message={
                  !getConnectorInfo()?.isConnected
                    ? 'Not connected'
                    : 'Connected'
                }
              />
            )
          }
          connectionDetailsComponent={
            getConnectorInfo()?.connectionStatus?.lastConnectedAt && (
              <ToolTip placement="top" title={getLastSyncedTooltip()}>
                <p className="synced-text">
                  Last synced{' '}
                  {formatTimeAgo(
                    getConnectorInfo()?.connectionStatus?.lastConnectedAt ?? ''
                  )}{' '}
                </p>
              </ToolTip>
            )
          }
          isLoading={isConfigLoading || getConnectorInfo()?.isLoading}
        >
          <ButtonBar>
            {shouldShowPopoutMenuSettings && (
              <PopOutMenu>
                <PopOutMenuOption
                  icon={Icon.Repeat}
                  onClick={refreshAuth}
                  title={tk('Refresh Token')}
                />

                {connectorInfo.source === 'salesforce' &&
                  (
                    getConnectorInfo()
                      ?.connectionStatus as ConnectorTestResult &
                      Record<'salesforceUser', ForceUser>
                  )?.salesforceUser && (
                    <PopOutMenuOption
                      icon={Icon.Link}
                      onClick={() => setIsConnectionDetailsModelOpen(true)}
                      title={tk('Connection details')}
                    />
                  )}

                <PopOutMenuOption
                  icon={NavigationIcon.Account}
                  onClick={async () => {
                    if (auth.user === undefined || auth.user.id === undefined) {
                      return;
                    }

                    await disableConnectorConfig(
                      connectorInfo.source,
                      auth.user.id,
                      onClose,
                      onError,
                      queryClient
                    );
                  }}
                  title={tk('Disconnect')}
                />
              </PopOutMenu>
            )}
            {connectorInfo.showSaveButton && (
              <Button
                isDisabled={!savableChanges || saveButtonDisabled}
                label={tk('Save')}
                onClick={() => {
                  if (!isValidPipelines()) {
                    setIsConfimModalOpen(true);
                    setSavableChanges(false);
                  } else {
                    activeTab === 'configuration' ||
                    activeTab === 'closeConfiguration'
                      ? saveConnectorConfig()
                      : saveMapping();
                  }
                }}
              />
            )}
          </ButtonBar>
        </LogoCardHeader>

        {!!getConnectorInfo() &&
          !getConnectorInfo()?.isConnected &&
          !(isConfigLoading || getConnectorInfo()?.isLoading) &&
          getConnectorInfo()?.isEnabled && (
            <Alert
              banner
              closable
              message={getBannerMessage()}
              type="error"
              action={
                <Button
                  label="Reconnect"
                  isLoading={isReconnecting}
                  onClick={handleConnectorReconnect}
                  className="px-10 mx-2"
                />
              }
            />
          )}

        {ENVIRONMENT_CONFIG.env === 'sandbox' ||
          (!ENVIRONMENT_CONFIG.isProduction && (
            <SandboxLabel
              name={tk(
                'Connecting account should be in a test or sandbox mode'
              )}
            />
          ))}
        <Tabs
          defaultActiveTab={activeTab}
          onChange={(tab) => {
            setActiveTab(tab);
            setCurrentTab(tab);
          }}
          tabs={tabs.filter(
            (tab) =>
              (!tab.systemOnly && (!tab.flag || flags[tab.flag])) ||
              showSystemTab
          )}
        />
        <section className="connector-tab-content">{getTabContent()}</section>
        {/* TODO: Implement using a solution here: https://github.com/remix-run/react-router/issues/8139 */}
        {/*<Prompt*/}
        {/*  when={saveableChanges}*/}
        {/*  message={'Are you sure you want to leave the page before saving?'}*/}
        {/*/>*/}
      </Card>
    </>
  );
};

export default ConnectorSettings;
