import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { getDetailLink } from 'app/src/services/routes/routeUtils';
import {
  Accordion,
  BillingItem,
  BillingSchedule,
  Cell,
  Contract,
  formatCurrencyOrDefault,
  formatDateOrDefault,
  getIconCell,
  Icon,
  NavigationIcon,
  pushIf,
  SimpleCell,
  SimpleTable,
  ToolTip,
  TruncateOption,
  truncateText,
  Type,
  useFlags,
} from 'common';
import React from 'react';
import { Link } from 'react-router-dom';
import { GracePeriodTooltip } from './GracePeriodTooltip/GracePeriodTooltip';

import styles from './PaymentItemAccordion.module.scss';

export type ScheduleAndBillingItem = {
  schedule: BillingSchedule;
  billingItem: BillingItem;
};
interface Props {
  scheduleAndBillingItems: ScheduleAndBillingItem[][];
  popoutMenuOptions: (billingSchedule: BillingSchedule) => React.ReactNode;
  contract: Contract;
}

const PaymentItemAccordion: React.FC<Props> = ({
  scheduleAndBillingItems,
  popoutMenuOptions,
  contract,
}) => {
  const { invoices } = useFlags();
  const isUpdateAllowed = isActionAllowed(FLAG_TYPES.TRANSFER, 'update');

  const billingCells: SimpleCell<ScheduleAndBillingItem>[] = [
    ...pushIf<Cell<ScheduleAndBillingItem>>(invoices, [
      getIconCell(null, {
        key: 'invoiceId',
        width: '5%',
        renderCell: (invoice: ScheduleAndBillingItem) => {
          if (invoice.schedule.externalInvoiced) {
            return (
              <ToolTip title="Invoiced outside of Cacheflow">
                <NavigationIcon.Agreements className="text-slate-lighter" />
              </ToolTip>
            );
          }
          return invoice.schedule.invoiceId ? (
            <Link
              className="text-blue underline"
              to={getDetailLink('invoice', invoice.schedule.invoiceId)!}
            >
              <NavigationIcon.Agreements />
            </Link>
          ) : null;
        },
      }),
    ]),
    {
      renderCell: (data: ScheduleAndBillingItem) => (
        <>
          {truncateText(
            data.billingItem.descriptionText || '',
            25,
            TruncateOption.WithDots
          )}
        </>
      ),
      key: 'description',
      headerLabel: 'Description',
      sortable: true,
      width: '15%',
    },
    {
      renderCell: (data: ScheduleAndBillingItem) => (
        <span>
          {`${formatDateOrDefault(data.billingItem.serviceStartDate)} - ${formatDateOrDefault(data.billingItem.serviceEndDate)}`}
        </span>
      ),
      key: 'scheduleDate',
      headerLabel: 'Service period',
      sortable: true,
    },
    {
      key: 'scheduleDate',
      headerLabel: 'Schedule date',
      renderCell: (data: ScheduleAndBillingItem) => {
        return (
          <div className="flex items-center">
            {formatDateOrDefault(data.schedule.scheduleDate)}
            <GracePeriodTooltip
              contract={contract}
              scheduleDate={data.schedule.scheduleDate}
            />
          </div>
        );
      },
    },
    {
      key: 'unitPrice',
      headerLabel: 'Unit price',
      renderCell: (data: ScheduleAndBillingItem) => {
        const amount = data.billingItem.amountWithoutTax;
        const quantity = data.billingItem.quantity ?? 1;
        return formatCurrencyOrDefault('USD', amount / quantity);
      },
    },
    {
      key: 'quantity',
      headerLabel: 'Quantity',
      renderCell: (data: ScheduleAndBillingItem) => (
        <div className="flex justify-center">{data.billingItem.quantity}</div>
      ),
    },
    {
      key: 'amount',
      headerLabel: 'Amount',
      renderCell: (data: ScheduleAndBillingItem) =>
        data.billingItem.amountWithoutTaxFormatted,
    },
    {
      key: 'amountPaid',
      headerLabel: 'Paid',
      renderCell: (data: ScheduleAndBillingItem) => {
        const { invoiceId } = data.schedule;
        return data.schedule.invoiceStatus === 'paid' && invoiceId ? (
          <Link
            className="text-green underline"
            to={getDetailLink('invoice', invoiceId)!}
          >
            <Icon.Checkmark />
          </Link>
        ) : null;
      },
    },
    ...pushIf(isUpdateAllowed, {
      key: 'options',
      headerLabel: 'Options',
      width: 64,
      clickable: true,
      renderCell: (data: ScheduleAndBillingItem) =>
        popoutMenuOptions(data.schedule),
    }),
  ];
  return (
    <>
      {scheduleAndBillingItems.map((scheduleOfBillingItem) => (
        <div
          className={styles.paymentItemAccordionContainer}
          key={scheduleOfBillingItem[0]?.billingItem.itemId}
        >
          <Accordion
            defaultExpanded
            expandIconPosition="start"
            header={
              <div className={styles.paymentItemAccordionHeader}>
                <Type bold>{scheduleOfBillingItem[0]?.billingItem.name}</Type>
              </div>
            }
          >
            <div>
              <SimpleTable<ScheduleAndBillingItem>
                cells={billingCells}
                disableSearch
                minWidth="900px"
                rows={scheduleOfBillingItem}
                zeroStateMessage="There are no billing records to display."
              />
            </div>
          </Accordion>
        </div>
      ))}
    </>
  );
};

export default PaymentItemAccordion;
