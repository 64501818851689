import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getIconCell,
  Icon,
  IconWithTooltip,
  NavigationIcon,
  PopOutMenu,
  PopOutMenuOption,
  pushIf,
  QueryTable,
  Refund,
  SelectOption,
} from 'common';

import ExternalLink from '../../../components/Sync/ExternalLink';
import { Page } from 'app/src/components';
import IDField from 'app/src/components/IDField';
import StatusLabel from 'app/src/components/StatusLabel';
import DateTimeCell from 'app/src/components/DateTimeCell';
import { apiClient } from 'app/src/services/httpClients/app';
import { usePaymentMethods } from 'app/src/services/paymentMethods';
import { ActivityDrawer } from 'app/src/components/ActivityDrawer/ActivityDrawer';
import { useActivityDrawer } from 'app/src/components/ActivityDrawer/useActivityDrawer';

import { refundStatusOptions } from '../refundUtils';
import { usePostRefundSync } from 'app/src/services/transfer';
import { useIsConnectorEnabled } from 'app/src/services/connectors';

export const RefundsPage = () => {
  const navigate = useNavigate();
  const [selectedRefund, setSelectedRefund] = useState<Refund | null>(null);
  const isQuickBooksEnabled = useIsConnectorEnabled('quickbooksConfig');
  const { mutate: syncRefund, isPending: isSyncPending } = usePostRefundSync();

  const {
    activity,
    isActivityDrawerOpen,
    openActivityDrawer,
    closeActivityDrawer,
  } = useActivityDrawer('refund', selectedRefund?.id ?? '');

  const { data: paymentMethods } = usePaymentMethods();
  const paymentTypeOptions: SelectOption<string>[] = useMemo(() => {
    if (!paymentMethods) return [];
    const seenMethods = new Set<string>();
    const paymentMethodOptions: SelectOption<string>[] = [];
    for (const method of paymentMethods) {
      if (method.paymentType && !seenMethods.has(method.paymentType)) {
        seenMethods.add(method.paymentType);
        paymentMethodOptions.push({
          value: String(method.paymentType),
          name: String(method.name),
        });
      }
    }
    return paymentMethodOptions;
  }, [paymentMethods]);

  const cells = [
    getIconCell(null, {
      renderCell: () => (
        <IconWithTooltip icon={NavigationIcon.Refund} tooltipText="Refund" />
      ),
    }),
    {
      key: 'id',
      sortKey: 'id',
      sortable: true,
      headerLabel: 'Refund Id',
      renderCell: (refund: Refund) => {
        return (
          <IDField documentID={refund.id}>
            <div>{refund.id.substring(0, 8)}</div>
          </IDField>
        );
      },
    },
    {
      key: 'name',
      sortKey: 'customer.name',
      sortable: true,
      headerLabel: 'Customer',
      renderCell: (refund: Refund) => {
        return refund.customer?.name;
      },
    },
    {
      key: 'paymentMethod',
      sortKey: 'transfer.paymentMethod.name',
      sortable: true,
      headerLabel: 'Payment Method',
      renderCell: (refund: Refund) => {
        return refund.transfer.paymentMethod?.name;
      },
    },
    {
      key: 'processDate',
      sortKey: 'processDate',
      sortable: true,
      headerLabel: 'Refund date',
      renderCell: (refund: Refund) => {
        return <DateTimeCell date={refund.processDate} showTime={false} />;
      },
    },
    {
      key: 'amount',
      sortable: true,
      headerLabel: 'Amount',
      renderCell: (refund: Refund) => {
        return `${refund.amountFormatted} ${refund.transfer.currency}`;
      },
    },
    {
      key: 'status',
      headerLabel: 'Status',
      sortable: true,
      renderCell: (refund: Refund) => {
        return <StatusLabel feature="refund" status={refund.status} />;
      },
    },
    ...pushIf(!!isQuickBooksEnabled, {
      key: 'syncStatus',
      headerLabel: 'Sync status',
      width: '75px',
      renderCell: (refund: Refund) => (
        <div className="flex justify-start">
          {' '}
          {refund.sources?.map((s) => (
            <ExternalLink key={s.sourceId} externalSource={s} />
          ))}
        </div>
      ),
    }),
    {
      key: 'options',
      headerLabel: 'Option',
      width: 64,
      clickable: true,
      renderCell: (refund: Refund) => (
        <PopOutMenu>
          <PopOutMenuOption
            icon={Icon.Activity}
            onClick={() => {
              setSelectedRefund(refund);
              openActivityDrawer();
            }}
            title="Refund activity"
          />
          <PopOutMenuOption
            icon={Icon.Repeat}
            isDisabled={isSyncPending}
            onClick={() => {
              syncRefund(refund.id);
            }}
            title="Sync refund to ERP"
          />
        </PopOutMenu>
      ),
    },
  ];

  const handleTableRowClick = (clickedRefund: Refund) => {
    navigate(`/refunds/${clickedRefund.id}`);
  };

  return (
    <Page leftWidget="Refunds">
      <QueryTable<Refund>
        cells={cells}
        fetchList={apiClient.listRefunds}
        filters={[
          {
            type: 'search',
            key: 'search',
            searchFields: ['customer.name'],
          },
          {
            type: 'dropdown',
            key: 'status',
            humanReadableName: 'Status',
            fieldName: 'status',
            options: refundStatusOptions(),
          },
          {
            type: 'valueRadio',
            key: 'amount',
            humanReadableName: 'Amount',
            fieldName: 'amount',
            prefix: '$',
          },
          {
            type: 'dropdown',
            key: 'transfer.paymentMethod.paymentType',
            humanReadableName: 'Payment method',
            fieldName: 'transfer.paymentMethod.paymentType',
            options: paymentTypeOptions,
          },
          {
            type: 'dateRadio',
            key: 'processDate',
            humanReadableName: 'Refund date',
            fieldName: 'processDate',
            optionsType: 'futureAndPast',
          },
        ]}
        minWidth="900px"
        onRowClick={handleTableRowClick}
        placeholder="Customer name"
        queryKey="apiClient.listRefunds"
        zeroStateMessage="There are no refund records to display."
      />

      <ActivityDrawer
        activityEvents={activity}
        isOpen={isActivityDrawerOpen}
        onClose={closeActivityDrawer}
        resourceType="refund"
      />
    </Page>
  );
};
