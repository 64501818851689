import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../../core-utils/routes';
import { authOrgPost } from '../../../services/api/system/organizations';
import { useAuthState } from '../../../Auth';
import {
  Button,
  ConfirmModal,
  FormField,
  Input,
  log,
  Select,
  useFormValidation,
} from 'common';

interface Props {
  dataTestId?: string;
  orgId: string;
}

const LoginAsButton: React.FC<Props> = ({ orgId, dataTestId }: Props) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuthState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [reason, setReason] = useState<string | undefined>();
  const [reasonCode, setReasonCode] = useState<string | undefined>();

  const { getErrorToShow, setHasVisitedField, isFormValid } =
    useFormValidation<any>(
      [
        {
          fieldName: 'reason',
          isRequired: true,
          humanReadableName: 'Reason',
        },
      ],
      { reason }
    );

  const loginAs = async () => {
    setShowConfirm(false);
    await authOrgPost(orgId, reason).then((res) => {
      log.info(`getting token for org ${orgId}`);
      if (res.token) {
        log.debug(res.token);
        setAuth({
          ...auth,
          token: res.token,
          reason,
          isAuthenticated: true,
        });
        navigate(`${LOGIN_ROUTE}?token=${res.token}`);
      }
    });
  };

  const reasons = ['Support', 'Audit', 'Configuration', 'Other'];

  const reasonOptions = () => {
    return reasons.map((option) => {
      return {
        name: option,
        value: option.toLowerCase(),
      };
    });
  };

  const handleReasonChange = (value: unknown) => {
    setReasonCode(value as string);
    if (value !== 'other') {
      setReason(value as string);
    }
  };

  return (
    <>
      <Button
        dataTestId={`login-${dataTestId}`}
        label="Login"
        onClick={() => setShowConfirm(true)}
        type="link"
      />
      <ConfirmModal
        header="Login as"
        isDisabled={!isFormValid}
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => loginAs()}
      >
        <FormField label="Reason">
          <Select
            onChange={handleReasonChange}
            options={reasonOptions()}
            value={reasonCode}
          />
        </FormField>
        <FormField errorToShow={getErrorToShow('reason')} label="Other">
          <Input
            dataTestId="reason-value"
            onBlur={() => setHasVisitedField('reason')}
            onChange={setReason}
          />
        </FormField>
      </ConfirmModal>
    </>
  );
};

export default LoginAsButton;
