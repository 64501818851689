import React from 'react';
import { LoadingSpinner } from 'common';

const LogoutLoader: React.FC = () => (
  <div className="fixed top-0 left-0 h-full w-full z-[9999] flex-col items-center justify-center flex animate-pulse backdrop-blur-[2px] backdrop-brightness-[40%]">
    <div className="flex flex-col items-center justify-center">
      <p className="mb-2 text-white">Please wait while we log you out.</p>
      <LoadingSpinner color="var(--white)" />
    </div>
  </div>
);
export default LogoutLoader;
