import React from 'react';

interface Props {
  children?: React.ReactNode;
}

const HelpChatHook: React.FC<Props> = ({ children }) => {
  return children;
};

export default HelpChatHook;
