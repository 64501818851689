import React from 'react';
import { getErrorMessage, Icon } from 'common';

interface Props {
  error?: unknown;
}

const ConnectorError = ({ error }: Props) => {
  const errorMessage =
    getErrorMessage(error) || 'Data could not load. Please try again.';

  return (
    <div className="connector-error">
      <Icon.Alert />
      <p>{errorMessage}</p>
    </div>
  );
};

export default ConnectorError;
