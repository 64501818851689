import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownButton,
  ItemIcon,
  LinkButton,
  ProposalSummary,
  ProposalTemplateSummary,
} from 'common';
import { PROPOSALS } from '../../core-utils/routes';
import StatusLabel from '../../components/StatusLabel';
import { useGetVisibleProposalTemplates } from 'app/src/services/proposalTemplate';
import {
  FLAG_TYPES,
  isActionAllowed,
} from '../../core-utils/helperFunctions/userServiceHelper';

interface Props {
  isDisabled?: boolean;
  isLoading: boolean;
  onClickCreate: (template?: ProposalTemplateSummary) => void;
  proposal?: ProposalSummary;
}

const ExternalEntityToProposalButton: React.FC<Props> = ({
  onClickCreate,
  proposal,
  isLoading,
  isDisabled: isDisabledProp = false,
}: Props) => {
  const { data: templates } = useGetVisibleProposalTemplates();

  const activeTemplates = templates?.filter(
    (template: ProposalTemplateSummary) => template.status === 'active'
  );

  const canCreate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'create');

  const getTemplateOption = (template: ProposalTemplateSummary) => {
    return (
      <Fragment key={template.id}>
        <div
          key={`use-template-${template.id}`}
          className="template-option"
          data-testid={`use-template-${template.id}`}
        >
          <div>{template.name}</div>
          <div className="action">
            <LinkButton
              onClick={() => {
                onClickCreate(template);
              }}
              dataTestId="copy-from-template-button"
              className="template-link"
              isDisabled={isLoading}
            >
              Create proposal
            </LinkButton>
          </div>
        </div>
        <div
          className="h-[1px] bg-gray-lines w-full"
          key={`gl-${template.id}`}
        />
      </Fragment>
    );
  };

  const getTemplateOptions = () => {
    return activeTemplates?.length ? (
      <div className="min-w-fit w-[300px]">
        <div className="text-slate text-xs uppercase pt-4 p-2">
          Proposal templates
        </div>

        <div className="overflow-y-auto max-h-80">
          {activeTemplates.map((t) => getTemplateOption(t))}
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="flex justify-start items-center">
      {proposal ? (
        <Link
          className="flex items-center gap-2 cursor-pointer"
          to={`${PROPOSALS}/${proposal.id}`}
        >
          <ItemIcon.Proposal width="30px" height="30px" />
          <StatusLabel
            status={proposal.status}
            displayStatus={proposal.displayStatus}
            feature="proposal"
            classNames="w-[150px]"
          />
        </Link>
      ) : (
        canCreate && (
          <DropdownButton
            label="Create proposal"
            isDisabled={isDisabledProp}
            onClick={onClickCreate}
            isLoading={isLoading}
            options={getTemplateOptions()}
          />
        )
      )}
    </div>
  );
};

export default ExternalEntityToProposalButton;
