import { EditorFeature, EditorFeatureKey } from './editorCommon';

import boldFeature from './features/boldFeature';
import underlineFeature from './features/underlineFeature';
import tableFeature from './features/tableFeature';
import historyFeature from './features/historyFeature';
import linkFeature from './features/linkFeature';
import bulletFeature from './features/bulletFeature';
import highlightFeature from './features/highlightFeature';

export const EDITOR_FEATURES: EditorFeature[] = [
  boldFeature,
  underlineFeature,
  highlightFeature,
  bulletFeature,
  tableFeature,
  linkFeature,
  historyFeature,
];

export const ALL_FEATURES: EditorFeatureKey[] = EDITOR_FEATURES.map(
  (feature) => feature.key
);
