import { Refund } from 'common/src';

const refundStatusLabels: Record<Refund['status'], string> = {
  pending: 'Pending',
  // eslint-disable-next-line camelcase
  in_progress: 'In Progress',
  posted: 'Posted',
  failed: 'Failed',
  cancelled: 'Cancelled',
  reversed: 'Reversed',
};

export const refundStatusOptions = () => {
  return (Object.keys(refundStatusLabels) as Refund['status'][]).map(
    (status) => ({
      value: status,
      name: refundStatusLabels[status],
    })
  );
};
