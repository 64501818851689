import ApiLogoSVG from './ApiLogoSVG';
import AvalaraLogoSVG from './AvalaraLogoSVG';
import CloseSVG from './CloseSVG';
import DocuSignLogoSVG from './docuSignLogoSVG';
import SlackLogoSVG from './slackLogoSVG';
import SalesforceLogoSVG from './salesforceLogoSVG';
import HubspotLogoSVG from './hubspotSVG';
import QuickbooksLogoSVG from './QuickbooksSVG';
import StripeLogoSVG from './StripeLogoSVG';
import Cacheflow from './Cacheflow';
import ZapierSVG from './ZapierSVG';
import ZapierLogoSVG from './ZapierLogoSVG';

export default {
  salesforce: SalesforceLogoSVG,
  api: ApiLogoSVG,
  stripe: StripeLogoSVG,
  docusign: DocuSignLogoSVG,
  hubspot: HubspotLogoSVG,
  close: CloseSVG,
  slack: SlackLogoSVG,
  quickbooks: QuickbooksLogoSVG,
  avalara: AvalaraLogoSVG,
  cacheflow: Cacheflow,
  zapier: ZapierSVG,
  zapierLogo: ZapierLogoSVG,
};
