import React from 'react';
import { Link } from 'react-router-dom';
import {
  Cell,
  Loading,
  SimpleTable,
  Transaction,
  useTranslation,
} from 'common';
import ErrorHandler from '../../components/ErrorHandler';
import { useTransactionChildren } from '../../services/api/transactions/transactionsList';
import StatusLabel from '../../components/StatusLabel';
import DateTimeCell from '../../components/DateTimeCell';

import {
  getPaymentTypeString,
  paymentIcon,
} from '../../core-utils/helperFunctions/paymentUtils';
import {
  getDebitAmount,
  getToFromName,
  getTransactionDetailLink,
} from '../../services/api/transactions/transactionUtils';

interface Props {
  transactionId: string;
}

const TransactionChildren: React.FC<Props> = ({ transactionId }) => {
  const {
    isLoading,
    error,
    isError,
    data: transfers,
  } = useTransactionChildren(transactionId);
  const { tk } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorHandler error={error} />;
  }

  const cells: Cell<Transaction>[] = [
    {
      key: 'processedAtFormatted',
      headerLabel: tk('Date / Time'),
      renderCell: (transaction: Transaction) => (
        <DateTimeCell date={transaction.processedAt} />
      ),
    },
    {
      key: 'fromName',
      headerLabel: tk('To/From'),
      renderCell: (transaction: Transaction) => (
        <div>{getToFromName(transaction)}</div>
      ),
    },
    {
      key: 'amount',
      headerLabel: tk('Amount'),
      renderCell: (transaction: Transaction) => (
        <div>{getDebitAmount(transaction)}</div>
      ),
    },
    {
      key: 'reason',
      headerLabel: tk('Payment method'),
      renderCell: (transaction: Transaction) => {
        return (
          <div className="flex text-center items-center justify-center">
            {paymentIcon(transaction)}
          </div>
        );
      },
    },
    {
      key: 'referenceType',
      headerLabel: tk('Payment type'),
      renderCell: (transaction: Transaction) => {
        const detailLink = getTransactionDetailLink(transaction);

        if (detailLink)
          return (
            <Link to={detailLink}>
              <div className="underline text-blue-darkest">
                {getPaymentTypeString(transaction)}
              </div>
            </Link>
          );
        return <div>{getPaymentTypeString(transaction)}</div>;
      },
    },

    {
      key: 'status',
      headerLabel: tk('Status'),
      renderCell: (transaction: Transaction) => (
        <div>
          <StatusLabel
            status={transaction.status || ''}
            feature="transaction"
          />
        </div>
      ),
    },
  ];

  if (!transfers?.length) {
    return null;
  }

  return (
    <SimpleTable<Transaction>
      disableSearch
      disableShadow
      cells={cells}
      rows={transfers}
      isLoading={isLoading}
    />
  );
};

export default TransactionChildren;
