import React from 'react';

import { Card, CardHeader, OrgConfig } from 'common';

import OrgConfigToggle from '../OrgConfigToggle';

const configKey: OrgConfig['configKey'] = 'addressVerificationConfig';

const AddressVerificationSettings: React.FC = () => {
  return (
    <Card>
      <CardHeader name="Address verification">
        <OrgConfigToggle
          orgConfigKey={configKey}
          dataTestId="enable-address-verification"
        />
      </CardHeader>
      <p className=" text-s p-1">
        This toggle only controls address verification before passing
        information to Avalara for taxes.
      </p>
    </Card>
  );
};

export default AddressVerificationSettings;
