import React from 'react';
import {
  AutoForm,
  Card,
  CardHeader,
  LocationCodeMappingConfig,
  OrgConfig,
  SelectOption,
  ValidationFormField,
} from 'common';
import { useQueryClient } from '@tanstack/react-query';
import {
  setOrgConfigDefault,
  useFindOrgDefault,
} from '../../../services/orgDefaults';

const CONFIG_TYPE = 'locationCodeMappingConfig';

const LocationCodeMappingSettings: React.FC = () => {
  const queryClient = useQueryClient();
  const { data: configData, isLoading } = useFindOrgDefault(CONFIG_TYPE);

  const saveConfig = async (value: LocationCodeMappingConfig) => {
    const val: OrgConfig['configValue'] = {
      ...value,
      configType: CONFIG_TYPE,
    };

    return await setOrgConfigDefault(
      CONFIG_TYPE,
      { ...configData, configKey: CONFIG_TYPE, configValue: val },
      queryClient
    );
  };

  enum LocationCodeType {
    CUSTOM = 'CUSTOM',
    ISO2 = 'ISO2 - US',
    ISO3 = 'ISO3 - USA',
    NAME = 'NAME - United States',
  }

  const options: SelectOption<string>[] = Object.keys(LocationCodeType).map(
    (key) => ({
      value: key,
      name: LocationCodeType[key as keyof typeof LocationCodeType].valueOf(),
    })
  );

  const fields: ValidationFormField<LocationCodeMappingConfig>[] = [
    {
      fieldName: 'locationCodeType',
      humanReadableName: 'Address display preference',
      type: 'select',
      inputProps: {
        options,
      },
      infoText: 'Set how address country and regions will be displayed',
    },
  ];

  return (
    <Card>
      <CardHeader name="Address settings" />
      <AutoForm
        isDisabled={isLoading}
        entity={configData?.configValue}
        entityName="location-code-mapping-settings"
        showControls
        onSave={saveConfig}
        queryClient={queryClient}
        fields={fields}
      />
    </Card>
  );
};

export default LocationCodeMappingSettings;
