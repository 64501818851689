import React from 'react';
import { formatDate } from 'common';

interface Props {
  children: React.ReactNode;
  date: string;
}

const EventCluster: React.FC<Props> = ({ date, children }) => {
  return (
    <div>
      <div className="text-slate pb-4 font-semibold">{formatDate(date)}</div>
      <div className="flex flex-col gap-2">{children}</div>
    </div>
  );
};

export default EventCluster;
