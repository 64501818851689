import { ApprovalRequest, Approver, isString, Proposal } from 'common';

import { useGetApprovalGroupsByUser } from 'app/src/services/approvals';
import { useGetAllPendingProposalApprovalRequests } from 'app/src/services/proposal';

export const useApprovalRequestsByUserGroups = (proposal: Proposal) => {
  const { data: approvalRequests, isLoading: isLoadingApprovalRequests } =
    useGetAllPendingProposalApprovalRequests(proposal.id);

  const { data: groupsForUser, isLoading: isLoadingGroupsByUser } =
    useGetApprovalGroupsByUser({ enabled: true });

  const isLoadingFilteredRequests =
    isLoadingApprovalRequests || isLoadingGroupsByUser;

  const getFilteredRequests = () => {
    if (isLoadingFilteredRequests || !approvalRequests || !groupsForUser) {
      return [];
    }

    const userGroups = groupsForUser
      .map((group) => group.group?.id)
      .filter(isString);

    const userIsApprover = (approver: Approver) =>
      approver.groupId && userGroups.includes(approver.groupId);

    const requestCanBeApproved = (request: ApprovalRequest) =>
      request.approvers?.some(userIsApprover);

    return approvalRequests.filter(requestCanBeApproved);
  };

  return {
    isLoadingFilteredRequests,
    getFilteredRequests,
  };
};
