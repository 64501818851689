import React from 'react';

import {
  getProposalStatusDisplay,
  Proposal,
  ProposalSummary,
  Tab,
  Tabs,
} from 'common';

interface Props {
  children: React.ReactNode;
  onTabChange: (tab: ProposalSummary['proposalType']) => void;
  selectedProposalType: ProposalSummary['proposalType'];
}

const getProposalTypeTab = (proposalType: Proposal['proposalType']): Tab => {
  return {
    label: getProposalStatusDisplay(proposalType),
    key: proposalType,
    dataTestId: proposalType + '-proposal',
  };
};

export const ApprovalTabsWrapper = ({
  onTabChange,
  selectedProposalType,
  children,
}: Props) => {
  return (
    <div>
      <Tabs
        tabs={[
          getProposalTypeTab('initial'),
          getProposalTypeTab('change'),
          getProposalTypeTab('renewal'),
        ]}
        onChange={(tab) => {
          onTabChange(tab as ProposalSummary['proposalType']);
        }}
        defaultActiveTab={selectedProposalType}
      />
      {children}
    </div>
  );
};
