import React from 'react';
import { Button, Card, CardHeader, CardRowValue } from 'common';
import { PaymentItemProps } from './PaymentList';
import styles from './PaymentInfoCard.module.scss';
import { useIsSuperAdmin } from '../../../../core-utils/helperFunctions/userServiceHelper';

interface Props {
  title: string;
  paymentItem: PaymentItemProps[] | undefined;
}

const PaymentInfoCard: React.FC<Props> = ({ title, paymentItem }) => {
  const isSuperAdmin = useIsSuperAdmin();

  return (
    <div className={styles.paymentInfoCard}>
      <div className={styles.side}>
        <Card bottomMargin throughline grow>
          <CardHeader name={title} throughline />
          {paymentItem?.map((paymentInfoItem: PaymentItemProps) => (
            <CardRowValue
              label={paymentInfoItem.label}
              key={paymentInfoItem.id}
            >
              <div className="flex gap-2">
                <div className="flex">{paymentInfoItem.value}</div>
                {paymentInfoItem.type === 'button' && isSuperAdmin && (
                  <Button
                    size="small"
                    label="Edit"
                    type="secondary"
                    isDisabled={paymentInfoItem.isDisabled}
                    onClick={paymentInfoItem.onClick}
                  />
                )}
              </div>
            </CardRowValue>
          ))}
        </Card>
      </div>
    </div>
  );
};

export default PaymentInfoCard;
