import React, { useEffect, useMemo } from 'react';
import {
  ConnectorConfiguration,
  ConnectorInfoObject,
  GroupedLines,
  Option,
} from '../ConnectorConstants/constants';
import ConnectorLocation from '../ConnectorLocation';
import {
  AccountingConfig,
  arrayTop,
  AvalaraConfig,
  FormField,
  Input,
  OrgConfig,
  Select,
  ToggleSwitch,
  ToolTip,
  useFlags,
  useFormValidation,
  useTranslation,
  ValidationFormField,
} from 'common';
import styles from './ConnectorConfigurationEditor.module.scss';

interface Props {
  configuration: ConnectorConfiguration;
  connectorConfigValue: OrgConfig['configValue'];
  connectorInfo: ConnectorInfoObject;
  setConnectorConfigValue: React.Dispatch<
    React.SetStateAction<OrgConfig['configValue']>
  >;
  setSaveableChanges: (arg: boolean) => void;
  handleDisableStatus: () => void;
}

const ConnectorConfigurationEditor = ({
  setConnectorConfigValue,
  configuration,
  connectorConfigValue,
  setSaveableChanges,
  connectorInfo,
  handleDisableStatus,
}: Props) => {
  const { tk } = useTranslation();
  const { locationCodeMapping } = useFlags();

  const getValues = (): { [key: string]: any } => {
    const values = configuration?.name
      ? (connectorConfigValue as any)[configuration.name]
      : connectorConfigValue;

    return values ?? {};
  };

  const getInfoMessege = (
    configurationLine: keyof AccountingConfig
  ): string => {
    if (configurationLine === 'productAccountCode') {
      return 'This account will be set as an income account for any new product created by Cacheflow during sync. Existing products in QuickBooks will not be updated.';
    } else if (configurationLine === 'depositAccountCode') {
      return 'This account will be set as deposit to account while creating payments in QuickBooks.';
    } else if (configurationLine === 'taxAccountCode') {
      return 'Income Account for tax line items';
    }
    return '';
  };

  const isAvalaraConfig = (c: OrgConfig['configValue']): c is AvalaraConfig =>
    c?.configType === 'avalaraConfig';

  const avalaraConfigOrUndefined = (
    c: OrgConfig['configValue']
  ): AvalaraConfig | undefined => (isAvalaraConfig(c) ? c : undefined);

  const requiredFields = useMemo(
    () =>
      configuration?.lines
        ? configuration.lines
            .filter((line) => line.isRequired)
            .map((line) => ({
              fieldName: line.fieldName,
              humanReadableName: line.humanReadableName,
              isRequired: line.isRequired,
            }))
        : [],
    [configuration?.lines]
  );

  const groupLinesBySection = <T extends Record<string, unknown>>(
    lines: ValidationFormField<T>[]
  ): GroupedLines<T> => {
    return lines.reduce<GroupedLines<T>>((acc, currentLine) => {
      const { section } = currentLine;

      if (!section) return acc;

      if (!acc[section]) {
        acc[section] = [];
      }

      acc[section].push(currentLine);

      return acc;
    }, {});
  };

  const groupedLinesBySection = groupLinesBySection(configuration.lines);
  const sections = Object.keys(groupedLinesBySection);

  const { getErrorToShow, setHasVisitedField, showAllErrors, isFormValid } =
    useFormValidation(requiredFields, getValues());

  const isAvalaraConfigValid = useMemo(() => {
    if (!isAvalaraConfig(connectorConfigValue)) {
      return true;
    }

    return connectorConfigValue?.enabled;
  }, [connectorConfigValue]);

  useEffect(() => {
    if (avalaraConfigOrUndefined(connectorConfigValue)?.enabled) {
      setSaveableChanges(isFormValid);
      showAllErrors();
    }
  }, [
    requiredFields,
    avalaraConfigOrUndefined(connectorConfigValue)?.enabled,
    isFormValid,
  ]);

  const handleFieldChange = (fieldName: string, value: any) => {
    const configName = configuration.name;
    const values = getValues();

    const change: OrgConfig['configValue'] = configName
      ? { [configName]: { ...values, [fieldName]: value } }
      : { [fieldName]: value };

    values[fieldName] = value;
    setConnectorConfigValue((prev) => ({
      ...prev,
      ...change,
    }));
    handleDisableStatus();
    (connectorConfigValue as AvalaraConfig).enabled
      ? setSaveableChanges(isFormValid)
      : setSaveableChanges(true);
  };

  const initialValueBoolean = (name: string) => {
    const values = getValues();
    return !!values[name];
  };

  function initialValue(name: string) {
    const values = getValues();
    const value =
      values[name] ??
      (() => {
        const lineEntry = configuration.lines.find(
          (entry) => entry.fieldName === name
        );
        const options: Option[] = lineEntry?.inputProps?.options;
        return arrayTop(options)?.value;
      })();
    return value ?? '';
  }

  const handleToggle = (fieldName: string) => {
    const values = getValues();
    handleFieldChange(fieldName, !values[fieldName]);
  };

  const getConnectorConfigurationLineInput = (
    line: ValidationFormField<unknown>
  ): React.JSX.Element => {
    return (
      <React.Fragment key={`connector-config-${line.fieldName}`}>
        {line.type === 'toggle' &&
        connectorInfo.configType !== 'hubspotConfig' ? (
          <FormField className={styles.formField}>
            <ToolTip
              title={
                connectorConfigValue?.configType === 'avalaraConfig' &&
                !avalaraConfigOrUndefined(connectorConfigValue)?.enabled
                  ? 'Can be updated only when the connector is enabled'
                  : ''
              }
            >
              <ToggleSwitch
                alignLabel="right"
                isDisabled={
                  connectorConfigValue?.configType === 'avalaraConfig' &&
                  !avalaraConfigOrUndefined(connectorConfigValue)?.enabled
                }
                label={tk(
                  line.humanReadableName ||
                    `${connectorInfo.source}.${line.fieldName}`
                )}
                name={`input-${line.fieldName}`}
                onChange={() => handleToggle(line.fieldName)}
                value={initialValueBoolean(line.fieldName)}
              />
            </ToolTip>
          </FormField>
        ) : (
          line.type !== 'toggle' && (
            <FormField
              className={styles.formField}
              errorToShow={
                line.isRequired && isAvalaraConfigValid
                  ? getErrorToShow(line.fieldName)
                  : undefined
              }
              label={tk(
                line.humanReadableName ||
                  `${connectorInfo.source}.${line.fieldName}`
              )}
            >
              {line.type === 'select' && (
                <ToolTip
                  title={getInfoMessege(
                    line.fieldName as keyof AccountingConfig
                  )}
                >
                  <Select<string>
                    dataTestId={`input-${line.fieldName}`}
                    onChange={(value) =>
                      handleFieldChange(line.fieldName, value)
                    }
                    options={line.inputProps?.options ?? []}
                    value={initialValue(line.fieldName)}
                  />
                </ToolTip>
              )}

              {line.type === 'string' && (
                <ToolTip
                  title={
                    connectorConfigValue?.configType === 'avalaraConfig' &&
                    !avalaraConfigOrUndefined(connectorConfigValue)?.enabled
                      ? 'Can be updated only when the connector is enabled'
                      : ''
                  }
                >
                  <Input
                    id={`input-${line.fieldName}`}
                    isDisabled={
                      connectorConfigValue?.configType === 'avalaraConfig' &&
                      !avalaraConfigOrUndefined(connectorConfigValue)?.enabled
                    }
                    onBlur={() =>
                      line.isRequired && setHasVisitedField(line.fieldName)
                    }
                    onChange={(rv) => handleFieldChange(line.fieldName, rv)}
                    value={initialValue(line.fieldName)}
                  />
                </ToolTip>
              )}

              {line.type === 'password' && (
                <ToolTip
                  title={
                    connectorConfigValue?.configType === 'avalaraConfig' &&
                    !avalaraConfigOrUndefined(connectorConfigValue)?.enabled
                      ? 'Can be updated only when the connector is enabled'
                      : ''
                  }
                >
                  <Input
                    id={`input-${line.fieldName}`}
                    isDisabled={
                      connectorConfigValue?.configType === 'avalaraConfig' &&
                      !avalaraConfigOrUndefined(connectorConfigValue)?.enabled
                    }
                    onBlur={() =>
                      line.isRequired && setHasVisitedField(line.fieldName)
                    }
                    onChange={(rv) => handleFieldChange(line.fieldName, rv)}
                    type="password"
                    value={initialValue(line.fieldName)}
                  />
                </ToolTip>
              )}
            </FormField>
          )
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="connector-configuration">
      <section className="bottom">
        {locationCodeMapping &&
          connectorConfigValue &&
          'locationCodeType' in connectorConfigValue &&
          connectorConfigValue.configType !== 'avalaraConfig' && (
            <div className="mb-2">
              <ConnectorLocation
                connectorConfigValue={connectorConfigValue}
                setConnectorConfigValue={setConnectorConfigValue}
                setSavableChanges={setSaveableChanges}
              />
            </div>
          )}
        {configuration?.toggleSwitchForEnablement && (
          <FormField
            label={tk(`connectors.${connectorInfo.source}.enableToggle`)}
          >
            <ToggleSwitch
              name="input-enabled-toggle"
              onChange={(e) => {
                setConnectorConfigValue(
                  (prev) =>
                    ({
                      ...prev,
                      configType: connectorInfo.configType,
                      enabled: e,
                    }) as OrgConfig['configValue']
                );
                setSaveableChanges(true);
                handleDisableStatus();
              }}
              value={
                connectorConfigValue &&
                'enabled' in connectorConfigValue &&
                connectorConfigValue.enabled
              }
            />
          </FormField>
        )}

        {sections.length > 0
          ? sections?.map((section) => {
              return (
                <div key={section}>
                  <div className={styles.sectionHeader}>{section}</div>
                  {groupedLinesBySection[section]?.map(
                    getConnectorConfigurationLineInput
                  )}
                </div>
              );
            })
          : configuration?.lines
              .filter((line) => line.include !== false)
              .map(getConnectorConfigurationLineInput)}

        {locationCodeMapping &&
          connectorConfigValue &&
          'locationCodeType' in connectorConfigValue &&
          connectorConfigValue.configType === 'avalaraConfig' && (
            <div className="mb-2">
              <ConnectorLocation
                connectorConfigValue={connectorConfigValue}
                isDisabled={
                  connectorConfigValue?.configType === 'avalaraConfig' &&
                  !avalaraConfigOrUndefined(connectorConfigValue)?.enabled
                }
                setConnectorConfigValue={setConnectorConfigValue}
                setSavableChanges={setSaveableChanges}
                tooltipText={
                  connectorConfigValue?.configType === 'avalaraConfig' &&
                  !avalaraConfigOrUndefined(connectorConfigValue)?.enabled
                    ? 'Can be updated only when the connector is enabled'
                    : ''
                }
              />
            </div>
          )}
      </section>
    </div>
  );
};

export default ConnectorConfigurationEditor;
