import { AttachmentConfig } from 'common';
import { APIResponse } from '../../../services/api/api';
import { useGetAllAttachmentConfigs } from '../../../services/api/attachments/attachments';

export const useGetAttachmentConfigs = (): APIResponse<AttachmentConfig> => {
  const { data, isLoading, isError, error } = useGetAllAttachmentConfigs();
  const apiResponse = new APIResponse<AttachmentConfig>(
    data!,
    isLoading,
    isError,
    error
  );

  return apiResponse;
};
