import React, { useState } from 'react';
import { Checkbox, PillButton, useTranslation } from 'common';

interface Props {
  isActive: boolean;
  isDefault: boolean;
  name: string;
  onActiveToggle: () => boolean;
  onSetDefault: () => void;
}

const PaymentTermCard: React.FC<Props> = ({
  name,
  isActive,
  isDefault,
  onActiveToggle,
  onSetDefault,
}) => {
  const { tk } = useTranslation();

  const [draftIsActive, setDraftIsActive] = useState<boolean>(isActive);

  const getLabel = () => {
    return tk(`term_${name}_name`, name);
  };

  const getDescription = () => {
    return tk(`term_${name}_description`, name);
  };

  const onToggle = () => {
    const settingTo = !draftIsActive;
    setDraftIsActive(!onActiveToggle() ? !settingTo : settingTo);
  };

  return (
    <>
      <div className="flex flex-row  pb-8">
        <div className="font-bold w-[35px]">
          <Checkbox
            value={draftIsActive}
            className="inline-block"
            name={name}
            onChange={onToggle}
          />
        </div>
        <div className="w-full pl-2">
          <div className="pb-2 font-medium text-md">{getLabel()}</div>
          <div className="w-3/5 text-slate-darker">{getDescription()}</div>
        </div>
        <div className="flex justify-end content-end  w-1/5">
          {draftIsActive && (
            <PillButton
              active={isDefault}
              onClick={() => {
                if (!isDefault) {
                  onSetDefault();
                }
              }}
              dataTestId={`${name}-set-as-default`}
            >
              {isDefault ? 'Default' : 'Set as default'}
            </PillButton>
          )}
        </div>
      </div>
      {/* <div className="mb-8 ml-8 p-6 pb-0 bg-slate-lightest"> */}
    </>
  );
};

export default PaymentTermCard;
