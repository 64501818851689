import React, { SVGProps } from 'react';

import { Contact, formatPerson, formatTime, User } from 'common';

import { isEventSystemUser } from 'app/src/core-utils/helperFunctions/eventUtils';

export interface EventDisplayProps {
  createdAt: string;
  dataTestId?: string;
  extra?: React.ReactNode;
  id: string;
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  label: string;
  person?: Contact | User | string;
  statusPill?: React.ReactNode;
  message?: string;
}

const EventDisplay: React.FC<EventDisplayProps> = ({
  createdAt,
  dataTestId,
  extra,
  person,
  id,
  icon: EventIcon,
  label,
  statusPill = null,
  message = '',
}) => (
  <div className="flex justify-start gap-4" data-testid={dataTestId} id={id}>
    <div className="w-10">
      <div className="w-10 h-10 flex items-center justify-center border-2 rounded-full text-slate border-slate">
        <EventIcon />
      </div>
    </div>
    <div className="w-full pb-5">
      <div className="flex items-center">
        <div className="font-semibold">{label}</div>
        {statusPill && <div className="ml-2">{statusPill}</div>}
      </div>

      {message && <div>{message}</div>}

      <div className="flex flex-row items-center pb-2.5">
        {isEventSystemUser(person) ? (
          <div className="text-xs text-slate">{formatTime(createdAt)}</div>
        ) : (
          <>
            <div className="text-xs text-blue">{formatPerson(person)}</div>
            <div className="text-xs text-slate">{`, ${formatTime(
              createdAt
            )}`}</div>
          </>
        )}
      </div>
      {extra ? extra : null}
    </div>
  </div>
);

export default EventDisplay;
