import { useOrganizations } from 'app/src/services/api/system/organizations';
import {
  BillingScheduleSyncSummary,
  Filter,
  isDropdownFilter,
  SearchFilterV2Value,
  TransferSyncSummary,
} from 'common';

enum SyncStatus {
  error = 'Error',
  success = 'Success',
}

export const reconciliationFilters = (
  isSystemAdmin: boolean,
  reconciliationFiltersType: SearchFilterV2Value[]
): Filter[] => {
  const filters: Filter[] = [];

  if (isSystemAdmin) {
    const { data: orgs } = useOrganizations(isSystemAdmin);
    const newData: any[] = orgs?.map((org) => {
      return { value: org.id, name: org.name };
    }) || ['No user availible'];

    filters.push({
      type: 'dropdown',
      key: 'orgId',
      humanReadableName: 'Organization',
      fieldName: 'orgId',
      options: newData,
      searchable: true,
    });
  }

  filters.push({
    type: 'searchV2',
    key: 'search',
    searchFields: reconciliationFiltersType,
  });

  filters.push({
    type: 'dateRadio',
    key: 'createdAt',
    humanReadableName: 'Created date',
    fieldName: 'createdAt',
    optionsType: 'currentAndPast',
  });

  filters.push({
    type: 'dateRadio',
    key: 'updatedAt',
    humanReadableName: 'Updated date',
    fieldName: 'updatedAt',
    optionsType: 'currentAndPast',
  });

  filters.push({
    type: 'dropdown',
    key: 'status',
    humanReadableName: 'Status',
    fieldName: 'status',
    options: Object.keys(SyncStatus).map((key) => ({
      value: key,
      name: SyncStatus[key as keyof typeof SyncStatus].valueOf(),
    })),
  });

  const dropdownFilter = filters[0];

  if (dropdownFilter && isDropdownFilter(dropdownFilter)) {
    const sortedOptions = dropdownFilter.options.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    sortedOptions.unshift({
      key: 'All',
      name: 'All organizations',
      value: '',
    });
    dropdownFilter.options = sortedOptions;
    return filters;
  }

  return filters;
};

export const getLatestEvent = (
  data: BillingScheduleSyncSummary | TransferSyncSummary
) => {
  return data.lastSuccessAfterScheduleUpdate
    ? data.lastSuccessAfterScheduleUpdate
    : data.lastEvent;
};
