import React, { SVGProps } from 'react';

const DocuSignLogoSVG: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 256.0 256.0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>DocuSign Logo</title>
      <defs>
        <clipPath id="docusign-outer">
          <path d="M256,0 L256,256 L0,256 L0,0 L256,0 Z" />
        </clipPath>
        <clipPath id="docusign-inner">
          <path d="M155,131.5 L155,154.5 L0,154.5 L0,131.5 L155,131.5 Z M89.5,0 L89.5,78.217 L120.959517,46.7571917 L137.222973,63.0206476 L77.5,122.74362 L17.7770272,63.0206476 L34.0404832,46.7571917 L66.5,79.217 L66.5,0 L89.5,0 Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#docusign-outer)">
        <g clipPath="url(#docusign-outer)">
          <polygon
            points="0,0 256,0 256,256 0,256 0,0"
            stroke="none"
            fill="#DBF900"
          />
        </g>
        <g transform="translate(50.5 50.5)">
          <g clipPath="url(#docusign-inner)">
            <polygon
              points="0,0 155,0 155,154.5 0,154.5 0,0"
              stroke="none"
              fill="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DocuSignLogoSVG;
