import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Button,
  Card,
  CardHeader,
  deepEquals,
  getErrorMessage,
  Loading,
  OrgConfig,
  ProposalConfig as ProposalConfigValue,
  ToggleSwitch,
  useFlags,
  useToast,
  useTranslation,
  Flex,
} from 'common';

import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';

const PROPOSAL_CONFIG_KEY: NonNullable<OrgConfig['configKey']> =
  'proposalConfig';

const ProposalManagement: React.FC = () => {
  const [draft, setDraft] = useState<ProposalConfigValue>({});
  const showToast = useToast();
  const queryClient = useQueryClient();
  const { tk } = useTranslation();
  const { data: proposalConfig, isLoading: isProposalConfigLoading } =
    useFindOrgDefault(PROPOSAL_CONFIG_KEY);
  const { proratingByMonths } = useFlags();

  const { mutate, isPending } = useSetOrgConfigDefault(
    PROPOSAL_CONFIG_KEY,
    () => showToast.success(tk('Settings saved.')),
    (error) =>
      showToast.error(
        getErrorMessage(error) ?? 'Failed to save setting. Please try again.'
      ),
    queryClient
  );

  const formIsDirty: boolean = !deepEquals(draft, proposalConfig?.configValue);

  useEffect(() => {
    if (proposalConfig) {
      const configValueIn: ProposalConfigValue | undefined =
        proposalConfig.configValue;

      if (configValueIn) {
        setDraft(configValueIn);
      }
    }
  }, [proposalConfig]);

  const saveConfig = () => {
    mutate({
      configValue: {
        ...draft,
        configType: PROPOSAL_CONFIG_KEY,
      },
    });
  };

  if (isProposalConfigLoading || !proposalConfig) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader name="Proposal management">
        <Button
          dataTestId="save-button"
          isDisabled={!formIsDirty}
          isLoading={isPending}
          label="Save"
          onClick={saveConfig}
        />
      </CardHeader>

      <Flex gap vertical>
        <ToggleSwitch
          dataTestId="changes-only-view-default"
          infoText="Displays only change values on signing and PDF download for change proposals."
          isDisabled={isPending}
          label={tk('Set changes only view as default')}
          onChange={(changesOnlyViewAsDefault) =>
            setDraft((prev) => ({
              ...prev,
              changesOnlyViewAsDefault,
            }))
          }
          value={draft.changesOnlyViewAsDefault}
        />

        <ToggleSwitch
          dataTestId="show-discount-view-default"
          infoText="Discounts for each product will be automatically displayed when available, with the option to hide them"
          isDisabled={isPending}
          label={tk('Show discount enabled by default on all products')}
          onChange={(showDiscountViewAsDefault) =>
            setDraft((prev) => ({
              ...prev,
              showDiscountViewAsDefault,
            }))
          }
          value={draft.showDiscountViewAsDefault}
        />

        {proratingByMonths && (
          <ToggleSwitch
            dataTestId="enable-daily-prorating"
            infoText="When an item contains a partial billing period, the prorated rate will be calculated utilizing the number of days in the specific month of which prorating is applied."
            isDisabled={isPending}
            label={tk('Enable daily proration')}
            onChange={(dailyProration) =>
              setDraft((prev) => ({
                ...prev,
                proratingType: dailyProration ? 'daily' : 'monthly',
              }))
            }
            value={draft.proratingType === 'daily'}
          />
        )}
      </Flex>
    </Card>
  );
};

export default ProposalManagement;
