import React, { useEffect, useState } from 'react';

import { LinkButton, NotificationEventConfiguration } from 'common';

import { NotificationRow } from './NotificationRow';

import styles from './Notifications.module.scss';

type NotificationMethod = 'email';
export type NotificationTargets = 'billing_contact' | 'accepting_contact';

export type NotificationSendOptions = {
  label: string;
  value: NotificationMethod;
};

export type NotificationTargetOptions = {
  label: string;
  value: NotificationTargets;
};

type NotificationsProps = {
  data?: NotificationEventConfiguration[];
  daysBeforeText: string;
  defaultNotification: NotificationEventConfiguration;
  maxNotifications: number;
  notificationSendOptions: NotificationSendOptions[];
  notificationTargetOptions: NotificationTargetOptions[];
  onSave: (notifications: NotificationEventConfiguration[]) => void;
  onSaveClear: () => void;
};

export type NotificationValidationBag = {
  daysBefore: boolean;
  targetGroups: boolean;
};

/**
 *
 * This is a base component that gets used by others to keep
 * an agnostic notifications system at its core. Loading data,
 * checking for errors or loading is all done in parent.
 *
 * Changes should not be made here, but instead in the extending parent
 * components, when you need to set up a new notification area.
 *
 * See AutoRenewalNotifications.tsx for an example.
 */
export const Notifications = ({
  data,
  daysBeforeText,
  defaultNotification,
  maxNotifications,
  notificationSendOptions,
  notificationTargetOptions,
  onSave,
  onSaveClear,
}: NotificationsProps) => {
  const [notifications, setNotifications] = useState<
    NotificationEventConfiguration[]
  >(data ?? []);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (data) {
      setNotifications(data);
    }
  }, [data]);

  useEffect(() => {
    if (!hasChanged) {
      return;
    }

    const isValid = isNotificationsValid(notifications);

    if (isValid) {
      setHasChanged(false);
      onSave(notifications);
    } else {
      // Do not submit past pending changes if current form is invalid
      onSaveClear();
    }
  }, [notifications, hasChanged]);

  const handleNotificationChange = (
    index: number,
    notification: NotificationEventConfiguration
  ) => {
    const updatedNotifications = notifications.map((prevNotification, i) =>
      i === index ? notification : prevNotification
    );

    setNotifications(updatedNotifications);
    setHasChanged(true);
  };

  const handleNotificationRemove = (indexToRemove: number) => {
    const updatedNotifications = notifications.filter(
      (_, index) => index !== indexToRemove
    );

    setNotifications(updatedNotifications);
    setHasChanged(true);
  };

  const addNotification = () => {
    const notificationData = [...notifications, defaultNotification];

    setNotifications(notificationData);
    setHasChanged(true);
  };

  const isNotificationsValid = (value: NotificationEventConfiguration[]) => {
    return value.every((notification) => isNotificationRowValid(notification));
  };

  const isNotificationRowValid = (
    notification: NotificationEventConfiguration
  ): boolean => {
    if (!isDaysBeforeValid(notification)) {
      return false;
    }

    if (!isTargetGroupsValid(notification)) {
      return false;
    }

    return true;
  };

  const isDaysBeforeValid = (
    notification: NotificationEventConfiguration
  ): boolean => {
    if (
      typeof notification.beforeDays === 'undefined' ||
      Number.isNaN(notification.beforeDays)
    ) {
      return false;
    }

    return notification.beforeDays >= 1;
  };

  const isTargetGroupsValid = (
    notification: NotificationEventConfiguration
  ): boolean => {
    return Boolean(
      notification.targetGroups && notification.targetGroups.length > 0
    );
  };

  return (
    <div className={styles.notifications}>
      {notifications.length > 0 && (
        <>
          {notifications.map((notification, index) => {
            const validationBag = {
              daysBefore: isDaysBeforeValid(notification),
              targetGroups: isTargetGroupsValid(notification),
            };

            return (
              <NotificationRow
                key={index}
                daysBeforeText={daysBeforeText}
                notification={notification}
                onChange={(newNotification) =>
                  handleNotificationChange(index, newNotification)
                }
                onRemove={() => handleNotificationRemove(index)}
                notificationSendOptions={notificationSendOptions}
                notificationTargetOptions={notificationTargetOptions}
                validationBag={validationBag}
              />
            );
          })}

          <hr className={styles.hr} />
        </>
      )}

      {notifications.length < maxNotifications && (
        <LinkButton
          className={styles.addNotification}
          onClick={addNotification}
        >
          Add notification
        </LinkButton>
      )}
    </div>
  );
};
