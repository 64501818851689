import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Checkbox,
  Currency,
  CurrencySearchSelect,
  getCurrencyLabelByCode,
  getErrorMessage,
  HighlightPill,
  InfoToolTip,
  LinkButton,
  Loading,
  NumberInput,
  Type,
  useToast,
  useTranslation,
} from 'common';
import {
  useAllCurrencies,
  useCreateOrgCurrency,
  useOrgCurrencies,
  useUpdateOrgCurrency,
} from '../../../services/api/currency/currency';
import { useAuth } from '../../../Auth';
import styles from './currencySettings.module.scss';

const CurrencySettings = () => {
  const [defaultCurrency, setDefaultCurrency] = React.useState<Currency>();
  const showToast = useToast();
  const { tk } = useTranslation();
  const qc = useQueryClient();
  const profile = useAuth();

  const {
    data: currencies,
    isLoading: isLoadingCurrencies,
    error: errorCurrencies,
  } = useAllCurrencies();
  const {
    data: orgCurrencies,
    isLoading: isLoadingOrgCurrencies,
    error: errorOrgCurrencies,
  } = useOrgCurrencies();

  const { mutate: createOrgCurrency, isPending: createOrgCurrencyIsLoading } =
    useCreateOrgCurrency(
      () => {
        showToast.success(tk('Currency Added'));
      },
      (error: unknown) => {
        showToast.error(tk('Error adding currency: ') + getErrorMessage(error));
      },
      qc
    );
  const { mutate: updateOrgCurrency, isPending: updateOrgCurrencyIsLoading } =
    useUpdateOrgCurrency(
      () => {
        showToast.success(tk('Currency Updated'));
      },
      (error: unknown) => {
        showToast.error(
          tk('Error updating currency: ') + getErrorMessage(error)
        );
      },
      qc
    );

  const supportedCurrencies: {
    code: string;
    description: string;
    display: string;
  }[] =
    currencies?.map((currency) => ({
      code: currency.code,
      description: currency.description,
      display: getCurrencyLabelByCode(currency.code),
    })) || [];

  React.useEffect(() => {
    const foundDefault = orgCurrencies?.find(
      (c) => c.code === profile.organizationDefaultCurrency
    );
    if (foundDefault && foundDefault.code !== defaultCurrency?.code) {
      setDefaultCurrency(foundDefault);
    }
  }, [orgCurrencies]);

  const addNewCurrency = (currency: string) => {
    const currencyObj: Currency = {
      active: true,
      code: currency,
      reportExchangeRate: 100,
    };
    createOrgCurrency(currencyObj);
  };

  const activateOrDisableOrgCurrency = (currency: string, checked: boolean) => {
    const orgCopies = [...(orgCurrencies || [])];
    const currentCurrency = orgCopies.find((c) => c.code === currency)!;
    currentCurrency.active = checked;
    updateOrgCurrency(currentCurrency);
  };

  const updateExchangeRate = (currency: string, value: number) => {
    const orgCopies = [...(orgCurrencies || [])];
    const currentCurrency = orgCopies.find((c) => c.code === currency)!;

    currentCurrency.reportExchangeRate = value;
    updateOrgCurrency(currentCurrency);
  };

  return (
    <Card className={styles.currencySettings}>
      <CardHeader
        name={
          <div className={styles.pageTitle}>
            Currency <HighlightPill label="BETA" />
          </div>
        }
      >
        <div className={styles.pageHeader}>
          {errorCurrencies ? (
            <p>{errorCurrencies.message}</p>
          ) : !isLoadingCurrencies &&
            !createOrgCurrencyIsLoading &&
            !updateOrgCurrencyIsLoading ? (
            <CurrencySearchSelect
              onSelectCurrency={(currency: string) => addNewCurrency(currency)}
              dataTestId="currency-select"
              currencies={currencies}
              orgCurrencies={orgCurrencies}
              shouldShowAllCurrencies
              defaultSelectedCurrency={profile.organizationDefaultCurrency!}
              placeholderText={tk('Add currency')}
            />
          ) : (
            <Loading />
          )}
        </div>
      </CardHeader>
      <Type paragraph className={styles.descriptionText}>
        {tk(`Configure the currencies supported by your organization. Toggling on a
          currency will make it available for new proposals and customers. 
          Conversion rate values are used for reporting and approval
          thresholds. `)}
        <strong>
          {tk(
            `Set the pricing for your accepted currencies on the product detail pages.`
          )}
        </strong>
        <Link
          to="https://help.getcacheflow.com/en/articles/8377644-beta-multi-currency"
          target="_blank"
        >
          <LinkButton>{tk('Read more')}</LinkButton>
        </Link>
      </Type>

      <div className={styles.currencyRow}>
        <h6 className={styles.rowHeaderLabel}>{tk('Active')}</h6>
        <h6 className={styles.rowHeaderLabel}>{tk('Currency code')}</h6>
        <h6 className={styles.rowHeaderLabel}>{tk('Description')}</h6>
        <h6 className={styles.rowHeaderLabel}>
          {tk('Conversion rate')}
          <InfoToolTip title="These conversion rates are used exclusively for reporting and approval thresholds. They are not associated with product pricing." />
        </h6>
      </div>
      {isLoadingOrgCurrencies && <Loading />}
      {!isLoadingOrgCurrencies && !errorOrgCurrencies && defaultCurrency && (
        <>
          <CurrencyRow
            currency={defaultCurrency}
            currencyMatch={supportedCurrencies.find(
              (c) => c.code === defaultCurrency.code
            )}
            defaultCurrencyCode={defaultCurrency.code}
          />
          {orgCurrencies?.map((currency: Currency) => {
            if (currency.code === profile.organizationDefaultCurrency) {
              return null;
            }
            const currencyMatch = supportedCurrencies.find(
              (c) => c.code === currency.code
            );
            return (
              <CurrencyRow
                key={currency.code}
                currency={currency}
                currencyMatch={currencyMatch}
                defaultCurrencyCode={defaultCurrency.code}
                activateOrDisableOrgCurrency={activateOrDisableOrgCurrency}
                updateExchangeRate={updateExchangeRate}
              />
            );
          })}
        </>
      )}
    </Card>
  );
};

const CurrencyRow = ({
  currency,
  currencyMatch,
  defaultCurrencyCode,
  activateOrDisableOrgCurrency,
  updateExchangeRate,
}: {
  activateOrDisableOrgCurrency?: (currency: string, checked: boolean) => void;
  currency: Currency;
  currencyMatch?: {
    code: string;
    description: string;
    display: string;
  };
  defaultCurrencyCode: string;
  updateExchangeRate?: (currency: string, value: number) => void;
}) => {
  return (
    <div
      data-testid={`currency-row-${currency.code}`}
      key={currency.code}
      className={styles.currencyRow}
    >
      {activateOrDisableOrgCurrency ? (
        <span className={styles.checkboxWrapper}>
          <Checkbox
            name=""
            value={currency.active}
            onChange={(checked) =>
              activateOrDisableOrgCurrency(currency.code, checked)
            }
          />
        </span>
      ) : (
        <div className={styles.checkboxPlaceholder} />
      )}
      <p>{currency.code}</p>
      <p>{currencyMatch?.description}</p>
      <div className={styles.exchangeRate}>
        {defaultCurrencyCode && (
          <>
            {defaultCurrencyCode === currency.code || !updateExchangeRate ? (
              <p
                className={styles.defaultCurrencyExchangeRate}
                data-testid="default-exchange-rate"
              >
                {currency.reportExchangeRate / 100}
              </p>
            ) : (
              <NumberInput
                dataTestId={`report-exchange-rate-${currency.code}`}
                scale={2}
                value={currency.reportExchangeRate / 100}
                onBlur={(value) => {
                  if (value && currency.code) {
                    updateExchangeRate(currency.code, value * 100);
                  }
                }}
              />
            )}
            <p className={styles.defaultCurrency}> = 1 {defaultCurrencyCode}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default CurrencySettings;
