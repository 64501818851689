import React from 'react';
import {
  ErrorFetching,
  getErrorCode,
  getErrorMessage,
  isBundle,
  Loading,
  Product,
  ProductRequest,
  useToast,
  useTranslation,
} from 'common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PRODUCTS } from '../../../../core-utils/routes';
import {
  useDeleteProduct,
  useProduct,
  useProductStats,
  useUpdateProduct,
  useVersionProduct,
} from '../../../../services/api/products/products';
import { getUserFacingMessage } from '../../../../services/utilities';
import ProductForm from './ProductForm';

const EditProductPage: React.FC = () => {
  const { id: productId } = useParams<{ id: string }>();
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const showToast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  if (!productId) {
    return <ErrorFetching />;
  }

  const {
    data: product,
    isPending: isProductLoading,
    isError: isErrorLoadingProduct,
  } = useProduct(productId);

  const label = isBundle(product) ? 'Bundle' : 'Product';

  const {
    data: stats,
    isPending: isStatsLoading,
    isError: isErrorLoadingStats,
  } = useProductStats(productId);

  const handleEditSuccess = (savedProduct: Product) => {
    showToast.info(tk(`${label} has been successfully updated`));
    if (
      productId !== savedProduct.id &&
      location.pathname === `/products/${productId}`
    ) {
      navigate(`/products/${savedProduct.id}`, { replace: true });
    }
  };

  const handleDeleteError = (error: unknown) => {
    const responseErrorMessage = getErrorMessage(error);
    const productUsedElsewhere: boolean =
      !!responseErrorMessage && responseErrorMessage.includes('foreign');
    const toastMessage = productUsedElsewhere
      ? `${label} is currently being used elsewhere. Unable to delete product.`
      : `Failed to delete ${label.toLowerCase()}. Please try again.`;

    showToast.error(toastMessage, 5);
  };

  const handleDeleteSuccess = () => {
    showToast.info(`${label} has been successfully deleted.`);
    navigate(PRODUCTS);
  };

  const handleUpdateError = (err: unknown) => {
    const errorCode = getErrorCode(err);
    if (errorCode?.startsWith('PRODUCT_ACTIVATION_MISSING_REQUIRED_')) {
      showToast.error(
        `${label} is missing a required price. Unable to activate.`
      );
    } else {
      const errorMessage =
        getUserFacingMessage(err) ||
        `${label} could not be updated, please try again.`;
      showToast.error(
        `Error saving ${label.toLowerCase()}: ` + errorMessage,
        5
      );
    }
  };

  const { mutate: deleteProduct, isPending: isDeletingProduct } =
    useDeleteProduct(handleDeleteSuccess, handleDeleteError, queryClient);

  const { mutate: updateProduct, isPending: isUpdatingProduct } =
    useUpdateProduct(
      product?.id || '',
      handleEditSuccess,
      handleUpdateError,
      queryClient
    );

  const { mutate: versionProduct, isPending: isVersioningProduct } =
    useVersionProduct(
      product?.id || '',
      handleEditSuccess,
      handleUpdateError,
      queryClient
    );

  if (isProductLoading || isStatsLoading) {
    return <Loading />;
  }

  if (isErrorLoadingProduct || isErrorLoadingStats) {
    return <ErrorFetching />;
  }

  const saveProduct = (newVersion: boolean, productUpdate: ProductRequest) => {
    if (newVersion) {
      versionProduct(productUpdate);
    } else {
      updateProduct(productUpdate);
    }
  };

  return (
    <ProductForm
      onClickSave={saveProduct}
      onClickDelete={() => {
        if (product.id) deleteProduct(product.id);
      }}
      productId={product.id}
      productBeingEdited={product}
      stats={stats}
      isProcessing={
        isUpdatingProduct || isVersioningProduct || isDeletingProduct
      }
    />
  );
};

export default EditProductPage;
