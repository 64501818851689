import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DropdownButton,
  getIconCell,
  getOrDefault,
  ItemIcon,
  NavigationIcon,
  PopOutMenuOption,
  ProductSummary,
  SimpleCell,
  SimpleTable,
  useFlags,
} from 'common';
import { useVisibleProducts } from '../../../../services/api/products/products';
import StatusLabel from '../../../../components/StatusLabel';
import Page from '../../../../components/Page';
import NewBundle from './NewBundle';
import NewProduct from './NewProduct';
import { getProductPricingSummary } from './productUtils';
import ProductOptions from './ProductOptions';
import ActivityCell from '../../../../components/ActivityCell';
import { getProductSearchField } from './ProductSelect';

const ProductListPage = () => {
  const { productBundles } = useFlags();
  const navigate = useNavigate();
  const [isAddingNewProduct, setIsAddingNewProduct] = useState<boolean>(false);
  const [isAddingNewBundle, setIsAddingNewBundle] = useState<boolean>(false);
  const { data, isLoading } = useVisibleProducts();
  const products = data || [];

  const handleRowClick = (row: ProductSummary) => {
    navigate(`/products/${row.id}`);
  };

  const cells: SimpleCell<ProductSummary>[] = [
    {
      ...getIconCell(ItemIcon.Product),
      renderCell: (product: ProductSummary) =>
        product.productType === 'single' ? (
          <ItemIcon.Product />
        ) : (
          <NavigationIcon.Bundles />
        ),
      searchable: true,
      searchValue: getProductSearchField,
    },
    {
      key: 'name',
      width: '30%',
      headerLabel: 'Name',
      sortable: true,
      allowWrap: true,
      renderCell: (product: ProductSummary) => (
        <>
          <div className="font-semibold truncate">
            {getOrDefault(product.name)}
          </div>
          {product.internalName && (
            <div className="text-xs text-slate-darker truncate">
              {getOrDefault(product.internalName)}
            </div>
          )}
        </>
      ),
      sortValue: (product: ProductSummary) => product.name,
    },
    {
      key: 'pricing',
      headerLabel: 'Pricing',
      width: '30%',
      allowWrap: true,
      renderCell: (product: ProductSummary) => {
        return getProductPricingSummary(product);
      },
    },
    {
      key: 'updatedAt',
      headerLabel: 'Last activity',
      width: '150px',
      overflowCell: true,
      renderCell: (product: ProductSummary) => (
        <ActivityCell
          timeAgo={product.updatedAt}
          person={product.user || product.updatedBy}
        />
      ),
    },
    {
      key: 'subscriptionCount',
      headerLabel: 'Subscriptions',
      width: '120px',
      sortable: true,
      sortValue: (product: ProductSummary) => product.subscriptionCount || 0,
      renderCell: (product: ProductSummary) => product.subscriptionCount || 0,
    },
    {
      key: 'status',
      headerLabel: 'Status',
      width: '120px',
      sortable: true,
      renderCell: (product: ProductSummary) => (
        <StatusLabel feature="product" status={product.status} />
      ),
    },
    {
      key: 'currency',
      headerLabel: 'Currencies',
      width: '20%',
      allowWrap: true,
      renderCell: (product: ProductSummary) => {
        if (product.currencies && product.currencies.length > 0) {
          const currencyString = product.currencies.slice(0, 4).join(', ');
          const moreSuffix =
            product.currencies.length > 4
              ? `, +${product.currencies.length - 4} more`
              : '';

          return `${currencyString}${moreSuffix}`;
        }

        return 'None';
      },
    },
    {
      key: 'options',
      headerLabel: 'Options',
      clickable: true,
      align: 'center',
      width: 64,
      renderCell: (product: ProductSummary) =>
        product.id ? <ProductOptions productId={product.id} /> : null,
    },
  ];

  const getDropdownOptions = () => {
    if (productBundles) {
      return (
        <div className="p-2">
          <PopOutMenuOption
            dataTestId="add-bundle-button"
            icon={NavigationIcon.Bundles}
            onClick={() => setIsAddingNewBundle(true)}
            title="Add bundle"
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Page
      leftWidget="Products"
      rightWidget={
        <DropdownButton
          dataTestId="add-product-button"
          label="Add product"
          onClick={() => setIsAddingNewProduct(true)}
          options={getDropdownOptions()}
          popoverProps={{ overlayClassName: 'template-popover' }}
        />
      }
    >
      <NewProduct
        isOpen={isAddingNewProduct}
        onClose={() => setIsAddingNewProduct(false)}
      />
      {productBundles && (
        <NewBundle
          isOpen={isAddingNewBundle}
          onClose={() => setIsAddingNewBundle(false)}
        />
      )}

      <SimpleTable
        cells={cells}
        rows={products}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        zeroStateMessage="There are currently no products."
      />
    </Page>
  );
};

export default ProductListPage;
