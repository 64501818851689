import React from 'react';
import styles from './TimeBasedPricing.module.scss';

const TimeBasedScheduleChart = ({
  percentageWidth,
}: {
  percentageWidth: number;
}) => {
  return (
    <div className={styles.scheduleChart}>
      <div
        style={{ width: `${percentageWidth > 100 ? 100 : percentageWidth}%` }}
        className={styles.scheduleChartBar}
      />
    </div>
  );
};

export default TimeBasedScheduleChart;
