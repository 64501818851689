import { salesforceTabs } from './salesforceTabs';
import { ConnectorInfo, OrgConfig } from 'common';

const SALESFORCE_CONFIG: OrgConfig['configKey'] = 'salesforceConfig';
const SALESFORCE: string = 'Salesforce';
const SALESFORCE_SOURCE: ConnectorInfo['source'] = 'salesforce';

const SALESFORCE_INFO = {
  configType: SALESFORCE_CONFIG,
  source: SALESFORCE_SOURCE,
  name: SALESFORCE,
  showSaveButton: true,
};

export { salesforceTabs, SALESFORCE_CONFIG, SALESFORCE, SALESFORCE_INFO };
