import React from 'react';

import { ConfirmModal } from 'common';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const SubscriptionChangeModal = ({
  isOpen,
  onClose,
  onConfirm,
}: Props) => {
  return (
    <ConfirmModal
      header="Confirm subscription change"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmText="Accept change(s)"
    >
      <div>
        <p>
          You're about to modify a subscription without obtaining the buyer's
          acceptance or signature. Please confirm your authority to proceed with
          these changes.
        </p>
        <br />
        <p>
          Should you choose to obtain the buyer's acceptance or signature,
          select 'Cancel' and then 'Select Recipients'.
        </p>
      </div>
    </ConfirmModal>
  );
};
