import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  useArchiveProposal,
  useRestoreProposal,
} from '../../../../services/proposal';
import { PROPOSALS } from '../../../../core-utils/routes';
import { isProposalActive } from '../proposalUtilities';

import {
  ConfirmModal,
  Icon,
  isTemplateProposalType,
  PopOutMenuOption,
  Proposal,
  ProposalSummary,
  useToast,
} from 'common';

interface Props {
  proposal: Proposal | ProposalSummary;
}

const ProposalArchiveOption: React.FC<Props> = ({ proposal }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const showToast = useToast();
  const { status } = proposal;
  const isArchived: boolean = !!proposal.archived;

  const handleError = () => {
    showToast.error(
      `Proposal ${isArchived ? 'restoring' : 'archiving'} failed`
    );
  };

  const handleSuccess = () => {
    showToast.info(`Proposal ${isArchived ? 'restored' : 'archived'} `);
    navigate(PROPOSALS);
  };

  const { mutate: archiveProposal } = useArchiveProposal(
    proposal.id,
    handleSuccess,
    handleError,
    queryClient
  );

  const { mutate: restoreProposal } = useRestoreProposal(
    proposal.id,
    handleSuccess,
    handleError,
    queryClient
  );

  return (
    <>
      <PopOutMenuOption
        dataTestId="proposal-editor-archive-button"
        icon={isArchived ? Icon.Unlocked : Icon.Locked}
        onClick={() => (isArchived ? restoreProposal() : setShowConfirm(true))}
        title={isArchived ? 'Restore' : 'Archive'}
        isDisabled={isProposalActive(status) && !isArchived}
      />

      <ConfirmModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => archiveProposal()}
      >
        <div>
          Are you sure you want to archive this{' '}
          {isTemplateProposalType(proposal) ? 'template' : 'proposal'}?
        </div>
      </ConfirmModal>
    </>
  );
};

export default ProposalArchiveOption;
