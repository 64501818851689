import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Icon,
  PopOutMenuOption,
  Proposal,
  ProposalSummary,
  useToast,
} from 'common';
import { useUpdateProposalPricing } from 'app/src/services/proposal';

interface Props {
  proposal: Proposal | ProposalSummary;
}

const ProposalUpdatePricingButton: React.FC<Props> = ({ proposal }) => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { id } = proposal;

  const handleUpdateError = () => {
    showToast.error('Pricing update failed');
  };

  const handleUpdateSuccess = () => {
    showToast.info('Pricing updated');
  };

  const { mutate: updatePricing } = useUpdateProposalPricing(
    id,
    handleUpdateSuccess,
    handleUpdateError,
    queryClient
  );

  return (
    <PopOutMenuOption
      dataTestId="proposal-editor-update-pricing-button"
      icon={Icon.ArrowUp}
      onClick={() => updatePricing()}
      title="Update Pricing"
    />
  );
};

export default ProposalUpdatePricingButton;
