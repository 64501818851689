import React from 'react';
import ExternalDealsPage from '../ExternalDealsPage';
import { NavigationIcon } from 'common';

const OpportunitiesPage = () => {
  return (
    <ExternalDealsPage
      sourceType="salesforce"
      dealLabel="opportunity"
      icon={NavigationIcon.SalesforceOpportunities}
      dealQueryParamName="opportunityId"
    />
  );
};

export default OpportunitiesPage;
