import React, { SVGProps } from 'react';
import {
  CardHeader,
  ConnectorTestResult,
  Icon,
  LoadingSpinner,
  useTranslation,
} from 'common';
import styles from './LogoCardHeader.module.scss';

interface Props {
  children?: React.ReactNode;
  logo?: React.ComponentType<SVGProps<SVGSVGElement>>;
  LogoElement?: React.ReactElement;
  name?: string;
  connectionComponent?: React.ReactNode;
  connectionDetailsComponent?: React.ReactNode;
  connectionResult?: ConnectorTestResult | undefined;
  isLoading?: boolean;
}

const LogoCardHeader = ({
  logo: LogoComponent,
  LogoElement,
  name,
  children,
  connectionResult,
  isLoading,
  connectionComponent,
  connectionDetailsComponent,
}: Props) => {
  const { tk } = useTranslation();

  const issueResults = () => {
    return connectionResult?.issues?.map((issue) => {
      return (
        <div
          key={issue.issueType}
          className="text-lg text-red-darker error-message flex"
        >
          <Icon.Exclamationmark width="32" height="32" />
          {tk(`issues.${issue.sourceType}.${issue.issueType}`)}
        </div>
      );
    });
  };

  return (
    <CardHeader
      name={
        <div className={styles.logoHeader}>
          {LogoComponent && <LogoComponent height={50} />}
          {LogoElement}
          {name && tk(name)}
          {connectionComponent && (
            <>
              &nbsp; &nbsp;
              {isLoading ? (
                <LoadingSpinner size={24} />
              ) : (
                <>
                  {connectionComponent}
                  {(connectionResult?.issues?.length ?? 0) > 0 &&
                    issueResults()}
                </>
              )}
            </>
          )}
        </div>
      }
    >
      <div className={styles.lastSycedStatusContainer}>
        {connectionDetailsComponent}
        {children}
      </div>
    </CardHeader>
  );
};

export default LogoCardHeader;
