import { Icon, ExternalSource, SyncEvent, ToolTip, useFlags } from 'common';
import React from 'react';
import CloseSVG from '../../assets/integration-logos/CloseSVG';
import HubspotSVG from '../../assets/integration-logos/hubspotSVG';
import SalesforceLogoSVG from '../../assets/integration-logos/salesforceLogoSVG';
import styles from './ExternalLink.module.scss';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';
import QuickbooksSVG from '../../assets/integration-logos/QuickbooksSVG';

interface Props {
  externalSource?: ExternalSource;
  showId?: boolean;
  lastSyncEvent?: SyncEvent;
  customDisplayText?: string;
  logoSize?: number;
}

const getLink = (data: ExternalSource) => {
  if (data?.sourceLink) {
    return data?.sourceLink;
  }
  if (data && data.sourceType === 'quickbooks') {
    return !ENVIRONMENT_CONFIG.isProduction
      ? 'https://app.sandbox.qbo.intuit.com/app/invoice?txnId=' + data.sourceId
      : 'https://app.qbo.intuit.com/app/invoice?txnId=' + data.sourceId;
  }
  return null;
};

const ExternalLink: React.FC<Props> = ({
  externalSource,
  showId = false,
  lastSyncEvent,
  customDisplayText,
  logoSize = 24,
}) => {
  const { salesforceConnector, hubspotConnector, closeConnector, quickbooks } =
    useFlags();

  if (
    !salesforceConnector &&
    !hubspotConnector &&
    !closeConnector &&
    !quickbooks
  ) {
    return null;
  }

  if (!externalSource && !lastSyncEvent) {
    return null;
  }

  const link = getLink(externalSource!);

  const getLinkId = () => {
    if (externalSource?.sourceType === 'close' && externalSource.sourceId) {
      // the close id is super long so just take the last 12 characters
      return externalSource.sourceId.slice(-12);
    }
    return externalSource?.sourceId;
  };

  return (
    <div className={styles.container}>
      <a
        href={link ?? undefined}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        {showId && <div>{getLinkId()}</div>}
        {externalSource?.sourceType === 'salesforce' && (
          <SalesforceLogoSVG height={logoSize} width={logoSize} />
        )}
        {externalSource?.sourceType === 'hubspot' && (
          <HubspotSVG height={logoSize} width={logoSize} />
        )}
        {externalSource?.sourceType === 'close' && (
          <CloseSVG height={logoSize} width={logoSize} />
        )}
        {(externalSource?.sourceType === 'quickbooks' || lastSyncEvent) && (
          <div>
            <div>
              <QuickbooksSVG height={logoSize} width={logoSize} />
            </div>
            <div>
              {lastSyncEvent?.error && (
                <ToolTip
                  title={lastSyncEvent.error}
                  customStyling={{
                    width: '100%',
                  }}
                >
                  <Icon.Alert width={27} className={styles.icon} />
                </ToolTip>
              )}
            </div>
          </div>
        )}
        {!showId && customDisplayText && (
          <div className={styles.externalLink}>{customDisplayText}</div>
        )}
      </a>
    </div>
  );
};

export default ExternalLink;
