import React from 'react';
import {
  Checkbox,
  CreateProposalRequest,
  DatePicker,
  formatDate,
  formatDateOrDefault,
  getToday,
  Input,
  InputError,
  isChangeProposalType,
  isRenewalProposalType,
  Nullable,
  parseDateOrNow,
  Proposal,
  useTranslation,
} from 'common';
import { isAfter } from 'date-fns';

interface Props {
  isAccepted: boolean;
  isDisabled: boolean;
  onCoreChange: (
    updateProposalRequest: Nullable<CreateProposalRequest>
  ) => void;
  proposal: Proposal;
}

const EffectiveDate = ({
  proposal,
  onCoreChange,
  isAccepted,
  isDisabled,
}: Props) => {
  const { tk } = useTranslation();
  const isRenewalProposal = isRenewalProposalType(proposal);
  const isChangeProposal = isChangeProposalType(proposal);

  const onChange = (
    field: keyof CreateProposalRequest,
    value: Nullable<CreateProposalRequest>[keyof CreateProposalRequest],
    isValid: boolean
  ) => {
    if (isValid) {
      onCoreChange({
        [field]: value,
      });
    }
  };

  const validateStartDate = (value?: string): string | null => {
    if (value === undefined) {
      return null;
    }
    const newDate = new Date(value);
    const earliestDate = new Date();
    earliestDate.setFullYear(earliestDate.getFullYear() - 5);
    const latestDate = new Date();
    latestDate.setFullYear(latestDate.getFullYear() + 5);

    if (newDate < earliestDate || newDate > latestDate) {
      return 'Effective date needs to be within 5 years of today';
    }
    return null;
  };

  const handleStartDateChange = (value: string): void => {
    if (value === '') {
      onChange('startDate', null, true);
      return;
    }
    onChange('startDate', value, true);
  };

  const getStartingDate = () => {
    const today = getToday();
    return isChangeProposal &&
      isAfter(
        parseDateOrNow(proposal.effectiveStartDate),
        parseDateOrNow(today)
      )
      ? proposal.effectiveStartDate
      : today;
  };
  const handleToggleStartDateIsSigningDate = (
    startDateIsSigningDate: boolean
  ) => {
    onCoreChange({
      startDate: startDateIsSigningDate ? null : getStartingDate(),
      options: {
        ...proposal.options,
        startDateIsSigningDate,
      },
    });
  };

  const startDateError = validateStartDate(proposal.startDate);

  const ends = (
    <div className="opacity-50">
      {`${tk('Ends')}: ${proposal.endDate && formatDate(proposal.endDate)}`}
    </div>
  );

  if (isAccepted) {
    return (
      <>
        <Input
          value={tk(formatDateOrDefault(proposal.effectiveStartDate))}
          isDisabled
        />
        {ends}
      </>
    );
  }

  if (isRenewalProposal) {
    return (
      <>
        <DatePicker
          dataTestId="proposal-effective-date"
          isDisabled
          onChange={handleStartDateChange}
          value={proposal.effectiveStartDate}
        />
        {proposal.endDate && (
          <div className="opacity-50">
            {tk(`Ends: ${formatDate(proposal.endDate)}`)}
          </div>
        )}
      </>
    );
  }

  const startDateIsSigningDate = (
    <Checkbox
      value={proposal.options?.startDateIsSigningDate}
      isDisabled={
        isDisabled ||
        isAfter(
          parseDateOrNow(proposal.effectiveStartDate),
          parseDateOrNow(getToday())
        )
      }
      label="Start on signing date"
      name="startDateIsSigningDate"
      onChange={handleToggleStartDateIsSigningDate}
    />
  );

  if (!proposal.options?.startDateIsSigningDate) {
    return (
      <>
        <DatePicker
          dataTestId="proposal-effective-date"
          isDisabled={isDisabled}
          onChange={handleStartDateChange}
          value={proposal.startDate}
        />
        {startDateError && !isDisabled ? (
          <InputError errorToShow={startDateError} />
        ) : (
          ends
        )}
        {startDateIsSigningDate}
      </>
    );
  }

  return (
    <>
      {ends}
      {startDateIsSigningDate}
    </>
  );
};

export default EffectiveDate;
