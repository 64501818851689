import React from 'react';
import {
  arrayTop,
  isArrayNonEmpty,
  isChangeProposalType,
  PaymentTerm,
  Proposal,
  ProposalLabel,
  Select,
  useTranslation,
} from 'common';
import { usePaymentTerms } from '../../../services/paymentTerms';

import { sortPaymentTerms } from '../../Settings/PaymentTerms';
import './paymentTerms.scss';

interface Props {
  isDisabled?: boolean;
  proposal: Proposal;
  selectPaymentTerm?: (paymentTerm: PaymentTerm['termType'] | null) => void;
}

const PaymentTermSelect: React.FC<Props> = ({
  proposal,
  selectPaymentTerm,
  isDisabled,
}) => {
  const { tk } = useTranslation();
  const paymentTermType = proposal.paymentTerm;
  const isProposalTypeChange = isChangeProposalType(proposal);
  const isProposalDraft = proposal.status === 'draft';

  const { data: paymentTermList, isLoading: paymentTermsLoading } =
    usePaymentTerms();

  const onChangePaymentTerm = (termType?: string) => {
    if (!termType) return;
    selectPaymentTerm && selectPaymentTerm(termType as PaymentTerm['termType']);
  };

  const termOptions = () => {
    return paymentTermList
      ? sortPaymentTerms(paymentTermList, true).map((record) => ({
          name: tk(`term_${record.name}_name`, record.name),
          value: record.termType,
        }))
      : [];
  };

  const defaultPaymentTerm = () => {
    if (!isArrayNonEmpty(paymentTermList)) {
      return null;
    }

    const filteredTerms = paymentTermList.filter(
      (t) => t.termType === paymentTermType
    );

    const filteredTerm = arrayTop(filteredTerms);
    if (!filteredTerm && paymentTermType) {
      return null;
    }

    const term = filteredTerm || paymentTermList[0];
    return <div>{tk(`term_${term.name}_description`, term.description)}</div>;
  };

  return (
    !paymentTermsLoading &&
    termOptions().length > 0 && (
      <div className="payment-terms-wrapper pb-4 flex-col">
        <ProposalLabel name={tk('Payment terms')} />
        {termOptions().length <= 1 && defaultPaymentTerm()}
        {termOptions().length > 1 && (
          <div className="w-[160px]">
            <Select
              onChange={(value) => onChangePaymentTerm(value)}
              value={String(paymentTermType)}
              placeholder="Select payment terms"
              dataTestId="proposalPaymentTerm"
              options={termOptions()}
              isDisabled={
                !isProposalDraft || isProposalTypeChange || isDisabled
              }
            />
          </div>
        )}
      </div>
    )
  );
};

export default PaymentTermSelect;
