import React from 'react';
import {
  AddressDisplay,
  getPersonFullName,
  Icon,
  Loading,
  ProposalLabel,
} from 'common';
import ProposalCard from './ProposalCard/ProposalCard';
import { useContract } from '../../../services/contract';

interface Props {
  contractId: string;
  isDisabled: boolean;
}

const BillingInfo: React.FC<Props> = ({ contractId, isDisabled }) => {
  const { data: contract, isLoading } = useContract(contractId);

  if (isLoading || !contract) {
    return <Loading />;
  }

  const address =
    contract.billTo?.billingAddress || contract.buyer.billingAddress;
  const contact =
    contract.billTo?.billingContact || contract.buyer.billingContact;

  return (
    <ProposalCard isDisabled={isDisabled}>
      <ProposalLabel name="Billing details" />
      <div className="flex gap-1">
        <Icon.Company />
        <div className="pt-2">
          {contract.billTo?.name || contract.buyer.name}
        </div>
      </div>
      <div className="flex gap-1">
        {address && (
          <>
            <Icon.Location />
            <div className="pt-2">
              <AddressDisplay address={address} />
            </div>
          </>
        )}
      </div>
      <div className="flex gap-1">
        <Icon.UserCircle />
        {contact && (
          <div className="pt-2">
            {getPersonFullName(contact)}, {contact.email}
          </div>
        )}
      </div>
    </ProposalCard>
  );
};

export default BillingInfo;
