import React from 'react';
import {
  arrayTop,
  AutoSize,
  formatHumanReadable,
  Product,
  ProductSummary,
  Proposal,
  SearchSelect,
} from 'common';
import ProductOption from '../../../Proposals/ProposalEditor/Products/ProductOption';

import styles from './ProductSelect.module.scss';

interface Props
  extends Partial<React.ComponentProps<typeof SearchSelect<ProductSummary>>> {
  onSelect: (product: ProductSummary) => void;
  products: ProductSummary[];
  proposal?: Proposal;
}

export const getProductSearchField = (product: Product | ProductSummary) =>
  [
    product.name,
    product.internalName,
    formatHumanReadable(product.recurrence),
    formatHumanReadable(product.productType),
  ].join(' ');

const ProductSelect: React.FC<Props> = ({
  products,
  onSelect,
  proposal,
  ...passDownProps
}) => {
  const longestProduct = products.reduce((longest, product) => {
    if (!longest || !longest.name) {
      return product;
    }

    if (!product.name) {
      return longest;
    }

    if (product.name.length > longest.name.length) {
      return product;
    }

    return longest;
  }, arrayTop(products));

  return (
    <AutoSize
      lengthPlaceholder={
        longestProduct && (
          <div className={styles.lengthPlaceholderItem}>
            <ProductOption option={longestProduct} proposal={proposal} />
          </div>
        )
      }
    >
      <SearchSelect<ProductSummary>
        {...passDownProps}
        displayField="name"
        searchField={getProductSearchField}
        displayFieldElement={(option) => (
          <ProductOption option={option} proposal={proposal} />
        )}
        onChange={onSelect}
        options={products}
        placeholder="Add product"
      />
    </AutoSize>
  );
};

export default ProductSelect;
