import React from 'react';
import { Loading, Proposal } from 'common';
import SignerInfoSection from './SignerInfoSection';
import { useDocuSignAccount } from '../../../../services/api/docusign/docusign';
import ConfigureDocuSign from './ConfigureDocuSign';
import SignWithDocuSign from './SignWithDocuSign';

interface Props {
  isDisabled?: boolean;
  proposalId: string;
  requiresSigning?: boolean;
  setRequiresSigning: (required: boolean) => void;
  signingDocument: Proposal['signingDocument'];
}

const DocuSignSection: React.FC<Props> = ({
  proposalId,
  isDisabled = false,
  requiresSigning,
  setRequiresSigning,
  signingDocument,
}) => {
  const {
    data: docusignAccount,
    isLoading,
    isError: docusignError,
  } = useDocuSignAccount();

  if (isLoading) {
    return <Loading />;
  }

  if (!docusignAccount || docusignError) {
    return <ConfigureDocuSign isDisabled={isDisabled} />;
  }

  if (signingDocument?.signedAt) {
    return (
      <SignerInfoSection
        proposalId={proposalId}
        signingDocument={signingDocument}
      />
    );
  }

  return (
    <SignWithDocuSign
      isDisabled={isDisabled}
      requiresSigning={requiresSigning}
      setRequiresSigning={setRequiresSigning}
    />
  );
};

export default DocuSignSection;
