import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIPTIONS } from '../../../../core-utils/routes';
import {
  useArchiveSubscription,
  useRestoreSubscription,
} from '../../../../services/contract';
import {
  ConfirmModal,
  Contract,
  ContractSummary,
  Icon,
  PopOutMenuOption,
  useToast,
} from 'common';

interface Props {
  contract: Contract | ContractSummary;
}

const SubscriptionArchiveOption: React.FC<Props> = ({ contract }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const showToast = useToast();
  const isArchived: boolean = !!contract.archived;

  const handleError = () => {
    showToast.error(
      `Subscription ${isArchived ? 'restoring' : 'archiving'} failed `
    );
  };

  const handleSuccess = () => {
    showToast.info(`Subscription ${isArchived ? 'restored' : 'archived'} `);
    navigate(SUBSCRIPTIONS);
  };

  const { mutate: archive } = useArchiveSubscription(
    contract.id,
    handleSuccess,
    handleError,
    queryClient
  );

  const { mutate: restore } = useRestoreSubscription(
    contract.id,
    handleSuccess,
    handleError,
    queryClient
  );

  return (
    <>
      <PopOutMenuOption
        dataTestId="subscription-archive-button"
        icon={isArchived ? Icon.Unlocked : Icon.Locked}
        onClick={() => (isArchived ? restore() : setShowConfirm(true))}
        title={isArchived ? 'Restore' : 'Archive'}
        isDisabled={contract.status === 'active'}
      />

      <ConfirmModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => archive()}
      >
        <div>Are you sure you want to archive this subscription?</div>
      </ConfirmModal>
    </>
  );
};

export default SubscriptionArchiveOption;
