import { QueryClient, useMutation } from '@tanstack/react-query';
import { PaymentUpdateRequest } from 'common';
import { apiClient } from './httpClients/app';
import { ALL_INVOICES_KEY } from './api/invoices/invoiceList';

const UPDATE_PAYMENT_KEY = 'PAYMENT_UPDATE_KEY';

export const useUpdatePayment = (
  id: string,
  qc: QueryClient,
  onSuccess: () => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationKey: [UPDATE_PAYMENT_KEY, id],
    mutationFn: async (data: PaymentUpdateRequest) =>
      await apiClient.updatePayment(id, data),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [ALL_INVOICES_KEY + '/' + id],
      });
      await qc.invalidateQueries({
        queryKey: [ALL_INVOICES_KEY],
      });
    },
  });
