import {
  Icon,
  Item,
  ItemSchedule,
  useRowItem,
  useRowItemSchedule,
  PopOutMenuOption,
  ModeTypes,
} from 'common';
import React from 'react';

export const CustomizePriceAction = ({
  onShowPriceModalClick,
}: {
  onShowPriceModalClick: (
    selectedSchedule: ItemSchedule,
    item: Item,
    initialView?: ModeTypes
  ) => void;
}) => {
  const schedule = useRowItemSchedule();
  const item = useRowItem();

  return (
    <PopOutMenuOption
      icon={Icon.CurrencyUsd}
      onClick={() => onShowPriceModalClick(schedule, item, 'edit')}
      title="Customize price"
    />
  );
};
