import React, { useEffect, useState } from 'react';
import {
  useCreateSignatureData,
  useDeleteSignatureData,
  useFindByUserSignatureData,
  useUpdateSignatureData,
} from '../../../../services/signature';

import {
  alphaNumericValidator,
  FormModal,
  Input,
  Signature,
  useFormValidation,
  useToast,
} from 'common';
import { useQueryClient } from '@tanstack/react-query';
import Label from '../../../../components/Label';
import Pad from './Pad';

const getSigningDataDraft = () => {
  return {
    name: '',
    title: '',
    signatureImageData: '',
  };
};

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  userId: string;
}

const SellerSignatureModal: React.FC<Props> = ({
  userId,
  closeModal,
  isOpen,
}: Props) => {
  const {
    data: signatureData,
    isFetching,
    isError,
  } = useFindByUserSignatureData(userId);

  const [signingDataDraft, setSigningDataDraft] = useState<Signature>(
    getSigningDataDraft()
  );
  const isNewSignature = signatureData === null;

  const showToast = useToast();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (signatureData === undefined) {
      return;
    }
    if (signatureData === null) {
      setSigningDataDraft(getSigningDataDraft());
    } else {
      setSigningDataDraft(signatureData);
    }
  }, [signatureData]);

  const { mutate: createSignatureData } = useCreateSignatureData(
    () => {
      showToast.info('Successfully saved signature.');
      closeModal();
    },
    () => {
      showToast.error('Failed to save signature. Please try again later.');
    },
    queryClient
  );

  const { mutate: updateSignatureData } = useUpdateSignatureData(
    () => {
      showToast.info('Successfully updated signature.');
      closeModal();
    },
    () => {
      showToast.error('Failed to save signature. Please try again later.');
    },
    queryClient
  );

  const { mutate: deleteSignatureData } = useDeleteSignatureData(
    () => {
      showToast.info('Successfully deleted signature.');
    },
    () => {
      showToast.error('Failed to delete signature. Please try again later.');
    },
    queryClient
  );

  const save = () => {
    if (isNewSignature) {
      createSignatureData({
        userId,
        signingData: signingDataDraft,
      });
    } else {
      if (signingDataDraft.title === '') {
        // TODO: could be better, more generic form/input logic to delete values,
        // doing this here for now since it's the only nullable field in this form
        // also, Brian said to make this null, but the Signature type doesn't
        // support it, so undefined for now
        signingDataDraft.title = undefined;
      }
      updateSignatureData({
        userId,
        signingData: signingDataDraft,
      });
    }
  };

  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<Signature>(
      [
        {
          fieldName: 'name',
          validator: alphaNumericValidator,
          isRequired: true,
          humanReadableName: 'Name',
        },
        {
          fieldName: 'title',
          validator: alphaNumericValidator,
          isRequired: true,
          humanReadableName: 'Title',
        },
        {
          fieldName: 'signatureImageData',
          isRequired: true,
          humanReadableName: 'Signature',
        },
      ],
      signingDataDraft
    );

  useEffect(() => {
    if (isOpen) {
      resetUIState();
    }
  }, [isOpen]);

  const saveIfValid = () => {
    if (isFormValid) {
      save();
    }
  };

  return (
    <FormModal
      width={800}
      isOpen={isOpen}
      entityTypeName="seller signature"
      isNewEntity={isNewSignature}
      onDelete={() => {
        deleteSignatureData(userId);
        closeModal();
      }}
      onClose={closeModal}
      onSubmit={save}
      isSubmitDisabled={!isFormValid}
    >
      {isFetching ? (
        <div>Loading</div>
      ) : isError ? (
        <div>Error</div>
      ) : (
        <div className="grid place-content-center">
          <div className="w-[500px]">
            <div className="grid w-full grid-cols-2 gap-2 mb-4">
              <div className="min-h-[110px]">
                <Label name="Name" />
                <Input
                  id="signatureName"
                  className="h-8"
                  placeholder="Name"
                  value={signingDataDraft.name}
                  onChange={(value) =>
                    setSigningDataDraft({ ...signingDataDraft, name: value })
                  }
                  onBlur={() => setHasVisitedField('name')}
                  onEnter={saveIfValid}
                  errorToShow={getErrorToShow('name')}
                />
              </div>
              <div>
                <Label name="Title" />
                <Input
                  id="signatureTitle"
                  className="h-8"
                  placeholder="Title"
                  value={signingDataDraft.title}
                  onChange={(value) =>
                    setSigningDataDraft({ ...signingDataDraft, title: value })
                  }
                  onBlur={() => setHasVisitedField('title')}
                  onEnter={saveIfValid}
                  errorToShow={getErrorToShow('title')}
                />
              </div>
            </div>
            <Label name="Signature" />
            <Pad
              signatureImageData={
                signingDataDraft.signatureImageData === ''
                  ? undefined
                  : signingDataDraft.signatureImageData
              }
              onSignatureChange={(signatureImageData: string): void => {
                setSigningDataDraft({
                  ...signingDataDraft,
                  signatureImageData,
                });
              }}
            />
          </div>
        </div>
      )}
    </FormModal>
  );
};

export default SellerSignatureModal;
