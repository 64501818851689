import React, { useEffect, useState } from 'react';
import CustomerFormDrawer from '../../../Sales/Customers/CustomerFormDrawer';
import { useCustomers } from '../../../../services/customer';
import { Customer, ErrorFetching, log, noop, QuerySelect } from 'common';

interface Props {
  isDisabled?: boolean;
  onSelectCustomer: (customer: Customer | undefined) => void;
  selectedCustomer?: Customer;
}

const CustomerSelect: React.FC<Props> = ({
  isDisabled,
  selectedCustomer,
  onSelectCustomer,
}) => {
  const [customerFormIsOpen, setCustomerFormIsOpen] = useState<boolean>(false);
  const [customerBeingEdited, setCustomerBeingEdited] = useState<
    Customer | undefined
  >(undefined);

  const [customerStarterName, setCustomerStarterName] = useState<
    string | undefined
  >(undefined);
  const [search, setSearch] = useState<string>('');
  const {
    data: customers,
    isError,
    refetch,
    isFetching,
  } = useCustomers(search);

  useEffect(() => {
    refetch().then(noop, log.error);
  }, [search]);

  if (isError) {
    return <ErrorFetching />;
  }

  const onClearSelection = selectedCustomer
    ? () => onSelectCustomer(undefined)
    : undefined;

  const customersOrEmpty =
    customers?.sort((a, b) => {
      if (!a.name || !b.name) {
        return -1;
      }
      return a.name.localeCompare(b.name);
    }) || [];

  const getAddNewPlaceholder = (currentlyTyped: string): string => {
    if (currentlyTyped) {
      return `Add "${currentlyTyped}"`;
    }
    return 'Add new customer';
  };

  const addNewCustomer = (currentlyTyped: string) => {
    setCustomerFormIsOpen(true);
    setCustomerStarterName(currentlyTyped);
  };

  const closeCustomerForm = () => {
    setCustomerBeingEdited(undefined);
    setCustomerFormIsOpen(false);
    setCustomerStarterName(undefined);
  };

  const handleCreateCustomer = (customer: Customer) => {
    setCustomerBeingEdited(customer);
    onSelectCustomer(customer);
  };

  const handleDeleteCustomer = () => {
    setCustomerBeingEdited(undefined);
    onSelectCustomer(undefined);
  };

  const onSelect = (customer: Customer | undefined) => {
    onSelectCustomer(customer);
  };

  return (
    <>
      <CustomerFormDrawer
        isOpen={customerFormIsOpen}
        customerBeingEdited={customerBeingEdited}
        onCreateCustomer={handleCreateCustomer}
        onCloseForm={closeCustomerForm}
        customerStarterName={customerStarterName}
        onDeleteCustomer={handleDeleteCustomer}
        isInProposalEditor
      />
      <QuerySelect<Customer>
        isSearching={isFetching}
        onSearch={(newSearch) => setSearch(newSearch)}
        isDisabled={isDisabled}
        dataTestId="customerId"
        options={customersOrEmpty}
        displayField="name"
        placeholder="Select customer"
        onChange={onSelect}
        onClearSelected={isDisabled ? undefined : onClearSelection}
        value={selectedCustomer}
        addNewPlaceHolder={getAddNewPlaceholder}
        onAddNew={addNewCustomer}
      />
    </>
  );
};

export default CustomerSelect;
