import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactGA from 'react-ga';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import { ENVIRONMENT_CONFIG } from '../config/hosts';
import {
  getCurrentVersion,
  pollForNewVersion,
} from './core-utils/helperFunctions/version';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { initI18N, log } from 'common';
import { launchDarklyContext } from './launchDarklyContext';

if (ENVIRONMENT_CONFIG.isMonitored) {
  Sentry.init({
    dsn: ENVIRONMENT_CONFIG.sentryKey,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.extraErrorDataIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    environment: ENVIRONMENT_CONFIG.env,
    release: getCurrentVersion().branch?.replace(/\//g, '-'),
  });

  pollForNewVersion();
}

log.info(getCurrentVersion());

if (ENVIRONMENT_CONFIG.isProduction) {
  ReactGA.initialize('UA-227488425-2', {
    /* debug: true, */
  });
}

initI18N().catch(log.error);

const container = document.getElementById('root');
const root = createRoot(container!);

// eslint-disable-next-line @typescript-eslint/no-floating-promises
(async () => {
  const LDProvider = await asyncWithLDProvider(launchDarklyContext);
  root.render(
    <LDProvider>
      <App />
    </LDProvider>
  );
})();
