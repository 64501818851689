import React from 'react';
import {
  BillingSchedule,
  capitalizeFirstLetter,
  Contract,
  formatDateOrDefault,
  formatDateUTC,
  formatTimeOrDefault,
  Transfer,
} from 'common';
import clsx from 'clsx';
import {
  getPaymentStatusOnlyValue,
  isOverdue,
  isPaymentBeingPaid,
  isPosted,
  paymentFailed,
  reasonCodeMessage,
} from './utils';
import { getDaysLeft } from '../../core-utils/helperFunctions/formatDate';

interface Props {
  contract: Contract;
  reference: BillingSchedule;
  transfer?: Transfer;
  usageAmount?: number;
}

const PaymentInfo: React.FC<Props> = ({
  transfer,
  reference,
  contract,
  usageAmount,
}: Props) => {
  const statusOnlyVal = getPaymentStatusOnlyValue(reference, contract) || '';
  const overdue = isOverdue(reference);
  const codeMessage = reasonCodeMessage(reference);

  const isManagedPayments = !contract.billingMethod?.paymentMethod
    ? false
    : contract.billingMethod.paymentMethod.managed;

  // If paid, display when it was paid
  // if payment failed. display failure message
  // If overdue (even if contract is not active), display overdue
  // If contract is active, display when statement is due
  // If contract is not active, display contract status
  const getMessage = (): string => {
    if (reference.externalInvoiced) {
      return 'Invoiced outside of Cacheflow';
    }
    if (isPosted(statusOnlyVal) && transfer?.processedAt) {
      /**
       * We want to show the UTC date that the transfer was processed at since
       * payments are processed right after midnight UTC on the due date. We
       * don't want to show the local date since it could be the day before the due date and look weird.
       */
      return `Paid ${formatDateUTC(transfer.processedAt)}`;
    } else if (paymentFailed(statusOnlyVal)) {
      let note = `Payment ${statusOnlyVal.toLowerCase()}: ${codeMessage}`;

      if (transfer?.retryAt) {
        note += ` Automatic retry at ${formatDateOrDefault(
          transfer.retryAt
        )} ${formatTimeOrDefault(transfer.retryAt)}`;
      }

      return note;
    } else if (isManagedPayments && overdue) {
      return 'Overdue';
    } else if (
      contract.status === 'active' ||
      contract.status === 'cancelled'
    ) {
      const { amountDue } = reference;

      if (!amountDue || amountDue === 0) {
        return 'No payment needed';
      }

      // negative charges exist for things like changes
      if (amountDue < 0) {
        return 'Processed externally';
      }

      const daysLeft = getDaysLeft(reference.invoiceDate);
      const daysLeftProcess = getDaysLeft(reference.dueDate);

      if (daysLeftProcess === 0 || isPaymentBeingPaid(reference)) {
        return !isManagedPayments ? 'Pending' : 'Processing payment';
      }

      if (contract.status === 'active') {
        if (daysLeft <= 0) {
          return !isManagedPayments ? 'Pending' : 'Pending processing';
        }

        return `Statement in ${daysLeft} days`;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (contract.status === 'cancelled') {
        if (statusOnlyVal === 'pending') {
          return 'Pending';
        }

        if (
          daysLeftProcess === 0 ||
          isPaymentBeingPaid(reference) ||
          daysLeft <= 0 ||
          (usageAmount && usageAmount > 0)
        ) {
          return !isManagedPayments ? 'Pending' : 'Pending processing';
        }
      }
    }
    return capitalizeFirstLetter(String(contract.status));
  };

  const className = clsx('text-left', {
    overDue: overdue && isManagedPayments,
  });

  return <div className={className}>{getMessage()}</div>;
};

export default PaymentInfo;
