import {
  Contact,
  ContactInternal,
  formatPerson,
  Icon,
  SIZE_SMALL,
} from 'common';
import React from 'react';

export const EventRecipient = ({
  person,
}: {
  person?: Contact | ContactInternal;
}): React.ReactElement => (
  <div className="text-xs text-black flex flex-row items-center">
    <Icon.Share {...SIZE_SMALL} />
    <span className="inline-block h-auto align-middle h-fit">
      {formatPerson(person)}
    </span>
  </div>
);
