import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Card, Loading } from 'common';
import { finishStripeConnectPost } from '../../../services/api/stripe/stripe';
import { SETTINGS_STRIPE } from '../../../core-utils/routes';
import ErrorHandler from '../../../components/ErrorHandler';
import StripeLogoSVG from '../../../assets/integration-logos/StripeLogoSVG';
import LogoCardHeader from '../Connectors/LogoCardHeader';
import { AxiosError } from 'axios';

interface Props {
  stripeCode: string;
}

const CardWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Card>
    <LogoCardHeader logo={StripeLogoSVG} />
    {children}
  </Card>
);

const SaveStripeCode: React.FC<Props> = ({ stripeCode }) => {
  const isMutating = useRef(false);
  // TODO: Figure out why useMutation isn't properly sending isError or error
  const [error, setError] = React.useState<Error>();
  const [isSuccess, setIsSuccess] = React.useState<boolean>();
  const mutation = useMutation({
    mutationKey: ['finishStripeConnectPost', stripeCode],
    mutationFn: async () => await finishStripeConnectPost(stripeCode),
    throwOnError: false,
    onError: (err: AxiosError) => setError(err),
    onSuccess: () => setIsSuccess(true),
  });
  const { mutate, isPending: isLoading } = mutation;

  useEffect(() => {
    if (stripeCode && !isMutating.current) {
      isMutating.current = true;
      mutate();
    }
  }, [stripeCode, mutate]);

  if (error) {
    return (
      <CardWrapper>
        <ErrorHandler error={error} />
      </CardWrapper>
    );
  }

  if (isSuccess) {
    return <Navigate to={SETTINGS_STRIPE} />;
  }

  if (isLoading) {
    return (
      <CardWrapper>
        <Loading />
      </CardWrapper>
    );
  }

  return null;
};

export default SaveStripeCode;
