import clsx from 'clsx';
import React from 'react';

import g2Easiest from '../../../assets/images/g2_easiest.png';
import g2Performer from '../../../assets/images/g2_performer.png';
import g2Relationship from '../../../assets/images/g2_relationship.png';
import DescopeLogo from '../../../assets/login-logos/Descope';

import seekrLogo from '../../../assets/login-logos/Seekr.png';
import SendosoLogo from '../../../assets/login-logos/Sendoso';
import SprintoLogo from '../../../assets/login-logos/Sprinto';
import TrayLogo from '../../../assets/login-logos/Tray';
import UniqodeLogo from '../../../assets/login-logos/Uniqode';
import RocketIcon from '../RocketIcon';

import styles from './LoginLayout.module.scss';

interface Props {
  bigIcon?: React.ReactNode;
  children?: React.ReactNode;
  isSystem?: boolean;
}

const LoginLayout: React.FC<Props> = ({
  isSystem = false,
  children,
  bigIcon,
}) => {
  return (
    <div className={clsx(styles.loginPage, isSystem && styles.isSystem)}>
      <div className={styles.container}>
        <div className={styles.left}>{children}</div>
        <div className={styles.right}>
          <div className={styles.blue}>
            <div className={styles.heroText}>
              <div>Close deals faster.</div>
              <div className={styles.icon}>{bigIcon || <RocketIcon />}</div>
            </div>
            <div>
              <img alt="G2 High performer" src={g2Performer} />
              <img alt="G2 Best relationship" src={g2Relationship} />
              <img alt="G2 Easiest to do business with" src={g2Easiest} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.logos}>
        <SendosoLogo height={21} />
        <TrayLogo height={37} />
        <UniqodeLogo height={29} />
        <img alt="Seekr" src={seekrLogo} className={styles.seekr} />
        <SprintoLogo height={29} />
        <DescopeLogo />
      </div>
    </div>
  );
};

export default LoginLayout;
