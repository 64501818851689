import { addDays } from 'date-fns';
import React, { useEffect, useRef } from 'react';
import {
  Button,
  ButtonBar,
  ConnectorInfo,
  ContractSummary,
  Drawer,
  formatDateISO,
  formatDateOrDefault,
  getBillingPeriodLabelAdverb,
  getErrorCode,
  getErrorMessage,
  log,
  Proposal,
  useToast,
  useTranslation,
} from 'common';
import { getProposalByExternalId } from '../../services/proposal';
import { useQueryClient } from '@tanstack/react-query';

import { useGoToProposal } from '../../core-utils/commonHooks/nav';
import Actions from 'app/src/pages/ExternalDeals/Actions';
import {
  getContractsForCustomer,
  getCustomerByExternalId,
} from 'app/src/services/customer';
import {
  getExternalDeal,
  useCreateProposalFromDeal,
} from '../../services/externalCrm';
import { useSearchParams } from 'react-router-dom';

interface Props {
  dealQueryParamName: string;
  onClose: () => void;
  onIsProcessing: (isProcessing: boolean) => void;
  selectedDealId: string | undefined;
  selectedTemplateId: string | undefined;
  sourceType: ConnectorInfo['source'];
}

const ProposalFromDeal: React.FC<Props> = ({
  sourceType,
  selectedDealId,
  dealQueryParamName,
  onIsProcessing,
  onClose,
  selectedTemplateId,
}) => {
  const isOnMountCalled = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const [contracts, setContracts] = React.useState<ContractSummary[]>([]);
  const dealIdParam = searchParams.get(dealQueryParamName);
  const dealId = dealIdParam || selectedDealId;

  const queryClient = useQueryClient();
  const showToast = useToast();
  const { tk } = useTranslation();

  const templateIdParam = searchParams.get('templateId');
  const templateId = templateIdParam || selectedTemplateId;

  const goToProposal = useGoToProposal();

  const { mutate: createNewProposal, isPending: isSubmitting } =
    useCreateProposalFromDeal(
      sourceType,
      goToProposal,
      (err: unknown) => {
        onIsProcessing(false);
        const errorCode = getErrorCode(err);
        const errorMessage = getErrorMessage(err);

        if (errorCode === 'PROPOSAL_EXISTS_FOR_DEAL') {
          showToast.error('Proposal already exists for this deal');
        } else if (errorCode === 'DEAL_HAS_NO_COMPANY') {
          showToast.error(
            'Cannot create a proposal with no associated company'
          );
        } else if (errorMessage) {
          showToast.error(errorMessage);
        } else {
          showToast.error('Error creating proposal');
        }
      },
      queryClient
    );

  useEffect(() => {
    onIsProcessing(isSubmitting);
  }, [isSubmitting]);

  const close = () => {
    // always clear the query params in case there is an error or
    // they close the drawer so they can continue from the deal list
    setSearchParams({});
    onIsProcessing(false);
    onClose();
  };

  const loadContractsForDeal = async () => {
    onIsProcessing(true);
    const externalDeal = await getExternalDeal(sourceType, dealId!).catch(
      () => {
        throw new Error('Deal not found');
      }
    );

    const externalCustomerId = externalDeal.customerId;
    if (!externalCustomerId) {
      throw new Error('Customer is required in order to create a proposal.');
    }

    getCustomerByExternalId(externalCustomerId)
      .then(async (customer) => {
        const existing = await getContractsForCustomer(customer.id);

        if (existing.length > 0) {
          setContracts(existing);
          setIsDrawerOpen(true);
          onIsProcessing(false);
        } else {
          createNewProposalFromDeal();
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          // okay to create a new proposal if the customer is not already linked
          createNewProposalFromDeal();
        } else {
          throw new Error('Error getting customer by external Id');
        }
      });
  };

  useEffect(() => {
    if (dealIdParam) {
      // only execute once if coming from external integration
      if (isOnMountCalled.current) return;
      isOnMountCalled.current = true;
    }

    if (dealId) {
      onIsProcessing(true);
      getProposalByExternalId(dealId)
        .then((proposal: Proposal | null) => {
          if (proposal) {
            goToProposal(proposal);
          } else {
            loadContractsForDeal().catch((e) => {
              log.error('Error loading contracts', e);
              showToast.error(e.message);
              close();
            });
          }
        })
        .catch((e) => {
          showToast.error(e);
          close();
        });
    }
  }, [dealId]);

  const createNewProposalFromDeal = () => {
    const options: any = {
      startDateIsSigningDate: true,
    };
    createNewProposal({
      dealId: dealId!,
      templateId: templateId || undefined,
      expiresAt: formatDateISO(addDays(new Date(), 30)),
      options,
    });
  };

  if (!dealId) {
    return null;
  }

  return (
    <Drawer
      footer={
        <ButtonBar>
          <Button
            label={tk('Cancel')}
            onClick={() => {
              setIsDrawerOpen(false);
              close();
            }}
            type="secondary"
          />
        </ButtonBar>
      }
      header={tk('Create proposal')}
      isOpen={isDrawerOpen}
    >
      <div>
        <p className="font-semibold">
          {tk('This customer has one or more subscriptions already.')}
        </p>
        <p className="mt-4">
          {tk(
            'Would you like to create a proposal for a new subscription or for modifying/renewing an existing one?'
          )}
        </p>
        <Button
          className="mt-4 w-full"
          dataTestId="proposal_create_button"
          isDisabled={isSubmitting}
          label={tk('Create new subscription')}
          onClick={createNewProposalFromDeal}
          type="primary"
        />
        <div className="mt-12 text-base font-bold">
          {tk('Existing subscriptions')}
        </div>
        {contracts.map((c) => (
          <div className="mt-8 justify-between center" key={c.id}>
            <div className="flex justify-between">
              <div>
                <div>
                  {c.name} - {c.proposalNumber}
                </div>
                <div>
                  {formatDateOrDefault(c.startDate)}-
                  {formatDateOrDefault(c.endDate)}
                </div>
              </div>
              <div className="text-right">
                <div>{c.totalAmountFormatted}</div>
                <div>{getBillingPeriodLabelAdverb(c.billingPeriod)}</div>
              </div>
            </div>
            <Actions
              contract={c}
              dealId={dealId}
              onIsProcessing={onIsProcessing}
              sourceType={sourceType}
            />
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default ProposalFromDeal;
