import axios from 'axios';
import {
  Api as AutoGeneratedApi,
  logError,
  logRejected,
  logResponse,
  prepareRequest,
  SHARED_AXIOS_CONFIG,
} from 'common';
import { getSsrHostUrl, getUrl } from '../helper';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';

// TODO: appAxios to be replaced once all the calls are using apiClient
export const appAxiosDeprecated = axios.create({
  baseURL: getUrl(ENVIRONMENT_CONFIG.cfAppHost),
  ...SHARED_AXIOS_CONFIG,
});

appAxiosDeprecated.interceptors.request.use(prepareRequest, logRejected);
appAxiosDeprecated.interceptors.response.use(logResponse, logError);

export const apiClient = new AutoGeneratedApi({
  baseURL: getUrl(ENVIRONMENT_CONFIG.cfAppHost),
  ...SHARED_AXIOS_CONFIG,
});

apiClient.instance.interceptors.request.use(prepareRequest, logRejected);
apiClient.instance.interceptors.response.use(logResponse, logError);

export const ssrAxios = axios.create({
  baseURL: getSsrHostUrl(),
  ...SHARED_AXIOS_CONFIG,
});

ssrAxios.interceptors.request.use(prepareRequest, logRejected);
ssrAxios.interceptors.response.use(logResponse, logError);
