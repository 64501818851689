import React from 'react';
import { Nullable, ProductRequest, ToggleSwitch } from 'common';
import { convertProductPricesScale } from './productUtils';

const ExtendedDecimalPricingToggle: React.FC<{
  isDisabled: boolean;
  product: Nullable<ProductRequest>;
  updateProductDraft: (product: Nullable<ProductRequest>) => void;
}> = ({ product, isDisabled, updateProductDraft }) => {
  return (
    <div className="mb-6">
      <ToggleSwitch
        alignLabel="right"
        name="extended-decimal-pricing-toggle"
        dataTestId="extended-decimal-pricing-toggle"
        label="Enable extended decimal places in prices"
        value={product.extendedScale || false}
        onChange={(checked) => {
          const updatedProduct: Nullable<ProductRequest> =
            convertProductPricesScale(product, checked);
          updateProductDraft({
            ...updatedProduct,
            extendedScale: checked,
          });
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ExtendedDecimalPricingToggle;
