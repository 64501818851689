import React, { Fragment, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  CopyProposalStrategy,
  useSubmitProposal,
} from '../../../../services/proposal';
import { useGetVisibleProposalTemplates } from '../../../../services/proposalTemplate';
import { PROPOSALS } from '../../../../core-utils/routes';
import {
  Button,
  DropdownButton,
  formatDateISO,
  getErrorMessage,
  LinkButton,
  Proposal,
  ProposalTemplateSummary,
  useToast,
  useTranslation,
} from 'common';
import { useIsCurrentUserAdmin } from '../../../../core-utils/helperFunctions/userServiceHelper';
import { addDays } from 'date-fns';
import './AddProposalButton.css';
import { useProposalCopy } from '../useProposalCopy';
import CopyErrorModal from '../CopyErrorModal/CopyErrorModal';
import clsx from 'clsx';

interface Props {
  isHidden?: boolean;
}

const AddProposalButton: React.FC<Props> = ({ isHidden = false }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentUserAdmin = useIsCurrentUserAdmin();
  const showToast = useToast();

  const [copyingTemplateId, setCopyingTemplateId] = useState<string>();
  const { data: templates } = useGetVisibleProposalTemplates();

  const { tk } = useTranslation();

  const {
    makeCopy,
    isLoading: isLoadingCopy,
    copyError,
    handleClose,
  } = useProposalCopy();

  const handleSubmitError = (data: unknown) => {
    showToast.error(getErrorMessage(data) || 'Error creating proposal');
  };

  const handleSubmitSuccess = (proposal: Proposal) => {
    navigate(`${PROPOSALS}/${proposal.id}?first=true`);
  };

  const { mutate: submitProposal, isPending: isLoadingSubmit } =
    useSubmitProposal(handleSubmitSuccess, handleSubmitError, queryClient);

  const createNewProposal = () => {
    submitProposal({
      name: 'Untitled',
      status: 'draft',
      termType: 'month',
      termQty: 12,
      expiresAt: formatDateISO(addDays(new Date(), 30)),
      options: {
        showTotalContractValue: true,
        startDateIsSigningDate: true,
        collectPurchaseOrderNumber: false,
      },
    });
  };

  const createNewProposalTemplate = () => {
    submitProposal({
      name: 'Untitled',
      status: 'draft',
      termType: 'month',
      termQty: 12,
      proposalType: 'template',
      expiresAt: formatDateISO(addDays(new Date(), 30)),
      options: {
        showTotalContractValue: true,
        startDateIsSigningDate: true,
        collectPurchaseOrderNumber: false,
      },
    });
  };

  const isLoading = isLoadingSubmit || isLoadingCopy;

  const getTemplateOption = (template: ProposalTemplateSummary) => {
    return (
      <Fragment key={template.id}>
        <div
          key={`use-template-${template.id}`}
          className="template-option"
          data-testid={`use-template-${template.id}`}
        >
          <div>
            {template.name}
            {template.status === 'draft' && (
              <div className="text-slate text-sm italic">(draft)</div>
            )}
          </div>
          <div className="action">
            {template.status === 'active' && (
              <LinkButton
                onClick={() => {
                  setCopyingTemplateId(template.id);
                  makeCopy(template.id!);
                }}
                dataTestId="copy-from-template-button"
                className="template-link"
                isDisabled={isLoading}
              >
                Create proposal
              </LinkButton>
            )}

            {currentUserAdmin && (
              <LinkButton
                onClick={() => {
                  navigate(`${PROPOSALS}/${template.id}`);
                }}
                dataTestId="edit-template-button"
                className="template-link"
                isDisabled={isLoading}
              >
                Edit
              </LinkButton>
            )}
          </div>
        </div>
        <div
          className="h-[1px] bg-gray-lines w-full"
          key={`gl-${template.id}`}
        />
      </Fragment>
    );
  };

  const getTemplateOptions = () => {
    return (
      <div className="min-w-fit w-[300px]">
        <div className="text-slate text-xs uppercase pt-4 p-2">
          Proposal templates
        </div>

        <div className="overflow-y-auto max-h-80">
          {templates?.map((t) => getTemplateOption(t))}
        </div>

        {currentUserAdmin && (
          <div className="p-2">
            <Button
              block
              dataTestId="create-template-button"
              isDisabled={isLoading}
              label="Create template"
              onClick={createNewProposalTemplate}
              type="secondary"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={clsx('flex gap-3', {
        invisible: isHidden,
      })}
    >
      <CopyErrorModal
        error={copyError}
        handleClose={() => {
          handleClose();
          setCopyingTemplateId(undefined);
        }}
        handleTryWithStrategy={(strategy: CopyProposalStrategy) => {
          if (copyingTemplateId) {
            makeCopy(copyingTemplateId, strategy);
          }
        }}
      />
      <DropdownButton
        dataTestId="create-proposal-button"
        isLoading={isLoading}
        label={tk('Create proposal')}
        onClick={createNewProposal}
        options={getTemplateOptions()}
        popoverProps={{ overlayClassName: 'template-popover' }}
      />
    </div>
  );
};

export default AddProposalButton;
