import { Transaction } from 'common';
import { getDetailLink } from '../../routes/routeUtils';

export const getCreditAmount = (
  transaction: Transaction
): string | null | undefined => {
  return transaction.type === 'CREDIT' ? transaction.amountFormatted : '';
};

export const getDebitAmount = (
  transaction: Transaction
): string | null | undefined => {
  return transaction.type === 'DEBIT' ? transaction.amountFormatted : '';
};

export const getTransactionDetailLink = (transaction: Transaction) => {
  if (transaction.referenceType === 'invoice') {
    return getDetailLink(
      transaction.referenceType,
      transaction.referenceId || ''
    );
  }

  return getDetailLink(
    transaction.referenceType || '',
    transaction.parentId || ''
  );
};

export const getPaymentType = (transaction: Transaction) => {
  if (transaction.paymentMethod?.paymentType) {
    return transaction.paymentMethod.paymentType;
  }
  switch (transaction.fromAccount?.type) {
    case 'credit':
      return 'cc';
    case 'depository':
      return 'direct_debit';
    default:
      return transaction.fromAccount?.type;
  }
};

export const getToFromName = (transaction: Transaction): string => {
  if (
    transaction.referenceType === 'billingschedule' ||
    transaction.referenceType === 'invoice'
  ) {
    return transaction.fromName || '-';
  } else {
    return transaction.toName || '-';
  }
};
