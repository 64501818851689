import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonBar,
  CardRowValue,
  Checkbox,
  ConfirmDeleteModal,
  Drawer,
  emailValidator,
  ErrorFetching,
  FormField,
  getErrorMessage,
  Input,
  isDefined,
  Loading,
  Select,
  SelectOption,
  TruncateOption,
  truncateText,
  useFormValidation,
  User,
  UserProfile,
  useToast,
  useTranslation,
} from 'common';
import {
  useCreateUser,
  useCreateUserInvite,
  useDeleteUser,
  useGetAllUsers,
  useGetUser,
  UserStatus,
  useUpdateUser,
} from '../../../services/users';
import { useQueryClient } from '@tanstack/react-query';
import { roles } from './Users';
import UserEmailAliasForm from './UserEmailAliasForm';
import IDField from 'app/src/components/IDField';
import styles from './EditUserForm.module.scss';

interface Props {
  isBeingViewed: boolean;
  onClose: () => void;
  onDeleteSuccess?: () => void;
  onSaveSuccess: () => void;
  roleTypeIn?: UserProfile['roleType'];
  userId?: string;
}

const initialUserState: UserProfile = {
  roleType: 'sales',
  invite: false,
  user: {
    firstName: '',
    lastName: '',
    username: '',
    status: 'pending',
  },
};

const EditUserForm: React.FC<Props> = ({
  isBeingViewed,
  userId,
  roleTypeIn,
  onSaveSuccess,
  onDeleteSuccess,
  onClose,
}: Props) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const { tk } = useTranslation();
  const showToast = useToast();
  const queryClient = useQueryClient();
  const [draft, setDraft] = useState<UserProfile>(
    roleTypeIn
      ? {
          ...initialUserState,
          roleType: roleTypeIn,
        }
      : initialUserState
  );

  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<User>(
      [
        {
          fieldName: 'firstName',
          isRequired: true,
          humanReadableName: 'First name',
        },
        {
          fieldName: 'lastName',
          isRequired: true,
          humanReadableName: 'Last name',
        },
        {
          fieldName: 'username',
          isRequired: true,
          validator: emailValidator,
          humanReadableName: 'Email',
        },
      ],
      draft.user || {}
    );

  const header = userId ? tk('Edit user') : tk('Add user');

  const handleCreateSuccess = () => {
    showToast.info('User updated');
    onSaveSuccess();
  };

  const handleCreateError = (responseError: unknown) => {
    const responseMsg =
      getErrorMessage(responseError) ||
      'There was an issue updating the user. Please try again.';
    showToast.error(responseMsg);
  };

  const changeUser = (user: User) => {
    setDraft((prev) => ({ ...prev, user }));
  };

  const { data: allUsers } = useGetAllUsers();

  const managers = (): SelectOption<string | undefined>[] => {
    return [
      { name: 'none', value: undefined },
      ...(allUsers
        ?.filter(
          (profile) =>
            profile.user?.id === draft.user?.manager?.id || // always show selected user (even id inactive, etc.)
            (!!profile.roleType && // cannot set API users as manager
              profile.user?.id !== draft.user?.id && // cannot set self as manager
              profile.user?.status === 'active' && // cannot set inactive user as manager
              (draft.user?.id === undefined || // ignore cycles for add new user, only applies to editing user
                profile.user.manager?.id !== draft.user.id)) // don't allow cycles (user's manager's manager becomes the user)
        )
        .map((profile) => ({
          name: [profile.user?.firstName, profile.user?.lastName]
            .filter(isDefined)
            .join(' '),
          value: profile.user?.id,
        })) ?? []),
    ];
  };

  const { mutate: createUser } = useCreateUser(
    handleCreateSuccess,
    handleCreateError,
    queryClient
  );

  const { mutate: updateUser } = useUpdateUser(
    handleCreateSuccess,
    handleCreateError,
    queryClient
  );

  const { mutate: createInvite } = useCreateUserInvite(
    () => {
      showToast.info('User invite resent');
    },
    handleCreateError,
    queryClient
  );

  const {
    data: userProfile,
    isLoading,
    isError,
  } = userId
    ? useGetUser(userId)
    : {
        data: undefined,
        isLoading: false,
        isError: false,
      };

  const handleDeleteSuccess = (): void => {
    showToast.info(tk('Deleted user'));
    if (onDeleteSuccess) {
      onDeleteSuccess();
    }
    setIsDeleteConfirmationOpen(false);
    onSaveSuccess();
  };

  const handleDeleteError = (error: unknown) => {
    const errorMessage = getErrorMessage(error);
    if (errorMessage) {
      showToast.error(tk(errorMessage));
    }
    setIsDeleteConfirmationOpen(false);
  };

  const { mutate: deleteUser } = useDeleteUser(
    handleDeleteSuccess,
    handleDeleteError,
    queryClient
  );

  useEffect(() => {
    if (userProfile) {
      let roleTypeValue: UserProfile['roleType'] =
        userProfile.roleType || roleTypeIn || 'admin';

      if (roleTypeValue === 'user') {
        roleTypeValue = 'sales';
      }
      setDraft({
        ...userProfile,
        roleType: roleTypeValue,
      });
    } else {
      setDraft(
        roleTypeIn
          ? {
              ...initialUserState,
              roleType: roleTypeIn,
            }
          : initialUserState
      );
    }
    resetUIState();
  }, [userProfile]);

  if (isLoading || isError || (userId && !userProfile)) {
    return (
      <Drawer header={header} isOpen={isBeingViewed} onClose={onClose}>
        {isLoading ? <Loading /> : <ErrorFetching />}
      </Drawer>
    );
  }

  const submit = () => {
    if (userId) {
      updateUser({ ...draft, id: userId });
    } else {
      createUser({ ...draft });
    }
  };

  const submitIfValid = () => {
    if (isFormValid) {
      submit();
    }
  };

  const handleToggleInvite = (inviteUser: boolean) => {
    setDraft((prev) => ({ ...prev, invite: inviteUser }));
  };

  // resend invite
  const handleResendInvite = () => {
    createInvite({
      email: draft.user?.username,
      type: draft.roleType,
    });
  };

  return (
    <Drawer
      footer={
        <ButtonBar>
          <Button
            block
            label={tk('Cancel')}
            onClick={onClose}
            type="secondary"
          />
          <Button
            block
            dataTestId="user_save_button"
            isDisabled={!isFormValid || isLoading}
            label={userId ? tk('Update') : tk('Create')}
            onClick={submitIfValid}
          />
        </ButtonBar>
      }
      header={header}
      isOpen={isBeingViewed}
      onClose={onClose}
    >
      <div>
        {userId && (
          <CardRowValue
            className={styles.userObjectId}
            label="Id:"
            leftClassName={styles.userIdLabel}
          >
            <IDField documentID={userId}>
              <div className="tabular-nums">
                {truncateText(userId, 8, TruncateOption.WithoutDots)}
              </div>
            </IDField>
          </CardRowValue>
        )}

        <FormField
          errorToShow={getErrorToShow('firstName')}
          label={tk('First name')}
        >
          <Input
            dataTestId="new-user-first-name"
            isDisabled={isLoading}
            onBlur={() => setHasVisitedField('firstName')}
            onChange={(value) =>
              changeUser({
                ...draft.user,
                firstName: value,
              })
            }
            onEnter={submitIfValid}
            placeholder={tk('Enter first name')}
            value={draft.user?.firstName}
          />
        </FormField>

        <FormField
          errorToShow={getErrorToShow('lastName')}
          label={tk('Last name')}
        >
          <Input
            dataTestId="new-user-last-name"
            isDisabled={isLoading}
            onBlur={() => setHasVisitedField('lastName')}
            onChange={(value) =>
              changeUser({
                ...draft.user,
                lastName: value,
              })
            }
            onEnter={submitIfValid}
            placeholder={tk('Enter last name')}
            value={draft.user?.lastName}
          />
        </FormField>

        <FormField errorToShow={getErrorToShow('username')} label={tk('Email')}>
          <Input
            dataTestId="new-user-email"
            isDisabled={isLoading}
            onBlur={() => setHasVisitedField('username')}
            onChange={(value) =>
              changeUser({
                ...draft.user,
                username: value,
              })
            }
            onEnter={submitIfValid}
            placeholder={tk('Enter email')}
            value={draft.user?.username}
          />
        </FormField>

        <FormField label={tk('Status')}>
          <Select<User['status']>
            dataTestId="status"
            isDisabled={isLoading}
            onChange={(value) =>
              changeUser({
                ...draft.user,
                status: value,
              })
            }
            options={Object.keys(UserStatus).map((key) => ({
              value: key as User['status'],
              name: UserStatus[key as keyof typeof UserStatus].valueOf(),
            }))}
            value={draft.user?.status}
          />
        </FormField>

        <FormField label={tk('Role')}>
          <Select<UserProfile['roleType']>
            dataTestId="role"
            isDisabled={isLoading}
            onChange={(value) =>
              setDraft((prev) => ({
                ...prev,
                roleType: value,
              }))
            }
            options={roles.filter((role) => !!role.value)}
            value={draft.roleType}
          />
        </FormField>

        <FormField label={tk('Manager')}>
          <Select
            dataTestId="manager-user-id"
            isDisabled={isLoading}
            onChange={(value) =>
              changeUser({
                ...draft.user,
                manager: allUsers?.find((user) => user.user?.id === value)
                  ?.user,
              })
            }
            options={managers()}
            value={draft.user?.manager?.id}
          />
        </FormField>

        <FormField label={tk('Invite user')}>
          <Checkbox
            isDisabled={!!userId || isLoading}
            label="Send invite"
            name="inviteUser"
            onChange={handleToggleInvite}
            value={draft.invite}
          />
          {userId && (
            <div
              className="underline cursor-pointer text-blue"
              data-testid="resend-invite"
              onClick={() => handleResendInvite()}
            >
              Resend invite
            </div>
          )}
        </FormField>

        {userId && <UserEmailAliasForm profile={draft} />}

        <div className="flex justify-between pt-6">
          <div className="flex flex-col justify-center">
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,no-constant-binary-expression */}
            {userId && false && (
              <Button
                // currently hiding this button until we have
                // soft deletes
                isDangerous
                label="Delete"
                onClick={() => setIsDeleteConfirmationOpen(true)}
                type="link"
              />
            )}
          </div>
        </div>
        <ConfirmDeleteModal
          isOpen={isDeleteConfirmationOpen}
          onClose={() => setIsDeleteConfirmationOpen(false)}
          onConfirm={() => deleteUser({ id: userId })}
          typeName="user"
        />
      </div>
    </Drawer>
  );
};

export default EditUserForm;
