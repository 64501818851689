import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useResetOrganizationAPI,
  useSubmitOrganization,
  useUpdateOrganizationAPI,
} from '../../services/api/system/organizations';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';
import {
  CurrencySearchSelect,
  domainValidator,
  FormModal,
  getErrorMessage,
  Input,
  Loading,
  Organization,
  Select,
  useFormValidation,
  useToast,
  useTranslation,
} from 'common';
import { useAllCurrencies } from '../../services/api/system/currency';

interface Props extends React.ComponentPropsWithoutRef<any> {
  onClose: () => void;
  open: boolean;
  organizationData?: Organization;
}

const getDraft = (src?: Organization): Organization => ({
  name: src?.name || '',
  domainName: src?.domainName || '',
  status: src?.status || 'active',
  defaultCurrency: src?.defaultCurrency || 'USD',
  flowDomain: src?.flowDomain || '',
});

const UpsertOrganizationDialog: React.FC<Props> = ({
  open,
  onClose,
  organizationData,
}: Props) => {
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const showToast = useToast();
  const [draft, setDraft] = useState<Organization>(getDraft(organizationData));
  const isCreatingNew = !organizationData;

  const {
    data: currencies,
    isLoading: isLoadingCurrencies,
    error: errorCurrencies,
  } = useAllCurrencies();

  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<Organization>(
      [
        {
          fieldName: 'name',
          isRequired: true,
          humanReadableName: 'Organization name',
        },
        {
          fieldName: 'domainName',
          isRequired: true,
          humanReadableName: 'Domain name',
          validator: domainValidator,
        },
        {
          fieldName: 'defaultCurrency',
          isRequired: true,
          humanReadableName: 'Base currency',
        },
      ],
      draft
    );

  useEffect(() => {
    if (open) {
      setDraft(getDraft(organizationData));
      resetUIState();
    }
  }, [open, organizationData]);

  const handleFieldChange = (
    fieldName: keyof Organization,
    value: string | number
  ): void => {
    const valueIn =
      (fieldName === 'domainName' || fieldName === 'flowDomain') && value
        ? value.toString().toLowerCase()
        : value;
    setDraft((prev) => ({
      ...prev,
      [fieldName]: valueIn,
    }));
  };

  const handleSubmitError = (error: unknown) => {
    const errorMessage = getErrorMessage(error);
    showToast.error(
      `Failed to ${isCreatingNew ? 'create' : 'update'} organization. ${errorMessage}`
    );
  };

  const handleSubmitSuccess = () => {
    // TODO: close modal
    // navigate(SYSTEM_DASHBOARD_ROUTE);
    showToast.info(
      `Organization has been ${
        isCreatingNew ? 'created' : 'updated'
      } successfully.`
    );
    onClose();
  };

  const handleResetError = () => {
    showToast.error('Failed to reset organization.');
  };

  const handleResetSuccess = () => {
    showToast.info('Organization has been reset successfully.');
  };

  const { mutate: createOrganization } = useSubmitOrganization(
    handleSubmitSuccess,
    handleSubmitError,
    queryClient
  );

  const { mutate: updateOrganization } = useUpdateOrganizationAPI(
    organizationData?.id || '',
    handleSubmitSuccess,
    handleSubmitError,
    queryClient
  );

  const { mutate: resetOrganization } = useResetOrganizationAPI(
    organizationData?.id || '',
    handleResetSuccess,
    handleResetError,
    queryClient
  );

  const save = () => {
    const body = {
      ...draft,
      timeunit: 'month',
    };
    if (isCreatingNew) {
      createOrganization(body);
    } else {
      updateOrganization(body);
    }
  };

  const onReset = () => {
    resetOrganization({ strategy: 'non_settings' });
  };

  const submitIfValid = () => {
    if (isFormValid) {
      save();
    }
  };

  const updateFlowDomain = () => {
    const valueIn = draft.domainName.toLowerCase();
    if (!draft.flowDomain && valueIn) {
      let flowDomain = valueIn;
      if (valueIn.indexOf('.') === valueIn.lastIndexOf('.')) {
        flowDomain = flowDomain.split('.').find((s) => !!s) || flowDomain;
      } else {
        flowDomain =
          flowDomain
            .match(/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})/)
            ?.find((s) => !!s) || flowDomain;
      }
      flowDomain = flowDomain.replaceAll('.', '-');

      setDraft((prev) => ({
        ...prev,
        flowDomain,
      }));
    }
  };

  return (
    <FormModal
      deleteText={tk('Reset')}
      entityTypeName="organization"
      isNewEntity={isCreatingNew}
      isOpen={open}
      isSubmitDisabled={!isFormValid}
      onClose={onClose}
      onDelete={ENVIRONMENT_CONFIG.isProduction ? undefined : onReset}
      onSubmit={save}
      width={506}
    >
      <label>{tk('Organization name')}</label>
      <Input
        onChange={(rv) => handleFieldChange('name', rv)}
        value={draft.name}
        onEnter={submitIfValid}
        errorToShow={getErrorToShow('name')}
        onBlur={() => setHasVisitedField('name')}
      />

      <label>{tk('Domain')}</label>
      <Input
        onChange={(rv) => handleFieldChange('domainName', rv)}
        value={draft.domainName}
        onEnter={submitIfValid}
        errorToShow={getErrorToShow('domainName')}
        onBlur={() => {
          setHasVisitedField('domainName');
          updateFlowDomain();
        }}
      />

      <label>{tk('Cacheflow Domain')}</label>
      <Input
        onChange={(rv) => handleFieldChange('flowDomain', rv)}
        value={draft.flowDomain}
        onEnter={submitIfValid}
        errorToShow={getErrorToShow('flowDomain')}
        onBlur={() => setHasVisitedField('flowDomain')}
      />

      <label>{tk('Base currency')}</label>
      {errorCurrencies ? (
        errorCurrencies.message
      ) : !isLoadingCurrencies ? (
        <CurrencySearchSelect
          shouldShowAllCurrencies
          dataTestId="currency-select"
          currencies={currencies}
          orgCurrencies={[]}
          defaultSelectedCurrency={draft.defaultCurrency || 'USD'}
          onSelectCurrency={(currency) => {
            handleFieldChange('defaultCurrency', currency);
          }}
        />
      ) : (
        <Loading />
      )}

      <div className="flex gap-2">
        <div className="w-1/2">
          <label>{tk('Status')}</label>
          <Select
            className="w-full"
            value={draft.status}
            onChange={(value) => handleFieldChange('status', value)}
            options={[
              { value: 'active', name: 'active' },
              { value: 'pending', name: 'pending' },
              { value: 'inactive', name: 'inactive' },
            ]}
          />
        </div>
      </div>
    </FormModal>
  );
};

export default UpsertOrganizationDialog;
