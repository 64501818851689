import React from 'react';
import {
  BillingPeriodsType,
  CurrentProposalContext,
  Item,
  ItemChangeRow,
  ItemRow,
  ItemSchedule,
  ProductGrid,
  ProductUpgrade,
  Proposal,
  ProposalItemChange,
} from 'common';
import { ItemActionsMenu, ItemScheduleActionsMenu } from './Actions';
import {
  EditableItemDescriptionArea,
  ItemDescriptionEditor,
  ItemEditorProvider,
} from './ItemEditor';
import RearrangeItemsAction from './Actions/RearrangeItemsAction';
import { getIsProductLinkRequired } from '../proposalUtilities';

interface Props {
  ariaLabeledBy?: string;
  billingPeriod: BillingPeriodsType;
  isDisabled: boolean;
  onShowPriceModalClick: (selectedSchedule: ItemSchedule, item: Item) => void;
  proposal: Proposal;
  upgrades: ProductUpgrade[];
}

const Products: React.FC<Props> = ({
  ariaLabeledBy,
  billingPeriod,
  isDisabled,
  onShowPriceModalClick,
  proposal,
  upgrades,
}) => {
  const changeForItem = (item: Item): ProposalItemChange | undefined => {
    return proposal.changes?.itemChanges?.find(
      (change) => change.key === item.key
    );
  };

  const removedItems =
    proposal.changes?.itemChanges?.filter(
      (change) => change.changeType === 'remove'
    ) ?? [];

  const isProductLinkRequired = getIsProductLinkRequired(proposal);

  return (
    <CurrentProposalContext.Provider value={proposal}>
      <ItemEditorProvider proposalId={proposal.id}>
        <ProductGrid
          ariaLabeledBy={ariaLabeledBy}
          itemDescription={ItemDescriptionEditor}
          itemDescriptionArea={EditableItemDescriptionArea}
        >
          {proposal.proposalItems.map((item) => (
            <ItemRow
              billingPeriod={billingPeriod}
              isDisabled={isDisabled}
              isProductLinkRequired={isProductLinkRequired}
              item={item}
              itemActions={
                !isDisabled && (
                  <>
                    <ItemActionsMenu />
                    <RearrangeItemsAction proposal={proposal} />
                  </>
                )
              }
              itemChange={changeForItem(item)}
              key={item.id}
              onShowPriceModalClick={onShowPriceModalClick}
              proposal={proposal}
              proposalSourceType={proposal.externalSource?.sourceType}
              scheduleActions={
                !isDisabled && (
                  <ItemScheduleActionsMenu
                    onShowPriceModalClick={onShowPriceModalClick}
                  />
                )
              }
              upgrades={upgrades}
            />
          ))}
          {removedItems.map((itemChange) => (
            <ItemChangeRow itemChange={itemChange} key={itemChange.key} />
          ))}
        </ProductGrid>
      </ItemEditorProvider>
    </CurrentProposalContext.Provider>
  );
};

export default Products;
