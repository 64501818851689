import React from 'react';
import { getErrorData, Icon, PopOutMenuOption, useToast } from 'common';
import { useQueryClient } from '@tanstack/react-query';

import { TransferReferenceType, useTriggerDue } from '../../services/transfer';

interface Props {
  detailLink: string;
  id: string;
  transferReferenceType: TransferReferenceType;
}

const TriggerDueAdmin: React.FC<Props> = ({
  id,
  transferReferenceType,
  detailLink,
}: Props) => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleSuccess = () => {
    showToast.success('Trigger due has been successfully posted');
  };

  const handleError = (error: unknown) => {
    const msg = JSON.stringify(getErrorData(error));
    showToast.error('Trigger due failed: ' + msg);
  };

  const { mutate: triggerDue } = useTriggerDue(
    detailLink,
    handleSuccess,
    handleError,
    queryClient
  );

  return (
    <PopOutMenuOption
      isSuper
      icon={Icon.Calendar}
      onClick={() => triggerDue({ id, type: transferReferenceType })}
      title="Set due today"
    />
  );
};

export default TriggerDueAdmin;
