import React from 'react';

import { ConfirmModal } from 'common';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const SubscriptionRenewalModal = ({
  isOpen,
  onClose,
  onConfirm,
}: Props) => (
  <ConfirmModal
    header="Confirm subscription renewal"
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    confirmText="Create renewal"
  >
    <div>
      <p>
        By creating the renewal you warrant that you have obtained permission
        from the buyer to act on their behalf.
      </p>
      <br />
      <p>
        Should you choose to obtain the buyer's direct acceptance or signature,
        select 'Cancel' and then 'Share'.
      </p>
    </div>
  </ConfirmModal>
);
