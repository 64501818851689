import { AccountContactsConfig } from '../../Connectors/ConnectorConstants/constants';

export const accountContactsConfig: AccountContactsConfig = {
  integration: 'Close',
  description:
    'The Close connector automatically imports accounts and contacts relevant to the available opportunities into Cacheflow. Customer and contact changes in Cacheflow are automatically pushed to Close.',
  cacheflowToIntegrationSection: [
    {
      left: 'Customers',
      right: 'Accounts',
    },
    {
      left: 'Customer contacts',
      right: 'Contacts',
    },
    {
      left: 'Billing contact',
      right: 'Contacts',
    },
    {
      left: 'Proposal signer',
      right: 'Contacts',
    },
  ],
  integrationToCacheflowSection: [
    {
      left: 'Accounts',
      right: 'Customers',
    },
    {
      left: 'Contacts',
      right: 'Customer contacts',
    },
  ],
};
