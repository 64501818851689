import React from 'react';
import clsx from 'clsx';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
  Button,
  Contract,
  Drawer,
  log,
  Proposal,
  ToolTip,
  Type,
  useToast,
  useTranslation,
} from 'common';

import { useCreateContractProposal } from 'app/src/services/contract';
import { useGoToProposal } from 'app/src/core-utils/commonHooks/nav';
import { getChangeRenewalState } from 'app/src/pages/Subscriptions/contractUtils';

import styles from './ChangeRenewDrawer.module.scss';

interface Props {
  closeDrawer: () => void;
  contract: Contract;
  contractProposals?: Proposal[];
  isShowing: boolean;
}

const ChangeRenewDrawer: React.FC<Props> = ({
  closeDrawer: onCloseDrawer,
  contract,
  contractProposals,
  isShowing,
}) => {
  const { tk } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const goToProposal = useGoToProposal();
  const showToast = useToast();

  const isEnded = contract.status === 'ended';

  const { mutate: doCreateContractProposal, isPending: isLoadingChange } =
    useCreateContractProposal(
      contract.id,
      { proposalType: 'change' },
      (successData) => {
        navigate(`/proposals/${successData.id}`);
      },
      (mutateError: unknown) => {
        showToast.error('Error creating change proposal');
        log.error(mutateError);
      },
      queryClient
    );

  const { mutate: doUpdateProposalTypeToRenewal, isPending: isLoadingRenewal } =
    useCreateContractProposal(
      contract.id,
      { proposalType: 'renewal' },
      (successData) => {
        navigate(`/proposals/${successData.id}`);
      },
      (mutateError: unknown) => {
        if (axios.isAxiosError(mutateError)) {
          switch (mutateError.response?.data?.errorCode) {
            case 'CHANGE_RENEWAL_PROPOSAL_NEEDS_PRODUCT':
              showToast.error(
                'To create a renewal, please add a renewable product to your proposal. Only including one-time products is not compatible with renewals.'
              );
              break;

            default:
              showToast.error('Error creating renewal');
              break;
          }
        } else {
          showToast.error('An unknown error occured');
        }

        log.error(mutateError);
      },
      queryClient
    );

  const changeRenewalState = getChangeRenewalState(contractProposals);

  const isRenewalProposalButtonDisabled =
    changeRenewalState.changeProposal && !changeRenewalState.renewalProposal;
  const isChangeProposalButtonDisabled =
    (changeRenewalState.renewalProposal &&
      !changeRenewalState.changeProposal) ||
    isEnded;

  const changeDisabledTooltipText = isEnded
    ? tk(
        'This subscription has ended. You cannot make changes on a completed subscription.'
      )
    : tk(
        'An active renewal proposal exists for this subscription. You cannot make changes until the renewal proposal is applied, deleted or accepted by the customer.'
      );
  return (
    <Drawer
      header={tk('Change or renew')}
      isOpen={isShowing}
      onClose={onCloseDrawer}
      className={styles.changeRenewDrawer}
    >
      <div className={clsx(styles.changeDescription, styles.blue)}>
        <div className={styles.subtitle}>Renew subscription</div>
        <Type paragraph>
          Renew a subscription by extending the term of the contract.
        </Type>
        <Type paragraph>
          A renewal inherits the current terms which you can modify, share, and
          get signed.
        </Type>
        {changeRenewalState.renewalProposal ? (
          <Button
            block
            label="View renewal proposal"
            className={styles.operationButton}
            onClick={() => goToProposal(changeRenewalState.renewalProposal!)}
          />
        ) : (
          <ToolTip
            placement="top"
            title="An active change proposal exists for this subscription. You cannot create a renewal until the change proposal is deleted or accepted by the customer."
            isDisabled={!isRenewalProposalButtonDisabled}
          >
            <Button
              block
              label="Create renewal proposal"
              dataTestId="create-renewal-proposal-button"
              className={styles.operationButton}
              onClick={doUpdateProposalTypeToRenewal}
              isDisabled={isRenewalProposalButtonDisabled || isLoadingChange}
              isLoading={isLoadingRenewal}
            />
          </ToolTip>
        )}
      </div>

      <div className={clsx(styles.changeDescription, styles.blue)}>
        <div className={styles.subtitle}>Change subscription</div>
        <Type paragraph>
          Change parts of the subscription within current subscription duration.
        </Type>
        <Type paragraph>
          A change proposal inherits the current terms which you can modify,
          share, and get signed.
        </Type>
        {changeRenewalState.changeProposal ? (
          <Button
            block
            label="View change proposal"
            className={styles.operationButton}
            onClick={() => goToProposal(changeRenewalState.changeProposal!)}
          />
        ) : (
          <ToolTip
            placement="top"
            title={changeDisabledTooltipText}
            isDisabled={!isChangeProposalButtonDisabled}
          >
            <Button
              block
              label="Create change proposal"
              dataTestId="create-change-proposal-button"
              className={styles.operationButton}
              onClick={doCreateContractProposal}
              isDisabled={isChangeProposalButtonDisabled || isLoadingRenewal}
              isLoading={isLoadingChange}
            />
          </ToolTip>
        )}
      </div>
    </Drawer>
  );
};

export default ChangeRenewDrawer;
