import React, { useState } from 'react';
import {
  Button,
  ButtonBar,
  Drawer,
  ErrorFetching,
  FormField,
  isDefined,
  Loading,
  ProposalSummary,
  SearchSelect,
  User,
  useToast,
  useTranslation,
} from 'common';

import { useUpdateProposal } from '../../../services/proposal';
import { useGetAllUsers } from '../../../services/users';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  proposal: ProposalSummary;
}

const ProposalSettingsDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  proposal,
}) => {
  const { tk } = useTranslation();
  const showToast = useToast();
  const [user, setUser] = useState<User | undefined>(proposal.owner);
  const { data: userProfiles, isLoading, isError } = useGetAllUsers();

  React.useEffect(() => {
    setUser(proposal.owner);
  }, [proposal]);

  const handleUpdateError = () => {
    showToast.error('Proposal failed to save');
  };

  const handleUpdateSuccess = () => {
    showToast.info('Owner updated');
    onClose();
  };

  const { mutate: updateProposal } = useUpdateProposal(proposal.id, {
    onSuccess: handleUpdateSuccess,
    onError: handleUpdateError,
  });

  const submit = () => {
    updateProposal({
      ownerId: user?.id,
    });
  };

  const onSelectUser = (u: User) => {
    setUser(u);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !userProfiles) {
    return <ErrorFetching />;
  }

  const users = userProfiles.map((value) => value.user).filter(isDefined);
  const isValid = !!user;

  return (
    <Drawer
      footer={
        <ButtonBar>
          <Button
            block
            label={tk('Cancel')}
            onClick={onClose}
            type="secondary"
          />
          <Button
            block
            isDisabled={!isValid}
            label={tk('Update')}
            onClick={submit}
          />
        </ButtonBar>
      }
      header={tk('Edit Proposal')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormField label={tk('Owner')}>
        <SearchSelect<User>
          dataTestId="ownerId"
          displayField="username"
          onChange={onSelectUser}
          onClearSelected={() => setUser(undefined)}
          options={users}
          placeholder="Select user"
          value={user}
        />
      </FormField>
    </Drawer>
  );
};

export default ProposalSettingsDrawer;
