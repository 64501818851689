import React from 'react';
import { isNumber, NumberInput, Proposal, Select } from 'common';

interface TermTypeRecordType {
  name: string;
  termType: Proposal['termType'];
}

const TermTypeRecords: TermTypeRecordType[] = [
  {
    name: 'Months',
    termType: 'month',
  },
  {
    name: 'Quarters',
    termType: 'quarter',
  },
  {
    name: 'Years',
    termType: 'year',
  },
];

interface Props {
  isDisabled?: boolean;
  onTermChanges: (termChanges: {
    freeMonths?: number;
    termQty?: number;
    termType?: Proposal['termType'];
  }) => void;
  proposal: Proposal;
}

const ProposalTerms: React.FC<Props> = ({
  isDisabled,
  proposal,
  onTermChanges,
}) => {
  const onTermQtyBlur = (value: number | undefined) => {
    if (!isNumber(value)) {
      return;
    }

    onTermChanges({
      termQty: value,
    });
  };

  const onTermTypeChange = (value: Proposal['termType']) => {
    onTermChanges({
      termType: value,
    });
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-1">
        <div>
          <NumberInput
            dataTestId="termQty"
            isDisabled={isDisabled}
            defaultValue={1}
            min={1}
            value={proposal.termQty}
            onBlur={onTermQtyBlur}
          />
        </div>
        <div>
          <Select<Proposal['termType']>
            dataTestId="termType"
            isDisabled={isDisabled}
            onChange={onTermTypeChange}
            value={proposal.termType}
            options={TermTypeRecords.map((termTypeRecord) => ({
              value: termTypeRecord.termType,
              name: termTypeRecord.name,
            }))}
          />
        </div>
      </div>
    </div>
  );
};

export default ProposalTerms;
