import React from 'react';

import { Checkbox, isString } from 'common';

interface Props {
  dataTestId?: string;
  description: string | string[];
  isChecked: boolean;
  isDisabled: boolean;
  label: string;
  onCheck: () => void;
}

const ProductRadioButtonOption: React.FC<Props> = ({
  label,
  description,
  isChecked,
  onCheck,
  isDisabled,
  dataTestId,
}: Props) => (
  <div className="my-1">
    <Checkbox
      value={isChecked}
      name={label}
      onChange={(checked) => {
        // radio button behavior - only allow checking unchecked things
        if (checked) {
          onCheck();
        }
      }}
      label={label}
      isDisabled={isDisabled}
      isRounded
      dataTestId={dataTestId}
    />
    <div className="text-xs pl-[25px] py-1 text-slate-darkest">
      {isString(description)
        ? description
        : description.map((x) => <div key={x}>{x}</div>)}
    </div>
  </div>
);

export default ProductRadioButtonOption;
