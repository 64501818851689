import React, { useEffect } from 'react';
import {
  Button,
  ButtonBar,
  Drawer,
  Icon,
  Item,
  PopOutMenuOption,
  Proposal,
  ReorderTable,
  Type,
  useToast,
  useTranslation,
} from 'common';

import { useBatchUpdateProposalItems } from '../../../../../services/proposal';
import ProductOption from '../ProductOption';

import styles from './RearrangeitemsAction.module.scss';

interface Props {
  proposal: Proposal;
}

const RearrangeItemsAction: React.FC<Props> = ({ proposal }) => {
  const { tk } = useTranslation();
  const toast = useToast();
  const [isOpen, setIsOpen] = React.useState(false);
  const [itemNames, setItemNames] = React.useState<Item[]>(
    proposal.proposalItems
  );
  const { mutate: updateProposalItems, isPending } =
    useBatchUpdateProposalItems(proposal.id, {
      onSuccess: () => {
        toast.success(tk('Updated'));
        setIsOpen(false);
      },
    });

  const title = tk('Rearrange items');

  useEffect(() => {
    setItemNames(proposal.proposalItems);
  }, [proposal, isOpen]);

  const handleReorder = (newState: Item[]) => {
    setItemNames(newState);
  };

  const handleSave = () => {
    const proposalItems = itemNames.map((item) => ({ id: item.id }));
    updateProposalItems({ id: proposal.id, body: proposalItems });
  };

  return (
    <>
      <PopOutMenuOption
        icon={Icon.SortAscending}
        isDisabled={proposal.proposalItems.length < 2}
        onClick={() => setIsOpen(true)}
        title={title}
      />
      <Drawer
        footer={
          <ButtonBar>
            <Button
              isDisabled={isPending}
              label="Cancel"
              onClick={() => setIsOpen(false)}
              type="secondary"
            />
            <Button isLoading={isPending} label="Save" onClick={handleSave} />
          </ButtonBar>
        }
        header={title}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Type className={styles.helperText}>
          Drag and drop the items below to arrange their order of appearance in
          the proposal.
        </Type>

        <ReorderTable
          cells={[
            {
              key: 'render',
              renderCell: (item) => (
                <ProductOption option={item.product} proposal={proposal} />
              ),
            },
          ]}
          disableHeader
          disableShadow
          isLoading={isPending}
          onReorder={handleReorder}
          rows={itemNames}
        />
      </Drawer>
    </>
  );
};

export default RearrangeItemsAction;
