import React, { useState } from 'react';
import { Select } from 'antd';

import {
  ApprovalRule,
  Button,
  ConfirmDeleteModal,
  Icon,
  isArray,
  isString,
  ProposalBillingPeriodsRuleConfig,
  ProposalPaymentTermsRuleConfig,
  ProposalSummary,
} from 'common';

import { MultiSelectRuleOptions } from './useMultiSelectRuleData';
import { GroupOption, searchFilterOption } from './_approvalRulesConfig';

import styles from './ApprovalRuleStyles.module.scss';
import { getConfig } from './ruleUtils';

interface Props {
  allGroupOptions: GroupOption[];
  approvalRule: ApprovalRule;
  conditionOperator: string | string[];
  configKey: string;
  description: string;
  multiSelectRuleOptions: MultiSelectRuleOptions;
  onRuleChange: (id: string, changedRule: ApprovalRule | null) => void;
  proposalType: ProposalSummary['proposalType'];
}

const isValidRuleType = (key: any): key is keyof MultiSelectRuleOptions => {
  const validKeys: (keyof MultiSelectRuleOptions)[] = [
    'proposalPaymentTerms',
    'proposalBillingPeriods',
  ];
  return validKeys.includes(key);
};

export const ApprovalRuleMultiselectRow = ({
  approvalRule,
  configKey,
  description,
  allGroupOptions,
  onRuleChange,
  proposalType,
  conditionOperator,
  multiSelectRuleOptions,
}: Props) => {
  const getValue = (): string[] => {
    const config = getConfig<
      ProposalBillingPeriodsRuleConfig | ProposalPaymentTermsRuleConfig
    >(approvalRule);

    if (
      config &&
      (configKey === 'billingPeriods' || configKey === 'paymentTerms')
    ) {
      const configValue = config[configKey as keyof typeof config];
      const getStringArray = (v: unknown): string[] =>
        isArray(v) ? v.filter(isString) : [];

      return getStringArray(configValue);
    }

    return [];
  };

  const [values, setValues] = useState<string[]>(getValue());
  const [showSelectError, setShowSelectError] = useState<boolean>(false);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const optionsForRule = isValidRuleType(approvalRule.config?.ruleType)
    ? multiSelectRuleOptions[approvalRule.config.ruleType]
    : [];

  const updateRule = (newValue: string[]): ApprovalRule => ({
    id: approvalRule.id,
    name: approvalRule.name,
    config: {
      ruleType: approvalRule.config?.ruleType!,
      proposalType,
      [configKey]: newValue,
    },
  });

  const handleSelectChange = (selectedValues: string[]) => {
    setValues(selectedValues);
    onRuleChange(approvalRule.id!, updateRule(selectedValues));
  };

  const onGroupsChange = (groupIds: string[]) => {
    setShowSelectError(groupIds.length === 0);

    const payload = {
      ...updateRule(values),
      approvalGroupIds: groupIds,
    };

    onRuleChange(approvalRule.id!, payload);
  };

  return (
    <div className={styles.ruleRow}>
      <div className={styles.name}>
        <Button
          primaryIcon={Icon.CloseCircle}
          onClick={() => setIsDeleteConfirmationOpen(true)}
          type="secondary"
          className={styles.removeIcon}
        />
        <span className={styles.ruleName}>{description}</span>
      </div>

      <div className={styles.condition}>{conditionOperator}</div>

      <div className={styles.input}>
        <Select
          onChange={handleSelectChange}
          value={values}
          disabled={!approvalRule}
          mode="multiple"
          className={styles.input}
          options={optionsForRule}
        />
      </div>

      <div className={styles.groups}>
        {allGroupOptions.length > 0 && (
          <Select
            onChange={onGroupsChange}
            value={approvalRule.approvalGroupIds?.map((id) => id)}
            options={allGroupOptions}
            mode="multiple"
            filterOption={searchFilterOption}
            className={styles.groupWidth}
            placeholder="Select approval group"
            disabled={!approvalRule}
            status={showSelectError ? 'error' : ''}
          />
        )}
      </div>

      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onConfirm={() => onRuleChange(approvalRule.id!, null)}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        typeName="condition"
      />
    </div>
  );
};
