import React from 'react';
import ExternalDealsPage from '../ExternalDealsPage';
import { NavigationIcon } from 'common';

const CloseOpportunities: React.FC = () => {
  return (
    <ExternalDealsPage
      sourceType="close"
      dealLabel="opportunity"
      icon={NavigationIcon.CloseOpportunity}
      dealQueryParamName="opportunityId"
    />
  );
};

export default CloseOpportunities;
