import { Contract, ContractSummary, Proposal } from 'common';
import {
  isActiveChangeProposal,
  isActiveRenewalProposal,
  isExpiredChangeProposal,
  isExpiredRenewalProposal,
} from 'app/src/pages/Proposals/ProposalEditor/proposalUtilities';

export const isContractManaged = (contract: Contract | ContractSummary) => {
  return contract.billingMethod && contract.billingMethod.status !== 'external';
};

export interface ChangeRenewalState {
  changeProposal?: Proposal;
  renewalProposal?: Proposal;
}

export const getChangeRenewalState = (
  contractProposals?: Proposal[]
): ChangeRenewalState => {
  const expiredChangeProposal = contractProposals?.find((proposal) =>
    isExpiredChangeProposal(proposal)
  );

  const activeChangeProposal = contractProposals?.find((proposal) =>
    isActiveChangeProposal(proposal)
  );

  const expiredRenewalProposal = contractProposals?.find((proposal) =>
    isExpiredRenewalProposal(proposal)
  );

  const activeRenewalProposal = contractProposals?.find((proposal) =>
    isActiveRenewalProposal(proposal)
  );

  const changeProposal = expiredChangeProposal
    ? expiredChangeProposal
    : activeChangeProposal;

  const renewalProposal = expiredRenewalProposal
    ? expiredRenewalProposal
    : activeRenewalProposal;

  return {
    changeProposal,
    renewalProposal,
  };
};
