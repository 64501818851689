import React from 'react';

import { CardHeader, CardRowValue, Contract, useFlags } from 'common';

import { AdvanceDateOptions } from 'app/src/core-utils/helperFunctions/types';

import styles from './InvoiceInAdvanceBillingSection.module.scss';

const InvoiceInAdvanceDatesMap = {
  // eslint-disable-next-line camelcase
  advance_date: 'Advance date',
  // eslint-disable-next-line camelcase
  billing_period_start_date: 'Billing period start date',
};

const getReadableDateString = (invoiceInAdvanceDate: AdvanceDateOptions) => {
  return InvoiceInAdvanceDatesMap[invoiceInAdvanceDate];
};

interface Props {
  contract: Contract;
}

export const InvoiceInAdvanceBillingSection = ({ contract }: Props) => {
  const { invoiceInAdvance: invoiceInAdvanceFlag } = useFlags();

  const invoiceConfiguration = contract.invoiceConfiguration;
  const renewalConfiguration = contract.renewalConfiguration;

  if (!invoiceInAdvanceFlag || !invoiceConfiguration) {
    return null;
  }

  return (
    <>
      <CardHeader name="Invoice in advance" className={styles.cardHeader} />
      {!renewalConfiguration && (
        <div className={styles.warningMessage}>
          Warning: auto-renew is not enabled for this subscription. Invoices
          cannot be sent in advance for future terms unless the subscription is
          set to auto-renew or is manually renewed.
        </div>
      )}
      <CardRowValue label="Days in advance">
        <div>{invoiceConfiguration.invoiceInAdvanceDays}</div>
      </CardRowValue>
      <CardRowValue label="Invoice date">
        <div>
          {getReadableDateString(invoiceConfiguration.invoiceInAdvanceDate!)}
        </div>
      </CardRowValue>
    </>
  );
};
