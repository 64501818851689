import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  ButtonBar,
  CardRowValue,
  ConfirmDeleteIdModal,
  Contact,
  CreateContactRequest,
  emailValidator,
  FormField,
  getErrorCode,
  getErrorMessage,
  Input,
  Loading,
  TruncateOption,
  truncateText,
  UpdateContactRequest,
  useFormValidation,
  useToast,
  useTranslation,
} from 'common';
import React, { useEffect, useState } from 'react';
import ExternalEntityLink from '../../../components/ExternalEntityLink';
import {
  useCreateContact,
  useDeleteContactAPI,
  useUpdateContactAPI,
} from '../../../services/contacts';
import IDField from 'app/src/components/IDField';
import styles from './ContactForm.module.scss';

const getEmptyDraft = (starterName?: string): CreateContactRequest => ({
  firstName: starterName ?? '',
  lastName: '',
  email: '',
  role: '',
});

interface Props {
  contactStarterName?: string;
  contactToEdit?: Contact;
  customerId: string;
  hideDeleteButton?: boolean;
  isBeingViewed?: boolean;
  onClose: () => void;
  onDeleteSuccess?: () => void;
  onSaveSuccess?: (contact: Contact) => void;
}

const ContactForm: React.FC<Props> = ({
  customerId,
  contactToEdit,
  onClose,
  onSaveSuccess,
  onDeleteSuccess,
  isBeingViewed,
  hideDeleteButton = false,
  contactStarterName,
}: Props) => {
  const isNewContact = !contactToEdit;
  const { tk } = useTranslation();
  const [contactDraft, setContactDraft] = useState<UpdateContactRequest>(
    contactToEdit ?? getEmptyDraft(contactStarterName)
  );
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<Contact>(
      [
        {
          fieldName: 'firstName',
          isRequired: true,
          humanReadableName: 'First name',
        },
        {
          fieldName: 'lastName',
          isRequired: true,
          humanReadableName: 'Last name',
        },
        {
          fieldName: 'email',
          isRequired: true,
          validator: emailValidator,
          humanReadableName: 'Email',
        },
      ],
      contactDraft
    );

  useEffect(() => {
    setContactDraft(contactToEdit || getEmptyDraft(contactStarterName));
    resetUIState();
  }, [contactToEdit, isBeingViewed]);

  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleFieldChange = (fieldName: keyof Contact, value: string): void => {
    setContactDraft((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSaveSuccess = (created: Contact) => {
    showToast.info(tk(isNewContact ? 'Created contact' : 'Updated contact'));
    if (onSaveSuccess) {
      onSaveSuccess(created);
    }
  };

  const handleSaveError = (error: unknown) => {
    const errorCode = getErrorCode(error);

    if (errorCode === 'CUSTOMER_EMAIL_ALREADY_EXIST') {
      showToast.error(
        `Email ${contactDraft.email} already in use by an existing contact`
      );
    } else {
      showToast.error(
        isNewContact
          ? tk('Failed to create contact')
          : tk('Failed to update contact')
      );
    }
  };

  const { mutate: createContact, isPending: isCreating } = useCreateContact(
    handleSaveSuccess,
    handleSaveError,
    queryClient
  );

  const { mutate: updateContact, isPending: isUpdating } = useUpdateContactAPI(
    handleSaveSuccess,
    handleSaveError,
    queryClient
  );

  const handleDeleteSuccess = (): void => {
    showToast.info(tk('Deleted contact'));
    if (onDeleteSuccess) {
      onDeleteSuccess();
    }
    setIsDeleteConfirmationOpen(false);
  };

  const handleDeleteError = (error: unknown) => {
    const errorMessage = getErrorMessage(error);
    if (errorMessage) {
      showToast.error(tk(errorMessage));
    }
    setIsDeleteConfirmationOpen(false);
  };

  const { mutate: deleteContact, isPending: isDeleting } = useDeleteContactAPI(
    handleDeleteSuccess,
    handleDeleteError,
    queryClient
  );

  const save = () => {
    if (isNewContact) {
      const createBody = {
        ...contactDraft,
      } as CreateContactRequest;

      createContact({ id: customerId, params: createBody });
    } else if (contactToEdit.id) {
      const updateBody = {
        ...contactDraft,
      } as UpdateContactRequest;

      updateContact({ id: contactToEdit.id, params: updateBody });
    }
  };

  const submitIfValid = () => {
    if (isFormValid) {
      save();
    }
  };

  return (
    <div className="w-full">
      {contactToEdit && (
        <div className="mb-2 flex justify-end">
          <ExternalEntityLink entity={contactToEdit} showId />
        </div>
      )}
      {contactToEdit?.id && (
        <CardRowValue
          className={styles.customerObjectId}
          label="Id:"
          leftClassName={styles.contactIdLabel}
        >
          <IDField documentID={contactToEdit.id}>
            <div className="tabular-nums">
              {truncateText(contactToEdit.id, 8, TruncateOption.WithoutDots)}
            </div>
          </IDField>
        </CardRowValue>
      )}
      <FormField
        errorToShow={getErrorToShow('firstName')}
        label={tk('First name')}
      >
        <Input
          id="contact_first_name_input"
          onBlur={() => setHasVisitedField('firstName')}
          onChange={(rv) => handleFieldChange('firstName', rv)}
          onEnter={submitIfValid}
          value={contactDraft.firstName ?? ''}
        />
      </FormField>
      <FormField
        errorToShow={getErrorToShow('lastName')}
        label={tk('Last name')}
      >
        <Input
          id="contact_last_name_input"
          onBlur={() => setHasVisitedField('lastName')}
          onChange={(rv) => handleFieldChange('lastName', rv)}
          onEnter={submitIfValid}
          value={contactDraft.lastName ?? ''}
        />
      </FormField>
      <FormField label={tk('Role (optional)')}>
        <Input
          id="contact_role_input"
          onChange={(rv) => handleFieldChange('role', rv)}
          onEnter={submitIfValid}
          value={contactDraft.role ?? ''}
        />
      </FormField>
      <FormField errorToShow={getErrorToShow('email')} label={tk('Email')}>
        <Input
          id="contact_email_input"
          onBlur={() => setHasVisitedField('email')}
          onChange={(rv) => handleFieldChange('email', rv)}
          onEnter={submitIfValid}
          value={contactDraft.email ?? ''}
        />
      </FormField>

      <ButtonBar
        className="pt-6"
        left={
          !isNewContact &&
          !hideDeleteButton && (
            <Button
              data-testid="contact-delete"
              isDangerous
              label="Delete"
              onClick={() => setIsDeleteConfirmationOpen(true)}
              type="link"
            />
          )
        }
      >
        <Button label={tk('Cancel')} onClick={onClose} type="secondary" />

        <Button
          dataTestId="contact-save"
          isDisabled={!isFormValid}
          label={tk('Save')}
          onClick={save}
        />
      </ButtonBar>

      {(isCreating || isUpdating || isDeleting) && <Loading />}

      <ConfirmDeleteIdModal
        id={contactToEdit?.id}
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={(id) => deleteContact(id)}
        typeName="contact"
      />
    </div>
  );
};

export default ContactForm;
