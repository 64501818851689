import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Button,
  ButtonBar,
  Filter,
  Icon,
  Organization,
  QueryTable,
  SimpleCell,
} from 'common';
import { SYSTEM_LOGIN_ROUTE } from '../../core-utils/routes';
import { LOGGED_OUT_STATE, useAuthState } from '../../Auth';
import OrganizationForm from './OrganizationForm';
import StatusLabel from '../../components/StatusLabel';
import LoginAsButton from './LoginAsButton/LoginAsButton';
import IDField from 'app/src/components/IDField';
import { getOrgAppBase } from '../../services/helper';
import { canAccessSystemAdminRoutesWithContext } from '../../core-utils/helperFunctions/userServiceHelper';
import Page from '../../components/Page';
import { systemClient } from 'app/src/services/httpClients/systemclient';

enum IdentityStatus {
  active = 'Active',
  inactive = 'Inactive',
  pending = 'Pending',
}

const SystemOrganizationsPage: React.FC = () => {
  const { auth, setAuth } = useAuthState();
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>();

  const [openModal, setOpenModal] = useState(false);

  if (!canAccessSystemAdminRoutesWithContext(auth)) {
    setAuth(LOGGED_OUT_STATE);

    return <Navigate replace to={SYSTEM_LOGIN_ROUTE} />;
  }

  const handleClick = () => {
    if (openModal) {
      setSelectedOrganization(undefined);
    }
    setOpenModal((prevState) => !prevState);
  };

  const cells: SimpleCell<Organization>[] = [
    {
      key: 'icon',
      align: 'center',
      renderCell: (cell: Organization) => (
        <Link to={`/system/organizations/${cell.id}`} className="text-primary">
          <Icon.Company />
        </Link>
      ),
      width: '50px',
    },
    {
      key: 'name',
      headerLabel: 'Name',
      width: '15%',
      overflowCell: true,
      renderCell: (cell: Organization) => (
        <div>
          <div>{cell.name}</div>
        </div>
      ),
    },
    {
      key: 'domainName',
      headerLabel: 'Domain name',
      sortable: true,
      searchable: true,
      width: '15%',
      overflowCell: true,
      renderCell: (cell: Organization) => (
        <div>
          <div>{cell.domainName}</div>
        </div>
      ),
    },
    {
      key: 'id',
      headerLabel: 'Org ID',
      searchable: true,
      width: '300px',
      renderCell: (cell: Organization) => (
        <div>
          <div className="font-[monospace] text-xs">{cell.id}</div>
        </div>
      ),
    },
    {
      key: 'flowDomain',
      headerLabel: 'Login URL',
      width: '200px',
      renderCell: (cell: Organization) =>
        cell.flowDomain ? (
          <IDField documentID={getOrgAppBase(cell.flowDomain)}>
            <div>{getOrgAppBase(cell.flowDomain).substring(0, 20)}....</div>
          </IDField>
        ) : null,
    },

    {
      key: 'status',
      headerLabel: 'Status',
      align: 'center',
      width: '140px',
      sortable: true,
      searchable: true,
      renderCell: (cell: Organization) => (
        <StatusLabel status={cell.status} feature="organization" />
      ),
    },
    {
      key: 'options',
      align: 'right',
      headerLabel: 'Options',
      width: '160px',
      clickable: true,
      renderCell: (cell: Organization) => {
        return (
          <ButtonBar>
            {cell.id && <LoginAsButton orgId={cell.id} dataTestId={cell.id} />}
            <Button //restricting edit org for now till further api development.
              onClick={() => {
                setSelectedOrganization(cell);
                setOpenModal(true);
              }}
              label="Edit"
            />
          </ButtonBar>
        );
      },
    },
  ];

  const filters: Filter[] = [
    {
      type: 'search',
      key: 'search',
      searchFields: ['name', 'flowDomain'],
    },
    {
      type: 'dropdown',
      key: 'status',
      humanReadableName: 'Status',
      fieldName: 'status',
      options: Object.keys(IdentityStatus).map((key) => ({
        value: key,
        name: IdentityStatus[key as keyof typeof IdentityStatus].valueOf(),
      })),
    },
  ];

  return (
    <Page
      leftWidget="Admin dashboard"
      rightWidget={
        <Button onClick={() => setOpenModal(true)} label="Add organization" />
      }
    >
      {openModal && (
        <OrganizationForm
          open={openModal}
          onClose={handleClick}
          organizationData={selectedOrganization}
        />
      )}
      <QueryTable<Organization>
        queryKey="organizations"
        cells={cells}
        fetchList={systemClient.listSystemOrgs}
        filters={filters}
        zeroStateMessage="There are currently no organizations."
      />
    </Page>
  );
};

export default SystemOrganizationsPage;
