import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from 'common';
import { useDeleteAPIWebhook } from '../../../../services/api/webhook/webhook';

export const useDeleteWebhook = () => {
  const [deleteState, setDeleteState] = useState(false);
  const [webhookId, setWebhookId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleDeleteError = useCallback(() => {
    showToast.error('Failed to delete webhook. Please try again later.');
  }, []);

  const handleDeleteSuccess = useCallback(() => {
    setDeleteState(false);
  }, []);

  const { mutate: deleteToken } = useDeleteAPIWebhook(
    handleDeleteSuccess,
    handleDeleteError,
    queryClient
  );

  const deleteHandler = (id: string) => {
    deleteToken(id);
  };

  const handleDeleteModalClose = () => {
    setDeleteState(false);
  };

  return {
    deleteHandler,
    deleteToken,
    handleDeleteModalClose,
    setDeleteState,
    setWebhookId,
    webhookId,
    deleteState,
  };
};
