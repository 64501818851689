import React, { useState } from 'react';
import ProposalSection from '../ProposalSection/ProposalSection';
import {
  BillingPeriodsType,
  Button,
  ConfirmModal,
  getBillingPeriodMonths,
  getProductBillingPeriod,
  getTermTypeLength,
  Icon,
  isDraftProposalStatus,
  Item,
  ItemLevelOverride,
  ItemOverride,
  PricebookEntry,
  Product,
  ProductSummary,
  ProductUpgrade,
  Proposal,
  ProposalItemRequest,
  QuantityOverride,
  Type,
  useFlags,
  useToast,
  useTranslation,
} from 'common';
import { AppProducts } from './AppProducts';
import {
  useAddProposalItem,
  useBatchUpdateProposalItems,
  useGetProposalUpgrades,
  useUpdateProposalItem,
} from '../../../../services/proposal';
import PeriodOverPeriodPriceAdjustmentModal from './PeriodOverPeriodPriceAdjustmentModal';
import { checkIfIsValidProductForProposal } from '../../../../core-utils/helperFunctions/productUtils';
import ProposalProductSelect from './ProposalProductSelect';
import styles from './ProductsSection.module.scss';

interface Props {
  billingPeriod: BillingPeriodsType;
  isDisabled: boolean;
  isSaving: boolean;
  proposal: Proposal;
}

export const ProductsSectionDeprecated: React.FC<Props> = ({
  billingPeriod,
  isDisabled,
  isSaving: isSavingProposal,
  proposal,
}: Props) => {
  const { tk } = useTranslation();
  const { periodOverPeriodAdj } = useFlags();
  const showToast = useToast();
  const [filteredUpgrades, setFilteredUpgrades] = useState<ProductUpgrade[]>(
    []
  );
  const [
    periodOverPeriodPriceAdjustmentModalOpen,
    setPeriodOverPeriodPriceAdjustmentModalOpen,
  ] = React.useState<boolean>(false);

  const items: Item[] = proposal.proposalItems;

  const [showInvoiceInAdvanceModal, setShowInvoiceInAdvanceModal] =
    useState(false);

  const { mutate: addProposalItem, isPending: isPendingAdd } =
    useAddProposalItem(proposal.id);

  const { mutate: updateProposalItem, isPending: isPendingUpdate } =
    useUpdateProposalItem(proposal.id);

  const { mutate: updateProposalItems, isPending: isPendingUpdateBatch } =
    useBatchUpdateProposalItems(proposal.id, {
      onError: () => showToast.error('Batch Item update failed'),
    });

  const isSaving: boolean =
    isSavingProposal || isPendingAdd || isPendingUpdate || isPendingUpdateBatch;

  const onAddProduct = (product: ProductSummary) => {
    if (checkIfIsValidProductForProposal(product, proposal)) {
      if (!!proposal.invoiceConfiguration && product.recurrence === 'usage') {
        setShowInvoiceInAdvanceModal(true);
      }

      const newItem: ProposalItemRequest = {
        productId: product.id,
        quantity:
          product.consumption === 'multiple' && product.recurrence !== 'usage'
            ? 1
            : undefined,
      };

      addProposalItem(newItem);
    }
  };

  const onUpdateItemOverrides = (
    itemId: string,
    overrides: ItemOverride[] | undefined,
    quantityOverrides: QuantityOverride[] | undefined
  ) => {
    updateProposalItem({
      id: itemId,
      body: { overrides, quantityOverrides },
    });
  };

  const onUpgradeProduct = (itemId: string, upgradeProduct: Product) => {
    updateProposalItem({
      id: itemId,
      body: { productId: upgradeProduct.id },
    });
  };

  const { data: upgrades } = useGetProposalUpgrades(
    proposal.id,
    isDraftProposalStatus(proposal)
  );

  React.useEffect(() => {
    const filtered: ProductUpgrade[] = [];
    upgrades?.forEach((upgrade) => {
      if (upgrade.changedFields.includes('entries')) {
        const entries = upgrade.entryChanges?.filter((entry) => {
          return entry.currency === proposal.currency;
        });
        if (entries?.length) {
          filtered.push({ ...upgrade, entryChanges: entries });
        }
      } else {
        filtered.push(upgrade);
      }
    });
    setFilteredUpgrades(filtered);
  }, [upgrades]);

  const getNewLevelOverrides = (
    adjustment: number,
    baseAdjustmentPrice: number,
    level: number,
    override: ItemOverride,
    baseAdjustmentPrices: PricebookEntry[],
    index: number
  ) => {
    const overrideLevel: ItemLevelOverride = {
      price: {
        amount: Math.round(adjustment * baseAdjustmentPrice),
      },
      level,
    };
    override.levels?.push(overrideLevel);
    return [
      ...baseAdjustmentPrices.slice(0, index),
      {
        ...baseAdjustmentPrices[index],
        price: {
          amount: Math.round(adjustment * baseAdjustmentPrice),
        },
      },
      ...baseAdjustmentPrices.slice(index + 1),
    ];
  };

  const saveProductPeriodOverPeriodPriceAdjustment = (
    nValue: number, // percent increase/decrease sent as a decimal 0.05 = 5%
    bp: BillingPeriodsType,
    isIncrease: boolean
  ) => {
    const bpCadence = getBillingPeriodMonths(bp);
    const proposalItemsToAdjust = proposal.proposalItems.filter((item) => {
      return item.product.recurrence === 'recurring';
    });
    const proposalItemsToNOTAdjust = proposal.proposalItems.filter((item) => {
      return item.product.recurrence !== 'recurring';
    });
    let newOverrides: Item[] = [];
    let termLengthMonths =
      proposal.termLengthMonths ||
      (proposal.termQty &&
        proposal.termQty * getTermTypeLength(proposal.termType)) ||
      0;
    if (proposal.freeMonths) {
      termLengthMonths -= proposal.freeMonths;
    }

    if (!termLengthMonths && termLengthMonths > 0) {
      throw new Error('Missing term length for proposal');
    }
    if (isIncrease) {
      newOverrides = proposalItemsToAdjust.map((item) => {
        const productBillingPeriod = getProductBillingPeriod(
          item.product,
          billingPeriod
        );
        const billingPeriodCadence =
          getBillingPeriodMonths(productBillingPeriod);

        if (bpCadence < billingPeriodCadence) {
          throw new Error(
            'Invalid billing period for period over period price adjustments'
          );
        }

        const adjustmentPeriodsLength = bpCadence / billingPeriodCadence;

        const numberOfAdjustments = Math.ceil(termLengthMonths / bpCadence);
        const overrides: ItemOverride[] = [];

        let baseAdjustmentPrices = item.product.entries.filter(
          (entry) =>
            entry.billingPeriod === productBillingPeriod &&
            entry.price.currency === proposal.currency
        );
        if (!baseAdjustmentPrices.length) {
          throw new Error(
            'Missing product pricebook entries for period over period price adjustments'
          );
        }
        let totalDuration = adjustmentPeriodsLength;
        // first adjustment is always equal to baseAdjustmentPrice therefore, there is no override needed
        for (let i = 1; i < numberOfAdjustments; i++) {
          const adjustment = 1 + nValue;
          if (baseAdjustmentPrices.length > 1) {
            // levels
            const override: ItemOverride = {
              duration:
                totalDuration + adjustmentPeriodsLength < termLengthMonths
                  ? adjustmentPeriodsLength
                  : termLengthMonths - totalDuration,
              discount: false,
              levels: [],
              startIndex: adjustmentPeriodsLength * i,
              billingPeriod: productBillingPeriod,
            };
            totalDuration += adjustmentPeriodsLength;
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            baseAdjustmentPrices.forEach((entry, index) => {
              const baseAdjustmentPrice: number | undefined =
                entry.price.amount;
              if (baseAdjustmentPrice) {
                baseAdjustmentPrices = getNewLevelOverrides(
                  adjustment,
                  baseAdjustmentPrice,
                  entry.level || index,
                  override,
                  baseAdjustmentPrices,
                  index
                );
              }
            });
            overrides.push(override);
          } else {
            const baseAdjustmentPrice = baseAdjustmentPrices[0]?.price?.amount;
            if (baseAdjustmentPrice) {
              const override: ItemOverride = {
                price: {
                  amount: Math.round(adjustment * baseAdjustmentPrice),
                },
                duration:
                  totalDuration + adjustmentPeriodsLength < termLengthMonths
                    ? adjustmentPeriodsLength
                    : termLengthMonths - totalDuration,
                discount: false,
                startIndex: adjustmentPeriodsLength * i,
                billingPeriod: productBillingPeriod,
              };
              totalDuration += adjustmentPeriodsLength;
              baseAdjustmentPrices = [
                {
                  ...baseAdjustmentPrices[0],
                  price: {
                    amount: Math.round(adjustment * baseAdjustmentPrice),
                  },
                },
              ]; // adjustments compound
              overrides.push(override);
            }
          }
        }
        item.overrides = overrides;
        return item;
      });
    } else {
      newOverrides = proposalItemsToAdjust.map((item) => {
        const productBillingPeriod = getProductBillingPeriod(
          item.product,
          billingPeriod
        );
        const billingPeriodCadence =
          getBillingPeriodMonths(productBillingPeriod);
        if (bpCadence < billingPeriodCadence) {
          throw new Error(
            'Invalid billing period for period over period price adjustments'
          );
        }
        const adjustmentPeriodsLength = bpCadence / billingPeriodCadence;

        const numberOfAdjustments = Math.ceil(termLengthMonths / bpCadence);

        const overrides: ItemOverride[] = [];

        let baseAdjustmentPrices = item.product.entries.filter(
          (entry) =>
            entry.billingPeriod === productBillingPeriod &&
            entry.price.currency === proposal.currency
        );
        if (baseAdjustmentPrices.length === 0) {
          throw new Error(
            'Missing product pricebook entries for period over period price adjustments'
          );
        }
        let totalDuration = adjustmentPeriodsLength;
        for (let i = numberOfAdjustments - 1; i > 0; i--) {
          const adjustment = 1 - nValue;
          if (baseAdjustmentPrices.length > 1) {
            // levels
            const override: ItemOverride = {
              duration:
                totalDuration + adjustmentPeriodsLength < termLengthMonths
                  ? adjustmentPeriodsLength
                  : termLengthMonths - totalDuration,
              discount: false,
              levels: [],
              startIndex: adjustmentPeriodsLength * (i - 1),
              billingPeriod: productBillingPeriod,
            };
            totalDuration += adjustmentPeriodsLength;
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            baseAdjustmentPrices.forEach((entry, index) => {
              const baseAdjustmentPrice: number | undefined =
                entry.price.amount;
              if (baseAdjustmentPrice) {
                baseAdjustmentPrices = getNewLevelOverrides(
                  adjustment,
                  baseAdjustmentPrice,
                  entry.level || index,
                  override,
                  baseAdjustmentPrices,
                  index
                );
              }
            });
            overrides.push(override);
          } else {
            const baseAdjustmentPrice = baseAdjustmentPrices[0]?.price?.amount;
            if (baseAdjustmentPrice) {
              const override: ItemOverride = {
                price: {
                  amount: Math.round(adjustment * baseAdjustmentPrice),
                },
                duration:
                  totalDuration + adjustmentPeriodsLength < termLengthMonths
                    ? adjustmentPeriodsLength
                    : termLengthMonths - totalDuration,
                discount: false,
                startIndex: adjustmentPeriodsLength * (i - 1),
                billingPeriod: productBillingPeriod,
              };
              totalDuration += adjustmentPeriodsLength;
              baseAdjustmentPrices = [
                {
                  ...baseAdjustmentPrices[0],
                  price: {
                    amount: Math.round(adjustment * baseAdjustmentPrice),
                  },
                },
              ]; // adjustments compound
              overrides.push(override);
            }
          }
        }
        item.overrides = overrides;
        return item;
      });
    }

    updateProposalItems({
      id: proposal.id,
      body: [...newOverrides, ...proposalItemsToNOTAdjust],
    });
    setPeriodOverPeriodPriceAdjustmentModalOpen(false);
  };

  const getProductsHeader = () => {
    return (
      <div className={styles.headerSection}>
        <div>Products</div>
        {periodOverPeriodAdj && isDraftProposalStatus(proposal) && (
          <Button
            iconBefore={Icon.Edit}
            isDisabled={isDisabled}
            label={tk('Period over period price adjustment')}
            onClick={() => setPeriodOverPeriodPriceAdjustmentModalOpen(true)}
            size="small"
            type="link"
          />
        )}
      </div>
    );
  };
  return (
    <ProposalSection
      isDisabled={isDisabled}
      isRemovable={false}
      noPadding
      openOnLoad
      section="Products"
      sectionRef="Products"
      title={getProductsHeader()}
    >
      <div className={styles.productsSectionLegacy}>
        <PeriodOverPeriodPriceAdjustmentModal
          billingPeriod={billingPeriod}
          isOpen={periodOverPeriodPriceAdjustmentModalOpen}
          onClose={() => setPeriodOverPeriodPriceAdjustmentModalOpen(false)}
          onSave={saveProductPeriodOverPeriodPriceAdjustment}
        />

        <AppProducts
          billingPeriod={billingPeriod}
          hideDiscounts={proposal.options?.hideDiscounts ?? false}
          isDisabled={isDisabled}
          isSaving={isSaving}
          items={items}
          onUpdateOverrides={onUpdateItemOverrides}
          onUpgradeProduct={onUpgradeProduct}
          proposal={proposal}
          upgrades={filteredUpgrades}
        />

        <ProposalProductSelect
          className="mb-6"
          isDisabled={isDisabled}
          onAddProduct={onAddProduct}
          proposal={proposal}
        />

        <ConfirmModal
          isOpen={showInvoiceInAdvanceModal}
          onConfirm={() => {
            setShowInvoiceInAdvanceModal(false);
          }}
        >
          <Type paragraph>
            Adding usage-based products disables the invoice in advance setting
            on the proposal.
          </Type>
        </ConfirmModal>
      </div>
    </ProposalSection>
  );
};
