import { closeTabs } from './closeTabs';
import { ConnectorInfo, OrgConfig } from 'common';

const CLOSE_CONFIG: OrgConfig['configKey'] = 'closeConfig';
const CLOSE: string = 'Close';
const CLOSE_SOURCE: ConnectorInfo['source'] = 'close';

export const CLOSE_INFO = {
  configType: CLOSE_CONFIG,
  source: CLOSE_SOURCE,
  name: CLOSE,
  showSaveButton: true,
};

export { closeTabs, CLOSE_CONFIG };
