import React from 'react';
import ConnectorSettings, {
  ConnectorTab,
} from '../Connectors/ConnectorSettings';
import { accountContactsConfig, WARMLY_INFO } from './constants';
import { Image } from 'antd';
import warmlyLogo from '../../../assets/integration-logos/warmly.png';
import { ConnectorTabs } from '../../../core-utils/enums/ConnectorTabs';
import { ConnectorConfiguration } from '../Connectors/ConnectorConstants/constants';

const WarmlySettings = () => {
  const tabs: ConnectorTab[] = [
    {
      key: ConnectorTabs.configuration,
      label: 'Configuration',
      systemOnly: false,
    },
  ];

  const configuration: ConnectorConfiguration = {
    name: null,
    lines: [
      {
        fieldName: 'enabled',
        type: 'toggle',
      },
      {
        fieldName: 'clientId',
        type: 'string',
      },
    ],
  };

  return (
    <ConnectorSettings
      accountContactsConfig={accountContactsConfig}
      configuration={configuration}
      connectorInfo={WARMLY_INFO}
      LogoElement={
        <Image alt="Warmly" src={warmlyLogo} width={72} height={72} />
      }
      tabs={tabs}
    />
  );
};

export default WarmlySettings;
