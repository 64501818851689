import { useState } from 'react';
import { ProductSummary, Proposal, ProposalItemRequest } from 'common';
import { checkIfIsValidProductForProposal } from '../../../../core-utils/helperFunctions/productUtils';
import { useAddProposalItem } from '../../../../services/proposal';

export const useAddProduct = (proposal: Proposal) => {
  const { mutate: addProposalItem } = useAddProposalItem(proposal.id);

  const [isInvoiceInAdvanceModalVisible, setIsInvoiceInAdvanceModalVisible] =
    useState(false);

  const hideInvoiceInAdvanceModal = () => {
    setIsInvoiceInAdvanceModalVisible(false);
  };

  const addProductHandler = (product: ProductSummary) => {
    if (checkIfIsValidProductForProposal(product, proposal)) {
      if (!!proposal.invoiceConfiguration && product.recurrence === 'usage') {
        setIsInvoiceInAdvanceModalVisible(true);
      }

      const newItem: ProposalItemRequest = {
        productId: product.id,
        quantity:
          product.consumption === 'multiple' && product.recurrence !== 'usage'
            ? 1
            : undefined,
      };

      addProposalItem(newItem);
    }
  };

  return {
    isInvoiceInAdvanceModalVisible,
    hideInvoiceInAdvanceModal,
    addProductHandler,
  };
};
