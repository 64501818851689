import {
  BillingPeriodsType,
  Button,
  getBillingPeriodLabelAdjective,
  getPricingDurations,
  getProductBillingPeriodPrice,
  Icon,
  isDefined,
  isNumber,
  Item,
  ItemOverride,
  Proposal,
  QuantityOverride,
  ScheduleLine,
  useTranslation,
} from 'common';
import React, { useState } from 'react';
import styles from './BillingPeriodPriceManagement.module.scss';
import AdjustmentScheduleOverview from '../TimeBasedPricing/TimeBasedPricingDrawer/AdjustmentScheduleOverview';
import clsx from 'clsx';
import { BillingPeriodString } from '../../../PaymentPlan/PaymentPlanContext';
import { TimeBasedPricingDrawer } from '../TimeBasedPricing/TimeBasedPricingDrawer/TimeBasedPricingDrawer';
import PriceOverride from './PriceOverride';

interface Props {
  handleSetOverride: (arg: ItemOverride) => void;
  isSaving: boolean;
  isValidOverride: (override: ItemOverride) => boolean;
  item: Item;
  onUpdateOverrides: (
    itemId: string,
    overrides: ItemOverride[] | undefined,
    quantityOverrides?: QuantityOverride[]
  ) => void;
  proposal: Proposal;
  itemBillingPeriods: BillingPeriodString[];
  scheduleDraft: Record<BillingPeriodsType, ScheduleLine[] | undefined>;
  setScheduleDraft: (
    scheduleDraft: Record<BillingPeriodsType, ScheduleLine[] | undefined>
  ) => void;
}

const FixedProductManagement = ({
  handleSetOverride,
  isSaving,
  isValidOverride,
  item,
  onUpdateOverrides,
  proposal,
  itemBillingPeriods,
  scheduleDraft,
  setScheduleDraft,
}: Props) => {
  const [isScheduleDrawerOpen, setIsScheduleDrawerOpen] = useState(false);
  const [scheduleDrawerBillingPeriod, setScheduleDrawerBillingPeriod] =
    useState<BillingPeriodsType | undefined>(undefined);

  const { tk } = useTranslation();

  const { product } = item;
  const allowListPriceAdjustments = item.product.listPriceAdjustable;
  const { currency } = proposal;
  const isOneTime = product.recurrence === 'one_time';

  if (isOneTime) {
    return (
      <PriceOverride
        isSaving={isSaving}
        handleSetOverride={handleSetOverride}
        item={item}
        currency={currency}
      />
    );
  }

  const isUsage = product.recurrence === 'usage';

  const filteredBillingPeriods: BillingPeriodsType[] = itemBillingPeriods
    .filter((bp) => bp && product.billingPeriods?.includes(bp))
    .map((bp): BillingPeriodsType => bp! as BillingPeriodsType);

  const openScheduleDrawer = (bp: BillingPeriodsType) => {
    setIsScheduleDrawerOpen(true);
    setScheduleDrawerBillingPeriod(bp);
  };
  const checkIfCanScheduleTBD = (bp: BillingPeriodString) => {
    const overrideRange = proposal.overrideRanges.find(
      (range) => range.billingPeriod === bp
    );
    return (
      overrideRange &&
      isNumber(overrideRange.startIndex) &&
      isNumber(overrideRange.endIndex)
    );
  };
  const handleSetScheduleDraft = (
    bp: BillingPeriodsType,
    draft?: ItemOverride[],
    quantityScheduleDraft?: QuantityOverride[]
  ) => {
    const newDraft = { ...scheduleDraft };

    newDraft[bp] =
      draft || quantityScheduleDraft
        ? getPricingDurations(
            proposal.termQty,
            proposal.termType,
            getProductBillingPeriodPrice(item.product, proposal.currency, bp),
            proposal.freeMonths,
            draft,
            quantityScheduleDraft,
            item.quantity
          )
        : undefined;

    setScheduleDraft(newDraft);
  };

  const resetSavedScheduleDraft = (bp: BillingPeriodsType) => {
    const currentDraft = { ...scheduleDraft };
    currentDraft[bp] = undefined;
    handleSetScheduleDraft(bp, undefined);
    const billingPeriodOverrides = (item.overrides ?? []).filter(
      isValidOverride
    );

    const scheduleOverrides = Object.values(currentDraft)
      .filter(isDefined)
      .filter((overrides) => overrides.length > 0)
      .flat();

    const scheduleOverridesFiltered = scheduleOverrides.filter((override) => {
      const money = getProductBillingPeriodPrice(
        product,
        proposal.currency,
        override.billingPeriod
      );
      return money.amount !== override.price.amount;
    });

    const finalResetPriceOverrides = [
      ...billingPeriodOverrides,
      ...scheduleOverridesFiltered,
    ].map((override) => {
      if (allowListPriceAdjustments) return override;

      const { listPrice, levels: previousLevels, ...rest } = override;

      const levels = previousLevels?.map((level) => {
        const { listPrice: levelListPrice, ...levelRest } = level;
        return levelRest;
      });

      return { ...levels, ...rest };
    });

    const finalResetQuantityOverrides =
      item.quantityOverrides?.filter((qo) => qo.billingPeriod !== bp) || [];

    onUpdateOverrides(
      item.id,
      finalResetPriceOverrides,
      finalResetQuantityOverrides
    );
  };

  return (
    <>
      {scheduleDrawerBillingPeriod && (
        <TimeBasedPricingDrawer
          isOpen={isScheduleDrawerOpen}
          item={item}
          onClose={() => setIsScheduleDrawerOpen(false)}
          handleUpdateOverrideSchedule={handleSetScheduleDraft}
          resetSavedScheduleDraft={resetSavedScheduleDraft}
          billingPeriod={scheduleDrawerBillingPeriod}
          currentOverrideSchedule={scheduleDraft[scheduleDrawerBillingPeriod]}
          proposal={proposal}
        />
      )}
      {filteredBillingPeriods.filter(isDefined).map((bp) => {
        const mergedScheduleLine = scheduleDraft[bp];

        const lineClass = clsx(
          mergedScheduleLine && styles.inputRowTimeBasedWithSchedule
        );

        return (
          <div
            key={`discounts-${bp}`}
            className={clsx(
              styles.sectionWrapper,
              mergedScheduleLine && styles.sectionWithSchedule
            )}
            data-testid={`discounts-${bp}`}
          >
            <div className={clsx(styles.bpRow, styles.rowLabels)}>
              {getBillingPeriodLabelAdjective(bp)}
              {!isUsage && checkIfCanScheduleTBD(bp) && (
                <div className={styles.scheduleButtonWrapper}>
                  <Button
                    onClick={() => openScheduleDrawer(bp)}
                    iconBefore={mergedScheduleLine ? null : Icon.Activity}
                    label={
                      mergedScheduleLine
                        ? tk('View price schedule details')
                        : tk('Schedule price')
                    }
                    className={
                      mergedScheduleLine ? styles.buttonWithSchedule : ''
                    }
                    dataTestId={`schedule-discount-${bp}`}
                    type="link"
                  />
                </div>
              )}
            </div>

            <div className={lineClass}>
              {!isDefined(mergedScheduleLine) ? (
                <PriceOverride
                  billingPeriod={bp}
                  currency={currency}
                  handleSetOverride={handleSetOverride}
                  isSaving={isSaving}
                  item={item}
                />
              ) : (
                <AdjustmentScheduleOverview
                  mergedSchedule={mergedScheduleLine}
                  billingPeriod={bp}
                  baseQuantity={item.quantity}
                />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FixedProductManagement;
