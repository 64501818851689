import React from 'react';
import './billTo.scss';
import {
  isChangeProposalType,
  Proposal,
  ProposalLabel,
  Select,
  useTranslation,
} from 'common';
import { useCustomers } from 'app/src/services/customer';

interface Props {
  isDisabled?: boolean;
  proposal: Proposal;
  selectBillTo?: (customerId: string) => void;
}

const BillToSelect: React.FC<Props> = ({
  proposal,
  selectBillTo,
  isDisabled,
}) => {
  const { tk } = useTranslation();
  const { data: customersList, isLoading: customersLoading } = useCustomers();
  const isProposalTypeChange = isChangeProposalType(proposal);
  const isProposalDraft = proposal.status === 'draft';

  const onChange = (id?: string) => {
    if (!id) return;
    selectBillTo?.(id);
  };

  if (!customersList || customersList.length === 0) {
    return null;
  }

  const customers =
    customersList
      .filter(
        (customer) =>
          (proposal.customer?.id && customer.id === proposal.customer.id) ||
          customer.partner
      )
      .map((customer) => ({
        name: customer.name,
        value: customer.id,
      })) || [];

  return (
    !customersLoading &&
    (proposal.status === 'draft' || proposal.billTo !== undefined) &&
    (proposal.customer?.id ? customers.length > 1 : customers.length > 0) && (
      <div className="bill-to-wrapper">
        <ProposalLabel name={tk('Bill to')} />
        <div className="w-[320px]">
          <Select
            onChange={(value) => onChange(value)}
            value={proposal.billTo?.id || proposal.customer?.id}
            placeholder="Select partner"
            dataTestId="billTo"
            options={customers}
            isDisabled={!isProposalDraft || isProposalTypeChange || isDisabled}
          />
        </div>
      </div>
    )
  );
};

export default BillToSelect;
