import React from 'react';
import {
  BillingPeriodsType,
  Button,
  ButtonBar,
  getProductBillingPeriodPrice,
  Item,
  mergeLinesToCombinedSchedule,
  Proposal,
  ScheduleLine,
  useTranslation,
} from 'common';
import styles from './TimeBasedPricing.module.scss';
import AdjustmentScheduleOverview from './AdjustmentScheduleOverview';

interface Props {
  billingPeriod: BillingPeriodsType;
  draftSchedule?: ScheduleLine[];
  item: Item;
  onClose: () => void;
  proposal: Proposal;
  resetSchedule: () => void;
  submit: () => void;
}

const TimeBasedPricingFooter = ({
  onClose,
  submit,
  resetSchedule,
  proposal,
  draftSchedule,
  item,
  billingPeriod,
}: Props) => {
  const { tk } = useTranslation();

  const mergedSchedule = draftSchedule
    ? mergeLinesToCombinedSchedule(draftSchedule)
    : [];
  const money = getProductBillingPeriodPrice(
    item.product,
    proposal.currency,
    billingPeriod
  );

  // if the schedule has more than one override or
  // the single override price/qty is different from the product price
  // then we have changes
  const hasChanges =
    mergedSchedule.length > 1 ||
    mergedSchedule[0]?.price?.amount !== money.amount ||
    mergedSchedule[0]?.quantity !== item.quantity;

  return (
    <div className={styles.timeBasedOverview}>
      <div className={styles.timeBasedOverviewContent}>
        <h3 className={styles.productName}>{item.name}</h3>
        <AdjustmentScheduleOverview
          baseQuantity={item.quantity}
          billingPeriod={billingPeriod}
          mergedSchedule={mergedSchedule}
        />
      </div>
      <ButtonBar>
        {hasChanges ? (
          <Button
            block
            dataTestId="reset-schedule-price-change"
            label={tk('Reset')}
            onClick={resetSchedule}
            type="secondary"
          />
        ) : (
          <Button
            block
            dataTestId="cancel-schedule-price-change"
            label={tk('Cancel')}
            onClick={onClose}
            type="secondary"
          />
        )}
        <Button
          block
          dataTestId="update-schedule-price-change"
          label={tk('Schedule')}
          onClick={submit}
        />
      </ButtonBar>
    </div>
  );
};

export default TimeBasedPricingFooter;
