import { Button, captureError, Proposal, useToast } from 'common';
import React from 'react';
import { useFlowDomain } from '../../../../core-utils/helperFunctions/userServiceHelper';
import ErrorPage from 'checkout/src/components/ErrorPage';
import { getProposalDialogPreviewUrl } from '../../../../services/helper';

interface Props {
  proposal: Proposal;
  proposalHash: string | undefined;
}

const PreviewPanel: React.FC<Props> = ({ proposal, proposalHash }) => {
  const showToast = useToast();
  const orgName = useFlowDomain();

  if (
    proposal.id === undefined ||
    proposal.previewCode === undefined ||
    orgName === undefined
  ) {
    captureError(
      new Error(
        `Proposal id, preview code, or org name is undefined when rendering preview. ${JSON.stringify(
          {
            'proposal.id': proposal.id,
            'proposal.previewCode': proposal.previewCode,
            orgName,
          }
        )}`
      )
    );
    return <ErrorPage message="Unable to render preview" />;
  }

  const previewUrl = getProposalDialogPreviewUrl(
    proposal.id,
    orgName,
    proposal.previewCode
  );

  const copyUrl = async () => {
    await navigator.clipboard.writeText(previewUrl);
    showToast.info('Copied to clipboard!');
  };

  return (
    <div className="previewPanel">
      <div className="copyPreview">
        <Button label="Copy preview link" onClick={copyUrl} type="secondary" />
      </div>
      <iframe
        height="1400"
        key={proposalHash}
        src={previewUrl}
        title="proposal-preview"
        width="100%"
      />
    </div>
  );
};

export default PreviewPanel;
