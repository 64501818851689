import {
  captureError,
  getErrorMessage,
  Loading,
  MetabaseRequest,
  MetabaseResponse,
} from 'common';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';
import { submitMetabaseSSORequest } from '../../services/metabase';

import styles from './ReportsPage.module.scss';

const METABASE_SITE_URL = 'https://cacheflow.metabaseapp.com';

const getIframeUrl = (metabaseResponse: MetabaseResponse) => {
  // the current page we are sending them to
  const dashboardHome = metabaseResponse.dashboardHome || '/';
  const uiOptions =
    'top_nav=true&side_nav=true&new_button=true&search=true&additional_info=false&logo=false';
  const returnTo = encodeURIComponent(dashboardHome + '?' + uiOptions);
  return (
    METABASE_SITE_URL +
    '/auth/sso?jwt=' +
    metabaseResponse.token +
    '&return_to=' +
    returnTo
  );
};

const MetadataReportsPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isMutating = useRef(false);
  const [data, setData] = useState<MetabaseResponse | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (!data && !isMutating.current) {
      isMutating.current = true;
      const request: Partial<MetabaseRequest> = {};

      const username = searchParams.get('username');
      // allow login by username if system admin, checked on the backend
      // spread searchParams to get additional params like firstName, lastName, orgId
      if (username) {
        request.username = username;
        searchParams.forEach((value, key) => {
          // other params that are overridable
          if (key === 'firstName' || key === 'lastName' || key === 'orgId') {
            request[key] = value;
          }
        });
      } else if (!ENVIRONMENT_CONFIG.isProduction) {
        // if not production and no username is provided we will spoof as Kelly
        // and display reports from her test top tomato org
        // this is to allow demoing and testing until we have multiple metabase instances
        request.username = 'kelly@kellyhoffer.com';
        request.orgId = 'e0de9c83-471a-4bea-bf4e-3a04040244e9';
        request.firstName = 'Kelly';
        request.lastName = 'Hoffer';
      }
      submitMetabaseSSORequest(request)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          const msg = 'Error loading reports.';
          const fullMessage = msg + ' ' + getErrorMessage(error);

          if (error.response.status !== 401) {
            // 401 is expected if the user is not an admin or not logged in, no need to alert
            captureError(fullMessage, {}, 'info');
          }

          if (!ENVIRONMENT_CONFIG.isProduction) {
            setErrorMessage(fullMessage);
          } else {
            setErrorMessage(msg);
          }
        });
    }
  }, []);

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  if (!data) {
    return <Loading />;
  }

  return (
    <div className={styles.reportsMetabasePage}>
      <iframe src={getIframeUrl(data)} />
    </div>
  );
};
export default MetadataReportsPage;
