import React from 'react';
import { captureError, useFlags, Icon } from 'common';
import { useLocation, useNavigate } from 'react-router-dom';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';
import { MAINTENANCE, SYSTEM_LOGIN_ROUTE } from '../../core-utils/routes';
import { Provider, useSsoProviders } from '../../services/api/sso/providers';
import DevButton from './DevButton';
import styles from './LoginForm.module.scss';
import SsoButton from './SsoButton';
import WarningWidget from './WarningWidget';

interface Props {
  errorMessage?: string;
  isSystem: boolean;
  prevPath?: string;
  searchSuffix?: string;
}

const LoginForm: React.FC<Props> = ({
  errorMessage,
  isSystem,
  prevPath,
  searchSuffix,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, isError, error } = useSsoProviders();

  const { maintenance } = useFlags();
  const forceError = new URLSearchParams(location.search).get('forceError');

  if (maintenance && location.pathname !== MAINTENANCE) {
    navigate(MAINTENANCE);
    return null;
  }

  if (!!forceError || isError) {
    const errorToSend = isError ? error : new Error(forceError!);
    captureError(errorToSend);
    return (
      <div className="flex items-center">
        <Icon.Alert />
        Login is currently unavailable: {errorToSend.message}
      </div>
    );
  }

  const getSystemProvider = (): Provider => ({
    id: 'systemgoogle',
    path: '/oauth2/authorization/systemgoogle',
  });

  return (
    <div className={styles.loginForm}>
      {errorMessage ? <WarningWidget messageCode={errorMessage} /> : null}

      {!isSystem &&
        data?.map((provider) => (
          <SsoButton
            key={provider.id}
            prevPath={prevPath}
            provider={provider}
            searchSuffix={searchSuffix}
          />
        ))}

      {isSystem && (
        <SsoButton
          key={getSystemProvider().id}
          prevPath={prevPath}
          provider={getSystemProvider()}
          system={isSystem}
        />
      )}

      {!ENVIRONMENT_CONFIG.isProduction ? (
        <div>
          {isSystem ? (
            <DevButton
              clientId="systemgoogle"
              isSystem
              route={SYSTEM_LOGIN_ROUTE}
            />
          ) : (
            <DevButton prevPath={prevPath} searchSuffix={searchSuffix} />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default LoginForm;
