import React from 'react';
import { camelCaseKeys, useFlags } from 'launchdarkly-react-client-sdk';
import { Flags, FlagsProvider } from 'common';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';

export const LaunchDarklyWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const flags = useFlags();
  const useFlagFunction = () => {
    return camelCaseKeys(flags) as Flags;
  };

  return (
    <FlagsProvider
      useFlags={useFlagFunction}
      ldClientSecret={ENVIRONMENT_CONFIG.ldClientSecret}
      defaultFlags={ENVIRONMENT_CONFIG.defaultFlags}
    >
      {children}
    </FlagsProvider>
  );
};
