import styles from './TooltipPill.module.scss';
import React from 'react';
import { ToolTip } from 'common';

const TooltipPill = ({
  label,
  dataTestId,
  tooltipText,
  tooltipPlacement = 'top',
}: {
  dataTestId: string;
  label: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right' | undefined;
  tooltipText?: string;
}) => {
  return (
    <span className={styles.tooltipPill} data-testid={dataTestId}>
      <ToolTip title={tooltipText} placement={tooltipPlacement}>
        {label}
      </ToolTip>
    </span>
  );
};

export default TooltipPill;
