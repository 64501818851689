import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { CompanyInfo, getMilliseconds, isNotFound } from 'common';
import { apiClient } from '../../httpClients/app';

const API_DATA_KEY = 'company_info';
const API_DATA_KEY_SAVE = 'company_info_save';

export const useCompanyInfo = (
  enabled = true
): UseQueryResult<CompanyInfo | undefined> =>
  useQuery({
    queryKey: [API_DATA_KEY + 'default'],
    queryFn: async () => {
      try {
        const { data } = await apiClient.getBranding();
        return data;
      } catch (err) {
        if (isNotFound(err)) {
          return undefined;
        } else {
          throw err;
        }
      }
    },
    enabled,
    // use staleTime so result gets cached across <AuthWrapper> renders
    staleTime: getMilliseconds({ seconds: 60 }),
  });

export const useAllCompanyInfo = (
  enabled = true
): UseQueryResult<CompanyInfo[] | undefined> =>
  useQuery({
    queryKey: [API_DATA_KEY],
    queryFn: async () => {
      try {
        const { data } = await apiClient.getAllBranding();
        return data;
      } catch (err) {
        if (isNotFound(err)) {
          return undefined;
        } else {
          throw err;
        }
      }
    },
    enabled,
    // use staleTime so result gets cached across <AuthWrapper> renders
    staleTime: getMilliseconds({ seconds: 60 }),
  });

const saveCompanyInfo = async ({ ...postData }: CompanyInfo) => {
  const { data } = postData.id
    ? await apiClient.updateBranding(postData)
    : await apiClient.createBranding(postData);
  return data;
};

export const useSaveCompanyInfo = (
  onSuccess: (companyInfo: CompanyInfo) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [API_DATA_KEY_SAVE],
    mutationFn: saveCompanyInfo,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [API_DATA_KEY] });
    },
  });

const deleteCompanyInfo = async (id: string) => {
  await apiClient.deleteBranding(id);
};

export const useDeleteCompanyInfo = (
  onSuccess: (data: any) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<void, Error, CompanyInfo['id']>({
    mutationFn: (companyInfoId) => deleteCompanyInfo(companyInfoId!),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [API_DATA_KEY] });
    },
  });
