import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Button,
  Drawer,
  FormField,
  getErrorCode,
  getErrorStatus,
  Input,
  NumberInput,
  Proposal,
  RenewalConfiguration,
  RenewalConfigurationRequest,
  useToast,
} from 'common';

import {
  usePatchRenewalConfig,
  usePostRenewalConfig,
} from 'app/src/services/proposal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  proposal: Proposal;
  renewalConfig: RenewalConfiguration;
}

export const AutoRenewalDrawer = ({
  isOpen,
  onClose,
  proposal,
  renewalConfig,
}: Props) => {
  const showToast = useToast();
  const queryClient = useQueryClient();
  const [formState, setFormState] =
    useState<RenewalConfiguration>(renewalConfig);

  const patchRenewalMutation = usePatchRenewalConfig(
    renewalConfig.id!,
    proposal.id,
    handleSuccess,
    handleError,
    queryClient
  );

  const postRenewalMutation = usePostRenewalConfig(
    proposal.id,
    handleSuccess,
    handleError,
    queryClient
  );

  const getPayload = (): RenewalConfigurationRequest => {
    return {
      termLengthMonths: formState.termLengthMonths,
      cancellationDeadlineDays: formState.cancellationDeadlineDays,
      priceIncrease: formState.priceIncrease,
    };
  };

  function handleSuccess() {
    showToast.success('Auto-renewal settings updated');
    onClose();
  }

  function handleError(error: unknown) {
    if (
      getErrorStatus(error) === 400 &&
      getErrorCode(error) ===
        'RENEWAL_CONFIGURATION_ASSOCIATED_WITH_MULTIPLE_ENTITIES'
    ) {
      postRenewalMutation.mutate(getPayload());
    } else {
      showToast.error('Updating auto-renewal settings failed');
    }
  }

  const handleChange = (
    field: string,
    value: boolean | number | string | null
  ) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };

  const handleSave = () => {
    patchRenewalMutation.mutate(getPayload());
  };

  return (
    <Drawer
      footer={
        <Button
          block
          isLoading={patchRenewalMutation.isPending}
          label="Done"
          onClick={handleSave}
        />
      }
      header="Customize auto-renewal"
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormField label="Renewal term length">
        <Input
          id="termLengthMonths"
          onChange={(v) => handleChange('termLengthMonths', v)}
          suffix="months"
          value={formState.termLengthMonths?.toString()}
        />
      </FormField>

      <FormField label="Renewal cancellation deadline">
        <Input
          id="cancellationDeadlineDays"
          onChange={(v) => handleChange('cancellationDeadlineDays', v)}
          suffix="days"
          value={formState.cancellationDeadlineDays?.toString()}
        />
      </FormField>

      <FormField label="Automatic price increase">
        <NumberInput
          id="priceIncrease"
          onChange={(v) => {
            handleChange('priceIncrease', v ?? 0);
          }}
          scale={2}
          suffix="%"
          value={formState.priceIncrease ?? 0}
        />
      </FormField>
    </Drawer>
  );
};
