import React from 'react';
import {
  Button,
  ButtonBar,
  CompanyInfo,
  RadioBlock,
  ScheduleBar,
  Type,
} from 'common';
import styles from './Sample.module.scss';

interface Props {
  companyInfo: CompanyInfo;
}

const Sample = ({ companyInfo }: Props) => {
  return (
    <div className={styles.sample}>
      <div className={styles.windowWidgets}>
        <div />
        <div />
        <div />
      </div>
      <div className={styles.windowBody}>
        <div className={styles.poweredBy}>&#123; Background &#125;</div>
        <div className={styles.header}>
          <div className={styles.logo}>
            {companyInfo.logo && companyInfo.logo.latestVersion?.url ? (
              <img src={companyInfo.logo.latestVersion.url} alt="logo" />
            ) : (
              <Type paragraph>&#123; Logo &#125;</Type>
            )}
          </div>
          <div>
            <Type paragraph>&#123; Header &#125;</Type>
            <Type paragraph>By default it looks just like the body.</Type>
          </div>
        </div>
        <div className={styles.paper}>
          <Type paragraph>&#123; Body &#125;</Type>
          <Type paragraph>
            By default it is a white background with black text.
          </Type>

          <RadioBlock isSelected isBranded title="Payment Method Option" />
          <RadioBlock
            isSelected={false}
            isBranded
            title="This is an unselected option"
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              padding: '1rem 0',
            }}
          >
            <ScheduleBar idx={0} lineEnd={3} percentageValue={30} />
            <ScheduleBar idx={1} lineEnd={3} percentageValue={60} />
            <ScheduleBar idx={2} lineEnd={3} percentageValue={100} />
          </div>
          <ButtonBar className="mt-2">
            <Button label="Secondary button" type="secondary" />{' '}
            <Button label="Primary button" />
          </ButtonBar>
        </div>
      </div>
    </div>
  );
};

export default Sample;
