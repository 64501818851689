import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { Contact, CreateContactRequest, UpdateContactRequest } from 'common';
import { apiClient } from './httpClients/app';

const ALL_CONTACTS_KEY = 'contacts';
const CREATE_CONTACT_KEY = 'createContact';

export const useContacts = (customersId?: string): UseQueryResult<Contact[]> =>
  useQuery({
    queryKey: [`${ALL_CONTACTS_KEY}_${customersId}`],
    queryFn: async () => {
      if (customersId) {
        const { data } = await apiClient.getCustomerContacts(customersId);
        return data;
      }
      return [];
    },
    enabled: !!customersId,
  });

const createCustomerContact = async (
  customerId: string,
  params: CreateContactRequest
) => {
  const { data } = await apiClient.createCustomerContact(customerId, params);
  return data;
};

export const useCreateContact = (
  onSuccess: (data: Contact) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<Contact, unknown, { id: string; params: CreateContactRequest }>({
    mutationKey: [CREATE_CONTACT_KEY],
    mutationFn: (data: { id: string; params: CreateContactRequest }) =>
      createCustomerContact(data.id, data.params),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        predicate: (query) => {
          // using this predicate function b/c I can't figure out why
          // qc.invalidateQueries({ queryKey: ALL_CONTACTS_KEY }) isn't working
          if (typeof query.queryKey[0] === 'string') {
            return query.queryKey[0].startsWith(ALL_CONTACTS_KEY);
          }
          return false;
        },
      });
    },
  });

const updateContact = async (
  contactId: string,
  patchData: UpdateContactRequest
) => {
  const { data } = await apiClient.updateContact(contactId, patchData);
  return data;
};

export const useUpdateContactAPI = (
  onSuccess: (data: Contact) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['UpdateContact'],
    mutationFn: (data: { id: string; params: UpdateContactRequest }) =>
      updateContact(data.id, data.params),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        predicate: (query) => {
          // using this predicate function b/c I can't figure out why
          // qc.invalidateQueries({ queryKey: ALL_CONTACTS_KEY }) isn't working
          if (typeof query.queryKey[0] === 'string') {
            return query.queryKey[0].startsWith(ALL_CONTACTS_KEY);
          }
          return false;
        },
      });
    },
  });

const deleteContact = async (contactId: string) => {
  const { data } = await apiClient.deleteContact(contactId);
  return data;
};

export const useDeleteContactAPI = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['DeleteContact'],
    mutationFn: deleteContact,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        predicate: (query) => {
          // using this predicate function b/c I can't figure out why
          // qc.invalidateQueries({ queryKey: ALL_CONTACTS_KEY }) isn't working
          if (typeof query.queryKey[0] === 'string') {
            return query.queryKey[0].startsWith(ALL_CONTACTS_KEY);
          }
          return false;
        },
      });
    },
  });
