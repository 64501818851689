import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  AutoFormModal,
  Button,
  Card,
  CardHeader,
  DefaultZeroState,
  getErrorMessage,
  getErrorStatus,
  Loading,
  LocationRest,
  OrgConfig,
  pushIf,
  SimpleTable,
  Tab,
  Tabs,
  TaxCodeRest,
  TaxCountryRulesConfig,
  ToggleSwitch,
  useFlags,
  useToast,
  useTranslation,
} from 'common';
import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';
import {
  GET_ALL_TAXCODES_PATH,
  useGetAllTaxCodes,
} from '../../../services/taxes';
import { apiClient } from '../../../services/httpClients/app';
import { useCountries } from 'app/src/services/customer';
import { Select } from 'antd';

const TaxationSettings: React.FC = () => {
  const [isAddingNew, setIsAddingNew] = React.useState<boolean>(false);
  const [taxCode, setTaxCode] = useState<TaxCodeRest>();
  const [configValue, setConfigValue] = useState<any>({});
  const showToast = useToast();
  const { data: taxCodes, isLoading } = useGetAllTaxCodes();
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const { taxEstimates, taxOnSelectedCountries, enableTaxId } = useFlags();
  const [savableChanges, setSavableChanges] = React.useState<boolean>(false);
  const [configKey, setConfigKey] =
    useState<NonNullable<OrgConfig['configKey']>>('avalaraConfig');

  const { data: configData } = useFindOrgDefault('avalaraConfig');
  const { data: taxCountryRulesConfigData, isLoading: loading } =
    useFindOrgDefault('taxCountryRulesConfig');
  const { data } = useCountries();

  const [enableTaxRules, setEnableTaxRules] = useState<boolean>(false);
  const [collectTaxId, setCollectTaxId] = useState<boolean>(false);

  const [includedCountriesIso, setIncludedCountriesIso] = useState<
    string[] | []
  >([]);

  const { mutate: updateConfig } = useSetOrgConfigDefault(
    configKey,
    () => showToast.success(tk('Tax rules updated successfully')),
    () => showToast.error(tk('Failed to save settings. Please try again.')),
    queryClient
  );

  const countries = data?.map((key: LocationRest) => ({
    key: key.iso,
    value: key.name,
  }));

  function getIsoCodeForCountry(countryName: string): string | undefined {
    const country = countries?.find((c) => c.value === countryName);
    return country?.key;
  }

  function getCountryByIsoCode(IsoCode: string): string | undefined {
    const country = countries?.find((c) => c.key === IsoCode);
    return country?.value;
  }

  useEffect(() => {
    if (configData) {
      const configValueIn: OrgConfig['configValue'] | undefined =
        configData.configValue;

      setConfigValue(configValueIn);
    }

    if (taxCountryRulesConfigData) {
      const isEnableTaxRules = !!(
        taxCountryRulesConfigData.configValue as
          | TaxCountryRulesConfig
          | undefined
      )?.enabled;

      setEnableTaxRules(isEnableTaxRules);
      setIncludedCountriesIso(
        (
          taxCountryRulesConfigData.configValue as
            | TaxCountryRulesConfig
            | undefined
        )?.includedCountriesIso
          ?.flatMap((country) => [getCountryByIsoCode(country)])
          .filter(
            (country): country is string => typeof country === 'string'
          ) ?? []
      );
      const isCollectTaxIdEnabled = !!(
        taxCountryRulesConfigData.configValue as
          | TaxCountryRulesConfig
          | undefined
      )?.collectTaxIdEnabled;

      setCollectTaxId(isCollectTaxIdEnabled);
    }
  }, [configData, taxCountryRulesConfigData]);

  const saveConnectorConfig = (value: boolean) => {
    const val = {
      ...configValue,
      configType: configKey,
      canCalculateOnProposals: value,
    };
    setConfigValue(val);
    updateConfig({ configValue: val });
    setConfigKey('avalaraConfig');
  };

  const saveTaxRules = () => {
    if (includedCountriesIso.length === 0 && enableTaxRules) {
      showToast.error('Ensure that at least one country is selected');
      return;
    }
    const val = {
      ...configValue,
      configType: 'taxCountryRulesConfig',
      enabled: enableTaxRules,
      includedCountriesIso: includedCountriesIso.map((country: string) =>
        getIsoCodeForCountry(country)
      ),
      collectTaxIdEnabled: collectTaxId,
    };
    updateConfig({ configValue: val });
    setConfigKey('taxCountryRulesConfig');
    setSavableChanges(false);
  };

  const handleDeleteTaxCode = async (taxCodeValue: string | undefined) => {
    if (taxCodeValue) {
      try {
        await apiClient.deleteTaxCode(taxCodeValue);
      } catch (error) {
        if (getErrorStatus(error) === 400) {
          const errorMessage = getErrorMessage(error);
          if (errorMessage) {
            showToast.error(tk(errorMessage));
          }
        }
      }
    }
  };

  const handleCloseModal = () => {
    setIsAddingNew(false);
    setTaxCode(undefined);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader name="Tax" />
      <Tabs
        tabs={[
          ...pushIf(taxOnSelectedCountries, {
            key: 'tax-rules',
            label: 'Settings',
            component: (
              <Card>
                <CardHeader name="">
                  <Button
                    isDisabled={!savableChanges}
                    label="Save"
                    onClick={saveTaxRules}
                  />
                </CardHeader>
                <div className="flex justify-between items-center my-3">
                  <div>
                    <p className="font-bold py-3 text-md">
                      Calculate tax only for selected countries
                    </p>
                  </div>
                  <div className="ml-2">
                    <ToggleSwitch
                      label="Enable"
                      onChange={() => {
                        setEnableTaxRules((prev) => !prev);
                        setIncludedCountriesIso([]);
                        setSavableChanges(true);
                      }}
                      value={enableTaxRules}
                    />
                  </div>
                </div>
                <div className="w-1/2 flex flex-col mb-6">
                  <p>
                    If this setting is disabled, taxes will be calculated for
                    all countries.
                  </p>
                </div>
                {enableTaxRules && (
                  <div className="w-1/3 flex flex-col">
                    <Select<string[]>
                      disabled={!enableTaxRules}
                      mode="multiple"
                      onChange={(e) => {
                        setIncludedCountriesIso(e);
                        setSavableChanges(true);
                      }}
                      options={countries}
                      placeholder="Choose countries"
                      style={{ width: '100%' }}
                      value={includedCountriesIso}
                    />
                  </div>
                )}
                {enableTaxId && (
                  <>
                    <div className="flex justify-between items-center my-3">
                      <div>
                        <p className="font-bold py-3 text-md">
                          Collect Tax ID from customers
                        </p>
                      </div>
                      <div className="ml-2">
                        <ToggleSwitch
                          label="Enable"
                          onChange={() => {
                            setCollectTaxId((prev) => !prev);
                            setSavableChanges(true);
                          }}
                          value={collectTaxId}
                        />
                      </div>
                    </div>
                    <div className="w-1/2 flex flex-col mb-6">
                      <p>
                        An additional Tax ID field is made available that allows
                        you to collect your customers tax registration ID during
                        checkout or when editing a customer record. The Tax ID
                        will be included on your customers Proposals & Invoices.
                      </p>
                    </div>
                  </>
                )}
              </Card>
            ),
          }),
          ...pushIf<Tab>(taxEstimates, {
            key: 'configuration',
            label: 'Configuration',
            component: (
              <div>
                <div className="flex justify-between mt-6">
                  <div className="w-2/5">
                    Calculate estimated tax on proposals
                  </div>
                  <div>
                    <ToggleSwitch
                      dataTestId="accept-requires-payment-method"
                      label="Enable"
                      name="accept"
                      onChange={(value) => {
                        saveConnectorConfig(value);
                      }}
                      value={configValue.canCalculateOnProposals}
                    />
                  </div>
                </div>
              </div>
            ),
          }),
          {
            key: 'tax-codes',
            label: 'Tax codes',
            component: (
              <div>
                <SimpleTable
                  cells={[
                    {
                      headerLabel: 'Code',
                      key: 'code',
                      renderCell: (tc: TaxCodeRest) => tc.code,
                    },
                    {
                      headerLabel: 'Description',
                      key: 'description',
                      renderCell: (tc: TaxCodeRest) => tc.description,
                    },
                    {
                      key: 'delete',
                      renderCell: () => null,
                    },
                  ]}
                  disableSearch
                  isLoading={isLoading}
                  numberOfSkeletonRows={3}
                  onRowClick={(clickedTaxCode) => setTaxCode(clickedTaxCode)}
                  rightWidget={
                    <>
                      <Button
                        className="mb-2"
                        label="Add new"
                        onClick={() => setIsAddingNew(true)}
                      />
                      <AutoFormModal<TaxCodeRest>
                        entity={taxCode}
                        entityName="Tax code"
                        fields={[
                          {
                            fieldName: 'code',
                            humanReadableName: 'Code',
                            isDisabled: !isAddingNew,
                            isRequired: true,
                          },
                          {
                            fieldName: 'description',
                            humanReadableName: 'Description',
                            isRequired: true,
                          },
                        ]}
                        isOpen={isAddingNew || taxCode !== undefined}
                        onClose={handleCloseModal}
                        onDelete={() => handleDeleteTaxCode(taxCode?.code)}
                        onSave={async (newTaxCode) =>
                          apiClient.updateTaxCode(newTaxCode.code, newTaxCode)
                        }
                        onSuccess={handleCloseModal}
                        queryClient={queryClient}
                        queryKey={[GET_ALL_TAXCODES_PATH]}
                      />
                    </>
                  }
                  rows={taxCodes}
                  zeroState={<DefaultZeroState message="No tax codes." />}
                />
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
};
export default TaxationSettings;
