import React from 'react';
import { Product, ProductSummary, useFlags } from 'common';
import { ItemRequestAndProduct } from './ProductForm';
import { useProducts } from 'app/src/services/api/products/products';
import ProductSelect from './ProductSelect';
import { getUnselectedProducts } from 'app/src/pages/Proposals/ProposalEditor/proposalUtilities';

interface Props {
  existingProducts: (Product | ProductSummary)[];
  onCreate: (pair: ItemRequestAndProduct) => void;
  recurrence: Product['recurrence'];
}

const NewBundleItem: React.FC<Props> = ({
  existingProducts,
  onCreate,
  recurrence,
}: Props) => {
  const { proposalProductMultiples } = useFlags();
  const { data: products, isError } = useProducts(
    recurrence === 'recurring' ? 'bundleableRecurring' : 'bundleableOnetime'
  );

  const filteredProducts = proposalProductMultiples
    ? products?.filter((p) => p.recurrence === recurrence)
    : getUnselectedProducts(products, existingProducts).filter(
        (p) => p.recurrence === recurrence
      );

  if (!products || !filteredProducts || isError) {
    return null;
  }

  return (
    <ProductSelect
      dataTestId="bundle-item-product-select"
      onSelect={(product) =>
        onCreate({
          itemRequest: {
            productId: product.id,
            bundlePercent: existingProducts.length ? 0 : 10000,
          },
          product,
        })
      }
      products={filteredProducts}
    />
  );
};

export default NewBundleItem;
