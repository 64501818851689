import React from 'react';
import { createButton } from 'react-social-login-buttons';
import SalesforceLogoSVG from '../../../assets/integration-logos/salesforceLogoSVG';
import { SIZE_SMALL } from 'common';

const icon = () => <SalesforceLogoSVG {...SIZE_SMALL} />;

const config = {
  text: 'Log in with Salesforce',
  icon,
  activeStyle: { background: 'var(--slate-lightest)' },
  style: {
    background: 'white',
    color: 'black',
  },
};

const SalesforceLoginButton = createButton(config);

export default SalesforceLoginButton;
