import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'app/src/Auth';
import {
  useIsCurrentUserAdmin,
  useIsSuperAdmin,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { useSubscriptionSync } from 'app/src/services/salesforce';
import {
  ConfirmModal,
  Contract,
  ContractSummary,
  getErrorMessage,
  Icon,
  PopOutMenu,
  PopOutMenuOption,
  useFlags,
  useToast,
  useTranslation,
} from 'common';
import React, { useState } from 'react';
import { isLocalOrDevEnvironment } from '../../../../../config/hosts';
import { useIsConnectorEnabled } from '../../../../services/connectors';
import {
  useCreateSubscriptionMetrics,
  useUpdateContract,
} from '../../../../services/contract';
import SubscriptionArchiveOption from './SubscriptionArchiveOption';
import { UpdateIssueDatesModal } from './UpdateIssueDatesModal';

interface Props {
  borderless: boolean;
  contract: Contract | ContractSummary;
}

const SubscriptionOptionsButton: React.FC<Props> = ({
  contract,
  borderless,
}) => {
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const showToast = useToast();
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showUpdateIssueDatesModal, setShowUpdateIssueDatesModal] =
    useState(false);
  const salesforceEnabled = useIsConnectorEnabled('salesforceConfig');
  const auth = useAuth();
  const { issueDateUpdating, pauseSubscriptions } = useFlags();
  const isAdmin = useIsCurrentUserAdmin();
  const showCreateMetricsAction =
    useIsSuperAdmin() || isLocalOrDevEnvironment();
  const showUpdateIssueDatesAction =
    useIsSuperAdmin() || (isAdmin && issueDateUpdating);
  const handleError = (error: unknown) => {
    const errorMessage = getErrorMessage(error);
    if (errorMessage) {
      showToast.error(errorMessage);
    }
  };

  const handleSuccess = () => {
    showToast.success('Subscription updated');
  };

  const { mutate } = useUpdateContract(
    contract.id,
    handleSuccess,
    handleError,
    queryClient
  );

  const { mutate: syncSubscription } = useSubscriptionSync(
    () => showToast.success('Success'),
    handleError
  );

  const { mutate: createSubscriptionMetrics } = useCreateSubscriptionMetrics(
    contract.id,
    () => showToast.success('Success'),
    handleError,
    queryClient
  );

  const activateContract = () => {
    setShowActivateModal(false);
    mutate({
      status: 'active',
    });
  };

  const pauseContract = () => {
    setShowPauseModal(false);
    mutate({
      status: 'paused',
    });
  };

  const cancelContract = () => {
    setShowCancelModal(false);
    mutate({
      status: 'cancelled',
    });
  };

  return (
    <>
      <PopOutMenu borderless={borderless}>
        {(auth.isSystemAdmin || pauseSubscriptions) && (
          <PopOutMenuOption
            icon={Icon.Alert}
            isDisabled={contract.status !== 'active'}
            onClick={() => setShowPauseModal(true)}
            title="Pause subscription"
          />
        )}
        {(auth.isSystemAdmin || pauseSubscriptions) && (
          <PopOutMenuOption
            icon={Icon.Alert}
            isDisabled={contract.status !== 'paused'}
            onClick={() => setShowActivateModal(true)}
            title="Activate subscription"
          />
        )}
        {isAdmin && (
          <PopOutMenuOption
            icon={Icon.Alert}
            isDisabled={
              contract.status !== 'active' && contract.status !== 'paused'
            }
            onClick={() => setShowCancelModal(true)}
            title="Cancel subscription"
          />
        )}
        {isAdmin && salesforceEnabled && (
          <PopOutMenuOption
            icon={Icon.Repeat}
            onClick={() => syncSubscription(contract.id)}
            title="Sync to Salesforce"
          />
        )}
        {showCreateMetricsAction && (
          <PopOutMenuOption
            icon={Icon.Information}
            onClick={() => createSubscriptionMetrics(contract.id)}
            title="Create metrics"
          />
        )}
        {showUpdateIssueDatesAction && (
          <PopOutMenuOption
            icon={Icon.Alert}
            isDisabled={contract.status !== 'active'}
            onClick={() => setShowUpdateIssueDatesModal(true)}
            title="Update issue dates"
          />
        )}
        <SubscriptionArchiveOption contract={contract} />
      </PopOutMenu>
      <ConfirmModal
        cancelText="No"
        confirmText="Yes, pause"
        isDanger
        isOpen={showPauseModal}
        onClose={() => setShowPauseModal(false)}
        onConfirm={() => pauseContract()}
      >
        <div className="p-4 flex flex-col gap-2 items-center justify-center">
          <Icon.Alert className="text-red" height={100} width={100} />
          <div>{tk('Are you sure you want to pause this subscription?')}</div>
          <div>{tk('This will pause all future payments.')}</div>
          <div>{tk('This contract can be activated at a later time.')}</div>
        </div>
      </ConfirmModal>
      <ConfirmModal
        cancelText="No"
        confirmText="Yes, activate"
        isDanger
        isOpen={showActivateModal}
        onClose={() => setShowActivateModal(false)}
        onConfirm={() => activateContract()}
      >
        <div className="p-4 flex flex-col gap-2 items-center justify-center">
          <Icon.Alert className="text-red" height={100} width={100} />
          <div>
            {tk('Are you sure you want to activate this subscription?')}
          </div>
          <div>{tk('This will enable all future payments.')}</div>
          <div>
            {tk('This contract can be paused or cancelled at a later time.')}
          </div>
        </div>
      </ConfirmModal>
      <ConfirmModal
        cancelText="No"
        confirmText="Yes, cancel"
        isDanger
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={() => cancelContract()}
      >
        <div className="p-4 flex flex-col gap-2 items-center justify-center">
          <Icon.Alert className="text-red" height={100} width={100} />
          <div>{tk('Are you sure you want to cancel this subscription?')}</div>
          <div>{tk('This will cancel future payments.')}</div>
          <div>
            {tk('This will also cancel any outstanding changes or renewals.')}
          </div>
          <div>{tk('This action can not be undone.')}</div>
        </div>
      </ConfirmModal>
      <UpdateIssueDatesModal
        contractId={contract.id}
        isOpen={showUpdateIssueDatesModal}
        onClose={() => setShowUpdateIssueDatesModal(false)}
      />
    </>
  );
};

export default SubscriptionOptionsButton;
