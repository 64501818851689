import { log } from 'common';
import React, { useEffect, useState } from 'react';
import userflowJs from 'userflow.js';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';
import { useCompanyInfo } from '../../services/api/branding/companyInfo';
import {
  isMultiOrgPath,
  useCurrentUser,
  useCurrentUserRole,
  useFlowDomain,
} from './userServiceHelper';

export const UserflowWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const role = useCurrentUserRole();
  const flowDomain = useFlowDomain();
  const user = useCurrentUser();
  const singleOrgPath = !isMultiOrgPath();
  const { data: companyInfo } = useCompanyInfo(!!user && singleOrgPath);
  const [isInitializing, setIsInitializing] = useState(false);

  useEffect(() => {
    const userId = user?.id;
    const username = user?.username;
    const automatorPattern = /.*@[\w-]*automator\.com/g;

    if (
      isInitializing ||
      !ENVIRONMENT_CONFIG.userflowKey ||
      !user ||
      !userId ||
      userflowJs.isIdentified() ||
      username?.match(automatorPattern)
    ) {
      return;
    }

    setIsInitializing(true);
    userflowJs.init(ENVIRONMENT_CONFIG.userflowKey);
    userflowJs
      .identify(userId, {
        name: `${user.firstName} ${user.lastName}`,
        username,
        createdAt: user.createdAt,
        role,
        // eslint-disable-next-line camelcase
        flow_domain: flowDomain,
        // eslint-disable-next-line camelcase
        company_name: companyInfo?.name,
      })
      .then(() => setIsInitializing(false), log.error);
  }, [companyInfo, role, flowDomain, user]);

  return children;
};
