import React from 'react';
import { getProposalDialogPreviewUrl } from '../../../../services/helper';
import { useFlowDomain } from '../../../../core-utils/helperFunctions/userServiceHelper';
import {
  Button,
  ButtonBar,
  captureError,
  Modal,
  Proposal,
  ProposalSummary,
  useToast,
} from 'common';
import { getProposalHash } from '../proposalUtilities';
import ErrorPage from 'checkout/src/components/ErrorPage';

interface Props {
  isOpen: boolean;
  proposal: Proposal | ProposalSummary;
  setIsClosed: () => void;
}

const PreviewDialog: React.FC<Props> = ({ isOpen, setIsClosed, proposal }) => {
  const orgName = useFlowDomain();
  const showToast = useToast();

  if (proposal.previewCode === undefined || orgName === undefined) {
    captureError(
      new Error(
        `Proposal preview code or org name is undefined when rendering preview. ${JSON.stringify(
          {
            'proposal.previewCode': proposal.previewCode,
            orgName,
          }
        )}`
      )
    );
    return <ErrorPage message="Unable to render preview" />;
  }

  const previewUrl = getProposalDialogPreviewUrl(
    proposal.id,
    orgName,
    proposal.previewCode
  );

  const openNewTab = () => {
    window.open(previewUrl, '_blank');
  };

  const copyLink = async () => {
    await navigator.clipboard.writeText(previewUrl);
    showToast.info('Link copied');
  };

  const footerButtons = (
    <ButtonBar>
      <Button label="Copy link" type="secondary" onClick={copyLink} />
      <Button label="Open in new tab" type="secondary" onClick={openNewTab} />
      <Button label="Close" onClick={setIsClosed} />
    </ButtonBar>
  );

  return (
    <Modal
      width={1200}
      shouldForceFullHeight
      isOpen={isOpen}
      footer={footerButtons}
      onClose={setIsClosed}
    >
      <iframe
        key={getProposalHash(proposal)}
        title="proposal-preview"
        width="100%"
        height="100%"
        src={previewUrl}
      />
    </Modal>
  );
};

export default PreviewDialog;
