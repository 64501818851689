import React from 'react';

const DebuggerSVG = () => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    className="iconify iconify--noto"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M80.07 76.12s3.94-3.19 4.13-4.11s-1.69-3.94-.7-10s7.18-12.25 10-10.28c2.82 1.97 2.53 3.24 2.53 3.24l2.82-.28l2.11 1.55l.56 3.1l3.38 1.55l3.38 4.08l-2.39 8.66l-10.7 4.43l-15.06 5.42l-.06-7.36z"
      fill="#6e4d44"
    />
    <path
      d="M89.4 52.52s3.03-1.14 4.5.63c1.06 1.27.28 2.96-.56 4.15c-.84 1.2-1.96 2.72-2.75 4.08c-.77 1.34-1.62 3.24-1.2 3.66c.68.68 2.25-1.27 3.17-2.6c1.6-2.34 2.99-4.09 3.59-4.79c.84-.99 2.12-1.7 3.59-.49c1.55 1.27-.14 3.66-.63 4.22s-2.53 3.03-3.24 4.01c-.7.99-1.55 2.46-1.06 2.96c.42.42 1.83-.84 2.6-1.62c.77-.77 2.66-2.85 3.19-3.38c1.13-1.13 2.29-2.36 4.06-.92c1.55 1.27-4.29 10-4.29 10l4.01-2.46s5-7.46 1.67-10.23c-1.95-1.62-4.13-.05-4.13-.05s1.55-2.67-.77-4.72c-2.1-1.84-4.43-.63-4.43-.63s.07-1.97-1.9-3.03c-1.97-1.04-4.85.65-5.42 1.21z"
      fill="#50362d"
    />
    <radialGradient
      id="IconifyId17ecdb2904d178eab14733"
      cx="68.416"
      cy="81.529"
      r="37.449"
      gradientTransform="matrix(-.3544 .9351 -1.016 -.3851 175.5 48.952)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset=".26" stopColor="#b68d76" />
      <stop offset=".41" stopColor="#a87f6a" />
      <stop offset=".679" stopColor="#875b4e" />
    </radialGradient>
    <path
      d="M46.82 49.07L16.55 64.42s1.12 11.29 4.36 19.15c4.65 11.26 14.06 23.46 30.41 29.56c12.81 4.79 26.14 6.29 38.22 1.97c9.02-3.22 12.74-7.32 18.3-12.6c5.45-5.18 11.33-16.12 11.33-16.12s6.34-10.63 4.79-25.06c-1.53-14.24-11.47-26.68-22.31-29.21c-9.44-2.21-9.52.28-5.56 5.42c3.8 4.93 7.88 9.15 9.57 14.36c1.69 5.21 3.38 15.77-6.34 20.13s-20.84 3.8-28.72-1.41s-13.37-11.4-15.91-15.2s-3.94-8.31-3.94-8.31l-3.93 1.97z"
      fill="url(#IconifyId17ecdb2904d178eab14733)"
    />
    <path
      d="M53.15 104.99c-.42 0-.83-.22-1.05-.61l-2.06-3.66c-.33-.58-.12-1.31.46-1.63c.58-.33 1.31-.12 1.63.46l2.06 3.66c.33.58.12 1.31-.46 1.63c-.17.1-.38.15-.58.15z"
      fill="#52322a"
    />
    <path
      d="M62.73 108.98c-.47 0-.91-.27-1.1-.73l-1.6-3.75a1.201 1.201 0 0 1 2.21-.94l1.6 3.75c.26.61-.03 1.31-.64 1.57c-.16.07-.32.1-.47.1z"
      fill="#52322a"
    />
    <path
      d="M68.69 102.41c-.37 0-.73-.17-.96-.48l-2.07-2.77c-.4-.53-.29-1.28.24-1.68c.53-.4 1.28-.29 1.68.24l2.07 2.77a1.198 1.198 0 0 1-.96 1.92z"
      fill="#52322a"
    />
    <path
      d="M78.4 110.29c-.42 0-.83-.22-1.05-.61l-1.69-3c-.33-.58-.12-1.31.46-1.63c.58-.33 1.31-.12 1.63.46l1.69 3a1.194 1.194 0 0 1-1.04 1.78z"
      fill="#52322a"
    />
    <radialGradient
      id="IconifyId17ecdb2904d178eab14734"
      cx="29.675"
      cy="46.815"
      r="24.012"
      gradientTransform="matrix(-.8885 .4589 -.3302 -.6394 71.499 63.129)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset=".271" stopColor="#fbe5cb" />
      <stop offset=".363" stopColor="#efd7be" />
      <stop offset=".538" stopColor="#cfb09b" />
      <stop offset=".776" stopColor="#9c7464" />
      <stop offset=".865" stopColor="#875b4e" />
    </radialGradient>
    <path
      d="M11.69 63.29c2.87 3.56 10.1 9.39 28.06-1.27c16.49-9.78 12.32-20.14 10.61-23.46c-1.6-3.1-3.54-4.31-3.54-4.31s1.71-3.72-1.48-5.6c-3.62-2.13-5.53 1.3-5.53 1.3s-7.69-3.37-17.7 1.09C9.25 36.78 8.69 46.72 8.69 46.72s-3.85-.07-4.32 4.32c-.56 5.24 4.69 6.01 4.69 6.01s.28 3.32 2.63 6.24z"
      fill="url(#IconifyId17ecdb2904d178eab14734)"
    />
    <path
      d="M44.43 68.5c2.32-1.48 2.46-5.33 2.53-7.81c.07-2.46-.92-4.25-2.75-4.01c-2.18.28-2.11 2.67-2.11 2.67s-1.41-2.89-3.8-1.83c-2.51 1.11-1.06 4.43-1.06 4.43s-2.98-2.18-4.08.92c-.56 1.58 1.27 4.29 4.01 5.84c2.75 1.55 7.26-.21 7.26-.21z"
      fill="#e0dfe2"
    />
    <path
      d="M39.11 59.21c-.54.24-.63 1.41.28 3.17c.81 1.55 1.79 1.9 2.32 1.55c.53-.35.04-1.72-.49-2.75c-.35-.68-1.16-2.4-2.11-1.97z"
      fill="#fefcfe"
    />
    <path
      d="M43.23 60.65c.03.81.11 2.39 1.09 2.43c.99.04.88-1.97.88-2.46s-.04-1.94-.92-2.01c-.88-.07-1.09 1.05-1.05 2.04z"
      fill="#fefcfe"
    />
    <path
      d="M35.34 64.49c.87.97 1.72 2.04 2.5 1.48s-.53-1.97-.92-2.5s-1.34-1.48-1.94-1.06c-.59.42-.23 1.41.36 2.08z"
      fill="#fefcfe"
    />
    <path
      d="M52.03 59.91s3.76-7.01 12.11-1.2c8.87 6.17 1.97 13.3-2.67 13.23s-7.95.42-10.56-.42s-5-3.38-5-3.38l-2.46 1.69s.49 4.79-1.2 6.83c-1.7 2.05 1.75 11.55-.85 14.08s-6.97 4.29-9.71 3.03c-2.75-1.27-5.7-5.84-6.26-9.92c-.56-4.08 1.41-13.59 5.28-13.87c3.87-.28 4.36.07 4.36.07s1.27-4.5 4.65-4.08s4.79 1.97 4.79 1.97l.92-.77s-1.2-1.9 1.27-4.15c2.46-2.25 5.28-.35 5.28-.35s.05-2.13.05-2.76z"
      fill="#875b4e"
    />
    <path
      d="M26.45 81.97c.43.9 1.36-.05 1.27-2.82s.52-5.58 2.53-7.23c2.02-1.64 4.13-.28 4.13-.28s-.42 1.27.05 2.86s1.41 2.16 1.17.42c-.23-1.74 0-8.73 4.46-8.02c4.46.7 3.53 6.44 1.69 8.96c-1.27 1.74-3.38 1.45-3.38 1.74c0 .28 1.69 5.58 1.88 8.31c.19 2.72-.05 6.34-3.05 6.76c-3 .42-7.04-.94-6.8 0c.23.94 5.82 4.41 10.51 1.88c4.98-2.69 3.57-9.39 3.38-10.65c-.19-1.27-1.17-4.55-1.22-5.21c-.01-.09 1.97-1.81 2.44-4.04c.66-3.1.09-4.6.09-4.6s1.83 2.3 4.46 3.05s5.77-.05 5.77-.05s5.4 1.74 9.24-.84c3.85-2.58 3.93-5.77 3.71-6.52c-.61-2.06-.98.24-1.55 1.22c-.84 1.45-2.86 3.38-6.19 3.89c-3.33.52-4.74-.7-5.26-.66s-3.28 1.55-6.19-.47c-3.68-2.55-2.7-5.88-.84-6.38c1.55-.42 4.3.47 5.4 2.06c1.03 1.5 1.88 3.85 2.21 3.33c.33-.52.05-2.82-1.08-4.32c-1.13-1.5-2.39-1.97-2.44-2.58c-.09-1.17.38-2.21 1.17-3.1s2.77-1.41 2.02-1.97s-3.28-.19-4.41 1.55c-1.13 1.74-.56 3.14-.56 3.14s-3.43-1.45-5.73 1.22c-1.82 2.12-1.08 4.5-1.08 4.5s-1.83-2.86-5.02-2.25c-3.19.61-4.32 3.99-4.32 3.99s-3-1.27-5.73.56s-3.61 5.77-3.52 7.88c.09 2.13.37 3.77.79 4.67z"
      fill="#6a493b"
    />
    <path
      d="M19.01 47.06c-2.46.43-2.39 2.67-2.36 3.45c.04.77.81 2.15 2.71 2.01c2.05-.15 2.71-2.08 2.57-3.31s-1.51-2.39-2.92-2.15z"
      fill="#27282d"
    />
    <path
      d="M36.4 38.9c-1.21 1.01-1.09 2.89.14 3.98c1.17 1.03 3.31 1.05 4.19-.53c.85-1.52.28-2.78-.67-3.55s-2.78-.64-3.66.1z"
      fill="#27282d"
    />
    <path
      d="M28.31 41.86c-2.33 1.12-1.6 3.43-1.79 5.21c-.22 1.97-1.52 2.39-1.52 4.08c0 1.37 2.11.97 4.08 1.06c1.65.07 3.21 1.83 2.22 3.1c-.63.81-1.74.55-2.36-.04c-.63-.6-1.16-.6-1.44-.14c-.28.46.46 2.91 3.34 2.67c2.57-.21 3.2-3.1 3.2-3.1s2.33.97 4.05-.18c1.9-1.27 1.44-3.77.67-4.5s-1.38-.25-1.41.04c-.07.63.35.81.42 1.34s-.14 1.3-.77 1.65c-.55.3-1.34-.11-1.65-.6c-.32-.49-.35-1.45-.28-1.97c.21-1.55 3.83-3.7 2.96-5.17c-.81-1.37-1.72.25-4.33-1.48c-1.07-.71-2.47-3.38-5.39-1.97z"
      fill="#27282d"
    />
    <path
      d="M20.42 67.59c-.04 0-.09 0-.14-.01a.736.736 0 0 1-.6-.87c0-.03.46-2.52.88-5.19c.42-2.72.98-4.81 1-4.9a.75.75 0 1 1 1.45.39c-.01.02-.56 2.11-.97 4.74c-.42 2.69-.88 5.2-.88 5.23a.75.75 0 0 1-.74.61z"
      fill="#e2e2e4"
    />
    <path
      d="M24.97 68.27s-.03 1.07-.84 1c-.86-.07-.66-1.11-.66-1.11s.25-3.51.35-5.44c.11-1.94.35-5.09.35-5.09s.06-.84.82-.79c.76.05.68.9.68.9s-.25 3.12-.35 5.05c-.1 1.95-.35 5.48-.35 5.48z"
      fill="#e2e2e4"
    />
    <path
      d="M27.6 67.07c-.4 0-.73-.32-.75-.72c0 0-.1-2.51-.17-3.39c-.07-.9-.07-4.62-.07-4.78c0-.41.34-.75.75-.75s.75.34.75.75c0 1.32.01 3.98.07 4.66c.07.88.17 3.34.18 3.44c.02.41-.3.76-.72.78c-.02.01-.03.01-.04.01z"
      fill="#e2e2e4"
    />
    <path
      d="M48.61 54.22c-.12 0-.25-.03-.37-.1c-.02-.01-2.4-1.34-3.98-2.13c-1.56-.78-3.9-1.61-3.93-1.62a.754.754 0 0 1-.46-.96c.14-.39.57-.59.96-.46c.1.04 2.46.87 4.1 1.69c1.62.81 3.94 2.11 4.04 2.16c.36.2.49.66.29 1.02c-.13.26-.38.4-.65.4z"
      fill="#e2e2e4"
    />
    <path
      d="M50.09 51.16c-.12 0-.25-.03-.36-.09c-.02-.01-2.34-1.29-4.17-2c-1.8-.7-4.5-1.12-4.52-1.12a.747.747 0 0 1-.63-.85c.06-.41.44-.69.85-.63c.12.02 2.89.44 4.84 1.2c1.92.75 4.25 2.03 4.35 2.08c.36.2.49.66.29 1.02c-.13.25-.39.39-.65.39z"
      fill="#e2e2e4"
    />
    <path
      d="M49.71 46.37c-.04 0-.07 0-.11-.01c-.03 0-2.57-.39-4.12-.56c-1.51-.17-4.19-.07-4.22-.07c-.41.03-.76-.31-.78-.72c-.02-.41.31-.76.72-.78c.11 0 2.82-.11 4.44.07c1.58.18 4.08.55 4.18.57c.41.06.69.44.63.85a.76.76 0 0 1-.74.65z"
      fill="#e2e2e4"
    />
    <linearGradient
      id="IconifyId17ecdb2904d178eab14735"
      gradientUnits="userSpaceOnUse"
      x1="88.858"
      y1="107.701"
      x2="111.349"
      y2="87.218"
    >
      <stop offset=".208" stopColor="#875b4e" />
      <stop offset=".579" stopColor="#765247" />
      <stop offset=".888" stopColor="#6e4d44" />
    </linearGradient>
    <path
      d="M82.93 106.72c-.14-3.17.42-6.26.84-7.81c1.12-4.12 2.11-6.62 4.36-7.95c5.96-3.54 9.95-3.11 12.11-4.65c2.46-1.76 7.34-9.46 10.91-8.05c2.5.99 2.39 5.3 2.39 5.3s3.87.07 4.5 2.39c.63 2.32-.84 4.72-.84 4.72s2.96 1.46 3.19 3.45c.23 2.06-.66 4.27-5.44 5.82s-10.98 1.64-10.98 1.64s-1.4 8.34-11.05 10.84c-6.26 1.62-9.78-.91-9.99-5.7z"
      fill="url(#IconifyId17ecdb2904d178eab14735)"
    />
    <path
      d="M83.23 103.27c-.42.19-2.34-9.08 3.61-13.19c4.83-3.33 9.71-2.58 12.44-4.74c2.72-2.16 9.24-11.47 13.89-7.51c2.53 2.16 1.22 4.79 1.22 4.79s2.97-.64 4.69 2.3c1.74 2.96-.75 5.63-.75 5.63s2.96.99 3 3.28c.05 2.3-.89 4.65-3.47 5.82c-2.99 1.36-7.46 2.53-10 2.67c-2.53.14-5.44-.61-3.28-.94c2.16-.33 10.14-2.86 11.87-3.38c2.96-.89 2.91-3.1 2.11-3.94c-1.16-1.23-2.25-.94-5.73.09c-2.92.87-6.57 2.58-6.71 1.5c-.14-1.08 3.33-2.25 5.68-3.57c2.35-1.31 6.24-2.72 5.21-5.11c-1.03-2.39-3.28-1.92-5.82-.38c-2.53 1.55-6.83 4.79-7.04 3.52c-.09-.56 4.13-3.99 5.35-4.83c1.22-.84 3.75-2.67 2.02-4.69s-4.08.28-5.82 1.88c-1.74 1.6-3.85 4.65-6.19 5.63c-3.53 1.48-8.56 1.95-10.93 3.75c-5.06 3.87-4.52 11.05-5.35 11.42z"
      fill="#50362d"
    />
  </svg>
);

export default DebuggerSVG;
