import React, { useEffect, useRef, useState } from 'react';
import { RefSelectProps, Select } from 'antd';
import clsx from 'clsx';

import {
  CreateProposalApprovalRuleRequest,
  Input,
  InputRef,
  LinkButton,
  ProposalCountryRuleConfig,
  ProposalSummary,
} from 'common';

import {
  GroupOption,
  RuleInfo,
  searchFilterOption,
} from '../rules/_approvalRulesConfig';

import styles from './ProposalRuleBuilderStyles.module.scss';

const DEFAULT_VALUE = 'US';

interface Props {
  allGroupOptions: GroupOption[];
  createRule: (payload: CreateProposalApprovalRuleRequest) => void;
  onCancelAdd: () => void;
  proposalType: ProposalSummary['proposalType'];
  selectedRule: RuleInfo;
}

export const ProposalTextBuilder = ({
  createRule,
  proposalType,
  selectedRule,
  onCancelAdd,
  allGroupOptions,
}: Props) => {
  const [value, setValue] = useState<string>(DEFAULT_VALUE);

  const ref = useRef<InputRef>(null);
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    ref.current?.focus({ cursor: 'all' });
  }, []);

  const handleSave = (newValue: string, groupId: string[]) => {
    const payload: CreateProposalApprovalRuleRequest = {
      name: `${selectedRule.name}-${proposalType}`,
      config: {
        [selectedRule.configKey]: newValue,
        proposalType,
        ruleType: selectedRule.name as ProposalCountryRuleConfig['ruleType'],
      },
      approvalGroupIds: groupId,
    };

    createRule(payload);
  };

  const handleSaveIfValid = (id: string[]) => {
    if (value && id.length > 0) {
      handleSave(value, id);
    }
  };

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleEnter = () => selectRef.current?.focus();

  return (
    <div
      className={clsx(styles.ruleRow, !!value && styles.showingGroupsSelect)}
    >
      <div className={styles.condition}>{selectedRule.conditionOperator}</div>
      <div className={styles.input}>
        <Input
          ref={ref}
          value={value}
          onChange={handleChange}
          onEnter={handleEnter}
        />
      </div>

      {!value && (
        <LinkButton className={styles.cancel} onClick={onCancelAdd}>
          cancel
        </LinkButton>
      )}

      {!!value && (
        <div className={styles.groups}>
          {allGroupOptions.length > 0 && (
            <Select
              ref={selectRef}
              onChange={handleSaveIfValid}
              value={[]}
              options={allGroupOptions}
              mode="multiple"
              filterOption={searchFilterOption}
              className={styles.groupWidth}
              placeholder="Select approval group"
              popupMatchSelectWidth={false}
            />
          )}
        </div>
      )}
    </div>
  );
};
