import React from 'react';
import { Contact, formatPerson, formatTimeAgo, User } from 'common';
import styles from './ActivityCell.module.scss';

interface Props {
  onClick?: () => void;
  person?: string | Contact | User;
  timeAgo?: string;
}

const ActivityCell: React.FC<Props> = ({ timeAgo, onClick, person }) => {
  return (
    <div className={styles.activityCell} onClick={onClick}>
      {timeAgo && <div>{formatTimeAgo(timeAgo)}</div>}
      <div className={styles.person}>{formatPerson(person)}</div>
    </div>
  );
};

export default ActivityCell;
