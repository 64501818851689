import React, { useState } from 'react';
import { Button, ButtonBar, FormField, Input, Modal, Type } from 'common';
import CacheflowLogoSVG from '../../../../public/images/cacheflow-logomark.png';
import plaidLogo from '../../../../public/images/plaid-logo-black-bg.png';
import styles from './CreateOrgNameModal.module.scss';

const CreateOrgNameModalBody: React.FC<{
  errorMessage: string;
  handleChange: any;
  inputError: boolean;
  inputValue: string;
  isError: boolean;
}> = ({ isError, inputError, inputValue, errorMessage, handleChange }) => {
  return (
    <>
      {isError && (
        <Type paragraph>
          Unable to link your bank account. Please contact support.
        </Type>
      )}
      <div>
        <Type paragraph>
          Cacheflow needs a legal organization name so that the funds can be
          deposited into your account.
        </Type>
        <FormField
          label="Legal name"
          errorToShow={inputError ? errorMessage : undefined}
        >
          <Input
            placeholder='Enter legal org name, Example: "ABC LLC"'
            className="w-full"
            value={inputValue}
            onChange={handleChange}
          />
        </FormField>
      </div>
    </>
  );
};

const CreateOrgNameModal: React.FC<{
  apiError: boolean;
  handleChange: any;
  handleSubmit: () => void;
  isOpen: boolean;
  orgLegalName: string;
  setIsOpen: (value: boolean) => void;
  textFieldError: {
    disabled: boolean;
    errorMessage: string;
    inputError: boolean;
  };
}> = ({
  handleChange,
  handleSubmit,
  setIsOpen,
  isOpen,
  textFieldError,
  apiError,
  orgLegalName,
}) => {
  const { inputError, errorMessage, disabled } = textFieldError;
  const [processing, setProcessing] = useState(false);

  const onClick = () => {
    setProcessing(true);
    handleSubmit();
  };

  return (
    <Modal
      isOpen={isOpen}
      width={600}
      onClose={() => setIsOpen(false)}
      header={
        <div className={styles.wrapper}>
          <div
            className={styles.orgIcon}
            style={{
              backgroundImage: `url(${CacheflowLogoSVG})`,
            }}
          />
          <div className={styles.orgSeperator} />
          <div
            className={styles.orgIcon}
            style={{
              backgroundImage: `url(${plaidLogo})`,
            }}
          />
        </div>
      }
      footer={
        <ButtonBar>
          <Button
            type="secondary"
            onClick={() => setIsOpen(false)}
            label="Cancel"
          />
          <Button
            onClick={onClick}
            isLoading={processing}
            isDisabled={disabled}
            label="Agree & proceed"
          />
        </ButtonBar>
      }
    >
      <CreateOrgNameModalBody
        isError={apiError}
        inputError={inputError}
        inputValue={orgLegalName}
        errorMessage={errorMessage}
        handleChange={handleChange}
      />
    </Modal>
  );
};

export default CreateOrgNameModal;
