import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCacheflow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 48 48"
    role="img"
    {...props}
  >
    <g clipPath="url(#Cacheflow_svg__a)">
      <path fill="#fff" d="M0 0h48v48H0z" />
      <rect width={48} height={48} fill="currentColor" rx={0.75} />
      <path
        fill="#fff"
        d="M24.924 15.01c2.8 0 5.195 1.21 6.76 3.202a.7.7 0 0 0 .902.19l4.886-2.776c.356-.213.475-.687.238-1.02-2.776-3.842-7.401-6.167-12.762-6.167C16.1 8.32 9.055 15.271 9.22 24c-.166 8.753 6.879 15.68 15.727 15.561 5.36 0 9.986-2.372 12.762-6.167a.74.74 0 0 0-.214-1.044l-4.863-2.8a.705.705 0 0 0-.925.19c-1.518 2.04-3.89 3.155-6.736 3.155-2.539 0-4.579-.83-6.168-2.538-1.708-1.826-2.467-4.056-2.348-6.879.142-4.65 3.819-8.468 8.468-8.468"
      />
    </g>
    <defs>
      <clipPath id="Cacheflow_svg__a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCacheflow;
