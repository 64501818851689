import {
  Card,
  CardHeader,
  Cell,
  Contract,
  formatDateShort,
  formatMoneyOrDefault,
  getIconCell,
  getIconDataBasedOnProposalType,
  IconWithTooltip,
  ItemMetrics,
  SimpleTable,
} from 'common';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetItemMetrics } from '../../../../services/contract';

interface Props {
  contract: Contract;
}

const ItemMetricsCard: React.FC<Props> = ({ contract }) => {
  const { data: itemMetrics } = useGetItemMetrics(contract.id);

  const navigate = useNavigate();

  let sortedMetrics: ItemMetrics[] = [];
  if (itemMetrics) {
    sortedMetrics = [...itemMetrics].sort((a, b) => {
      const dateA = new Date(a.proposal.acceptedAt!).getTime();
      const dateB = new Date(b.proposal.acceptedAt!).getTime();
      return dateA - dateB;
    });
  }

  const tableRowOnClick = (selectedItemMetric: ItemMetrics) => {
    navigate(`/proposals/${selectedItemMetric.proposal.id}`);
  };

  const cells: Cell<ItemMetrics>[] = [
    getIconCell(null, {
      renderCell: ({ proposal }: ItemMetrics) => {
        const iconData = getIconDataBasedOnProposalType(proposal);
        return (
          <IconWithTooltip
            icon={iconData.icon}
            tooltipText={iconData.tooltipText}
          />
        );
      },
    }),
    {
      key: 'proposal',
      headerLabel: ['Proposal', 'Ref.'],
      renderCell: ({ proposal }: ItemMetrics) => (
        <div>
          <div>{proposal.name}</div>
          <div>{proposal.proposalNumber}</div>
        </div>
      ),
    },
    {
      key: 'product',
      headerLabel: 'Product',
      renderCell: ({ product }: ItemMetrics) => product.name,
    },
    {
      key: 'recurrence-pricing',
      headerLabel: ['Recurrence', 'Pricing'],
      renderCell: ({ product }: ItemMetrics) => (
        <div>
          <div>{product.recurrence}</div>
          <div>{product.pricing}</div>
        </div>
      ),
    },
    {
      key: 'startDate',
      headerLabel: 'Start date',
      renderCell: (metrics) => formatDateShort(metrics.termStartDate),
    },
    {
      key: 'endDate',
      headerLabel: 'End date',
      renderCell: (metrics) => formatDateShort(metrics.termEndDate),
    },
    {
      key: 'acv',
      headerLabel: 'ACV',
      renderCell: (metrics) => formatMoneyOrDefault(metrics.acv),
    },
    {
      key: 'delta-acv',
      headerLabel: 'Delta ACV',
      renderCell: (metrics) => formatMoneyOrDefault(metrics.deltaAcv),
    },
    {
      key: 'tcv',
      headerLabel: 'TCV',
      renderCell: (metrics) => formatMoneyOrDefault(metrics.tcv),
    },
    {
      key: 'delta-tcv',
      headerLabel: 'Delta TCV',
      renderCell: (metrics) => formatMoneyOrDefault(metrics.deltaTcv),
    },
  ];

  return (
    <Card>
      <CardHeader name="Item metrics" />
      <SimpleTable<ItemMetrics>
        cells={cells}
        dataTestId="item-metrics-table"
        disableSearch
        isLoading={!itemMetrics}
        minWidth="720px"
        onRowClick={tableRowOnClick}
        rows={sortedMetrics}
      />
    </Card>
  );
};

export default ItemMetricsCard;
