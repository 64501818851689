import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  CreatePaymentMethodRequest,
  PaymentMethod,
  UpdatePaymentMethodRequest,
} from 'common';
import { apiClient } from './httpClients/app';

const GET_PAYMENT_METHOD_DATA = 'PAYMENT_METHODS_KEY';
const GET_SETTINGS_PAYMENT_METHOD_DATA = 'SETTINGS_PAYMENT_METHODS_KEY';
const UPDATE_PAYMENT_METHOD_KEY = 'UPDATE_PAYMENT_METHOD_KEY';
const DELETE_PAYMENT_METHOD_KEY = 'DELETE_PAYMENT_METHOD_KEY';
const BATCH_UPDATE_PAYMENT_METHODS_KEY = 'BATCH_UPDATE_PAYMENT_METHODS_KEY';

export const usePaymentMethods = (
  shouldRefetchPaymentMethods?: boolean,
  currency?: string
): UseQueryResult<PaymentMethod[]> =>
  useQuery({
    queryKey: [GET_PAYMENT_METHOD_DATA, currency],
    queryFn: async () => {
      const { data } = await apiClient.getPaymentMethods({ currency });
      return data;
    },
    enabled: shouldRefetchPaymentMethods,
  });

export const useSettingsPaymentMethods = (
  shouldRefetchPaymentMethods?: boolean,
  currency?: string
): UseQueryResult<PaymentMethod[]> =>
  useQuery({
    queryKey: [GET_SETTINGS_PAYMENT_METHOD_DATA, currency],
    queryFn: async () => {
      const { data } = await apiClient.getSettingsPaymentMethods({ currency });
      return data;
    },
    enabled: shouldRefetchPaymentMethods,
  });

const createPaymentMethod = async (
  createPaymentMethodRequest: CreatePaymentMethodRequest
) => {
  const { data } = await apiClient.createPaymentMethod(
    createPaymentMethodRequest
  );
  return data;
};

export const useCreatePaymentMethod = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [UPDATE_PAYMENT_METHOD_KEY],
    mutationFn: createPaymentMethod,
    onSuccess,
    onError,
    onSettled: async () => await clearPaymentMethodsCache(qc),
  });

const updatePaymentMethod = async (
  id: string,
  config: UpdatePaymentMethodRequest
) => {
  const { data } = await apiClient.updatePaymentMethod(id, config);
  return data;
};

export const useUpdatePaymentMethod = (
  id: string,
  onSuccess: (data: PaymentMethod) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [UPDATE_PAYMENT_METHOD_KEY],
    mutationFn: (data: UpdatePaymentMethodRequest) =>
      updatePaymentMethod(id, data),
    onSuccess,
    onError,
    onSettled: async () => await clearPaymentMethodsCache(qc),
  });

const deletePaymentMethod = async (config: PaymentMethod) => {
  await apiClient.deletePaymentMethod(config.id!);
};

export const useDeletePaymentMethod = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [DELETE_PAYMENT_METHOD_KEY],
    mutationFn: deletePaymentMethod,
    onSuccess,
    onError,
    onSettled: async () => await clearPaymentMethodsCache(qc),
  });

const batchUpdatePaymentMethods = async (
  ids: string[],
  updatePaymentMethodRequests: UpdatePaymentMethodRequest[]
) => {
  const { data } = await apiClient.updatePaymentMethodBatch(
    ids.join(','),
    updatePaymentMethodRequests
  );
  return data;
};

export const useBatchUpdatePaymentMethods = (
  ids: string[],
  updatePaymentMethodRequests: UpdatePaymentMethodRequest[],
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [BATCH_UPDATE_PAYMENT_METHODS_KEY],
    mutationFn: () =>
      batchUpdatePaymentMethods(ids, updatePaymentMethodRequests),
    onSuccess,
    onError,
    onSettled: async () => await clearPaymentMethodsCache(qc),
  });

export const clearPaymentMethodsCache = async (qc: QueryClient) => {
  await qc.invalidateQueries({ queryKey: [GET_PAYMENT_METHOD_DATA] });
  await qc.invalidateQueries({ queryKey: [GET_SETTINGS_PAYMENT_METHOD_DATA] });
};
