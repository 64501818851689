import React, { useState } from 'react';
import PreviewDialog from '../PreviewDialog/PreviewDialog';
import { useValidateProposal } from '../proposalUtilities';
import { Button, Icon, Modal, Proposal, useTranslation } from 'common';
import ActionBarButton from '../../../../components/ActionBarButton';

interface Props {
  isDisabled: boolean;
  proposal: Proposal;
}

const ProposalPreviewButton: React.FC<Props> = ({ proposal, isDisabled }) => {
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const { tk } = useTranslation();

  const onClick = () => {
    setPreviewDialogOpen(true);
  };

  const {
    isValid,
    customerError,
    productError,
    termQtyError,
    expirationError,
  } = useValidateProposal(proposal);

  return (
    <>
      <ActionBarButton
        disabledTooltip="Proposal must be valid before it can be previewed"
        icon={Icon.DocumentView}
        isDisabled={isDisabled}
        onClick={onClick}
        tooltip="Preview"
      />

      <Modal
        width={800}
        isOpen={previewDialogOpen && !isValid}
        onClose={() => setPreviewDialogOpen(false)}
      >
        <>
          <div className="mb-4 text-lg">
            {tk('The following needs to be corrected before viewing preview.')}
          </div>

          <div className="my-4 text-red">
            {customerError && <div key="customerError">{customerError}</div>}
            {productError && <div key="productError">{productError}</div>}
            {termQtyError && <div key="termQtyError">{termQtyError}</div>}
            {expirationError && (
              <div key="expirationError">{expirationError}</div>
            )}
          </div>
          <div className="flex justify-end">
            <Button
              type="secondary"
              onClick={() => setPreviewDialogOpen(false)}
              label="Close"
            />
          </div>
        </>
      </Modal>

      <PreviewDialog
        isOpen={previewDialogOpen && isValid}
        setIsClosed={() => setPreviewDialogOpen(false)}
        proposal={proposal}
      />
    </>
  );
};

export default ProposalPreviewButton;
