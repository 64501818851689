import React, { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { VersionInfo } from '../../../core-utils/helperFunctions/version';
import { formatDatetime, formatTimeAgo, Loading } from 'common';

interface Props {
  name: string;
  versionHook: () => UseQueryResult<VersionInfo>;
}

const AppVersion: React.FC<Props> = ({ name, versionHook }: Props) => {
  const [versionState, setVersionState] = useState<VersionInfo>();

  const { data, isLoading, isError, error } = versionHook();

  useEffect(() => {
    if (!isLoading && !isError) {
      setVersionState(data);
    }
  }, [isLoading, isError, data]);

  const trimSha = (sha: string) => {
    if (sha && sha.length > 11) {
      return sha.substring(0, 8) + '...';
    }
    return sha;
  };

  const getBody = (): React.ReactNode => {
    if (isLoading) {
      return <Loading />;
    }

    if (isError) {
      return <div>{error.message}</div>;
    }
    if (versionState) {
      return (
        <div>
          <div className="my-1">{versionState.branch || '?'}</div>
          <div className="my-1">{versionState.version || '?'}</div>
          <div className="my-1">{trimSha(versionState.sha || '?')}</div>
          <div className="my-1">
            {versionState.environment || versionState.profiles || '?'}
          </div>
          <div className="my-1">
            Build Time:{' '}
            {versionState.buildTime
              ? formatDatetime(versionState.buildTime)
              : '?'}
          </div>
          {versionState.buildTime && formatTimeAgo(versionState.buildTime)}
          {versionState.startTime && (
            <div className="my-4">
              <div className="my-1">
                Start time: {formatDatetime(versionState.startTime)}
              </div>
              {formatTimeAgo(versionState.startTime)}
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div className="p-2 text-lg font-bold">{name}</div>
      {getBody()}
    </div>
  );
};

export default AppVersion;
