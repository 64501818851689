import {
  FormField,
  Item,
  ItemDescription,
  useEditDescriptionHandler,
  useIsEditingItem,
  useTranslation,
} from 'common';
import React from 'react';
import { RichTextInput } from '../../../../../components';

export const ItemDescriptionEditor = ({
  isTruncationDisabled,
  item,
}: {
  isTruncationDisabled: boolean;
  item: Item;
}) => {
  const isEditing =
    useIsEditingItem(item) && !!item.product.descriptionCustomizable;
  const { tk } = useTranslation();
  const setDescription = useEditDescriptionHandler();

  return isEditing ? (
    <FormField label={tk('Description (optional)')}>
      <RichTextInput
        featureSet="product.description"
        onChange={setDescription}
        value={item.description}
      />
    </FormField>
  ) : (
    <ItemDescription isTruncationDisabled={isTruncationDisabled} item={item} />
  );
};
