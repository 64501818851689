import React from 'react';
import {
  Button,
  ButtonBar,
  FormField,
  Icon,
  Input,
  isDraftProposalStatus,
  Modal,
  PopOutMenuOption,
  Proposal,
  ProposalSummary,
  useFlags,
  useToast,
  useTranslation,
} from 'common';
import { getCheckoutTemplateUrl } from '../../../services/helper';
import { useFlowDomain } from '../../../core-utils/helperFunctions/userServiceHelper';
import { isProposalTemplate } from '../ProposalEditor/proposalUtilities';

interface Props {
  editorContext?: boolean;
  proposal: Proposal | ProposalSummary;
}

const BuyerCheckoutOption: React.FC<Props> = ({ proposal, editorContext }) => {
  const { tk } = useTranslation();
  const showToast = useToast();
  const isTemplate = isProposalTemplate(proposal);
  const { buyer: hasBuyerCheckout } = useFlags();
  const orgName = useFlowDomain();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const copyUrl = async () => {
    const linkUrl = getCheckoutTemplateUrl(proposal.id, orgName!);
    await navigator.clipboard.writeText(linkUrl);
    showToast.info('Copied to clipboard!');
  };

  if (
    isDraftProposalStatus(proposal) ||
    !editorContext ||
    !isTemplate ||
    !hasBuyerCheckout
  ) {
    return null;
  }

  return (
    <>
      <PopOutMenuOption
        icon={Icon.Link}
        onClick={handleOpenModal}
        title="Embed link in your website"
        dataTestId="copy-checkout-option"
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        header={tk('Embed link in your website')}
        footer={
          <ButtonBar>
            <span
              data-checkoutlink={getCheckoutTemplateUrl(proposal.id, orgName!)}
              data-testid="copy-checkout"
            />
            <Button
              label={tk('Copy link')}
              onClick={copyUrl}
              type="secondary"
            />
            <Button
              dataTestId="close-copy-checkout-modal"
              label="Close"
              onClick={() => setIsModalOpen(false)}
            />
          </ButtonBar>
        }
      >
        <FormField label={tk('Link to accept online payments')}>
          <Input value={getCheckoutTemplateUrl(proposal.id, orgName!)} />
        </FormField>
      </Modal>
    </>
  );
};

export default BuyerCheckoutOption;
