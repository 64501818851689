import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  getItemBillingPeriod,
  Icon,
  isOneTimeProduct,
  useBillingPeriod,
  useCurrentProposal,
  useRowItem,
  useRowItemSchedule,
  useToast,
  PopOutMenuOption,
} from 'common';
import { useDeleteItemSchedule } from '../../../../../../services/proposal';

export const DeleteItemScheduleAction = () => {
  const qc = useQueryClient();
  const showToast = useToast();
  const billingPeriod = useBillingPeriod();
  const proposal = useCurrentProposal();
  const schedule = useRowItemSchedule();
  const item = useRowItem();

  const { mutate } = useDeleteItemSchedule(
    proposal.id,
    null,
    () => showToast.error('Item schedule delete failed'),
    qc
  );

  const handleClick = useCallback(() => {
    mutate(schedule.id!);
  }, [schedule.id]);

  const itemBillingPeriod = getItemBillingPeriod(item, billingPeriod);
  const firstSchedule = item.schedules.find(
    (current) =>
      !current.billingPeriod || current.billingPeriod === itemBillingPeriod
  );

  if (isOneTimeProduct(item.product) || firstSchedule?.id === schedule.id) {
    return null;
  }

  return (
    <PopOutMenuOption icon={Icon.Close} onClick={handleClick} title="Remove" />
  );
};
