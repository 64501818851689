import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AttachmentConfig } from 'common';
import { apiClient } from '../../httpClients/app';

const ALL_ATTACHMENT_CONFIGS_KEY = 'AllAttachments';

const doCreateAttachmentConfig = async (attachmentConfig: AttachmentConfig) => {
  const { data } = await apiClient.createAttachmentConfig(attachmentConfig);
  return data;
};

const deleteAttachmentConfig = async (id: string) => {
  const { data } = await apiClient.deleteAttachmentConfig(id);
  return data;
};

export const useCreateAttachmentConfig = (
  onSuccess: (data: AttachmentConfig) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['CreateAttachment'],
    mutationFn: doCreateAttachmentConfig,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_ATTACHMENT_CONFIGS_KEY] });
    },
  });

const updateAttachmentConfig = async (
  attachmentConfig: AttachmentConfig
): Promise<AttachmentConfig> => {
  const { data } = await apiClient.updateAttachmentConfig(
    attachmentConfig.id!,
    attachmentConfig
  );
  return data;
};

const getGetAttachmentKey = (attachmentId: string) => [
  'GetAttachment',
  attachmentId,
];

export const useUpdateAttachmentConfig = (
  onSuccess: (data: AttachmentConfig) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['UpdateAttachment'],
    mutationFn: updateAttachmentConfig,
    onSuccess,
    onError,
    onSettled: async (attachmentConfig?: AttachmentConfig) => {
      await qc.invalidateQueries({ queryKey: [ALL_ATTACHMENT_CONFIGS_KEY] });
      if (attachmentConfig) {
        await qc.invalidateQueries({
          queryKey: getGetAttachmentKey(attachmentConfig.id!),
        });
      }
    },
  });

export const useGetAllAttachmentConfigs = (): UseQueryResult<
  AttachmentConfig[]
> =>
  useQuery({
    queryKey: [ALL_ATTACHMENT_CONFIGS_KEY],
    queryFn: async () => {
      const { data } = await apiClient.getAllAttachmentConfigs();
      return data;
    },
  });

export const useDeleteAttachmentConfig = (
  onSuccess: (data: AttachmentConfig) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['DeleteAttachment'],
    mutationFn: deleteAttachmentConfig,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_ATTACHMENT_CONFIGS_KEY] });
    },
  });
