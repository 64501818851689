import React, { ReactNode, useMemo, useState } from 'react';
import { Item, ItemEditorState, ItemEditorStateContext } from 'common';
import { useUpdateProposalItem } from '../../../../../services/proposal';

export const useItemEditorState = (
  onCommit: (item: Item, name: string, description?: string) => void
): ItemEditorState => {
  const [item, setItem] = useState<Item>();

  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();

  const cancel = () => {
    setItem(undefined);
  };

  const commit = () => {
    if (item && name) {
      onCommit(item, name, description);
    }
    setItem(undefined);
  };

  const editItem = (itemToEdit: Item) => {
    setName(itemToEdit.name);
    setDescription(itemToEdit.description);
    setItem(itemToEdit);
  };

  return useMemo(
    () => ({
      editItem,
      item,
      setName,
      setDescription,
      commit,
      cancel,
      name,
      description,
    }),
    [item, name, description]
  );
};

export const ItemEditorProvider = ({
  children,
  proposalId,
}: {
  children: ReactNode;
  proposalId: string;
}) => {
  const { mutate } = useUpdateProposalItem(proposalId);

  const onCommit = ({ id }: Item, name: string, description?: string) => {
    mutate({ id, body: { name, description } });
  };

  const state = useItemEditorState(onCommit);

  return (
    <ItemEditorStateContext.Provider value={state}>
      {children}
    </ItemEditorStateContext.Provider>
  );
};
