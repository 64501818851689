import React, { useState } from 'react';
import {
  Card,
  cleanOptions,
  DefaultZeroState,
  formatDateOrDefault,
  formatTimeOrDefault,
  getOrDefault,
  Loading,
  Modal,
  QueryTable,
  Select,
  SimpleCell,
  TextArea,
  WebhookResult,
} from 'common';
import { Link } from 'react-router-dom';
import { useGetAPIWebhooks } from '../../../../services/api/webhook/webhook';
import ErrorHandler from '../../../../components/ErrorHandler';
import { apiClient } from '../../../../services/httpClients/app';
import { getDetailLink } from 'app/src/services/routes/routeUtils';

const ALL_EVENTS = 'webevents';

const WebHookEvents: React.FC = () => {
  const { data: webhooks, isPending, isError, error } = useGetAPIWebhooks();
  const [webhookId, setWebhookId] = useState<string | undefined>(
    webhooks?.find((w) => w)?.id
  );
  const [selectedWebhookResult, setSelectedWebhookResult] = useState<
    WebhookResult | undefined
  >();

  if (isPending || isError) {
    return (
      <div>
        <Card>
          {isPending && <Loading />}
          {isError && <ErrorHandler error={error} />}
        </Card>
      </div>
    );
  }

  const cells: SimpleCell<WebhookResult>[] = [
    {
      renderCell: (data: WebhookResult) => {
        return (
          <div>
            {formatDateOrDefault(data.createdAt)}{' '}
            {formatTimeOrDefault(data.createdAt)}
          </div>
        );
      },
      key: 'createdAt',
      headerLabel: 'Date/Time',
      sortable: true,
    },
    {
      renderCell: (data: WebhookResult) => getOrDefault(data.eventType),
      className: 'font-semibold',
      key: 'eventType',
      headerLabel: 'Event type',
      width: '25%',
    },
    {
      renderCell: (data: WebhookResult) => getOrDefault(data.status),
      className: 'font-semibold',
      key: 'status',
      headerLabel: 'Status',
      width: '25%',
    },
    {
      renderCell: (data: WebhookResult) => (
        <>
          {getOrDefault(data.referenceType)} {getOrDefault(data.referenceId)}
        </>
      ),
      key: 'referenceInformation',
      headerLabel: 'Object reference',
      width: '35%',
      overflowCell: true,
    },
  ];

  const handleTableRowClick = (selectedRow: WebhookResult) => {
    setSelectedWebhookResult(selectedRow);
  };

  return (
    <div className="connector-configuration">
      <section className="bottom">
        {webhooks.length === 0 && (
          <DefaultZeroState message="You currently do not have any webhook events." />
        )}
        <div>
          <Select
            dataTestId="webhook-selection"
            onChange={(id) => setWebhookId(id)}
            options={cleanOptions(
              webhooks.map((hook) => ({
                value: hook.id,
                name: hook.name,
              })),
              'WebHookEvents'
            )}
            value={webhookId}
          />
        </div>

        {webhooks.length > 0 && !webhookId && (
          <DefaultZeroState message="You currently haven't selected a webhook." />
        )}
        {webhookId && (
          <QueryTable<WebhookResult>
            cells={cells}
            fetchList={({ page, size, sort }) =>
              apiClient.getHookEvents(webhookId, { page, size, sort })
            }
            minWidth="900px"
            onRowClick={handleTableRowClick}
            queryKey={webhookId + ALL_EVENTS}
          />
        )}
      </section>
      <Modal
        isOpen={!!selectedWebhookResult}
        onClose={() => setSelectedWebhookResult(undefined)}
        width={600}
      >
        <div className="p-4">
          <span className="font-[600]">
            {getOrDefault(selectedWebhookResult?.referenceType)}
          </span>{' '}
          {selectedWebhookResult?.referenceType &&
            selectedWebhookResult.referenceId && (
              <Link
                className="text-blue underline"
                to={
                  getDetailLink(
                    selectedWebhookResult.referenceType,
                    selectedWebhookResult.referenceId
                  )!
                }
              >
                {getOrDefault(selectedWebhookResult.referenceId)}
              </Link>
            )}
        </div>
        <div className="grid grid-cols-3 p-4 justify-center items-center">
          <div>
            {formatDateOrDefault(selectedWebhookResult?.createdAt)}{' '}
            {formatTimeOrDefault(selectedWebhookResult?.createdAt)}
          </div>
          <div>{selectedWebhookResult?.eventType}</div>
          <div>{selectedWebhookResult?.status}</div>
        </div>
        <div className="p-4 font-[600]">Request</div>
        <TextArea
          autoSize
          value={
            selectedWebhookResult?.request &&
            JSON.stringify(selectedWebhookResult.request, null, 2)
          } //autoSize
          isDisabled
        />

        <div className="p-4 font-[600]">Response</div>
        <TextArea
          autoSize
          isDisabled
          value={selectedWebhookResult?.result} // autoSize
        />
      </Modal>
    </div>
  );
};

export default WebHookEvents;
