import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Address,
  AddressDisplay,
  areAddressesTheSame,
  Button,
  Contact,
  Customer,
  getIconCell,
  getOrDefault,
  getPersonFullName,
  ItemIcon,
  QueryTable,
  SimpleCell,
  useTranslation,
} from 'common';
import CustomerFormDrawer from './CustomerFormDrawer';
import { ALL_CUSTOMERS_KEY } from '../../../services/customer';
import Page from '../../../components/Page';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { apiClient } from '../../../services/httpClients/app';
import TooltipPill from 'app/src/components/TooltipPill';

const CustomersPage = () => {
  const [customerFormIsOpen, setCustomerFormIsOpen] = useState<boolean>(false);
  const [customerBeingEdited, setCustomerBeingEdited] = useState<
    Customer | undefined
  >(undefined);
  const navigate = useNavigate();
  const canCreate = isActionAllowed(FLAG_TYPES.CUSTOMER, 'create');
  const { tk } = useTranslation();

  const getAddressesDisplay = (
    shippingAddress?: Address,
    billingAddress?: Address
  ) => {
    if (areAddressesTheSame(billingAddress, shippingAddress))
      return (
        <div className="text-xs">
          <AddressDisplay address={shippingAddress} includeBreaks={false} />
        </div>
      );

    return (
      <div>
        <div className="text-xs">
          <AddressDisplay address={shippingAddress} includeBreaks={false} />
        </div>
        <div className="text-xs">
          <AddressDisplay address={billingAddress} includeBreaks={false} />
        </div>
      </div>
    );
  };

  const getContactDisplay = (contact?: Contact) => {
    if (contact) {
      return (
        <div>
          <div
            className="text-ellipsis overflow-hidden"
            title={getPersonFullName(contact)}
          >
            {getPersonFullName(contact)}
          </div>
          <div
            className="text-xs text-ellipsis overflow-hidden"
            title={contact.email}
          >
            {contact.email}
          </div>
        </div>
      );
    }
    return '-';
  };

  const getCurrencyDisplay = (currency?: string) => {
    if (currency) {
      return (
        <div>
          <p>{currency}</p>
        </div>
      );
    }
    return '-';
  };

  const cells: SimpleCell<Customer>[] = [
    getIconCell(ItemIcon.Customer),
    {
      renderCell: (data: Customer) => {
        return (
          <div className="flex gap-4">
            <div className="truncate">{getOrDefault(data.name)}</div>
            {data.partner && (
              <TooltipPill label={tk('Partner')} dataTestId="is-partner" />
            )}
          </div>
        );
      },
      className: 'font-semibold',
      key: 'name',
      headerLabel: 'Name',
      searchable: true,
      width: '25%',
      overflowCell: true,
      sortable: true,
    },
    {
      renderCell: (data: Customer) =>
        getAddressesDisplay(data.shippingAddress, data.billingAddress),
      key: 'shippingAddress',
      headerLabel: ['Ship to address', 'Bill to address'],
      width: '35%',
      overflowCell: true,
    },
    {
      renderCell: (data: Customer) => getContactDisplay(data.billingContact),
      key: 'billingContact',
      headerLabel: 'Billing contact',
      width: '30%',
      overflowCell: true,
    },
    {
      renderCell: (data: Customer) => getCurrencyDisplay(data.defaultCurrency),
      key: 'defaultCurrency',
      headerLabel: 'Default currency',
      align: 'center',
      width: '10%',
      overflowCell: true,
    },
  ];

  const handleTableRowClick = (selectedRow: Customer) => {
    navigate(`/customers/${selectedRow.id}`);
  };

  const closeCustomerForm = () => {
    setCustomerBeingEdited(undefined);
    setCustomerFormIsOpen(false);
  };

  return (
    <Page
      leftWidget="Customers"
      rightWidget={
        <Button
          onClick={() => setCustomerFormIsOpen(true)}
          label="Add customer"
          dataTestId="add-customer-button"
          className={canCreate ? undefined : 'invisible'}
        />
      }
    >
      <CustomerFormDrawer
        isOpen={customerFormIsOpen}
        customerBeingEdited={customerBeingEdited}
        onCreateCustomer={(c) => setCustomerBeingEdited(c)}
        onCloseForm={closeCustomerForm}
      />
      <QueryTable<Customer>
        filters={[
          {
            type: 'search',
            key: 'name',
            searchFields: ['name'],
          },
        ]}
        queryKey={ALL_CUSTOMERS_KEY}
        cells={cells}
        minWidth="900px"
        onRowClick={handleTableRowClick}
        fetchList={apiClient.listCustomers}
      />
    </Page>
  );
};

export default CustomersPage;
