import React from 'react';
import clsx from 'clsx';
import {
  amountToPercentage,
  BillingPeriodsType,
  Button,
  convertToDefaultScale,
  formatDurationRanges,
  formatMoneyOrDefault,
  formatNumber,
  formatPercentageString,
  Icon,
  isArrayNonEmpty,
  isNumber,
  ScheduleLine,
  useTranslation,
} from 'common';
import styles from './AdjustmentScheduleOverview.module.scss';

const AdjustmentScheduleOverview = ({
  mergedSchedule,
  billingPeriod,
  baseQuantity,
}: {
  baseQuantity?: number;
  billingPeriod: BillingPeriodsType;
  mergedSchedule: ScheduleLine[];
}) => {
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const { tk } = useTranslation();

  if (!isArrayNonEmpty(mergedSchedule)) return null;

  let numberOfOverrides = 0;
  let amountDifference = 0;
  mergedSchedule.forEach((scheduleLine) => {
    if (
      scheduleLine.price.amount !== scheduleLine.listPrice.amount ||
      scheduleLine.quantity !== baseQuantity
    ) {
      numberOfOverrides += scheduleLine.duration;
      amountDifference +=
        ((scheduleLine.price.amount || 0) -
          (scheduleLine.listPrice.amount || 0)) *
        scheduleLine.duration *
        (isNumber(scheduleLine.quantity) ? scheduleLine.quantity : 1);
    }
  });

  // post-multiplication prices should always to be shown in default scale.
  const amountDifferenceMoney = convertToDefaultScale({
    amount: amountDifference,
    currency: mergedSchedule[0].basePrice.currency,
    scale: mergedSchedule[0].basePrice.scale,
  });

  const checkIfDiscount = (scheduleLine: ScheduleLine): boolean =>
    isNumber(scheduleLine.price.amount) &&
    isNumber(scheduleLine.listPrice.amount) &&
    scheduleLine.price.amount < scheduleLine.listPrice.amount;

  const linesToShow = showAll ? mergedSchedule : mergedSchedule.slice(0, 5);

  return (
    <div
      className={styles.adjustmentScheduleOverview}
      data-testid="adjustment-schedule-overview"
    >
      {linesToShow.map((scheduleLine) => {
        return (
          <div
            key={scheduleLine.startIndex}
            className={styles.timeBasedOverviewContentRow}
          >
            <p>{formatDurationRanges(scheduleLine, billingPeriod)}</p>
            <div className={styles.priceOverview}>
              {scheduleLine.discount && checkIfDiscount(scheduleLine) && (
                <p
                  className={clsx(
                    styles.strikethrough,
                    styles.priceOverviewDiscount
                  )}
                >
                  {scheduleLine.listPrice.formattedAmount}
                </p>
              )}
              <p>{scheduleLine.price.formattedAmount}</p>
              {isNumber(scheduleLine.quantity) && (
                <p className={styles.qty}>
                  x <strong>{formatNumber(scheduleLine.quantity)} </strong>
                </p>
              )}
              {scheduleLine.discount && checkIfDiscount(scheduleLine) && (
                <p className={styles.percentOff}>
                  (
                  {formatPercentageString(
                    amountToPercentage(
                      scheduleLine.listPrice.amount,
                      scheduleLine.price.amount,
                      false
                    )
                  )}
                  )
                </p>
              )}
            </div>
          </div>
        );
      })}
      {mergedSchedule.length > 5 && (
        <div className={clsx(styles.showAll, showAll && styles.showAllOpen)}>
          <Button
            onClick={() => setShowAll(!showAll)}
            type="link"
            iconBefore={showAll ? Icon.CaretUp : Icon.CaretDown}
            label={showAll ? tk('Show less') : tk('Show all')}
          />
        </div>
      )}
      <div
        className={clsx(styles.timeBasedOverviewContentRow, styles.withDivider)}
      >
        <p data-testid="periods-changed">
          {numberOfOverrides + tk(' periods altered')}
        </p>
        <p data-testid="amount-difference">
          {formatMoneyOrDefault(amountDifferenceMoney)}
        </p>
      </div>
    </div>
  );
};

export default AdjustmentScheduleOverview;
