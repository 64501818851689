export interface IProfileNameInput {
  firstName?: string;
  lastName?: string;
}

export interface IProfileNameOutput {
  firstChar: string;
  firstName: string;
  fullName: string;
  lastNameFirstChar: string;
}

const handleAccountProfileName = (
  firstName?: string,
  lastName?: string
): IProfileNameOutput => {
  let firstChar = '';
  let lastNameFirstChar = '';
  if (
    firstName === '' ||
    firstName === undefined ||
    lastName === '' ||
    lastName === undefined
  ) {
    // eslint-disable-next-line no-param-reassign
    firstName = 'NA';
    // eslint-disable-next-line no-param-reassign
    lastName = 'NA';
    firstChar = 'N';
    lastNameFirstChar = 'N';
  } else {
    firstChar = firstName ? firstName.charAt(0) : 'N';
    lastNameFirstChar = lastName ? lastName.charAt(0) : 'N';
  }

  const fullName = `${firstName} ${lastName ? lastName.charAt(0) : ''}`;
  return {
    fullName,
    firstChar,
    firstName,
    lastNameFirstChar,
  };
};

export default handleAccountProfileName;
