import React from 'react';
import styles from './eventRules.module.scss';
import { ApprovalRuleSummary } from 'common';

const EventRules = ({ rules }: { rules: ApprovalRuleSummary[] }) => {
  const getRuleDescriptions = () => {
    return rules.map((r, idx) => {
      return (
        <p key={`${r.name}${idx}`} className={styles.eventRule}>
          {r.description}
        </p>
      );
    });
  };

  return <div className={styles.eventRules}>{getRuleDescriptions()}</div>;
};

export default EventRules;
