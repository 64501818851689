import React, { useCallback, useEffect, useState } from 'react';
import { log, WebhookResult } from 'common';
import { useTestAPIWebhook } from '../../../../services/api/webhook/webhook';

interface WebHookTestResultProps {
  hookId: string;
  testTime: Date | undefined;
}

interface WebHookDataProps {
  hookId: string;
  testTime: Date | undefined;
}

const WebhookData: React.FC<WebHookDataProps> = ({
  hookId,
  testTime,
}: WebHookDataProps) => {
  const [testResult, setTestResult] = useState('');
  const [testStatus, setTestStatus] = useState('');
  const [isTestError, setIsTestError] = useState(false);

  const handleTestError = useCallback((error: unknown) => {
    log.warn(error);
    setIsTestError(true);
  }, []);

  const handleTestSuccess = useCallback((data: WebhookResult) => {
    setTestStatus(data.status ?? 'sent');
    setTestResult(data.result ?? 'Success');
  }, []);

  const { mutate: runWebhookTest } = useTestAPIWebhook(
    handleTestSuccess,
    handleTestError
  );

  useEffect(() => {
    if (testTime) {
      runWebhookTest(hookId);
    }
  }, [hookId, testTime]);

  return (
    <div className="px-4 py-4 mb-4 border-gray-300 border-solid rounded-md w-96">
      <div
        className={
          isTestError ? 'text-sm text-red red' : 'text-sm text-green green'
        }
      >
        {testStatus && <div>Status: {testStatus}</div>}
        {testResult && <div>Result: {testResult}</div>}
      </div>
    </div>
  );
};

// TODO: make note that https://webhook.site/ can be used to see a sample webhook payload easily

export const WebhookTestWrapper: React.FC<WebHookTestResultProps> = ({
  hookId,
  testTime,
}: WebHookTestResultProps) =>
  testTime ? <WebhookData hookId={hookId} testTime={testTime} /> : null;
