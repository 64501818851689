import React from 'react';
import ApprovalNotifications from './ApprovalNotifications';
import { useFindSlackWorkspace } from '../../services/slack';
import {
  Card,
  CardHeader,
  ErrorFetching,
  InternalAppLink,
  Loading,
  useTranslation,
} from 'common';
import { useIsCurrentUserAdmin } from '../../core-utils/helperFunctions/userServiceHelper';
import { SETTINGS_SLACK } from '../../core-utils/routes';

// currently just slack notifications but will change to email/slack/whatever else
const UserNotifications: React.FC = () => {
  const { tk } = useTranslation();
  const {
    data: slackWorkspace,
    isLoading: isLoadingSlackWorkspace,
    isError: isErrorLoadingSlackWorkspace,
  } = useFindSlackWorkspace();
  const isAdmin = useIsCurrentUserAdmin();

  if (isLoadingSlackWorkspace) {
    return <Loading />;
  }

  if (isErrorLoadingSlackWorkspace) {
    return <ErrorFetching />;
  }
  const hasAddedAppToWorkspace = !!slackWorkspace;

  return (
    <Card>
      <CardHeader name={tk('Slack notifications')} />
      {!hasAddedAppToWorkspace && (
        <div>
          {tk('Slack notifications are not enabled for your organization.')}{' '}
          {isAdmin ? (
            <span>
              {tk('As an admin you can change this in ')}
              <InternalAppLink
                route={SETTINGS_SLACK}
                breadcrumbs={['Settings', 'Slack']}
              />
              .
            </span>
          ) : (
            <span>
              {tk('Please ask an admin about enabling notifications.')}
            </span>
          )}
        </div>
      )}
      <div className="h-4" />
      <ApprovalNotifications hasAddedAppToWorkspace={hasAddedAppToWorkspace} />
    </Card>
  );
};

export default UserNotifications;
