import React from 'react';
import styles from './ActionBar.module.scss';

interface Props {
  children: React.ReactNode;
}

const ActionBar: React.FC<Props> = ({ children }) => {
  return <div className={styles.actionBar}>{children}</div>;
};

export default ActionBar;
