import React from 'react';
import { Card, ZapierConfig } from 'common';
import { useOrgDefaults } from 'app/src/services/orgDefaults';
import ZapierSVG from '../../../assets/integration-logos/ZapierSVG';
import useScript from 'app/src/core-utils/helperFunctions/useScript';
import './ZapierSettings.css';
import LogoCardHeader from '../Connectors/LogoCardHeader';

const ZapierSettings = () => {
  const { data: orgConfigs } = useOrgDefaults();

  const configValue = orgConfigs?.get('zapierConfig')
    ?.configValue as ZapierConfig;

  const isConnected: boolean =
    (configValue?.enabled && configValue?.clientId !== null) || false;

  const loadedScript = useScript({
    url: 'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js',
    id: 'zapier',
    async: true,
    shouldLoad: isConnected,
  } as const);

  return (
    <>
      <Card>
        <LogoCardHeader logo={ZapierSVG} />
      </Card>
      <Card>
        {isConnected && loadedScript && (
          <div className="zapier-container">
            <zapier-workflow
              app-selection-display="show"
              client-id={configValue?.clientId}
              intro-copy-display="show"
              manage-zaps-display="show"
              template-limit={2}
              template-style="row"
              theme="light"
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default ZapierSettings;
