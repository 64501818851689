import React, { useState } from 'react';
import {
  Button,
  FormField,
  Input,
  PaymentMethod,
  useFormValidation,
  useTranslation,
} from 'common';
import {
  achAccountNumberValidator,
  ACHDetails,
  achRoutingNumberValidator,
} from './utilities';

interface Props {
  closeForm: () => void;
  paymentMethod: PaymentMethod;
  submit: (method: PaymentMethod) => void;
}

const ACHForm: React.FC<Props> = ({ paymentMethod, submit, closeForm }) => {
  const { tk } = useTranslation();
  const [draft, setDraft] = useState<ACHDetails>(
    paymentMethod.configuration || {}
  );

  // separate state here since it's optional/there's no validation and doesn't fit into ACHDetails type
  const [descriptionDraft, setDescriptionDraft] = useState<string>(
    paymentMethod.description || ''
  );

  const save = () => {
    const body = {
      ...paymentMethod,
      description: descriptionDraft,
      configuration: draft as PaymentMethod['configuration'],
    };
    submit(body);
  };

  const { getErrorToShow, setHasVisitedField, isFormValid } =
    useFormValidation<ACHDetails>(
      [
        {
          fieldName: 'accountName',
          isRequired: true,
          humanReadableName: 'Account name',
        },
        {
          fieldName: 'bankName',
          isRequired: true,
          humanReadableName: 'Bank name',
        },
        {
          fieldName: 'routingNumber',
          isRequired: true,
          validator: achRoutingNumberValidator,
          humanReadableName: 'Routing number',
        },
        {
          fieldName: 'accountNumber',
          isRequired: true,
          validator: achAccountNumberValidator,
          humanReadableName: 'Account number',
        },
      ],
      draft
    );

  const handleFieldChange = (fieldName: string, value: any) => {
    setDraft((prev) => ({ ...prev, [fieldName]: value }));
  };

  const submitIfValid = () => {
    if (isFormValid) {
      save();
    }
  };

  return (
    <div>
      <div className="font-bold text-lg">{tk('ACH')}</div>
      <div className="h-4" />
      <FormField label={tk('Description')}>
        <Input
          id="description"
          className="h-8"
          onChange={setDescriptionDraft}
          value={descriptionDraft}
          onEnter={submitIfValid}
        />
      </FormField>

      <div className="font-bold text-base">Bank details</div>
      <div className="h-4" />
      <FormField
        label={tk('Account name')}
        errorToShow={getErrorToShow('accountName')}
      >
        <Input
          id="accountName"
          className="h-8"
          onChange={(rv) => handleFieldChange('accountName', rv)}
          value={draft.accountName}
          onEnter={submitIfValid}
          onBlur={() => setHasVisitedField('accountName')}
        />
      </FormField>
      <FormField
        label={tk('Bank name')}
        errorToShow={getErrorToShow('bankName')}
      >
        <Input
          id="bankName"
          className="h-8"
          onChange={(rv) => handleFieldChange('bankName', rv)}
          value={draft.bankName}
          onEnter={submitIfValid}
          onBlur={() => setHasVisitedField('bankName')}
        />
      </FormField>
      <FormField
        label={tk('Routing/ABA number')}
        errorToShow={getErrorToShow('routingNumber')}
      >
        <Input
          id="routingNumber"
          className="h-8"
          onChange={(rv) => handleFieldChange('routingNumber', rv)}
          value={draft.routingNumber}
          onEnter={submitIfValid}
          onBlur={() => setHasVisitedField('routingNumber')}
        />
      </FormField>
      <FormField
        label={tk('Account number')}
        errorToShow={getErrorToShow('accountNumber')}
      >
        <Input
          id="accountNumber"
          className="h-8"
          onChange={(rv) => handleFieldChange('accountNumber', rv)}
          value={draft.accountNumber}
          onEnter={submitIfValid}
          onBlur={() => setHasVisitedField('accountNumber')}
        />
      </FormField>
      <div className="flex justify-end gap-2 pt-6 w-full">
        <Button type="secondary" label={tk('Cancel')} onClick={closeForm} />
        <Button isDisabled={!isFormValid} label={tk('Save')} onClick={save} />
      </div>
    </div>
  );
};

export default ACHForm;
