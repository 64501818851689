export class APIResponse<T> {
  responseData: T[];

  isLoading: boolean;

  isError: boolean;

  error: unknown;

  constructor(
    responseData: T[],
    isLoading: boolean,
    isError: boolean,
    error: unknown
  ) {
    this.responseData = responseData;
    this.isLoading = isLoading;
    this.isError = isError;
    this.error = error;
  }
}
