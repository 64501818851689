import React, { useState } from 'react';
import {
  CreateProposalRequest,
  FormField,
  FormModal,
  Input,
  useTranslation,
} from 'common';

interface Props {
  onChange: (updateProposalRequest: CreateProposalRequest) => void;
  onClose: () => void;
  proposalName: string;
}

const NameModal: React.FC<Props> = ({ proposalName, onChange, onClose }) => {
  const { tk } = useTranslation();
  const [name, setName] = useState<string>(proposalName || 'Untitled');
  const [open, setOpen] = useState<boolean>(true);

  const save = () => {
    onChange({ name });
    setOpen(false);
  };

  const cancel = () => {
    onClose();
    setOpen(false);
  };

  return (
    <FormModal
      isOpen={open}
      isNewEntity
      onClose={cancel}
      entityTypeName={tk('Proposal Template')}
      onSubmit={save}
      isProcessing={false}
      width={500}
      submitDataTestId="saveProposalName"
    >
      <FormField
        label={tk('Template name')}
        infoText={tk(
          'This will be the name as it appears to your organization.'
        )}
      >
        <Input
          dataTestId="proposalName"
          onChange={(value) => setName(value)}
          onEnter={save}
          value={name}
        />
      </FormField>
    </FormModal>
  );
};

export default NameModal;
