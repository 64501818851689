import React, { useState } from 'react';
import {
  formatDatetime,
  getErrorCode,
  getErrorMessage,
  log,
  SigningDocument,
  SigningDocumentViewResponse,
} from 'common';
import { getDiagnostics, getView } from '../services/api/docusign/docusign';
import { useAuth } from '../Auth';

interface Props {
  id: string;
  signingDocument: SigningDocument;
}

const SignerInfo: React.FC<Props> = ({ id, signingDocument }) => {
  const [signingDocumentError, setSigningDocumentError] = useState<
    string | undefined
  >();
  const [diagnostics, setDiagnostics] = useState<string | undefined>();

  const gotoView = () => {
    getView(id)
      .then((data: SigningDocumentViewResponse) => {
        if (data.url) {
          window.open(data.url, '_blank');
        }
      })
      .catch((error: unknown) => {
        if (getErrorCode(error) === 'SIGNING_DOCUMENT_NOT_ACCESSIBLE') {
          setSigningDocumentError(
            'Signing document is not accessible. Please contact your DocuSign account admin to access the document.'
          );
        } else {
          setSigningDocumentError(getErrorMessage(error) ?? 'Unknown error');
        }
      });
  };

  const showDiagnostics = () => {
    getDiagnostics(id).then((data: any) => {
      const signers: any[] = data.recipients.signers;
      const miniSigners = signers.map((s) => {
        return {
          clientUserId: s.clientUserId,
          name: s.name,
          email: s.email,
        };
      });

      setDiagnostics('Recipients: ' + JSON.stringify(miniSigners));
    }, log.error);
  };

  const auth = useAuth();
  return (
    <div>
      Signed{' '}
      {signingDocument.signedAt ? formatDatetime(signingDocument.signedAt) : ''}{' '}
      via DocuSign.
      <br />
      Signed by: {signingDocument.signer.firstName}{' '}
      {signingDocument.signer.lastName}
      {signingDocument.signer.role && (
        <>, {signingDocument.signer.role}</>
      )}, {signingDocument.signer.email}
      {!signingDocumentError && (
        <div
          onClick={gotoView}
          className="underline cursor-pointer hover:text-blue"
        >
          View signed document
        </div>
      )}
      {signingDocumentError && (
        <div className="text-red">{signingDocumentError}</div>
      )}
      {auth.isSystemAdmin && (
        <div
          onClick={showDiagnostics}
          className="underline cursor-pointer hover:text-blue"
        >
          Show signed document diagnostics
        </div>
      )}
      {diagnostics && <div>{diagnostics}</div>}
    </div>
  );
};

export default SignerInfo;
