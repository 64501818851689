import { StageMapping } from 'common';

export interface CacheflowMapping {
  cacheflowStatus: string;
  cacheflowStatusInfo: string;
  cacheflowStatusMapping: StageMapping['proposalEventType'];
}

export const cacheflowMappings: CacheflowMapping[] = [
  {
    cacheflowStatus: 'Draft',
    cacheflowStatusMapping: 'set_to_draft',
    cacheflowStatusInfo:
      'Indicates the proposal is in draft state and has not been shared with an end-customer.',
  },
  {
    cacheflowStatus: 'Pending Approval',
    cacheflowStatusMapping: 'approval_requested',
    cacheflowStatusInfo:
      'If Approvals are enabled on your account, this status indicates that a proposal owner has sent a proposal for approval.',
  },
  {
    cacheflowStatus: 'Approved',
    cacheflowStatusMapping: 'approval_approved',
    cacheflowStatusInfo:
      'If Approvals are enabled on your account, this status indicates that a proposal has been approved and ready to be shared.',
  },
  {
    cacheflowStatus: 'Activated',
    cacheflowStatusMapping: 'activated',
    cacheflowStatusInfo:
      'The proposal owner has shared a proposal where no signature is required.',
  },
  {
    cacheflowStatus: 'Started',
    cacheflowStatusMapping: 'started',
    cacheflowStatusInfo:
      'The proposal owner has shared a proposal that requires signature.',
  },
  {
    cacheflowStatus: 'Signed',
    cacheflowStatusMapping: 'signed',
    cacheflowStatusInfo:
      'Indicates that the prospect or customer has signed the proposal.',
  },
  {
    cacheflowStatus: 'Completed',
    cacheflowStatusMapping: 'accepted',
    cacheflowStatusInfo:
      'Indicates that the prospect or customer has entered payment information or agreed to pay.',
  },
  {
    cacheflowStatus: 'Canceled',
    cacheflowStatusMapping: 'cancelled',
    cacheflowStatusInfo:
      'Indicates that the proposal owner canceled the proposal.',
  },
];
