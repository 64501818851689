import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useDeleteProposal } from '../../../../services/proposal';
import { PROPOSALS } from '../../../../core-utils/routes';

import {
  ConfirmDeleteModal,
  Icon,
  isTemplateProposalType,
  PopOutMenuOption,
  Proposal,
  ProposalSummary,
  useToast,
  useTranslation,
} from 'common';

interface Props {
  proposal: Proposal | ProposalSummary;
}

const ProposalDeleteOption: React.FC<Props> = ({ proposal }) => {
  const [showDeletionConfirm, setShowDeletionConfirm] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const showToast = useToast();
  const deletableStates = ['draft', 'expired', 'cancelled'];
  const { status } = proposal;
  const isDeletable: boolean = deletableStates.includes(status);
  const { tk } = useTranslation();

  const handleDeleteError = () => {
    showToast.error('Proposal deletion failed');
  };

  const handleDeleteSuccess = () => {
    showToast.info('Proposal deleted');
    navigate(PROPOSALS);
  };

  const { mutate: deleteProposal } = useDeleteProposal(
    proposal.id,
    handleDeleteSuccess,
    handleDeleteError,
    queryClient
  );

  return (
    <>
      <PopOutMenuOption
        icon={Icon.Trash}
        isDangerous
        isDisabled={!isDeletable}
        name={tk('Delete Proposal')}
        onClick={() => setShowDeletionConfirm(true)}
        title={tk('Delete')}
      />

      <ConfirmDeleteModal
        isOpen={showDeletionConfirm}
        onClose={() => setShowDeletionConfirm(false)}
        onConfirm={deleteProposal}
        typeName={isTemplateProposalType(proposal) ? 'template' : 'proposal'}
      />
    </>
  );
};

export default ProposalDeleteOption;
