import {
  Cell,
  DomainField,
  ErrorFetching,
  ExternalFieldMapping,
  Icon,
  IconButton,
  SearchSelect,
  SimpleTable,
} from 'common';
import React from 'react';
import {
  useCfProposalProperties,
  useDealProperties,
} from '../../../services/externalCrm';
import { cacheflowMappings } from './ConnectorConstants/cacheflowStatusMapping';
import { ConnectorInfoObject } from './ConnectorConstants/constants';

interface Props {
  connectorInfo: ConnectorInfoObject;
  fieldMappings: ExternalFieldMapping[];
  setFieldMappings: (value: ExternalFieldMapping[]) => void;
  setSaveableChanges: (arg: boolean) => void;
}

const ProposalFieldMappings = ({
  connectorInfo,
  fieldMappings,
  setFieldMappings,
  setSaveableChanges,
}: Props) => {
  const {
    data: crmProperties,
    isError: isCrmPropertiesError,
    isLoading: isLoadingDealProperties,
  } = useDealProperties();

  const {
    data: cfProperties,
    isError: isCfPropertiesError,
    isLoading: isLoadingCfProperties,
  } = useCfProposalProperties();

  const findAndReplaceMapping = (
    prevMapping: ExternalFieldMapping,
    newMapping: ExternalFieldMapping,
    mappings: ExternalFieldMapping[]
  ) => {
    const foundMappingIndex = mappings.findIndex(
      (map) => map.domainField.name === prevMapping.domainField.name
    );
    return [
      ...mappings.slice(0, foundMappingIndex),
      newMapping,
      ...mappings.slice(foundMappingIndex + 1, mappings.length),
    ];
  };

  const typeMapping: { [key: string]: string[] } = {
    Number: ['Integer', 'Number'],
    Date: ['Date', 'Datetime'],
    Datetime: ['Date', 'Datetime'],
    String: ['String'],
    Boolean: ['Boolean'],
  };

  const handleUnmapStage = (cfField: DomainField) => {
    const foundMappingIndex: number = fieldMappings.findIndex((mapping) => {
      return mapping.domainField.name === cfField.name;
    });

    if (foundMappingIndex >= 0) {
      const newbies = fieldMappings
        .slice(0, foundMappingIndex)
        .concat(fieldMappings.slice(foundMappingIndex + 1));
      setFieldMappings(newbies);

      setSaveableChanges(true);
    }
  };

  const handleSelectProperty = (crmFieldName: string, cfField: DomainField) => {
    const crmObjectProperty = crmProperties?.find(
      (prop) => prop.name === crmFieldName
    )!;
    const prevFieldMapping = fieldMappings.find(
      (mapping) => mapping.domainField.name === cfField.name
    );
    const newFieldMapping: ExternalFieldMapping = {
      crmObjectProperty,
      domainField: cfField,
    };
    let newMappings;
    if (!prevFieldMapping) {
      newMappings = [...fieldMappings, newFieldMapping];
    } else {
      newMappings = findAndReplaceMapping(
        prevFieldMapping,
        newFieldMapping,
        fieldMappings
      );
    }
    setFieldMappings(newMappings);
    setSaveableChanges(true);
  };

  const stageCells: Cell<DomainField>[] = [
    {
      key: 'cachflowProperty',
      headerLabel: 'Cacheflow proposal property',
      renderCell: (cell: DomainField) => (
        <span className="cacheflow-stage">
          <p>{cell.displayName}</p>
        </span>
      ),
    },
    {
      key: 'arrow',
      headerLabel: '',
      width: '10%',
      renderCell: () => <Icon.ArrowRight />,
    },
    {
      key: `${connectorInfo.source}Stage`,
      headerLabel: `${connectorInfo.name} deal property`,
      width: '55%',
      renderCell: (cell: DomainField) => {
        const selectedCrmProp = fieldMappings.find((fm) => {
          return fm.domainField.name === cell.name;
        })?.crmObjectProperty;
        return (
          <div className="connector-select-stage">
            <SearchSelect
              placeholder="No mapping configured"
              noResultsText={`No fields of type "${cell.type}" found`}
              displayField="label"
              options={
                crmProperties
                  ? crmProperties
                      .filter((property) =>
                        typeMapping[property.type]?.includes(cell.type)
                      )
                      .sort((a, b) => a.name.localeCompare(b.name))
                  : []
              }
              onChange={(newCrmProp) => {
                handleSelectProperty(newCrmProp.name, cell);
              }}
              value={selectedCrmProp}
            />
            <IconButton
              icon={Icon.CloseCircle}
              label="remove-field-mapping"
              onClick={() => handleUnmapStage(cell)}
            />
          </div>
        );
      },
    },
  ];

  if (isCrmPropertiesError || isCfPropertiesError) {
    return <ErrorFetching />;
  }

  const sortedProperties = cfProperties?.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );

  return (
    <div className="connector-opportunities">
      <section className="bottom">
        <h3>Proposal field mapping</h3>
        <SimpleTable
          cells={stageCells}
          rows={sortedProperties}
          disableSearch
          disableShadow
          numberOfSkeletonRows={cacheflowMappings.length}
          isLoading={isLoadingCfProperties || isLoadingDealProperties}
        />
      </section>
    </div>
  );
};
export default ProposalFieldMappings;
