import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  ApiWebhook,
  CreateApiWebhookRequest,
  WebhookResult,
  WebhooksMetadata,
} from 'common';
import { apiClient } from '../../httpClients/app';

const ALL_API_WEBHOOKS_KEY = 'APIWebhooks';
const WEBHOOKS_METADATA_KEY = 'WebhooksMetadata';
const CREATE_API_WEBHOOK = 'createAPIWebhook';
const DELETE_API_WEBHOOK = 'deleteAPIWebhook';

export const useWebhooksMetadata = (): UseQueryResult<WebhooksMetadata> =>
  useQuery({
    queryKey: [WEBHOOKS_METADATA_KEY],
    queryFn: async () => {
      const { data } = await apiClient.getWebhooksMetadata();
      return data;
    },
  });

export const useGetAPIWebhooks = (): UseQueryResult<ApiWebhook[]> =>
  useQuery({
    queryKey: [ALL_API_WEBHOOKS_KEY],
    queryFn: async () => {
      const { data } = await apiClient.getHooks();
      return data;
    },
  });

const submitAPIWebhook = async (body: CreateApiWebhookRequest) => {
  const { data } = await apiClient.addWebhook(body);
  return data;
};

export const useCreateAPIWebhook = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [CREATE_API_WEBHOOK],
    mutationFn: submitAPIWebhook,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_API_WEBHOOKS_KEY] });
    },
  });

const testAPIWebhook = async (id: string) => {
  const { data } = await apiClient.testHook(id);
  return data;
};

export const useTestAPIWebhook = (
  onSuccess: (data: WebhookResult) => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationKey: ['testApiHook'],
    mutationFn: testAPIWebhook,
    onSuccess,
    onError,
    onSettled: () => {},
  });

const deleteAPIWebhook = async (id: string) => {
  const { data } = await apiClient.removeHook(id);
  return data;
};

export const useDeleteAPIWebhook = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [DELETE_API_WEBHOOK],
    mutationFn: deleteAPIWebhook,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_API_WEBHOOKS_KEY] });
    },
  });
