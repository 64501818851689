import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  AutoFormCard,
  User,
  UserProfile,
  useTranslation,
  ValidationFormField,
} from 'common';

interface Props {
  userProfile: UserProfile;
}

const UserForm: React.FC<Props> = ({ userProfile }) => {
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const fields: ValidationFormField<User & UserProfile>[] = [
    {
      fieldName: 'firstName',
      isRequired: true,
      humanReadableName: 'First name',
    },
    {
      fieldName: 'lastName',
      isRequired: true,
      humanReadableName: 'Last name',
    },
    {
      fieldName: 'roleType',
      humanReadableName: 'Role',
    },
    {
      fieldName: 'username',
      humanReadableName: 'Email',
    },
  ];

  return (
    <AutoFormCard
      className="max-w-[500px]"
      entityName="user"
      queryClient={queryClient}
      header={tk('User profile')}
      isDisabled
      entity={{
        ...userProfile.user,
        ...userProfile,
      }}
      fields={fields}
    />
  );
};

export default UserForm;
