import {
  isChangeProposalType,
  isOneTimeProduct,
  useCurrentProposal,
  useRowItem,
} from 'common';

/** on a change proposal, it's currently illegal to remove the last item or
 * remove a pre-existing one-time item, but removing
 * a newly added one is fine. **/
export const useIsItemRemovable = () => {
  const item = useRowItem();
  const proposal = useCurrentProposal();

  const foundChangeType = proposal.changes?.itemChanges?.find(
    (ic) => ic.key === item.key
  )?.changeType;

  const isRemovable =
    !isChangeProposalType(proposal) ||
    ((!isOneTimeProduct(item.product) || foundChangeType === 'add') &&
      proposal.proposalItems.length > 1);

  const isRemoveItemTooltipVisible =
    isChangeProposalType(proposal) &&
    isOneTimeProduct(item.product) &&
    foundChangeType !== 'add';

  return { isRemovable, isRemoveItemTooltipVisible };
};
