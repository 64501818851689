import React from 'react';
import useLoadingScreen from './hook';
import {
  Button,
  getErrorMessage,
  Icon,
  isNotAccessible,
  isNotFound,
  Modal,
  log,
  useToast,
} from 'common';

interface ErrorHandlerProps extends React.ComponentPropsWithoutRef<any> {
  error?: unknown;
  message?: string;
  onclick?: () => void;
  titleHeading?: React.ReactElement | string;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({
  titleHeading,
  message,
  error,
  onclick,
}: ErrorHandlerProps) => {
  const { open, handleClick } = useLoadingScreen(true);
  const showToast = useToast();

  const internalOnClick = () => {
    handleClick();
    if (onclick) {
      onclick();
    }
  };

  const errorText = () => {
    return (
      getErrorMessage(error) ||
      message ||
      "Shoot! We didn't want you to ever see this, but it happened. Try refreshing, if it still happens contact support@getcacheflow.com."
    );
  };

  const errorTitle = () => {
    if (titleHeading) return titleHeading;

    if (error && isNotFound(error)) {
      return 'Data is unavailable';
    }
    if (error && isNotAccessible(error)) {
      return 'Not allowed';
    }
    log.warn('Error occurred ', error);
    return 'App currently unavailable';
  };

  if (open && error && isNotAccessible(error)) {
    showToast.error(getErrorMessage(error) || 'Not allowed');
    return null;
  }

  return (
    <Modal isOpen={open} width={450} onClose={() => {}}>
      <div className="flex flex-col items-center">
        <div className="flex flex-row border-solid border-b-1 border-gray-lines justify-between item-center">
          <div className="flex text-orange">
            <Icon.Information width={50} height={50} />
          </div>
          <div className="flex pl-2 text-lg font-semibold items-center">
            {errorTitle()}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-center">{errorText()}</p>
          <Button
            label="Close"
            onClick={internalOnClick}
            className="w-[132px] mt-5"
          />
        </div>
      </div>
    </Modal>
  );
};
export default ErrorHandler;
