import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemIcon, useTranslation } from 'common';
import ActionBarButton from '../ActionBarButton';
import styles from './SubscriptionButton.module.scss';

interface Props {
  contractId: string;
  isDisabled: boolean;
}

const SubscriptionButton: React.FC<Props> = ({ contractId, isDisabled }) => {
  const navigate = useNavigate();
  const { tk } = useTranslation();

  return (
    <ActionBarButton
      className={styles.subscriptionButton}
      dataTestId="action-bar-subscription"
      icon={(props) => <ItemIcon.Subscription {...props} />}
      isDisabled={isDisabled}
      onClick={() => navigate(`/subscriptions/${contractId}`)}
      tooltip={tk('Subscription')}
    />
  );
};

export default SubscriptionButton;
