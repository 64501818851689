import React from 'react';
import { getErrorData, Icon, PopOutMenuOption, useToast } from 'common';
import { useQueryClient } from '@tanstack/react-query';
import { useTriggerTransferProcess } from '../../services/transfer';

interface TriggerProcessAdmin {
  detailLink: string;
  transferId: string;
}

const TriggerProcess: React.FC<TriggerProcessAdmin> = ({
  transferId,
  detailLink,
}: TriggerProcessAdmin) => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleSuccess = () => {
    showToast.success('Transfer process has been successfully posted');
  };

  const handleError = (error: unknown) => {
    const msg = JSON.stringify(getErrorData(error));
    showToast.error('Transfer process failed: ' + msg);
  };

  const {
    mutate: triggerProcess,
    isError,
    error,
  } = useTriggerTransferProcess(
    detailLink,
    handleSuccess,
    handleError,
    queryClient
  );

  if (isError) {
    handleError(error);
  }

  return (
    <PopOutMenuOption
      icon={Icon.TriangleRight}
      isDangerous
      onClick={() => triggerProcess(transferId)}
      title="Retry payment"
    />
  );
};

export default TriggerProcess;
