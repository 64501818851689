import React from 'react';
import { ToggleSwitch } from 'common';

interface Props {
  description: string;
  isDisabled?: boolean;
  isEnabled: boolean;
  name: string;
  onChange: (isEnabled: boolean) => void;
}

const NotificationOption: React.FC<Props> = ({
  name,
  description,
  isEnabled,
  onChange,
  isDisabled = false,
}) => (
  <div className="flex justify-between">
    <div>
      <div
        className={`
			  font-bold pb-1
			  ${isDisabled ? 'text-slate-lighter' : ''}
		  `}
      >
        {name}
      </div>
      <div
        className={`
			  text-xs
			  ${isDisabled ? 'text-slate-lighter' : 'text-slate-darker'}
		  `}
      >
        {description}
      </div>
    </div>
    <div className="w-1/6 flex justify-center">
      <ToggleSwitch
        value={isEnabled}
        onChange={onChange}
        name="blahdeletehtis"
        isDisabled={isDisabled}
      />
    </div>
  </div>
);

export default NotificationOption;
