import React, { useState } from 'react';
import { Select } from 'antd';

import {
  ApprovalRule,
  Button,
  ConfirmDeleteModal,
  Icon,
  isNumber,
  NumberInput,
  ProposalFreeMonthsRuleConfig,
  ProposalSummary,
  ProposalTermLengthRuleConfig,
} from 'common';

import { GroupOption, searchFilterOption } from './_approvalRulesConfig';

import styles from './ApprovalRuleStyles.module.scss';
import { getConfig } from './ruleUtils';

interface Props {
  allGroupOptions: GroupOption[];
  approvalRule: ApprovalRule;
  conditionOperator: string | string[];
  configKey: string;
  description: string;
  onRuleChange: (id: string, changedRule: ApprovalRule | null) => void;
  proposalType: ProposalSummary['proposalType'];
}

const DEFAULT_NUMBER = 0;

export const ApprovalRuleNumberRow = ({
  allGroupOptions,
  approvalRule,
  conditionOperator,
  configKey,
  description,
  onRuleChange,
  proposalType,
}: Props) => {
  const getValue = (): number => {
    const config = getConfig<
      ProposalTermLengthRuleConfig | ProposalFreeMonthsRuleConfig
    >(approvalRule);

    if (config && (configKey === 'termLength' || configKey === 'freeMonths')) {
      const configValue = config[configKey as keyof typeof config];

      return isNumber(configValue) ? configValue : DEFAULT_NUMBER;
    }

    return DEFAULT_NUMBER;
  };

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const [value, setValue] = useState<number>(getValue());
  const [showSelectError, setShowSelectError] = useState<boolean>(false);

  const updateRule = (newValue: number): ApprovalRule => ({
    id: approvalRule.id,
    name: approvalRule.name,
    config: {
      ruleType: approvalRule.config?.ruleType!,
      proposalType,
      [configKey]: newValue,
    },
  });

  const onNumberBlur = (newValue: number | undefined = 0) => {
    setValue(newValue);
    onRuleChange(approvalRule.id!, updateRule(newValue));
  };

  const onGroupsChange = (groupIds: string[]) => {
    setShowSelectError(groupIds.length === 0);

    const payload = {
      ...updateRule(value),
      approvalGroupIds: groupIds,
    };

    onRuleChange(approvalRule.id!, payload);
  };

  return (
    <div className={styles.ruleRow}>
      <div className={styles.name}>
        <Button
          primaryIcon={Icon.CloseCircle}
          onClick={() => setIsDeleteConfirmationOpen(true)}
          type="secondary"
          className={styles.removeIcon}
        />
        <span className={styles.ruleName}>{description}</span>
      </div>

      <div className={styles.condition}>{conditionOperator}</div>

      <div className={styles.input}>
        <NumberInput
          defaultValue={0}
          value={value}
          isDisabled={!approvalRule}
          onBlur={onNumberBlur}
          suffix="Months"
          max={1000}
        />
      </div>

      <div className={styles.groups}>
        {allGroupOptions.length > 0 && (
          <Select
            onChange={onGroupsChange}
            value={approvalRule.approvalGroupIds?.map((id) => id)}
            options={allGroupOptions}
            mode="multiple"
            filterOption={searchFilterOption}
            className={styles.groupWidth}
            placeholder="Select approval group"
            disabled={!approvalRule}
            status={showSelectError ? 'error' : ''}
          />
        )}
      </div>

      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onConfirm={() => onRuleChange(approvalRule.id!, null)}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        typeName="condition"
      />
    </div>
  );
};
