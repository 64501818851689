import React, { SVGProps } from 'react';
import { MdiIcon } from 'common';

// You can search icons at https://pictogrammers.com/library/mdi/

const wrapIcon = (
  Component: React.ComponentType<SVGProps<SVGSVGElement>>
): React.FC => {
  // This matches the size and proportions of our 36px icon set
  return ({ className }: SVGProps<SVGSVGElement>) => (
    <Component
      style={{ margin: '5px', fill: 'currentcolor' }}
      width={26}
      height={26}
      className={className}
    />
  );
};

export const EditorIconBold = wrapIcon(MdiIcon.FormatBold);
export const EditorIconUndo = wrapIcon(MdiIcon.Undo);
export const EditorIconRedo = wrapIcon(MdiIcon.Redo);
export const EditorIconLink = wrapIcon(MdiIcon.LinkVariant);
export const EditorIconUnlink = wrapIcon(MdiIcon.LinkVariantMinus);
export const EditorIconUnderline = wrapIcon(MdiIcon.FormatUnderline);
export const EditorFormatListBulleted = wrapIcon(MdiIcon.FormatListBulleted);
export const EditorIconHighlight = wrapIcon(MdiIcon.FormatColorHighlight);

export const EditorIconAddTable = wrapIcon(MdiIcon.TableLargePlus);
export const EditorIconRemoveTable = wrapIcon(MdiIcon.TableLargeRemove);

export const EditorIconColumnInsertLeft = wrapIcon(
  MdiIcon.TableColumnPlusBefore
);
export const EditorIconColumnInsertRight = wrapIcon(
  MdiIcon.TableColumnPlusAfter
);
export const EditorIconColumnRemove = wrapIcon(MdiIcon.TableColumnRemove);

export const EditorIconRowInsertLeft = wrapIcon(MdiIcon.TableRowPlusBefore);
export const EditorIconRowInsertRight = wrapIcon(MdiIcon.TableRowPlusAfter);
export const EditorIconRowRemove = wrapIcon(MdiIcon.TableRowRemove);
