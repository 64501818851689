import React from 'react';
import {
  formatCurrencyOrDefault,
  isNumber,
  Item,
  Money,
  NumberInput,
  ScheduleLine,
} from 'common';
import TimeBasedScheduleChart from '../TimeBasedScheduleChart';

import styles from './TimeBasedPricing.module.scss';
import clsx from 'clsx';
import ListPriceSalesPriceInput from '../../ListPriceSalesPriceInput';

const TimeBasedScheduleRow = ({
  item,
  rowItem,
  period,
  unitPrice,
  updateRowItemPrice,
  updateQuantityOverride,
  quantity,
  currency,
}: {
  currency?: string;
  item: Item;
  period: number;
  quantity?: number;
  rowItem: ScheduleLine;
  unitPrice: number;
  updateQuantityOverride: (value: number, index: number) => void;
  updateRowItemPrice: (
    listPrice: Money,
    salesPrice: Money,
    index: number
  ) => void;
}) => {
  return (
    <div
      className={clsx(styles.scheduleRow, isNumber(quantity) && styles.withQty)}
      data-testid={
        rowItem.startIndex === 0 ? 'tbp-schedule-first-row' : 'tbp-schedule-row'
      }
    >
      <p>{period}</p>
      <p>
        {isNumber(quantity)
          ? formatCurrencyOrDefault(
              currency,
              (rowItem.price.amount || 0) * quantity
            )
          : rowItem.price.formattedAmount}
      </p>
      <TimeBasedScheduleChart
        percentageWidth={((rowItem.price.amount || 0) / unitPrice) * 100} // there is no visual indication for price increase
      />
      {isNumber(quantity) && (
        <div className={styles.smallInput}>
          <NumberInput
            value={rowItem.quantity || quantity || 0}
            onChange={(value) => {
              if (isNumber(value)) {
                updateQuantityOverride(value, rowItem.startIndex);
              }
            }}
            dataTestId={`qty-input-${period}`}
          />
        </div>
      )}
      <ListPriceSalesPriceInput
        timeBasedPricing
        id={String(period)}
        basePrice={rowItem.basePrice}
        currentListPrice={rowItem.listPrice}
        currentPrice={rowItem.price}
        isSaving={false}
        item={item}
        onPriceChange={(listPrice, salesPrice) =>
          updateRowItemPrice(listPrice, salesPrice, rowItem.startIndex)
        }
      />
    </div>
  );
};

export default TimeBasedScheduleRow;
