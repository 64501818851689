import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { log } from 'common';
import { authAxios } from '../../../services/httpClients/auth';

const GET_AUTH_TOKEN_PATH = '/dev/token';

// this is a temporary interface till we get a proper implementation of auth flow
interface Auth {
  orgId: string;
  token: string;
  userId: string;
}

// check if small interfaces needs to be created for the request body param
export const useAuthToken = (
  runthis: boolean,
  params: any
): UseQueryResult<Auth> =>
  useQuery({
    queryKey: [params.subject, runthis],
    queryFn: async () => {
      log.debug('creating dev token', params);
      const { data } = await authAxios.post<Auth>(GET_AUTH_TOKEN_PATH, params);
      return data;
    },
    enabled: runthis,
    retry: false,
    gcTime: -1,
  });
