import {
  ApprovalGroup,
  ApprovalRequestSummary,
  ApprovalRule,
  CreateProposalApprovalRuleRequest,
  UpdateProposalApprovalRuleRequest,
} from 'common';
import { apiClient } from './httpClients/app';
import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

const GET_ALL_PROPOSAL_APPROVAL_RULES_KEY = 'allProposalApprovalRules';
const GET_SPECIFIC_PROPOSAL_APPROVAL_RULE_KEY = 'specificProposalApprovalRule';
const GET_SPEC_PROPOSAL_APPROVAL_RULE_PATH =
  '/api/latest/settings/proposal-approval-rules/';
const CREATE_PROPOSAL_APPROVAL_RULES_PATH =
  '/api/latest/settings/proposal-approval-rules';
const DELETE_PROPOSAL_APPROVAL_RULE_KEY = 'deleteProposalApprovalRule';

const GET_MY_APPROVER_REQUESTS_KEY = '/api/latest/approval-requests/me';

const getProposalApprovalRuleQueryKey = (id: string) => {
  return `${GET_SPECIFIC_PROPOSAL_APPROVAL_RULE_KEY}_${id}`;
};

const getUserApprovalGroupsQueryKey = (id: string) => {
  return `/api/latest/data/users/${id}/approval-groups'`;
};

export const useGetProposalApprovalRules = (): UseQueryResult<ApprovalRule[]> =>
  useQuery({
    queryKey: [CREATE_PROPOSAL_APPROVAL_RULES_PATH],
    queryFn: async () => {
      const { data } = await apiClient.getAllProposalApprovalRules();
      return data;
    },
  });

export const useGetMyApproverRequests = (): UseQueryResult<
  ApprovalRequestSummary[]
> =>
  useQuery({
    queryKey: [GET_MY_APPROVER_REQUESTS_KEY],
    queryFn: async () => {
      const { data } = await apiClient.getMyApproverRequests();
      return data;
    },
  });

export const useGetApprovalGroupsByUser = ({
  enabled,
}: {
  enabled?: boolean;
}): UseQueryResult<ApprovalGroup[]> => {
  const effectiveUserId = 'me';

  return useQuery({
    queryKey: [getUserApprovalGroupsQueryKey(effectiveUserId)],
    queryFn: async () => {
      const { data } = await apiClient.getApprovalGroupsByUser(effectiveUserId);
      return data;
    },
    enabled,
  });
};

const createProposalApprovalRule = async (
  params: CreateProposalApprovalRuleRequest
) => {
  const { data } = await apiClient.createProposalApprovalRule(params);
  return data;
};

export const useCreateProposalApprovalRule = (
  onSuccess: (data: CreateProposalApprovalRuleRequest) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<ApprovalRule, unknown, CreateProposalApprovalRuleRequest>({
    mutationKey: [CREATE_PROPOSAL_APPROVAL_RULES_PATH],
    mutationFn: createProposalApprovalRule,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [GET_ALL_PROPOSAL_APPROVAL_RULES_KEY],
      });

      await qc.invalidateQueries({
        queryKey: [CREATE_PROPOSAL_APPROVAL_RULES_PATH],
      });
    },
  });

const updateProposalApprovalRule = async (
  id: string | undefined,
  params: UpdateProposalApprovalRuleRequest
): Promise<ApprovalRule> => {
  const { data } = await apiClient.updateProposalApprovalRule(id!, params);
  return data;
};

export const useUpdateProposalApprovalRule = (
  onSuccess: (data: ApprovalRule) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<ApprovalRule, unknown, UpdateProposalApprovalRuleRequest>({
    mutationKey: [GET_SPEC_PROPOSAL_APPROVAL_RULE_PATH],
    mutationFn: (data: ApprovalRule) =>
      updateProposalApprovalRule(data.id, data),
    onSuccess: (data) => {
      onSuccess(data);
      qc.setQueryData([getProposalApprovalRuleQueryKey(data.id!)], data);
    },
    onError,
    onSettled: async (data) => {
      await qc.invalidateQueries({
        queryKey: [GET_ALL_PROPOSAL_APPROVAL_RULES_KEY],
      });
      // this matches by list prefix.
      if (data) {
        await qc.invalidateQueries({
          queryKey: [getProposalApprovalRuleQueryKey(data.id!)],
        });
      }

      await qc.invalidateQueries({
        queryKey: [CREATE_PROPOSAL_APPROVAL_RULES_PATH],
      });
    },
  });

const deleteProposalApprovalRule = async (id: string) => {
  const { data } = await apiClient.deleteProposalApprovalRule(id);
  return data;
};

export const useDeleteProposalApprovalRule = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [DELETE_PROPOSAL_APPROVAL_RULE_KEY],
    mutationFn: deleteProposalApprovalRule,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [GET_ALL_PROPOSAL_APPROVAL_RULES_KEY],
      });
    },
  });
