import React from 'react';
import Bold from '@tiptap/extension-bold';
import EditorButton from '../EditorButton';
import { EditorFeature } from '../editorCommon';
import { EditorIconBold } from '../editorIcons';

const boldFeature: EditorFeature = {
  extensions: [Bold],
  contextButtons: (editor) => (
    <EditorButton
      isSelected={editor.isActive('bold')}
      label="Bold"
      onClick={() => editor.chain().focus().toggleBold().run()}
      icon={EditorIconBold}
    />
  ),
  key: 'bold',
};

export default boldFeature;
