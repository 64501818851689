import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  ConfigProvider,
  Dropdown,
  MenuProps,
  Space,
  ThemeConfig,
} from 'antd';
import { isToday, isYesterday } from 'date-fns';

import { ApprovalRequestSummary, Icon, LoadingSpinner } from 'common';

import { useGetMyApproverRequests } from 'app/src/services/approvals';

import { isOlder, sortByUpdatedAt } from './utils';
import { ApprovalItem } from './components/ApprovalItem/ApprovalItem';
import { EmptyTaskInbox } from './components/EmptyTaskInbox/EmptyTaskInbox';

import styles from './TaskInbox.module.scss';

export const TaskInbox = () => {
  const navigate = useNavigate();

  const theme: ThemeConfig = {
    components: {
      Dropdown: {
        controlItemBgHover: 'var(--color-primary-lightest)',
      },
    },
  };

  const { data: approvalRequests, isLoading } = useGetMyApproverRequests();

  const todayItems = approvalRequests
    ?.filter((item: ApprovalRequestSummary) => isToday(item.updatedAt!))
    .sort(sortByUpdatedAt);

  const yesterdayItems = approvalRequests
    ?.filter((item: ApprovalRequestSummary) => isYesterday(item.updatedAt!))
    .sort(sortByUpdatedAt);

  const restItemsByUpdatedAt = approvalRequests
    ?.filter((item: ApprovalRequestSummary) => isOlder(item.updatedAt!))
    .sort(sortByUpdatedAt);

  const items: MenuProps['items'] = [
    ...(todayItems && todayItems.length > 0
      ? [
          {
            key: 'today-header',
            label: <div className={styles.dateHeader}>Today</div>,
            disabled: true,
          },
          ...todayItems.map((item) => ({
            key: item.id,
            label: <ApprovalItem item={item} />,
            onClick: () => {
              navigate(`/proposals/${item.proposal?.id}`);
            },
          })),
        ]
      : []),

    ...(yesterdayItems && yesterdayItems.length > 0
      ? [
          {
            key: 'yesterday-header',
            label: <div className={styles.dateHeader}>Yesterday</div>,
            disabled: true,
          },
          ...yesterdayItems.map((item) => ({
            key: item.id,
            label: <ApprovalItem item={item} />,
          })),
        ]
      : []),

    ...(restItemsByUpdatedAt && restItemsByUpdatedAt.length > 0
      ? [
          {
            key: 'rest-header',
            label: <div className={styles.dateHeader}>Older</div>,
            disabled: true,
          },
          ...restItemsByUpdatedAt.map((item) => ({
            key: item.id,
            label: <ApprovalItem item={item} />,
          })),
        ]
      : []),
  ];

  const totalItems =
    (todayItems?.length ?? 0) +
    (yesterdayItems?.length ?? 0) +
    (restItemsByUpdatedAt?.length ?? 0);

  if (isLoading) {
    return <LoadingSpinner size={24} />;
  }

  return (
    <ConfigProvider theme={theme}>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        dropdownRender={(menu) => (
          <div className={styles.container}>
            <div className={styles.header}>
              <Space>
                <span className={styles.headerText}>My approval requests</span>
              </Space>
            </div>
            <div className={styles.tasksContainer}>
              {totalItems === 0 ? <EmptyTaskInbox /> : menu}
            </div>
          </div>
        )}
      >
        <a onClick={(e) => e.preventDefault()} className={styles.badgeWrapper}>
          <Badge dot={!!totalItems}>
            <Icon.NotificationOn height={32} width={32} />
          </Badge>
        </a>
      </Dropdown>
    </ConfigProvider>
  );
};
