import {
  capitalizeFirstLetter,
  Contact,
  Customer,
  ExternalSource,
  isArrayNonEmpty,
  Product,
  Proposal,
  SelectOption,
} from 'common';

/**
 * Note: the Java interface ConnectableEntity is an impl
 * detail and not part of the public API shape.
 */

type MultipleSourceEntity = Contact | Customer | Product;
export type ConnectableEntity = MultipleSourceEntity | Proposal;

const getExternalSource = (
  entity: ConnectableEntity
): ExternalSource | undefined => {
  if (
    Object.hasOwn(entity, 'externalId') &&
    Object.hasOwn(entity, 'externalLink') &&
    Object.hasOwn(entity, 'source')
  ) {
    const proposal = entity as Proposal;
    if (proposal.externalId && proposal.externalLink && proposal.source) {
      return {
        sourceId: proposal.externalId,
        sourceLink: proposal.externalLink,
        sourceType: proposal.source,
      };
    }
  } else if (Object.hasOwn(entity, 'sources')) {
    const e = entity as MultipleSourceEntity;
    if (isArrayNonEmpty(e.sources)) {
      return e.sources[0];
    }
  }
  return undefined;
};

const CRM_SOURCES = new Set(['close', 'hubspot', 'salesforce', 'quickbooks']);
export const getCRMExternalSource = (
  entity: ConnectableEntity
): ExternalSource | undefined => {
  const source = getExternalSource(entity);
  if (source && CRM_SOURCES.has(source.sourceType)) {
    return source;
  }
  return undefined;
};

export type AdvanceDateOptions = 'advance_date' | 'billing_period_start_date';
export const INVOICE_ADVANCE_DATE_OPTIONS: SelectOption<AdvanceDateOptions>[] =
  [
    {
      value: 'advance_date',
      name: 'Invoice generation date (Advance date)',
    },
    {
      value: 'billing_period_start_date',
      name: 'Schedule date (Billing period start date)',
    },
  ];

export type CrmSource = 'close' | 'hubspot' | 'quickbooks' | 'salesforce';
export const crmNameFromSource = (source: CrmSource) => {
  if (source === 'hubspot') {
    return 'HubSpot';
  }
  return capitalizeFirstLetter(source);
};
