import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  ContractSummary,
  Customer,
  CustomerRequest,
  LocationRest,
  ProposalSummary,
} from 'common';
import { apiClient } from './httpClients/app';

export const ALL_CUSTOMERS_KEY = 'customers';
const CREATE_CUSTOMER_KEY = 'createCustomer';

export const useCustomers = (search?: string): UseQueryResult<Customer[]> =>
  useQuery({
    queryKey: [ALL_CUSTOMERS_KEY],
    queryFn: async () => {
      const { data } = await apiClient.listCustomers({
        search: search ? `name:*${search}*` : undefined,
      });
      return data;
    },
  });

const submitCustomer = async (params: CustomerRequest) => {
  const { data } = await apiClient.createCustomer(params);
  return data;
};

export const useCreateCustomer = (
  onSuccess: (data: Customer) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [CREATE_CUSTOMER_KEY],
    mutationFn: (data: CustomerRequest) => submitCustomer(data),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_CUSTOMERS_KEY] });
    },
  });

const updateCustomer = async (
  customerId: string,
  customerRequest: CustomerRequest
): Promise<Customer> => {
  const { data } = await apiClient.patchCustomer(customerId, customerRequest);
  return data;
};

export const useUpdateCustomerAPI = (
  onSuccess: (data: Customer) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['UpdateCustomer'],
    mutationFn: (data: Customer) => {
      const updateCustomerBody = {
        ...data,
      } as CustomerRequest;

      return updateCustomer(data.id, updateCustomerBody);
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_CUSTOMERS_KEY] });
    },
  });

const deleteCustomer = async (customerId: string) => {
  const { data } = await apiClient.deleteCustomer(customerId);
  return data;
};

export const useDeleteCustomer = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['DeleteCustomer'],
    mutationFn: deleteCustomer,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_CUSTOMERS_KEY] });
    },
  });

export const useCustomer = (id: string): UseQueryResult<Customer> =>
  useQuery({
    queryKey: [ALL_CUSTOMERS_KEY + '/' + id],
    queryFn: async () => {
      const { data } = await apiClient.getCustomer(id);
      return data;
    },
  });

export const getCustomerByExternalId = async (externalId: string) => {
  const { data } = await apiClient.getCustomerByExternalId(externalId);
  return data;
};

export const useCustomerSubscriptions = (
  customerId: string
): UseQueryResult<ContractSummary[]> =>
  useQuery({
    queryKey: [ALL_CUSTOMERS_KEY + '/' + customerId + '/subscriptions'],
    queryFn: async () => {
      const { data } = await apiClient.getCustomerContracts(customerId);
      return data;
    },
  });

export const useCustomerProposals = (
  customerId: string
): UseQueryResult<ProposalSummary[]> =>
  useQuery({
    queryKey: [ALL_CUSTOMERS_KEY + '/' + customerId + '/proposals'],
    queryFn: async () => {
      const { data } = await apiClient.getCustomerProposals(customerId);
      return data;
    },
  });

export const getContractsForCustomer = async (customerId: string) => {
  const { data } = await apiClient.getCustomerContracts(customerId);
  return data;
};

export const useCountries = (): UseQueryResult<LocationRest[]> =>
  useQuery({
    queryKey: ['countries'],
    queryFn: async () => {
      const { data } = await apiClient.getAllCountries();
      return data;
    },
  });

export const useRegion = (iso: string): UseQueryResult<LocationRest[]> =>
  useQuery({
    queryKey: ['regions', iso],
    queryFn: async () => {
      const { data } = await apiClient.getAllRegionsOfCountry(iso);
      return data;
    },
    enabled: !!iso,
  });
