import axios from 'axios';
import { getErrorCode, getErrorData, getErrorMessage, isObject } from 'common';

export const slackUserAlreadyUsedMsg =
  'This Slack user has already been linked to Cacheflow.';

export function getUserFacingMessage(error: unknown): string | undefined {
  if (axios.isAxiosError(error)) {
    const errorData = getErrorData(error);
    const errorCode = getErrorCode(error);

    if (errorData && errorCode === 'FIELD_TOO_LONG') {
      const field: string | null = isObject(errorData.detail)
        ? (errorData.detail.field as string)
        : null;

      if (field) {
        return `Value for field "${field}" is too long`;
      }
    }

    const errorMessage = getErrorMessage(error);
    if (errorMessage) {
      const incompatibleTermPayEveryMessage =
        'Term is not compatible with payment schedule';

      if (errorMessage.startsWith(incompatibleTermPayEveryMessage)) {
        return incompatibleTermPayEveryMessage;
      }

      if (
        errorMessage.startsWith('Slack user') &&
        errorMessage.includes('already linked to org')
      ) {
        return slackUserAlreadyUsedMsg;
      }

      return errorMessage;
    }
  }
  return undefined;
}
