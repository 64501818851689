import React from 'react';
import clsx from 'clsx';
import ErrorBoundary from '../../wrappers/ErrorBoundary';

interface Props {
  hasWhiteBackground?: boolean;
  leftNav?: React.ReactElement;
  mainContent?: React.ReactElement;
}

const BaseTemplateComponent: React.FC<Props> = ({
  leftNav,
  mainContent,
  hasWhiteBackground = false,
}: Props) => {
  return (
    <div className="flex flex-1">
      {leftNav}
      <main
        className={clsx('right-content flex flex-col flex-1', {
          'white-background': hasWhiteBackground,
        })}
      >
        <ErrorBoundary>
          <div className={clsx('w-full main-content')}>{mainContent}</div>
        </ErrorBoundary>
      </main>
    </div>
  );
};

export default BaseTemplateComponent;
