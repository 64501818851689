import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { ApiToken } from 'common';

const ALL_API_TOKEN_KEY = 'apiToken';
const CREATE_API_TOKEN_KEY = 'createAPIToken';
const DELETE_API_TOKEN_KEY = 'deleteAPIToken';

export const useAPIToken = (): UseQueryResult<ApiToken[]> =>
  useQuery({
    queryKey: [ALL_API_TOKEN_KEY],
    queryFn: async () => {
      const { data } = await apiClient.getAllTokens();
      return data;
    },
  });

const submitAPIToken = async (params: ApiToken): Promise<ApiToken> => {
  const { data } = await apiClient.addToken(params);
  return data;
};

export const useCreateAPIToken = (
  onSuccess: (data: ApiToken) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [CREATE_API_TOKEN_KEY],
    mutationFn: submitAPIToken,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_API_TOKEN_KEY] });
    },
  });

const deleteAPIToken = async (id: string): Promise<ApiToken> => {
  const { data } = await apiClient.removeToken(id);
  return data;
};

export const useDeleteAPIToken = (
  onSuccess: (data: ApiToken) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [DELETE_API_TOKEN_KEY],
    mutationFn: deleteAPIToken,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_API_TOKEN_KEY] });
    },
  });
