import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  ApprovalRule,
  Button,
  CreateProposalApprovalRuleRequest,
  getErrorMessage,
  Loading,
  ProposalSummary,
  Type,
  useToast,
  useTranslation,
} from 'common';

import {
  useCreateProposalApprovalRule,
  useDeleteProposalApprovalRule,
  useGetProposalApprovalRules,
  useUpdateProposalApprovalRule,
} from 'app/src/services/approvals';
import {
  ApprovalGroupWithUsers,
  useGetApprovalGroupsWithUsers,
} from 'app/src/services/groups';
import { useGetAllApprovalGroups } from 'app/src/services/api/approvalGroups/approvalGroups';

import { RuleSelector } from '../rule-builders/RuleSelector';
import { getRuleInfo, GroupOption } from './_approvalRulesConfig';
import { ApprovalTabsWrapper } from './ApprovalTabsWrapper';
import { ApprovalRuleMoneyRow } from './ApprovalRuleMoneyRow';
import { ApprovalRulePercentRow } from './ApprovalRulePercentageRow';
import { ApprovalRuleTextRow } from './ApprovalRuleTextRow';
import { ApprovalRuleBooleanRow } from './ApprovalRuleBooleanRow';
import { ApprovalRuleNumberRow } from './ApprovalRuleNumberRow';
import { ApprovalRuleMultiselectRow } from './ApprovalRuleMultiselectRow';
import { ProductRuleSelector } from '../rule-builders/ProductRuleSelector';
import { useMultiSelectRuleData } from './useMultiSelectRuleData';

import { ProductRuleDiscountRow } from './ProductRuleDiscountRow';
import { ProductRuleDiscountRangeRow } from './ProductRuleDiscountRangeRow';
import { ProductRuleQuantityRow } from './ProductRuleQuantityRow';

import styles from './ApprovalRules.module.scss';

const getRule = (id: string, rules: ApprovalRule[] | undefined) => {
  return rules?.find((r) => r.id === id);
};

export const ApprovalRules = () => {
  const showToast = useToast();
  const { tk } = useTranslation();
  const queryClient = useQueryClient();

  const { multiSelectRuleOptions } = useMultiSelectRuleData();

  const [proposalType, setProposalType] =
    useState<ProposalSummary['proposalType']>('initial');

  const [draftProposalApprovalRules, setDraftProposalApprovalRules] = useState<
    ApprovalRule[] | undefined
  >(undefined);

  const [isAddingProposalCondition, setIsAddingProposalCondition] =
    useState(false);
  const [isAddingProductCondition, setIsAddingProductCondition] =
    useState(false);

  const {
    data: proposalApprovalRules,
    isLoading: isProposalApprovalRulesLoading,
  } = useGetProposalApprovalRules();

  const { data: approvalGroups, isLoading: isApprovalGroupsLoading } =
    useGetAllApprovalGroups();

  const { data: groupsWithUsers, isLoading: isGroupsWithUsersLoading } =
    useGetApprovalGroupsWithUsers(approvalGroups);

  useEffect(() => {
    setDraftProposalApprovalRules(proposalApprovalRules);
  }, [proposalApprovalRules]);

  const onError = (error: unknown) => {
    showToast.error(tk(getErrorMessage(error) || 'Please try again.'));
  };

  const { mutate: createProposalApprovalRule, isPending } =
    useCreateProposalApprovalRule(
      (createdRule: ApprovalRule) => {
        setDraftProposalApprovalRules([
          ...draftProposalApprovalRules!,
          createdRule,
        ]);
        showToast.info(tk('Condition created'));
      },
      onError,
      queryClient
    );

  const { mutate: updateProposalApprovalRule } = useUpdateProposalApprovalRule(
    (updatedRule: ApprovalRule) => {
      const updatedRules = draftProposalApprovalRules!.map((r) =>
        r.id === updatedRule.id ? updatedRule : r
      );

      setDraftProposalApprovalRules(updatedRules);
      showToast.info(tk('Condition saved'));
    },
    onError,
    queryClient
  );

  const { mutate: deleteProposalApprovalRule } = useDeleteProposalApprovalRule(
    () => showToast.info(tk('Condition removed')),
    onError,
    queryClient
  );

  const onRuleChange = (
    id: string | undefined,
    updatedRule: ApprovalRule | null
  ) => {
    const rule = id ? getRule(id, draftProposalApprovalRules) : undefined;

    // Create rule
    if (!rule && updatedRule !== null) {
      createProposalApprovalRule(updatedRule);
    }
    // Update or delete rule
    else if (rule) {
      if (updatedRule !== null) {
        updateProposalApprovalRule(updatedRule);
      } else {
        setDraftProposalApprovalRules(
          draftProposalApprovalRules!.filter((r) => r.id !== id)
        );
        deleteProposalApprovalRule(rule.id!);
      }
    } else {
      showToast.error(tk('Invalid rule.'));
    }
  };

  const handleAddingAgreementCondition = (
    newRuleRequest: CreateProposalApprovalRuleRequest
  ) => {
    createProposalApprovalRule(newRuleRequest);
    setIsAddingProposalCondition(false);
    setIsAddingProductCondition(false);
  };

  if (
    !draftProposalApprovalRules ||
    isProposalApprovalRulesLoading ||
    isApprovalGroupsLoading ||
    isGroupsWithUsersLoading
  ) {
    return <Loading />;
  }

  const allGroupOptions: GroupOption[] =
    approvalGroups?.map((approvalGroup) => {
      const isDisabled = !groupsWithUsers?.some(
        (groupWithUsers: ApprovalGroupWithUsers) => {
          return (
            groupWithUsers.group.id === approvalGroup.group?.id &&
            groupWithUsers.users.length > 0
          );
        }
      );

      const label = isDisabled
        ? `${approvalGroup.group?.name} (Group requires users)`
        : approvalGroup.group?.name;

      return {
        value: approvalGroup.id || '',
        label,
        disabled: isDisabled,
      };
    }) || [];

  const renderApprovalRules = (draftRules: ApprovalRule[] | undefined) => {
    const sortedRules = draftRules?.sort((a, b) => {
      if (a.createdAt && b.createdAt) {
        return a.createdAt.localeCompare(b.createdAt);
      }

      return 0;
    });

    return sortedRules?.map((rule) => {
      const ruleInfo = getRuleInfo(rule.config?.ruleType!);

      const commonProps = {
        ...ruleInfo,
        approvalRule: rule,
        onRuleChange,
        proposalType,
        allGroupOptions,
      };

      const key = rule.id;

      switch (rule.config?.ruleType) {
        case 'proposalAmount':
          return <ApprovalRuleMoneyRow key={key} {...commonProps} />;

        case 'proposalDiscountPercent':
          return <ApprovalRulePercentRow key={key} {...commonProps} />;

        case 'proposalCountry':
          return <ApprovalRuleTextRow key={key} {...commonProps} />;

        case 'proposalCustomTerms':
        case 'proposalAutoRenewal':
        case 'proposalDocusign':
        case 'proposalExternalCrm':
        case 'proposalAttachments':
        case 'proposalAmountDecrease':
        case 'proposalDuplicateProducts':
          return <ApprovalRuleBooleanRow key={key} {...commonProps} />;

        case 'proposalFreeMonths':
        case 'proposalTermLength':
          return <ApprovalRuleNumberRow key={key} {...commonProps} />;

        case 'proposalPaymentTerms':
        case 'proposalBillingPeriods':
          return (
            <ApprovalRuleMultiselectRow
              key={key}
              {...commonProps}
              multiSelectRuleOptions={multiSelectRuleOptions}
            />
          );

        default:
          return null;
      }
    });
  };

  const renderProductRules = (draftRules: ApprovalRule[] | undefined) => {
    const sortedRules = draftRules?.sort((a, b) => {
      if (a.createdAt && b.createdAt) {
        return a.createdAt.localeCompare(b.createdAt);
      }

      return 0;
    });

    return sortedRules?.map((rule) => {
      const ruleInfo = getRuleInfo(rule.config?.ruleType!);

      const commonProps = {
        ...ruleInfo,
        approvalRule: rule,
        onRuleChange,
        proposalType,
        allGroupOptions,
      };

      const key = rule.id;

      switch (rule.config?.ruleType) {
        case 'proposalItemQuantity':
          return <ProductRuleQuantityRow key={key} {...commonProps} />;

        case 'proposalProductDiscountPercent':
          if ('maxDiscount' in rule.config) {
            return <ProductRuleDiscountRangeRow key={key} {...commonProps} />;
          }

          return <ProductRuleDiscountRow key={key} {...commonProps} />;

        default:
          return null;
      }
    });
  };

  const initialRules = draftProposalApprovalRules.filter(
    (rule) => rule.config?.proposalType === 'initial' && rule.enabled
  );

  const changeRules = draftProposalApprovalRules.filter(
    (rule) => rule.config?.proposalType === 'change' && rule.enabled
  );

  const renewalRules = draftProposalApprovalRules.filter(
    (rule) => rule.config?.proposalType === 'renewal' && rule.enabled
  );

  return (
    <ApprovalTabsWrapper
      onTabChange={setProposalType}
      selectedProposalType={proposalType}
    >
      <div className={styles.ruleSection}>
        <Type header={4}>Proposal conditions</Type>

        <div className={styles.approvalRules}>
          {proposalType === 'initial' && renderApprovalRules(initialRules)}

          {proposalType === 'change' && renderApprovalRules(changeRules)}

          {proposalType === 'renewal' && renderApprovalRules(renewalRules)}

          {!isAddingProposalCondition && (
            <Button
              className={styles.addRuleButton}
              type="secondary"
              onClick={() => setIsAddingProposalCondition(true)}
              label="+ Proposal condition"
              dataTestId="add-proposal-condition"
            />
          )}
          {isAddingProposalCondition && (
            <RuleSelector
              proposalType={proposalType}
              onRuleAdded={handleAddingAgreementCondition}
              onCancelAdd={() => setIsAddingProposalCondition(false)}
              isPending={isPending}
              allGroupOptions={allGroupOptions}
            />
          )}
        </div>
      </div>

      <div className={styles.ruleSection}>
        <Type header={4}>Product conditions</Type>

        <div className={styles.approvalRules}>
          {proposalType === 'initial' && renderProductRules(initialRules)}

          {proposalType === 'change' && renderProductRules(changeRules)}

          {proposalType === 'renewal' && renderProductRules(renewalRules)}

          {!isAddingProductCondition && (
            <Button
              className={styles.addRuleButton}
              type="secondary"
              onClick={() => setIsAddingProductCondition(true)}
              label="+ Product condition"
              dataTestId="add-product-condition"
            />
          )}
          {isAddingProductCondition && (
            <ProductRuleSelector
              proposalType={proposalType}
              onRuleAdded={handleAddingAgreementCondition}
              onCancelAdd={() => setIsAddingProductCondition(false)}
              isPending={isPending}
              allGroupOptions={allGroupOptions}
            />
          )}
        </div>
      </div>
    </ApprovalTabsWrapper>
  );
};
