import { useEffect, useState } from 'react';

export const useCopyClipboard = () => {
  const [toolTipOpen, setToolTipOpen] = useState<string>();

  const setToolTipClose = () => {
    setToolTipOpen('');
  };

  useEffect(() => {
    if (toolTipOpen) {
      setTimeout(() => {
        setToolTipClose();
      }, 3000);
    }
  }, [toolTipOpen]);

  const handleCopy = async (token: string) => {
    setToolTipOpen(token);
    await navigator.clipboard.writeText(token);
  };

  return {
    toolTipOpen,
    setToolTipClose,
    handleCopy,
  };
};
