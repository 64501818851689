import { Icon, OrgConfig, ToggleSwitch } from 'common';
import React from 'react';
import { AccountContactsConfig } from '../ConnectorConstants/constants';

interface Props {
  accountContactsConfig: AccountContactsConfig;
  connectorConfigValue: OrgConfig['configValue'];
  setConnectorConfigValue: React.Dispatch<
    React.SetStateAction<OrgConfig['configValue']>
  >;
  setSavableChanges: (arg: boolean) => void;
}

const AccountContacts = ({
  accountContactsConfig,
  setConnectorConfigValue,
  setSavableChanges,
  connectorConfigValue,
}: Props) => {
  const {
    integration,
    description,
    cacheflowToIntegrationSection,
    integrationToCacheflowSection,
  } = accountContactsConfig;

  const configType: any = integration.toLowerCase() + 'Config';

  return (
    <div className="connector-settings-tab connector-account-contacts">
      <p className="mt-2">{description}</p>
      {cacheflowToIntegrationSection.length > 0 && (
        <section>
          <h3>
            Cacheflow <Icon.ArrowRight /> {integration}
          </h3>
          {cacheflowToIntegrationSection.map(
            (cfToIntVal: { left: string; right: string }) => (
              <span className="flex gap-2" key={cfToIntVal.left}>
                <Icon.Checkmark color="var(--green)" />
                <p className="check-label">
                  {cfToIntVal.left} <Icon.ArrowRight />
                  {cfToIntVal.right}
                </p>
              </span>
            )
          )}
        </section>
      )}
      {(integration === 'Salesforce' || integration === 'HubSpot') && (
        <section>
          <div className="mb-6 font-medium flex items-center justify-between mt-8">
            <h3>
              Sync updates to Cacheflow customers and contacts from{' '}
              {integration}
            </h3>
            <div className="flex gap-4">
              <h3>Enable</h3>
              <ToggleSwitch
                value={
                  connectorConfigValue &&
                  'syncSourceToCacheflow' in connectorConfigValue &&
                  !!connectorConfigValue.syncSourceToCacheflow
                }
                onChange={(event) => {
                  setConnectorConfigValue((prev) => ({
                    ...prev,
                    syncSourceToCacheflow: event,
                    configType,
                  }));
                  setSavableChanges(true);
                }}
              />
            </div>
          </div>
          <div className="w-1/2 flex flex-col">
            <p>
              If disabled, Cacheflow will sync customers & contacts only during
              proposal creation from{' '}
              {integration === 'Salesforce' ? 'opportunity' : 'deal'} and no
              further updates from {integration} will be synced.
            </p>
          </div>
        </section>
      )}
      {integrationToCacheflowSection.length > 0 && (
        <section>
          <h3>
            {integration} <Icon.ArrowRight /> Cacheflow
          </h3>
          {integrationToCacheflowSection.map(
            (cfToIntVal: { left: string; right: string }) => (
              <span className="flex gap-2" key={cfToIntVal.left}>
                <Icon.Checkmark color="var(--green)" />
                <p className="check-label">
                  {cfToIntVal.left} <Icon.ArrowRight />
                  {cfToIntVal.right}
                </p>
              </span>
            )
          )}
        </section>
      )}
    </div>
  );
};

export default AccountContacts;
