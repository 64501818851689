import React, { useState } from 'react';
import { useGetAttachmentConfigs } from './hook';
import {
  AttachmentConfig,
  capitalizeFirstLetter,
  ConfirmDeleteIdModal,
  formatDateOrDefault,
  getErrorMessage,
  Icon,
  SimpleCell,
  SimpleTable,
  useToast,
} from 'common';
import { useDeleteAttachmentConfig } from '../../../services/api/attachments/attachments';
import { useQueryClient } from '@tanstack/react-query';
import { prepareExternalLink } from '../../../core-utils/helperFunctions/link';
import './styles.css';

interface Props {
  onClickAttachment: (attachment: AttachmentConfig) => void;
}

const AttachmentsTable: React.FC<Props> = ({ onClickAttachment }: Props) => {
  const { responseData: attachments, isLoading } = useGetAttachmentConfigs();
  const queryClient = useQueryClient();
  const [attachmentConfigId, setAttachmentConfigId] = useState<
    string | undefined
  >(undefined);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const showToast = useToast();

  const { mutate: deleteAttachment } = useDeleteAttachmentConfig(
    () => {
      setAttachmentConfigId(undefined);
      showToast.info('Attachment has been removed.');
    },
    (error: unknown) => {
      const errorMessage =
        getErrorMessage(error) ||
        'There was a problem removing this attachment, please try again.';
      showToast.error(capitalizeFirstLetter(errorMessage), 5);
    },
    queryClient
  );

  const handleDelete = (att: AttachmentConfig) => {
    setAttachmentConfigId(att.id);
    setShowCancelConfirm(true);
  };

  const processDeletion = (id: string) => {
    setShowCancelConfirm(false);
    setAttachmentConfigId(undefined);
    deleteAttachment(id);
  };

  const handleClose = () => {
    setAttachmentConfigId(undefined);
    setShowCancelConfirm(false);
  };

  const cells: SimpleCell<AttachmentConfig>[] = [
    {
      key: 'icon',
      headerLabel: '',
      width: '60px',
      renderCell: (data: AttachmentConfig) => {
        if (data.type === 'document') {
          const fileExtension =
            data.document?.fileName?.match(/\.[0-9a-z]+$/i)?.[0];
          return (
            <div className="flex items-center justify-center">
              <div className="file-type-tag">
                {fileExtension?.substring(1) || 'file'}
              </div>
            </div>
          );
        } else
          return (
            <div className="flex items-center justify-center">
              <Icon.Link />
            </div>
          );
      },
    },
    {
      key: 'name',
      headerLabel: ['Name', 'Added'],
      searchable: true,
      renderCell: (data: AttachmentConfig) => (
        <div>
          <div className="attachment-name">{data.name}</div>
          <div className="attachment-date">
            {formatDateOrDefault(data.createdAt)}
          </div>
        </div>
      ),
    },
    {
      key: 'signing',
      headerLabel: 'Add to signing',
      align: 'center',
      renderCell: (data: AttachmentConfig) => (
        <div className="flex items-center justify-center text-green">
          {data.inEnvelope ? <Icon.Checkmark /> : ''}
        </div>
      ),
    },
    {
      key: 'autoAddToEnvelope',
      headerLabel: 'Auto add to new proposals',
      align: 'center',
      renderCell: (data: AttachmentConfig) => (
        <div className="flex items-center justify-center text-green">
          {data.autoAdd ? <Icon.Checkmark /> : ''}
        </div>
      ),
    },
    {
      key: 'preview',
      width: '70px',
      headerLabel: 'Preview',
      renderCell: (data: AttachmentConfig) => {
        if (data.type === 'document') {
          return (
            <a
              href={data.document?.latestVersion?.url}
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center cursor-pointer text-slate-lighter hover:text-blue"
            >
              <Icon.Preview name="Preview" />
            </a>
          );
        }
        return (
          <div>
            <a
              href={prepareExternalLink(data.linkUrl)}
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center cursor-pointer text-slate-lighter hover:text-blue"
            >
              <Icon.Preview name="Preview" />
            </a>
          </div>
        );
      },
    },
    {
      key: 'delete',
      width: '70px',
      headerLabel: 'Remove',
      renderCell: (data: AttachmentConfig) => {
        return (
          <button
            type="button"
            className="flex items-center justify-center w-full text-slate-lighter hover:text-red"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(data);
            }}
          >
            <Icon.Close name="Delete" />
          </button>
        );
      },
    },
  ];

  return (
    <>
      <SimpleTable
        cells={cells}
        rows={attachments}
        onRowClick={(row: AttachmentConfig) => onClickAttachment(row)}
        isLoading={isLoading}
      />
      <ConfirmDeleteIdModal
        id={attachmentConfigId}
        isOpen={showCancelConfirm}
        onClose={handleClose}
        onConfirm={processDeletion}
        typeName="attachment"
      />
    </>
  );
};

export default AttachmentsTable;
