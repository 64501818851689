import React from 'react';
import BankAccountsCard from './BankAccountsCard';
import styles from './BankAccountDetailCard.module.scss';

interface Props {
  accountNumber: string | undefined;
  accountType: string | undefined;
  bankName: string | undefined;
}

const BankAccountDetailCard = ({
  accountNumber,
  bankName,
  accountType,
}: Props) => {
  return (
    <BankAccountsCard className={styles.bankAccountDetailCard}>
      <div className={styles.header}>
        <div>{bankName}</div>
      </div>

      <div className={styles.details}>
        <p>'xxxxxxxxxxxx' {accountNumber || 'xxxx'}</p>
        <p>{accountType}</p>
      </div>
    </BankAccountsCard>
  );
};

export default BankAccountDetailCard;
