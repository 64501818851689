import React from 'react';
import { useCountries, useRegion } from 'app/src/services/customer';
import {
  Address,
  AddressDisplay,
  AddressForm,
  Checkbox,
  Contact,
  FormField,
  getCountry,
  getPersonFullName,
  SearchSelect,
  useTranslation,
} from 'common';

interface Props {
  address?: Address;
  billingContact?: Contact;
  contacts: Contact[];
  hasShippingAddressBeenSet: boolean;
  isBillingAddressSameAsShipping: boolean;
  onAddressChange: (value: Address) => void;
  onChangeBillingContact: (newContact?: Contact) => void;
  onToggleBillingSameAsShipping: (isTheSame: boolean) => void;
  subscriptionFlowBillingErrors?: (
    fieldName: number | string | symbol
  ) => string | undefined;
  isSubscriptionBillingContactValid?: boolean;
}

const CustomerBillingDetails: React.FC<Props> = ({
  contacts,
  address,
  onAddressChange,
  isBillingAddressSameAsShipping,
  onToggleBillingSameAsShipping,
  hasShippingAddressBeenSet,
  billingContact,
  onChangeBillingContact,
  subscriptionFlowBillingErrors,
  isSubscriptionBillingContactValid = true,
}) => {
  const { tk } = useTranslation();

  const { data: countries = [] } = useCountries();
  const { data: regions = [] } = useRegion(getCountry(address, countries));

  return (
    <div className="flex flex-col gap-4">
      <Checkbox
        name="isBillingAddressSameAsShipping"
        value={isBillingAddressSameAsShipping && hasShippingAddressBeenSet}
        onChange={onToggleBillingSameAsShipping}
        isDisabled={!hasShippingAddressBeenSet}
        label={tk('Same as ship to')}
      />
      {isBillingAddressSameAsShipping && hasShippingAddressBeenSet ? (
        <AddressDisplay address={address} isGreyedOut />
      ) : (
        <div>
          <AddressForm
            addressData={address}
            onChange={onAddressChange}
            countries={countries}
            regions={regions}
            {...(subscriptionFlowBillingErrors
              ? { getErrorToShow: subscriptionFlowBillingErrors }
              : undefined)}
          />
        </div>
      )}
      <div>
        <FormField
          errorToShow={
            !isSubscriptionBillingContactValid
              ? tk('Please select a billing contact')
              : undefined
          }
          label={tk('Billing contact person')}
        >
          <SearchSelect
            options={contacts}
            displayField={(c) =>
              `${getPersonFullName(c)}${c.email ? ` (${c.email})` : ''}`
            }
            value={billingContact}
            onChange={onChangeBillingContact}
            onClearSelected={() => onChangeBillingContact(null!)}
          />
        </FormField>
      </div>
    </div>
  );
};

export default CustomerBillingDetails;
