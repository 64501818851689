import React, { useEffect, useRef, useState } from 'react';
import {
  ApprovalAction,
  ApprovalRequest,
  Button,
  ButtonBar,
  Drawer,
  FormField,
  getErrorMessage,
  Proposal,
  TextArea,
  useToast,
  useTranslation,
} from 'common';
import { useQueryClient } from '@tanstack/react-query';

import { useCreateProposalApprovalAction } from '../../../../services/proposal';
import VerifyProposal from '../ProposalShare/VerifyProposal';
import { useValidateProposal } from '../proposalUtilities';

interface Props {
  action: ApprovalAction['action'];
  approvalRequest: ApprovalRequest;
  closeDrawer: () => void;
  isShowing: boolean;
  proposal: Proposal;
}

const VERIFY = 'verify';
const ACT_ON_APPROVAL = 'actOnApproval';

const ApprovalCancelDrawer: React.FC<Props> = ({
  proposal,
  approvalRequest,
  action,
  isShowing,
  closeDrawer,
}) => {
  const { tk } = useTranslation();
  const showToast = useToast();
  const queryClient = useQueryClient();
  const [draft, setDraft] = useState<ApprovalAction>({
    message: '',
  });
  const [activeViewKey, setActiveViewKey] = useState(VERIFY);
  const { isValid } = useValidateProposal(proposal);

  const onClose = () => {
    setActiveViewKey(VERIFY);
    closeDrawer();
  };
  const handleCreateSuccess = () => {
    showToast.info('Success');
    setActiveViewKey(VERIFY);
    onClose();
  };

  const handleCreateError = (responseError: unknown) => {
    const responseMsg =
      getErrorMessage(responseError) ||
      'There was an issue making the request. Please try again.';
    showToast.error(responseMsg);
  };

  const { mutate: createAction } = useCreateProposalApprovalAction(
    proposal.id,
    approvalRequest.id!,
    handleCreateSuccess,
    handleCreateError,
    queryClient
  );

  const inputRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (isShowing && activeViewKey === ACT_ON_APPROVAL) {
      setTimeout(() => {
        // TODO: figure out why/if all these focusing calls have to be after timeouts
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [isShowing]);

  const submit = () => {
    createAction({ ...draft, action });
  };

  const messageMap = new Map<ApprovalAction['action'], any>([
    [
      'approve',
      {
        title: 'Approve proposal',
        heading:
          'Use the  input below to explain any changes you may have made.',
        inputLabel: 'Message to proposal owner',
        submitLabel: 'Submit approval',
      },
    ],
    [
      'reject',
      {
        title: 'Reject proposal',
        heading: 'Please explain the reason for your rejection.',
        inputLabel: 'Message to proposal owner',
        submitLabel: 'Submit rejection',
      },
    ],
    [
      'cancel',
      {
        title: 'Cancel approval request',
        heading: 'Please explain the reason for canceling.',
        inputLabel: 'Message to approvers',
        submitLabel: 'Cancel approval request',
      },
    ],
  ]);
  const msg = messageMap.get(action);

  if (!action) {
    return null;
  }

  if (!isValid) {
    return (
      <VerifyProposal
        isOpen={isShowing}
        onClose={onClose}
        proposal={proposal}
      />
    );
  }

  return (
    <Drawer
      footer={
        <ButtonBar>
          <Button
            block
            dataTestId="submit-approval-action"
            label={tk(msg.submitLabel)}
            onClick={submit}
          />
          <Button block label={tk('Cancel')} onClick={onClose} type="link" />
        </ButtonBar>
      }
      header={tk(msg.title)}
      isOpen={isShowing}
      onClose={onClose}
    >
      <div className="pb-4">{tk(msg.heading)}</div>
      <div className="pb-4">
        <FormField label={tk(msg.inputLabel)}>
          <TextArea
            className="p-2 !h-32"
            dataTestId="approval-action-message"
            onChange={(value) =>
              setDraft({
                ...draft,
                message: value,
              })
            }
            onEnter={submit}
            ref={inputRef}
            value={draft.message}
          />
        </FormField>
      </div>
    </Drawer>
  );
};
export default ApprovalCancelDrawer;
