import React from 'react';
import { Editor } from '@tiptap/react';
import EditorButton from '../EditorButton';
import { EditorFeature } from '../editorCommon';

import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';

import {
  EditorIconAddTable,
  EditorIconColumnInsertLeft,
  EditorIconColumnInsertRight,
  EditorIconColumnRemove,
  EditorIconRemoveTable,
  EditorIconRowInsertLeft,
  EditorIconRowInsertRight,
  EditorIconRowRemove,
} from '../editorIcons';

const barButtons = (editor: Editor) => {
  const tableIsFocused = editor.isActive('table');

  if (!tableIsFocused) {
    return (
      <EditorButton
        label="Create table"
        onClick={() =>
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 4, withHeaderRow: true })
            .run()
        }
        icon={EditorIconAddTable}
      />
    );
  }

  return (
    <>
      <EditorButton
        label="Remove table"
        onClick={() => editor.chain().focus().deleteTable().run()}
        icon={EditorIconRemoveTable}
      />

      <EditorButton
        label="Add column to left"
        onClick={() => editor.chain().focus().addColumnBefore().run()}
        icon={EditorIconColumnInsertLeft}
      />

      <EditorButton
        label="Add column to right"
        onClick={() => editor.chain().focus().addColumnAfter().run()}
        icon={EditorIconColumnInsertRight}
      />

      <EditorButton
        label="Remove column"
        onClick={() => editor.chain().focus().deleteColumn().run()}
        icon={EditorIconColumnRemove}
      />

      <EditorButton
        label="Add row above"
        onClick={() => editor.chain().focus().addRowBefore().run()}
        icon={EditorIconRowInsertLeft}
      />

      <EditorButton
        label="Add row below"
        onClick={() => editor.chain().focus().addRowAfter().run()}
        icon={EditorIconRowInsertRight}
      />

      <EditorButton
        label="Remove row"
        onClick={() => editor.chain().focus().deleteRow().run()}
        icon={EditorIconRowRemove}
      />
    </>
  );
};

const tableFeature: EditorFeature = {
  extensions: [
    Table.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
  ],
  barButtons,
  key: 'table',
};

export default tableFeature;
