import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { systemClient } from '../../httpClients/systemclient';
import { CurrencyListing } from 'common';

const ALL_CURRENCIES_KEY = 'allcurrency';

export const useAllCurrencies = (): UseQueryResult<CurrencyListing[]> =>
  useQuery({
    queryKey: [ALL_CURRENCIES_KEY],
    queryFn: async () => {
      const { data } = await systemClient.listAllCurrencies();
      return data;
    },
  });
