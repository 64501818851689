import React from 'react';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import EditorButton from '../EditorButton';
import { EditorFeature } from '../editorCommon';
import { EditorFormatListBulleted } from '../editorIcons';

const bulletFeature: EditorFeature = {
  extensions: [BulletList, ListItem],
  barButtons: (editor) => (
    <EditorButton
      isSelected={editor.isActive('bulletList')}
      label="Bullet"
      onClick={() => editor.chain().focus().toggleBulletList().run()}
      icon={EditorFormatListBulleted}
    />
  ),
  key: 'bullet',
};

export default bulletFeature;
