import React, { useState } from 'react';
import {
  Button,
  DefaultZeroState,
  formatHumanReadable,
  getPersonFullName,
  SelectOption,
  SimpleCell,
  SimpleTable,
  SolidRadio,
  UserProfile,
  Space,
} from 'common';
import DateTimeCell from '../../../components/DateTimeCell';
import { useGetAllUsers, useGetAllUsersByRole } from '../../../services/users';
import EditUserForm from './EditUserForm';

import UserProfileIcon from '../../../components/UserProfileIcon';

export const roles: SelectOption<UserProfile['roleType']>[] = [
  {
    value: undefined,
    name: 'All',
  },
  {
    value: 'admin',
    name: 'Admin',
  },
  {
    value: 'sales',
    name: 'Sales',
  },
  {
    value: 'observer',
    name: 'Observer',
  },
];

const Users: React.FC = () => {
  const [roleTypeSelected, setRoleTypeSelected] =
    useState<UserProfile['roleType']>();
  const {
    data: users,
    isLoading,
    isError,
  } = roleTypeSelected
    ? useGetAllUsersByRole(roleTypeSelected)
    : useGetAllUsers();
  const [selectedUserId, setSelectedUserId] = useState<string>();

  const [newUserOpen, setNewUserOpen] = useState(false);

  if (isError) {
    return <div>Error loading Users</div>;
  }

  const cells: SimpleCell<UserProfile>[] = [
    {
      key: 'icon',
      headerLabel: '',
      renderCell: (userProfile: UserProfile) =>
        userProfile.user ? (
          <UserProfileIcon
            disabled={userProfile.user.status === 'pending'}
            user={userProfile.user}
          />
        ) : null,
      width: '70px',
      align: 'center',
    },
    {
      key: 'name',
      headerLabel: 'Name',
      overflowCell: true,
      renderCell: (userProfile: UserProfile) => {
        return (
          <div>
            <div className="font-bold">
              {getPersonFullName(userProfile.user)}
            </div>
            <div className="text-xs">
              {userProfile.user?.username?.toLowerCase()}
            </div>
          </div>
        );
      },
      sortValue: (userProfile: UserProfile) =>
        getPersonFullName(userProfile.user),
      searchValue: (userProfile: UserProfile) =>
        getPersonFullName(userProfile.user) + userProfile.user?.username,
      sortable: true,
      searchable: true,
    },
    {
      key: 'status',
      width: '100px',
      headerLabel: 'Status',
      renderCell: (userProfile: UserProfile) =>
        formatHumanReadable(userProfile.user?.status),
      sortValue: (userProfile: UserProfile) => userProfile.user?.status,
      searchValue: (userProfile: UserProfile) => userProfile.user?.status,
      sortable: true,
      searchable: true,
    },
    {
      key: 'role',
      headerLabel: 'Role',
      width: '100px',
      className: 'text-xs',
      renderCell: (userProfile: UserProfile) =>
        userProfile.roleType?.toUpperCase() ||
        (userProfile.user?.username?.startsWith('api@') && 'API'),
      sortValue: (userProfile: UserProfile) => userProfile.roleType,
      searchValue: (userProfile: UserProfile) => userProfile.roleType,
      sortable: true,
      searchable: true,
    },
    {
      key: 'created',
      headerLabel: 'Created',
      width: '110px',
      renderCell: (userProfile: UserProfile) => {
        return (
          <DateTimeCell
            date={userProfile.user?.createdAt || userProfile.user?.updatedAt}
          />
        );
      },
      sortValue: (userProfile: UserProfile) => userProfile.user?.createdAt,
      sortable: true,
    },
  ];

  const isEditable = (user: UserProfile) => {
    return user.roleType !== 'api' && !user.user?.username?.startsWith('api@');
  };

  const onClose = () => {
    setNewUserOpen(false);
    setSelectedUserId(undefined);
  };

  return (
    <div>
      {(!!selectedUserId || newUserOpen) && (
        <EditUserForm
          isBeingViewed
          onClose={onClose}
          onSaveSuccess={onClose}
          roleTypeIn={roleTypeSelected}
          userId={selectedUserId}
        />
      )}
      <SimpleTable<UserProfile>
        cells={cells}
        dataTestId="users-table"
        isLoading={isLoading}
        minWidth="600px"
        onRowClick={(userProfile: UserProfile) => {
          if (isEditable(userProfile)) {
            setSelectedUserId(userProfile.user?.id);
          }
        }}
        rightWidget={
          <Space>
            <SolidRadio
              onChange={(newValue) => setRoleTypeSelected(newValue)}
              options={roles}
              size="small"
              value={roleTypeSelected}
            />
            <Button
              dataTestId="create-user-button"
              isDisabled={false}
              label="Add user"
              onClick={() => setNewUserOpen(true)}
            />
          </Space>
        }
        rows={users}
        zeroState={
          <DefaultZeroState
            dataTestId="no-users"
            message="There are no users"
          />
        }
      />
    </div>
  );
};

export default Users;
