import React, { useEffect, useState } from 'react';
import {
  AttachmentConfig,
  isDraftProposalStatus,
  Proposal,
  ProposalAttachment,
} from 'common';
import ProposalSection from '../ProposalSection/ProposalSection';
import { useGetAttachmentConfigs } from '../../../Settings/Attachments/hook';
import SelectAttachments from './SelectAttachments';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

interface Props {
  isDisabled: boolean;
  onAttachmentsChange: (attachments: ProposalAttachment[]) => void;
  proposal: Proposal;
}

const AttachmentSection: React.FC<Props> = ({
  proposal,
  isDisabled,
  onAttachmentsChange,
}) => {
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  const [attachmentConfigs, setAttachmentConfigs] =
    useState<AttachmentConfig[]>();
  const apiResponse = useGetAttachmentConfigs();
  const proposalIsInDraft = isDraftProposalStatus(proposal);
  useEffect(() => {
    if (!attachmentConfigs && !apiResponse.isLoading && !apiResponse.isError) {
      setAttachmentConfigs(apiResponse.responseData);
    }
  }, [apiResponse]);

  if (!canUpdate && proposal.attachments.length === 0) {
    return null;
  }

  return (
    <ProposalSection
      section="Attachments"
      title="Attachments"
      sectionRef="Attachments"
      openOnLoad={proposal.attachments.length > 0}
      onRemove={() => onAttachmentsChange([])}
      isDisabled={isDisabled}
    >
      {attachmentConfigs && (
        <SelectAttachments
          isDisabled={isDisabled || !canUpdate}
          attachmentConfigs={attachmentConfigs}
          attachments={proposal.attachments}
          onAttachmentsChange={onAttachmentsChange}
          proposalIsInDraft={proposalIsInDraft}
        />
      )}
    </ProposalSection>
  );
};

export default AttachmentSection;
