import React, { PropsWithChildren, useCallback } from 'react';
import { ItemScheduleRequest, UpdateItemScheduleContext } from 'common';
import { useUpdateItemSchedule } from '../../../../services/proposal';

export const UpdateItemScheduleProvider = ({
  proposalId,
  children,
}: PropsWithChildren<{ proposalId: string }>) => {
  const { mutate } = useUpdateItemSchedule(proposalId);

  const update = useCallback(
    (id: string, request: ItemScheduleRequest) => {
      mutate({ itemScheduleId: id, body: request });
    },
    [mutate]
  );

  return (
    <UpdateItemScheduleContext.Provider value={update}>
      {children}
    </UpdateItemScheduleContext.Provider>
  );
};
