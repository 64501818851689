import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useConnectStripeAccount,
  useRemoveConnectStripeAccount,
  useStripeAccount,
  useUpdateConnectStripeAccount,
} from '../../../services/api/stripe/stripe';
import {
  Button,
  ButtonBar,
  Card,
  FormField,
  getErrorMessage,
  Icon,
  Input,
  LoadingSpinner,
  log,
  PopOutMenu,
  PopOutMenuOption,
  StripeAccount,
  StripeConnectResponse,
  useTranslation,
} from 'common';
import StripeConnectCard from './StripeConnectCard';
import StripeCreateCard from './StripeCreateCard';
import { ConnectionStatus } from '../../../components';
import { useIsSuperAdmin } from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';
import LogoCardHeader from '../Connectors/LogoCardHeader';
import StripeLogoSVG from '../../../assets/integration-logos/StripeLogoSVG';
import StripeUnmanagedMethodsToggle from './StripeUnmanagedMethodsToggle';
import StripePaymentRetryToggle from './StripePaymentRetryToggle';
import { SETTINGS_STRIPE } from '../../../core-utils/routes';
import { getBaseHostUrl } from '../../../services/helper';

const StripeForms = () => {
  const [routingToStripe, setRoutingToStripe] = useState(false);
  const [stripeAccount, setStripeAccount] = useState<StripeAccount>();
  const [error, setError] = useState<unknown>();
  const queryClient = useQueryClient();
  const { data: stripeAccountData, isError, isLoading } = useStripeAccount();
  const urlParams = new URLSearchParams(location.search);
  const [stripeCode, setStripeCode] = useState(urlParams.get('code'));
  const [removed, setRemoved] = useState(false);
  const { tk } = useTranslation();
  const isSuperAdmin = useIsSuperAdmin();
  const [isDraftDirty, setIsDraftDirty] = useState(false);
  const connectId = stripeAccount?.connectAccountId;
  const showStripeId =
    connectId && (isSuperAdmin || !ENVIRONMENT_CONFIG.isProduction);

  const allowCreation = ENVIRONMENT_CONFIG.isLocal;
  const [currency, setCurrency] = useState<string>('usd');
  const country = 'US';

  const { mutate } = useConnectStripeAccount(
    (data: StripeConnectResponse) => {
      log.debug(data);
      const redirectUrl = `${getBaseHostUrl()}${SETTINGS_STRIPE}`;
      window.location.href = `${data.url}&redirect_uri=${redirectUrl}`;
    },
    (err: unknown) => {
      setError(err);
    }
  );

  const { isPending: mutateIsLoading, mutate: updateMutate } =
    useUpdateConnectStripeAccount(
      (data: StripeConnectResponse) => {
        log.debug(data);
        setError(undefined);
        setIsDraftDirty(false);
      },
      (err: unknown) => {
        setError(err);
      }
    );

  const save = () => {
    updateMutate({
      country,
      currency,
    });
  };

  const setup = () => {
    if (stripeAccountData && !isError && !removed) {
      const acct = stripeAccountData;
      setStripeAccount(acct);
      if (acct.currency) {
        setCurrency(acct.currency);
      }
    }
  };

  if (!stripeAccount) {
    setup();
  }

  const handleStripeButtonClick = () => {
    mutate({ country, currency });
  };

  const { mutate: removeAccount } = useRemoveConnectStripeAccount(
    () => {
      log.debug('on remove stripe');
      setRemoved(true);
      setRoutingToStripe(false);
      setStripeCode(null);
      setStripeAccount(undefined);
    },
    (err: unknown) => {
      setError(err);
    },
    queryClient
  );

  if (isLoading) {
    return (
      <div className="flex flex-col h-full bg-white min-h-[200px] p-6 justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const remove = () => {
    removeAccount();
  };

  const connectStripe = (reconnect: boolean) => {
    setRoutingToStripe(!reconnect);
    mutate({
      country: 'us',
      currency: 'usd',
      connectExisting: true,
      reconnect,
    });
  };

  const hasStripeConnection = !!(
    stripeAccount?.connectAccountId &&
    !error &&
    stripeAccount.status === 'active'
  );
  return (
    <Card>
      <LogoCardHeader
        connectionComponent={
          !routingToStripe && (
            <div className="flex items-center justify-between">
              <ConnectionStatus
                isConnected={hasStripeConnection}
                message={hasStripeConnection ? 'Connected' : 'Not connected'}
              />
            </div>
          )
        }
        logo={StripeLogoSVG}
      >
        {stripeAccount && (
          <ButtonBar>
            <PopOutMenu>
              {(stripeAccount.status !== 'active' || isSuperAdmin) && (
                <PopOutMenuOption
                  icon={Icon.Trash}
                  onClick={remove}
                  title="Remove"
                  isDisabled={stripeAccount.status === 'active'}
                />
              )}
              <PopOutMenuOption
                icon={Icon.Refresh}
                onClick={() => connectStripe(true)}
                title="Reconnect"
              />
            </PopOutMenu>
            <Button
              label={tk('Save')}
              isDisabled={!isDraftDirty}
              isLoading={mutateIsLoading}
              onClick={save}
            />
          </ButtonBar>
        )}
      </LogoCardHeader>

      <div className="flex flex-col h-full justify-between">
        {!routingToStripe && stripeAccount?.connectAccountId && (
          <div>
            <div className="max-w-screen-sm">
              <div className="h-2" />
              {error ? (
                <div className="bg-red-lightest rounded-md p-2 mb-4 text-red border-red border-[1px]">
                  {getErrorMessage(error)}
                </div>
              ) : null}

              {stripeAccount.status === 'inactive' && (
                <div className="w-full flex flex-col justify-center items-center border-orange-darker border-[1px] rounded-[4px] bg-orange-lightest p-6 gap-2 mb-4">
                  <p>Your Stripe setup isn't complete</p>
                  <Button
                    dataTestId="setup-stripe-button"
                    label="Finish setting up account"
                    onClick={handleStripeButtonClick}
                    type="link"
                  />
                </div>
              )}

              <div className="flex items-start justify-between">
                {showStripeId && (
                  <FormField label={tk('Stripe account id')}>
                    <Input value={stripeAccount.connectAccountId} isDisabled />
                  </FormField>
                )}
              </div>

              <FormField label={tk('Settlement currency')}>
                <Input value={stripeAccount.currency} isDisabled />
              </FormField>

              <div className="my-4">
                <StripeUnmanagedMethodsToggle />
              </div>
              <div className="my-4">
                <StripePaymentRetryToggle />
              </div>
            </div>
          </div>
        )}

        {!routingToStripe &&
          stripeAccount?.connectAccountId &&
          stripeAccount.connectType !== 'created' && (
            <div className="flex flex-col pb-6">
              Your existing Stripe account is connected.
            </div>
          )}

        {!stripeAccount?.connectAccountId && (
          <div className="flex flex-row justify-between">
            {allowCreation && (
              <StripeCreateCard
                setRoutingToStripe={setRoutingToStripe}
                setError={setError}
                isDisabled={routingToStripe || !!stripeCode}
              />
            )}

            <StripeConnectCard
              connectStripe={connectStripe}
              isDisabled={routingToStripe || !!stripeCode}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default StripeForms;
