import React, { useState } from 'react';
import { Button, Modal } from 'common';

interface Props {
  errorMessage: string;
}

const NotificationErrorMessage: React.FC<Props> = ({ errorMessage }) => {
  const [isShowingError, setIsShowingError] = useState(false);
  return (
    <>
      <Button
        label="See error"
        onClick={() => setIsShowingError(true)}
        type="link"
      />
      <Modal isOpen={isShowingError} width={1000}>
        <div className="flex justify-end">
          <Button
            label="Close"
            onClick={() => setIsShowingError(false)}
            type="link"
          />
        </div>
        <div
          style={{
            whiteSpace: 'pre-line',
            maxWidth: '100%',
            overflowX: 'auto',
            fontFamily: 'monospace',
          }}
        >
          {errorMessage}
        </div>
      </Modal>
    </>
  );
};

export default NotificationErrorMessage;
