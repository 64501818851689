import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { Customer, TaxCodeRest } from 'common';

export const GET_ALL_TAXCODES_PATH = '/api/latest/settings/taxes/tax-codes';

export const useGetAllTaxCodes = (): UseQueryResult<TaxCodeRest[]> =>
  useQuery({
    queryKey: [GET_ALL_TAXCODES_PATH],
    queryFn: async () => {
      const { data } = await apiClient.getTaxCodes();
      return data;
    },
  });

const getTaxExemptCodes = async () => {
  const { data } = await apiClient.getExemptTaxCodes();
  return data;
};

export const useTaxExemptCodes = (
  options = {}
): UseQueryResult<NonNullable<Customer['taxUseCode']>[]> =>
  useQuery({
    queryKey: ['tax-exempt-codes'],
    queryFn: getTaxExemptCodes,
    ...options,
  });
