import {
  Cell,
  CrmStage,
  Icon,
  IconButton,
  InfoToolTip,
  Loading,
  Select,
  SimpleTable,
  Tab,
  Tabs,
  ToolTip,
  cleanOptions,
  ProposalSummary,
  type StageMapping,
} from 'common';
import React, { useEffect } from 'react';
import {
  CacheflowMapping,
  cacheflowMappings,
} from '../ConnectorConstants/cacheflowStatusMapping';
import { ConnectorInfoObject } from '../ConnectorConstants/constants';
import { useGetCrmPipelines } from '../../../../services/externalCrm';
import { ConnectorPipelineType } from '../ConnectorSettings';

interface Props {
  connectorInfo: ConnectorInfoObject;
  newConnectorMapping: StageMapping[];
  setNewConnectorMapping: React.Dispatch<React.SetStateAction<StageMapping[]>>;
  setSaveableChanges: (arg: boolean) => void;
  setConnectorPipeline: React.Dispatch<
    React.SetStateAction<ConnectorPipelineType>
  >;
  connectorPipeline: ConnectorPipelineType;
}

const ConnectorStageMappigs = ({
  setNewConnectorMapping,
  newConnectorMapping,
  setSaveableChanges,
  connectorInfo,
  setConnectorPipeline,
  connectorPipeline,
}: Props) => {
  const {
    data: crmPipelines,
    isLoading: loading,
    isError,
  } = useGetCrmPipelines(connectorInfo.source);

  const getConnectorPipeline = (proposalType: StageMapping['proposalType']) => {
    return newConnectorMapping.find((stage) => {
      return stage.proposalType === proposalType;
    });
  };

  const [stages, setStages] = React.useState<CrmStage[]>();

  const [activeTab, setActiveTab] =
    React.useState<ProposalSummary['proposalType']>('initial');

  const getStages = (pipelineName: string | undefined) => {
    return crmPipelines?.find((pipeline) => pipeline.name === pipelineName)
      ?.stages;
  };

  useEffect(() => {
    const updatedPipeline = getConnectorPipeline(activeTab)?.pipeline;

    setConnectorPipeline((prev) => ({
      ...prev,
      [activeTab]: updatedPipeline,
    }));
  }, [activeTab]);

  useEffect(() => {
    const updatedStages = getStages(connectorPipeline[activeTab]);
    setStages(updatedStages);
  }, [connectorPipeline]);

  if (isError) {
    return <div>Error loading stages</div>;
  }

  const findAndReplaceMapping = (
    cacheflowStatusMapping: StageMapping['proposalEventType'],
    newMapping: StageMapping
  ) => {
    const foundMappingIndex = newConnectorMapping.findIndex((map) => {
      return (
        map.proposalEventType === cacheflowStatusMapping &&
        map.proposalType === activeTab &&
        map.pipeline === connectorPipeline[activeTab] &&
        map.toStage !== undefined
      );
    });

    if (foundMappingIndex === -1) {
      return [
        ...newConnectorMapping,
        {
          proposalEventType: cacheflowStatusMapping,
          toStage: newMapping.toStage,
          proposalType: activeTab,
          pipeline: connectorPipeline[activeTab],
        },
      ];
    }

    return [
      ...newConnectorMapping.slice(0, foundMappingIndex),
      newMapping,
      ...newConnectorMapping.slice(
        foundMappingIndex + 1,
        newConnectorMapping.length
      ),
    ];
  };

  const handleUnmapStage = (
    cacheflowStatusMapping: StageMapping['proposalEventType']
  ) => {
    const foundMapping = newConnectorMapping.find((map) => {
      return (
        map.proposalEventType === cacheflowStatusMapping &&
        map.proposalType === activeTab &&
        map.pipeline === connectorPipeline[activeTab]
      );
    });

    if (foundMapping) {
      const newMapping: StageMapping = {
        proposalEventType: cacheflowStatusMapping,
        toStage: undefined,
        proposalType: activeTab,
        pipeline: connectorPipeline[activeTab],
      };

      const updatedMapping = findAndReplaceMapping(
        cacheflowStatusMapping,
        newMapping
      );
      setNewConnectorMapping(updatedMapping.filter((map) => map.toStage));

      const stagesLeft = updatedMapping.filter(
        (map) =>
          map.proposalType === activeTab &&
          map.pipeline === connectorPipeline[activeTab] &&
          map.toStage !== undefined
      ).length;

      if (stagesLeft === 0) {
        setConnectorPipeline((prev) => ({
          ...prev,
          [activeTab ?? 'initial']: '',
        }));
        setNewConnectorMapping(
          newConnectorMapping.filter(
            (mapping) => mapping.proposalType !== activeTab
          )
        );
      }

      setSaveableChanges(true);
    }
  };

  const handleSelectStage = (
    value: string,
    cacheflowStatusMapping: StageMapping['proposalEventType']
  ) => {
    const newMapping: StageMapping = {
      proposalEventType: cacheflowStatusMapping,
      toStage: value,
      proposalType: activeTab,
      pipeline: connectorPipeline[activeTab],
    };

    const updatedMapping = findAndReplaceMapping(
      cacheflowStatusMapping,
      newMapping
    );
    setNewConnectorMapping(updatedMapping);

    setSaveableChanges(true);
  };

  const handlePipelineChange = (value: string) => {
    if (activeTab in connectorPipeline) {
      setSaveableChanges(true);
      setConnectorPipeline((prev) => ({
        ...prev,
        [activeTab]: value,
      }));
      setStages(getStages(value));
    }
    setNewConnectorMapping(
      newConnectorMapping.filter(
        (mapping) =>
          mapping.proposalType !== activeTab &&
          mapping.pipeline &&
          mapping.proposalType
      )
    );
  };

  const getStageCells = (): Cell<CacheflowMapping>[] => [
    {
      key: 'cachflowStatus',
      headerLabel: 'Cacheflow status',
      renderCell: (cell) => (
        <span className="cacheflow-stage">
          <p>{cell.cacheflowStatus}</p>
          <InfoToolTip title={cell.cacheflowStatusInfo} />
        </span>
      ),
    },
    {
      key: 'arrow',
      headerLabel: '',
      width: '10%',
      renderCell: () => <Icon.ArrowRight />,
    },
    {
      key: `${connectorInfo.source}Stage`,
      headerLabel: `${connectorInfo.name} stage`,
      width: '55%',
      renderCell: (cell) => (
        <div className="connector-select-stage">
          <ToolTip
            title={
              !connectorPipeline[activeTab] &&
              newConnectorMapping.filter(
                (mapping) => mapping.proposalType === activeTab
              ).length === 0
                ? 'Please select a pipeline first'
                : ''
            }
          >
            <Select<string>
              placeholder="Select stage"
              noResultsText={
                connectorPipeline
                  ? 'No stages found'
                  : 'Select a pipeline first'
              }
              options={cleanOptions(
                stages?.map((stage) => ({
                  value: stage.id,
                  name: stage.name,
                })),
                'stageOptions'
              )}
              onChange={(newValue) =>
                handleSelectStage(newValue, cell.cacheflowStatusMapping)
              }
              value={
                newConnectorMapping.find(
                  (mapping) =>
                    mapping.proposalEventType === cell.cacheflowStatusMapping &&
                    mapping.proposalType === activeTab &&
                    stages?.find((stage) => stage.id === mapping.toStage) &&
                    mapping.pipeline === connectorPipeline[activeTab]
                )?.toStage
              }
              isDisabled={
                !connectorPipeline[activeTab] &&
                newConnectorMapping.filter(
                  (mapping) => mapping.proposalType === activeTab
                ).length === 0
              }
            />
          </ToolTip>

          <IconButton
            icon={Icon.CloseCircle}
            label="remove-connector-mapping"
            onClick={() => handleUnmapStage(cell.cacheflowStatusMapping)}
            isDisabled={
              !connectorPipeline[activeTab] &&
              newConnectorMapping.filter(
                (mapping) => mapping.proposalType === activeTab
              ).length === 0
            }
          />
        </div>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  const renderStageTable = () => {
    return (
      <div className="connector-opportunities">
        <div className="connector-opportunities">
          <h3>Proposal stage mapping</h3>
          <div className="flex justify-between items-center pb-4">
            <p className="text-md">
              Select which pipeline you would like {activeTab} proposals to be
              associated
            </p>
            <div className="w-1/4">
              <Select<string>
                value={connectorPipeline[activeTab]}
                options={cleanOptions(
                  crmPipelines?.map((pipeline) => ({
                    value: pipeline.name,
                    name: pipeline.name,
                  })),
                  'pipelineOptions'
                )}
                onChange={handlePipelineChange}
                onClearSelected={() => {
                  if (activeTab) {
                    setConnectorPipeline((prev) => ({
                      ...prev,
                      [activeTab]: '',
                    }));
                    setNewConnectorMapping(
                      newConnectorMapping.filter(
                        (mapping) => mapping.proposalType !== activeTab
                      )
                    );
                    setSaveableChanges(true);
                  }
                }}
                placeholder="Select pipeline"
              />
            </div>
          </div>
          <SimpleTable
            cells={getStageCells()}
            rows={cacheflowMappings}
            disableSearch
            disableShadow
            numberOfSkeletonRows={cacheflowMappings.length}
            isLoading={loading}
          />
        </div>
      </div>
    );
  };

  const tabs: Tab[] = [
    {
      key: 'initial',
      label: 'Initial',
      component: renderStageTable(),
    },
    {
      key: 'change',
      label: 'Change',
      component: renderStageTable(),
    },

    {
      key: 'renewal',
      label: 'Renewal',
      component: renderStageTable(),
    },
  ];

  return (
    <Tabs
      onChange={(tab) => {
        setActiveTab(tab as 'initial' | 'renewal' | 'change');
      }}
      tabs={tabs}
    />
  );
};

export default ConnectorStageMappigs;
