import React, { useState } from 'react';
import { Select } from 'antd';

import {
  ApprovalRule,
  Button,
  ConfirmDeleteModal,
  Icon,
  isNumber,
  Money,
  MoneyInput,
  ProposalAmountRuleConfig,
  ProposalSummary,
} from 'common';

import { GroupOption, searchFilterOption } from './_approvalRulesConfig';

import styles from './ApprovalRuleStyles.module.scss';
import { getConfig } from './ruleUtils';

interface Props {
  allGroupOptions: GroupOption[];
  approvalRule: ApprovalRule;
  conditionOperator: string[] | string;
  configKey: string;
  description: string;
  onRuleChange: (name: string, changedRule: ApprovalRule | null) => void;
  proposalType: ProposalSummary['proposalType'];
}

export const ApprovalRuleMoneyRow = ({
  approvalRule,
  proposalType,
  onRuleChange,
  allGroupOptions,
  conditionOperator,
  description,
  configKey,
}: Props) => {
  const getValue = (): Money => {
    const config = getConfig<ProposalAmountRuleConfig>(approvalRule);

    const configValue =
      config && typeof config === 'object' && configKey in config
        ? config[configKey as keyof ProposalAmountRuleConfig]
        : undefined;

    const amount =
      typeof configValue === 'object' && 'amount' in configValue
        ? configValue.amount
        : undefined;

    return isNumber(amount) ? { amount } : { amount: 0 };
  };

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const [value, setValue] = useState<Money>(getValue());
  const [showSelectError, setShowSelectError] = useState<boolean>(false);

  const updateRule = (newValue: Money): ApprovalRule => ({
    id: approvalRule.id,
    name: approvalRule.name,
    config: {
      ruleType: approvalRule.config?.ruleType!,
      proposalType,
      [configKey]: newValue,
    },
  });

  const handleValueChange = (newMoneyValue: Money) => {
    setValue(newMoneyValue);
  };

  const handleSave = () => {
    onRuleChange(approvalRule.id!, updateRule(value));
  };

  const onGroupsChange = (groupIds: string[]) => {
    setShowSelectError(groupIds.length === 0);

    const payload = {
      ...updateRule(value),
      approvalGroupIds: groupIds,
    };

    onRuleChange(approvalRule.id!, payload);
  };

  return (
    <div className={styles.ruleRow}>
      <div className={styles.name}>
        <Button
          className={styles.removeIcon}
          onClick={() => setIsDeleteConfirmationOpen(true)}
          primaryIcon={Icon.CloseCircle}
          type="secondary"
        />
        <span className={styles.ruleName}>{description}</span>
      </div>

      <div className={styles.condition}>{conditionOperator}</div>

      <div className={styles.input}>
        <MoneyInput
          defaultCurrency="USD" // TODO: no more hard coded currency, leave this here even with default handled to mark it as an area of attentions
          isDisabled={!approvalRule}
          onBlur={handleSave}
          onChange={handleValueChange}
          value={value}
        />
      </div>

      <div className={styles.groups}>
        {allGroupOptions.length > 0 && (
          <Select
            className={styles.groupWidth}
            disabled={!approvalRule}
            filterOption={searchFilterOption}
            mode="multiple"
            onChange={onGroupsChange}
            options={allGroupOptions}
            placeholder="Select approval group"
            status={showSelectError ? 'error' : ''}
            value={approvalRule.approvalGroupIds?.map((id) => id)}
          />
        )}
      </div>

      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => onRuleChange(approvalRule.id!, null)}
        typeName="condition"
      />
    </div>
  );
};
