import React from 'react';
import ExternalDealsCallback from '../ExternalDealsCallback';
import { SALESFORCE_OPPORTUNITIES } from '../../../core-utils/routes';

const OpportunitiesCallback: React.FC = () => {
  return (
    <ExternalDealsCallback
      dealRoute={SALESFORCE_OPPORTUNITIES}
      dealQueryParamName="opportunityId"
    />
  );
};

export default OpportunitiesCallback;
