import React from 'react';
import { formatHumanReadable, Loading, RequestParams } from 'common';
import { AxiosResponse } from 'axios';
import EventCard from '../pages/Settings/SyncEvents/EventCard';
import styles from './SyncSummaryCard.module.scss';
import { useSyncSummary } from '../services/syncEvents';

interface Props<T> {
  searchParams: string;
  fetchCountList: (
    query?: { search?: string },
    params?: RequestParams
  ) => Promise<AxiosResponse<T, any>>;
  queryKey: string;
}

const SyncSummaryCard = <T extends Record<string, any>>({
  searchParams,
  fetchCountList,
  queryKey,
}: Props<T>) => {
  const { data: syncSummaryCount, isLoading } = useSyncSummary<T, unknown>(
    queryKey,
    fetchCountList,
    searchParams
  );

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        syncSummaryCount && (
          <div className={styles.cardContainer}>
            {Object.keys(syncSummaryCount).map((key) => (
              <EventCard
                key={key}
                count={syncSummaryCount[key as keyof T]}
                title={formatHumanReadable(key)}
              />
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default SyncSummaryCard;
