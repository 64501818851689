import React from 'react';
import './shipFrom.scss';
import {
  cleanOptions,
  CompanyInfo,
  isArray,
  isChangeProposalType,
  Proposal,
  ProposalLabel,
  Select,
  useFlags,
  useTranslation,
} from 'common';
import { useAllCompanyInfo } from 'app/src/services/api/branding/companyInfo';

interface Props {
  isDisabled?: boolean;
  proposal: Proposal;
  selectShipFrom?: (companyInfoId: string) => void;
}

const ShipFromSelect: React.FC<Props> = ({
  proposal,
  selectShipFrom,
  isDisabled,
}) => {
  const { tk } = useTranslation();
  const { data: companyInfoList, isLoading } = useAllCompanyInfo();
  const isProposalTypeChange = isChangeProposalType(proposal);
  const isProposalDraft = proposal.status === 'draft';
  const { shipFrom } = useFlags();

  const onChange = (id?: string) => {
    if (!id) return;
    selectShipFrom?.(id);
  };

  if (!shipFrom || !companyInfoList || companyInfoList.length === 0) {
    return null;
  }

  const defaultCompany = () => {
    return isArray(companyInfoList)
      ? companyInfoList.find((i: CompanyInfo) => i.defaultInfo) || {}
      : {};
  };

  const companyInfos = cleanOptions(
    companyInfoList.map((c) => ({
      name: c.name,
      value: c.id,
    })),
    'companyInfos'
  );

  return (
    !isLoading &&
    (proposal.status === 'draft' || proposal.shipFrom !== undefined) &&
    (proposal.shipFrom?.id
      ? companyInfos.length > 1
      : companyInfos.length > 0) && (
      <div className="ship-from-wrapper">
        <ProposalLabel name={tk('Ship from')} />
        <div className="w-[320px]">
          <Select
            dataTestId="shipFrom"
            isDisabled={!isProposalDraft || isProposalTypeChange || isDisabled}
            onChange={(value) => onChange(value)}
            options={companyInfos}
            placeholder="Select company"
            value={proposal.shipFrom?.id || defaultCompany().id}
          />
        </div>
      </div>
    )
  );
};

export default ShipFromSelect;
