import React, { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.333 12.159v-.721c.542 0 .92-.11 1.135-.332.215-.224.322-.595.322-1.11v-1.38c0-.49.055-.903.164-1.236.112-.337.282-.606.51-.808.227-.202.515-.348.865-.437.35-.09.76-.135 1.235-.135v1.14c-.371 0-.662.054-.87.163a.896.896 0 0 0-.437.51c-.08.227-.12.519-.12.875v1.75c0 .247-.037.476-.111.687-.074.209-.21.39-.409.544-.199.153-.482.274-.85.36-.37.087-.847.13-1.434.13Zm4.231 6.106c-.474 0-.886-.045-1.235-.134a2.046 2.046 0 0 1-.866-.438 1.824 1.824 0 0 1-.51-.808c-.108-.333-.163-.745-.163-1.235v-1.385c0-.513-.107-.882-.322-1.106-.215-.224-.593-.337-1.135-.337v-.716c.587 0 1.064.043 1.433.13.369.083.652.204.851.36.199.154.335.337.409.549.074.208.11.436.11.682v1.75c0 .353.04.643.12.87.084.232.23.401.438.51.208.113.499.169.87.169v1.14Zm-4.23-5.443v-1.384h1.331v1.384H5.333ZM18.207 12.106v.716c-.542 0-.92.113-1.135.337-.215.224-.322.593-.322 1.106v1.385c0 .49-.056.902-.168 1.235a1.789 1.789 0 0 1-.505.808 2.046 2.046 0 0 1-.866.438c-.35.09-.761.134-1.235.134v-1.14c.371 0 .661-.055.87-.168a.877.877 0 0 0 .433-.51c.083-.227.125-.517.125-.87v-1.75c0-.246.037-.474.11-.682a1.18 1.18 0 0 1 .409-.548c.199-.157.482-.278.85-.361.37-.087.847-.13 1.434-.13ZM13.976 6c.474 0 .886.045 1.235.135.35.09.638.235.866.437.227.202.396.471.505.808.112.333.168.745.168 1.236v1.38c0 .515.107.886.322 1.11.215.221.593.332 1.135.332v.721c-.587 0-1.065-.043-1.433-.13-.369-.086-.652-.207-.851-.36a1.185 1.185 0 0 1-.409-.544 2.076 2.076 0 0 1-.11-.687v-1.75c0-.356-.042-.648-.125-.875a.877.877 0 0 0-.433-.51c-.209-.109-.498-.164-.87-.164V6Zm4.23 5.438v1.384h-1.331v-1.384h1.332Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;
