import { ExternalLink } from 'common';
import React from 'react';

import styles from './SmallPrint.module.scss';

const PRIVACY_POLICY_URL = 'https://www.getcacheflow.com/privacy-policy';
// https://docs.getcacheflow.com/93df2497-cb0b-44c7-a0ea-3616edb35b7f/b9f94fda-1ff7-43b4-addd-22e75c32a869/Cacheflow%20Terms%20of%20Service%20with%20Signature.pdf
const TERMS_OF_SERVICE =
  'https://www.getcacheflow.com/subscription-services-agreement';

const SmallPrint: React.FC = () => {
  return (
    <div className={styles.smallPrint}>
      <div className={styles.links}>
        <ExternalLink href={TERMS_OF_SERVICE}>Terms of service</ExternalLink>
        <ExternalLink href={PRIVACY_POLICY_URL}>Privacy policy</ExternalLink>
        <ExternalLink href="mailto:support@getcacheflow.com">
          Contact us
        </ExternalLink>
      </div>

      <div className={styles.copyright}>
        &copy; Copyright {new Date().getFullYear()} Cacheflow Inc.
      </div>
    </div>
  );
};

export default SmallPrint;
