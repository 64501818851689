import React from 'react';

import { Input, Select } from 'antd';

import { Button, Icon, NotificationEventConfiguration } from 'common';

import styles from './NotificationRow.module.scss';
import {
  NotificationSendOptions,
  NotificationTargetOptions,
  NotificationTargets,
  NotificationValidationBag,
} from './Notifications';

interface Props {
  daysBeforeText: string;
  notification: NotificationEventConfiguration;
  notificationSendOptions: NotificationSendOptions[];
  notificationTargetOptions: NotificationTargetOptions[];
  onChange: (notification: NotificationEventConfiguration) => void;
  onRemove: () => void;
  validationBag: NotificationValidationBag;
}

/**
 *
 * This is a base component that gets used by others to keep
 * an agnostic notifications system at its core. Loading data,
 * checking for errors or loading is all done in parent.
 *
 * Changes should not be made here, but instead in the extending parent
 * components, when you need to set up a new notification area.
 *
 * See AutoRenewalNotifications.tsx for an example.
 */
export const NotificationRow = ({
  notification,
  onChange,
  onRemove,
  notificationSendOptions,
  notificationTargetOptions,
  daysBeforeText,
  validationBag,
}: Props) => {
  const handleBeforeDaysChange = (e: { target: { value: string } }) => {
    onChange({
      ...notification,
      beforeDays: parseInt(e.target.value, 10),
    });
  };

  const handleNotificationTypeChange = (
    value: NotificationEventConfiguration['notificationType']
  ) => {
    onChange({
      ...notification,
      notificationType: value,
    });
  };

  const handleNotificationTargetsChange = (values: NotificationTargets[]) => {
    onChange({
      ...notification,
      targetGroups: values,
    });
  };

  return (
    <div className={styles.notificationRow}>
      <Input
        type="number"
        status={validationBag.daysBefore ? '' : 'error'}
        value={notification.beforeDays}
        onChange={handleBeforeDaysChange}
        className={styles.beforeDays}
        min={0}
      />

      <p
        className={styles.text}
      >{`days before ${daysBeforeText}, notify via`}</p>

      <Select
        onChange={handleNotificationTypeChange}
        value={notification.notificationType}
      >
        {notificationSendOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>

      <p>to</p>

      <Select
        onChange={handleNotificationTargetsChange}
        value={notification.targetGroups}
        mode="multiple"
        className={styles.targetInput}
        allowClear
        style={{ width: '40%' }}
        placeholder="Select notification targets"
        status={validationBag.targetGroups ? '' : 'error'}
      >
        {notificationTargetOptions.map((target, index) => (
          <Select.Option key={index} value={target.value}>
            {target.label}
          </Select.Option>
        ))}
      </Select>

      <Button onClick={onRemove} primaryIcon={Icon.Close} type="secondary" />
    </div>
  );
};
