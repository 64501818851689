import React, { useState } from 'react';

import {
  ConfirmModal,
  getErrorMessage,
  Icon,
  ItemIcon,
  PopOutMenuOption,
  Proposal,
  ProposalSummary,
  useToast,
  useTranslation,
  ExternalSource,
  isRenewalProposalType,
  isChangeProposalType,
} from 'common';

import { CopyProposalStrategy } from '../../../services/proposal';
import {
  useLinkObjectToExternal,
  useUnlinkExternalObject,
} from '../../../services/externalCrm';
import { useIsCurrentUserAdmin } from '../../../core-utils/helperFunctions/userServiceHelper';
import { isProposalTemplate } from '../ProposalEditor/proposalUtilities';
import { useProposalCopy } from '../ProposalEditor/useProposalCopy';
import CopyErrorModal from '../ProposalEditor/CopyErrorModal/CopyErrorModal';
import { useQueryClient } from '@tanstack/react-query';
import LinkEntityToCrmDrawer from '../../Sales/LinkEntityToCrmDrawer';
import { apiClient } from 'app/src/services/httpClients/app';

interface Props {
  editorContext?: boolean;
  proposal: Proposal | ProposalSummary;
}

const CopyOptions: React.FC<Props> = ({ proposal, editorContext }) => {
  const { tk } = useTranslation();
  const showToast = useToast();
  const [showConfirm, setShowConfirm] = useState(false);
  const [linkToCrmDrawerOpen, setLinkToCrmDrawerOpen] = useState(false);
  const currentUserAdmin = useIsCurrentUserAdmin();
  const isTemplate = isProposalTemplate(proposal);
  const hasExternalSource =
    ('externalSource' in proposal && proposal.externalSource?.sourceId) ||
    ('externalId' in proposal && proposal.externalId);
  const queryClient = useQueryClient();
  const { makeCopy, copyError, handleClose } = useProposalCopy();

  const doMakeCopy = (strategy?: CopyProposalStrategy) =>
    makeCopy(proposal.id, strategy);

  const { mutate: unlinkProposalFromCRM } = useUnlinkExternalObject(
    'proposal',
    proposal.id,
    () => {
      setShowConfirm(false);
      showToast.info('Proposal unlinked from CRM');
    },
    (err: unknown) => {
      setShowConfirm(false);
      const errorMessage = getErrorMessage(err);
      if (errorMessage) {
        showToast.error(errorMessage);
      } else {
        showToast.error('Failed to unlink proposal from CRM');
      }
    },
    queryClient
  );
  const unlinkFromCRM = () => {
    unlinkProposalFromCRM({
      sourceId: proposal.externalId,
      sourceType: proposal.source,
    });
  };

  const { mutate: linkObjectToExternal, isPending } = useLinkObjectToExternal(
    'proposal',
    proposal.id,
    () => {
      showToast.info('Proposal linked to CRM');
      setLinkToCrmDrawerOpen(false);
    },
    (err: unknown) => {
      const errorMessage = getErrorMessage(err);
      if (errorMessage) {
        showToast.error(errorMessage);
      } else {
        showToast.error('Failed to link proposal to CRM');
      }
    },
    queryClient
  );

  const getSources = (proposalObj: Proposal | ProposalSummary) => {
    let sources: ExternalSource[] = [];
    if (
      (isRenewalProposalType(proposal) || isChangeProposalType(proposal)) &&
      proposalObj.customer &&
      'sources' in proposalObj.customer
    ) {
      sources = proposalObj.customer.sources ?? [];
    }
    return sources;
  };
  return (
    <>
      <CopyErrorModal
        error={copyError}
        handleClose={handleClose}
        handleTryWithStrategy={(strategy: CopyProposalStrategy) =>
          doMakeCopy(strategy)
        }
      />

      {isTemplate && proposal.status !== 'draft' && (
        <PopOutMenuOption
          icon={ItemIcon.Proposal}
          title={tk('Create proposal')}
          onClick={() => doMakeCopy()}
          dataTestId="create-proposal"
        />
      )}

      {isTemplate && currentUserAdmin && (
        <PopOutMenuOption
          icon={ItemIcon.ProposalTemplate}
          title={tk('Make a copy')}
          onClick={() => doMakeCopy('makeTemplate')}
        />
      )}

      {!isTemplate && (
        <PopOutMenuOption
          icon={Icon.Copy}
          onClick={() => doMakeCopy()}
          title={tk('Make a copy')}
          dataTestId="make-copy"
        />
      )}

      {!isTemplate && currentUserAdmin && (
        <PopOutMenuOption
          icon={ItemIcon.ProposalTemplate}
          onClick={() => doMakeCopy('makeTemplate')}
          title={tk('Create template')}
        />
      )}
      {!isTemplate && editorContext && (
        <>
          {hasExternalSource ? (
            <PopOutMenuOption
              dataTestId="proposal-unlink-from-crm-button"
              icon={Icon.Unlink}
              onClick={() => setShowConfirm(true)}
              title={tk('Unlink from CRM')}
            />
          ) : (
            <PopOutMenuOption
              dataTestId="proposal-link-to-crm-button"
              icon={Icon.Link}
              onClick={() => setLinkToCrmDrawerOpen(true)}
              title={tk('Link to CRM')}
            />
          )}
          <LinkEntityToCrmDrawer
            fetchOptions={apiClient.getExternalDeals}
            isUpdating={isPending}
            linkEntityToCRM={linkObjectToExternal}
            isOpen={linkToCrmDrawerOpen}
            onClose={() => setLinkToCrmDrawerOpen(false)}
            headerLabel="Link proposal to CRM "
            entityLabel="Deal / Opportunity"
            entityType="proposal"
            defaultSearchValue={proposal.name.slice(0, 3)}
            sources={getSources(proposal)}
          />
          <ConfirmModal
            isOpen={showConfirm}
            onClose={() => setShowConfirm(false)}
            onConfirm={() => unlinkFromCRM()}
          >
            <div>Are you sure you want to unlink crm from proposal?</div>
          </ConfirmModal>
        </>
      )}
    </>
  );
};

export default CopyOptions;
