import { Select } from 'antd';
import { useVisibleProducts } from 'app/src/services/api/products/products';
import { useGetProposalApprovalRules } from 'app/src/services/approvals';

import { LinkButton, Proposal, ProposalApprovalRuleConfig } from 'common';
import React, { useEffect, useState } from 'react';

import {
  getProductRules,
  getRuleInfo,
  GroupOption,
  RuleInfo,
} from '../rules/_approvalRulesConfig';
import { ProductBuilder } from './ProductBuilder';

import styles from './RuleSelector.module.scss';

const ruleComponentsMap = {
  proposalProductDiscountPercent: ProductBuilder,
};

type RuleComponentKey = keyof typeof ruleComponentsMap;

type SelectableProductRules = RuleInfo & {
  id: string;
  rootProductId: string;
};

interface Props {
  allGroupOptions: GroupOption[];
  isPending?: boolean;
  onCancelAdd: () => void;
  onRuleAdded: (addedRule: any) => void;
  proposalType: Proposal['proposalType'];
}

export const ProductRuleSelector = ({
  proposalType,
  onRuleAdded,
  onCancelAdd,
  isPending,
  allGroupOptions,
}: Props) => {
  const [selectedRule, setSelectedRule] =
    useState<SelectableProductRules | null>(null);
  const [selectableRules, setSelectableRules] = useState<
    SelectableProductRules[]
  >([]);

  const { data: proposalApprovalRules } = useGetProposalApprovalRules();
  const { data: products } = useVisibleProducts();

  useEffect(() => {
    const availableRules = getProductRules();
    // for each product we need to create a new rule object based on the available rules
    const selectableProductRules: SelectableProductRules[] = (
      products || []
    ).flatMap((product) => {
      return availableRules.map((rule) => {
        return {
          ...rule,
          name: rule.name,
          description: product.name ?? '',
          id: `${rule.name}-${product.rootId}`,
          rootProductId: product.rootId ?? '',
        };
      });
    });

    setSelectableRules(selectableProductRules);
  }, [proposalType, products]);

  const handleRuleChange = (value: ProposalApprovalRuleConfig['ruleType']) => {
    const selection = selectableRuleOptions.find(
      (option) => option.value === value
    );

    const ruleName = value.split(
      '-'
    )[0] as ProposalApprovalRuleConfig['ruleType'];

    if (ruleName in ruleComponentsMap) {
      const ruleInfo = getRuleInfo(ruleName);

      const updatedSelectedRule = {
        ...ruleInfo,
        selectedProductName: selection?.label,
        name: ruleInfo.name,
        id: selection?.id ?? '',
        rootProductId: selection?.rootProductId ?? '',
      };

      setSelectedRule(updatedSelectedRule);
    }
  };

  const filteredApprovalRulesByProposalType = proposalApprovalRules?.filter(
    (rule) => rule.config?.proposalType === proposalType
  );

  const filteredSelectableRules = selectableRules.filter((selectableRule) => {
    const matchingApprovalRule = filteredApprovalRulesByProposalType?.find(
      (approvalRule) => {
        return approvalRule.config?.ruleType === selectableRule.name;
      }
    );

    return !matchingApprovalRule || matchingApprovalRule.config?.ruleType;
  });

  const selectableRuleOptions = filteredSelectableRules.map((rule) => {
    return {
      ...rule,
      value: rule.id,
      label: rule.description,
    };
  });

  const SelectedRuleComponent =
    selectedRule && selectedRule.name in ruleComponentsMap
      ? ruleComponentsMap[selectedRule.name as RuleComponentKey]
      : null;

  return (
    <div className={styles.ruleSelector}>
      {!selectedRule && (
        <Select
          autoFocus
          showAction={['focus', 'click']}
          showSearch
          placeholder="Add product condition"
          popupMatchSelectWidth={false}
          optionFilterProp="label"
          onChange={handleRuleChange}
          filterOption={(input, option) =>
            option
              ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              : false
          }
          options={selectableRuleOptions}
          className={styles.select}
          data-testid="product-condition-selector"
          popupClassName="product-condition-selector"
        />
      )}

      {SelectedRuleComponent && selectedRule && (
        <SelectedRuleComponent
          createRule={onRuleAdded}
          proposalType={proposalType}
          selectedRule={selectedRule}
          onCancelAdd={onCancelAdd}
          allGroupOptions={allGroupOptions}
        />
      )}

      {!isPending && !selectedRule && (
        <LinkButton className={styles.cancel} onClick={onCancelAdd}>
          cancel
        </LinkButton>
      )}
    </div>
  );
};
