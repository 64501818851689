import React, { SVGProps } from 'react';
import { Button } from 'common';

interface Props {
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  isDisabled?: boolean;
  isSelected?: boolean;
  label: string;
  onClick: () => void;
}

const EditorButton: React.FC<Props> = ({
  icon,
  isDisabled,
  isSelected,
  label,
  onClick,
}: Props) => (
  <Button
    isDisabled={isDisabled}
    label={label}
    onClick={onClick}
    primaryIcon={icon}
    type={isSelected ? 'primary' : 'secondary'}
  />
);

export default EditorButton;
