import React from 'react';
import clsx from 'clsx';
import {
  BILLING_PERIODS,
  BillingPeriodsType,
  BundlePriceEntry,
  formatMoneyOrDefault,
  FormField,
  getBillingPeriodPriceLabel,
  getEntryRequest,
  Icon,
  InfoToolTip,
  LevelRequest,
  Money,
  MoneyInput,
  Nullable,
  ProductRequest,
  ToggleSwitch,
  useFlags,
  useTranslation,
} from 'common';
import { isBillingPeriodEnabled } from './productUtils';
import styles from './PricingConfiguration.module.scss';

interface Props {
  bottomUpEntries?: BundlePriceEntry[];
  currency: string;
  isDisabled: boolean;
  orgEnabledBillingPeriods: BillingPeriodsType[];
  pricingLevelsAreValid: boolean;
  productDraft: Nullable<ProductRequest>;
  removeLevel: (level: number) => void;
  toggleBillingPeriod: (billingPeriod: BillingPeriodsType) => void;
  updateLevelPrice: (
    price: Money,
    billingPeriod?: BillingPeriodsType | undefined,
    level?: number
  ) => void;
}

const DeleteIcon: React.FC<{
  index: number;
  onClick: (index: number) => void;
}> = ({ index, onClick }) => {
  if (index < 1) {
    return <div />;
  }

  return (
    <button
      className={styles.deleteIcon}
      onClick={() => onClick(index)}
      type="button"
    >
      <div data-testid={`level-delete-${index}`}>
        <Icon.Close />
      </div>
    </button>
  );
};

export const PricingConfigurationTable: React.FC<Props> = ({
  bottomUpEntries,
  isDisabled,
  pricingLevelsAreValid,
  productDraft,
  currency,
  removeLevel,
  updateLevelPrice,
  toggleBillingPeriod,
  orgEnabledBillingPeriods,
}: Props) => {
  const { tk } = useTranslation();
  const { entries } = productDraft;
  const hasExtendedDecimals = productDraft.extendedScale || false;
  const priceLevels = productDraft.levels ?? [];
  const priceLevelsSorted: LevelRequest[] = priceLevels.sort((a, b) => {
    return (a.start ?? 0) - (b.start ?? 0);
  });

  const handleLevelPeriodPriceChange = (
    levelIndex: number,
    billingPeriod: BillingPeriodsType | undefined,
    price: Money
  ): void => {
    updateLevelPrice(price, billingPeriod, levelIndex);
  };

  const oneTime: boolean = productDraft.recurrence === 'one_time';
  const priceHeader: string =
    productDraft.pricing === 'stairstep'
      ? oneTime
        ? 'Tier price'
        : 'Monthly tier price'
      : oneTime
        ? 'Unit price'
        : 'Monthly unit price';

  const labelClassName: string = 'text-slate-darkest min-h-[24px]';
  const { negativePriceSupport } = useFlags();

  return (
    <div>
      <div>
        {priceLevelsSorted.map((level, index) => {
          const firstRow: boolean = index === 0;

          const oneTimePrice = getEntryRequest(
            entries,
            currency,
            undefined,
            index
          )?.price;
          const oneTimeBottomUpPrice = getEntryRequest(
            bottomUpEntries,
            currency,
            undefined,
            index
          )?.price;

          return (
            <div
              className={clsx(styles.grid, firstRow && styles.firstRow)}
              key={index}
            >
              {oneTime && (
                <FormField
                  className={styles.pricingTableFormField}
                  key="product-price-one-time"
                  label={priceHeader}
                  labelClassName={labelClassName}
                  showLabel={firstRow}
                >
                  <MoneyInput
                    dataTestId={`level-price-${index}`}
                    defaultCurrency={currency}
                    hasExtendedDecimals={hasExtendedDecimals}
                    isDisabled={isDisabled}
                    isRequiredField
                    negativeSupported={negativePriceSupport}
                    onChange={(money) =>
                      handleLevelPeriodPriceChange(index, undefined, money)
                    }
                    value={oneTimePrice}
                  />
                  {oneTimeBottomUpPrice && (
                    <div className={styles.bottomUpPrice}>
                      Product total:{' '}
                      {formatMoneyOrDefault(oneTimeBottomUpPrice)}
                    </div>
                  )}
                </FormField>
              )}
              {!oneTime && (
                <>
                  {BILLING_PERIODS.map((billingPeriod) => {
                    const periodPrice = getEntryRequest(
                      entries,
                      currency,
                      billingPeriod,
                      index
                    )?.price;
                    const bottomUpPrice = getEntryRequest(
                      bottomUpEntries,
                      currency,
                      billingPeriod,
                      index
                    )?.price;
                    const isOrgEnabled =
                      orgEnabledBillingPeriods.includes(billingPeriod);
                    const periodEnabled = isBillingPeriodEnabled(
                      productDraft,
                      billingPeriod
                    );
                    const periodToggle = firstRow ? (
                      <div className={styles.billingPeriodToggle}>
                        {isOrgEnabled && (
                          <InfoToolTip title="This billing period is active on your org. Disabling it will prevent this product from being added to proposals with default billing period settings." />
                        )}
                        <ToggleSwitch
                          key={`product-period-toggle-${billingPeriod}-${index}`}
                          name={`product-period-toggle-${billingPeriod}`}
                          onChange={() => toggleBillingPeriod(billingPeriod)}
                          value={!!periodEnabled}
                        />
                      </div>
                    ) : null;

                    return (
                      <FormField
                        className={styles.pricingTableFormField}
                        key={`product-price-${billingPeriod}`}
                        label={getBillingPeriodPriceLabel(
                          billingPeriod,
                          productDraft.pricing
                        )}
                        labelChild={periodToggle}
                        labelClassName={labelClassName}
                        showLabel={firstRow}
                      >
                        <MoneyInput
                          dataTestId={`product-price-input-${billingPeriod}-${index}-${currency}`}
                          defaultCurrency={currency}
                          hasExtendedDecimals={hasExtendedDecimals}
                          isDisabled={!periodEnabled}
                          isRequiredField={!!periodEnabled}
                          negativeSupported={negativePriceSupport}
                          onChange={(money) =>
                            handleLevelPeriodPriceChange(
                              index,
                              billingPeriod,
                              money
                            )
                          }
                          value={periodPrice}
                        />
                        {bottomUpPrice && (
                          <div className={styles.bottomUpPrice}>
                            Product total: {formatMoneyOrDefault(bottomUpPrice)}
                          </div>
                        )}
                      </FormField>
                    );
                  })}
                </>
              )}
              <DeleteIcon
                index={index}
                key={level.start}
                onClick={removeLevel}
              />
            </div>
          );
        })}
      </div>
      {!pricingLevelsAreValid && (
        <div className="text-red">
          {tk(
            'Pricing level boundaries need to be integers and non-overlapping.'
          )}
        </div>
      )}
    </div>
  );
};
