import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  AutoFormDrawer,
  BillingSchedule,
  getDatetime,
  getErrorMessage,
  getToday,
  Money,
  PaymentUpdateRequest,
  useToast,
  ValidationFormField,
} from 'common';
import { useUpdatePayment } from 'app/src/services/invoices';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedPayment: BillingSchedule | undefined;
  customerName?: string;
}

interface FormState {
  amount: Money;
  processedAt: string;
  reason: string;
  customerName: string;
  referenceNumber: string;
}

export const InvoicePaymentUpdateDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedPayment,
  customerName,
}: Props) => {
  const queryClient = useQueryClient();

  const fields: ValidationFormField<FormState>[] = [
    customerName && {
      isDisabled: true,
      humanReadableName: 'Customer name',
      fieldName: 'customerName',
      type: 'string',
    },
    {
      humanReadableName: 'Reference number',
      fieldName: 'referenceNumber',
      type: 'string',
    },
    {
      isDisabled: true,
      fieldName: 'amount',
      type: 'money',
      inputProps: {
        currency: selectedPayment?.currency,
      },
    },
    {
      humanReadableName: 'Payment date',
      fieldName: 'processedAt',
      type: 'date',
      isRequired: true,
    },
    {
      humanReadableName: 'Note',
      fieldName: 'reason',
      type: 'textArea',
    },
  ].filter(Boolean) as ValidationFormField<FormState>[];

  const toast = useToast();

  const onSuccess = () => {
    toast.success('Payment updated successfully');
    onClose();
  };

  const onError = (e: unknown) => {
    const error = getErrorMessage(e);
    toast.error(error ?? 'Error while updating payment');
    onClose();
  };

  const { mutate } = useUpdatePayment(
    selectedPayment?.invoiceId!,
    queryClient,
    onSuccess,
    onError
  );

  const handleSave = (data: FormState) => {
    if (!selectedPayment?.invoiceId) {
      return;
    }

    const paymentUpdateRequest: PaymentUpdateRequest = {
      amount: selectedPayment.amountDue,
      paymentDate: getDatetime(data.processedAt),
      reason: data.reason,
      externalId: data.referenceNumber,
    };

    mutate(paymentUpdateRequest);
  };

  return (
    <AutoFormDrawer<FormState>
      queryClient={queryClient}
      entity={{
        amount: {
          amount: selectedPayment?.amountDue,
          currency:
            selectedPayment?.currency ?? selectedPayment?.transfer?.currency,
        },
        customerName,
        processedAt: getToday(),
        referenceNumber: '',
      }}
      hideToastMessage
      isOpen={isOpen}
      onClose={onClose}
      header="Payment update"
      entityName="Transfer event"
      fields={fields}
      onSave={handleSave}
    />
  );
};
