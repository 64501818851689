import React, { SVGProps } from 'react';

const RocketIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M60.42.433C54.88.752 44.9 1.687 40.342 4.382c-5.858 3.466-11.635 9.97-16.299 16.212l-9.3 1.197c-1.795.232-3.357 1.39-4.077 3.027l-2.18 4.955c-.171.393-.152.84.053 1.216.205.377.576.638 1.003.715l6.34 1.101c-.472.786-.873 1.47-1.189 2.018a3.735 3.735 0 0 0 .626 4.527l.354.35-1.503 1.48a3.789 3.789 0 0 0 0 5.41l3.428 3.376a3.89 3.89 0 0 0 2.748 1.121 3.89 3.89 0 0 0 2.747-1.12l1.501-1.479.355.35a3.842 3.842 0 0 0 2.712 1.107c.659 0 1.31-.17 1.886-.491.556-.311 1.251-.707 2.05-1.17l1.118 6.242c.075.42.343.785.726.987.21.112.44.166.67.166.19 0 .382-.038.565-.114l5.032-2.146c1.662-.71 2.84-2.247 3.075-4.014l1.215-9.157c6.343-4.593 12.949-10.282 16.466-16.049 2.74-4.488 3.69-14.314 4.01-19.77a3.713 3.713 0 0 0-1.038-2.812A3.844 3.844 0 0 0 60.42.434V.433ZM11.8 29.263l1.468-3.332a2.34 2.34 0 0 1 1.842-1.367l6.683-.859a148.876 148.876 0 0 0-4.34 6.545l-5.653-.987Zm9.284 18.726c-.396.39-1.088.39-1.484 0l-3.43-3.376a1.022 1.022 0 0 1 0-1.462l1.505-1.478 4.91 4.835-1.5 1.481Zm18.884 1.047a2.312 2.312 0 0 1-1.388 1.814l-3.384 1.445-1-5.563a157.935 157.935 0 0 0 6.647-4.273l-.876 6.577Zm18.063-26.28c-6.495 10.64-24.516 21.265-29.886 24.268-.383.212-.881.142-1.191-.164l-1.358-1.334s0-.002-.002-.002l-6.919-6.812s-.003 0-.003-.003l-1.354-1.334a.969.969 0 0 1-.166-1.172c3.05-5.288 13.837-23.032 24.647-29.427 4.19-2.476 14.535-3.31 18.85-3.556.277 0 .535.109.726.308a.96.96 0 0 1 .268.734c-.249 4.186-1.097 14.37-3.612 18.494Z" />
      <path d="M46.333 11.082c-1.959 0-3.798.75-5.18 2.113a7.142 7.142 0 0 0 0 10.199 7.34 7.34 0 0 0 5.18 2.113 7.33 7.33 0 0 0 5.179-2.113 7.142 7.142 0 0 0 0-10.2 7.324 7.324 0 0 0-5.18-2.113Zm3.176 10.338a4.496 4.496 0 0 1-3.174 1.295c-1.199 0-2.326-.458-3.174-1.295a4.376 4.376 0 0 1 0-6.25 4.496 4.496 0 0 1 3.174-1.296c1.2 0 2.327.458 3.174 1.295a4.376 4.376 0 0 1 0 6.25ZM9.024 47.307a7.858 7.858 0 0 0-5.545 2.26C.684 52.318.646 60.305.668 61.878a1.41 1.41 0 0 0 1.401 1.38h.197c2.855 0 9.768-.27 12.305-2.767a7.61 7.61 0 0 0 2.296-5.46 7.616 7.616 0 0 0-2.296-5.46 7.865 7.865 0 0 0-5.547-2.263Zm3.54 11.208c-1.222 1.203-5.321 1.816-9.046 1.93.105-3.356.711-7.667 1.963-8.903a5.006 5.006 0 0 1 3.54-1.443c1.338 0 2.595.513 3.54 1.443a4.853 4.853 0 0 1 1.465 3.485 4.83 4.83 0 0 1-1.463 3.488Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h65v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default RocketIcon;
