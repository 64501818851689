import React from 'react';
import { SALESFORCE_INFO, salesforceTabs as tabs } from './SalesforceConstants';
import { accountContactsConfig } from './SalesforceConstants/AccountContactsConfig';
import ConnectorSettings from '../Connectors/ConnectorSettings';
import SalesforceLogoSVG from '../../../assets/integration-logos/salesforceLogoSVG';
import { ValidationFormField } from 'common/src';

const SalesforceSettings = () => {
  const elements: ValidationFormField<any>[] = [
    {
      fieldName: 'syncSignedDocument',
      humanReadableName:
        'Push subscription information to Salesforce. The Cacheflow for Salesforce package must be installed in the organization.',
      type: 'toggle',
    },
  ];
  return (
    <ConnectorSettings
      accountContactsConfig={accountContactsConfig}
      connectorInfo={SALESFORCE_INFO}
      tabs={tabs}
      logo={SalesforceLogoSVG}
      configuration={{
        name: null,
        lines: elements,
      }}
    />
  );
};

export default SalesforceSettings;
