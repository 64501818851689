import { Contact, Drawer, Proposal } from 'common';
import React from 'react';
import ContactForm from '../../../Sales/Customers/ContactForm';

interface Props {
  contactBeingEdited: Contact | undefined;
  isOpen: boolean;
  onClose: () => void;
  proposal: Proposal;
}

const EditContactDrawer: React.FC<Props> = ({
  onClose,
  contactBeingEdited,
  proposal,
  isOpen,
}) => {
  const customerId = proposal.customer?.id || '';

  return (
    <Drawer isOpen={isOpen} header="Edit contact" onClose={onClose}>
      <ContactForm
        onClose={onClose}
        contactToEdit={contactBeingEdited}
        customerId={customerId}
        onSaveSuccess={onClose}
        hideDeleteButton
        isBeingViewed={isOpen}
      />
    </Drawer>
  );
};

export default EditContactDrawer;
