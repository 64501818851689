import React from 'react';
import clsx from 'clsx';
import styles from './Pill.module.scss';

const Pill = ({
  label,
  type,
  isDisabled,
}: {
  isDisabled?: boolean;
  label: string;
  type: 'error' | 'info';
}) => (
  <div
    className={clsx(
      styles.pill,
      isDisabled && styles.disabled,
      type === 'error' && styles.error,
      type === 'info' && styles.info
    )}
  >
    <p>{label}</p>
  </div>
);

export default Pill;
