import { Card, CardHeader, Customer } from 'common';
import React from 'react';
import InvoiceTable from '../../../Invoices/InvoiceTable';

interface Props {
  customer: Customer;
}

const CustomerInvoiceCard: React.FC<Props> = ({ customer }) => {
  const baseValues = customer.id
    ? [
        [
          {
            key: 'customer.id',
            value: customer.id,
            comparator: ':',
          },
        ],
      ]
    : undefined;

  return (
    <Card>
      <CardHeader name="Invoices" />
      <InvoiceTable
        baseSearchValues={baseValues}
        filters={[
          {
            type: 'search',
            key: 'search',
            searchFields: ['invoiceNumber'],
          },
        ]}
        excludeCols={['customer.name']}
      />
    </Card>
  );
};

export default CustomerInvoiceCard;
