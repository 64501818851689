import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { QuickbooksAccount } from 'common';

export const useGetAllAccounts = (): UseQueryResult<QuickbooksAccount[]> =>
  useQuery({
    queryKey: ['quickbooks-accounts'],
    queryFn: async () => {
      const { data } = await apiClient.getAccountsForQuickbooks();
      return data;
    },
  });

const syncSelectedBillingSchedules = async (scheduleIds: string[]) => {
  const { data } = await apiClient.syncBillingSchedules(scheduleIds);
  return data;
};

export const useResyncSelectedSchedules = (
  onSuccess: () => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationKey: ['quickbooks-resync-selected-invoices'],
    mutationFn: syncSelectedBillingSchedules,
    onSuccess,
    onError,
  });
