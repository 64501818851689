import React, { useState } from 'react';
import { AttachmentConfig, Button } from 'common';
import AttachmentsTable from './AttachmentsTable';
import CreateAttachmentConfig from './CreateAttachmentConfig';
import EditAttachmentConfig from './EditAttachmentConfig';
import Page from '../../../components/Page';

const Attachments: React.FC = () => {
  const [addingNewAttachment, setAddingNewAttachment] = useState(false);
  const [attachmentBeingEdited, setAttachmentBeingEdited] = useState<
    AttachmentConfig | undefined
  >(undefined);

  return (
    <Page
      leftWidget="Attachments"
      rightWidget={
        <Button
          onClick={() => setAddingNewAttachment(true)}
          label="Add new attachment"
          dataTestId="add-attachment-button"
        />
      }
    >
      {addingNewAttachment && (
        <CreateAttachmentConfig
          isShowing={addingNewAttachment}
          onClose={() => setAddingNewAttachment(false)}
          onCreateSuccess={(config) => {
            setAddingNewAttachment(false);
            setAttachmentBeingEdited(config);
          }}
        />
      )}
      {!!attachmentBeingEdited && (
        <EditAttachmentConfig
          isShowing={!!attachmentBeingEdited}
          attachmentConfig={attachmentBeingEdited}
          onSaveSuccess={() => setAttachmentBeingEdited(undefined)}
          onDeleteSuccess={() => setAttachmentBeingEdited(undefined)}
          onClose={() => setAttachmentBeingEdited(undefined)}
        />
      )}
      <div className="max-w-[1000px]">
        <AttachmentsTable onClickAttachment={setAttachmentBeingEdited} />
      </div>
    </Page>
  );
};

export default Attachments;
