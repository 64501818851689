import React, { useEffect, useState } from 'react';
import { useIsSuperAdmin } from '../../../core-utils/helperFunctions/userServiceHelper';
import {
  BrandingConfig,
  CardHeader,
  Checkbox,
  ColorInput,
  CompanyInfo,
  defaultBrand,
  formatHumanReadable,
  FormField,
  getCompanyBranding,
  Input,
  InputError,
  isBoolean,
  isString,
  Label,
  Select,
  SelectOption,
  Tabs,
  ThemeConfig,
  ToggleSwitch,
  ValidationError,
} from 'common';
import Sample from './Sample';
import { ENVIRONMENT_CONFIG } from '../../../../config/hosts';

interface Props {
  companyInfo: CompanyInfo;
  onBrandingChange: (newBranding: any) => void;
  onBrandingEnabledChange: (enabled: boolean) => void;
}

enum BrandingTabs {
  backgrounds = 'backgrounds',
  brand = 'brand',
  buttons = 'buttons',
  code = 'code',
  controls = 'controls',
}

const FieldSet = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) => (
  <div>
    <Label text={label} />
    <div className="h-[1px] bg-gray-lines mb-2" />
    <div className="grid grid-cols-2 gap-x-2">{children}</div>
  </div>
);

const backgroundImageSizeOptions: SelectOption<string>[] = [
  { value: 'auto', name: 'Auto' },
  { value: 'cover', name: 'Cover' },
  { value: 'contain', name: 'Contain' },
  { value: '100% auto', name: 'Full width' },
  { value: 'auto 100%', name: 'Full height' },
];

const backgroundImagePositionOptions: SelectOption<string>[] = [
  { value: 'top left', name: 'Top left' },
  { value: 'top center', name: 'Top center' },
  { value: 'top right', name: 'Top right' },
  { value: 'center left', name: 'Center left' },
  { value: 'center', name: 'Center' },
  { value: 'center right', name: 'Center right' },
  { value: 'bottom left', name: 'Bottom left' },
  { value: 'bottom center', name: 'Bottom center' },
  { value: 'bottom right', name: 'Bottom right' },
];

const CheckoutBranding: React.FC<Props> = ({
  companyInfo,
  onBrandingEnabledChange,
  onBrandingChange,
}) => {
  const [error, setError] = useState<ValidationError>(null);
  const [companyBranding, setCompanyBranding] = useState<BrandingConfig>({});
  const [brandingJson, setBrandingJson] = useState<string | undefined>(
    JSON.stringify({})
  );
  const isSuperAdmin = useIsSuperAdmin();

  const tabs = [
    { key: BrandingTabs.brand, label: 'Brand colors' },
    { key: BrandingTabs.backgrounds, label: 'Backgrounds' },
    { key: BrandingTabs.buttons, label: 'Buttons' },
    { key: BrandingTabs.controls, label: 'Controls' },
    { key: BrandingTabs.code, label: 'Code' },
  ];

  const [activeTab, setActiveTab] = useState<string>(BrandingTabs.brand);

  if (!isSuperAdmin) {
    return null;
  }

  useEffect(() => {
    if (companyInfo.branding) {
      setCompanyBranding(getCompanyBranding(companyInfo) || {});
      setBrandingJson(JSON.stringify(getCompanyBranding(companyInfo), null, 2));
    }
  }, [companyInfo]);

  const getSection = (
    brandingObj: BrandingConfig,
    section: keyof BrandingConfig
  ): Record<string, boolean | string | undefined> => brandingObj[section] || {};

  function getSectionString(
    brandingObj: BrandingConfig,
    section: keyof BrandingConfig,
    field: string
  ): string | undefined {
    const sectionValue = getSection(brandingObj, section);
    const sectionFieldValue = sectionValue[field];

    if (isString(sectionFieldValue)) {
      return sectionFieldValue;
    }

    return undefined;
  }

  function getSectionBoolean(
    brandingObj: BrandingConfig,
    section: keyof BrandingConfig,
    field: string
  ): boolean | undefined {
    const sectionValue = getSection(brandingObj, section);
    const sectionFieldValue = sectionValue[field];

    if (isBoolean(sectionFieldValue)) {
      return sectionFieldValue;
    }

    return undefined;
  }

  const getPlaceholderValue = (
    section: keyof BrandingConfig,
    field: string
  ): string | undefined => getSectionString(defaultBrand, section, field);

  const getString = (
    section: keyof BrandingConfig,
    field: string
  ): string | undefined => getSectionString(companyBranding, section, field);

  const getBoolean = (
    section: keyof BrandingConfig,
    field: string
  ): boolean | undefined => getSectionBoolean(companyBranding, section, field);

  const onFieldColorChange = (
    section: keyof BrandingConfig,
    field: string,
    newValue: boolean | string | undefined
  ) =>
    onBrandingChange(
      // This removes undefined values and keeps the rest valid JSON
      JSON.parse(
        JSON.stringify({
          ...companyBranding,
          [section]: {
            ...getSection(companyBranding, section),
            [field]: newValue,
          },
        })
      )
    );

  const renderField = (section: keyof BrandingConfig, field: string) => (
    <FormField label={formatHumanReadable(field)}>
      <ColorInput
        dataTestId={`${section}-${field}`}
        onChange={(newValue: string | undefined) => {
          onFieldColorChange(section, field, newValue);
        }}
        placeholder={getPlaceholderValue(section, field)}
        value={getString(section, field)}
      />
    </FormField>
  );

  const renderInput = (section: keyof BrandingConfig, field: string) => (
    <FormField label={formatHumanReadable(field)}>
      <Input
        dataTestId={`${section}-${field}`}
        onChange={(newValue: string | undefined) => {
          onFieldColorChange(section, field, newValue);
        }}
        placeholder={getPlaceholderValue(section, field) || 'URL'}
        value={getString(section, field)}
      />
    </FormField>
  );

  const renderSelect = (
    section: keyof BrandingConfig,
    field: string,
    options: SelectOption<string>[],
    placeholder: string
  ) => (
    <Select<string>
      dataTestId={`${section}-${field}`}
      onChange={(newValue) => {
        onFieldColorChange(section, field, newValue);
      }}
      onClearSelected={() => {
        onFieldColorChange(section, field, undefined);
      }}
      options={options}
      placeholder={placeholder}
      value={getString(section, field)}
    />
  );

  const renderCheckbox = (section: keyof BrandingConfig, field: string) => (
    <Checkbox
      dataTestId={`${section}-${field}`}
      label={formatHumanReadable(field)}
      name={field}
      onChange={(newValue) => {
        onFieldColorChange(section, field, newValue);
      }}
      value={getBoolean(section, field) || false}
    />
  );

  const fgBgSection = (section: keyof BrandingConfig, label: string) => (
    <FieldSet key={section} label={label}>
      {renderField(section, 'backgroundColor')}
      {renderField(section, 'foregroundColor')}
    </FieldSet>
  );

  const fgBgImgSection = (section: keyof BrandingConfig, label: string) => (
    <FieldSet key={section} label={label}>
      {renderField(section, 'backgroundColor')}
      {renderField(section, 'foregroundColor')}
      {renderInput(section, 'backgroundImage')}
      <FormField label="Background positioning">
        <div style={{ display: 'flex', gap: '4px', marginBottom: '4px' }}>
          {renderSelect(
            section,
            'backgroundImageSize',
            backgroundImageSizeOptions,
            'cover'
          )}
          {renderSelect(
            section,
            'backgroundImagePosition',
            backgroundImagePositionOptions,
            'top center'
          )}
        </div>
        {renderCheckbox(section, 'backgroundImageRepeat')}
      </FormField>
    </FieldSet>
  );

  const getTabContent = () => {
    switch (activeTab) {
      case BrandingTabs.brand:
        return (
          <div>
            <FieldSet label="Brand colors">
              {renderField('colors', 'primary')}
              {renderField('colors', 'primaryBackground')}
              {renderField('colors', 'success')}
              {renderField('colors', 'error')}
            </FieldSet>
          </div>
        );
      case BrandingTabs.backgrounds:
        return (
          <div>
            {fgBgImgSection('window', 'Background')}
            {fgBgImgSection('paper', 'Body')}
            {fgBgImgSection('header', 'Header')}
          </div>
        );
      case BrandingTabs.buttons:
        return (
          <div>
            {fgBgSection('buttonPrimary', 'Primary buttons')}
            {fgBgSection('buttonSecondary', 'Secondary buttons')}
          </div>
        );
      case BrandingTabs.controls:
        return (
          <div>
            {fgBgSection('option', 'Proposal options')}
            {fgBgSection('bar', 'Schedule bars')}
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-[400px] text-xs border-[1px]"
            onChange={(elm) => {
              try {
                const newBranding = JSON.parse(elm.currentTarget.value);
                onBrandingChange(newBranding);
                setError(null);
              } catch (e) {
                setError('Invalid JSON');
              }
              setBrandingJson(elm.currentTarget.value);
            }}
            value={brandingJson}
          />
        );
    }
  };

  return (
    <>
      <CardHeader name="Custom Branding">
        <ToggleSwitch
          dataTestId="custom-branding-toggle"
          label="Enable"
          onChange={(checked) => onBrandingEnabledChange(checked)}
          value={companyInfo.brandingEnabled}
        />
      </CardHeader>
      <ThemeConfig
        brandingEnabled
        companyInfo={companyInfo}
        isProduction={ENVIRONMENT_CONFIG.isProduction}
      >
        <div>
          <Tabs
            defaultActiveTab={activeTab}
            onChange={(tab) => {
              setActiveTab(tab);
            }}
            tabs={tabs}
          />
          <div className="grid grid-cols-2 gap-6">
            {getTabContent()}
            <Sample
              companyInfo={companyInfo}
              key={JSON.stringify(companyInfo)}
            />
          </div>
        </div>
      </ThemeConfig>
      <InputError errorToShow={error} />
    </>
  );
};

export default CheckoutBranding;
