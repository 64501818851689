import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Accordion,
  ApprovalRequirements,
  ApprovalRuleResult,
  Button,
  Drawer,
  FormField,
  getErrorMessage,
  Icon,
  Loading,
  log,
  noop,
  Proposal,
  ProposalApprovalRequest,
  TextArea,
  User,
  useToast,
  useTranslation,
} from 'common';

import { useGetAllApprovalGroups } from 'app/src/services/api/approvalGroups/approvalGroups';
import { useCreateProposalApprovalRequest } from '../../../../services/proposal';
import { apiClient } from 'app/src/services/httpClients/app';
import VerifyProposal from '../ProposalShare/VerifyProposal';
import { useValidateProposal } from '../proposalUtilities';

import { GroupUsersRow } from './GroupUsersRow';

import styles from './RequestApprovalDrawer.module.scss';

type RulesByGroup = Record<
  string,
  {
    groupId: string;
    groupName?: string;
    rules: ApprovalRuleResult[];
  }
>;

type GroupMap = Record<
  string,
  {
    groupId: string;
    groupName: string;
  }
>;

interface RequestApprovalDrawerProps {
  closeDrawer: () => void;
  isShowing: boolean;
  proposal: Proposal;
  requirements: ApprovalRequirements;
}

export const RequestApprovalDrawer = ({
  proposal,
  isShowing,
  closeDrawer,
  requirements,
}: RequestApprovalDrawerProps) => {
  const { tk } = useTranslation();
  const showToast = useToast();
  const queryClient = useQueryClient();
  const { isValid } = useValidateProposal(proposal);

  const { data: allApprovalGroups, isLoading: isApprovalGroupsLoading } =
    useGetAllApprovalGroups();

  const [draft, setDraft] = useState<ProposalApprovalRequest>({
    message: '',
  });

  const onClose = () => {
    closeDrawer();
  };

  const handleCopyEmailsToClipboard = async (groupId: string) => {
    const { data } = await apiClient.getUsersByGroupId(groupId);

    const emailList = data.map((user: User) => user.username).join(',');

    await navigator.clipboard.writeText(emailList);
    showToast.info('Emails copied to clipboard!');
  };

  const handleCreateSuccess = () => {
    showToast.info('Approval requested.');
    onClose();
  };

  const handleCreateError = (responseError: unknown) => {
    const responseMsg =
      getErrorMessage(responseError) ||
      'There was an issue making the request. Please try again.';

    showToast.error(responseMsg);
  };

  const { mutate: requestApproval } = useCreateProposalApprovalRequest(
    proposal.id,
    handleCreateSuccess,
    handleCreateError,
    queryClient
  );

  const submit = () => {
    requestApproval({ ...draft });
  };

  if (isApprovalGroupsLoading) {
    return <Loading />;
  }

  if (!isValid) {
    return (
      <VerifyProposal
        isOpen={isShowing}
        onClose={onClose}
        proposal={proposal}
      />
    );
  }

  const groupMap = (allApprovalGroups || []).reduce<GroupMap>((acc, group) => {
    if (group.id && group.group?.id && group.group.name) {
      acc[group.id] = {
        groupName: group.group.name,
        groupId: group.group.id,
      };
    }

    return acc;
  }, {});

  const requiredRules = requirements.ruleResults?.filter(
    (r: ApprovalRuleResult) => r.required
  );

  const rulesByGroupObject = (requiredRules || []).reduce<RulesByGroup>(
    (acc, rule: ApprovalRuleResult) => {
      rule.groups?.forEach((groupId: string) => {
        if (!acc[groupId]) {
          acc[groupId] = {
            // @ts-ignore TODO: Fix
            groupName: groupMap[groupId].groupName,
            // @ts-ignore TODO: Fix
            groupId: groupMap[groupId].groupId,
            rules: [],
          };
        }

        const ruleDetails = {
          id: rule.id,
          name: rule.name,
          description: rule.description,
          ruleType: rule.ruleType,
        };

        // check if the current ruleDetails already exists in the group to avoid dups
        // @ts-ignore TODO: Fix
        const isDuplicate = acc[groupId].rules.some(
          (r) =>
            r.name === ruleDetails.name &&
            r.description === ruleDetails.description &&
            r.ruleType === ruleDetails.ruleType
        );

        if (!isDuplicate) {
          // @ts-ignore TODO: Fix
          acc[groupId].rules.push(ruleDetails);
        }
      });

      return acc;
    },
    {}
  );

  // Convert the grouped object back into an array
  const rulesOrganizedByGroup = Object.values(rulesByGroupObject);

  return (
    <Drawer header={tk('Approval')} isOpen={isShowing} onClose={onClose}>
      <div className={styles.requestApprovalDrawer}>
        <div>
          <p className={styles.section}>Required approvals</p>
          <p>Click group to see approvers.</p>
        </div>

        <div className={styles.ruleCards}>
          {rulesOrganizedByGroup.map((group) => {
            return (
              <div className={styles.ruleCard} key={group.groupId}>
                <Accordion
                  expandIconPosition="start"
                  header={
                    <span className={styles.ruleGroupName}>
                      {group.groupName}
                      <Icon.Copy
                        className={styles.copyIcon}
                        height={22}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyEmailsToClipboard(group.groupId)
                            .then(noop)
                            .catch(log.error);
                        }}
                        width={22}
                      />
                    </span>
                  }
                >
                  <GroupUsersRow group={group} />
                </Accordion>
                <ul>
                  {group.rules.map((rule) => {
                    return <li key={rule.id}>{rule.description}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>

        <FormField
          className={styles.section}
          label={tk('Message to approvers')}
        >
          <TextArea
            className={styles.textArea}
            dataTestId="approval-request-message"
            onChange={(value) =>
              setDraft({
                ...draft,
                message: value,
              })
            }
            onEnter={submit}
            value={draft.message}
          />
        </FormField>

        <div className={styles.buttonColumn}>
          <Button
            className={styles.primary}
            dataTestId="send-approval-request"
            label={tk('Send approval request')}
            onClick={submit}
          />
          <Button
            className={styles.secondary}
            label={tk('Cancel')}
            onClick={onClose}
            type="link"
          />
        </div>
      </div>
    </Drawer>
  );
};
