import {
  QueryClient,
  QueryObserverResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { OrganizationSummary, ReferenceKey, TokenResponse } from 'common';

import { apiClient } from '../../httpClients/app';
import { authAxios } from '../../httpClients/auth';

const GET_USER_PROFILE = '/api/latest/profile/me';
const GET_SSO_REDIRECT = `${GET_USER_PROFILE}/sso_redirect`;
const USER_PROFILE_KEY_SAVE = 'useUserProfileSave';
const AUTH_TOKEN_PATH = '/security/session';

export const USER_PROFILE_KEY = 'useUserProfile';

export interface OrganizationProfile {
  id?: string;
  name?: string;
}

interface Code {
  type: string;
  value: string; // preview, invite, hotp, totp
}

interface AuthRequest {
  code?: Code;
  email?: string;
  referenceScope: ReferenceKey;
}

export const userProfileGet = async () => {
  const { data } = await apiClient.getUserProfile();
  return data;
};

export const useGetMyOrgs = (): QueryObserverResult<OrganizationSummary[]> =>
  useQuery<OrganizationSummary[]>({
    queryKey: [],
    queryFn: async () => {
      const { data } = await apiClient.getIdentityOrglist();
      return data;
    },
  });

export const useSsoRedirect = (
  enabled: boolean,
  ssoSource: string,
  returnTo?: string
) =>
  useQuery({
    queryKey: [GET_SSO_REDIRECT, ssoSource],
    queryFn: async () => {
      /* eslint-disable camelcase */
      const params = {
        sso_source: ssoSource,
        ...(returnTo ? { return_to: returnTo } : {}),
      };
      /* eslint-enable camelcase */

      const { data } = await apiClient.getSsoRedirect(params);
      return data;
    },
    enabled,
  });

const saveOrganizationName = async ({ ...postData }: any) => {
  const { data } = await apiClient.updateOrganization(postData.id, postData);
  return data;
};

export const userSupportTokenGet = async () => {
  const { data } = await apiClient.getSupportToken();
  return data;
};

export const useSaveOrganizationInfo = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [USER_PROFILE_KEY_SAVE],
    mutationFn: saveOrganizationName,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [USER_PROFILE_KEY] });
    },
  });

export const createAuthTokenRequest = (orgId: string, token: string) => {
  const params: AuthRequest = {
    referenceScope: {
      referenceId: orgId,
      referenceType: 'organization',
    },
    code: {
      value: token,
      type: 'orgless_bearer',
    },
  };

  return params;
};

const exchangeToken = async (params: AuthRequest) => {
  const url = AUTH_TOKEN_PATH;
  const { data } = await authAxios.post(url, params);
  return data as TokenResponse;
};

export const useAuthTokenExchange = (
  onSuccess: (data: TokenResponse) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [AUTH_TOKEN_PATH],
    mutationFn: exchangeToken,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [USER_PROFILE_KEY] });
    },
  });
