import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { ActivityEvent, Refund, TransferEvent, useToast } from 'common';

import { apiClient } from './httpClients/app';

export enum TransferStatus {
  cancelled = 'Cancelled',
  declined = 'Declined',
  failed = 'Failed',
  in_progress = 'In progress',
  pending = 'Pending',
  posted = 'Posted',
}

export type TransferReferenceType = 'billingschedule' | 'invoice';

interface TransferEventRequest {
  transferEvent: TransferEvent;
  transferId: string;
}

const sendTransferEvent = async (
  transferId: string,
  transferEvent: TransferEvent
) => {
  const { data } = await apiClient.createTransferEvent(
    transferId,
    transferEvent
  );
  return data;
};

export const useSendTransferEvent = (
  transferId: string,
  detailLink: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`${detailLink}/transactions`],
    mutationFn: (data: TransferEvent) => sendTransferEvent(transferId, data),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries();
    },
  });

export const usePostTransferEvent = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`/transactions`],
    mutationFn: ({ transferId, transferEvent }: TransferEventRequest) =>
      sendTransferEvent(transferId, transferEvent),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries();
    },
  });

const postRefundSync = async (refundId: string): Promise<Refund> => {
  const { data } = await apiClient.syncRefund(refundId);

  return data;
};

export const usePostRefundSync = (
  args?: Parameters<typeof useMutation<Refund, unknown, unknown>>[0]
) => {
  const showToast = useToast();

  return useMutation({
    mutationKey: ['refund-sync'],
    mutationFn: (refundId: string) => postRefundSync(refundId),
    onSuccess: () => {
      showToast.success('Refund sync initiated successfully.');
    },
    onError: () => {
      showToast.error('Refund sync failed.');
    },
    ...args,
  });
};

export const useTransactionEvents = (
  id: string,
  detailLink: string
): UseQueryResult<TransferEvent[]> =>
  useQuery({
    queryKey: [`${detailLink}/transactions`, { status: 'events', id }],
    queryFn: async () => {
      const { data } = await apiClient.listTransferEvents(id);
      return data;
    },
    enabled: !!id,
  });

export const useRefundActivity = (
  id: string
): UseQueryResult<ActivityEvent[]> =>
  useQuery({
    queryKey: [`refunds/${id}/activity`],
    queryFn: async () => {
      const { data } = await apiClient.getRefundActivity(id);
      return data;
    },
    enabled: !!id,
  });

export const useTransferActivity = (
  id: string
): UseQueryResult<ActivityEvent[]> =>
  useQuery({
    queryKey: [`transfers/${id}/activity`],
    queryFn: async () => {
      const { data } = await apiClient.listTransferActivity(id);
      return data;
    },
    enabled: !!id,
  });

export const useInvoiceActivity = (
  id: string
): UseQueryResult<ActivityEvent[]> =>
  useQuery({
    queryKey: [`invoices/${id}/activity`],
    queryFn: async () => {
      const { data } = await apiClient.getInvoiceActivity(id);
      return data;
    },
    enabled: !!id,
  });

const triggerTransferProcess = async (id: string) => {
  const { data } = await apiClient.triggerTransferRequest(id);
  return data;
};

export const useTriggerTransferProcess = (
  detailLink: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`${detailLink}/transactions`],
    mutationFn: triggerTransferProcess,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries();
    },
  });

const triggerDue = async (params: {
  id: string;
  type: TransferReferenceType;
}) => {
  const { data } = await apiClient.triggerBilling(params.id);
  return data;
};

export const useTriggerDue = (
  detailLink: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`${detailLink}/transactions`],
    mutationFn: triggerDue,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries();
    },
  });
