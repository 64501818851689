import React from 'react';
import { PaymentMethod } from 'common';
import clsx from 'clsx';

interface Props {
  internalNameClassName?: string;
  method?: PaymentMethod;
}

const PaymentMethodName: React.FC<Props> = ({
  method,
  internalNameClassName,
}) => {
  if (!method) return null;
  return (
    <>
      {method.name}
      {method.name !== method.internalName && (
        <span className={clsx(internalNameClassName, 'ml-2 text-slate')}>
          ({method.internalName})
        </span>
      )}
    </>
  );
};

export default PaymentMethodName;
