import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../httpClients/app';
import { systemClient } from '../../httpClients/systemclient';

const LOGOUT_KEY = 'logout';

export const logoutGet = () =>
  useQuery({
    queryKey: [LOGOUT_KEY],
    queryFn: async () => {
      const { data } = await apiClient.logout();
      return data;
    },
    enabled: false,
    gcTime: 0,
  });

export const adminLogoutGet = () =>
  useQuery({
    queryKey: [LOGOUT_KEY],
    queryFn: async () => {
      const { data } = await systemClient.logout();
      return data;
    },
    enabled: false,
    gcTime: 0,
  });
