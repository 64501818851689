import React from 'react';

import {
  AutoRenewalConfig,
  NotificationEventConfiguration,
  OrgConfig,
  useDebounce,
} from 'common';

import {
  Notifications,
  NotificationSendOptions,
  NotificationTargetOptions,
} from './Notifications';

interface Props {
  data: AutoRenewalConfig;
  mutateAutoRenewal: (data: OrgConfig) => void;
}

export const AutoRenewalNotifications = ({
  data,
  mutateAutoRenewal,
}: Props) => {
  const { debouncedFunction: debouncedMutateAutoRenewal, clear: clearMutate } =
    useDebounce((updatedNotifications) => {
      mutateAutoRenewal({
        configKey: 'autoRenewalConfig',
        configValue: {
          ...data,
          notificationConfigurations: updatedNotifications,
        } as any,
      });
    }, 1000);

  const notifications = data.notificationConfigurations;

  const defaultNotification: NotificationEventConfiguration = {
    beforeDays: 30,
    notificationType: 'email',
    targetGroups: ['billing_contact'],
    eventType: 'auto_renewal_reminder',
  };

  const notificationSendOptions: Array<NotificationSendOptions> = [
    {
      value: 'email',
      label: 'Email',
    },
  ];

  const notificationTargetOptions: Array<NotificationTargetOptions> = [
    {
      value: 'billing_contact',
      label: 'Billing contact',
    },
    {
      value: 'accepting_contact',
      label: 'Accepting contact',
    },
  ];

  return (
    <Notifications
      data={notifications}
      daysBeforeText="renewal"
      defaultNotification={defaultNotification}
      maxNotifications={3}
      notificationSendOptions={notificationSendOptions}
      notificationTargetOptions={notificationTargetOptions}
      onSave={debouncedMutateAutoRenewal}
      onSaveClear={clearMutate}
    />
  );
};
