import React from 'react';
import './EventStyles.scss';

interface EventCardProps {
  count: number | undefined;
  title: string;
}

const EventCard: React.FC<EventCardProps> = ({ count, title }) => {
  return (
    <div className="mainCard">
      <div className="innerLeftElement">
        <h1>{count}</h1>
      </div>
      <div className="innerRightElement">
        <h3>{title}</h3>
      </div>
    </div>
  );
};

export default EventCard;
