import React, { useState } from 'react';
import { Select } from 'antd';

import {
  ApprovalRule,
  Button,
  ConfirmDeleteModal,
  Icon,
  Input,
  ProposalCountryRuleConfig,
  ProposalSummary,
} from 'common';

import { GroupOption, searchFilterOption } from './_approvalRulesConfig';

import styles from './ApprovalRuleStyles.module.scss';
import { getConfig } from './ruleUtils';

const DEFAULT_TEXT = '';

interface Props {
  allGroupOptions: GroupOption[];
  approvalRule: ApprovalRule | undefined;
  conditionOperator: string | string[];
  configKey: string;
  description: string;
  onRuleChange: (id: string, changedRule: ApprovalRule | null) => void;
  proposalType: ProposalSummary['proposalType'];
}

export const ApprovalRuleTextRow = ({
  approvalRule,
  configKey,
  description,
  onRuleChange,
  proposalType,
  conditionOperator,
  allGroupOptions,
}: Props) => {
  const getValue = (): string => {
    const config = getConfig<ProposalCountryRuleConfig>(approvalRule);

    const configValue =
      config && typeof config === 'object' && configKey in config
        ? config[configKey as keyof ProposalCountryRuleConfig]
        : undefined;

    return typeof configValue === 'string' ? configValue : DEFAULT_TEXT;
  };

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const [value, setValue] = useState<string>(getValue());
  const [showSelectError, setShowSelectError] = useState<boolean>(false);

  const updateRule = (newValue: string): ApprovalRule => ({
    id: approvalRule?.id,
    name: approvalRule?.name,
    config: {
      ruleType: approvalRule?.config?.ruleType!,
      proposalType,
      [configKey]: newValue,
    },
  });

  const handleValueChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleSave = () => {
    onRuleChange(approvalRule?.id!, updateRule(value));
  };

  const onGroupsChange = (groupIds: string[]) => {
    setShowSelectError(groupIds.length === 0);

    const payload = {
      ...updateRule(value),
      approvalGroupIds: groupIds,
    };

    onRuleChange(approvalRule?.id!, payload);
  };

  return (
    <div className={styles.ruleRow}>
      <div className={styles.name}>
        <Button
          primaryIcon={Icon.CloseCircle}
          onClick={() => setIsDeleteConfirmationOpen(true)}
          type="secondary"
          className={styles.removeIcon}
        />
        <span className={styles.ruleName}>{description}</span>
      </div>
      <div className={styles.condition}>{conditionOperator}</div>
      <div className={styles.input}>
        <Input
          value={value}
          isDisabled={!approvalRule}
          onChange={handleValueChange}
          onBlur={handleSave}
        />
      </div>
      <div className={styles.groups}>
        {allGroupOptions.length > 0 && (
          <Select
            onChange={onGroupsChange}
            value={approvalRule?.approvalGroupIds?.map((id) => id)}
            options={allGroupOptions}
            mode="multiple"
            className={styles.groupWidth}
            filterOption={searchFilterOption}
            placeholder="Select approval group"
            disabled={!approvalRule}
            status={showSelectError ? 'error' : ''}
          />
        )}
      </div>

      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onConfirm={() => onRuleChange(approvalRule?.id!, null)}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        typeName="rule"
      />
    </div>
  );
};
