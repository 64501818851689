import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ENVIRONMENT_CONFIG } from '../../../../config/hosts';
import {
  DocusignAccount,
  DocusignEnvelopeQuota,
  DocusignUser,
  pluralize,
  SigningDocumentViewResponse,
} from 'common';
import { apiClient } from '../../httpClients/app';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

const DOCUSIGN_DEMO_SERVER = 'https://account-d.docusign.com';
const DOCUSIGN_PROD_SERVER = 'https://account.docusign.com';
const CONSENT_REDIRECT_PATH = '/docusign/callback';
const CONSENT_SCOPES = 'openid%20signature%20impersonation';

export const useDocuSignAccount = (): UseQueryResult<
  DocusignAccount | undefined
> => {
  return useQuery({
    queryKey: ['apiClient.getDocusignAccount'],
    queryFn: async () => {
      const { data } = await apiClient.getDocusignAccount();
      return data;
    },
  });
};

export const useDocusignEnvelopeQuota = (
  enabled: boolean
): UseQueryResult<DocusignEnvelopeQuota | undefined> => {
  return useQuery({
    queryKey: ['apiClient.getDocusignEnvelopeQuota'],
    queryFn: async () => {
      const { data } = await apiClient.getDocusignEnvelopeQuota();
      return data;
    },
    enabled,
  });
};

export const useDocusignEnvelopeQuotaStatus: () => {
  isLoading: boolean;
  isError: boolean;
  envelopeQuotaStatus: { quotaDescription: string; quotaStatus: string };
} = () => {
  const { data: account, isLoading: isAccountLoading } = useDocuSignAccount();
  const {
    data: docusignQuota,
    isLoading: isQuotaLoading,
    isError,
  } = useDocusignEnvelopeQuota(!!account);
  const [envelopeQuotaStatus, setEnvelopeQuotaStatus] = useState({
    quotaStatus: '',
    quotaDescription: '',
  });

  useEffect(() => {
    if (docusignQuota) {
      if (docusignQuota.blocked) {
        setEnvelopeQuotaStatus({
          quotaStatus: 'Envelope creation blocked by Docusign',
          quotaDescription:
            'Your buyers will be unable to successfully sign Docusign documents.',
        });
      } else if (!docusignQuota.unlimitedQuota) {
        if (docusignQuota.allowed && docusignQuota.sent) {
          const remaining = docusignQuota.allowed - docusignQuota.sent;
          if (remaining >= 0 && remaining < 10) {
            setEnvelopeQuotaStatus({
              quotaStatus: 'Remaining Docusign envelopes low',
              quotaDescription: `You have ${pluralize(remaining, 'envelope')} remaining. This could prevent your buyers from signing Docusign documents.`,
            });
          }
        }
      }
    }
  }, [docusignQuota]);

  return {
    envelopeQuotaStatus,
    isLoading: isAccountLoading || isQuotaLoading,
    isError,
  };
};

// Note: this doesn't cache, because it is used to test the DocuSign setup.
export const getDocuSignUser = async (): Promise<DocusignUser> => {
  const { data } = await apiClient.checkDocusignUser();
  return data;
};

export const doDocuSignSetup = async (
  code: string
): Promise<AxiosResponse<void>> => {
  // TODO: fix this on server to be typed to return string?
  return await apiClient.setupDocusignAccount({ code });
};

export const getConsentURL = () => {
  const server =
    ENVIRONMENT_CONFIG.isProduction && ENVIRONMENT_CONFIG.env !== 'sandbox'
      ? DOCUSIGN_PROD_SERVER
      : DOCUSIGN_DEMO_SERVER;
  const redirectUri = `${window.location.protocol}//${window.location.host}${CONSENT_REDIRECT_PATH}`;
  const integrationKey = ENVIRONMENT_CONFIG.dsIntegrationKey;
  return `${server}/oauth/auth?response_type=code&scope=${CONSENT_SCOPES}&client_id=${integrationKey}&redirect_uri=${redirectUri}`;
};

export const getView = async (
  proposalId: string
): Promise<SigningDocumentViewResponse> => {
  const { data } = await apiClient.getSigningView(proposalId, {
    returnUrl: window.location.href,
  });

  return data;
};

export const getDiagnostics = async (proposalId: string): Promise<any> => {
  const { data } = await apiClient.getSigningDiagnostics(proposalId);
  return data;
};
