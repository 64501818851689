import React from 'react';
import clsx from 'clsx';
import {
  Button,
  isChangeProposalType,
  isDraftProposalStatus,
  isInTerminalProposalStatus,
  PaymentMethod,
  PaymentSchedule,
  Proposal,
  ProposalLabel,
  useFlags,
  useTranslation,
} from 'common';
import PaymentPlanOption from './PaymentPlanOption';

interface Props {
  allPaymentMethods: PaymentMethod[];
  focusedPaymentSchedule: PaymentSchedule;
  isDisabled: boolean;
  openPaymentOptionsEditPanel: () => void;
  proposal: Proposal;
  selectPaymentPlanIndex: (index: number) => void;
}

const PaymentPlanOptions: React.FC<Props> = ({
  allPaymentMethods,
  focusedPaymentSchedule,
  isDisabled,
  openPaymentOptionsEditPanel,
  proposal,
  selectPaymentPlanIndex,
}) => {
  const { tk } = useTranslation();
  const { enableChangeProposalPaymentMethods } = useFlags();
  // NOTE: change proposals have changeable accepted schedule,
  // if enableChangeProposalPaymentMethods flag is true
  // but are not in 'accepted' status.
  const disallowScheduleSelection = enableChangeProposalPaymentMethods
    ? isInTerminalProposalStatus(proposal)
    : isChangeProposalType(proposal) || isInTerminalProposalStatus(proposal);

  const optionClasses = clsx({
    'plan-set-options': true,
    accepted: disallowScheduleSelection,
  });

  const acceptedSchedule: PaymentSchedule | undefined = proposal.schedules.find(
    (paymentSchedule) =>
      proposal.acceptedScheduleId &&
      paymentSchedule.id === proposal.acceptedScheduleId
  );

  const isAcceptedSchedule =
    enableChangeProposalPaymentMethods && !isChangeProposalType(proposal);

  if (isAcceptedSchedule && acceptedSchedule) {
    return (
      <div className={optionClasses}>
        <div className="label-wrapper">
          <ProposalLabel name={tk('Billing period')} />
        </div>

        <PaymentPlanOption
          key={acceptedSchedule.id}
          allPaymentMethods={allPaymentMethods}
          allowScheduleSelection={false}
          focusedPaymentSchedule={acceptedSchedule}
          paymentSchedule={acceptedSchedule}
          proposal={proposal}
          scheduleIndex={0} // Not used when accepted
          selectPaymentPlanIndex={() => {}}
        />
      </div>
    );
  }

  return (
    <div className={optionClasses}>
      <div className="label-wrapper">
        <h3 className="billing-periods-labels">{tk('Billing periods')}</h3>
        {isDraftProposalStatus(proposal) && (
          <Button
            type="secondary"
            size="small"
            label={tk('Edit')}
            isDisabled={isDisabled}
            onClick={openPaymentOptionsEditPanel}
          />
        )}
      </div>

      {proposal.schedules.map((paymentSchedule, scheduleIndex) => {
        return (
          <PaymentPlanOption
            key={paymentSchedule.id}
            allowScheduleSelection={!disallowScheduleSelection}
            allPaymentMethods={allPaymentMethods}
            focusedPaymentSchedule={focusedPaymentSchedule}
            paymentSchedule={paymentSchedule}
            proposal={proposal}
            scheduleIndex={scheduleIndex}
            selectPaymentPlanIndex={selectPaymentPlanIndex}
          />
        );
      })}
    </div>
  );
};

export default PaymentPlanOptions;
