import React, { useState } from 'react';
import { ClearLegacyModal } from './ClearLegacyModal';
import { Button } from 'common';

export const ClearLegacyCheckoutButton: React.FC<{}> = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <ClearLegacyModal handleClose={() => setOpen(false)} isOpen={open} />
      <Button
        dataTestId="clear-legacy-checkout-button"
        isDangerous
        label="Clear Legacy Checkout"
        onClick={() => setOpen(true)}
      />
    </div>
  );
};
