import clsx from 'clsx';
import { Proposal } from 'common';
import React, { useState } from 'react';
import { isLocalOrDevEnvironment } from '../../../../../config/hosts';
import DebuggerSVG from '../../../../assets/internal/DebuggerSVG';
import ProposalBasics from './ProposalBasics';
import styles from './proposalDebugger.module.scss';
import ProposalDeltas from './ProposalDeltas';

interface Props {
  proposal: Proposal;
}

const ProposalDebugger: React.FC<Props> = ({ proposal }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  if (!proposal.id || !isLocalOrDevEnvironment()) {
    return null;
  }

  return (
    <div className={styles.proposalDebugger}>
      <button type="button" onClick={togglePanel} className={styles.openButton}>
        <DebuggerSVG />
      </button>
      <div
        className={clsx(styles.debuggerPanel, isOpen && styles.debuggerOpen)}
      >
        <button type="button" onClick={togglePanel} className={styles.closeBtn}>
          ×
        </button>
        <div className={styles.panelContent}>
          {isOpen && (
            <>
              <ProposalBasics proposal={proposal} />
              <ProposalDeltas proposal={proposal} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProposalDebugger;
