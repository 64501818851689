import React from 'react';

import { Card, CardHeader, Loading, OrgConfig } from 'common';

import OrgConfigToggle from '../OrgConfigToggle';
import { useFindOrgDefault } from 'app/src/services/orgDefaults';
import { ApprovalGroups } from './groups/ApprovalGroups';
import { ApprovalRules } from './rules/ApprovalRules';

const configKey: OrgConfig['configKey'] = 'areApprovalsEnabled';

export const ApprovalsPage = () => {
  const { data: orgApprovalSetting, isLoading } = useFindOrgDefault(configKey);

  const isApprovalsEnabled = !!orgApprovalSetting?.intValue;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader className="!text-lg" name="Proposal approvals">
        <OrgConfigToggle
          dataTestId="enable-approvals"
          orgConfigKey={configKey}
        />
      </CardHeader>

      {isApprovalsEnabled ? (
        <>
          <ApprovalRules />
          <ApprovalGroups />
        </>
      ) : (
        <div>Enable approvals for proposals.</div>
      )}
    </Card>
  );
};
