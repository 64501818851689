import React from 'react';
import {
  AutoFormModal,
  Unit,
  useTranslation,
  ValidationFormField,
} from 'common';
import { apiClient } from 'app/src/services/httpClients/app';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSaveSuccess?: (saved: Unit) => void;
  singularUnitStarterName?: string;
}

const getFreshDraft = (singularUnitStarterName?: string): Unit => ({
  name: singularUnitStarterName || '',
  plural: '',
});

const UnitForm: React.FC<Props> = ({
  onClose,
  isOpen,
  onSaveSuccess,
  singularUnitStarterName,
}) => {
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const entity = getFreshDraft(singularUnitStarterName);
  const fields: ValidationFormField<Unit>[] = [
    {
      fieldName: 'name',
      humanReadableName: tk('Name'),
      isRequired: true,
    },
    {
      fieldName: 'plural',
      humanReadableName: tk('Plural'),
      isRequired: true,
    },
  ];

  return (
    <AutoFormModal<Unit>
      entity={entity}
      entityName="unit"
      fields={fields}
      isOpen={isOpen}
      onClose={onClose}
      onSave={apiClient.createUnit}
      onSuccess={onSaveSuccess}
      queryClient={queryClient}
    />
  );
};

export default UnitForm;
