import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  ConnectorSetup,
  getErrorMessage,
  Loading,
  log,
  useToast,
  useTranslation,
} from 'common';
import {
  useGetConnectionTest,
  useSaveAuthorizeConnector,
} from '../../../services/connectors';
import { INTEGRATIONS_AND_CONNECTORS } from '../../../core-utils/routes';

const ConnectorAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { connectorType } = useParams<{
    connectorType: ConnectorSetup['type'];
  }>();
  const urlParams = new URLSearchParams(location.search);
  const authCode = urlParams.get('code') || undefined;
  const stateTokenKey = urlParams.get('state') || undefined;
  const [isError, setIsError] = useState(false);
  const isOnMountCalled = useRef(0);
  // const [isDisabled, setIsDisabled] = useState(false);
  const showToast = useToast();
  // const flags = useFlags();
  const { tk } = useTranslation();

  const { data: connectionResult } = useGetConnectionTest(connectorType);

  const { mutate: saveAuthorization } = useSaveAuthorizeConnector(
    connectorType,
    () => {
      navigate(`/settings/${connectorType}`, { replace: true });
    },
    (error: unknown) => {
      showToast.error(
        tk(
          getErrorMessage(error) ?? 'Failed to authenticate. Please try again!'
        )
      );
      setIsError(true);
    }
  );

  const setAuthorization = useCallback(async (body: ConnectorSetup) => {
    log.debug('setAuthorization ' + isOnMountCalled.current, stateTokenKey);
    // if (connectorType && !(flags as any)[connectorType]) return;
    if (isOnMountCalled.current > 0) {
      return;
    }
    isOnMountCalled.current += 1;

    if (connectionResult?.status === 'active') return;
    return await saveAuthorization(body);
  }, []);

  useEffect(() => {
    log.debug('use effect ' + isOnMountCalled.current, stateTokenKey);
    log.debug('connector type ', connectorType);

    const body: ConnectorSetup = {
      type: connectorType,
      code: authCode,
      state: stateTokenKey,
    };

    const config: ConnectorSetup['config'] = {};

    for (const [key, value] of urlParams.entries()) {
      if (key !== 'state' && key !== 'code') {
        config[key] = value;
      }
    }

    setAuthorization({
      ...body,
      config,
    }).catch((err) => log.error(err));
  }, []);

  if (isError) {
    return <Navigate replace to={INTEGRATIONS_AND_CONNECTORS} />;
  }

  return <Loading />;
};

export default ConnectorAuthCallback;
