import React from 'react';
import {
  CreateProposalRequest,
  Nullable,
  PaymentSchedule,
  PaymentTerm,
  Proposal,
  useTranslation,
} from 'common';
import ProposalSection from '../ProposalSection/ProposalSection';
import PaymentPlanWrapper from '../../PaymentPlan/PaymentPlanWrapper';

interface Props {
  isDisabled: boolean;
  paymentSchedule: PaymentSchedule;
  proposal: Proposal;
  selectBillTo: (billTo?: string) => void;
  selectPaymentPlanIndex: (index: number) => void;
  selectPaymentTerm?: (paymentTerm: PaymentTerm['termType'] | null) => void;
  selectShipFrom: (shipFrom?: string) => void;
  setProposalField: (
    field: keyof CreateProposalRequest,
    value: Nullable<CreateProposalRequest>[keyof CreateProposalRequest]
  ) => void;
}

const PaymentPlanSection: React.FC<Props> = ({
  selectPaymentPlanIndex,
  selectPaymentTerm,
  selectBillTo,
  selectShipFrom,
  isDisabled,
  paymentSchedule,
  proposal,
  setProposalField,
}) => {
  const { tk } = useTranslation();

  return (
    <ProposalSection
      section="Payments"
      sectionRef="Payments"
      title={tk('Payments')}
      openOnLoad
      isDisabled={isDisabled}
      isRemovable={false}
    >
      <PaymentPlanWrapper
        paymentSchedule={paymentSchedule}
        proposal={proposal}
        isDisabled={isDisabled}
        selectPaymentTerm={selectPaymentTerm}
        selectPaymentPlanIndex={selectPaymentPlanIndex}
        selectBillTo={selectBillTo}
        selectShipFrom={selectShipFrom}
        setProposalField={setProposalField}
      />
    </ProposalSection>
  );
};

export default PaymentPlanSection;
