import React from 'react';
import { Tabs } from 'common';
import { Page } from 'app/src/components';
import SystemInvoiceReconciliation from './SystemInvoiceReconciliation';
import SystemPaymentReconciliation from './SystemPaymentReconciliation';
import { systemClient } from 'app/src/services/httpClients/systemclient';
import ReconciliationSummary from './ReconciliationSummary';

const SystemReconciliation = () => {
  const getReconciliationSummary = async () => {
    const response = await systemClient.getSystemReconciliationSummary();
    return response.data;
  };

  return (
    <Page
      leftWidget={
        <span className="eventPageHeading">
          <h1 className="innerHeading">QuickBooks reconciliation</h1>
        </span>
      }
    >
      <ReconciliationSummary
        getReconciliationSummary={getReconciliationSummary}
      />
      <Tabs
        tabs={[
          {
            label: 'Invoices',
            key: 'invoices',
            component: <SystemInvoiceReconciliation />,
          },
          {
            label: 'Payments',
            key: 'payments',
            component: <SystemPaymentReconciliation />,
          },
        ]}
        defaultActiveTab="invoices"
      />
    </Page>
  );
};

export default SystemReconciliation;
