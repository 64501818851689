import React from 'react';

import { Icon, useTranslation } from 'common';

interface Props {
  messageCode?: string;
}

const WarningWidget: React.FC<Props> = ({ messageCode }) => {
  const { tk } = useTranslation();

  return (
    <div className="flex flex-row items-center justify-center text-red">
      <div>
        <Icon.Alert width={30} height={30} />
      </div>
      {messageCode && <div className="text-center">{tk(messageCode)}</div>}
    </div>
  );
};
export default WarningWidget;
