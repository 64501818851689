import React from 'react';
import {
  BillingPeriodsType,
  Button,
  ButtonBar,
  Item,
  ItemOverride,
  mergeTierLinesToCombinedSchedule,
  Proposal,
  ScheduleLine,
  useTranslation,
} from 'common';
import TierAdjustmentScheduleOverview from './TierAdjustmentScheduleOverview';
import { getLevelPrice } from './utils';
import styles from './TBDTier.module.scss';

interface Props {
  billingPeriod: BillingPeriodsType;
  draftSchedule?: ScheduleLine[];
  item: Item;
  onClose: () => void;
  overrideItem?: ItemOverride;
  proposal: Proposal;
  resetSchedule: () => void;
  submit: () => void;
}

const TimeBasedPricingTieredFooter = ({
  onClose,
  submit,
  resetSchedule,
  proposal,
  draftSchedule,
  item,
  billingPeriod,
  overrideItem,
}: Props) => {
  const { tk } = useTranslation();

  const { product, quantity } = item;
  const mergedSchedule = draftSchedule
    ? mergeTierLinesToCombinedSchedule(draftSchedule)
    : [];
  const basePrices =
    item.levels?.map((_level, index) => {
      return getLevelPrice(
        product.entries,
        proposal.currency,
        billingPeriod,
        index,
        overrideItem?.levels
      );
    }) || [];

  // if the schedule has more than one override or
  // the leveled override price/qty is different from the billing period base level price
  // then we have changes
  const hasChanges =
    mergedSchedule.length > 1 ||
    mergedSchedule[0]?.levels?.some(
      (level, index) =>
        level.price.amount !== basePrices[index]?.billingPeriodMoney?.amount
    ) ||
    mergedSchedule[0]?.quantity !== quantity;

  return (
    <div className={styles.timeBasedOverview}>
      <div className={styles.timeBasedOverviewContent}>
        <h3 className={styles.productName}>{item.name}</h3>
        <TierAdjustmentScheduleOverview
          basePrices={basePrices}
          billingPeriod={billingPeriod}
          entries={product.entries}
          mergedSchedule={mergedSchedule}
          pricing={product.pricing}
          tiers={product.levels}
        />
      </div>
      <ButtonBar>
        {hasChanges ? (
          <Button
            block
            dataTestId="reset-schedule-price-change"
            label={tk('Reset')}
            onClick={resetSchedule}
            type="secondary"
          />
        ) : (
          <Button
            block
            dataTestId="cancel-schedule-price-change"
            label={tk('Cancel')}
            onClick={onClose}
            type="secondary"
          />
        )}
        <Button
          block
          dataTestId="update-schedule-price-change"
          label={tk('Schedule')}
          onClick={submit}
        />
      </ButtonBar>
    </div>
  );
};

export default TimeBasedPricingTieredFooter;
