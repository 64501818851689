import React, { useEffect, useState } from 'react';

import {
  alphaNumericValidator,
  CreateApiWebhookRequest,
  FormField,
  FormModal,
  getUrlValidator,
  Input,
  isDefined,
  Loading,
  Select,
  useFormValidation,
  useToast,
  useTranslation,
  WebhookMetadata,
} from 'common';
import { useQueryClient } from '@tanstack/react-query';
import {
  useCreateAPIWebhook,
  useWebhooksMetadata,
} from '../../../../services/api/webhook/webhook';

const getFreshDraft = (): CreateApiWebhookRequest => ({
  name: '',
  url: '',
  eventType: 'proposal_updated' as CreateApiWebhookRequest['eventType'],
});

const AddWebhook: React.FC<{
  closeModal: () => void;
  open: boolean;
}> = ({ open, closeModal }) => {
  const queryClient = useQueryClient();
  const showToast = useToast();
  const { tk } = useTranslation();

  const {
    data: metadata,
    isPending: isLoadingMetadata,
    isError: isErrorLoadingMetadata,
  } = useWebhooksMetadata();

  const [draft, setDraft] = useState<CreateApiWebhookRequest>(getFreshDraft());
  const [referenceType, setReferenceType] =
    useState<WebhookMetadata['referenceType']>('proposal');

  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<CreateApiWebhookRequest>(
      [
        {
          fieldName: 'name',
          isRequired: true,
          humanReadableName: 'Name',
          validator: alphaNumericValidator,
        },
        {
          fieldName: 'url',
          isRequired: true,
          humanReadableName: 'URL',
          validator: getUrlValidator(true),
        },
        {
          fieldName: 'eventType',
          isRequired: true,
        },
      ],
      draft
    );

  useEffect(() => {
    if (open) {
      setDraft(getFreshDraft());
      resetUIState();
    }
  }, [open]);

  const handleSubmitError = () => {
    showToast.error(tk('Failed to create webhook. Please try again later.'));
  };

  const handleSubmitSuccess = () => {
    closeModal();
    showToast.info(tk('Successfully created webhook.'));
  };

  const { mutate } = useCreateAPIWebhook(
    handleSubmitSuccess,
    handleSubmitError,
    queryClient
  );

  const submitIfValid = () => {
    if (isFormValid) {
      submitHandler();
    }
  };

  const submitHandler = () => {
    mutate(draft);
  };

  const eventTypeHandler = (newEventType: WebhookMetadata['eventType']) => {
    const newReferenceType = metadata?.webhooks?.find(
      (item) => item.eventType === newEventType
    )?.referenceType;

    setReferenceType(newReferenceType);
    setDraft((prev) => ({
      ...prev,
      eventType: newEventType!,
    }));
  };

  return (
    <FormModal
      entityTypeName="webhook"
      isNewEntity
      isOpen={open}
      isSubmitDisabled={!isFormValid}
      onClose={() => closeModal()}
      onSubmit={submitHandler}
      width={600}
    >
      <>
        {isLoadingMetadata && <Loading />}
        {isErrorLoadingMetadata && (
          <div
          // TODO: error component
          >
            Error loading webhook options. Please try again later.
          </div>
        )}
        {!isLoadingMetadata && !isErrorLoadingMetadata && (
          <div className="flex flex-col p-[24px 24px 0px 24px] w-full">
            <FormField errorToShow={getErrorToShow('name')} label={tk('Name')}>
              <Input
                onBlur={() => setHasVisitedField('name')}
                onChange={(value) =>
                  setDraft((prev) => ({ ...prev, name: value }))
                }
                onEnter={submitIfValid}
                placeholder="Enter webook name"
                value={draft.name}
              />
            </FormField>
            <FormField
              errorToShow={getErrorToShow('url')}
              label={tk('Endpoint URL')}
            >
              <Input
                onBlur={() => setHasVisitedField('url')}
                onChange={(value) =>
                  setDraft((prev) => ({ ...prev, url: value }))
                }
                onEnter={submitIfValid}
                placeholder="https://demo.com"
                value={draft.url}
              />
            </FormField>
            <div className="grid grid-cols-2 gap-6 items-center">
              <FormField label={tk('Event type')}>
                <Select<WebhookMetadata['eventType']>
                  className="w-full"
                  onChange={eventTypeHandler}
                  options={(metadata.webhooks || [])
                    .map((webhook) => webhook.eventType)
                    .filter(isDefined)
                    .map((eventType) => ({
                      value: eventType,
                      name: eventType,
                    }))}
                  placeholder="Event"
                  value={draft.eventType}
                />
              </FormField>
              <div className="mt-2">
                <span className="font-semibold mr-2">{tk('Resource')}:</span>
                {referenceType}
              </div>
            </div>
          </div>
        )}
      </>
    </FormModal>
  );
};

export default AddWebhook;
