import { createButton } from 'react-social-login-buttons';

import React from 'react';
import HubspotSVG from '../../../assets/integration-logos/hubspotSVG';
import { SIZE_SMALL } from 'common';

const icon = () => <HubspotSVG {...SIZE_SMALL} />;

const config = {
  text: 'Log in with HubSpot',
  icon,
  activeStyle: { background: 'var(--slate-lightest)' },
  style: {
    background: 'white',
    color: 'black',
  },
};

const HubspotLoginButton = createButton(config);

export default HubspotLoginButton;
