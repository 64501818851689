import React, { useState } from 'react';
import { Button, CardHeader, log, Select, StripeConnectResponse } from 'common';
import { useConnectStripeAccount } from '../../../services/api/stripe/stripe';
import {
  countryCurrencies,
  usePaymentCountries,
} from '../../../services/api/stripe/countries';

interface Props {
  isDisabled?: boolean;
  setError: (error: unknown) => void;
  setRoutingToStripe: (cond: boolean) => void;
}

const StripeCreateCard: React.FC<Props> = ({
  isDisabled,
  setRoutingToStripe,
  setError,
}) => {
  const [country, setCountry] = useState<string>('US');
  const [currencies, setCurrencies] = useState<string[]>(['usd']);
  const [currency, setCurrency] = useState<string>('usd');
  const { data: countries } = usePaymentCountries();

  const { mutate, isPending: isLoading } = useConnectStripeAccount(
    (data: StripeConnectResponse) => {
      log.debug(data);
      window.location.href = data.url!;
    },
    (err: unknown) => {
      setError(err);
    }
  );

  const handledropDownChange = async (value: typeof country) => {
    setCountry(value);
    await countryCurrencies(value).then((currs) => {
      setCurrencies(currs);
    });
  };

  const handleStripeButtonClick = () => {
    setRoutingToStripe(true);
    mutate({ country, currency });
  };

  const handleCurrencyDropDownChange = (value: string) => {
    setCurrency(value);
  };

  return (
    <div className="border rounded border-gray-lines w-1/2 m-1 p-4 flex flex-col justify-between">
      <CardHeader name="Create a new Stripe account" />
      <p>
        Register for a new account Stripe account that will be managed by
        Cacheflow.{' '}
        <span className="italic">
          Transaction fees will be applied prior to payout.
        </span>
      </p>

      {countries && (
        <div className="flex flex-col pt-6 pb-6">
          <label htmlFor="stripe_country_select" className="mb-2 font-semibold">
            Select country
          </label>
          <Select<typeof country>
            onChange={handledropDownChange}
            value={country}
            dataTestId="cc-country-selection"
            options={countries.map((c) => ({
              value: c.code,
              name: c.name,
            }))}
          />
        </div>
      )}

      <div className="flex flex-col pb-6">
        <label htmlFor="stripe_currency_select" className="mb-2 font-semibold">
          Settlement currency
        </label>
        <Select
          onChange={handleCurrencyDropDownChange}
          value={currency}
          dataTestId="cc-currency-selection"
          options={currencies.map((c) => ({ value: c, name: c }))}
        />
      </div>

      <Button
        dataTestId="setup-stripe-button"
        isDisabled={isDisabled}
        isLoading={isLoading}
        label="Create new account"
        onClick={handleStripeButtonClick}
      />
    </div>
  );
};

export default StripeCreateCard;
