import React, { useEffect, useState } from 'react';
import {
  BillingPeriodsType,
  BundlePriceEntry,
  Button,
  isNumber,
  LevelRequest,
  Money,
  Nullable,
  ProductRequest,
  Tabs,
  useTranslation,
} from 'common';
import { usePaymentMethods } from '../../../../services/paymentMethods';
import { PricingConfigurationTable } from './PricingConfigurationTable';
import { PricingConfigurationTableFixed } from './PricingConfigurationTableFixed';
import ExtendedDecimalPricingToggle from './ExtendedDecimalPricingToggle';
import ManageCurrencyDrawer from './ManageCurrencyDrawer';
import { getEntriesWithUpdate } from './productUtils';
import ProductRangeInputs from './ProductRangeInputs';
import styles from './PricingConfiguration.module.scss';

interface Props {
  bottomUpEntries?: BundlePriceEntry[];
  isDisabled: boolean;
  productDraft: Nullable<ProductRequest>;
  setPricingIsValid: (isValid: boolean) => void;
  updateProductDraft: (productRequest: Nullable<ProductRequest>) => void;
}

const HeaderWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const description =
    'Configure the product’s price for annual, semi-annual, quarterly, and monthly billing periods.';
  return (
    <div className={styles.pricingConfiguration}>
      <div className="mb-2 font-medium">Price configuration</div>
      <div className="mb-4 text-xs text-slate-darkest">{description}</div>

      <div>{children}</div>
    </div>
  );
};

export const PricingConfiguration: React.FC<Props> = ({
  bottomUpEntries,
  isDisabled,
  productDraft,
  updateProductDraft,
  setPricingIsValid,
}: Props) => {
  const { tk } = useTranslation();
  const [isAddCurrencyDrawerOpen, setIsAddCurrencyDrawerOpen] = useState(false);
  const [pricingLevelsAreValid, setPricingLevelsAreValid] = useState(true);
  const [orgEnabledBillingPeriods, setOrgEnabledBillingPeriods] = useState<
    BillingPeriodsType[]
  >([]);

  const isFixed: boolean = productDraft.pricing === 'fixed';
  const fixedBilling: boolean = productDraft.billing === 'fixed';

  useEffect(() => {
    setPricingIsValid(pricingLevelsAreValid);
  }, [pricingLevelsAreValid]);

  useEffect(() => {
    if (!isFixed && productDraft.levels?.length === 0) {
      updateLevels([
        {
          start: 0,
        },
      ]);
    }
  }, [productDraft.pricing]);

  const { data: paymentMethods } = usePaymentMethods();

  useEffect(() => {
    if (paymentMethods) {
      const bp = paymentMethods.reduce((acc: BillingPeriodsType[], pm) => {
        if (pm.billingPeriods) {
          acc.push(...pm.billingPeriods);
        }
        return acc;
      }, []);
      setOrgEnabledBillingPeriods(bp);
    }
  }, [paymentMethods]);

  const updatePricebookEntry = (
    price: Money,
    billingPeriod?: BillingPeriodsType | undefined,
    level?: number
  ) => {
    updateProductDraft({
      ...productDraft,
      entries: getEntriesWithUpdate(
        productDraft.entries ?? [],
        price,
        billingPeriod,
        level
      ),
    });
  };

  const toggleBillingPeriod = (billingPeriod: BillingPeriodsType) => {
    if (productDraft.billingPeriods?.find((bp) => bp === billingPeriod)) {
      updateProductDraft({
        ...productDraft,
        billingPeriods: productDraft.billingPeriods.filter(
          (bp) => bp !== billingPeriod
        ),
        entries: productDraft.entries?.filter(
          (e) => e.billingPeriod !== billingPeriod
        ),
      });
    } else {
      updateProductDraft({
        ...productDraft,
        billingPeriods: fixedBilling
          ? [billingPeriod]
          : [...(productDraft.billingPeriods ?? []), billingPeriod],
        entries: fixedBilling
          ? productDraft.entries?.filter(
              (e) => e.billingPeriod === billingPeriod
            )
          : productDraft.entries,
      });
    }
  };

  const updatePeriodPrice = (
    price: Money,
    billingPeriod: BillingPeriodsType
  ) => {
    updatePricebookEntry(price, billingPeriod);
  };

  const updateLevelPrice = (
    price: Money,
    billingPeriod?: BillingPeriodsType | undefined,
    level?: number
  ) => updatePricebookEntry(price, billingPeriod, level);

  const removeLevel = (index: number) => {
    const updatedEntries = productDraft.entries
      ? productDraft.entries
          .filter((e) => isNumber(e.level) && e.level !== index)
          .map((e) =>
            !isNumber(e.level) || e.level < index
              ? { ...e }
              : { ...e, level: e.level - 1 }
          )
      : undefined;

    updateProductDraft({
      ...productDraft,
      levels: [
        ...(productDraft.levels ?? []).slice(0, index),
        ...(productDraft.levels ?? []).slice(index + 1),
      ],
      entries: updatedEntries,
    });
  };

  const updateLevels = (levelsUpdate: LevelRequest[]) => {
    updateProductDraft({ ...productDraft, levels: levelsUpdate });
  };
  const currencyTabs =
    productDraft.currencies?.map((currency) => ({
      label: currency,
      key: currency,
      dataTestId: currency + '-currency-tab',
      component: isFixed ? (
        <PricingConfigurationTableFixed
          bottomUpEntries={bottomUpEntries}
          isDisabled={isDisabled}
          productDraft={productDraft}
          currency={currency}
          updatePrice={updatePricebookEntry}
          updatePeriodPrice={updatePeriodPrice}
          toggleBillingPeriod={toggleBillingPeriod}
          orgEnabledBillingPeriods={orgEnabledBillingPeriods}
        />
      ) : (
        <PricingConfigurationTable
          bottomUpEntries={bottomUpEntries}
          isDisabled={isDisabled}
          pricingLevelsAreValid={pricingLevelsAreValid}
          productDraft={productDraft}
          currency={currency}
          removeLevel={removeLevel}
          updateLevelPrice={updateLevelPrice}
          toggleBillingPeriod={toggleBillingPeriod}
          orgEnabledBillingPeriods={orgEnabledBillingPeriods}
        />
      ),
    })) || [];

  const decimalPricingToggle = () => {
    return (
      <ExtendedDecimalPricingToggle
        isDisabled={isDisabled}
        product={productDraft}
        updateProductDraft={updateProductDraft}
      />
    );
  };

  const currencyTabBar = (
    <Tabs
      tabs={currencyTabs}
      tabBarExtraContent={{
        right: (
          <Button
            className={styles.addCurrencyButton}
            onClick={() => {
              setIsAddCurrencyDrawerOpen(true);
            }}
            dataTestId="manage-currencies"
            label={tk('+ Manage currency')}
            type="link"
          />
        ),
      }}
    />
  );

  if (isFixed) {
    return (
      <HeaderWrapper>
        <ManageCurrencyDrawer
          isOpen={isAddCurrencyDrawerOpen}
          onClose={() => setIsAddCurrencyDrawerOpen(false)}
          productCurrencies={productDraft.currencies ?? []}
          saveProductCurrencies={(currencies) => {
            updateProductDraft({
              ...productDraft,
              currencies,
            });
          }}
        />
        {decimalPricingToggle()}
        <div className={styles.currencyWrapper}>{currencyTabBar}</div>
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper>
      <ManageCurrencyDrawer
        isOpen={isAddCurrencyDrawerOpen}
        onClose={() => setIsAddCurrencyDrawerOpen(false)}
        productCurrencies={productDraft.currencies ?? []}
        saveProductCurrencies={(currencies) => {
          updateProductDraft({
            ...productDraft,
            currencies,
          });
        }}
      />

      {decimalPricingToggle()}
      <div className={styles.currencyWrapper}>
        <ProductRangeInputs
          productDraft={productDraft}
          updateLevels={updateLevels}
          isDisabled={isDisabled}
          setPricingLevelsAreValid={setPricingLevelsAreValid}
        />
        {currencyTabBar}
      </div>
    </HeaderWrapper>
  );
};
