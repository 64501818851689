import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useIsSuperAdmin } from '../../core-utils/helperFunctions/userServiceHelper';
import { isPosted } from './utils';
import { TransferStatus, useSendTransferEvent } from '../../services/transfer';
import {
  BillingSchedule,
  Button,
  ButtonBar,
  Drawer,
  FormField,
  getDatetime,
  getErrorMessage,
  Input,
  MoneyInput,
  Select,
  Transaction,
  Transfer,
  TransferEvent,
  useFormValidation,
  useToast,
  useTranslation,
} from 'common';

interface Props {
  billingSchedule?: BillingSchedule;
  detailLink: string;
  isOpen: boolean;
  onCloseForm: () => void;
  transfer?: Transaction | Transfer;
}

const PaymentForm: React.FC<Props> = ({
  billingSchedule,
  isOpen,
  onCloseForm,
  detailLink,
  transfer,
}) => {
  const isSuperAdmin = useIsSuperAdmin();
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const showToast = useToast();

  const [draft, setDraft] = useState<Partial<TransferEvent> | undefined>();

  const handleSuccess = () => {
    showToast.success('Success');
    closeForm();
  };

  const handleError = (error: unknown) => {
    const msg = getErrorMessage(error);
    showToast.error(`Transfer send failed: ${msg}`);
  };

  const { mutate: sendTransferEvent } = useSendTransferEvent(
    transfer?.id!,
    detailLink,
    handleSuccess,
    handleError,
    queryClient
  );

  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<TransferEvent>(
      [
        {
          fieldName: 'amount',
          isRequired: true,
          humanReadableName: 'Amount',
        },
        {
          fieldName: 'status',
          isRequired: true,
          humanReadableName: 'Status',
        },
        {
          fieldName: 'reason',
          isRequired: false,
          humanReadableName: 'Failure reason',
        },
        {
          fieldName: 'processedAt',
          isRequired: false,
          humanReadableName: 'Processed at',
          validator: (value: string) => {
            try {
              const a = new Date(value);
              if (a.getTime() > 0) {
                return null;
              } else {
                return 'Invalid Date';
              }
            } catch (e: any) {
              return e.message;
            }
          },
        },
      ],
      draft || {}
    );

  useEffect(() => {
    if (transfer) {
      const defaultState = {
        amount: transfer.amount,
        status: transfer.status,
        reason: '',
        processor: transfer.processor || 'manual',
        processedAt: getDatetime(),
      } as TransferEvent;
      setDraft(defaultState);
    }
    resetUIState();
  }, [transfer]);

  const handleFieldChange = (fieldName: string, value: any) => {
    if (!draft) return;
    setDraft((prev) => ({ ...prev, [fieldName]: value }));
  };

  const submitIfValid = () => {
    if (isFormValid) {
      sendTransferEvent(draft as TransferEvent);
    }
  };

  const closeForm = () => {
    setDraft(undefined);
    onCloseForm();
  };

  if (!transfer) {
    return (
      <Drawer header="Update payment" isOpen={isOpen} onClose={closeForm} />
    );
  }

  const footer = (
    <ButtonBar>
      <Button block label="Cancel" onClick={closeForm} type="secondary" />
      <Button
        block
        isDisabled={!isFormValid || isPosted(transfer.status || '')}
        label="Send event"
        onClick={submitIfValid}
      />
    </ButtonBar>
  );

  return (
    <Drawer
      footer={footer}
      header="Update payment"
      isOpen={isOpen}
      onClose={closeForm}
    >
      <FormField label={tk('Transfer Id')}>
        <div>{transfer.id}</div>
      </FormField>

      {isSuperAdmin && (
        <FormField label={tk('Type')}>
          <div>
            {billingSchedule ? 'billingschedule' : transfer.referenceType}
          </div>
        </FormField>
      )}

      <FormField label={tk('Amount')}>
        <MoneyInput
          defaultCurrency={transfer.currency}
          onBlur={(money) => handleFieldChange('amount', money.amount ?? 0)}
          placeholder="Amount"
          value={{ amount: draft?.amount, currency: transfer.currency }}
        />
      </FormField>

      <FormField label={tk('Status')}>
        <Select<Transfer['status']>
          onChange={(value) => handleFieldChange('status', value)}
          options={Object.keys(TransferStatus).map((key) => ({
            value: key as Transfer['status'],
            name: TransferStatus[key as keyof typeof TransferStatus].valueOf(),
          }))}
          value={draft?.status}
        />
      </FormField>

      <FormField
        errorToShow={getErrorToShow('reason')}
        label={tk('Reason - only if status is failed')}
      >
        <Input
          id="reason"
          onBlur={() => setHasVisitedField('reason')}
          onChange={(rv) => handleFieldChange('reason', rv)}
          onEnter={submitIfValid}
          value={draft?.reason}
        />
      </FormField>

      <FormField
        errorToShow={getErrorToShow('processedAt')}
        label={tk('Processed at: defaults to now')}
      >
        <Input
          id="processedAt"
          onBlur={() => setHasVisitedField('processedAt')}
          onChange={(rv) => handleFieldChange('processedAt', rv)}
          onEnter={submitIfValid}
          value={draft?.processedAt}
        />
      </FormField>
    </Drawer>
  );
};

export default PaymentForm;
