import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Icon,
  PopOutMenuOption,
  Proposal,
  ProposalSummary,
  useToast,
} from 'common';
import { useToggleLegacyCheckout } from 'app/src/services/proposal';

interface Props {
  proposal: Proposal | ProposalSummary;
}

const ToggleLegacyCheckoutButton: React.FC<Props> = ({ proposal }) => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { id } = proposal;

  const handleUpdateError = () => {
    showToast.error('Toggle legacy checkout failed');
  };

  const handleUpdateSuccess = () => {
    showToast.info('Legacy checkout toggled');
  };

  const { mutate: doToggle } = useToggleLegacyCheckout(
    id,
    handleUpdateSuccess,
    handleUpdateError,
    queryClient
  );

  return (
    <PopOutMenuOption
      dataTestId="toggle-legacy-checkout-button"
      icon={Icon.ArrowUp}
      onClick={() => doToggle()}
      title="Toggle Legacy Checkout"
    />
  );
};

export default ToggleLegacyCheckoutButton;
