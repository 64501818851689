import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  AutoFormDrawer,
  BillingSchedule,
  getDatetime,
  getToday,
  Money,
  TransferEvent,
  ValidationFormField,
} from 'common';
import { apiClient } from '../../../../../services/httpClients/app';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedPayment: BillingSchedule | undefined;
}

interface FormState {
  amount: Money;
  processedAt: string;
  reason: string;
}

export const PaymentUpdateForm: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedPayment,
}: Props) => {
  const queryClient = useQueryClient();
  const transfer = selectedPayment?.transfer;
  const transferId = transfer?.id;
  const fields: ValidationFormField<FormState>[] = [
    {
      isDisabled: true,
      fieldName: 'amount',
      type: 'money',
      inputProps: {
        currency: selectedPayment?.currency,
      },
    },
    {
      humanReadableName: 'Payment date',
      fieldName: 'processedAt',
      type: 'date',
      isRequired: true,
    },
    {
      humanReadableName: 'Note',
      fieldName: 'reason',
      type: 'textArea',
    },
  ];

  const handleSave = async (data: FormState) => {
    if (!selectedPayment || !transfer || !transferId) {
      return;
    }

    const currency = selectedPayment.currency || transfer.currency;
    const transferEvent: TransferEvent = {
      amount: selectedPayment.amountDue,
      currency,
      processedAt: getDatetime(data.processedAt),
      reason: data.reason,
      processor: 'manual',
      status: 'posted',
    };

    await apiClient.createTransferEvent(transferId, {
      ...transferEvent,
      currency,
    });
  };

  const onSuccess = async () => {
    onClose();
    await queryClient.invalidateQueries();
  };

  return (
    <AutoFormDrawer<FormState>
      queryClient={queryClient}
      entity={{
        amount: {
          amount: selectedPayment?.amountDue,
          currency: selectedPayment?.currency || transfer?.currency,
        },
        processedAt: getToday(),
      }}
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
      header="Payment update"
      entityName="Transfer event"
      fields={fields}
      onSave={handleSave}
    />
  );
};
