import React, { useEffect, useState } from 'react';
import {
  BillingSchedule,
  Card,
  CardHeader,
  CardRowValue,
  Contact,
  Contract,
  formatDateOrDefault,
  formatNullableFormattedAmount,
  getPersonFullName,
  Invoice,
  PURCHASE_ORDER_INVALID_STRING_ERROR_MESSEGE,
  PURCHASE_ORDER_UPDATE_ERROR_MESSEGE,
  PURCHASE_ORDER_UPDATE_SUCCESS_MESSEGE,
  TruncateOption,
  truncateText,
  Type,
  useToast,
  useTranslation,
} from 'common';
import styles from './InvoiceSummaryCard.module.scss';
import { apiClient } from 'app/src/services/httpClients/app';
import POInputBox from '../../../components/POInputBox';
import POLabelWithValue from 'app/src/components/POLabelWithValue';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import IDField from 'app/src/components/IDField';

interface Props {
  invoice: Invoice;
  schedule: BillingSchedule;
  subscription: Contract;
}

const getContactDisplay = (contact?: Contact) => {
  if (contact) {
    return (
      <div>
        <div title={getPersonFullName(contact)}>
          {getPersonFullName(contact)}
        </div>
        <div className="text-xs" title={contact.email}>
          {contact.email}
        </div>
      </div>
    );
  }
  return '-';
};

const InvoiceSummaryCard: React.FC<Props> = ({
  subscription,
  schedule,
  invoice,
}) => {
  const showToast = useToast();

  const { tk } = useTranslation();

  const [isEditingPO, setIsEditingPO] = useState<boolean>(false);

  const [poDisplayValue, setPoDisplayValue] = useState<string>('');

  const canUpdate = isActionAllowed(FLAG_TYPES.INVOICE, 'update');

  useEffect(() => {
    setPoDisplayValue(invoice.purchaseOrderNumber!);
  }, [invoice]);

  const updatePO = async (poNumber: string) => {
    if (poNumber !== invoice.purchaseOrderNumber) {
      await apiClient
        .updateInvoice(invoice.id, {
          purchaseOrderNumber: poNumber,
        })
        .then(() => {
          showToast.success(PURCHASE_ORDER_UPDATE_SUCCESS_MESSEGE);
        })
        .catch(() => {
          showToast.error(PURCHASE_ORDER_UPDATE_ERROR_MESSEGE);
        });
      setIsEditingPO(false);
    } else {
      if (poNumber !== invoice.purchaseOrderNumber) {
        showToast.error(PURCHASE_ORDER_INVALID_STRING_ERROR_MESSEGE);
      } else {
        setIsEditingPO(false);
      }
    }
  };

  const isPoEditable =
    invoice.status === 'draft' ||
    invoice.status === 'open' ||
    invoice.status === 'past_due';

  const renderPO = () => {
    if (
      !isEditingPO &&
      (invoice.purchaseOrderNumber !== undefined || isPoEditable)
    ) {
      return (
        <POLabelWithValue
          label="PO Number"
          poDisplayValue={poDisplayValue}
          setEditingPO={setIsEditingPO}
          isEditDisabled={!isPoEditable || !canUpdate}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.invoiceSummaryCard}>
      <div className={styles.side}>
        <Card bottomMargin throughline grow>
          <CardHeader name="Invoice" throughline />
          {invoice.id && (
            <CardRowValue label="Id">
              <IDField documentID={invoice.id}>
                <div className="tabular-nums">
                  {truncateText(invoice.id, 8, TruncateOption.WithoutDots)}
                </div>
              </IDField>
            </CardRowValue>
          )}
          <CardRowValue label="Customer name">
            <Type bold>{subscription.buyer.name}</Type>
          </CardRowValue>
          {subscription.billTo && (
            <CardRowValue label="Bill to">
              {subscription.billTo.name}
            </CardRowValue>
          )}
          <CardRowValue label="Billing contact">
            {getContactDisplay(
              subscription.billTo
                ? subscription.billTo.billingContact
                : subscription.buyer.billingContact
            )}
          </CardRowValue>

          {subscription.buyer.tin && (
            <CardRowValue label="Tax ID">
              <Type>{subscription.buyer.tin}</Type>
            </CardRowValue>
          )}
        </Card>
      </div>

      <div className={styles.side}>
        <Card bottomMargin throughline grow>
          {renderPO()}
          {isEditingPO && (
            <div className="ml-0 w-full">
              <POInputBox
                label="PO Number"
                value={poDisplayValue}
                onChange={(e) => {
                  setPoDisplayValue(e);
                }}
                onCancel={() => setIsEditingPO(false)}
                onSubmit={() => {
                  updatePO(poDisplayValue).catch((error) => {
                    throw error;
                  });
                }}
              />
            </div>
          )}
          <CardRowValue label="Invoice date">
            <div>{formatDateOrDefault(schedule.invoiceDate)}</div>
          </CardRowValue>
          <CardRowValue label="Due date">
            <div>{formatDateOrDefault(schedule.dueDate)}</div>
          </CardRowValue>
          <CardRowValue label="Currency">
            <div>{subscription.currency}</div>
          </CardRowValue>
          <CardRowValue label="Payment terms">
            <div>
              {subscription.paymentTerm &&
                tk(
                  `term_${subscription.paymentTerm}_name`,
                  subscription.paymentTerm
                )}
            </div>
          </CardRowValue>

          <CardRowValue label="Invoice amount">
            <div>{formatNullableFormattedAmount(invoice.amountFormatted)}</div>
          </CardRowValue>
          <CardRowValue label="Balance">
            <div>{formatNullableFormattedAmount(invoice.balanceFormatted)}</div>
          </CardRowValue>
        </Card>
      </div>
    </div>
  );
};

export default InvoiceSummaryCard;
