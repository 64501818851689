import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import { Icon, isString, OrgConfig, ToolTip, User } from 'common';

import {
  getGroupPath,
  useGetGroup,
  useGetGroupUsers,
  useRemoveUsersFromGroup,
  useUpdateGroupUsers,
} from 'app/src/services/groups';
import { useFindOrgDefault } from 'app/src/services/orgDefaults';

import { GroupOption, searchFilterOption } from '../rules/_approvalRulesConfig';

import styles from './ApprovalGroupRow.module.scss';

type Props = {
  configKey: Extract<
    OrgConfig['configKey'],
    'approvalGroup' | 'approvalExclusionGroup'
  >;
  usersListOptions: SelectProps<string, GroupOption>['options'];
};

export const ApprovalGroupUniqueRow = ({
  configKey,
  usersListOptions,
}: Props) => {
  const queryClient = useQueryClient();

  const { data: groupOrgSetting } = useFindOrgDefault(configKey);
  const { data: group } = useGetGroup(groupOrgSetting?.referenceId);
  const { data: groupUsers } = useGetGroupUsers(group?.id);

  const [usersInGroupValue, setUsersInGroupValue] = useState<User[]>([]);

  const SPECIFIC_GROUP_PATH = getGroupPath(group?.id!);

  const updateGroupUsers = useUpdateGroupUsers(
    () => {},
    () => {},
    queryClient,
    SPECIFIC_GROUP_PATH
  );

  const removeGroupUser = useRemoveUsersFromGroup(
    () => {},
    () => {},
    queryClient,
    SPECIFIC_GROUP_PATH
  );

  useEffect(() => setUsersInGroupValue(groupUsers || []), [groupUsers]);

  const handleUserChange = (currentSelectedUserIds: string | string[]) => {
    // Determine if an item was added or removed based on the length of arrays
    if (currentSelectedUserIds.length > usersInGroupValue.length) {
      // Adding user
      if (Array.isArray(currentSelectedUserIds)) {
        addUserToGroup(currentSelectedUserIds);
      }
    } else if (currentSelectedUserIds.length < usersInGroupValue.length) {
      // Removing user
      const removedUser = usersInGroupValue.find(
        (user) => !currentSelectedUserIds.includes(user.id!)
      );

      if (removedUser) {
        removeUserFromGroup(removedUser.id!);
      }
    }
  };

  const addUserToGroup = (values: string[]) => {
    const currentUsersInGroup = [...usersInGroupValue];

    updateGroupUsers.mutate(
      {
        groupId: group?.id!,
        userIds: values,
      },
      {
        onError: () => {
          setUsersInGroupValue(currentUsersInGroup);
        },
      }
    );
  };

  const removeUserFromGroup = (userId: string) => {
    const currentUsersInGroup = [...usersInGroupValue];

    removeGroupUser.mutate(
      {
        groupId: group?.id!,
        userIds: [userId],
      },
      {
        onError: () => {
          setUsersInGroupValue(currentUsersInGroup);
        },
      }
    );
  };

  return (
    <div className={styles.group}>
      <div className={styles.groupName}>
        {group?.name}{' '}
        <ToolTip title="Users in the exclusion group are exempt from approval requirements.">
          <Icon.Information width={28} height={28} className={styles.rowIcon} />
        </ToolTip>
      </div>
      <Select
        onChange={handleUserChange}
        options={usersListOptions}
        mode="multiple"
        className={styles.groupWidth}
        filterOption={searchFilterOption}
        placeholder="Select user"
        value={groupUsers?.map((user) => user.id).filter(isString)}
      />
    </div>
  );
};
