import { useNavigate } from 'react-router-dom';
import { PROPOSALS } from '../../core-utils/routes';
import { Proposal } from 'common';

export function useGoToProposal() {
  const navigate = useNavigate();
  return (proposal: Proposal) => {
    navigate(`${PROPOSALS}/${proposal.id}`);
  };
}
