import { ConnectorInfo, OrgConfig } from 'common';

const QUICKBOOKS: string = 'QuickBooks';
const QUICKBOOKS_CONFIG: OrgConfig['configKey'] = 'quickbooksConfig';
const QUICKBOOKS_SOURCE: ConnectorInfo['source'] = 'quickbooks';

export const QUICKBOOKS_INFO = {
  configType: QUICKBOOKS_CONFIG,
  source: QUICKBOOKS_SOURCE,
  name: QUICKBOOKS,
  showSaveButton: true,
};

interface AccountContactsConfig {
  isRefundsEnabled: boolean;
}

export enum SectionsEnum {
  PRODUCTS = 'Products',
  PAYMENTS = 'Payments',
  REFUNDS = 'Refunds',
  SALES_TAX = 'Sales tax',
  OTHER = 'Other',
  ADDITIONAL = 'Additional',
}

export const getAccountContactsConfig = ({
  isRefundsEnabled,
}: AccountContactsConfig) => ({
  integration: 'QuickBooks',
  description:
    'The QuickBooks Online connector automatically syncs invoices and payments to QuickBooks.',
  cacheflowToIntegrationSection: [
    {
      left: 'Customers',
      right: 'Customers',
    },
    {
      left: 'Products',
      right: 'Items',
    },
    {
      left: 'Billing/Payment Schedules',
      right: 'Invoices',
    },
    {
      left: 'Billing/Payment Schedule Items',
      right: 'Invoice Lines',
    },
    {
      left: 'Payments',
      right: 'Payments',
    },
    ...(isRefundsEnabled
      ? [
          {
            left: 'Refunds',
            right: 'Refund Receipts',
          },
        ]
      : []),
  ],
  integrationToCacheflowSection: [
    {
      left: 'Payments',
      right: 'Payments',
    },
  ],
});
