import {
  formatDateShort,
  formatMoneyOrDefault,
  ItemDeltaSpansResponse,
  Proposal,
} from 'common';
import React from 'react';
import { useGetProposalDeltas } from '../../../../services/proposal';
import styles from './proposalDebugger.module.scss';

interface Props {
  proposal: Proposal;
}

const ProposalDeltas: React.FC<Props> = ({ proposal }) => {
  const { data } = useGetProposalDeltas(proposal.id);

  const getProposalTotal = (items: ItemDeltaSpansResponse[]) => {
    return items.reduce((acc, item) => {
      return (
        acc +
        item.spans.reduce((acc2, span) => {
          return acc2 + span.proratedTotalAmount.amount!;
        }, 0)
      );
    }, 0);
  };

  if (!data) {
    return null;
  }

  return (
    <div className={styles.deltas}>
      <h2>Proposal deltas</h2>
      {data.map((delta: ItemDeltaSpansResponse) => (
        <div className={styles.deltaItem} key={delta.item.id}>
          <div className={styles.deltaItemTitle}>{delta.item.name}</div>
          <table className={styles.spanTable}>
            <thead>
              <tr>
                <th>Start</th>
                <th>End</th>
                <th>Dur</th>
                <th>Qty</th>
                <th>Pr</th>
                <th>Pro days</th>
                <th>Pro amount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {delta.spans.map((span) => (
                <tr key={delta.item.id + span.startDate!}>
                  <td>{formatDateShort(span.startDate!)}</td>
                  <td>{formatDateShort(span.endDate!)}</td>
                  <td>{span.duration}</td>
                  <td>{span.quantity}</td>
                  <td>
                    {Number((span.prorating || 0).toString().slice(0, 4))}
                  </td>
                  <td>{span.proratedDays}</td>
                  <td className={styles.amount}>
                    {span.proratedAmount!.formattedAmount}
                  </td>
                  <td className={styles.amount}>
                    {formatMoneyOrDefault(span.proratedTotalAmount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
      <div className={styles.proposalTotal}>
        Proposal change total:{' '}
        {formatMoneyOrDefault({
          currency: proposal.currency,
          amount: getProposalTotal(data),
        })}
      </div>
    </div>
  );
};

export default ProposalDeltas;
