import React from 'react';
import { Loading, ReconciliationSummaryRest } from 'common';
import EventCard from './EventCard';
import { useQuery } from '@tanstack/react-query';

interface ReconciliationSummaryProps {
  getReconciliationSummary: () => Promise<ReconciliationSummaryRest>;
}

const ReconciliationSummary: React.FC<ReconciliationSummaryProps> = ({
  getReconciliationSummary,
}) => {
  const { data: reconciliationSummary, isLoading } = useQuery({
    queryKey: ['getReconciliationSummary'],
    queryFn: getReconciliationSummary,
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="cardContainer">
          <EventCard
            count={reconciliationSummary?.totalSchedulesSynced}
            title="Invoices synced"
          />
          <EventCard
            count={reconciliationSummary?.totalSchedulesFailed}
            title="Invoices failed"
          />
          <EventCard
            count={reconciliationSummary?.totalTransfersSynced}
            title="Payments synced"
          />
          <EventCard
            count={reconciliationSummary?.totalTransfersFailed}
            title="Payments failed"
          />
        </div>
      )}
    </div>
  );
};

export default ReconciliationSummary;
