import React, { useEffect, useRef, useState } from 'react';
import { RefSelectProps, Select } from 'antd';
import clsx from 'clsx';

import {
  CreateProposalApprovalRuleRequest,
  InputRef,
  isDefined,
  LinkButton,
  NumberInput,
  ProposalDiscountPercentRuleConfig,
  ProposalSummary,
} from 'common';

import {
  GroupOption,
  RuleInfo,
  searchFilterOption,
} from '../rules/_approvalRulesConfig';

import styles from './ProposalRuleBuilderStyles.module.scss';

interface Props {
  allGroupOptions: GroupOption[];
  createRule: (payload: CreateProposalApprovalRuleRequest) => void;
  onCancelAdd: () => void;
  proposalType: ProposalSummary['proposalType'];
  selectedRule: RuleInfo;
}

export const ProposalPercentBuilder = ({
  proposalType,
  createRule,
  selectedRule,
  onCancelAdd,
  allGroupOptions,
}: Props) => {
  const [value, setValue] = useState<number>(0);

  const ref = useRef<InputRef>(null);
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    ref.current?.focus({ cursor: 'all' });
  }, []);

  const handleSave = (newValue: number, groupId: string[]) => {
    const payload = {
      name: `${selectedRule.name}-${proposalType}`,
      config: {
        [selectedRule.configKey]: newValue,
        proposalType,
        ruleType:
          selectedRule.name as ProposalDiscountPercentRuleConfig['ruleType'],
      },
      approvalGroupIds: groupId,
    };

    createRule(payload);
  };

  const handleSaveIfValid = (id: string[]) => {
    if (value >= 0 && id.length > 0) {
      handleSave(value, id);
    }
  };

  const handleChange = (newValue: number | undefined = 0) => {
    const convertedValued = newValue * 100;
    if (newValue !== convertedValued) {
      setValue(convertedValued);
    }
  };

  const handleEnter = () => selectRef.current?.focus();

  return (
    <div
      className={clsx(
        styles.ruleRow,
        isDefined(value) && value >= 0 && styles.showingGroupsSelect
      )}
    >
      <div className={styles.condition}>{selectedRule.conditionOperator}</div>

      <div className={styles.input}>
        <NumberInput
          ref={ref}
          defaultValue={0}
          value={value / 100}
          onEnter={handleEnter}
          onChange={handleChange}
          suffix="%"
          max={1000}
          dataTestId={selectedRule.name}
        />
      </div>

      {!isDefined(value) && (
        <LinkButton className={styles.cancel} onClick={onCancelAdd}>
          cancel
        </LinkButton>
      )}

      {isDefined(value) && value >= 0 && (
        <div className={styles.groups}>
          {allGroupOptions.length > 0 && (
            <Select
              ref={selectRef}
              onChange={handleSaveIfValid}
              value={[]}
              options={allGroupOptions}
              mode="multiple"
              filterOption={searchFilterOption}
              className={styles.groupWidth}
              placeholder="Select approval group"
              popupMatchSelectWidth={false}
            />
          )}
        </div>
      )}
    </div>
  );
};
