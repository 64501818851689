import React, { useEffect, useState } from 'react';
import {
  Button,
  ConnectorInfo,
  ContractSummary,
  log,
  useToast,
  useTranslation,
} from 'common';
import { useQueryClient } from '@tanstack/react-query';

import { useGoToProposal } from '../../core-utils/commonHooks/nav';
import {
  useContractProposals,
  useCreateContractProposal,
} from 'app/src/services/contract';
import {
  ChangeRenewalState,
  getChangeRenewalState,
} from 'app/src/pages/Subscriptions/contractUtils';

interface Props {
  contract: ContractSummary;
  dealId: string;
  onIsProcessing: (isProcessing: boolean) => void;
  sourceType: ConnectorInfo['source'];
}

const Actions: React.FC<Props> = ({
  sourceType,
  contract,
  dealId,
  onIsProcessing,
}) => {
  const queryClient = useQueryClient();
  const showToast = useToast();
  const { tk } = useTranslation();

  const goToProposal = useGoToProposal();
  const { data, isLoading } = useContractProposals(contract.id);
  const [changeRenewalState, setChangeRenewalState] =
    useState<ChangeRenewalState>({});

  useEffect(() => {
    if (data) {
      setChangeRenewalState(getChangeRenewalState(data));
    }
  }, [data]);

  const { mutate: createChangeProposal, isPending: isLoadingChange } =
    useCreateContractProposal(
      contract.id,
      {
        proposalType: 'change',
        source: sourceType,
        externalId: dealId,
      },
      goToProposal,
      (mutateError: unknown) => {
        showToast.error(tk('Error creating change proposal'));
        log.error(mutateError);
      },
      queryClient
    );

  const { mutate: createRenewalProposal, isPending: isLoadingRenewal } =
    useCreateContractProposal(
      contract.id,
      {
        proposalType: 'renewal',
        source: sourceType,
        externalId: dealId,
      },
      goToProposal,
      (mutateError: unknown) => {
        showToast.error(tk('Error creating renewal'));
        log.error(mutateError);
      },
      queryClient
    );

  useEffect(() => {
    onIsProcessing(isLoadingChange || isLoadingRenewal);
  }, [isLoadingChange, isLoadingRenewal]);

  return (
    <div className="flex mt-2 gap-1 justify-end">
      <Button
        type="primary"
        size="small"
        label={tk('Change')}
        dataTestId="change_create_button"
        onClick={createChangeProposal}
        isDisabled={
          isLoading ||
          isLoadingChange ||
          isLoadingRenewal ||
          !!changeRenewalState.changeProposal
        }
      />
      <Button
        type="primary"
        size="small"
        label={tk('Renew')}
        dataTestId="renewal_create_button"
        onClick={createRenewalProposal}
        isDisabled={
          isLoading ||
          isLoadingChange ||
          isLoadingRenewal ||
          !!changeRenewalState.renewalProposal
        }
      />
    </div>
  );
};

export default Actions;
