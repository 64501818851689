import React from 'react';
import {
  getBillingPeriodLabelAdjective,
  isStringContent,
  PaymentMethod,
  PaymentSchedule,
  Proposal,
  RadioBlock,
  useTranslation,
} from 'common';

import styles from './PaymentPlanOption.module.scss';
import TooltipPill from 'app/src/components/TooltipPill';

interface Props {
  allPaymentMethods: PaymentMethod[];
  allowScheduleSelection: boolean;
  focusedPaymentSchedule: PaymentSchedule;
  paymentSchedule: PaymentSchedule;
  proposal: Proposal;
  scheduleIndex: number;
  selectPaymentPlanIndex: (index: number) => void;
}

const PaymentPlanOption: React.FC<Props> = ({
  allowScheduleSelection,
  allPaymentMethods,
  focusedPaymentSchedule,
  paymentSchedule,
  proposal,
  scheduleIndex,
  selectPaymentPlanIndex,
}) => {
  const { tk } = useTranslation();
  const payEvery = paymentSchedule.payEvery;

  const isSelectedPreAccepted: boolean =
    allowScheduleSelection && payEvery === focusedPaymentSchedule.payEvery;
  const isSelectedPostAccepted: boolean =
    !allowScheduleSelection &&
    paymentSchedule.id === proposal.acceptedScheduleId;
  const isSelected: boolean = isSelectedPreAccepted || isSelectedPostAccepted;
  const isDefault = payEvery === proposal.defaultBillingPeriod;

  const paymentMethods: string = (paymentSchedule.paymentMethodIds || [])
    .map(
      (id: string) =>
        allPaymentMethods.find((m: PaymentMethod) => m.id === id)?.name
    )
    .filter(isStringContent)
    .join(', ');

  const title: string = getBillingPeriodLabelAdjective(payEvery);

  const AddonAfter = () => {
    if (!allowScheduleSelection) {
      return null;
    }

    return (
      <div className={styles.addonAfter}>
        {isDefault && (
          <TooltipPill
            label={tk('Default')}
            tooltipText="Default is the option initially selected in checkout"
            dataTestId={`schedule-default-${payEvery}`}
          />
        )}
      </div>
    );
  };

  const handleClick = () => {
    if (allowScheduleSelection) {
      selectPaymentPlanIndex(scheduleIndex);
    }
  };

  return (
    <RadioBlock
      addonAfter={<AddonAfter />}
      className={styles.paymentPlanOption}
      dataTestId={`schedule-${payEvery}`}
      description={paymentMethods}
      isAccepted={!allowScheduleSelection}
      isSelected={isSelected}
      onClick={handleClick}
      title={title}
    />
  );
};

export default PaymentPlanOption;
