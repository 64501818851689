import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Cell,
  formatDateOrDefault,
  getIconCell,
  getIconDataBasedOnProposalType,
  getOrDefault,
  Icon,
  IconWithTooltip,
  pluralize,
  PopOutMenu,
  PopOutMenuOption,
  Proposal,
  pushIf,
  SimpleTable,
} from 'common';
import StatusLabel from '../../../components/StatusLabel';
import { apiClient } from '../../../services/httpClients/app';
import { useAuth } from 'app/src/Auth';

interface Props {
  contractProposals?: Proposal[];
}

const HistoryTab: React.FC<Props> = ({ contractProposals }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  let sortedProposals: Proposal[] = [];
  if (contractProposals) {
    // TODO: Replace with compareChronological or compareReverseChronological
    sortedProposals = [...contractProposals].sort((a, b) => {
      const dateA = new Date(a.createdAt || '').getTime();
      const dateB = new Date(b.createdAt || '').getTime();
      return dateB - dateA;
    });
  }
  const tableRowOnClick = (selectedProposal: Proposal) => {
    navigate(`/proposals/${selectedProposal.id}`);
  };

  const getRowClassName = (row: Proposal) => {
    if (row.archived) {
      return 'bg-slate-lightest';
    }
    return undefined;
  };

  const cells: Cell<Proposal>[] = [
    getIconCell(null, {
      renderCell: (proposal: Proposal) => {
        const iconData = getIconDataBasedOnProposalType(proposal);
        return (
          <IconWithTooltip
            icon={iconData.icon}
            tooltipText={iconData.tooltipText}
          />
        );
      },
    }),
    {
      key: 'customer',
      headerLabel: ['Customer', 'Ref.'],
      renderCell: ({ customer, proposalNumber }: Proposal) => (
        <div>
          <div>{getOrDefault(customer?.name)}</div>
          <div className="text-xs">{getOrDefault(proposalNumber)}</div>
        </div>
      ),
      overflowCell: true,
    },
    {
      key: 'amount',
      headerLabel: ['Value', 'Term'],
      renderCell: ({ amountFormatted, termQty, termType }: Proposal) => (
        <div>
          <div>{getOrDefault(amountFormatted)}</div>
          <div className="text-xs text-slate">
            {pluralize(termQty, termType)}
          </div>
        </div>
      ),
    },
    {
      key: 'line_items',
      headerLabel: 'Line items',
      renderCell: ({ proposalItems }: Proposal) =>
        pluralize(proposalItems.length, 'product'),
    },
    {
      key: 'effective_date',
      headerLabel: 'Effective date',
      renderCell: ({ effectiveStartDate }: Proposal) =>
        formatDateOrDefault(effectiveStartDate),
    },
    {
      key: 'status',
      headerLabel: 'Status',
      renderCell: ({ status, displayStatus }: Proposal) => (
        <StatusLabel
          displayStatus={displayStatus}
          feature="proposal"
          status={status}
        />
      ),
    },
    ...pushIf<Cell<Proposal>>(auth.isSystemAdmin, {
      key: 'options',
      headerLabel: 'Option',
      width: 64,
      clickable: true,
      renderCell: (data: Proposal) => (
        <PopOutMenu>
          <PopOutMenuOption
            icon={Icon.Repeat}
            isDangerous
            onClick={async () => {
              await apiClient.setBillingMethodOnRenewal(data.id);
            }}
            title="Set payment from contract"
          />
        </PopOutMenu>
      ),
    }),
  ];

  return (
    <SimpleTable<Proposal>
      cells={cells}
      dataTestId="history-table"
      disableSearch
      getRowClassName={getRowClassName}
      isLoading={!contractProposals}
      minWidth="720px"
      onRowClick={tableRowOnClick}
      rows={sortedProposals}
    />
  );
};

export default HistoryTab;
