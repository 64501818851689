import React from 'react';
import { Icon, PaymentIcon, Transaction } from 'common';
import { getPaymentType } from '../../services/api/transactions/transactionUtils';

export const paymentIcon = (transaction: Transaction) => {
  if (transaction.category === 'PAYOUT') {
    return <Icon.ArrowRight />;
  }

  switch (getPaymentType(transaction)) {
    case 'cc': {
      return <PaymentIcon.Creditcard />;
    }

    case 'direct_debit': {
      return <PaymentIcon.Dd />;
    }

    case 'ach': {
      return <PaymentIcon.Ach />;
    }

    case 'check': {
      return <PaymentIcon.Check />;
    }
    case 'wire': {
      return <PaymentIcon.Wire />;
    }
    default:
      return <PaymentIcon.Nopayment />;
  }
};

export const getPaymentTypeString = (data: any): string => {
  if (data.category === 'PAYOUT') {
    return 'Payout';
  }
  switch (data.parentReferenceType || data.referenceType) {
    case 'billingschedule': {
      return 'Subscription payment';
    }

    case 'invoice': {
      return data.invoiceNumber;
    }
  }

  return '-';
};
