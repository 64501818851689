import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UpcomingBillingResponse } from 'common';
import { systemClient } from '../../httpClients/systemclient';
import { formatISO } from 'date-fns';

export const useSystemUpcomingBilling = (
  d: Date
): UseQueryResult<UpcomingBillingResponse[]> => {
  return useQuery({
    queryKey: ['UPCOMING_BILLING_' + d],
    queryFn: async () => {
      const { data } = await systemClient.upcomingBilling({
        dueDate: formatISO(d, { representation: 'date' }),
      });
      return data;
    },
  });
};
