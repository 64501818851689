import { ErrorFetching, ItemIcon, Loading, pushIf, Tabs } from 'common';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isLocalOrDevEnvironment } from '../../../../config/hosts';
import Page from '../../../components/Page';
import { useIsSuperAdmin } from '../../../core-utils/helperFunctions/userServiceHelper';
import { SUBSCRIPTIONS } from '../../../core-utils/routes';
import { useContract, useContractProposals } from '../../../services/contract';
import { useReferenceIssues } from '../../../services/syncEvents';
import ContractDetailsTab from './ContractDetailsTab';
import HistoryTab from './HistoryTab';
import MetricsTab from './Metrics/MetricsTab';
import PaymentList from './PaymentSchedule/PaymentList';
import SubscriptionHeader from './SubscriptionBar/SubscriptionHeader';

const SubscriptionDetailPage: React.FC = () => {
  const { id: subscriptionId } = useParams<{ id: string }>();
  const [selectedTab, setSelectedTab] = useState<string>('details');
  const showMetricsTab = useIsSuperAdmin() || isLocalOrDevEnvironment();

  if (!subscriptionId) {
    return <ErrorFetching />;
  }

  const { data: contract, isLoading } = useContract(subscriptionId);
  const { data: contractProposals, isLoading: areProposalsLoading } =
    useContractProposals(subscriptionId);
  // Eventually build off contract and all elements that are errors, for now customer is the main one

  const { data: issues } = useReferenceIssues(
    'customer',
    !isLoading && !!contract?.buyer,
    contract?.buyer.id
  );

  const nameComponent = () => {
    if (!contract) {
      return undefined;
    }

    return (
      <div className="flex h-10 gap-2">
        <div className="flex flex-col justify-center">
          <Link to={SUBSCRIPTIONS}>
            <ItemIcon.Subscription />
          </Link>
        </div>
        <div className="flex flex-col justify-center">
          {contract.name || contract.buyer.name}
          <p className="text-xs">
            {contract.contractNumber
              ? `Subscription No. ${contract.contractNumber}`
              : ''}
          </p>
        </div>
      </div>
    );
  };

  const statusComponent = () => {
    if (!contract || areProposalsLoading) {
      return undefined;
    }

    return (
      <SubscriptionHeader
        contract={contract}
        contractProposals={contractProposals}
      />
    );
  };

  if (isLoading || areProposalsLoading) {
    return <Loading />;
  }

  if (!contract) {
    return <ErrorFetching />;
  }

  return (
    <Page leftWidget={nameComponent()} rightWidget={statusComponent()}>
      <Tabs
        defaultActiveTab={selectedTab}
        onChange={setSelectedTab}
        tabs={[
          {
            label: 'Details',
            key: 'details',
            component: (
              <ContractDetailsTab
                contract={contract}
                contractProposals={contractProposals}
                issues={issues}
              />
            ),
          },
          {
            label: 'Billing',
            key: 'payments',
            component: <PaymentList contract={contract} issues={issues} />,
          },
          {
            label: 'History',
            key: 'history',
            component: <HistoryTab contractProposals={contractProposals} />,
          },
          ...pushIf(showMetricsTab, {
            label: 'Metrics',
            key: 'metrics',
            component: <MetricsTab contract={contract} />,
          }),
        ]}
      />
    </Page>
  );
};

export default SubscriptionDetailPage;
