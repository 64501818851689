import React from 'react';
import Underline from '@tiptap/extension-underline';
import EditorButton from '../EditorButton';
import { EditorFeature } from '../editorCommon';
import { EditorIconUnderline } from '../editorIcons';

const underlineFeature: EditorFeature = {
  extensions: [Underline],
  contextButtons: (editor) => (
    <EditorButton
      isSelected={editor.isActive('underline')}
      label="Underline"
      onClick={() => editor.chain().focus().toggleUnderline().run()}
      icon={EditorIconUnderline}
    />
  ),
  key: 'underline',
};

export default underlineFeature;
