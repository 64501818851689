import {
  BillingSchedule,
  Contract,
  ContractUsageSummary,
  Product,
  ProductUsage,
} from 'common';
import { addDays, isPast, parseISO } from 'date-fns';

export const isUsageBasedContract = (contract: Contract): boolean => {
  const usageBasedContractItems = contract.items.filter(
    (item) => item.product.recurrence === 'usage'
  );
  return usageBasedContractItems.length > 0;
};
export const getUnbilledAmount = (
  billingSchedules: BillingSchedule[] | undefined
): number =>
  billingSchedules?.reduce((total, schedule) => {
    if (schedule.invoiceStatus !== undefined) {
      return total;
    }

    return total + schedule.amountDue;
  }, 0) || 0;

export const getPaidAmount = (
  billingSchedules: BillingSchedule[] | undefined
): number =>
  billingSchedules?.reduce((total, schedule) => {
    if (
      schedule.transferStatus === undefined ||
      schedule.transferStatus !== 'posted' ||
      !schedule.transfer?.paidAmount
    ) {
      return total;
    }

    return total + schedule.transfer.paidAmount;
  }, 0) || 0;

export const usageTotal = (
  billingSchedule: BillingSchedule | undefined,
  products: Product[] | undefined
): number => {
  const productsMap: Map<string, Product> = getProductsMap(products);

  return (billingSchedule?.billingItems ?? []).reduce<number>(
    (total, billingItem) => {
      if (!billingItem.product.id) {
        return total;
      }

      const product = productsMap.get(billingItem.product.id);
      if (!product || product.recurrence !== 'usage') {
        return total;
      }

      return total + billingItem.amountWithoutTax;
    },
    0
  );
};

export const fixedTotal = (
  billingSchedule: BillingSchedule | undefined,
  products: Product[] | undefined
) => {
  const productsMap: Map<string, Product> = getProductsMap(products);

  if (billingSchedule?.billingItems.length) {
    return billingSchedule.billingItems
      .filter((item) => {
        if (!item.product.id) {
          return false;
        }

        const product = productsMap.get(item.product.id);
        return product && product.recurrence !== 'usage';
      })
      .map((item) => item.amountWithoutTax)
      .reduce<number>((a, b) => a + b, 0);
  }

  return 0;
};

export const getProductIdsForContractsBilling = (
  contracts: ContractUsageSummary[] | undefined
) => {
  let productIds: string[] = [];
  if (contracts) {
    productIds = Array.from(
      contracts.reduce((previousValue, currentValue) => {
        return new Set([
          ...previousValue,
          ...getProductIdsForBilling(currentValue.currentUsageSchedule),
        ]);
      }, new Set<string>())
    );
  }
  return productIds;
};

export const getProductIdsForContractBilling = (
  contract: Contract | undefined
): string[] => {
  const billingSchedules = contract?.billingSchedules;

  if (!contract || !billingSchedules) {
    return [];
  }

  return Array.from(
    billingSchedules.reduce((previousValue, currentValue) => {
      const { billingItems } = currentValue;

      const biProductIds: string[] = billingItems.reduce<string[]>((pv, bi) => {
        return !bi.product.id ? pv : [...pv, bi.product.id];
      }, []);

      return new Set([...previousValue, ...biProductIds]);
    }, new Set<string>())
  );
};

export const getProductIdsForBilling = (
  billingSchedule: BillingSchedule | undefined
): string[] => {
  if (!billingSchedule) {
    return [];
  }

  const { billingItems } = billingSchedule;

  const biProductIds: string[] = billingItems.reduce<string[]>((pv, bi) => {
    return !bi.product.id ? pv : [...pv, bi.product.id];
  }, []);

  return biProductIds;
};

export const isStaleContract = (
  contract: ContractUsageSummary,
  staleUsagePeriod: number
): boolean => {
  return isPast(
    addDays(
      parseISO(contract.currentUsageSchedule?.scheduleDate ?? ''),
      -1 * staleUsagePeriod
    )
  );
};

export const getCurrentUsageMap = (
  contracts: Contract[] | undefined
): Map<string, BillingSchedule> => {
  if (!contracts) {
    return new Map<string, BillingSchedule>();
  }

  const currentUsagePairs: [Contract, BillingSchedule][] = contracts.reduce<
    [Contract, BillingSchedule][]
  >((previousValue, c) => {
    // the first one is good enough for the usage entry screen
    const billingSchedule = c.billingSchedules.find(
      (bs) => bs.usageEntryActive
    );
    if (billingSchedule) {
      return [...previousValue, [c, billingSchedule]];
    }

    return previousValue;
  }, []);

  return new Map<string, BillingSchedule>(
    currentUsagePairs.map((cuPair) => [cuPair[0].id, cuPair[1]])
  );
};

const getProductsMap = (
  products: Product[] | undefined
): Map<string, Product> => {
  if (!products) {
    return new Map<string, Product>();
  }

  return products.reduce<Map<string, Product>>(
    (previousValue, currentValue) => {
      const { id } = currentValue;

      if (!id) {
        return previousValue;
      }

      return new Map([...previousValue, [id, currentValue]]);
    },
    new Map<string, Product>()
  );
};

export const getLastUpdatedUsage = (
  billingSchedule?: BillingSchedule
): ProductUsage | undefined => {
  if (billingSchedule && billingSchedule.billingItems.length > 0) {
    return (
      billingSchedule.billingItems
        .filter((item) => item.latestProductUsage)
        // TODO: Replace with compareChronological or compareReverseChronological
        .sort(
          (a, b) =>
            new Date(b.latestProductUsage?.createdAt ?? '').getTime() -
            new Date(a.latestProductUsage?.createdAt ?? '').getTime()
        )[0]?.latestProductUsage
    );
  }
  return undefined;
};
