import { Card, CardHeader, Contract, formatRichText } from 'common';
import React from 'react';
import useSubscriptionProposal from './hook';
import SignerInfo from 'app/src/components/SignerInfo';

interface Props {
  contract: Contract;
}

const CustomTermsCard: React.FC<Props> = ({ contract }) => {
  const proposal = useSubscriptionProposal(contract);

  return (
    <>
      {contract.customTerms && (
        <Card>
          <CardHeader name="Additional terms" />
          {formatRichText(contract.customTerms)}
        </Card>
      )}

      {proposal && proposal.signingDocument && (
        <Card>
          <CardHeader name="Signing" />
          <SignerInfo
            id={proposal.id}
            signingDocument={proposal.signingDocument}
          />
        </Card>
      )}
    </>
  );
};

export default CustomTermsCard;
