import React from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import clsx from 'clsx';

interface Props {
  leftWidget: React.ReactNode;
  rightWidget: React.ReactNode;
}

const Header: React.FC<Props> = ({ leftWidget, rightWidget }) => {
  return (
    <div
      className={clsx(
        'flex flex-row fixed z-[998] items-center justify-between',
        'w-[calc(100%-220px)] h-[4.5rem] px-6 py-3 bg-white',
        'border-b border-solid border-gray-lines'
      )}
    >
      <LeftSection>{leftWidget}</LeftSection>
      <RightSection>{rightWidget}</RightSection>
    </div>
  );
};

export default Header;
