import React, { PropsWithChildren, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AddItemScheduleContext, ItemScheduleRequest, useToast } from 'common';
import { useAddItemSchedule } from '../../../../services/proposal';

export const AddItemScheduleProvider = ({
  proposalId,
  children,
}: PropsWithChildren<{ proposalId: string }>) => {
  const qc = useQueryClient();
  const showToast = useToast();

  const { mutate } = useAddItemSchedule(
    proposalId,
    null,
    () => showToast.error('Item schedule add failed'),
    qc
  );

  const update = useCallback(
    (id: string, request: ItemScheduleRequest) => {
      mutate({ itemId: id, body: request });
    },
    [mutate]
  );

  return (
    <AddItemScheduleContext.Provider value={update}>
      {children}
    </AddItemScheduleContext.Provider>
  );
};
