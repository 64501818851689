import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  FormModal,
  Input,
  InputRef,
  useFormValidation,
  useToast,
  useTranslation,
} from 'common';
import { useCreateAPIToken } from '../../../services/apiToken';

interface Props {
  closeModal: () => void;
  isOpen: boolean;
}

const freshDraft = { name: '' };
const CreateAPITokenModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const showToast = useToast();
  const queryClient = useQueryClient();
  const { tk } = useTranslation();

  const { mutate: createToken } = useCreateAPIToken(
    () => {
      showToast.info(tk('Successfully created API token'));
      closeModal();
    },
    () => showToast.error(tk('Could not create API token')),
    queryClient
  );

  const [tokenDraft, setTokenDraft] = useState<{ name: string }>(freshDraft);
  const inputRef = useRef<InputRef>(null);

  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<{ name: string }>(
      [
        {
          fieldName: 'name',
          isRequired: true,
          humanReadableName: 'Token name',
        },
      ],
      tokenDraft
    );

  useEffect(() => {
    if (isOpen) {
      resetUIState();
      setTokenDraft(freshDraft);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.select();
        }
      }, 50);
    }
  }, [isOpen]);

  const submit = () => {
    createToken(tokenDraft);
  };

  const submitIfValid = () => {
    if (isFormValid) {
      submit();
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      width={500}
      entityTypeName="token"
      isSubmitDisabled={!isFormValid}
      onClose={closeModal}
      onSubmit={submit}
      isNewEntity
    >
      <div className="h-24">
        <div>Token name</div>
        <Input
          ref={inputRef}
          id="contact_first_name_input"
          placeholder="Enter token name"
          className="h-10 mt-2"
          onChange={(rv) => setTokenDraft({ name: rv })}
          value={tokenDraft.name}
          onEnter={submitIfValid}
          onBlur={() => setHasVisitedField('name')}
          errorToShow={getErrorToShow('name')}
        />
      </div>
    </FormModal>
  );
};

export default CreateAPITokenModal;
