import { FormField, OrgConfig, TextArea } from 'common';
import React from 'react';
import { useConnectorConfig } from '../../../services/connectors';

interface Props {
  connectorName: OrgConfig['configKey'];
}

const ConnectorStatus = ({ connectorName }: Props) => {
  const configData = useConnectorConfig(connectorName);

  return (
    <div className="connector-settings-tab">
      <FormField label="Config">
        <TextArea
          value={configData ? JSON.stringify(configData, null, 2) : ''}
          autoSize
        />
      </FormField>
    </div>
  );
};

export default ConnectorStatus;
