import React, { useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useToast, useTranslation } from 'common';
import { useQueryClient } from '@tanstack/react-query';
import { SETTINGS_SLACK, USER_NOTIFICATIONS } from '../../../core-utils/routes';
import {
  getSlackAppAuthRedirectUri,
  getSlackUserAuthRedirectUri,
  useCreateSlackUser,
  useCreateSlackWorkspace,
} from '../../../services/slack';
import {
  getUserFacingMessage,
  slackUserAlreadyUsedMsg,
} from '../../../services/utilities';

interface Props {
  isAuthingWorkspace: boolean; // else is authing user
}

const SlackViewCallback: React.FC<Props> = ({ isAuthingWorkspace }) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const queryClient = useQueryClient();
  const slackAuthCode = urlParams.get('code');
  const stateTokenKey = urlParams.get('state');
  const showToast = useToast();
  const { tk } = useTranslation();
  const isOnMountCalled = useRef(false);

  const { mutate: saveWorkspace } = useCreateSlackWorkspace(
    () => {
      showToast.success(
        tk('Slack app successfully added to your workspace.'),
        5
      );
    },
    () => {
      showToast.error(tk('Failed to connect to Slack. Please try again!'));
    },
    queryClient
  );

  const { mutate: authUser } = useCreateSlackUser(
    () => {
      showToast.success(tk('Notifications successfully enabled.'));
    },
    (error: unknown) => {
      // TODO: user-facing errors.....
      const parsedMessage = getUserFacingMessage(error);
      showToast.error(
        tk(
          parsedMessage === slackUserAlreadyUsedMsg
            ? slackUserAlreadyUsedMsg
            : 'Failed to connect to Slack. Please try again.'
        ),
        5
      );
    },
    queryClient
  );

  useEffect(() => {
    if (isOnMountCalled.current) return;
    isOnMountCalled.current = true;

    const body = {
      slackAuthCode,
      stateTokenKey,
    };
    if (isAuthingWorkspace) {
      saveWorkspace({
        ...body,
        originalRedirectUri: getSlackAppAuthRedirectUri(),
      });
    } else {
      authUser({
        ...body,
        originalRedirectUri: getSlackUserAuthRedirectUri(),
      });
    }
  }, []);

  return (
    <Navigate
      replace
      to={isAuthingWorkspace ? SETTINGS_SLACK : USER_NOTIFICATIONS}
    />
  );
};

export default SlackViewCallback;
