import React from 'react';
import { Event, SimpleCell, SimpleTable } from 'common';
import { useEventList } from '../../services/status';
import Page from '../../components/Page';

const SystemEventStatusPage: React.FC = () => {
  const { data } = useEventList();

  const objectDef = (event: any) => {
    if (event?.proposal) {
      return `proposal ${event.proposal.id}`;
    }
    if (event?.transferEvent) {
      return `transferEvent ${event.transferEvent.id}`;
    }
    if (event?.contract) {
      return `contract ${event.contract.id}`;
    }
    return `${event?.source?.id}`;
  };

  const eventCells: SimpleCell<Event>[] = [
    {
      key: 'eventType',
      headerLabel: 'Event Type',
      sortable: true,
      searchable: true,
      renderCell: (cell) => <div>{cell.eventType}</div>,
    },
    {
      key: 'groupId',
      headerLabel: 'Group Id',
      sortable: true,
      searchable: true,
      renderCell: (cell) => <div>{cell.groupId}</div>,
    },
    {
      key: 'orgId',
      headerLabel: 'Org Id',
      sortable: true,
      searchable: true,
      renderCell: (cell) => <div>{cell.orgId}</div>,
    },
    {
      key: 'object',
      headerLabel: 'Object',
      align: 'center',
      renderCell: (cell) => <div>{objectDef(cell)}</div>,
    },
  ];

  const resultCells: SimpleCell<any>[] = [
    {
      key: 'id',
      headerLabel: 'ID',
      sortable: true,
      searchable: true,
      renderCell: (cell) => <div>{cell.id}</div>,
    },
    {
      key: 'eventType',
      headerLabel: 'Event Type',
      sortable: true,
      searchable: true,
      renderCell: (cell) => <div>{cell.eventType}</div>,
    },
    {
      key: 'orgId',
      headerLabel: 'Org ID',
      sortable: true,
      searchable: true,
      renderCell: (cell) => <div>{cell.orgId}</div>,
    },
    {
      key: 'object',
      headerLabel: 'Object',
      align: 'center',
      renderCell: (cell) => <div>{objectDef(cell.event)}</div>,
    },
    {
      key: 'results',
      headerLabel: 'Results',
      align: 'center',
      renderCell: (cell) => (
        <div className="w-[150px] truncate hover:text-clip">{cell.results}</div>
      ),
    },
    {
      key: 'status',
      headerLabel: 'Status',
      align: 'center',
      renderCell: (cell) => <div>{cell.status}</div>,
    },
  ];

  return (
    <Page className="flex flex-col" leftWidget="Event Status">
      <div className="p-6">Event Results</div>

      <SimpleTable cells={resultCells} rows={data?.results} />

      <div className="p-6 border-t border-gray">Events</div>

      <SimpleTable cells={eventCells} rows={data?.events} />
    </Page>
  );
};

export default SystemEventStatusPage;
