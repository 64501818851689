import React from 'react';
import {
  BILLING_PERIODS,
  BillingPeriodsType,
  Button,
  ButtonBar,
  ConfirmModal,
  FormField,
  getBillingPeriodLabelAdverb,
  getBillingPeriodMonths,
  isNumber,
  Modal,
  NumberInput,
  Select,
  useTranslation,
} from 'common';
import ProductRadioButtonOption from '../../../Sales/Products/ProductsList/ProductRadioButtonOption';
import styles from './ProductsSection.module.scss';

interface Props {
  billingPeriod: BillingPeriodsType;
  isOpen: boolean;
  onClose: () => void;
  onSave: (nValue: number, bp: BillingPeriodsType, isIncrease: boolean) => void;
}
const PeriodOverPeriodPriceAdjustmentModal = ({
  isOpen,
  billingPeriod,
  onClose,
  onSave,
}: Props) => {
  if (!isOpen) {
    return null;
  }
  const { tk } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState<
    boolean | undefined
  >(false);
  const [nValue, setNValue] = React.useState<number | undefined>(0);
  const [bp, setBP] = React.useState<BillingPeriodsType | undefined>(
    billingPeriod
  );
  const [isIncrease, setIsIncrease] = React.useState<boolean>(true);
  const availableBillingPeriods = [...BILLING_PERIODS].filter(
    (billPer) =>
      getBillingPeriodMonths(billPer) >= getBillingPeriodMonths(billingPeriod)
  );
  return (
    <Modal
      isOpen={isOpen}
      header={tk('Modify period increase/decrease')}
      onClose={onClose}
      footer={
        <ButtonBar>
          <Button type="secondary" onClick={onClose} label="Cancel" />

          <Button
            isDisabled={nValue === undefined || !isNumber(nValue) || !bp}
            onClick={() => setIsConfirmModalOpen(true)}
            dataTestId="apply-adjustment-percent"
            label="Apply adjustment"
          />
        </ButtonBar>
      }
    >
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={onClose}
        onConfirm={() => nValue && bp && onSave(nValue / 100, bp, isIncrease)}
        header={tk('Are you sure?')}
        confirmText={tk('Apply Adjustment')}
      >
        {tk(
          ' All previous price adjustments on recurring products will be overwritten. Further adjustments can be made on individual products.'
        )}
      </ConfirmModal>
      <p>
        {tk(
          'Changes made here will apply to all recurring products in your proposal and will override any previously set discounts you might have applied to those products. One-time and Usage products will be unaffected.'
        )}
      </p>
      <FormField label={tk('How would you like to modify your products?')}>
        <ProductRadioButtonOption
          label={tk('Increase')}
          description={[
            tk(
              'Starting from the second billing period (based on what you select), the price is increased by a fixed percent.'
            ),
            tk(
              'Example: with a 10% increase, Year 1: $100, Year 2: $110, Year 3: $121.'
            ),
          ]}
          isChecked={isIncrease}
          onCheck={() => setIsIncrease(true)}
          isDisabled={false}
          dataTestId="increase"
        />
        <ProductRadioButtonOption
          label={tk('Decrease')}
          description={[
            tk(
              'Starting from the last billing period (based on what you select), the price is decreased by a fixed percent.'
            ),
            tk(
              'Example: with a 10% decrease, Year 1: $81, Year 2: $90, Year 3: $100.'
            ),
          ]}
          isChecked={!isIncrease}
          onCheck={() => setIsIncrease(false)}
          isDisabled={false}
          dataTestId="decrease"
        />
      </FormField>
      <div className={styles.percentSection}>
        <NumberInput
          dataTestId="percent-input"
          suffix="%"
          max={100}
          min={0}
          value={nValue}
          onChange={(newValue) => setNValue(newValue)}
        />
        <Select
          options={availableBillingPeriods.map((billP) => ({
            value: billP,
            name: getBillingPeriodLabelAdverb(billP),
          }))}
          value={bp}
          onChange={(val) => setBP(val)}
        />
      </div>
    </Modal>
  );
};

export default PeriodOverPeriodPriceAdjustmentModal;
