import React from 'react';
import { GoogleLoginButton, OktaLoginButton } from 'react-social-login-buttons';
import { Provider } from '../../../services/api/sso/providers';
import { getApiUrl } from '../../../services/helper';
import SalesforceLoginButton from './SalesforceLoginButton';
import MicrosoftLoginButton from './MicrosoftLoginButton';
import { LOGIN_ROUTE, SYSTEM_LOGIN_ROUTE } from '../../../core-utils/routes';
import HubspotLoginButton from './HubspotLoginButton';

const getLocationWithoutSubdomain = (isSystem: boolean): string => {
  let url = window.location.host.replace(/https?:\/\//i, '');
  const firstDotIndex = url.indexOf('.');
  const firstSubdomain = url.substring(0, firstDotIndex);
  url += isSystem ? SYSTEM_LOGIN_ROUTE : LOGIN_ROUTE;
  if (firstSubdomain === 'app') {
    // url is app.getcacheflow.com or app.dev.getcacheflow.com, return as is
    return `${window.location.protocol}//${url}`;
  }
  // url is foo.app.getcacheflow.com, return just app.getcacheflow.com
  return `${window.location.protocol}//${url.slice(firstDotIndex + 1)}`;
};

const getRedirectUrl = (
  isSystem: boolean,
  prevPath?: string,
  searchSuffix?: string
) => {
  return encodeURIComponent(
    `${getLocationWithoutSubdomain(isSystem)}${
      !isSystem && prevPath && prevPath !== '/' ? '/?prevPath=' + prevPath : '/'
    }${
      !isSystem && prevPath && searchSuffix && searchSuffix !== ''
        ? '&searchSuffix=' + searchSuffix
        : ''
    }`
  );
};

// brand_id=13053115377556&locale_id=1&return_to=https%3A%2F%2Fcacheflow.zendesk.com&sso_source=zendesk&timestamp=1678295806
const getSsoUrl = (
  isSystem: boolean,
  provider: Provider,
  prevPath?: string,
  searchSuffix?: string
) => {
  let ssoLink = `${getApiUrl(provider.path)}?redirect_uri=${getRedirectUrl(
    isSystem,
    prevPath,
    searchSuffix
  )}`;
  const queryParams = new URLSearchParams(location.search);

  const inviteCode = queryParams.get('invite_code');
  if (inviteCode) {
    return ssoLink + '&invite_code=' + inviteCode;
  }
  const ssoSource = queryParams.get('sso_source');
  if (ssoSource) {
    ssoLink = ssoLink + '&sso_source=' + ssoSource;
  }
  const returnTo = queryParams.get('return_to');
  if (returnTo) {
    ssoLink = ssoLink + '&return_to=' + returnTo;
  }

  return ssoLink;
};

interface Props {
  prevPath?: string;
  provider: Provider;
  searchSuffix?: string;
  system?: boolean;
}

const SsoButton: React.FC<Props> = ({
  provider,
  prevPath,
  searchSuffix,
  system,
}) => {
  const ssoRedirect = () => {
    window.location.href = getSsoUrl(
      !!system,
      provider,
      prevPath,
      searchSuffix
    );
  };

  // TODO move into message bundles
  const getText = () => {
    switch (provider.id) {
      case 'google':
        return 'Google';
      case 'fakegoogle':
        return 'Fake Google';
      case 'hubspot':
        return 'HubSpot';
      case 'systemgoogle':
        return 'System Google';
      case 'okta':
        return 'Okta';
      case 'salesforce':
        return 'Salesforce';
      case 'microsoft':
        return 'Microsoft';
      default:
        return '';
    }
  };

  switch (provider.id) {
    case 'google':
    case 'fakegoogle':
    case 'systemgoogle':
      return <GoogleLoginButton onClick={ssoRedirect} text={getText()} />;
    case 'okta':
      return <OktaLoginButton onClick={ssoRedirect} text={getText()} />;
    case 'salesforce':
      return <SalesforceLoginButton onClick={ssoRedirect} text={getText()} />;
    case 'microsoft':
      return <MicrosoftLoginButton onClick={ssoRedirect} text={getText()} />;
    case 'hubspot':
      return <HubspotLoginButton onClick={ssoRedirect} text={getText()} />;
    default:
      return <div />;
  }
};

export default SsoButton;
