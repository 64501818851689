import React from 'react';
import clsx from 'clsx';
import './eventMessage.scss';

const EventMessage = ({
  message,
  isError = false,
}: {
  isError?: boolean;
  message: string;
}) => {
  const classes = clsx({
    'event-message': true,
    'is-error': isError,
  });
  return <div className={classes}>{message}</div>;
};

export default EventMessage;
