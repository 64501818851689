import React, { useState } from 'react';
import { Button, cleanOptions, CardHeader, Select, useToast } from 'common';
import {
  useListMigrations,
  usePerformMigration,
} from 'app/src/services/api/system/organizations';

import styles from './ForceMigration.module.css';

interface Props {
  orgId: string;
}

export const ForceMigration: React.FC<Props> = ({ orgId }) => {
  const showToast = useToast();
  const [migration, setMigration] = useState<string>();

  const handleMigrationError = () => {
    showToast.error('Perform migration failed');
  };

  const handleMigrationSuccess = () => {
    showToast.info('Perform migration succeeded');
  };

  const { data: migrationDescs } = useListMigrations();
  const migrations = (migrationDescs || []).map((desc) => desc.jobName);

  const { mutate: doMigration } = usePerformMigration(
    orgId,
    migration || '',
    handleMigrationSuccess,
    handleMigrationError
  );

  return (
    <>
      <CardHeader name="Force Migration" />
      <div className={styles.container}>
        <Select
          onChange={(m) => setMigration(m)}
          options={cleanOptions(
            migrations.map((option) => ({
              name: option,
              value: option,
            })),
            'ForceMigration'
          )}
          value={migration}
        />
        <Button
          isDangerous
          isDisabled={!migration}
          label="Migrate"
          onClick={() => doMigration()}
        />
      </div>
    </>
  );
};
