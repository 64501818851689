import React from 'react';
import clsx from 'clsx';
import {
  assertDefined,
  BillingPeriodsType,
  getEntry,
  getLevelDescription,
  getTotalLinePrice,
  getZeroMoney,
  isNumber,
  Item,
  ItemLevelOverride,
  Money,
  NumberInput,
  PricebookEntry,
  Product,
  ProductLevel,
  ScheduleLine,
} from 'common';

import styles from './TBDTier.module.scss';
import ListPriceSalesPriceInput from '../../ListPriceSalesPriceInput';
import { isActiveTier } from './utils';

interface Props {
  billingPeriod: BillingPeriodsType;
  currency?: string;
  entries: PricebookEntry[];
  item: Item;
  period: number;
  pricing: Product['pricing'];
  quantity?: number;
  rowItem: ScheduleLine;
  tiers: ProductLevel[];
  updateQuantityOverride: (value: number, index: number) => void;
  updateRowItemLevelPrice: (
    salesPrice: Money,
    listPrice: Money,
    rowIndex: number,
    levelIndex: number
  ) => void;
}

const TBDTierRow = ({
  rowItem,
  entries,
  period,
  updateRowItemLevelPrice,
  updateQuantityOverride,
  quantity,
  currency,
  tiers,
  item,
  billingPeriod,
  pricing,
}: Props) => {
  const rowLevels =
    rowItem.levels?.length === tiers.length
      ? rowItem.levels
      : tiers.map((_tier, index) => {
          const level = rowItem.levels?.find(
            (rowlevel) => rowlevel.level === index
          );
          if (level) {
            return level;
          }
          const entry = getEntry(entries, currency, billingPeriod, index);
          if (entry) {
            return {
              level: index,
              price: entry.price,
            };
          }
          return {
            level: index,
            price: getZeroMoney(currency || 'USD'),
          };
        });
  const rowTotal = getTotalLinePrice(
    rowLevels,
    pricing,
    tiers,
    rowItem.quantity
  );
  return (
    <div
      className={clsx(styles.scheduleRow, !!quantity && styles.withQty)}
      data-testid={
        rowItem.startIndex === 0 ? 'tbp-schedule-first-row' : 'tbp-schedule-row'
      }
    >
      <p>{period}</p>
      <p>{rowTotal.formattedAmount}</p>
      {isNumber(quantity) && (
        <div className={styles.smallInput}>
          <NumberInput
            value={rowItem.quantity || quantity || 0}
            onChange={(value) => {
              if (isNumber(value)) {
                updateQuantityOverride(value, rowItem.startIndex);
              }
            }}
            dataTestId={`qty-input-${period}`}
          />
        </div>
      )}
      <div className={styles.tiers}>
        {rowLevels.map((level: ItemLevelOverride, levelIndex) => {
          const entry = assertDefined(
            getEntry(entries, currency, billingPeriod, levelIndex)
          );
          const basePrice = entry.price;
          const currentListPrice = level.listPrice || entry.price;
          const currentPrice = level.price;
          const activeTier = isActiveTier(
            item,
            levelIndex,
            rowItem.quantity || 0
          );

          return (
            <div
              // @ts-ignore TODO: Fix
              key={tiers[levelIndex].start}
              data-testid={`tbp-schedule-tier-${period}-${levelIndex}`}
              className={clsx(styles.tierRow, activeTier && styles.activeTier)}
            >
              <p className={styles.tierRange}>
                {getLevelDescription(
                  // @ts-ignore TODO: Fix
                  tiers[levelIndex],
                  tiers[levelIndex + 1]
                )}
              </p>
              <ListPriceSalesPriceInput
                timeBasedPricing
                id={`schedule-input-${period}-${levelIndex}`}
                basePrice={basePrice}
                currentListPrice={currentListPrice}
                currentPrice={currentPrice}
                isSaving={false}
                item={item}
                onPriceChange={(listPrice, salesPrice) =>
                  updateRowItemLevelPrice(
                    listPrice,
                    salesPrice,
                    rowItem.startIndex,
                    levelIndex
                  )
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TBDTierRow;
