import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  ApprovalGroup,
  ApprovalGroupRequest,
  AutoFormModal,
  Button,
  Loading,
  Type,
  useToast,
  ValidationFormField,
} from 'common';

import {
  useDeleteApprovalGroup,
  useGetAllApprovalGroups,
} from 'app/src/services/api/approvalGroups/approvalGroups';
import { useGetAllUsers } from 'app/src/services/users';
import { apiClient } from 'app/src/services/httpClients/app';
import { useGetProposalApprovalRules } from 'app/src/services/approvals';

import { ApprovalGroupRow } from './ApprovalGroupRow';
import { ApprovalGroupUniqueRow } from './ApprovalGroupUniqueRow';

import styles from './ApprovalGroups.module.scss';

export const ApprovalGroups = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState<ApprovalGroup | null>(null);

  const { data: allUsers, isLoading: isAllUsersLoading } = useGetAllUsers();
  const { data: approvalGroups, isLoading: isApprovalGroupsLoading } =
    useGetAllApprovalGroups();
  const {
    data: proposalApprovalRules,
    isLoading: isProposalApprovalRulesLoading,
  } = useGetProposalApprovalRules();

  const deleteApprovalGroup = useDeleteApprovalGroup(
    () => {},
    () => {},
    queryClient
  );

  const handleAddApprovalGroup = () => {
    setIsModalOpen(true);
    setGroupToEdit(null);
  };

  const handleSavingGroup = (formData: ApprovalGroupRequest) => {
    if (editingGroupName) {
      return apiClient.updateApprovalGroup(groupToEdit?.id!, formData);
    } else {
      return apiClient.createApprovalGroup(formData);
    }
  };

  const handleGroupDelete = () => {
    if (groupToEdit) {
      deleteApprovalGroup.mutate(groupToEdit.id);
      toast.info('Approval group deleted');
    }
  };

  const handleSaveSuccess = () => {
    setIsModalOpen(false);
  };

  const formFields: ValidationFormField<ApprovalGroupRequest>[] = [
    {
      fieldName: 'name',
      humanReadableName: 'Group name',
      isRequired: true,
    },
  ];

  const adminUsersSelectOptions = allUsers
    ?.filter((user) => user.roleType === 'admin')
    .map((user) => ({
      value: user.user?.id,
      label: `${user.user?.firstName} ${user.user?.lastName}`,
    }));

  const isGroupInRule = proposalApprovalRules?.some((par) => {
    return par.approvalGroupIds?.includes(groupToEdit?.id!);
  });

  const editingGroupName = groupToEdit
    ? { name: groupToEdit.group?.name }
    : null;

  const isAbleToDelete = !!editingGroupName && !isGroupInRule;

  if (
    isAllUsersLoading ||
    isApprovalGroupsLoading ||
    isProposalApprovalRulesLoading
  ) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.groupSection}>
        <Type header={4}>Approval groups</Type>
        <div className={styles.groupList}>
          {approvalGroups?.map((approvalGroup) => {
            return (
              <ApprovalGroupRow
                key={approvalGroup.id}
                approvalGroup={approvalGroup}
                usersListOptions={adminUsersSelectOptions}
                onEdit={() => {
                  setGroupToEdit(approvalGroup);
                  setIsModalOpen(true);
                }}
              />
            );
          })}
        </div>
        <Button
          onClick={handleAddApprovalGroup}
          type="secondary"
          label="+ Approval group"
          dataTestId="add-approval-group"
          className={styles.addGroupButton}
        />
      </div>

      <Type header={4}>Approval exclusion</Type>
      <ApprovalGroupUniqueRow
        configKey="approvalExclusionGroup"
        usersListOptions={adminUsersSelectOptions}
      />

      {isModalOpen && (
        <AutoFormModal
          entity={editingGroupName}
          entityName="approval group"
          fields={formFields}
          isOpen={isModalOpen}
          onClose={() => {
            setGroupToEdit(null);
            setIsModalOpen(false);
          }}
          removeDeleteConfirmation
          onDelete={editingGroupName ? handleGroupDelete : undefined}
          isAbleToDelete={isAbleToDelete}
          cannotDeleteReason="Approval group in use. Please first remove the group from any conditions."
          onSave={handleSavingGroup}
          queryKey={['/api/latest/data/approval-groups']}
          onSuccess={handleSaveSuccess}
          queryClient={queryClient}
        />
      )}
    </>
  );
};
