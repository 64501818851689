import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AutoForm, OrgConfig, ValidationFormField } from 'common';
import { getTimeZones, TimeZone } from '@vvo/tzdb';
import {
  ORG_DEFAULTS_PATH,
  useFindOrgDefault,
} from '../../../services/orgDefaults';
import { apiClient } from '../../../services/httpClients/app';

/* only get timezones once, otherwise there is huge lag on every render */
const timeZones = getTimeZones({ includeUtc: true });
const CONFIG_KEY: OrgConfig['configKey'] = 'timezone';

const CompanyTimeZone: React.FC = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useFindOrgDefault(CONFIG_KEY);
  const fields: ValidationFormField<OrgConfig>[] = [
    {
      type: 'searchSelect',
      fieldName: 'stringValue',
      humanReadableName: 'Time zone',
      infoText:
        'Set the time zone for dates in proposals, subscriptions, and payments.',
      inputProps: {
        valueIsKey: true,
        options: timeZones,
        keyField: (tz: TimeZone) => tz.name,
        displayField: (tz: TimeZone) =>
          `(${tz.abbreviation}) ${tz.currentTimeFormat}`,
      },
    },
  ];
  const onSave: React.ComponentProps<typeof AutoForm<OrgConfig>>['onSave'] = (
    payload
  ) => apiClient.upsertOrgConfig(CONFIG_KEY, payload);

  return (
    <AutoForm
      autoSave
      entity={data}
      entityName="company"
      fields={fields}
      isDisabled={isLoading}
      onSave={onSave}
      queryClient={queryClient}
      queryKey={[ORG_DEFAULTS_PATH]}
      showControls={false}
    />
  );
};

export default CompanyTimeZone;
