/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  ALL_TRANSACTIONS,
  APP,
  ATTACHMENTS,
  BILLING,
  CLOSE_OPPORTUNITIES,
  CUSTOMERS,
  DOCUSIGN_CALLBACK,
  EMAILS_SENT,
  EVENT_STATUS,
  HUBSPOT_DEALS,
  HUBSPOT_DEALS_CALLBACK,
  HUBSPOT_SIGNUP,
  INTEGRATIONS_AND_CONNECTORS,
  INVOICES,
  LOGIN_ROUTE,
  MAINTENANCE,
  ORG_SELECTION_ROUTE,
  PAYMENTS,
  PLAYWRIGHT_TRIGGERS,
  PRODUCTS,
  PROPOSAL_EDITOR,
  PROPOSALS,
  QUICKBOOKS_RECONCILIATION,
  REFUNDS,
  REPORTS,
  SALESFORCE_OPPORTUNITIES,
  SALESFORCE_OPPORTUNITIES_CALLBACK,
  SETTINGS,
  SINGLE_CUSTOMER,
  SINGLE_INVOICE,
  SINGLE_PAYMENT,
  SINGLE_PRODUCT,
  SINGLE_REFUND,
  SINGLE_SUBSCRIPTION,
  SLACK_CALLBACK_USER,
  SLACK_CALLBACK_WORKSPACE,
  STATUS,
  SUBSCRIPTIONS,
  SYNC_EVENTS,
  SYSTEM_DASHBOARD_ROUTE,
  SYSTEM_LOGIN_ROUTE,
  SYSTEM_ORGANIZATION_ROUTE,
  SYSTEM_ROUTE,
  SYSTEM_TODAY,
  UPCOMING_TRANSFERS,
  USER,
} from '../../core-utils/routes';
import ContractsListPage from '../../pages/Subscriptions/ContractsListPage';
import SubscriptionDetailPage from '../../pages/Subscriptions/SubscriptionDetail/SubscriptionDetailPage';
import BillingScheduleList from '../../pages/BillingSchedules/BillingScheduleList';
import RenderRoutes from './RenderRoutes';
import SystemBasePage from '../../components/templates/SystemBasePage';
import {
  useCanAccessUserRoutes,
  FLAG_TYPES,
  useCanAccessSystemAdminRoutes,
} from '../../core-utils/helperFunctions/userServiceHelper';
import PlaywrightTriggers from '../../pages/Playwright/PlaywrightTriggers';
import SystemOrganizationsPage from '../../pages/System/SystemOrganizationsPage';
import OrganizationDetailPage from '../../pages/System/Organization/OrganizationDetailPage';
import SystemProposalsPage from '../../pages/System/SystemProposalsPage';
import TransactionsPage from '../../pages/Transactions/TransactionsPage';
import Settings from '../../pages/Settings';
import CustomersPage from '../../pages/Sales/Customers/CustomersPage';
import CustomerPage from '../../pages/Sales/Customers/CustomerPage';
import ProductListPage from '../../pages/Sales/Products/ProductsList/ProductListPage';
import EditProductPage from '../../pages/Sales/Products/ProductsList/EditProductPage';
import ProposalListPage from '../../pages/Proposals/ProposalList/ProposalListPage';
import AppPage from '../../components/templates/AppPage';
import FullPage from '../../components/templates/FullPage';
import LoginPage from '../../pages/Login/LoginPage';
import OrgSelectionPage from '../../pages/Login/OrgSelectionPage';
import DocusignCallbackPage from '../../pages/Settings/Docusign/DocusignCallbackPage';
import SlackCallbackPage from '../../pages/Settings/Slack/SlackCallbackPage';
import SystemStatusPage from '../../pages/System/SystemStatusPage';
import Attachments from '../../pages/Settings/Attachments/Attachments';
import OpportunitiesPage from '../../pages/ExternalDeals/Salesforce/OpportunitiesPage';
import OpportunitiesCallback from '../../pages/ExternalDeals/Salesforce/OpportunitiesCallback';
import SystemEventStatusPage from '../../pages/System/SystemEventStatusPage';
import UpcomingTransfersPage from '../../pages/System/UpcomingTransfers/UpcomingTransfersPage';
import ProposalLoader from '../../pages/Proposals/ProposalEditor/ProposalLoader';
import UserPage from '../../pages/User/UserPage';
import IntegrationsAndConnectorsList from '../../pages/IntegrationsAndConnectors/IntegrationsAndConnectorsList';
import HubspotDeals from '../../pages/ExternalDeals/Hubspot/HubspotDeals';
import HubspotDealsCallback from '../../pages/ExternalDeals/Hubspot/HubspotDealsCallback';
import HubspotSignup from '../../pages/ExternalDeals/Hubspot/HubspotSignup';
import HelpChatHook from '../../pages/System/HelpChat/HelpChatHook';
import { LaunchDarklyWrapper } from '../../core-utils/helperFunctions/launchDarkly';
import { UserflowWrapper } from '../../core-utils/helperFunctions/userflow';
import ReportsPage from 'app/src/pages/Reports/ReportsPage';
import EmailHistory from 'app/src/pages/System/EmailHistory';

import { Profile, Reference, ToastWrapper } from 'common';
import CloseOpportunities from '../../pages/ExternalDeals/Close/CloseOpportunities';
import MaintenancePage from '../../pages/Maintenance/MaitenancePage';
import SyncEvents from 'app/src/pages/Settings/SyncEvents/SyncEvents';
import SystemReconciliation from 'app/src/pages/Settings/SyncEvents/SystemReconciliation';
import { RefundsPage } from 'app/src/pages/Refunds/RefundsPage/RefundsPage';
import { RefundDetailsPage } from 'app/src/pages/Refunds/RefundDetailsPage/RefundDetailsPage';
import { PaymentsPage } from 'app/src/pages/Payments/PaymentsPage';
import { PaymentDetailPage } from '../../pages/Payments/PaymentDetailPage';
import { InvoicesPage } from 'app/src/pages/Invoices/InvoicesPage';
import { InvoiceDetailsPage } from 'app/src/pages/Invoices/InvoiceDetailsPage';
import { ConnectionProvider } from 'app/src/pages/Settings/Connectors/ConnectionProvider';

export interface RouteDef {
  action?: NonNullable<Reference['actions']>[number];
  authKey?: (typeof FLAG_TYPES)[keyof typeof FLAG_TYPES];
  component: React.ComponentType | React.ComponentType<{ routes: RouteDef[] }>;
  excludedInProduction?: boolean;
  hasNestedRoutes?: true;
  path: string;
  roles?: Profile['role'][];
  routes?: RouteDef[];
}

export const SETTINGS_ROUTE: RouteDef = {
  component: () => (
    <ConnectionProvider>
      <AppPage mainContent={<Settings />} startRoute={SETTINGS} />
    </ConnectionProvider>
  ),
  hasNestedRoutes: true,
  path: SETTINGS,
  roles: ['admin'],
};

export const USER_ROUTE: RouteDef = {
  component: () => <AppPage mainContent={<UserPage />} startRoute={USER} />,
  hasNestedRoutes: true,
  path: USER,
};

const SystemAdminRoutes: React.FC<{ routes: RouteDef[] }> = (props) =>
  useCanAccessSystemAdminRoutes() ? (
    <RenderRoutes {...props} />
  ) : (
    <Navigate replace to={{ pathname: SYSTEM_LOGIN_ROUTE }} />
  );

const UserRoutes: React.FC<{ routes: RouteDef[] }> = (props) =>
  useCanAccessUserRoutes() ? (
    <LaunchDarklyWrapper>
      <HelpChatHook>
        <UserflowWrapper>
          <ToastWrapper>
            <RenderRoutes {...props} />
          </ToastWrapper>
        </UserflowWrapper>
      </HelpChatHook>
    </LaunchDarklyWrapper>
  ) : (
    <Navigate
      replace
      state={{
        prevPath: location.pathname,
        searchSuffix: null,
      }}
      to={LOGIN_ROUTE}
    />
  );

const ROUTES: RouteDef[] = [
  {
    path: LOGIN_ROUTE,
    component: () => (
      <LaunchDarklyWrapper>
        <LoginPage />
      </LaunchDarklyWrapper>
    ),
  },
  {
    path: ORG_SELECTION_ROUTE,
    component: () => <OrgSelectionPage />,
  },
  {
    path: SYSTEM_LOGIN_ROUTE,
    component: () => <LoginPage isSystem />,
  },
  {
    path: SYSTEM_ROUTE,
    hasNestedRoutes: true,
    component: SystemAdminRoutes,
    routes: [
      {
        path: SYSTEM_DASHBOARD_ROUTE,
        component: () => (
          <SystemBasePage
            mainContent={<SystemOrganizationsPage />}
            startRoute={SYSTEM_DASHBOARD_ROUTE}
          />
        ),
      },
      {
        path: SYSTEM_ORGANIZATION_ROUTE,
        component: () => (
          <SystemBasePage
            mainContent={<OrganizationDetailPage />}
            startRoute={SYSTEM_ORGANIZATION_ROUTE}
          />
        ),
      },
      {
        path: SYSTEM_TODAY,
        component: () => (
          <SystemBasePage
            mainContent={<SystemProposalsPage />}
            startRoute={SYSTEM_TODAY}
          />
        ),
      },
      {
        path: STATUS,
        component: () => (
          <SystemBasePage
            mainContent={<SystemStatusPage />}
            startRoute={STATUS}
          />
        ),
      },
      {
        path: UPCOMING_TRANSFERS,
        component: () => (
          <SystemBasePage
            mainContent={<UpcomingTransfersPage />}
            startRoute={UPCOMING_TRANSFERS}
          />
        ),
      },
      {
        path: SYNC_EVENTS,
        component: () => (
          <SystemBasePage
            mainContent={<SyncEvents />}
            startRoute={SYNC_EVENTS}
          />
        ),
      },
      {
        path: QUICKBOOKS_RECONCILIATION,
        component: () => (
          <SystemBasePage
            mainContent={<SystemReconciliation />}
            startRoute={QUICKBOOKS_RECONCILIATION}
          />
        ),
      },
      {
        path: EMAILS_SENT,
        component: () => (
          <SystemBasePage
            mainContent={<EmailHistory />}
            startRoute={EMAILS_SENT}
          />
        ),
      },
      {
        path: EVENT_STATUS,
        component: () => (
          <SystemBasePage
            mainContent={<SystemEventStatusPage />}
            startRoute={EVENT_STATUS}
          />
        ),
      },
    ],
  },
  {
    path: DOCUSIGN_CALLBACK,
    component: () => <DocusignCallbackPage />,
  },
  {
    path: SLACK_CALLBACK_WORKSPACE,
    component: () => <SlackCallbackPage isAuthingWorkspace />,
  },
  {
    path: SLACK_CALLBACK_USER,
    component: () => <SlackCallbackPage isAuthingWorkspace={false} />,
  },
  {
    path: HUBSPOT_SIGNUP,
    component: () => <HubspotSignup />,
  },
  {
    path: MAINTENANCE,
    component: () => (
      <LaunchDarklyWrapper>
        <FullPage>
          <MaintenancePage />
        </FullPage>
      </LaunchDarklyWrapper>
    ),
  },
  {
    hasNestedRoutes: true,
    path: APP,
    component: UserRoutes,
    routes: [
      {
        authKey: FLAG_TYPES.PROPOSAL,
        path: PROPOSALS,
        component: () => (
          <ConnectionProvider>
            {' '}
            <AppPage
              mainContent={<ProposalListPage />}
              startRoute={PROPOSALS}
            />
          </ConnectionProvider>
        ),
      },
      {
        authKey: FLAG_TYPES.CONTRACT,
        path: SUBSCRIPTIONS,
        component: () => (
          <AppPage
            mainContent={<ContractsListPage />}
            startRoute={SUBSCRIPTIONS}
          />
        ),
      },

      {
        authKey: FLAG_TYPES.CONTRACT,
        path: SINGLE_SUBSCRIPTION,
        action: 'read',
        component: () => (
          <AppPage
            mainContent={<SubscriptionDetailPage />}
            startRoute={SINGLE_SUBSCRIPTION}
          />
        ),
      },

      {
        hasNestedRoutes: true,
        path: BILLING,
        component: () => (
          <AppPage mainContent={<BillingScheduleList />} startRoute={BILLING} />
        ),
      },
      {
        authKey: FLAG_TYPES.TRANSFER,
        path: ALL_TRANSACTIONS,
        component: () => (
          <AppPage
            mainContent={<TransactionsPage />}
            startRoute={ALL_TRANSACTIONS}
          />
        ),
      },
      {
        authKey: FLAG_TYPES.TRANSFER,
        path: PAYMENTS,
        component: () => (
          <AppPage mainContent={<PaymentsPage />} startRoute={PAYMENTS} />
        ),
      },
      {
        path: REFUNDS,
        component: () => (
          <AppPage mainContent={<RefundsPage />} startRoute={REFUNDS} />
        ),
      },
      {
        path: SINGLE_REFUND,
        component: () => (
          <AppPage
            mainContent={<RefundDetailsPage />}
            startRoute={SINGLE_REFUND}
          />
        ),
      },
      {
        authKey: FLAG_TYPES.TRANSFER,
        path: SINGLE_PAYMENT,
        component: () => (
          <AppPage
            mainContent={<PaymentDetailPage />}
            startRoute={SINGLE_PAYMENT}
          />
        ),
      },
      SETTINGS_ROUTE,
      {
        path: INTEGRATIONS_AND_CONNECTORS,
        component: () => (
          <ConnectionProvider>
            <AppPage
              mainContent={<IntegrationsAndConnectorsList />}
              startRoute={INTEGRATIONS_AND_CONNECTORS}
            />
          </ConnectionProvider>
        ),
      },
      {
        path: PLAYWRIGHT_TRIGGERS,
        roles: ['admin'],
        excludedInProduction: true,
        component: () => (
          <AppPage
            mainContent={<PlaywrightTriggers />}
            startRoute={PLAYWRIGHT_TRIGGERS}
          />
        ),
      },
      {
        authKey: FLAG_TYPES.PRODUCT,
        path: PRODUCTS,
        roles: ['admin'],
        component: () => (
          <AppPage mainContent={<ProductListPage />} startRoute={PRODUCTS} />
        ),
      },
      {
        authKey: FLAG_TYPES.PRODUCT,
        path: SINGLE_PRODUCT,
        roles: ['admin'],
        component: () => (
          <AppPage
            hasWhiteBackground
            mainContent={<EditProductPage />}
            startRoute={SINGLE_PRODUCT}
          />
        ),
      },
      {
        path: INVOICES,
        component: () => (
          <AppPage mainContent={<InvoicesPage />} startRoute={INVOICES} />
        ),
      },
      {
        path: SINGLE_INVOICE,
        component: () => (
          <AppPage
            mainContent={<InvoiceDetailsPage />}
            startRoute={SINGLE_INVOICE}
          />
        ),
      },

      {
        authKey: FLAG_TYPES.CUSTOMER,
        path: CUSTOMERS,
        component: () => (
          <AppPage mainContent={<CustomersPage />} startRoute={CUSTOMERS} />
        ),
      },
      {
        authKey: FLAG_TYPES.CUSTOMER,
        path: SINGLE_CUSTOMER,
        component: () => (
          <AppPage
            mainContent={<CustomerPage />}
            startRoute={SINGLE_CUSTOMER}
          />
        ),
      },
      {
        authKey: FLAG_TYPES.PROPOSAL,
        path: PROPOSAL_EDITOR,
        component: () => (
          <FullPage>
            <ProposalLoader />
          </FullPage>
        ),
      },
      {
        hasNestedRoutes: true,
        path: ATTACHMENTS,
        roles: ['admin'],
        component: () => (
          <AppPage mainContent={<Attachments />} startRoute={ATTACHMENTS} />
        ),
      },
      {
        authKey: FLAG_TYPES.PROPOSAL,
        path: SALESFORCE_OPPORTUNITIES,
        component: () => (
          <AppPage
            mainContent={<OpportunitiesPage />}
            startRoute={SALESFORCE_OPPORTUNITIES}
          />
        ),
      },
      {
        authKey: FLAG_TYPES.PROPOSAL,
        path: HUBSPOT_DEALS,
        component: () => (
          <AppPage mainContent={<HubspotDeals />} startRoute={HUBSPOT_DEALS} />
        ),
      },
      {
        authKey: FLAG_TYPES.PROPOSAL,
        path: SALESFORCE_OPPORTUNITIES_CALLBACK,
        component: () => <OpportunitiesCallback />,
      },
      {
        authKey: FLAG_TYPES.PROPOSAL,
        path: HUBSPOT_DEALS_CALLBACK,
        component: () => <HubspotDealsCallback />,
      },
      {
        authKey: FLAG_TYPES.PROPOSAL,
        path: CLOSE_OPPORTUNITIES,
        component: () => (
          <AppPage
            mainContent={<CloseOpportunities />}
            startRoute={CLOSE_OPPORTUNITIES}
          />
        ),
      },
      USER_ROUTE,
      {
        hasNestedRoutes: true,
        path: REPORTS,
        component: () => (
          <AppPage mainContent={<ReportsPage />} startRoute={REPORTS} />
        ),
      },
    ],
  },
];

export default ROUTES;
