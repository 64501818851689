import React from 'react';
import { getInputClasses } from 'common';
import RichTextEditor, { RichTextEditorProps } from './RichTextEditor';

const RichTextInput: React.FC<RichTextEditorProps> = (props) => {
  return (
    <RichTextEditor
      inputClassName={getInputClasses({
        isDisabled: props.isDisabled,
      })}
      fireDebounceMs={0}
      fireOnDebounce
      {...props}
    />
  );
};

export default RichTextInput;
