import {
  ContactInternal,
  getPersonFullName,
  Icon,
  SIZE_SMALL,
  useToast,
  useTranslation,
} from 'common';
import React from 'react';
import StatusLabel from '../../../../components/StatusLabel';
import { useFlowDomain } from '../../../../core-utils/helperFunctions/userServiceHelper';
import { getCheckoutUrl } from '../../../../services/helper';

interface Props {
  contact: ContactInternal;
  isDisabled?: boolean;
  isProposalSigned: boolean;
  onClick: () => void;
  onClickRemove: () => void;
  proposalId: string;
  proposalIsAccepted: boolean;
  signerEmail: string;
}

const ProposalContact: React.FC<Props> = ({
  contact,
  onClick,
  proposalIsAccepted,
  proposalId,
  signerEmail,
  isProposalSigned,
  onClickRemove,
  isDisabled,
}: Props) => {
  const { tk } = useTranslation();
  const orgName = useFlowDomain();
  const showToast = useToast();

  const copyUrl = async (
    e: React.MouseEvent<HTMLButtonElement>,
    email: string
  ) => {
    e.stopPropagation();
    const linkUrl = getCheckoutUrl(proposalId, email, orgName!);
    await navigator.clipboard.writeText(linkUrl);
    showToast.info('Copied to clipboard!');
  };

  const removeButton = () => {
    return (
      <button
        type="button"
        className={`flex items-center self-center p-2 rounded-md text-black bg-slate-lightestest hover:text-red ${isDisabled ? 'invisible' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          onClickRemove();
        }}
      >
        <Icon.Trash {...SIZE_SMALL} style={{ margin: '-3px' }} />
        <div className="ml-2 text-xs">Remove</div>
      </button>
    );
  };

  return (
    <div
      className={`px-6 py-2 flex justify-between ${
        proposalIsAccepted || isDisabled ? '' : 'cursor-pointer'
      }`}
      onClick={!proposalIsAccepted && !isDisabled ? () => onClick() : () => {}}
      key={contact.id}
    >
      <div>
        <div className="font-semibold">{getPersonFullName(contact)}</div>
        <div className="text-xs max-w-[200px] overflow-hidden text-ellipsis">
          {contact.email.toLowerCase()}
        </div>
        <div className="text-xs">{contact.role}</div>
      </div>
      <div className="flex items-center justify-between gap-1">
        <button
          type="button"
          className="flex items-center self-center p-2 rounded text-blue bg-blue-lighter hover:text-white hover:bg-blue"
          data-testid="copy-checkout"
          data-checkoutlink={getCheckoutUrl(
            proposalId,
            contact.email,
            orgName!
          )}
          onClick={(e) => copyUrl(e, contact.email)}
        >
          <Icon.Copy {...SIZE_SMALL} style={{ margin: '-3px' }} />
          <div className="ml-1 whitespace-nowrap text-xs font-bold">
            {tk('Copy link')}
          </div>
        </button>

        {signerEmail === contact.email && isProposalSigned ? (
          <StatusLabel
            status="SIGNER"
            classNames="px-2 py-4 text-green bg-green-lighter rounded-md"
          />
        ) : (
          removeButton()
        )}
      </div>
    </div>
  );
};

export default ProposalContact;
