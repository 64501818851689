import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '../../httpClients/app';
import { getName } from 'country-list';

const ALL_COUNTRY_METADATA_KEY = 'CountriesMetadata';

interface Country {
  code: string;
  currencies?: string[];
  name: string;
}

const getCountryName = (code: string): string => {
  const OVERRIDES: { [key: string]: string } = {
    US: 'United States',
    GB: 'United Kingdom',
  };
  const name: string = OVERRIDES[code] || getName(code) || code;
  return `${name} (${code})`;
};

const paymentCountries = async () => {
  const { data } = await apiClient.getStripeCountries();
  return data.map((code) => {
    return {
      code,
      name: getCountryName(code),
    };
  });
};

export const usePaymentCountries = (): UseQueryResult<Country[]> =>
  useQuery({
    queryKey: [ALL_COUNTRY_METADATA_KEY],
    queryFn: paymentCountries,
  });

export const countryCurrencies = async (code: string): Promise<string[]> => {
  const { data } = await apiClient.getStripeCountryCurrencies(code);
  return data;
};
