import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  CreatePaymentMethodRequest,
  Input,
  PaymentMethod,
  UpdatePaymentMethodRequest,
  useFormValidation,
  useToast,
  useTranslation,
} from 'common';
import { useDeletePaymentMethod } from '../../../services/paymentMethods';

interface Props {
  closeForm: () => void;
  methodBeingEdited?: PaymentMethod;
  submit: (updatePaymentMethodRequest: UpdatePaymentMethodRequest) => void;
}

const getInitialDraft = (
  paymentMethod?: PaymentMethod
): CreatePaymentMethodRequest => {
  return {
    name: paymentMethod?.name || '',
    description: paymentMethod?.description || '',
    customText: paymentMethod?.customText || '',
    paymentType: 'custom',
  };
};

const CustomPaymentForm: React.FC<Props> = ({
  methodBeingEdited,
  submit,
  closeForm,
}) => {
  const { tk } = useTranslation();
  const [draft, setDraft] = useState<UpdatePaymentMethodRequest>(
    getInitialDraft(methodBeingEdited)
  );

  const { getErrorToShow, setHasVisitedField, isFormValid } =
    useFormValidation<UpdatePaymentMethodRequest>(
      [
        {
          fieldName: 'name',
          isRequired: true,
          humanReadableName: 'Name',
        },
      ],
      draft
    );

  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleDeleteSuccess = () => {
    showToast.info(tk('Successfully deleted payment method.'));
    closeForm();
  };

  const handleDeleteError = () => {
    showToast.info(tk('Could not delete payment method.'));
  };

  const { mutate: deletePaymentMethod } = useDeletePaymentMethod(
    handleDeleteSuccess,
    handleDeleteError,
    queryClient
  );

  const save = () => {
    submit(draft);
  };

  const handleFieldChange = (fieldName: string, value: any) => {
    setDraft((prev) => ({ ...prev, [fieldName]: value }));
  };

  const submitIfValid = () => {
    if (isFormValid) {
      save();
    }
  };

  return (
    <>
      <div className="h-2" />
      <div>{tk('Name')}</div>
      <Input
        id="name"
        className="h-8"
        onChange={(rv) => handleFieldChange('name', rv)}
        value={draft.name}
        onEnter={submitIfValid}
        errorToShow={getErrorToShow('name')}
        onBlur={() => setHasVisitedField('name')}
      />
      <div className="h-4" />
      <div>{tk('Description')}</div>
      <Input
        id="description"
        className="h-8"
        onChange={(rv) => handleFieldChange('description', rv)}
        value={draft.description}
        onEnter={submitIfValid}
      />
      <div className="h-4" />
      <div>{tk('Details')}</div>
      <textarea
        id="customText"
        className="h-24 block w-full pr-10 focus:outline-none focus:ring-red-500 sm:text-sm rounded-[4px] bg-slate-lightest border focus:border-2 px-[13px] focus:px-3 focus:border-blue border-black border-opacity-10"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          handleFieldChange('customText', e.target.value)
        }
        value={draft.customText}
      />
      <div className="flex justify-between  w-full pt-6">
        <div className="flex flex-col justify-center">
          {methodBeingEdited && (
            <div
              className="text-red underline cursor-pointer"
              onClick={() => deletePaymentMethod(methodBeingEdited)}
            >
              Delete
            </div>
          )}
        </div>
        <div className="flex gap-2">
          <Button type="secondary" label={tk('Cancel')} onClick={closeForm} />
          <Button isDisabled={!isFormValid} label={tk('Save')} onClick={save} />
        </div>
      </div>
    </>
  );
};

export default CustomPaymentForm;
