import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { Account, CreateAccountsWithPlaidRequest } from 'common';

interface PlaidLinkToken {
  error: string;
  linkToken: string;
}

export const generatePlaidToken = (
  enabled: boolean
): UseQueryResult<PlaidLinkToken> =>
  useQuery({
    queryKey: ['apiClient.getFundingAccount'],
    queryFn: async () => {
      const { data } = await apiClient.initCreateAccountWithPlaid();
      // TODO: This response type should be fixed on the backend
      return data as unknown as PlaidLinkToken;
    },
    enabled,
  });

export const useLinkAccount = (
  onSuccess: (data: Account[]) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<Account[], unknown, CreateAccountsWithPlaidRequest>({
    mutationKey: ['apiClient.createAccountsWithPlaid'],
    mutationFn: async (variables) => {
      const { data } = await apiClient.createAccountsWithPlaid(variables);
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: ['apiClient.listAccounts'] });
    },
  });

export const useAccounts = (): UseQueryResult<Account[]> =>
  useQuery({
    queryKey: ['apiClient.listAccounts'],
    queryFn: async () => {
      const { data } = await apiClient.listAccounts();
      return data;
    },
  });
