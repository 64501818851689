import React, { useState } from 'react';
import { FormField, InputRef, NumberInput } from 'common';

interface Props {
  editable: boolean;
  onChange: (quantity: number | undefined) => void;
  onPressEnter: () => void;
  productName: string;
  quantity?: number;
}

const ProductUsageField = React.forwardRef<InputRef, Props>(
  ({ productName, quantity, editable, onChange, onPressEnter }, ref) => {
    const [hasVisited, setHasVisited] = useState<boolean>(false);

    const error =
      hasVisited && quantity === undefined ? 'Quantity is required' : undefined;

    return (
      <FormField label={productName} errorToShow={error}>
        <NumberInput
          isDisabled={!editable}
          maxScale={2}
          onBlur={() => setHasVisited(true)}
          onChange={onChange}
          onEnter={onPressEnter}
          placeholder="Quantity"
          ref={ref}
          value={quantity}
        />
      </FormField>
    );
  }
);

export default ProductUsageField;
