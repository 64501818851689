import React, { useState } from 'react';
import clsx from 'clsx';
import { NavSubMenu } from './sideNavBar';
import { NavigationIcon, useTranslation } from 'common';

interface Props {
  children: React.ReactNode;
  navSubMenu: NavSubMenu;
  selectedRoute: string;
}

const SubMenu: React.FC<Props> = ({
  selectedRoute,
  navSubMenu,
  children,
}: Props) => {
  const { tk } = useTranslation();
  const subRoutes = navSubMenu.navMenuItems.map((value) => value.route);

  const [openDrawer, setOpenDrawer] = useState<boolean>(
    subRoutes.includes(selectedRoute)
  );

  return (
    <div>
      <div className={clsx('rounded-md', openDrawer && 'bg-blue-lightest')}>
        {children && (
          <div
            className="flex items-center bg-transparent h-12 w-full cursor-pointer"
            id={navSubMenu.label}
            data-testid={navSubMenu.label}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <div className="w-12 flex item-center justify-center">
              <div
                className={`${
                  openDrawer ? '' : 'rotate-[-90deg]'
                } transition-all duration-300`}
              >
                <NavigationIcon.Foldedout width={32} height={32} />
              </div>
            </div>
            <div className="hover:text-blue">{tk(navSubMenu.label)}</div>
          </div>
        )}
        {children && openDrawer && children}
      </div>
    </div>
  );
};
export default SubMenu;
