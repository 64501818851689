import React, { SVGProps } from 'react';
import clsx from 'clsx';
import {
  arraySafeAccess,
  BillingItem,
  BillingSchedule,
  Cell,
  DEFAULT,
  Drawer,
  formatNullableFormattedAmount,
  isNumber,
  NavigationIcon,
  pushIf,
  SimpleTable,
  Table,
  useFlags,
} from 'common';
import Pill from '../../../Proposals/ProposalEditor/Products/Pill';
import styles from './PaymentDetails.module.scss';

interface Props {
  onClose: () => void;
  schedule?: BillingSchedule;
}

export const PaymentDetails: React.FC<Props> = ({
  schedule,
  onClose,
}: Props) => {
  type ItemOrBundle = {
    billingItems: BillingItem[];
    bundleId: string | undefined;
    bundleName: string | undefined;
  };

  const dataSource = schedule?.billingItems.reduce<ItemOrBundle[]>(
    (acc, item) => {
      const previousItem = arraySafeAccess(acc, acc.length - 1);
      const isContinuingBundle: boolean = Boolean(
        previousItem &&
          previousItem.bundleId &&
          previousItem.bundleId === item.bundle?.id
      );

      if (!isContinuingBundle) {
        return [
          ...acc,
          {
            billingItems: [item],
            bundleId: item.bundle?.id,
            bundleName: item.bundleName,
          },
        ];
      }

      // @ts-ignore TODO: Remove
      acc[acc.length - 1].billingItems.push(item);
      return acc;
    },
    []
  );

  const { taxyTurby } = useFlags();

  const renderIconLabel = ({
    IconComponent,
    label,
    indent,
    pillLabel,
  }: {
    IconComponent: React.ComponentType<SVGProps<SVGSVGElement>>;
    indent?: boolean;
    label: React.ReactNode;
    pillLabel?: string;
  }) => (
    <div className={styles.iconLabelCell}>
      <div className={clsx(indent && styles.indent)}>
        <IconComponent />
      </div>
      <div className={styles.iconLabel}>{label}</div>
      {pillLabel && <Pill label={pillLabel} type="info" />}
    </div>
  );

  const renderName = (data: ItemOrBundle) => {
    if (data.bundleId) {
      return renderIconLabel({
        IconComponent: NavigationIcon.Bundles,
        label: data.bundleName,
        pillLabel: 'Bundle',
      });
    }

    return renderIconLabel({
      IconComponent: NavigationIcon.Products,
      label: data.billingItems[0]?.name,
    });
  };

  const formatProrationOrDefault = (proration: number | undefined) =>
    proration ? `${proration}%` : DEFAULT;

  const cells: Cell<ItemOrBundle>[] = [
    {
      key: 'product',
      headerLabel: ['Product', 'Type'],
      overflowCell: true,
      renderCell: (value) => renderName(value),
      width: 240,
    },
    ...pushIf<Cell<ItemOrBundle>>(taxyTurby, [
      {
        key: 'subtotal',
        headerLabel: 'Subtotal',
        renderCell: (data) =>
          data.bundleId
            ? DEFAULT
            : formatNullableFormattedAmount(
                data.billingItems[0]?.amountWithoutTaxFormatted
              ),
        width: 100,
      },
      {
        key: 'taxAmount',
        headerLabel: 'Tax',
        renderCell: (data) =>
          data.bundleId
            ? DEFAULT
            : formatNullableFormattedAmount(
                data.billingItems[0]?.taxAmountFormatted
              ),
        width: 100,
      },
    ]),
    {
      key: 'amountDue',
      headerLabel: taxyTurby ? 'Total' : 'Amount',
      renderCell: (data: ItemOrBundle) =>
        data.bundleId ? DEFAULT : data.billingItems[0]?.amountDueFormatted,
      width: 100,
    },
    {
      key: 'proration',
      headerLabel: 'Pro rating',
      renderCell: (data) =>
        formatProrationOrDefault(data.billingItems[0]?.proration),
      width: 100,
    },
  ];

  const expandedRowRender = (record: ItemOrBundle) => {
    return (
      <Table<BillingItem>
        disableHeader
        disableShadow
        cells={[
          {
            key: 'name',
            headerLabel: 'Name',
            renderCell: (value) =>
              renderIconLabel({
                IconComponent: NavigationIcon.Products,
                label: value.name,
                indent: true,
              }),
            overflowCell: true,
            width: 240,
          },
          ...pushIf<Cell<BillingItem>>(taxyTurby, [
            {
              key: 'subtotal',
              headerLabel: 'Subtotal',
              renderCell: (data) => data.amountWithoutTaxFormatted,
              width: 100,
            },
            {
              key: 'taxAmount',
              headerLabel: 'Tax',
              renderCell: (data) =>
                formatNullableFormattedAmount(data.taxAmountFormatted),
              width: 100,
            },
          ]),
          {
            key: 'amount',
            headerLabel: taxyTurby ? 'Total' : 'Amount',
            renderCell: (data) => data.amountDueFormatted,
            width: 100,
          },
          {
            key: 'proration',
            headerLabel: 'Pro rating',
            renderCell: (data) => formatProrationOrDefault(data.proration),
            width: 100,
          },
          {
            key: 'empty',
            headerLabel: '',
            renderCell: () => null,
            width: 60,
          },
        ]}
        rows={record.billingItems}
        zeroStateMessage="No items"
      />
    );
  };

  const cellWidth: number = cells
    .map((c) => c.width)
    .reduce<number>((acc, w) => acc + (isNumber(w) ? w : 0), 0);

  const expandableIconWidth = dataSource?.some((i) => i.bundleId) ? 60 : 0;

  return (
    <Drawer
      header="Payment details"
      isOpen={!!schedule}
      noPadding
      onClose={onClose}
      width={cellWidth + expandableIconWidth}
    >
      <SimpleTable<ItemOrBundle>
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => !!record.bundleId,
        }}
        cells={cells}
        disableSearch
        disableShadow
        rows={dataSource}
        zeroStateMessage="No items"
      />
    </Drawer>
  );
};
