import React from 'react';
import { Button, CardHeader } from 'common';

interface Props {
  isDisabled?: boolean;
  connectStripe: (cond: boolean) => void;
}

const StripeConnectCard: React.FC<Props> = ({ isDisabled, connectStripe }) => {
  const handleConnectExistingClick = () => {
    connectStripe(false);
  };

  return (
    <div className="border rounded border-gray-lines  m-1 w-1/2 p-4 flex flex-col justify-between">
      <div>
        <CardHeader name="Use your existing Stripe account" />
        <p>
          Cacheflow will connect to you current account and use it to bill
          customers for new subscriptions.
        </p>
      </div>
      <Button
        dataTestId="setup-stripe-button"
        isDisabled={isDisabled}
        label="Use existing account"
        onClick={handleConnectExistingClick}
      />
    </div>
  );
};

export default StripeConnectCard;
