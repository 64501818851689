import React from 'react';
import ProposalSection from '../ProposalSection/ProposalSection';
import { SigningDocument } from 'common';
import SignerInfo from 'app/src/components/SignerInfo';

interface Props {
  proposalId: string;
  signingDocument: SigningDocument;
}

const SignerInfoSection: React.FC<Props> = ({
  proposalId,
  signingDocument,
}) => {
  return (
    <ProposalSection
      section="Customer signing"
      title="Customer signing"
      openOnLoad={signingDocument.signedAt !== undefined}
      isDisabled
      dataTestId="buyer-signing-section"
    >
      <SignerInfo id={proposalId} signingDocument={signingDocument} />
    </ProposalSection>
  );
};

export default SignerInfoSection;
