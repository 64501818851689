import React, { useEffect, useState } from 'react';
import {
  BILLING_PERIODS,
  BillingPeriodConfig,
  Button,
  Card,
  CardHeader,
  getBillingPeriodLabelAdjective,
  getErrorMessage,
  InternalAppLink,
  isDefined,
  Loading,
  OrgConfig,
  PaymentMethod,
  UpdatePaymentMethodRequest,
  useToast,
  useTranslation,
} from 'common';
import {
  useBatchUpdatePaymentMethods,
  useSettingsPaymentMethods,
} from '../../../services/paymentMethods';
import {
  setOrgConfigDefault,
  useFindOrgDefault,
} from '../../../services/orgDefaults';
import { useQueryClient } from '@tanstack/react-query';
import BillingPeriod from './BillingPeriod';
import { SETTINGS_PAYMENT_METHODS } from '../../../core-utils/routes';
import styles from './BillingPeriods.module.scss';

const BillingPeriods: React.FC = () => {
  const qc = useQueryClient();
  const showToast = useToast();
  const { tk } = useTranslation();

  type BillingPeriodString = 'month' | 'quarter' | 'halfyear' | 'year';

  const [defaultBillingPeriods, setDefaultBillingPeriods] = useState<
    Record<BillingPeriodString, boolean>
  >({
    month: true,
    quarter: false,
    halfyear: false,
    year: false,
  });
  const [enabledPeriods, setEnabledPeriods] = useState(
    [] as BillingPeriodString[]
  );
  const [billingPeriodToMethods, setBillingPeriodToMethods] = useState<
    Map<BillingPeriodString, PaymentMethod[]> | undefined
  >();

  const [shouldResetPaymentMethods, setShouldResetPaymentMethods] =
    useState<boolean>(true);

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const { data: paymentMethods, isLoading: paymentMethodsLoading } =
    useSettingsPaymentMethods(shouldResetPaymentMethods);

  const CONFIG_KEY: OrgConfig['configKey'] = 'billingPeriodConfig';

  const { data: serverDefaultBillingPeriod, isLoading: defaultBPLoading } =
    useFindOrgDefault(CONFIG_KEY);

  useEffect(() => {
    const newBillingPeriodToMethods = new Map<
      BillingPeriodString,
      PaymentMethod[]
    >();
    if (paymentMethods && shouldResetPaymentMethods) {
      paymentMethods.forEach((pm) => {
        if (pm.billingPeriods) {
          pm.billingPeriods.forEach((bp) => {
            let pmList = newBillingPeriodToMethods.get(bp);
            if (!pmList) {
              pmList = [];
            }

            newBillingPeriodToMethods.set(bp, [...pmList, pm]);
          });
        }
      });
      setBillingPeriodToMethods(newBillingPeriodToMethods);
      setEnabledPeriods([...newBillingPeriodToMethods.keys()]);
      setShouldResetPaymentMethods(false);
    }
  }, [paymentMethods, shouldResetPaymentMethods]);

  useEffect(() => {
    const configValue = serverDefaultBillingPeriod?.configValue as
      | BillingPeriodConfig
      | undefined;
    const proposalBillingPeriods = configValue?.newProposalBillingPeriods;
    if (proposalBillingPeriods && proposalBillingPeriods.length > 0) {
      const copy = { ...defaultBillingPeriods };
      for (const value of BILLING_PERIODS) {
        copy[value] = proposalBillingPeriods.includes(value);
      }
      setDefaultBillingPeriods(copy);
    }
  }, [serverDefaultBillingPeriod]);

  const onActiveToggle = (bp: BillingPeriodString) => {
    if (billingPeriodToMethods) {
      handleToggleDefaultSelection(bp, false);
      if (enabledPeriods.includes(bp)) {
        setEnabledPeriods(enabledPeriods.filter((cbp) => cbp !== bp));
        setBillingPeriodToMethods(
          new Map([...billingPeriodToMethods, [bp, []]])
        );
      } else {
        setEnabledPeriods([...enabledPeriods, bp]);
        setBillingPeriodToMethods(
          new Map([
            ...billingPeriodToMethods,
            [bp, [...(paymentMethods ?? [])]],
          ])
        );
      }
      setHasChanges(true);
    }
  };

  const onMethodToggle = (bp: BillingPeriodString, pm: PaymentMethod) => {
    if (billingPeriodToMethods) {
      if (enabledPeriods.includes(bp)) {
        const currentList = billingPeriodToMethods.get(bp);
        if (!currentList) {
          setBillingPeriodToMethods(
            new Map([...billingPeriodToMethods, [bp, [pm]]])
          );
        } else if (currentList.findIndex((cpm) => cpm.id === pm.id) === -1) {
          setBillingPeriodToMethods(
            new Map([...billingPeriodToMethods, [bp, [...currentList, pm]]])
          );
        } else {
          setBillingPeriodToMethods(
            new Map([
              ...billingPeriodToMethods,
              [bp, currentList.filter((cpm) => cpm.id !== pm.id)],
            ])
          );
        }
      }
      setHasChanges(true);
    }
  };

  const paymentMethodIds: string[] = paymentMethods
    ? paymentMethods.map((pm) => pm.id).filter(isDefined)
    : [];

  const paymentMethodUpdates = billingPeriodToMethods
    ? paymentMethodIds.map((id) => {
        const bpsForMethod = [];
        for (const [key, value] of billingPeriodToMethods.entries()) {
          if (value.findIndex((pm) => pm.id === id) !== -1) {
            bpsForMethod.push(key);
          }
        }

        return { billingPeriods: bpsForMethod } as UpdatePaymentMethodRequest;
      })
    : [];

  const { mutate: updatePaymentMethods } = useBatchUpdatePaymentMethods(
    paymentMethodIds,
    paymentMethodUpdates,
    () => {
      showToast.info(tk('Updated billing periods'));
    },
    (error: unknown) => {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) {
        showToast.info(tk(errorMessage));
      }
      setShouldResetPaymentMethods(true);
    },
    qc
  );

  if (paymentMethodsLoading || defaultBPLoading) {
    return <Loading />;
  }

  const onSave = () => {
    if (!isValidBillingPeriod()) {
      showToast.error(
        'Please add a payment method for active billing periods.'
      );
      setHasChanges(false);
      return;
    }
    const defaultBPs: string[] = [];
    for (const [key, value] of Object.entries(defaultBillingPeriods)) {
      if (value) {
        defaultBPs.push(key);
      }
    }
    // users cannot create any proposals if there are no default billing periods
    // therefore, block saving if there are none selected
    if (defaultBPs.length === 0) {
      showToast.error(tk('Please select at least one default billing period'));
      return;
    }
    const configBody = {
      configType: CONFIG_KEY,
      newProposalBillingPeriods: defaultBPs as BillingPeriodString[],
    } as BillingPeriodConfig;
    // TODO: only do the necessary operations.
    setOrgConfigDefault(
      CONFIG_KEY,
      {
        configKey: CONFIG_KEY,
        configValue: configBody,
      },
      qc
    )
      .then(() => {
        updatePaymentMethods();
        setHasChanges(false);
      })
      .catch((error: unknown) => {
        const errorMessage = getErrorMessage(error);
        if (errorMessage) {
          showToast.info(tk(errorMessage));
        }
      });
  };
  const handleToggleDefaultSelection = (
    bp: BillingPeriodString,
    newValue?: boolean
  ) => {
    setHasChanges(true);
    const defaultDraft = { ...defaultBillingPeriods };
    defaultDraft[bp] =
      newValue !== undefined ? newValue : !defaultBillingPeriods[bp];
    setDefaultBillingPeriods(defaultDraft);
  };

  const isValidBillingPeriod = () => {
    return enabledPeriods.every((bp) => {
      const paymentMethodsForPeriod = billingPeriodToMethods?.get(bp) ?? [];
      return paymentMethodsForPeriod.some((pm) => pm.enabled);
    });
  };

  return (
    <Card className={styles.billingPeriods}>
      <CardHeader name="Billing periods">
        <Button
          label="Save"
          isDisabled={!hasChanges}
          onClick={onSave}
          dataTestId="billing-periods-save"
        />
      </CardHeader>
      <p>
        Select the billing periods offered by your organization. Selected
        billing periods will be available on all proposals. Click on{' '}
        <strong>“Set by default”</strong> to make each billing period visible by
        default on proposals*.
      </p>
      <p>
        If <strong>“Set by default”</strong> is not selected, the enabled
        billing period is still an available option, but hidden by default on
        proposals.
      </p>
      <p>
        Payment methods may be configured at{' '}
        <InternalAppLink
          route={SETTINGS_PAYMENT_METHODS}
          breadcrumbs={[tk('Settings'), tk('Payment methods')]}
        />
        .
      </p>

      <p className={styles.asteriskText}>
        * Enabling or disabling billing periods will not have an effect on
        existing proposals or subscriptions. Changes saved will only affect new
        proposals.
      </p>

      {paymentMethods &&
        billingPeriodToMethods &&
        BILLING_PERIODS.map((bp) => (
          <BillingPeriod
            key={bp}
            isDefault={defaultBillingPeriods[bp] || false}
            isLastDefault={
              defaultBillingPeriods[bp] &&
              Object.values(defaultBillingPeriods).filter((bpValue) => bpValue)
                .length === 1
            }
            billingPeriod={bp}
            name={getBillingPeriodLabelAdjective(bp)}
            isActive={enabledPeriods.includes(bp)}
            allPaymentMethods={paymentMethods}
            selectedPaymentMethods={billingPeriodToMethods.get(bp)}
            onActiveToggle={() => onActiveToggle(bp)}
            onToggleDefault={() => {
              handleToggleDefaultSelection(bp);
            }}
            onPaymentMethodToggle={(pm: PaymentMethod) =>
              onMethodToggle(bp, pm)
            }
          />
        ))}
    </Card>
  );
};

export default BillingPeriods;
