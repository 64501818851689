import React, { useEffect, useState } from 'react';
import { registerNewVersionListener } from '../../../core-utils/helperFunctions/version';
import { Button, Icon, Modal } from 'common';

const VersionCheck: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onNewVersion = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    registerNewVersionListener('VersionCheck', onNewVersion);
  });

  const onClose = () => {
    setIsOpen(false);
  };

  const refresh = () => {
    window.location.reload();
  };

  const footer = (
    <div className="flex justify-center">
      <Button onClick={refresh} label="Refresh the page" />
    </div>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={450} footer={footer}>
      <div className="p-2 text-center">
        <div className="w-1/6 m-auto">
          <Icon.Checkmark width={72} height={72} className="text-green" />
        </div>
        <div className="flex justify-center">
          A new application version is available
        </div>
      </div>
    </Modal>
  );
};

export default VersionCheck;
