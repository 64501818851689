import IDField from 'app/src/components/IDField';
import { apiClient } from 'app/src/services/httpClients/app';
import { getDetailLink } from 'app/src/services/routes/routeUtils';
import ErrorPage from 'checkout/src/components/ErrorPage';
import {
  capitalizeFirstLetter,
  Cell,
  ConnectorInfo,
  ErrorCell,
  Filter,
  LinkButton,
  pushIf,
  QueryTable,
  sortOptionsInAlphabeticalOrder,
  SyncEvent,
  TruncateOption,
  truncateText,
} from 'common';
import React, { SVGProps } from 'react';
import { Link } from 'react-router-dom';
import logos from '../../../../assets/integration-logos';
import DateTimeCell from '../../../../components/DateTimeCell';
import StatusLabel from '../../../../components/StatusLabel';

interface Props {
  connectorName: ConnectorInfo['source'];
}

interface Option {
  name: string;
  value: string;
}

const getReferenceTypes = (connectorName: string | undefined): Option[] => {
  let referenceTypes;

  if (connectorName === 'avalara') {
    referenceTypes = [
      { value: 'invoice', name: 'Invoice' },
      { value: 'customer', name: 'Customer' },
    ];
  } else if (connectorName === 'close') {
    referenceTypes = [
      { value: 'contact', name: 'Contact' },
      { value: 'customer', name: 'Customer' },
      { value: 'proposal', name: 'Proposal' },
    ];
  } else if (connectorName === 'hubspot') {
    referenceTypes = [
      { value: 'contact', name: 'Contact' },
      { value: 'customer', name: 'Customer' },
      { value: 'organization', name: 'Organization' },
      { value: 'proposal', name: 'Proposal' },
      { value: 'proposalitem', name: 'Item' },
    ];
  } else if (connectorName === 'quickbooks') {
    referenceTypes = [
      { value: 'invoice', name: 'Invoice' },
      { value: 'contact', name: 'Contact' },
      { value: 'customer', name: 'Customer' },
      { value: 'organization', name: 'Organization' },
      { value: 'paymentMethod', name: 'Payment method' },
      { value: 'product', name: 'Product' },
      { value: 'transfer', name: 'Payments' },
    ];
  } else {
    referenceTypes = [
      { value: 'contact', name: 'Contact' },
      { value: 'contract', name: 'Subscription' },
      { value: 'customer', name: 'Customer' },
      { value: 'product', name: 'Product' },
      { value: 'proposal', name: 'Proposal' },
      { value: 'proposalitem', name: 'Item' },
    ];
  }
  return referenceTypes;
};

const getReferenceTypeLabel = (referenceType: string | undefined) => {
  if (referenceType) {
    switch (referenceType) {
      case 'invoice':
        return 'Invoice';
      case 'transfer':
        return 'Payment';
      case 'contract':
        return 'Subscription';
      case 'proposalitem':
        return 'Item';
      default:
        return capitalizeFirstLetter(referenceType);
    }
  }
  return '';
};

const getColumnHeaderName = (connectorName: string): string => {
  const specialNames: Record<string, string> = {
    hubspot: 'HubSpot object',
    quickbooks: 'QuickBooks object',
  };

  return (
    specialNames[connectorName] ||
    `${capitalizeFirstLetter(connectorName)} object`
  );
};

const ConnectorEvents = ({ connectorName }: Props) => {
  interface logoProps {
    logo: React.ComponentType<SVGProps<SVGSVGElement>>;
  }

  if (!connectorName) {
    return <ErrorPage message="Connector name undefined" />;
  }

  const RenderLogo = ({ logo: LogoComponent }: logoProps) => {
    return <LogoComponent height={20} />;
  };

  const getDirection = (data: SyncEvent) => {
    let logoName = logos.salesforce;
    if (data.externalSource && data.externalSource.sourceType in logos) {
      logoName = logos[data.externalSource.sourceType as keyof typeof logos];
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        {data.direction === 'to_cacheflow' ? (
          <>
            <RenderLogo logo={logoName} />
            <span>{` -> `}</span>
            <RenderLogo logo={logos.cacheflow} />
          </>
        ) : (
          <>
            <RenderLogo logo={logos.cacheflow} />
            <span>{` -> `}</span>
            <RenderLogo logo={logoName} />
          </>
        )}
      </div>
    );
  };

  const cells: Cell<SyncEvent>[] = [
    {
      renderCell: (data: SyncEvent) => <DateTimeCell date={data.createdAt} />,
      key: 'createdAt',
      headerLabel: 'Synced at',
    },
    {
      renderCell: (data: SyncEvent) =>
        getReferenceTypeLabel(data.referenceType),
      key: 'referenceType',
      headerLabel: 'Type',
    },
    {
      renderCell: (data: SyncEvent) => {
        const entityLink = getDetailLink(
          data.referenceType ?? '',
          data.referenceId ?? ''
        );
        if (entityLink) {
          return (
            <IDField
              className="justify-between w-full box-border pr-3 gap-2"
              documentID={data.referenceId}
            >
              <Link to={entityLink}>
                <div className="underline text-blue-darkest">
                  {data.referenceId?.substring(0, 18)}
                </div>
              </Link>
            </IDField>
          );
        }
        return (
          <IDField
            className="justify-between w-full box-border pr-3 gap-2"
            documentID={data.referenceId}
          >
            {data.referenceId?.substring(0, 18)}
          </IDField>
        );
      },
      key: 'referenceId',
      width: '15%',
      headerLabel: 'Cacheflow object',
    },
    {
      renderCell: (data: SyncEvent) => {
        const externalId = data.externalSource?.sourceId
          ? truncateText(
              data.externalSource.sourceId,
              20,
              TruncateOption.WithoutDots
            )
          : '-';
        return data.externalSource?.sourceLink ? (
          <LinkButton
            onClick={() =>
              window.open(data.externalSource?.sourceLink, '_blank')
            }
          >
            {externalId}
          </LinkButton>
        ) : (
          externalId
        );
      },
      key: 'externalId',
      headerLabel: getColumnHeaderName(connectorName),
    },
    {
      renderCell: (data: SyncEvent) => getDirection(data),
      key: 'direction',
      headerLabel: 'Direction',
    },

    {
      renderCell: (data: SyncEvent) =>
        data.action && capitalizeFirstLetter(data.action),
      key: 'action',
      headerLabel: 'Action',
    },
    {
      renderCell: (data: SyncEvent) =>
        data.status === 'success' ? (
          <StatusLabel feature={data.referenceType} status="success" />
        ) : (
          <ErrorCell
            error={data.error}
            statusLabel={
              <StatusLabel feature={data.referenceType} status="error" />
            }
          />
        ),
      key: 'status',
      headerLabel: 'Status',
      width: '180px',
      align: 'center' as Cell<SyncEvent>['align'],
    },
  ];

  const filters: Filter[] = [
    {
      type: 'searchV2',
      key: 'search',
      searchFields: [
        {
          field: 'referenceId',
          operation: 'equals',
        },
      ],
    },
    {
      type: 'dropdown',
      key: 'status',
      humanReadableName: 'Status',
      fieldName: 'status',
      options: sortOptionsInAlphabeticalOrder([
        { value: 'success', name: 'Success' },
        { value: 'error', name: 'Error' },
      ]),
    },
    ...pushIf<Filter>(true, {
      type: 'dropdown',
      key: 'direction',
      humanReadableName: 'Sync direction',
      fieldName: 'direction',
      options: sortOptionsInAlphabeticalOrder([
        {
          value: 'to_cacheflow',
          name: `${connectorName === 'hubspot' ? 'HubSpot' : capitalizeFirstLetter(connectorName)} -> Cacheflow`,
        },
        {
          value: 'from_cacheflow',
          name: `Cacheflow -> ${connectorName === 'hubspot' ? 'HubSpot' : capitalizeFirstLetter(connectorName)}`,
        },
      ]),
    }),
    {
      type: 'dropdown',
      key: 'referenceType',
      humanReadableName: 'Entity type',
      fieldName: 'referenceType',
      options: sortOptionsInAlphabeticalOrder(getReferenceTypes(connectorName)),
    },
  ];

  // TODO: method will be deprecated after backend updated
  // Fetch entity options based on connector name
  const getEntityOptions = () => {
    const entityTypeDropdownOptions = getReferenceTypes(connectorName);
    return entityTypeDropdownOptions.map((option: Option) => option.value);
  };

  return (
    <div className="connector-settings-tab">
      <QueryTable<SyncEvent>
        baseSearchValues={[
          [{ key: 'source', comparator: ':', value: connectorName }],
        ]}
        cells={cells}
        entityTypeDropdownOptions={getEntityOptions()}
        fetchList={apiClient.getEvents}
        filters={filters}
        queryKey="apiClient.getEvents"
      />
    </div>
  );
};

export default ConnectorEvents;
