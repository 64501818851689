import React from 'react';
import { Drawer, Icon } from 'common';
import ProposalEvents from '../ActivityDrawer/ProposalEvents';
import ActionBarButton from '../../../../components/ActionBarButton';

interface Props {
  activityDialogOpen: boolean;
  isDisabled: boolean;
  proposalId: string;
  setActivityDialogOpen: (isOpen: boolean) => void;
}

const ProposalActivityButton: React.FC<Props> = ({
  proposalId,
  activityDialogOpen,
  setActivityDialogOpen,
  isDisabled,
}) => {
  const onClick = () => {
    setActivityDialogOpen(true);
  };

  return (
    <>
      <ActionBarButton
        dataTestId="proposal-editor-open-activity-button"
        icon={Icon.Activity}
        onClick={onClick}
        tooltip="Activity"
        isDisabled={isDisabled}
      />

      <Drawer
        noPadding
        isOpen={activityDialogOpen}
        onClose={() => setActivityDialogOpen(false)}
        header="Activity"
        dataTestId="drawer-activity"
      >
        <ProposalEvents isOpen={activityDialogOpen} proposalId={proposalId} />
      </Drawer>
    </>
  );
};

export default ProposalActivityButton;
