import React, { useEffect, useState } from 'react';
import {
  areAddressesTheSame,
  Contact,
  Customer,
  useFormValidation,
  ValidationFormField,
} from 'common';
import ContactForm from './ContactForm';
import EditCustomer from './EditCustomer';
import addMissingAddressFields from './AddMissingAddressFields';
import { useContacts } from 'app/src/services/contacts';

interface Props {
  customerBeingEdited?: Customer;
  customerStarterName?: string;
  isDisabled: boolean;
  isInProposalEditor?: boolean;
  onCloseForm: () => void;
  onUpdateCustomer: (customer: Customer, currencyChanged: boolean) => void;
}

const CustomerFormInline: React.FC<Props> = ({
  customerBeingEdited,
  isDisabled,
  onCloseForm,
  onUpdateCustomer,
  customerStarterName,
  isInProposalEditor = false,
}) => {
  const [contactBeingEdited, setContactBeingEdited] = useState<
    Contact | undefined
  >(undefined);
  const getCustomerDraft = () => {
    return customerBeingEdited || { name: customerStarterName };
  };
  const [customerDraft, setCustomerDraft] =
    useState<Partial<Customer>>(getCustomerDraft());
  const [activeDrawerViewKey, setActiveDrawerViewKey] = useState<
    'main' | 'contacts'
  >('main');
  const isContactsDrawerOpen = activeDrawerViewKey === 'contacts';

  const { data: customerContacts } = useContacts(customerDraft.id);

  const { isFormValid: hasCustomerName } = useFormValidation(
    [
      {
        fieldName: 'name',
        isRequired: true,
        humanReadableName: 'Legal company name',
      },
    ],
    { name: customerDraft.name }
  );
  const addressValidationRules: ValidationFormField<any>[] = [
    {
      fieldName: 'streetAddress',
      isRequired: true,
      humanReadableName: 'Street',
    },
    { fieldName: 'city', isRequired: true, humanReadableName: 'City' },
    {
      fieldName: 'postalCode',
      isRequired: true,
      humanReadableName: 'Zip/Postal code',
    },
    {
      fieldName: 'country',
      isRequired: true,
      humanReadableName: 'Country',
    },
  ];
  const {
    isFormValid: areShippingAddressFieldsValid,
    getErrorToShow: getShippingAddressErrors,
  } = useFormValidation(
    addressValidationRules,
    customerDraft.shippingAddress ?? {}
  );

  const {
    isFormValid: areBilllingAddressFieldsValid,
    getErrorToShow: getBillingAddressErrors,
  } = useFormValidation(
    addressValidationRules,
    customerDraft.billingAddress ?? {}
  );

  const isSubscriptionAddressValid = {
    hasCustomerName,
    isShippingAddressValid: areShippingAddressFieldsValid,
    isBillingAddressValid: areBilllingAddressFieldsValid,
    isBillingContactValid: !!customerDraft.billingContact,
    subscriptionContacts: customerContacts,
  };

  useEffect(() => {
    setCustomerDraft(getCustomerDraft());
  }, [customerBeingEdited, isDisabled]);

  useEffect(() => {
    const updatedShippingAddress = addMissingAddressFields(
      customerDraft.shippingAddress ?? {}
    );
    const updatedBillingAddress = addMissingAddressFields(
      customerDraft.billingAddress ?? {}
    );

    if (
      !areAddressesTheSame(
        updatedShippingAddress,
        customerDraft.shippingAddress
      ) ||
      !areAddressesTheSame(updatedBillingAddress, customerDraft.billingAddress)
    ) {
      setCustomerDraft((prevDraft) => ({
        ...prevDraft,
        shippingAddress: updatedShippingAddress,
        billingAddress: updatedBillingAddress,
      }));
    }
  }, [customerDraft]);

  const closeContactForm = (): void => {
    setActiveDrawerViewKey('main');
    setContactBeingEdited(undefined);
  };

  const onSaveContactSuccess = (contact: Contact) => {
    if (contact.id && contact.id === customerDraft.billingContact?.id) {
      setCustomerDraft({
        ...customerDraft,
        billingContact: contact,
      });
    }

    closeContactForm();
  };

  const editContact = (contact: Contact) => {
    setContactBeingEdited(contact);
    setActiveDrawerViewKey('contacts');
  };

  const closeForm = () => {
    setContactBeingEdited(undefined);
    setActiveDrawerViewKey('main');
    onCloseForm();
  };

  return (
    <>
      {!isContactsDrawerOpen && (
        <EditCustomer
          customerDraft={customerDraft}
          isBeingViewed={activeDrawerViewKey === 'main' && !isDisabled}
          isInProposalEditor={isInProposalEditor}
          isInline
          isSubscriptionAddressValid={isSubscriptionAddressValid}
          onCancel={closeForm}
          onClickAddContact={() => setActiveDrawerViewKey('contacts')}
          onClickEditContact={editContact}
          onDeleteSuccess={() => {}}
          onSaveSuccess={onUpdateCustomer}
          setCustomerDraft={setCustomerDraft}
          subscriptionFlowBillingErrors={getBillingAddressErrors}
          subscrptionFlowShippingErros={getShippingAddressErrors}
        />
      )}
      {isContactsDrawerOpen && customerBeingEdited?.id && (
        <ContactForm
          customerId={customerBeingEdited.id}
          contactToEdit={contactBeingEdited}
          onSaveSuccess={onSaveContactSuccess}
          onClose={closeContactForm}
          onDeleteSuccess={closeContactForm}
          isBeingViewed={!isDisabled}
        />
      )}
    </>
  );
};

export default CustomerFormInline;
