import React from 'react';
import { KnexDbMigration } from 'common';
import { UseQueryResult } from '@tanstack/react-query';
import {
  useCFAppVersion,
  useCheckoutVersion,
  useFlowStatus,
  useMigrationList,
  usePaymentServiceStatus,
} from '../../services/status';
import Page from '../../components/Page';
import DateTimeCell from '../../components/DateTimeCell';
import AppVersion from './ProjectStatus/AppVersion';
import { VersionInfo } from '../../core-utils/helperFunctions/version';

const SystemStatusPage: React.FC = () => {
  const { data, isLoading } = useMigrationList();

  const apps: { hook: () => UseQueryResult<VersionInfo>; name: string }[] = [
    { name: 'front-end', hook: useCFAppVersion },
    { name: 'checkout', hook: useCheckoutVersion },
    { name: 'flow', hook: useFlowStatus },
    { name: 'payment service', hook: usePaymentServiceStatus },
  ];

  // id, createdAt, createdBy, updatedAt, updatedBy, domainName, name, flowDomain, status, created, orgId, active
  const migration = (m: KnexDbMigration) => {
    return (
      <div className="flex flex-row bg-white rounded-md m-2 p-2">
        <DateTimeCell date={m.migrationTime} showTime />
        <div className="text-base pl-4">{m.name}</div>
      </div>
    );
  };

  const sortAndFilter = (dataList: KnexDbMigration[]) => {
    const dateToCheck = new Date();
    const daysBack = 14;
    dateToCheck.setDate(dateToCheck.getDate() - daysBack);

    return dataList
      .sort((n1, n2) => {
        if (
          n1.migrationTime === undefined ||
          n2.migrationTime === undefined ||
          n2.migrationTime === n1.migrationTime
        ) {
          return 0;
        }

        return n1.migrationTime < n2.migrationTime ? 1 : -1;
      })
      .filter((val: any) => {
        return dateToCheck.toISOString() < val.migrationTime;
      });
  };

  return (
    <Page className="flex flex-col justify-center" leftWidget="System Status">
      <div className="flex flex-row items-center justify-between ml-2">
        {apps.map((value) => {
          return (
            <div key={value.name} className="w-1/4 p-6">
              <AppVersion name={value.name} versionHook={value.hook} />
            </div>
          );
        })}
      </div>
      <div className="flex flex-col w-full">
        <div className="text-lg font-semibold m-2 p-2">
          Migrations last 2 weeks
        </div>
        {!isLoading &&
          data &&
          sortAndFilter(data).map((value) => migration(value))}
      </div>
    </Page>
  );
};

export default SystemStatusPage;
