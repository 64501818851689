import React, { useEffect, useRef, useState } from 'react';
import { RefSelectProps, Select } from 'antd';
import clsx from 'clsx';

import {
  CreateProposalApprovalRuleRequest,
  InputRef,
  LinkButton,
  Money,
  MoneyInput,
  ProposalApprovalRuleConfig,
  ProposalSummary,
} from 'common';

import {
  GroupOption,
  RuleInfo,
  searchFilterOption,
} from '../rules/_approvalRulesConfig';

import styles from './ProposalRuleBuilderStyles.module.scss';

interface Props {
  allGroupOptions: GroupOption[];
  createRule: (payload: CreateProposalApprovalRuleRequest) => void;
  onCancelAdd: () => void;
  proposalType: ProposalSummary['proposalType'];
  selectedRule: RuleInfo;
}

export const ProposalMoneyBuilder = ({
  proposalType,
  createRule,
  selectedRule,
  onCancelAdd,
  allGroupOptions,
}: Props) => {
  const [value, setValue] = useState<Money>({ amount: 0 });

  const ref = useRef<InputRef>(null);
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    ref.current?.focus({ cursor: 'all' });
  }, []);

  const handleSave = (newValue: Money, groupId: string[]) => {
    const payload: CreateProposalApprovalRuleRequest = {
      name: `${selectedRule.name}-${proposalType}`,
      config: {
        [selectedRule.configKey]: {
          [selectedRule.configKey]: newValue.amount,
        },
        proposalType,
        ruleType: selectedRule.name as ProposalApprovalRuleConfig['ruleType'],
      },
    };

    createRule({ ...payload, approvalGroupIds: groupId });
  };

  const handleSaveIfValid = (id: string[]) => {
    if (value.amount && value.amount > 0 && id.length > 0) {
      handleSave(value, id);
    }
  };

  const handleChange = (newValue: Money) => {
    setValue(newValue);
  };

  const handleEnter = () => selectRef.current?.focus();

  return (
    <div
      className={clsx(
        styles.ruleRow,
        !!value.amount && styles.showingGroupsSelect
      )}
    >
      <div className={styles.condition}>{selectedRule.conditionOperator}</div>
      <div className={styles.input}>
        <MoneyInput
          defaultCurrency="USD" // TODO: no more hard coded currency, leave this here even with default handled to mark it as an area of attentions
          onChange={handleChange}
          onEnter={handleEnter}
          ref={ref}
          value={value}
        />
      </div>

      {!value.amount && (
        <LinkButton className={styles.cancel} onClick={onCancelAdd}>
          cancel
        </LinkButton>
      )}

      {!!value.amount && (
        <div className={styles.groups}>
          {allGroupOptions.length > 0 && (
            <Select
              className={styles.groupWidth}
              filterOption={searchFilterOption}
              mode="multiple"
              onChange={handleSaveIfValid}
              options={allGroupOptions}
              placeholder="Select approval group"
              popupMatchSelectWidth={false}
              ref={selectRef}
              value={[]}
            />
          )}
        </div>
      )}
    </div>
  );
};
