import React, { useEffect } from 'react';
import { Drawer, Proposal } from 'common';
import ShareProposalPanel from './ShareProposalPanel';
import './ShareProposalPage.scss';
import PreviewPanel from './PreviewPanel';
import { getProposalHash } from '../proposalUtilities';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onRecipientsChange: (recipientIds: string[]) => void;
  proposal: Proposal;
}

const ShareProposalPage: React.FC<Props> = ({
  proposal,
  onRecipientsChange,
  onClose,
  isOpen,
}) => {
  const [proposalHash, setProposalHash] = React.useState<string | undefined>(
    getProposalHash(proposal)
  );

  useEffect(() => {
    setProposalHash(getProposalHash(proposal));
  }, [isOpen]);

  return (
    <Drawer isOpen={isOpen} noPadding width="95vw">
      <div className="sharePage">
        <PreviewPanel proposalHash={proposalHash} proposal={proposal} />
        <div className="sharePanel">
          <ShareProposalPanel
            proposal={proposal}
            onClose={onClose}
            onRecipientsChange={onRecipientsChange}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ShareProposalPage;
