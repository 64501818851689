import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'common';
import { PROPOSALS } from '../../core-utils/routes';

interface Props {
  status?: number;
}

const ProposalLoadError = ({ status }: Props) => (
  <div className="m-auto my-8 text-center text-mont text-slate-darkest sm:w-1/2 xs:h-screen sm:h-full ">
    <div>
      <Icon.AlertVariant
        className="m-auto text-red"
        height="6rem"
        width="6rem"
      />
      <p className="mb-6 font-semibold xs:text-xl sm:text-3xl">
        {status === 404
          ? 'Proposal Not Found'
          : 'There was an error loading the proposal'}
      </p>

      <Link
        to={PROPOSALS}
        className="mb-6 font-semibold xs:text-xl sm:text-3xl"
      >
        <Button
          label="Back to proposals"
          className="m-auto text-sm text-white"
        />
      </Link>
    </div>
  </div>
);

export default ProposalLoadError;
