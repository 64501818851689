import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormField,
  FormModal,
  Input,
  useFormValidation,
  useTranslation,
} from 'common';

interface Props {
  autoAdd?: boolean;
  inEnvelope?: boolean;
  isLinkAttachment: boolean;
  isProposalAttachment?: boolean;
  isShowing: boolean;
  name?: string;
  onClickDelete: () => void;
  onClickSave: (name: string, inEnvelope: boolean, autoAdd: boolean) => void;
  onClose: () => void;
  readOnlyUrlOrFileName?: string;
}

const EditProposalAttachmentOrAttachmentConfig: React.FC<Props> = ({
  isShowing,
  name = '',
  inEnvelope = false,
  autoAdd = false,
  onClose,
  onClickSave,
  onClickDelete,
  isProposalAttachment = false,
  readOnlyUrlOrFileName,
  isLinkAttachment,
}) => {
  const { tk } = useTranslation();
  const [nameDraft, setNameDraft] = useState<string>(name);
  const [inEnvelopeDraft, setInEnvelopeDraft] = useState<boolean>(inEnvelope);
  const [autoAddDraft, setAutoAddDraft] = useState<boolean>(autoAdd);

  const {
    getErrorToShow,
    setHasVisitedField,
    isFormValid: isNameValid,
    resetUIState,
  } = useFormValidation<{ name: string }>(
    [
      {
        fieldName: 'name',
        isRequired: true,
        humanReadableName: 'Name',
      },
    ],
    { name: nameDraft }
  );

  useEffect(() => {
    setNameDraft(name);
    setInEnvelopeDraft(inEnvelope);
    setAutoAddDraft(autoAdd);
    resetUIState();
  }, [isShowing]);

  const save = (): void => {
    onClickSave(nameDraft, inEnvelopeDraft, autoAddDraft);
  };

  const saveIfValid = () => {
    if (isNameValid) {
      save();
    }
  };

  return (
    <FormModal
      isOpen={isShowing}
      width={600}
      onDelete={onClickDelete}
      onClose={onClose}
      isNewEntity={false}
      entityTypeName="attachment"
      submitDataTestId="save-attachment-button"
      onSubmit={save}
      isSubmitDisabled={!isNameValid}
    >
      <div>
        <div>
          <FormField label="Name" errorToShow={getErrorToShow('name')}>
            <Input
              dataTestId="name-input"
              onChange={setNameDraft}
              value={nameDraft}
              onBlur={() => setHasVisitedField('name')}
              onEnter={saveIfValid}
              selectAllContentOnFocus
            />
          </FormField>

          {readOnlyUrlOrFileName && (
            <FormField label={tk(isLinkAttachment ? 'Link / URL' : 'File')}>
              <Input
                isDisabled
                value={readOnlyUrlOrFileName}
                selectAllContentOnFocus
              />
            </FormField>
          )}

          {!isProposalAttachment && (
            <Checkbox
              name="autoAdd"
              dataTestId="auto-add-checkbox"
              value={autoAddDraft}
              onChange={setAutoAddDraft}
              label="Automatically add to new proposals"
            />
          )}

          {!isLinkAttachment && (
            <Checkbox
              name="inEnvelope"
              dataTestId="in-envelope-checkbox"
              value={inEnvelopeDraft}
              onChange={setInEnvelopeDraft}
              label="Add to signing envelope"
            />
          )}
        </div>
      </div>
    </FormModal>
  );
};

export default EditProposalAttachmentOrAttachmentConfig;
