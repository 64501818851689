import React, { useEffect, useState } from 'react';
import {
  AddressDisplay,
  Card,
  CardHeader,
  CardRowValue,
  Contract,
  formatHumanReadable,
  getBillingPeriodLabelAdjective,
  getPersonFullName,
  PURCHASE_ORDER_UPDATE_ERROR_MESSEGE,
  PURCHASE_ORDER_UPDATE_SUCCESS_MESSEGE,
  useToast,
} from 'common';
import POInputBox from 'app/src/components/POInputBox';
import { InvoiceInAdvanceBillingSection } from './InvoiceInAdvanceBillingSection/InvoiceInAdvanceBillingSection';
import { apiClient } from 'app/src/services/httpClients/app';
import POLabelWithValue from 'app/src/components/POLabelWithValue';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

interface Props {
  contract: Contract;
}

const BillingDetailsCard = ({ contract }: Props) => {
  const canUpdate = isActionAllowed(FLAG_TYPES.CONTRACT, 'update');
  const billTo = contract.billTo ?? contract.buyer;

  const [isEditingPO, setIsEditingPO] = useState<boolean>(false);

  const [poDisplayValue, setPoDisplayValue] = useState<string>(
    contract.purchaseOrderNumber ?? ''
  );

  const showToast = useToast();

  const updatePO = async (poNumber: string) => {
    if (poNumber !== contract.purchaseOrderNumber) {
      await apiClient
        .updateContract(contract.id, {
          purchaseOrderNumber: poNumber,
        })
        .then(() => {
          showToast.success(PURCHASE_ORDER_UPDATE_SUCCESS_MESSEGE);
        })
        .catch(() => {
          showToast.error(PURCHASE_ORDER_UPDATE_ERROR_MESSEGE);
        });
      setIsEditingPO(false);
    } else {
      setIsEditingPO(false);
    }
  };

  useEffect(() => {
    setPoDisplayValue(contract.purchaseOrderNumber ?? '');
  }, [contract]);

  const isPoEditable =
    contract.status === 'active' || contract.status === 'paused';

  const renderPO = () => {
    if (
      !isEditingPO &&
      (contract.purchaseOrderNumber !== undefined || isPoEditable)
    ) {
      return (
        <POLabelWithValue
          isEditDisabled={!isPoEditable || !canUpdate}
          label="PO Number"
          poDisplayValue={poDisplayValue}
          setEditingPO={setIsEditingPO}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Card throughline>
      <CardHeader name="Billing details" throughline />

      <CardRowValue label="Payment frequency">
        <div>{getBillingPeriodLabelAdjective(contract.billingPeriod)}</div>
      </CardRowValue>

      <CardRowValue label="Proration method">
        {formatHumanReadable(contract.proratingType)}
      </CardRowValue>

      {contract.billTo && (
        <CardRowValue label="Bill to">{billTo.name}</CardRowValue>
      )}

      <CardRowValue label="Billing address">
        <AddressDisplay address={billTo.billingAddress} />
      </CardRowValue>

      <CardRowValue label="Billing contact person">
        {getPersonFullName(billTo.billingContact)}
      </CardRowValue>

      <CardRowValue label="Billing email">
        {billTo.billingContact?.email}
      </CardRowValue>

      <>
        {renderPO()}

        {isEditingPO && (
          <POInputBox
            label="PO Number"
            onCancel={() => setIsEditingPO(false)}
            onChange={(newValue) => {
              setPoDisplayValue(newValue);
            }}
            onSubmit={async () => {
              await updatePO(poDisplayValue);
            }}
            value={poDisplayValue}
          />
        )}
      </>

      <InvoiceInAdvanceBillingSection contract={contract} />
    </Card>
  );
};

export default BillingDetailsCard;
