import AttachmentDocumentAndLinkForm from './AttachmentDocumentAndLinkForm';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useCreateAttachmentConfig } from '../../../services/api/attachments/attachments';
import {
  AttachmentConfig,
  capitalizeFirstLetter,
  getErrorMessage,
  useToast,
  useTranslation,
} from 'common';

interface Props {
  isShowing: boolean;
  onClose: () => void;
  onCreateSuccess: (config: AttachmentConfig) => void;
}

const CreateAttachmentConfig: React.FC<Props> = ({
  isShowing,
  onClose,
  onCreateSuccess,
}) => {
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { mutate: createAttachmentConfig } = useCreateAttachmentConfig(
    (config: AttachmentConfig) => {
      onCreateSuccess(config);
      showToast.info('Success!');
    },
    (error: unknown) => {
      const errorMessage =
        getErrorMessage(error) ||
        tk('There was a problem uploading this attachment, please try again.');
      showToast.error(capitalizeFirstLetter(errorMessage), 5);
    },
    queryClient
  );

  const handleCreateDocumentOrLink = (
    attachmentName: string,
    documentId?: string,
    linkUrl?: string
  ) => {
    if (documentId) {
      createAttachmentConfig({
        type: 'document',
        name: attachmentName,
        documentId,
      });
    } else {
      createAttachmentConfig({
        type: 'link',
        name: attachmentName,
        linkUrl,
      });
    }
  };

  return (
    <AttachmentDocumentAndLinkForm
      isShowing={isShowing}
      onClose={onClose}
      onSaveSuccess={handleCreateDocumentOrLink}
    />
  );
};

export default CreateAttachmentConfig;
