import React from 'react';
import SideNavBar from 'app/src/components/Navigation/sideNav';
import {
  closeNavItem,
  hubspotNavItem,
  salesforceNavItems,
  setupContent,
  topContent,
} from 'app/src/components/Navigation/sideNav/sideNavBar';
import BasePage from './BasePage';
import BaseTemplateComponent from './BaseTemplate';
import { useIsConnectorEnabled } from '../../services/connectors';
import {
  isMultiOrgPath,
  useCurrentUser,
} from '../../core-utils/helperFunctions/userServiceHelper';

interface Props {
  hasWhiteBackground?: boolean;
  mainContent: React.ReactElement;
  startRoute: string;
}

/**
 * Our standard sidebar nav layout with a header
 */
const AppPage: React.FC<Props> = ({
  startRoute,
  mainContent,
  hasWhiteBackground,
}) => {
  const navContent = [...topContent];
  const user = useCurrentUser();
  const multiOrgPath = isMultiOrgPath();
  const salesforceConnectorEnabled =
    user && !multiOrgPath && useIsConnectorEnabled('salesforceConfig');
  const isHubspotEnabled =
    user && !multiOrgPath && useIsConnectorEnabled('hubspotConfig');
  const isCloseConnectorEnabled =
    user && !multiOrgPath && useIsConnectorEnabled('closeConfig');

  if (salesforceConnectorEnabled) {
    salesforceNavItems.forEach((item) => (item.hidden = false));
  }

  if (isHubspotEnabled) {
    hubspotNavItem.hidden = false;
  }

  if (isCloseConnectorEnabled) {
    closeNavItem.hidden = false;
  }

  return (
    <BasePage>
      <BaseTemplateComponent
        mainContent={mainContent}
        hasWhiteBackground={hasWhiteBackground}
        leftNav={
          <SideNavBar
            startRoute={startRoute}
            topContent={navContent}
            setupContent={setupContent}
          />
        }
      />
    </BasePage>
  );
};

export default AppPage;
