import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { ProposalTemplateSummary } from 'common';

export const PROPOSALS_TEMPLATES_PATH = '/data/proposal-templates';

const getVisibleProposalTemplates = async () => {
  const { data } = await apiClient.listVisibleTemplates();
  return data;
};

export const useGetVisibleProposalTemplates = (): UseQueryResult<
  ProposalTemplateSummary[]
> =>
  useQuery({
    queryKey: [PROPOSALS_TEMPLATES_PATH],
    queryFn: getVisibleProposalTemplates,
  });
