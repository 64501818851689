import React from 'react';
import { Button, Contact, Icon, useTranslation } from 'common';

interface Props {
  contacts: Contact[];
  onClickAddContact?: () => void;
  onClickEditContact?: (contact: Contact) => void;
}

const CustomerContacts: React.FC<Props> = ({
  contacts,
  onClickAddContact,
  onClickEditContact,
}: Props) => {
  const { tk } = useTranslation();

  return (
    <div>
      {contacts.length === 0 && (
        <div className="pt-3 text-xs">
          {tk('You have not added any contacts to this customer yet.')}
        </div>
      )}

      <div className="divide-y-4 divide-white">
        {contacts.map((contact) => (
          <div
            key={contact.email}
            className="p-2 flex justify-between customer-contact bg-slate-lightest rounded-md"
          >
            <div>
              <div className="flex">
                <div className="font-bold">
                  {contact.firstName} {contact.lastName}
                </div>
                {contact.role && (
                  <div className="pl-1 text-slate-darker">({contact.role})</div>
                )}
              </div>
              <div className="text-xs">{contact.email}</div>
            </div>
            {onClickEditContact && (
              <button
                type="button"
                data-testid="edit-contact"
                className="flex flex-col justify-center text-blue"
                onClick={() => onClickEditContact(contact)}
              >
                <Icon.Edit />
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="h-2" />
      {onClickAddContact && (
        <Button
          className="h-8 !py-2 !px-3 text-xs w-full"
          type="secondary"
          dataTestId="add-contact"
          label={tk('Add contact')}
          onClick={onClickAddContact}
        />
      )}
    </div>
  );
};

export default CustomerContacts;
