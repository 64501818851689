import React, { useEffect, useState } from 'react';
import {
  areAllLevelsEqual,
  BillingPeriodsType,
  Button,
  ButtonBar,
  Drawer,
  getEntry,
  getPricingDurations,
  getProductBillingPeriodOverrides,
  getProductBillingPeriodPrice,
  isChangeProposalType,
  isDefined,
  Item,
  ItemOverride,
  log,
  mergeLinesToOverrides,
  Proposal,
  QuantityOverride,
  ScheduleLine,
  useTranslation,
} from 'common';
import FixedProductManagement from './FixedProductManagement';
import TieredProductManagement from './TieredProductManagement';
import styles from './BillingPeriodPriceManagement.module.scss';
import { BillingPeriodString } from '../../../PaymentPlan/PaymentPlanContext';
import { getBaseLevelsByBillingPeriod } from '../TimeBasedPricing/TimeBasedPricingTieredDrawer/utils';

interface Props {
  billingPeriod: BillingPeriodString;
  isOpen: boolean;
  isSaving: boolean;
  item: Item;
  onClose: () => void;
  onUpdateOverrides: (
    itemId: string,
    overrides: ItemOverride[] | undefined,
    quantityOverrides?: QuantityOverride[]
  ) => void;
  proposal: Proposal;
}

const getItemBillingPeriods = (
  proposal: Proposal,
  item: Item
): BillingPeriodString[] => {
  const { product } = item;
  const isFixedBilling = product.billing === 'fixed';

  if (isFixedBilling && product.billingPeriods) {
    return product.billingPeriods;
  }

  const selectedSchedule = isChangeProposalType(proposal)
    ? proposal.schedules.find(
        (schedule) => schedule.id === proposal.acceptedScheduleId
      )
    : null;

  return selectedSchedule
    ? [selectedSchedule.payEvery]
    : proposal.schedules.map((schedule) => schedule.payEvery);
};

export function BillingPeriodPriceManagementDrawer({
  billingPeriod,
  isOpen,
  isSaving,
  item,
  onClose,
  onUpdateOverrides,
  proposal,
}: Props) {
  const { tk } = useTranslation();

  const allowListPriceAdjustments = item.product.listPriceAdjustable;
  const itemBillingPeriods = getItemBillingPeriods(proposal, item);

  const [scheduleDraft, setScheduleDraft] = useState<
    Record<BillingPeriodsType, ScheduleLine[] | undefined>
  >({
    halfyear: undefined,
    month: undefined,
    quarter: undefined,
    year: undefined,
  });

  const { product } = item;

  // one-time products should be locked to billingPeriod: null discounts,
  // whether billing period pricing is off or on
  const isOneTime = product.recurrence === 'one_time';

  const isFixedPricing = product.pricing === 'fixed';

  useEffect(() => {
    if (!isOneTime) {
      const newScheduleOverrides = { ...scheduleDraft };
      const currentOverrideMap = item.overrides
        ? new Map(item.overrides.map((entry) => [entry.billingPeriod, entry]))
        : null;
      const currentQtyMap = item.quantityOverrides
        ? new Map(
            item.quantityOverrides.map((entry) => [entry.billingPeriod, entry])
          )
        : null;

      // just for typing. undefined billing period is impossible for non-one time items
      const filteredBillingPeriods: BillingPeriodsType[] =
        itemBillingPeriods.map(
          (bp): BillingPeriodsType => bp! as BillingPeriodsType
        );

      filteredBillingPeriods.forEach((bp) => {
        const currentOverride = currentOverrideMap?.get(bp);
        const currentQty = currentQtyMap ? currentQtyMap.get(bp) : null;
        if (currentOverride || currentQty) {
          if (currentOverride?.duration || currentQty?.duration) {
            const bpOverrides = getProductBillingPeriodOverrides(item, bp);
            const quantityOverrides = item.quantityOverrides?.filter(
              (qo) => qo.billingPeriod === bp
            );
            const baseLevels =
              product.levels && product.levels.length > 0
                ? getBaseLevelsByBillingPeriod(
                    product.levels,
                    bp,
                    proposal.currency,
                    product.entries
                  )
                : undefined;
            newScheduleOverrides[bp] = getPricingDurations(
              proposal.termQty,
              proposal.termType,
              getProductBillingPeriodPrice(product, proposal.currency, bp),
              proposal.freeMonths,
              bpOverrides,
              quantityOverrides,
              item.quantity,
              baseLevels
            );
          }
        }
      });

      setScheduleDraft(newScheduleOverrides);
    }
  }, [item]);

  useEffect(() => {
    const hasNewScheduleOverride = Object.values(scheduleDraft).some(
      (overrides) =>
        overrides?.some((override) => !findScheduleOverrideMatch(override))
    );

    if (hasNewScheduleOverride) {
      save();
    }
  }, [scheduleDraft]);

  const handleSetOverride = (override: ItemOverride) => {
    const hasNewOverride: boolean =
      !!(override.price || override.levels) && !hasOverrideMatch(override);

    if (hasNewOverride) {
      save(override);
    }
  };

  const findScheduleOverrideMatch = (override: ScheduleLine) => {
    if (!billingPeriod) {
      return null;
    }

    const overrides: ItemOverride[] = getProductBillingPeriodOverrides(
      item,
      override.billingPeriod
    );

    const quantityOverrides =
      item.quantityOverrides?.filter(
        (qo) => qo.billingPeriod === override.billingPeriod
      ) || [];

    if (overrides.length || quantityOverrides.length) {
      const rowPrice = getProductBillingPeriodPrice(
        product,
        proposal.currency,
        override.billingPeriod
      );
      const baseLevels =
        product.levels && product.levels.length > 0
          ? getBaseLevelsByBillingPeriod(
              product.levels,
              override.billingPeriod,
              proposal.currency,
              product.entries
            )
          : undefined;
      return !!getPricingDurations(
        proposal.termQty,
        proposal.termType,
        rowPrice,
        proposal.freeMonths,
        overrides,
        item.quantity ? quantityOverrides : undefined,
        item.quantity ? item.quantity : undefined,
        baseLevels
      ).find((itemOverride) => {
        return (
          itemOverride.billingPeriod === override.billingPeriod &&
          itemOverride.startIndex === override.startIndex &&
          itemOverride.duration === override.duration &&
          itemOverride.price.amount === override.price.amount &&
          itemOverride.listPrice.amount === override.listPrice.amount &&
          itemOverride.discount === override.discount &&
          ((!itemOverride.levels && !override.levels) ||
            (itemOverride.levels &&
              override.levels &&
              areAllLevelsEqual(itemOverride.levels, override.levels))) &&
          (item.quantity
            ? itemOverride.quantity === override.quantity
            : true) &&
          itemBillingPeriods.includes(override.billingPeriod)
        );
      });
    }
    return false;
  };

  const areOverridePricesEqual = (
    itemOverride: ItemOverride,
    override: ItemOverride
  ): boolean => {
    if (itemOverride.levels) {
      return itemOverride.levels.every((level, index) => {
        return (
          level.price.amount === override.levels?.[index]?.price?.amount &&
          level.listPrice?.amount ===
            override.levels?.[index]?.listPrice?.amount
        );
      });
    } else {
      return (
        itemOverride.price?.amount === override.price?.amount &&
        itemOverride.listPrice?.amount === override.listPrice?.amount
      );
    }
  };

  const hasOverrideMatch = (override: ItemOverride): boolean =>
    !!billingPeriod &&
    getProductBillingPeriodOverrides(item, override.billingPeriod).some(
      (itemOverride) =>
        itemOverride.price &&
        itemOverride.billingPeriod === override.billingPeriod &&
        itemOverride.discount === override.discount &&
        isValidOverride(itemOverride) &&
        itemBillingPeriods.includes(override.billingPeriod) &&
        areOverridePricesEqual(itemOverride, override)
    );

  const isValidOverride = (override: ItemOverride): boolean => {
    return (
      !!override.billingPeriod && !scheduleDraft[override.billingPeriod]?.length
    );
  };

  const save = (newOverride?: ItemOverride) => {
    if (!item.id) {
      log.error('Item has no id on save');
      return;
    }

    if (isOneTime) {
      onUpdateOverrides(item.id, [newOverride].filter(isDefined));
    } else {
      const fullOverrideList = buildComprehensiveOverrideList(newOverride);

      onUpdateOverrides(
        item.id,
        fullOverrideList.price,
        fullOverrideList.quantity
      );
    }
  };

  const buildComprehensiveOverrideList = (newOverride?: ItemOverride) => {
    const billingPeriodOverrides = (item.overrides ?? []).filter(
      (override) =>
        isValidOverride(override) &&
        newOverride?.billingPeriod !== override.billingPeriod
    );

    const scheduleLines = Object.values(scheduleDraft)
      .filter(isDefined)
      .filter((lines) => lines.length > 0);

    const { overrides, quantityOverrides } = mergeLinesToOverrides(
      scheduleLines.flat()
    );

    const quantityOverridesFiltered = quantityOverrides.filter(
      (qo) => qo.quantity !== item.quantity
    );

    const scheduleOverridesFiltered = overrides.filter((override) => {
      if (override.levels && override.duration) {
        return override.levels.some((level) => {
          const baseAmount = getEntry(
            product.entries,
            proposal.currency,
            override.billingPeriod,
            level.level
          )?.price.amount;

          return (
            level.price.amount !== baseAmount ||
            level.listPrice?.amount !== baseAmount
          );
        });
      } else {
        const baseAmount = getProductBillingPeriodPrice(
          product,
          proposal.currency,
          override.billingPeriod
        ).amount;

        return (
          override.price?.amount !== baseAmount ||
          override.listPrice?.amount !== baseAmount
        );
      }
    });

    const priceOverrides = [
      ...billingPeriodOverrides,
      ...scheduleOverridesFiltered,
      newOverride,
    ].filter(isDefined);

    // remove any level overrides that match the base level price
    priceOverrides.forEach((override) => {
      if (override.levels) {
        override.levels = override.levels.filter((level) => {
          const entry = getEntry(
            product.entries,
            proposal.currency,
            override.billingPeriod,
            level.level
          )?.price.amount;

          return (
            level.price.amount !== entry || level.listPrice?.amount !== entry
          );
        });
      }
    });

    // remove listPrice if cant be set
    if (!allowListPriceAdjustments) {
      priceOverrides.forEach((override) => {
        delete override.listPrice;
        override.levels?.forEach((level) => {
          delete level.listPrice;
        });
      });
    }

    return { price: priceOverrides, quantity: quantityOverridesFiltered };
  };

  return (
    <div>
      <Drawer
        className={styles.billingPeriodPriceManagement}
        footer={
          <ButtonBar>
            <Button
              block
              dataTestId="update-price-change"
              label={tk('Done')}
              onClick={onClose}
            />
          </ButtonBar>
        }
        header={tk('Mark-up or down product prices')}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className={styles.drawerContent}>
          <div className={styles.descriptionText}>
            {tk(
              'Prices marked down from the original price will show as discounts, unless hidden.' +
                ' Price changes do not alter the original product and only apply to this proposal.'
            )}
          </div>
          <div className="mb-6">
            <strong>{item.name}</strong>
          </div>

          {isFixedPricing ? (
            <FixedProductManagement
              handleSetOverride={handleSetOverride}
              isSaving={isSaving}
              isValidOverride={isValidOverride}
              item={item}
              itemBillingPeriods={itemBillingPeriods}
              onUpdateOverrides={onUpdateOverrides}
              proposal={proposal}
              scheduleDraft={scheduleDraft}
              setScheduleDraft={setScheduleDraft}
            />
          ) : (
            <TieredProductManagement
              billingPeriod={billingPeriod}
              handleSetOverride={handleSetOverride}
              isSaving={isSaving}
              isValidOverride={isValidOverride}
              item={item}
              itemBillingPeriods={itemBillingPeriods}
              onUpdateOverrides={onUpdateOverrides}
              proposal={proposal}
              scheduleDraft={scheduleDraft}
              setScheduleDraft={setScheduleDraft}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
}
