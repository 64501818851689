import {
  getBillingPeriodLabelAdjective,
  ProductSummary,
  Proposal,
} from 'common';

export const getProposalProductValidationMessage = (
  product: ProductSummary,
  proposal: Proposal | undefined
): string | null => {
  if (!proposal) {
    return null;
  }

  if (product.currencies && !product.currencies.includes(proposal.currency)) {
    return `Missing currency (${proposal.currency})`;
  }

  if (product.recurrence === 'one_time' || product.billing === 'fixed') {
    return null;
  }

  const productPeriodSet = new Set(product.billingPeriods);
  const proposalPeriodSet = new Set(
    proposal.schedules.map((ps) => ps.payEvery)
  );
  const missingBillingPeriod = [...proposalPeriodSet].find(
    (period) => !productPeriodSet.has(period)
  );

  if (missingBillingPeriod) {
    return `Missing billing period (${getBillingPeriodLabelAdjective(missingBillingPeriod)})`;
  }

  return null;
};

export const checkIfIsValidProductForProposal = (
  product: ProductSummary,
  proposal: Proposal
): boolean => !getProposalProductValidationMessage(product, proposal);
