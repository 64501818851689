import React from 'react';
import {
  Cell,
  DefaultZeroState,
  Loading,
  SimpleTable,
  TransferEvent,
  useTranslation,
} from 'common';
import DateTimeCell from '../../components/DateTimeCell';
import { useTransactionEvents } from '../../services/transfer';
import ErrorHandler from '../../components/ErrorHandler';
import { useIsSuperAdmin } from '../../core-utils/helperFunctions/userServiceHelper';

interface Props {
  detailLink: string;
  transactionId: string;
}

const TransactionActivity: React.FC<Props> = ({
  transactionId,
  detailLink,
}) => {
  const isFailed = (event: TransferEvent) => {
    return !['pending', 'in_progress', 'posted'].includes(event.status);
  };

  const {
    isLoading,
    error,
    isError,
    data: events,
  } = useTransactionEvents(transactionId, detailLink);
  const { tk } = useTranslation();
  const isSuperAdmin = useIsSuperAdmin();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorHandler error={error} />;
  }

  const cells: Cell<TransferEvent>[] = [
    {
      key: 'processedAtFormatted',
      headerLabel: tk('Date / Time'),
      width: '10%',
      renderCell: (data: TransferEvent) => (
        <DateTimeCell date={data.createdAt} />
      ),
    },
    {
      key: 'amount',
      headerLabel: tk('Amount'),
      width: '10%',
      renderCell: (data: TransferEvent) => (
        <div>
          {data.paymentType} {data.amountFormatted}
        </div>
      ),
    },
    {
      key: 'reason',
      headerLabel: tk('Payment information'),
      width: '30%',
      renderCell: (data: TransferEvent) => {
        const className = isFailed(data) ? 'text-red' : 'text-green';
        const message = `${data.status.toUpperCase()} ${tk(
          data.processorCode || ''
        )}`;
        return (
          <div className={` ${className} whitespace-normal`}>
            <div>{message}</div>
            <div className="text-xs">{data.reason}</div>
          </div>
        );
      },
    },

    {
      key: 'referenceId',
      headerLabel: tk('Schedule reference ID'),
      renderCell: (data: TransferEvent) => <div>{data.referenceNumber}</div>,
    },
  ];

  if (isSuperAdmin) {
    cells.push({
      key: 'externalId',
      headerLabel: tk('External ID'),
      renderCell: (data: TransferEvent) => (
        <div>
          <div>{data.processor}</div>
          <div className="text-xs">{data.externalId}</div>
        </div>
      ),
    });
  }

  return (
    <div className="w-full">
      <SimpleTable<TransferEvent>
        disableSearch
        disableShadow
        cells={cells}
        rows={events}
        isLoading={isLoading}
        zeroState={
          <DefaultZeroState message="There is no activity for this transaction." />
        }
      />
    </div>
  );
};

export default TransactionActivity;
