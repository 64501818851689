import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { log, useTranslation } from 'common';

const pathTitleMap = new Map<string, string>([
  ['login', 'Login'],
  ['integrations', 'Integrations and Connections'],
  ['proposals', 'Proposals'],
  ['subscriptions', 'Subscriptions'],
  ['transactions', 'Transactions'],
  ['customers', 'Customers'],
  ['products', 'Products'],
  ['plans', 'Payment plan sets'],
  ['attachments', 'Attachments'],
  ['settings', 'Account settings'],
  ['system', 'System'],
  ['payments', 'Payments'],
]);

function useManageDocumentTitle() {
  const location = useLocation();
  const { tk } = useTranslation();
  useEffect(() => {
    try {
      const firstPathPart = location.pathname.split('/')[1];
      let newDocTitle = firstPathPart && pathTitleMap.get(firstPathPart);
      if (!newDocTitle) {
        log.warn(
          `No document title specified for path at url ${location.pathname}`
        );
        newDocTitle = 'Cacheflow';
      }
      document.title = tk(newDocTitle);
    } catch (error) {
      log.warn('Document title-changing hook crashed:');
      log.warn(error);
      document.title = 'Cacheflow';
    }
  }, [location]);
}

export default useManageDocumentTitle;
