import { ItemDescriptionArea, useIsEditingItem } from 'common';
import React, { ComponentProps } from 'react';

export const EditableItemDescriptionArea = ({
  item,
  isVisible,
  children,
}: ComponentProps<typeof ItemDescriptionArea>) => {
  const isEditing = useIsEditingItem(item);

  return (
    <ItemDescriptionArea isVisible={isVisible || isEditing} item={item}>
      {children}
    </ItemDescriptionArea>
  );
};
