import React, { PropsWithChildren, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { DeleteItemScheduleContext, useToast } from 'common';
import { useDeleteItemSchedule } from '../../../../services/proposal';

export const DeleteItemScheduleProvider = ({
  proposalId,
  children,
}: PropsWithChildren<{ proposalId: string }>) => {
  const qc = useQueryClient();
  const showToast = useToast();

  const { mutate } = useDeleteItemSchedule(
    proposalId,
    null,
    () => showToast.error('Item schedule delete failed'),
    qc
  );

  const update = useCallback((id: string) => {
    mutate(id);
  }, []);

  return (
    <DeleteItemScheduleContext.Provider value={update}>
      {children}
    </DeleteItemScheduleContext.Provider>
  );
};
