import React from 'react';
import { getErrorMessage, Icon, PopOutMenuOption, useToast } from 'common';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateTaxes } from '../../../../services/contract';

interface Props {
  scheduleId: string;
}

const TriggerUpdateTax: React.FC<Props> = ({ scheduleId }: Props) => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleSuccess = () => {
    showToast.success('Taxes have been successfully updated');
  };

  const handleError = (error: unknown) => {
    const msg = getErrorMessage(error);
    showToast.error(msg || 'Tax update error');
  };

  const { mutate: triggerProcess } = useUpdateTaxes(
    scheduleId,
    handleSuccess,
    handleError,
    queryClient
  );

  return (
    <PopOutMenuOption
      icon={Icon.TriangleRight}
      onClick={() => triggerProcess(scheduleId)}
      title="Update taxes"
    />
  );
};

export default TriggerUpdateTax;
