import React from 'react';
import {
  ForceUser,
  formatDatetime,
  SalesforceConfig,
  Type,
  Flex,
  SmallGrayText,
} from 'common';
import styles from './SalesforceConnectionExtras.module.scss';

interface Props {
  connectorConfigValue?: SalesforceConfig;
  salesforceUser: ForceUser | undefined;
}

const SalesforceDetails: React.FC<Props> = ({
  connectorConfigValue,
  salesforceUser,
}) => {
  return (
    <div className="flex flex-col gap-2 mb-2">
      <Flex vertical>
        <SmallGrayText text="Name" className={styles.labelGray} />
        <Type>{`${salesforceUser?.FirstName} ${salesforceUser?.LastName}`}</Type>
      </Flex>
      <Flex vertical>
        <SmallGrayText text="Email" className={styles.labelGray} />
        <Type>{salesforceUser?.Username}</Type>
      </Flex>
      <h3 className="font-medium text-lg my-2">Salesforce Package</h3>
      {salesforceUser?.PackageInstalledAt ? (
        <Flex vertical gap={8}>
          <Flex vertical>
            <SmallGrayText text="Version" className={styles.labelGray} />
            <Type>{salesforceUser.PackageVersion}</Type>
          </Flex>
          <Flex vertical>
            <SmallGrayText text="Installed" className={styles.labelGray} />
            <Type>{formatDatetime(salesforceUser.PackageInstalledAt)}</Type>
          </Flex>
          {connectorConfigValue?.priceBook2Id && (
            <Flex vertical>
              <SmallGrayText
                text="Cacheflow pricebook"
                className={styles.labelGray}
              />
              <Type>{connectorConfigValue.priceBook2Id}</Type>
            </Flex>
          )}
        </Flex>
      ) : (
        <div>
          <Type size={18} paragraph>
            Cacheflow for Salesforce package not installed
          </Type>
        </div>
      )}
    </div>
  );
};

export default SalesforceDetails;
