import React from 'react';

import { useGetPendingProposalApprovalRequest } from '../../../../services/proposal';
import { useGetGroupUsers } from '../../../../services/groups';
import { Accordion, getPersonFullName, useTranslation } from 'common';

interface Props {
  proposalId: string;
}

// we could have a pattern for components like this that make two, dependent api calls
const ProposalApprovers: React.FC<Props> = ({ proposalId }) => {
  const { tk } = useTranslation();
  const {
    data: dataPendingRequests,
    isPending: isLoadingPendingRequests,
    isError: isErrorPendingRequests,
  } = useGetPendingProposalApprovalRequest(proposalId);

  const approvers = dataPendingRequests?.approvers;
  const approvalGroupId = approvers ? approvers[0]?.groupId : undefined;

  const {
    data: dataGroupUsers,
    isPending: isLoadingGroupUsers,
    isError: isErrorGroupUsers,
  } = useGetGroupUsers(approvalGroupId);

  if (
    isLoadingPendingRequests ||
    isErrorPendingRequests ||
    isLoadingGroupUsers ||
    isErrorGroupUsers ||
    dataGroupUsers.length === 0
  ) {
    return null;
  }

  return (
    <Accordion
      header={
        <span className="font-bold text-xs">
          {tk('Show list of approvers')}
        </span>
      }
    >
      <div>
        {dataGroupUsers.map((user) => (
          <div key={getPersonFullName(user)} className="text-xs">
            {getPersonFullName(user)}
          </div>
        ))}
      </div>
    </Accordion>
  );
};

export default ProposalApprovers;
