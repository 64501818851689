import React from 'react';
import { ACHFields, MethodProps } from './utilities';

// TODO reuse the one from checkout
const ACH = ({ paymentMethod }: MethodProps) => {
  const getACHDetails = () => {
    return ACHFields.map((field) => {
      return (
        <div
          key={field.label}
          className="flex w-1/2 my-2 rounded-md flex-nowrap xs:text-xs sm:text-sm"
        >
          <div className="w-1/2 font-medium">{field.label}</div>
          <div className="w-1/2 font-semibold">
            {paymentMethod.configuration
              ? (paymentMethod.configuration[field.name] as React.ReactNode)
              : '-'}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="w-full xs:mt-8 sm:mt-0">
      <div className="mb-4 font-semibold">Bank details</div>
      {getACHDetails()}
    </div>
  );
};

export default ACH;
