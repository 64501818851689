import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { ApprovalGroup } from 'common';

import { apiClient } from '../../httpClients/app';

const APPROVAL_GROUPS_PATH = '/api/latest/data/approval-groups';

export const useGetAllApprovalGroups = (): UseQueryResult<ApprovalGroup[]> =>
  useQuery({
    queryKey: [APPROVAL_GROUPS_PATH],
    queryFn: async () => {
      const { data } = await apiClient.getAllApprovalGroups();
      return data;
    },
  });

const deleteApprovalGroup = async (groupId: string): Promise<void> => {
  await apiClient.deleteApprovalGroup(groupId);
};

export const useDeleteApprovalGroup = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<void, Error, ApprovalGroup['id']>({
    mutationFn: (groupId) => deleteApprovalGroup(groupId!),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [APPROVAL_GROUPS_PATH],
      });
    },
  });
