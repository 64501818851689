import React from 'react';
import { CLOSE_INFO, closeTabs as tabs } from './CloseConstants';
import { accountContactsConfig } from './CloseConstants/AccountContactsConfig';
import ConnectorSettings from '../Connectors/ConnectorSettings';

import CloseSVG from '../../../assets/integration-logos/CloseSVG';

const CloseSettings = () => {
  return (
    <ConnectorSettings
      accountContactsConfig={accountContactsConfig}
      connectorInfo={CLOSE_INFO}
      tabs={tabs}
      logo={CloseSVG}
    />
  );
};

export default CloseSettings;
