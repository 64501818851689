import React, { useEffect, useRef, useState } from 'react';
import {
  FormField,
  FormModal,
  getUrlValidator,
  Input,
  InputRef,
  useFormValidation,
  useTranslation,
} from 'common';

interface Props {
  draft: {
    text: string;
    url: string;
  };
  isOpen: boolean;
  onAddLink: (linkText: string, url: string) => void;
  onClose: () => void;
}

type Link = {
  text: string;
  url: string;
};

const AddLinkModal: React.FC<Props> = ({
  isOpen,
  draft,
  onClose,
  onAddLink,
}) => {
  const { tk } = useTranslation();
  const [linkDraft, setLinkDraft] = useState<Link>(draft);
  const textInputRef = useRef<InputRef>(null);
  const urlInputRef = useRef<InputRef>(null);

  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<Link>(
      [
        {
          fieldName: 'text',
          isRequired: true,
          humanReadableName: 'Link text',
        },
        {
          fieldName: 'url',
          isRequired: true,
          validator: getUrlValidator(),
          humanReadableName: 'Link URL',
        },
      ],
      linkDraft
    );

  useEffect(() => {
    if (isOpen) {
      resetUIState();
      setLinkDraft(draft);
      setTimeout(() => {
        // TODO: figure out why/if all these focusing calls have to be after timeouts
        if (urlInputRef.current) {
          urlInputRef.current.focus();
        } else if (textInputRef.current) {
          textInputRef.current.focus();
        }
      }, 100);
    }
  }, [isOpen]);

  const submitIfValid = () => {
    if (isFormValid && isOpen) {
      saveLink();
    }
  };

  const saveLink = (): void => {
    const urlWithProtocol = linkDraft.url.startsWith('http')
      ? linkDraft.url
      : `http://${linkDraft.url}`;
    onAddLink(linkDraft.text, urlWithProtocol);
  };

  const handleTextChange = (newValue: string): void => {
    setLinkDraft((prev) => ({ ...prev, text: newValue }));
  };

  const handleUrlChange = (newValue: string): void => {
    setLinkDraft((prev) => ({ ...prev, url: newValue }));
  };

  return (
    <FormModal
      isOpen={isOpen}
      width={600}
      entityTypeName="link"
      onClose={onClose}
      onSubmit={saveLink}
      isSubmitDisabled={!isFormValid}
      isNewEntity
    >
      <FormField label={tk('Link text')} errorToShow={getErrorToShow('text')}>
        <Input
          id="link_text"
          value={linkDraft.text}
          onChange={handleTextChange}
          onBlur={() => setHasVisitedField('text')}
          onEnter={submitIfValid}
          ref={textInputRef}
        />
      </FormField>
      <FormField label={tk('Link URL')} errorToShow={getErrorToShow('url')}>
        <Input
          id="link_url"
          value={linkDraft.url}
          onChange={handleUrlChange}
          onBlur={() => setHasVisitedField('url')}
          onEnter={submitIfValid}
          ref={urlInputRef}
        />
      </FormField>
    </FormModal>
  );
};

export default AddLinkModal;
