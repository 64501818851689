import {
  Card,
  CardHeader,
  Cell,
  Contract,
  formatDateShort,
  formatMoneyOrDefault,
  getToday,
  ItemIntervalMetrics,
  SimpleTable,
} from 'common';
import React from 'react';
import { useGetItemIntervalMetrics } from '../../../../services/contract';
import styles from './Metrics.module.scss';

interface Props {
  contract: Contract;
}

const ItemIntervalMetricsCard: React.FC<Props> = ({ contract }) => {
  const { data: itemIntervalMetrics } = useGetItemIntervalMetrics(contract.id);

  let sortedMetrics: ItemIntervalMetrics[] = [];
  if (itemIntervalMetrics) {
    sortedMetrics = [...itemIntervalMetrics].sort((a, b) => {
      const dateA = new Date(a.startDate).getTime();
      const dateB = new Date(b.startDate).getTime();
      return dateA - dateB;
    });
  }

  const cells: Cell<ItemIntervalMetrics>[] = [
    {
      key: 'product',
      headerLabel: 'Product',
      renderCell: ({ product }: ItemIntervalMetrics) => product.name,
    },
    {
      key: 'recurrence-pricing',
      headerLabel: ['Recurrence', 'Pricing'],
      renderCell: ({ product }: ItemIntervalMetrics) => (
        <div>
          <div>{product.recurrence}</div>
          <div>{product.pricing}</div>
        </div>
      ),
    },
    {
      key: 'billing-period',
      headerLabel: 'Billing period',
      renderCell: (metrics: ItemIntervalMetrics) => metrics.billingPeriod,
    },
    {
      key: 'startDate',
      headerLabel: 'Start date',
      renderCell: (metrics) => formatDateShort(metrics.startDate),
    },
    {
      key: 'endDate',
      headerLabel: 'End date',
      renderCell: (metrics) => formatDateShort(metrics.endDate),
    },
    {
      key: 'quantity',
      headerLabel: 'Quantity',
      renderCell: (metrics) => metrics.quantity,
    },
    {
      key: 'list-tcv',
      headerLabel: 'List TCV',
      renderCell: (metrics) => formatMoneyOrDefault(metrics.tcv),
    },
    {
      key: 'tcv',
      headerLabel: 'TCV',
      renderCell: (metrics) => formatMoneyOrDefault(metrics.tcv),
    },
    {
      key: 'arr',
      headerLabel: 'ARR',
      renderCell: (metrics) => formatMoneyOrDefault(metrics.arr),
    },
    {
      key: 'mrr',
      headerLabel: 'MRR',
      renderCell: (metrics) => formatMoneyOrDefault(metrics.mrr),
    },
  ];

  type MetricField = 'arr' | 'mrr';
  const getActiveMetricTotal = (metricField: MetricField) => {
    // filter metrics that fall in between the current date
    const currentDate = getToday();
    const currentAmount = sortedMetrics
      .filter(
        (metric) =>
          metric.startDate <= currentDate && metric.endDate >= currentDate
      )
      .reduce((acc, metric) => acc + (metric[metricField].amount ?? 0), 0);

    return formatMoneyOrDefault({
      amount: currentAmount,
      currency: contract.currency,
    });
  };

  const getMetricField = (metricField: MetricField) => {
    return (
      <div className={styles.metric}>
        <div className={styles.metricLabel}>Current {metricField}</div>
        <div className={styles.metricValue}>
          {getActiveMetricTotal(metricField)}
        </div>
      </div>
    );
  };

  return (
    <Card>
      <CardHeader name="Item interval metrics" />
      <div className={styles.currentMetrics}>
        {getMetricField('arr')}
        {getMetricField('mrr')}
      </div>
      <SimpleTable<ItemIntervalMetrics>
        cells={cells}
        dataTestId="item-interval-metrics-table"
        disableSearch
        isLoading={!itemIntervalMetrics}
        minWidth="720px"
        rows={sortedMetrics}
      />
    </Card>
  );
};

export default ItemIntervalMetricsCard;
