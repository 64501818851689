import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Loading,
  StripeConfig,
  ToggleSwitch,
  useToast,
  useTranslation,
} from 'common';
import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';
import { clearPaymentMethodsCache } from '../../../services/paymentMethods';

const StripeUnmanagedMethodsToggle: React.FC = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useFindOrgDefault('stripeConfig');
  const { tk } = useTranslation();
  const showToast = useToast();

  const onSuccess = async () => {
    showToast.info(tk('Setting saved.'));
    // clear payment methods cache to force refetch
    await clearPaymentMethodsCache(queryClient);
  };

  const { mutate } = useSetOrgConfigDefault(
    'stripeConfig',
    onSuccess,
    () => showToast.error(tk('Failed to save setting. Please try again.')),
    queryClient
  );

  if (isLoading || data === undefined) {
    return <Loading />;
  }

  const updateStripeConfig = (allowUnmanagedTypes: boolean) => {
    mutate({
      configValue: {
        ...data?.configValue,
        unmanagedPaymentTypes: allowUnmanagedTypes
          ? ['cc', 'direct_debit']
          : [],
      },
    }) as any;
  };

  const allowUnmanagedTypes = () => {
    if (data?.configValue) {
      const stripeConfig: StripeConfig = data.configValue;
      return (
        stripeConfig.unmanagedPaymentTypes &&
        stripeConfig.unmanagedPaymentTypes.length > 0
      );
    }
    return false;
  };

  return (
    <>
      <ToggleSwitch
        value={allowUnmanagedTypes()}
        label={tk(
          'Enable payment method to capture in checkout for later use in Stripe Billing'
        )}
        name="stripeConfig.unmanagedMethods"
        onChange={updateStripeConfig}
      />
      <div className="mt-1 text-xs text-slate">
        {tk(
          'Cacheflow will not manage the payments for customers that select these payment methods'
        )}
      </div>
    </>
  );
};

export default StripeUnmanagedMethodsToggle;
