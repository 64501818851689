import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { ApprovalGroup, Group, User } from 'common';

const GET_ALL_GROUPS_PATH = '/api/latest/data/groups';

export const getGroupPath = (groupId: string) => {
  return GET_ALL_GROUPS_PATH + '/' + groupId;
};

export const useGetGroup = (groupId?: string): UseQueryResult<Group> => {
  return useQuery({
    queryKey: ['apiClient.getGroupById', groupId],
    queryFn: async () => {
      const { data } = await apiClient.getGroupById(groupId!);
      return data;
    },
    enabled: !!groupId,
  });
};

export type ApprovalGroupWithUsers = {
  createdAt: string;
  createdBy: string;
  group: Group;
  id: string;
  priority?: number | undefined;
  updatedAt?: string | undefined;
  updatedBy?: string | undefined;
  users: User[];
};

export const useGetApprovalGroupsWithUsers = (
  approvalGroups?: ApprovalGroup[]
): UseQueryResult<ApprovalGroupWithUsers[]> => {
  return useQuery({
    queryKey: ['approvalGroupsWithUsers'],
    queryFn: async () => {
      if (!approvalGroups || approvalGroups.length === 0) return [];

      const groupsWithUsers = await Promise.all(
        approvalGroups.map(async (approvalGroup) => {
          const users = await apiClient.getUsersByGroupId(
            approvalGroup.group?.id!
          );

          return {
            ...approvalGroup,
            users: users.data,
          };
        })
      );
      return groupsWithUsers;
    },
    enabled: !!approvalGroups && approvalGroups.length > 0,
  });
};

export const useGetGroupUsers = (groupId?: string) =>
  useQuery<User[]>({
    queryKey: [getGroupPath(groupId!)],
    queryFn: async () => {
      const { data } = await apiClient.getUsersByGroupId(groupId!);
      return data;
    },
    enabled: !!groupId,
  });

const updateGroupUsers = async ({
  groupId,
  userIds,
}: {
  groupId: string;
  userIds: string[];
}) => {
  const { data } = await apiClient.updateGroupUsers(groupId, {
    userIds,
  });
  return data;
};

export const useUpdateGroupUsers = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient,
  queryKeyToInvalidate?: string | string[]
) =>
  useMutation({
    mutationKey: ['UpdateGroupUsers'],
    mutationFn: updateGroupUsers,
    onSuccess,
    onError,
    onSettled: async () => {
      if (queryKeyToInvalidate) {
        const queryKeys = Array.isArray(queryKeyToInvalidate)
          ? queryKeyToInvalidate
          : [queryKeyToInvalidate];

        for (const key of queryKeys) {
          await qc.invalidateQueries({ queryKey: [key] });
        }
      } else {
        await qc.invalidateQueries();
      }
    },
  });

export const useRemoveUsersFromGroup = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient,
  queryKeyToInvalidate?: string | string[]
) =>
  useMutation({
    mutationKey: ['RemoveGroupUsers'],
    mutationFn: removeGroupUsers,
    onSuccess,
    onError,
    onSettled: async () => {
      if (queryKeyToInvalidate) {
        const queryKeys = Array.isArray(queryKeyToInvalidate)
          ? queryKeyToInvalidate
          : [queryKeyToInvalidate];

        await qc.invalidateQueries({ queryKey: queryKeys });
      } else {
        await qc.invalidateQueries({ queryKey: [GET_ALL_GROUPS_PATH] });
      }
    },
  });

const removeGroupUsers = async ({
  groupId,
  userIds,
}: {
  groupId: string;
  userIds: string[];
}) => {
  const { data } = await apiClient.deleteUsersFromGroup(groupId, {
    userIds,
  });
  return data;
};
