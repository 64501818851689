import { QueryClient, useMutation } from '@tanstack/react-query';
import { Document } from 'common';
import { appAxiosDeprecated } from '../../httpClients/app';

const API_DOCUMENTS_PATH = '/api/latest/documents';
const ALL_DOCUMENTS_KEY = 'AllDocuments';

interface documentArgs {
  document: Document;
  file: File;
}

export const doCreateDocument = async ({ document, file }: documentArgs) => {
  const formData = new FormData();

  const jsonBlob = new Blob([JSON.stringify(document)], {
    type: 'application/json',
  });
  formData.append('document', jsonBlob);
  formData.append('file', file);

  // TODO: Add this endpoint to apiClient
  const { data } = await appAxiosDeprecated.post(API_DOCUMENTS_PATH, formData);
  return data;
};

export const useCreateDocument = (
  onSuccess: (data: Document) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['CreateDocument'],
    mutationFn: doCreateDocument,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_DOCUMENTS_KEY] });
    },
  });
