import React from 'react';
import { SyncEvent } from 'common';
import ExternalLink from './Sync/ExternalLink';
import {
  ConnectableEntity,
  getCRMExternalSource,
} from '../core-utils/helperFunctions/types';

interface Props {
  entity: ConnectableEntity;
  showId?: boolean;
  lastSyncEvent?: SyncEvent;
  customDisplayText?: string;
  logoSize?: number;
}

const ExternalEntityLink: React.FC<Props> = ({
  entity,
  showId = false,
  lastSyncEvent,
  customDisplayText,
  logoSize = 24,
}) => {
  const externalSource = getCRMExternalSource(entity);
  return (
    <ExternalLink
      externalSource={externalSource}
      showId={showId}
      lastSyncEvent={lastSyncEvent}
      customDisplayText={customDisplayText}
      logoSize={logoSize}
    />
  );
};

export default ExternalEntityLink;
