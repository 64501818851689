import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateDocument } from '../../../services/api/documents/documents';
import {
  capitalizeFirstLetter,
  Document,
  FileInput,
  FormField,
  FormModal,
  getErrorMessage,
  getUrlValidator,
  Input,
  useFormValidation,
  useToast,
  useTranslation,
} from 'common';
import styles from './AttachmentDocumentAndLinkForm.module.scss';

interface Props {
  isShowing: boolean;
  onClose: () => void;
  onSaveSuccess: (
    attachmentName: string,
    documentId?: string,
    linkUrl?: string
  ) => void;
}

const AttachmentDocumentAndLinkForm: React.FC<Props> = ({
  isShowing,
  onClose,
  onSaveSuccess,
}) => {
  const { tk } = useTranslation();
  const showToast = useToast();
  const [uploadedDocumentId, setUploadedDocumentId] = useState<
    string | undefined
  >(undefined);
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(
    undefined
  );
  const [linkUrl, setLinkUrl] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();
  const {
    getErrorToShow,
    resetUIState,
    isFormValid: isLinkUrlValid,
    setHasVisitedField,
  } = useFormValidation(
    [
      {
        fieldName: 'linkUrl',
        isRequired: false,
        validator: uploadedDocumentId ? undefined : getUrlValidator(true),
      },
      {
        fieldName: 'name',
        isRequired: true,
        validator: (value: string) => (value ? null : 'Name is required'),
      },
    ],
    { linkUrl, name }
  );

  useEffect(() => {
    if (!isShowing) {
      setUploadedDocumentId(undefined);
      setUploadedFileName(undefined);
      setLinkUrl(undefined);
      setName(undefined);
      resetUIState();
    }
  }, [isShowing]);

  const handleDocumentUploadSuccess = (savedDocument: Document) => {
    setUploadedFileName(savedDocument.fileName);
    setUploadedDocumentId(savedDocument.id);
  };

  const handleDocumentUploadError = (error: unknown) => {
    const errorMessage =
      getErrorMessage(error) ||
      tk('There was a problem uploading this attachment, please try again.');
    showToast.error(capitalizeFirstLetter(errorMessage), 5);
  };

  const { mutate: createDocument, isPending: isCreatingDocument } =
    useCreateDocument(
      handleDocumentUploadSuccess,
      handleDocumentUploadError,
      queryClient
    );

  const clearUploadedDocument = () => {
    setUploadedDocumentId(undefined);
    setUploadedFileName(undefined);
  };

  const handleFileChange = (newFile: File) => {
    const filePath = newFile.name;
    const fileName =
      filePath.substring(filePath.lastIndexOf('/') + 1) || tk('Untitled');

    if (!name) {
      // Removes file extension
      const nameTemp = fileName.replace(/\.[^/.]+$/, '');
      setName(nameTemp);
    }

    createDocument({ document: { fileName }, file: newFile });
  };

  const isFormValid = !!uploadedDocumentId || (!!linkUrl && isLinkUrlValid);

  const saveIfValid = () => {
    if (isFormValid && name) {
      if (uploadedDocumentId) {
        onSaveSuccess(name, uploadedDocumentId);
      } else if (linkUrl) {
        onSaveSuccess(name, undefined, linkUrl);
      }
    } else {
      setHasVisitedField('name');
      setHasVisitedField('linkUrl');
    }
  };

  return (
    <FormModal
      className={styles.attachmentDocumentAndLinkForm}
      entityTypeName="attachment"
      isNewEntity
      isOpen={isShowing}
      onClose={onClose}
      onSubmit={saveIfValid}
      submitDataTestId="create-attachment-button"
    >
      <FormField label={tk('Name')} errorToShow={getErrorToShow('name')}>
        <Input
          dataTestId="name-input"
          onBlur={() => setHasVisitedField('name')}
          onChange={setName}
          onEnter={saveIfValid}
          placeholder="Attachment name"
          selectAllContentOnFocus
          value={name}
        />
      </FormField>

      <div className={styles.orFields}>
        <FormField label={tk('Upload a file')}>
          <FileInput
            dataTestId="file-upload-input"
            isDisabled={!!linkUrl}
            isUploading={isCreatingDocument}
            onChange={handleFileChange}
            onClearFile={uploadedDocumentId ? clearUploadedDocument : undefined}
            selectedFileName={uploadedFileName}
            uploadingPlaceholder="Uploading file"
          />
        </FormField>

        <div className={styles.or}>or</div>

        <FormField
          label={tk('Link / URL')}
          errorToShow={getErrorToShow('linkUrl')}
        >
          <Input
            dataTestId="url-input"
            isDisabled={!!uploadedDocumentId}
            onBlur={() => setHasVisitedField('linkUrl')}
            onChange={setLinkUrl}
            onEnter={saveIfValid}
            placeholder="Type or paste link here"
            selectAllContentOnFocus
          />
        </FormField>
      </div>
    </FormModal>
  );
};

export default AttachmentDocumentAndLinkForm;
