import React from 'react';
import { DocuSignLogo } from 'common';
import ProposalSection from '../ProposalSection/ProposalSection';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

interface Props {
  isDisabled: boolean;
  requiresSigning?: boolean;
  setRequiresSigning: (required: boolean) => void;
}

const SignWithDocuSign: React.FC<Props> = ({
  isDisabled,
  requiresSigning,
  setRequiresSigning,
}) => {
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  if (!canUpdate && !requiresSigning) {
    return null;
  }

  return (
    <ProposalSection
      section="Add Sign With DocuSign"
      sectionRef="DocuSign"
      title="Sign With DocuSign"
      onRemove={() => setRequiresSigning(false)}
      onOpen={() => setRequiresSigning(true)}
      openOnLoad={requiresSigning}
      isDisabled={isDisabled}
      dataTestId="sign-with-docusign-section"
    >
      <div className="flex items-center gap-4 divide-x-2 divide-slate-darkest">
        <div className="w-1/3">
          <DocuSignLogo />
        </div>
        <div className="pl-4">
          <p>
            <span className="text-lg font-semibold">
              DocuSign signing is enabled on this proposal.
            </span>{' '}
            <br />
            On checkout the customer can generate a signable DocuSign contract
            on the fly with the selected options. The contract must be signed
            before the customer sets up payments.
          </p>
        </div>
      </div>
    </ProposalSection>
  );
};

export default SignWithDocuSign;
