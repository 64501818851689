import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Cell,
  ContractSummary,
  Customer,
  DefaultZeroState,
  formatDateOrDefault,
  formatNullableFormattedAmount,
  getBillingPeriodLabelAdverb,
  getIconCell,
  ItemIcon,
  SimpleTable,
} from 'common';
import StatusLabel from 'app/src/components/StatusLabel';
import { useCustomerSubscriptions } from 'app/src/services/customer';
import { getPaymentMethodName } from 'app/src/pages/Subscriptions/SubscriptionDetail/hook';
import { isContractManaged } from 'app/src/pages/Subscriptions/contractUtils';
import { getContractDisplayStatus } from '../../../../services/contractView';

interface Props {
  customer: Customer;
}

const CustomerSubscriptionCard: React.FC<Props> = ({ customer }) => {
  const navigate = useNavigate();

  const handleTableRowClick = (selectedRow: ContractSummary) => {
    navigate(`/subscriptions/${selectedRow.id}`);
  };

  const { data: contracts, isLoading } = useCustomerSubscriptions(customer.id);

  const cells: Cell<ContractSummary>[] = [
    getIconCell(ItemIcon.Subscription),
    {
      key: 'name',
      headerLabel: ['Customer', 'Subscription No.'],
      overflowCell: true,
      renderCell: (data: ContractSummary) => (
        <div>
          <div className="text-ellipsis overflow-hidden">{customer.name}</div>
          <div className="text-xs">{data.contractNumber || '-'}</div>
        </div>
      ),
    },
    {
      key: 'startDate',
      headerLabel: ['Start'],
      width: '110px',
      renderCell: (data: ContractSummary) => (
        <div>{formatDateOrDefault(data.startDate)}</div>
      ),
    },
    {
      key: 'endDate',
      headerLabel: ['End'],
      width: '110px',
      renderCell: (data: ContractSummary) => (
        <div>{formatDateOrDefault(data.endDate)}</div>
      ),
    },
    {
      key: 'totalContractValue',
      headerLabel: 'TCV',
      align: 'right',
      width: '130px',
      renderCell: (data: ContractSummary) => (
        <div>{formatNullableFormattedAmount(data.totalAmountFormatted)}</div>
      ),
    },
    {
      key: 'nextPayment',
      headerLabel: 'Next payment',
      width: '110px',
      renderCell: (data: ContractSummary) =>
        isContractManaged(data) && (
          <div>
            <div className="flex justify-start">
              {formatDateOrDefault(data.nextPaymentDue?.scheduleDate)}
            </div>
            <div className="flex justify-start text-xs opacity-75">
              {formatNullableFormattedAmount(
                data.nextPaymentDue?.amountDueFormatted
              )}
            </div>
          </div>
        ),
    },
    {
      key: 'paymentMethod',
      headerLabel: ['Bill. frequency', 'Method'],
      width: '180px',
      overflowCell: true,
      renderCell: (data: ContractSummary) => (
        <div>
          <div>
            {data.billingPeriod
              ? getBillingPeriodLabelAdverb(data.billingPeriod)
              : '-'}
          </div>
          <div className="flex">{getPaymentMethodName(data)}</div>
        </div>
      ),
    },
    {
      key: 'status',
      headerLabel: 'Status',
      width: '110px',
      renderCell: (data: ContractSummary) => (
        <div className="text-center">
          <StatusLabel
            status={getContractDisplayStatus(data)}
            feature="contract"
          />
        </div>
      ),
    },
  ];

  return (
    <Card>
      <CardHeader name="Subscriptions" />
      <SimpleTable<ContractSummary>
        cells={cells}
        disableSearch
        disableShadow
        onRowClick={handleTableRowClick}
        rows={contracts}
        minWidth="900px"
        zeroState={
          <DefaultZeroState message="There are no subscriptions to display." />
        }
        isLoading={isLoading}
      />
    </Card>
  );
};

export default CustomerSubscriptionCard;
