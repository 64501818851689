import React from 'react';
import History from '@tiptap/extension-history';
import { EditorFeature } from '../editorCommon';
import EditorButton from '../EditorButton';
import { EditorIconRedo, EditorIconUndo } from '../editorIcons';

History.configure({
  depth: 10,
  // may need to tweak this later
  // newGroupDelay: 1000,
});

const historyFeature: EditorFeature = {
  barButtons: (editor) => (
    <>
      <EditorButton
        isDisabled={!editor.can().undo()}
        label="Undo"
        onClick={() => editor.chain().focus().undo().run()}
        icon={EditorIconUndo}
      />
      <EditorButton
        isDisabled={!editor.can().redo()}
        label="Redo"
        onClick={() => editor.chain().focus().redo().run()}
        icon={EditorIconRedo}
      />
    </>
  ),
  extensions: [History],
  key: 'history',
};

export default historyFeature;
