import React from 'react';

interface Props {
  children: React.ReactNode;
}

const EventClusters: React.FC<Props> = ({ children }) => {
  return <div className="flex flex-col gap-6">{children}</div>;
};

export default EventClusters;
