import { log } from 'common';
import { useState } from 'react';

// Based but modified from https://usehooks.com/useLocalStorage/
// Usage:
//   const [name, setName, clearName] = useLocalStorage<string>('name', 'Bob');

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  onChange?: (newVal: T) => void
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    const initialState = ((): T => {
      try {
        // Get from local storage by key
        const item = localStorage.getItem(key);

        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        log.error(error);
        return initialValue;
      }
    })();

    if (onChange) {
      onChange(initialState);
    }

    return initialState;
  });

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so that we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      // Save state
      if (onChange) {
        onChange(valueToStore);
      }

      setStoredValue(valueToStore);

      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      log.error(error);
    }
  };

  const clearValue = () => {
    if (onChange) {
      onChange(initialValue);
    }

    setStoredValue(initialValue);

    localStorage.removeItem(key);
  };

  return [storedValue, setValue, clearValue] as const;
}
