import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import { ApprovalGroup, isString, LinkButton, User, useToast } from 'common';

import {
  getGroupPath,
  useGetGroupUsers,
  useRemoveUsersFromGroup,
  useUpdateGroupUsers,
} from 'app/src/services/groups';

import { GroupOption, searchFilterOption } from '../rules/_approvalRulesConfig';

import styles from './ApprovalGroupRow.module.scss';

interface Props {
  approvalGroup: ApprovalGroup;
  onEdit?: () => void;
  usersListOptions: SelectProps<string, GroupOption>['options'];
}

export const ApprovalGroupRow = ({
  approvalGroup,
  usersListOptions,
  onEdit,
}: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: usersInGroupList } = useGetGroupUsers(approvalGroup.group?.id);

  const [usersInGroupValue, setUsersInGroupValue] = useState<User[]>([]);
  const [nameValue, setNameValue] = useState(approvalGroup.group?.name);

  const SPECIFIC_GROUP_PATH = getGroupPath(approvalGroup.group?.id!);

  const updateGroupUsers = useUpdateGroupUsers(
    () => {},
    () => {},
    queryClient,
    [SPECIFIC_GROUP_PATH, 'approvalGroupsWithUsers']
  );

  const removeGroupUser = useRemoveUsersFromGroup(
    () => {},
    () => {},
    queryClient,
    SPECIFIC_GROUP_PATH
  );

  useEffect(
    () => setUsersInGroupValue(usersInGroupList || []),
    [usersInGroupList]
  );

  useEffect(() => {
    setNameValue(approvalGroup.group?.name);
  }, [approvalGroup.group?.name]);

  const handleUserChange = (currentSelectedUserIds: string | string[]) => {
    if (currentSelectedUserIds.length === 0) {
      toast.error(
        'Cannot remove last user. Add a different user if you want to remove this one.'
      );
      return;
    }

    // Determine if an item was added or removed based on the length of arrays
    if (currentSelectedUserIds.length > usersInGroupValue.length) {
      // Adding user
      if (Array.isArray(currentSelectedUserIds)) {
        addUserToGroup(currentSelectedUserIds);
      }
    } else if (currentSelectedUserIds.length < usersInGroupValue.length) {
      // Removing user
      const removedUserId = usersInGroupValue.find(
        (user) => !currentSelectedUserIds.includes(user.id!)
      );

      if (removedUserId) {
        removeUserFromGroup(removedUserId.id!);
      }
    }
  };

  const addUserToGroup = (values: string[]) => {
    const currentUsersInGroup = [...usersInGroupValue];

    updateGroupUsers.mutate(
      {
        groupId: approvalGroup.group?.id!,
        userIds: values,
      },
      {
        onError: () => {
          setUsersInGroupValue(currentUsersInGroup);
        },
      }
    );
  };

  const removeUserFromGroup = (userId: string) => {
    const currentUsersInGroup = [...usersInGroupValue];

    removeGroupUser.mutate(
      {
        groupId: approvalGroup.group?.id!,
        userIds: [userId],
      },
      {
        onError: () => {
          setUsersInGroupValue(currentUsersInGroup);
        },
      }
    );
  };

  return (
    <div className={styles.group}>
      <div className={styles.groupName}>
        <LinkButton
          className={styles.editButton}
          onClick={() => {
            onEdit?.();
          }}
          dataTestId="approval-group-name-link"
        >
          {nameValue}
        </LinkButton>
      </div>

      <Select
        onChange={handleUserChange}
        value={usersInGroupValue.map((user: User) => user.id).filter(isString)}
        options={usersListOptions}
        mode="multiple"
        filterOption={searchFilterOption}
        className={styles.groupWidth}
        placeholder="Select approval user"
        data-testid="approval-group-users"
      />
    </div>
  );
};
