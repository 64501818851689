import { QueryClient, useMutation } from '@tanstack/react-query';
import { apiClient } from './httpClients/app';

const STATE_TOKEN_KEY = 'stateToken';

export const useCreateStateToken = (
  onSuccess: (data: string) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [STATE_TOKEN_KEY],
    mutationFn: async () => {
      const { data } = await apiClient.createStateToken();
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await Promise.all([
        qc.invalidateQueries({ queryKey: [STATE_TOKEN_KEY] }),
      ]);
    },
  });
