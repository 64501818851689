import React from 'react';
import { useBillingScheduleUsage } from '../../services/billing';
import {
  BillingSchedule,
  ErrorFetching,
  formatDatetimeOrDefault,
  isArrayNonEmpty,
  Loading,
  Product,
  useTranslation,
} from 'common';

interface Props {
  billingSchedule: BillingSchedule;
  products: Product[];
}

const BillingScheduleUpdateLogs: React.FC<Props> = ({
  billingSchedule,
  products,
}: Props) => {
  const { tk } = useTranslation();
  const {
    data: updates,
    isLoading: areUpdatesLoading,
    isError: isLoadingUpdatesError,
  } = useBillingScheduleUsage(billingSchedule.id);

  if (areUpdatesLoading) {
    return <Loading />;
  }

  if (isLoadingUpdatesError) {
    return <ErrorFetching />;
  }

  if (!updates) {
    return null;
  }

  const fieldOrder: string[] = [];

  const productsMap: Map<string, Product> = new Map<string, Product>();
  products.forEach((p) => productsMap.set(p.id, p));

  const productNameMap = new Map<string, string>();
  billingSchedule.billingItems.forEach((item) => {
    fieldOrder.push(item.id);
    productNameMap.set(item.id, productsMap.get(item.product.id!)!.name!);
  });

  const updatesMap = new Map<
    string,
    { billingItemId: string; enteredAt: string; quantity: number }[]
  >();

  updates.forEach((update) => {
    const usageSubmission = {
      billingItemId: update.billingItemId!,
      quantity: update.quantity!,
      enteredAt: `${formatDatetimeOrDefault(update.createdAt)}, ${
        update.createdBy
      }`,
    };

    const key = `${update.createdAt}${update.createdBy}`;
    const previousField = updatesMap.get(key);
    const newField = previousField
      ? [...previousField, usageSubmission]
      : [usageSubmission];

    updatesMap.set(key, newField);
  });

  return (
    <div className="bg-slate-lightest border border-gray-lines border-x-0 border-b-0 px-6 py-4 text-xs text-slate-darkest">
      <div>{tk('Usage update history:')}</div>
      {updatesMap.size === 0 ? (
        <div>{tk('This is the first update')}</div>
      ) : (
        <div className="flex flex-col gap-4 pt-4">
          {Array.from(updatesMap).map(([updatedAtString, items]) => {
            if (!isArrayNonEmpty(items)) {
              return null;
            }

            return (
              <div key={updatedAtString}>
                {items[0].enteredAt}
                <div>
                  {items
                    .sort((a, b) =>
                      fieldOrder.indexOf(a.billingItemId) <
                      fieldOrder.indexOf(b.billingItemId)
                        ? -1
                        : 1
                    )
                    .map((thing) => (
                      <div key={thing.billingItemId}>
                        {productNameMap.get(thing.billingItemId)}:{' '}
                        {thing.quantity}
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BillingScheduleUpdateLogs;
