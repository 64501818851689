import React from 'react';
import { InternalAppLink, useTranslation } from 'common';
import { SETTINGS_STRIPE } from 'app/src/core-utils/routes';
import { MethodProps } from './utilities';

const CreditCard = ({ paymentMethod }: MethodProps) => {
  const { tk } = useTranslation();
  const stripeLink = (
    <InternalAppLink
      route={SETTINGS_STRIPE}
      breadcrumbs={[tk('Integrations and Connectors'), tk('Stripe')]}
    />
  );

  return (
    <div className="w-full flex justify-center">
      <div>
        {paymentMethod.configured ? (
          <p>Configured with Stripe in {stripeLink}</p>
        ) : (
          <p>
            To enable credit cards, you must first connect to Stripe in{' '}
            {stripeLink}.
          </p>
        )}
      </div>
    </div>
  );
};

export default CreditCard;
