import React, { useEffect, useState } from 'react';
import ProposalCard from '../ProposalCard/ProposalCard';
import clsx from 'clsx';
import { Icon, IconButton, ProposalLabel } from 'common';
import styles from './ProposalSection.module.scss';

interface Props {
  children?: React.ReactNode;
  dataTestId?: string;
  headerActions?: React.ReactNode;
  isDisabled: boolean;
  isRemovable?: boolean;
  noPadding?: boolean;
  onOpen?: () => void;
  onRemove?: () => void;
  openOnLoad?: boolean;
  section: string;
  sectionRef?: string;
  title: React.ReactNode;
  titleActions?: React.ReactNode;
}

const ProposalSection: React.FC<Props> = ({
  children,
  dataTestId,
  headerActions,
  isDisabled,
  isRemovable = true,
  noPadding,
  onOpen,
  onRemove,
  openOnLoad = false,
  section,
  sectionRef,
  title,
  titleActions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(openOnLoad);
  }, [openOnLoad]);

  const open = () => {
    if (!isDisabled) {
      onOpen?.();
      setIsOpen(true);
    }
  };

  const close = () => {
    onRemove?.();
    setIsOpen(false);
  };

  if (isOpen || !isRemovable) {
    return (
      <ProposalCard
        className={styles.proposalSection}
        isDisabled={isDisabled}
        noPadding={noPadding}
        sectionRef={sectionRef}
      >
        <div className={styles.cardHeader}>
          <div className={styles.left}>
            <ProposalLabel
              className={noPadding ? styles.proposalLabelPadding : undefined}
              id={sectionRef}
              name={title}
            />
            {titleActions}
          </div>
          <div className={styles.right}>
            {headerActions}
            {!isDisabled && isRemovable && (
              <div className={styles.removeIcon}>
                <IconButton
                  dataTestId={sectionRef}
                  icon={Icon.Trash}
                  isDangerous
                  label="Remove"
                  onClick={close}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.children}>{children}</div>
      </ProposalCard>
    );
  }

  return (
    <div
      aria-hidden="true"
      className={clsx(
        styles.proposalSectionPlaceholder,
        !isDisabled && styles.active
      )}
      data-testid={dataTestId}
      onClick={open}
    >
      <span>{section}</span>
    </div>
  );
};

export default ProposalSection;
