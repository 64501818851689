import React from 'react';

interface Props {
  className?: string;
  name: string;
}

const SandboxLabel: React.FC<Props> = ({ name, className }) => (
  <div
    className={`font-medium text-center text-sm leading-6 uppercase tracking-wider my-[6px] py-[3px] text-orange-darker bg-orange-lightest ${
      className || ''
    }`}
  >
    {name}
  </div>
);

export default SandboxLabel;
