import React from 'react';
import CacheflowLogo from '../../assets/CacheflowLogo';
import LoginLayout from './LoginLayout';
import OrgSelectionRow from './OrgSelectionRow';
import { useGetMyOrgs } from '../../services/api/profile/user';
import { Button, log } from 'common';
import { LOGGED_OUT_STATE, useAuthState } from '../../Auth';
import { logoutGet } from '../../services/api/profile/logout';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../core-utils/routes';
import SmallPrint from './SmallPrint';

interface Props {}

const OrgSelectionPage: React.FC<Props> = () => {
  const { data: orgs } = useGetMyOrgs();
  const { refetch } = logoutGet();
  const { setAuth } = useAuthState();
  const navigate = useNavigate();

  const logout = () => {
    refetch()
      .then(() => {
        log.debug('logged out success');
      })
      .catch((err) => {
        log.error(err);
      })
      .finally(() => {
        setAuth({
          ...LOGGED_OUT_STATE,
          searchSuffix: undefined,
        });
        navigate(LOGIN_ROUTE);
      });
  };

  return (
    <LoginLayout>
      <div className="content-center pb-6 mt-4">
        <CacheflowLogo width={200} />
      </div>
      <div className="flex flex-col justify-center item-center place-content-center">
        <div className="text-center text-md font-semibold">
          Choose an account to continue
        </div>
        <div className="w-full p-4 flex flex-col">
          {orgs?.map((o) => <OrgSelectionRow key={o.id} organization={o} />)}
        </div>

        <div className="flex w-full justify-center item-center">
          <Button
            onClick={logout}
            label="Logout"
            dataTestId="logout-selection"
            type="link"
          />
        </div>
      </div>

      <div className="items-center mt-4 ">
        <SmallPrint />
      </div>
    </LoginLayout>
  );
};

export default OrgSelectionPage;
