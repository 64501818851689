import React from 'react';
import {
  BillingPeriodsType,
  ErrorFetching,
  ItemDeltas,
  ProductGridSkeletonLoader,
  Proposal,
} from 'common';
import { useGetProposalDeltas } from '../../../../services/proposal';

interface Props {
  billingPeriod: BillingPeriodsType;
  proposal: Proposal;
}

const ProductDeltas: React.FC<Props> = ({ billingPeriod, proposal }) => {
  const {
    data: deltas,
    isError,
    isLoading,
  } = useGetProposalDeltas(proposal.id);

  if (isLoading) {
    const numRows = Math.max(proposal.changes?.itemChanges?.length ?? 5, 0);
    return <ProductGridSkeletonLoader numRows={numRows} />;
  }

  if (isError) {
    return <ErrorFetching />;
  }

  return (
    <ItemDeltas
      billingPeriod={billingPeriod}
      deltas={deltas}
      proposal={proposal}
    />
  );
};

export default ProductDeltas;
