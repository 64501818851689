import React from 'react';
import {
  isEmpty,
  ProductSummary,
  Proposal,
  Select,
  useFlags,
  useTranslation,
} from 'common';
import { checkIfIsValidProductForProposal } from '../../../../core-utils/helperFunctions/productUtils';
import ProductSelect from '../../../Sales/Products/ProductsList/ProductSelect';
import { useEligibleProducts } from '../../../../services/api/products/products';
import { getUnselectedProducts } from '../proposalUtilities';

interface Props {
  className?: string;
  isDisabled: boolean;
  onAddProduct: (product: ProductSummary) => void;
  proposal: Proposal;
}

const ProposalProductSelect: React.FC<Props> = ({
  className,
  isDisabled,
  onAddProduct,
  proposal,
}) => {
  const { data: eligibleProducts, isPending, isError } = useEligibleProducts();
  const { tk } = useTranslation();
  const { proposalProductMultiples } = useFlags();

  if (isDisabled) {
    return null;
  }

  const products = proposal.proposalItems.map((item) => item.product);

  if (isError || (!isPending && !eligibleProducts.length)) {
    return (
      <Select
        className={className}
        isDisabled
        options={[]}
        placeholder={tk('Could not load products.')}
        status="error"
      />
    );
  }

  const filteredProducts: ProductSummary[] = proposalProductMultiples
    ? (eligibleProducts ?? [])
    : getUnselectedProducts(eligibleProducts, products);

  const [unselectedValidProducts, unselectedInvalidProducts] =
    filteredProducts.reduce<[ProductSummary[], ProductSummary[]]>(
      (acc, product) => {
        const isValid = checkIfIsValidProductForProposal(product, proposal);

        acc[isValid ? 0 : 1].push(product);
        return acc;
      },
      [[], []]
    );

  if (isEmpty(filteredProducts)) {
    if (isEmpty(proposal.proposalItems)) {
      return (
        <Select
          className={className}
          isDisabled
          options={[]}
          placeholder={tk('There are no active products.')}
        />
      );
    }

    return null;
  }

  return (
    <ProductSelect
      className={className}
      dataTestId="proposalEditorProductSearch"
      isDisabled={false}
      isLoading={isPending}
      isOptionDisabled={(option) =>
        !checkIfIsValidProductForProposal(option, proposal)
      }
      onSelect={onAddProduct}
      products={[...unselectedValidProducts, ...unselectedInvalidProducts]}
      proposal={proposal}
    />
  );
};

export default ProposalProductSelect;
