import React from 'react';
import { Card } from 'common';
import clsx from 'clsx';
import styles from './BankAccountsCard.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const BankAccountsCard: React.FC<Props> = ({ children, className }) => {
  return (
    <Card className={clsx(styles.bankAccountCard, className)}>{children}</Card>
  );
};

export default BankAccountsCard;
