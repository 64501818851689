import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../../../Auth';
import {
  INTEGRATIONS_AND_CONNECTORS,
  LOGIN_ROUTE,
} from '../../../core-utils/routes';

const HubspotSignup: React.FC = () => {
  const auth = useAuth();

  if (auth.isAuthenticated) {
    return <Navigate replace to={INTEGRATIONS_AND_CONNECTORS} />;
  }

  return (
    <div className="text-center p-48">
      <p>
        You must have an active Cacheflow account to use Cacheflow’s HubSpot
        Connector.
      </p>
      <p>
        Contact us{' '}
        <Link
          to="https://www.getcacheflow.com/demo"
          className="hover:underline text-blue"
        >
          here
        </Link>{' '}
        for more information. Or if you already have an account, log in{' '}
        <Link to={LOGIN_ROUTE} className="hover:underline text-blue">
          here
        </Link>
        .
      </p>
    </div>
  );
};

export default HubspotSignup;
