import { Contact, formatDateSortable, isObject, User } from 'common';
import { EventDisplayProps } from 'app/src/components/events/EventDisplay';

const SYSTEM_USER = 'system@getcacheflow.com';

export const isEventSystemUser = (
  person: User | Contact | string | undefined
): boolean => {
  return isObject(person) && person.createdBy === SYSTEM_USER;
};

// This is built for events, but could be slightly modified to cluster any object pretty easily
export const clusterByDate = <T extends { createdAt?: string }>(
  events: T[]
): Array<{
  date: string;
  events: T[];
}> => {
  const eventsClusteredByDate: {
    date: string;
    events: T[];
  }[] = [];

  events.reverse().forEach((event) => {
    const { createdAt } = event;

    if (!createdAt) {
      return;
    }

    const date = formatDateSortable(createdAt);
    const mostRecentCluster = eventsClusteredByDate[
      eventsClusteredByDate.length - 1
    ] as (typeof eventsClusteredByDate)[number] | undefined;

    if (mostRecentCluster && mostRecentCluster.date === date) {
      mostRecentCluster.events.push(event);
    } else {
      eventsClusteredByDate.push({ date, events: [event] });
    }
  });

  return eventsClusteredByDate;
};

// Converts an array of events like ProposalEvent or ContractEvent to props for the EventDisplay
const eventsToEventProps = <T>(
  events: T[],
  converter: (currentValue: T, previousEvent?: T) => EventDisplayProps | null
): EventDisplayProps[] =>
  events.reduce<EventDisplayProps[]>((arr, event) => {
    const idx = events.indexOf(event);
    const previousEvent = idx < events.length - 1 ? events[idx + 1] : undefined;
    const props = converter(event, previousEvent);
    if (props === null) {
      return arr;
    }

    return [...arr, props];
  }, []);

type EventClusters<T> = Array<{ date: string; events: T[] }>;
type EventPropClusters = Array<{
  date: string;
  propObjects: EventDisplayProps[];
}>;

// Converts an array of clusters of events to clusters of props for the EventDisplay
// including removal of empty event clusters
export const getEventsPropsClusters = <T>(
  clusters: EventClusters<T>,
  converter: (currentValue: T, previousEvent?: T) => EventDisplayProps | null
): Array<{ date: string; propObjects: EventDisplayProps[] }> =>
  clusters.reduce<EventPropClusters>((arr, cluster) => {
    const propObjects: EventDisplayProps[] = eventsToEventProps(
      cluster.events,
      converter
    );

    if (propObjects.length < 1) {
      return arr;
    }

    return [...arr, { propObjects, date: cluster.date }];
  }, []);

export const sortByDateAsc = <T>(items: T[]): T[] =>
  items.sort((a, b) => {
    if (!isObject(a) || !isObject(b) || !a.createdAt || !b.createdAt) {
      return 0;
    }
    return a.createdAt < b.createdAt ? -1 : 1;
  });
