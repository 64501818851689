import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Unit } from 'common';
import { apiClient } from '../../httpClients/app';

const ALL_UNITS_KEY = 'unit-list';

export const useUnits = (): UseQueryResult<Unit[]> =>
  useQuery({
    queryKey: [ALL_UNITS_KEY],
    queryFn: async () => {
      const { data } = await apiClient.listUnits();
      return data;
    },
  });
