import {
  ENVIRONMENT_CONFIG,
  LOCAL_ENVIRONMENT_CONFIG,
} from '../../config/hosts';
import qs from 'qs';

const { checkoutHost, shouldUseHttps, shouldUseCheckoutHttps } =
  ENVIRONMENT_CONFIG;

export const getUrl = (host: string, path?: string): string => {
  const protocol = ENVIRONMENT_CONFIG.shouldUseHttps ? 'https' : 'http';
  const endpoint = path || '';
  return `${protocol}://${host}${endpoint}`;
};

export const getApiUrl = (path?: string) =>
  getUrl(ENVIRONMENT_CONFIG.cfAppHost, path);

export const getOrgApiBase = (orgDomain: string) => {
  const protocol = ENVIRONMENT_CONFIG.shouldUseHttps ? 'https' : 'http';
  return `${protocol}://${orgDomain}.${ENVIRONMENT_CONFIG.cfAppHost}`;
};

const isLocalApp = (): boolean =>
  // When developing locally, we are on the 'development' environment,
  // which means that this would modify the app.dev share links to use localhost,
  // which we only want when actually running locally
  !!LOCAL_ENVIRONMENT_CONFIG &&
  window.location.href.indexOf(LOCAL_ENVIRONMENT_CONFIG.cfBaseAppHost) !== -1;

const getBaseHostName = () => {
  let hostname = ENVIRONMENT_CONFIG.cfBaseAppHost;
  if (isLocalApp() && LOCAL_ENVIRONMENT_CONFIG) {
    hostname = LOCAL_ENVIRONMENT_CONFIG.cfBaseAppHost;
  }
  return hostname;
};

const getSsrHostName = () => {
  let hostname = ENVIRONMENT_CONFIG.cfSsrHost;
  if (isLocalApp() && LOCAL_ENVIRONMENT_CONFIG) {
    hostname = LOCAL_ENVIRONMENT_CONFIG.cfSsrHost;
  }
  return hostname;
};

export const getSsrHostUrl = () =>
  `${sslOn() ? 'https' : 'http'}://${getSsrHostName()}`;

const sslOn = () => !isLocalApp() && shouldUseHttps;

export const getBaseHostUrl = () =>
  `${sslOn() ? 'https' : 'http'}://${getBaseHostName()}`;

export const getCheckoutBase = (orgName: string) => {
  if (LOCAL_ENVIRONMENT_CONFIG && isLocalApp()) {
    const { checkoutHost: localHost, shouldUseCheckoutHttps: localHttps } =
      LOCAL_ENVIRONMENT_CONFIG;

    return `${localHttps ? 'https' : 'http'}://${orgName}.${localHost}`;
  }

  return `${
    shouldUseCheckoutHttps ? 'https' : 'http'
  }://${orgName}.${checkoutHost}`;
};

export const getOrgAppBase = (orgName: string) =>
  `${sslOn() ? 'https' : 'http'}://${orgName}.${getBaseHostName()}`;

export const getCheckoutUrl = (
  proposalId: string,
  contactEmail: string,
  orgName: string
) =>
  `${getCheckoutBase(orgName)}/p/${proposalId}?${qs.stringify({
    email: contactEmail,
  })}`;

export const getCheckoutTemplateUrl = (proposalId: string, orgName: string) =>
  `${getCheckoutBase(orgName)}/t/${proposalId}`;

export const getProposalDialogPreviewUrl = (
  id: string,
  orgName: string,
  token: string,
  isPdf?: boolean
) =>
  `${getCheckoutBase(orgName)}/p/${id}?${qs.stringify({
    token,
    printPdf: isPdf,
  })}`;
