import React from 'react';
import { User } from 'common';
import handleAccountProfileName from '../core-utils/helperFunctions/profileName';
import clsx from 'clsx';

interface Props {
  disabled?: boolean;
  user: User;
}

const UserProfileIcon: React.FC<Props> = ({ user, disabled = false }) => {
  const { firstChar, lastNameFirstChar } = handleAccountProfileName(
    user.firstName,
    user.lastName
  );

  return (
    <div
      className={clsx(
        'grid w-8 h-8 mx-2 text-white rounded-full flex-0 bg-black place-content-center',
        { 'opacity-20': disabled }
      )}
    >
      {firstChar}
      {lastNameFirstChar}
    </div>
  );
};

export default UserProfileIcon;
