import { Button, Drawer, FormField, TextArea } from 'common';
import React from 'react';
import { EnhanceOption } from './EnhanceOption';

const enhanceOptionsConfig = [
  {
    title: 'Friendliness',
    options: ['Less friendly', 'Keep as is', 'More friendly'],
    key: 'Friendliness',
  },
  {
    title: 'Length',
    options: ['Shorter', 'Keep as is', 'Longer'],
    key: 'Length',
  },
  {
    title: 'Prose format',
    options: ['Continuous', 'Keep as is', 'Structured'],
    key: 'Prose format',
  },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  enhanceCoverLetter: (prompt: string) => void;
}

export const EnhanceDrawer = ({
  isOpen,
  onClose,
  enhanceCoverLetter,
}: Props) => {
  const [draft, setDraft] = React.useState('');
  const [enhancedPrompts, setEnhancedPrompts] = React.useState<
    Map<string, string>
  >(new Map());

  const enhance = () => {
    // add all enhanced prompts to the draft
    let enhancedDraft = draft;
    enhancedPrompts.forEach((value) => {
      enhancedDraft += value;
    });
    enhanceCoverLetter(enhancedDraft);
    onClose();
  };
  return (
    <Drawer
      footer={<Button block label="Enhance" onClick={enhance} />}
      header="Enhance cover letter"
      isOpen={isOpen}
      onClose={onClose}
    >
      {enhanceOptionsConfig.map((optionConfig) => (
        <EnhanceOption
          key={optionConfig.key}
          options={optionConfig.options}
          setEnhancedPrompt={(prompt) => {
            enhancedPrompts.set(optionConfig.key, prompt);
            setEnhancedPrompts(new Map(enhancedPrompts));
          }}
          title={optionConfig.title}
        />
      ))}
      <FormField label="Prompt">
        <TextArea
          autoSize={{ minRows: 3 }}
          id="coverletter-prompt"
          onChange={(value) => setDraft(value)}
          value={draft}
        />
      </FormField>
    </Drawer>
  );
};
