import { useQueryClient } from '@tanstack/react-query';

import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { useUpdateCoverLetter } from 'app/src/services/proposal';
import {
  CoverLetterRequest,
  getErrorMessage,
  LinkButton,
  LoadingSpinner,
  Proposal,
  SparkleIcon,
  useFlags,
  useToast,
} from 'common';
import React, { useEffect, useState } from 'react';
import { RichTextEditor } from '../../../../components';
import ProposalSection from '../ProposalSection/ProposalSection';
import styles from './CoverLetter.module.scss';
import { CoverLetterPrompt } from './CoverLetterPrompt';
import { EnhanceDrawer } from './EnhanceDrawer';

interface Props {
  isDisabled: boolean;
  proposal: Proposal;
}

const CoverLetter: React.FC<Props> = ({ isDisabled, proposal }) => {
  const showToast = useToast();
  const [coverLetterDraft, setCoverLetterDraft] = useState<string | undefined>(
    proposal.coverLetter
  );
  const [coverLetterText, setCoverLetterText] = useState<string>('');
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const [isEnhanceDrawerOpen, setIsEnhanceDrawerOpen] =
    useState<boolean>(false);
  const [isCoverLetterPromptDrawerOpen, setIsCoverLetterPromptDrawerOpen] =
    useState<boolean>(false);

  const qc = useQueryClient();
  const { cgptCoverletter, aiGeneration } = useFlags();

  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');
  const { isPending, mutate: updateCoverLetter } = useUpdateCoverLetter(
    proposal.id,
    (proposalSuccess: Proposal) => {
      setCoverLetterText('');
      setCoverLetterDraft(proposalSuccess.coverLetter);
    },
    (error) => {
      const message = getErrorMessage(error);
      showToast.error(
        'Proposal cover letter update failed' + (message ? `: ${message}` : '')
      );
    },
    qc
  );

  useEffect(() => {
    if (
      !isRendering &&
      coverLetterDraft !== undefined &&
      coverLetterDraft.length > 0
    ) {
      const renderCoverLetter = async () => {
        setIsRendering(true);
        setCoverLetterText('');

        const words = coverLetterDraft.split(' ');
        for (let i = 0; i < words.length; i++) {
          setCoverLetterText(
            (prev) => prev + words[i] + (i < words.length - 1 ? ' ' : '')
          );
          await new Promise<void>((resolve) => {
            setTimeout(resolve, 15);
          });
        }

        setIsRendering(false);
      };
      renderCoverLetter().catch(() => {
        setCoverLetterText(coverLetterDraft);
        setIsRendering(false);
      });
    }
  }, [coverLetterDraft]);

  const generateCoverLetter = () => {
    updateCoverLetter({
      action: 'generate',
    } as CoverLetterRequest);
  };

  const enhanceCoverLetter = (prompt: string) => {
    updateCoverLetter({
      action: 'enhance',
      additionalPrompt: prompt,
    } as CoverLetterRequest);
  };

  const onTextChange = (data: string | undefined) => {
    updateCoverLetter({
      action: 'save',
      coverLetter: data,
    } as CoverLetterRequest);
  };

  const renderActions = () => {
    if (!aiGeneration || !canUpdate || isDisabled) {
      return null;
    }

    if (isPending) {
      return <LoadingSpinner size={32} />;
    }
    return (
      <div className={styles.actions}>
        <LinkButton
          className={styles.action}
          onClick={() => setIsCoverLetterPromptDrawerOpen(true)}
        >
          <SparkleIcon width={26} />
        </LinkButton>
        <LinkButton className={styles.action} onClick={generateCoverLetter}>
          Generate
        </LinkButton>
        <LinkButton
          className={styles.action}
          onClick={() => setIsEnhanceDrawerOpen(true)}
        >
          Enhance
        </LinkButton>
      </div>
    );
  };

  if (!cgptCoverletter) {
    return null;
  }

  return (
    <ProposalSection
      section="Add cover letter"
      sectionRef="CoverLetter"
      openOnLoad={!!coverLetterDraft}
      headerActions={renderActions()}
      title="Cover letter"
      onRemove={() => onTextChange(undefined)}
      isDisabled={isDisabled}
    >
      <RichTextEditor
        dataTestId="cover-letter"
        featureSet="proposal.coverLetter"
        fireOnBlur
        fireOnDebounce
        value={coverLetterText}
        isDisabled={isDisabled || isPending || isRendering}
        onChange={onTextChange}
        placeholder="Add cover letter"
      />
      <EnhanceDrawer
        isOpen={isEnhanceDrawerOpen}
        onClose={() => setIsEnhanceDrawerOpen(false)}
        enhanceCoverLetter={enhanceCoverLetter}
      />
      <CoverLetterPrompt
        isOpen={isCoverLetterPromptDrawerOpen}
        onClose={() => setIsCoverLetterPromptDrawerOpen(false)}
        generate={generateCoverLetter}
      />
    </ProposalSection>
  );
};

export default CoverLetter;
