import React from 'react';
import { LoadingSpinner } from 'common';
import { useGetMyOrgs } from '../../services/api/profile/user';
import { ORG_SELECTION_ROUTE } from '../../core-utils/routes';
import { useAuthState } from '../../Auth';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Props {
  dealRoute: string;
  dealQueryParamName: string;
}

function ExternalDealsCallback({ dealRoute, dealQueryParamName }: Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dealIdParam = searchParams.get(dealQueryParamName);
  const templateIdParam = searchParams.get('templateId');
  const { auth, setAuth } = useAuthState();
  const { data: orgs, isLoading } = useGetMyOrgs();

  if (isLoading) {
    return <LoadingSpinner size={24} />;
  }

  if (orgs && orgs.length > 1) {
    if (auth.identityToken) {
      setAuth({
        ...auth,
        prevPath: dealRoute,
        searchSuffix: `${dealQueryParamName}=${dealIdParam || ''}&templateId=${templateIdParam || ''}`,
      });
      navigate(ORG_SELECTION_ROUTE, { replace: true });
    }
  } else {
    navigate(
      `${dealRoute}?${dealQueryParamName}=${dealIdParam || ''}&templateId=${templateIdParam || ''}`
    );
  }
  return <LoadingSpinner size={24} />;
}

export default ExternalDealsCallback;
