import 'regenerator-runtime/runtime';
import React from 'react';
import './index.css';
import 'common/style.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeConfig, ToastWrapper } from 'common';
import { AnalyticsWrapper } from './AnalyticsWrapper';
import ErrorBoundary from './wrappers/ErrorBoundary';
import RenderRoutes from './services/routes/RenderRoutes';
import ROUTES from './services/routes/Routes';
import HelpChat from './pages/System/HelpChat/HelpChat';
import VersionCheck from './pages/System/VersionCheck/VersionCheck';
import { AuthWrapper } from './Auth';
import { ENVIRONMENT_CONFIG } from '../config/hosts';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const App: React.FC = () => (
  <React.StrictMode>
    <AnalyticsWrapper>
      <BrowserRouter>
        <ErrorBoundary>
          <AuthWrapper>
            <ErrorBoundary>
              <ThemeConfig isProduction={ENVIRONMENT_CONFIG.isProduction}>
                <ToastWrapper>
                  <HelpChat>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <RenderRoutes routes={ROUTES} />
                  </HelpChat>
                </ToastWrapper>
                <VersionCheck />
              </ThemeConfig>
            </ErrorBoundary>
          </AuthWrapper>
        </ErrorBoundary>
      </BrowserRouter>
    </AnalyticsWrapper>
  </React.StrictMode>
);

export default App;
