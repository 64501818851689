import React from 'react';
import { ErrorFetching, SearchSelect, TaxCodeRest } from 'common';
import { useGetAllTaxCodes } from 'app/src/services/taxes';

interface Props {
  dataTestId?: string;
  onClearSelected: () => void;
  onSelect: (taxCode: TaxCodeRest) => void;
  selected?: string;
}

const TaxCodeSelect: React.FC<Props> = ({
  onSelect,
  onClearSelected,
  selected,
  dataTestId,
}) => {
  const { data: taxCodes, isLoading, isError } = useGetAllTaxCodes();

  if (isError) {
    return <ErrorFetching />;
  }

  const selectedCode = taxCodes?.find((taxCode) => taxCode.code === selected);

  return (
    <SearchSelect<TaxCodeRest>
      options={taxCodes || []}
      displayField="description"
      isLoading={isLoading}
      value={selectedCode}
      onChange={onSelect}
      onClearSelected={onClearSelected}
      dataTestId={dataTestId}
    />
  );
};

export default TaxCodeSelect;
