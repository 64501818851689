import React from 'react';
import { ErrorCell, SyncEvent } from 'common';
import StatusLabel from 'app/src/components/StatusLabel';

interface Props {
  eventDetail?: SyncEvent;
  feature: string;
}

const SyncStatusLabel: React.FC<Props> = ({ eventDetail, feature }) => {
  if (eventDetail?.status === 'success') {
    return <StatusLabel status="success" feature={feature} />;
  } else if (eventDetail?.status === 'error') {
    return (
      <ErrorCell
        error={eventDetail.error}
        statusLabel={<StatusLabel status="error" feature={feature} />}
      />
    );
  }
  return 'Not synced';
};

export default SyncStatusLabel;
