import React from 'react';
import { Item, ItemSchedule } from 'common';
import {
  CustomizePriceAction,
  DeleteItemScheduleAction,
} from './ItemScheduleActions';

export const ItemScheduleActionsMenu = ({
  onShowPriceModalClick,
}: {
  onShowPriceModalClick: (selectedSchedule: ItemSchedule, item: Item) => void;
}) => {
  return (
    <>
      <CustomizePriceAction onShowPriceModalClick={onShowPriceModalClick} />
      <DeleteItemScheduleAction />
    </>
  );
};
