import { Radio } from 'antd';
import { FormField } from 'common';
import React from 'react';
import styles from './CoverLetter.module.scss';

interface Props {
  title: string;
  options: string[];
  setEnhancedPrompt: (prompt: string) => void;
}

export const EnhanceOption = ({ title, options, setEnhancedPrompt }: Props) => {
  const [value, setValue] = React.useState(options[1]);

  const enhancePrompt = (optionValue: string) => {
    const prompt =
      optionValue === 'Keep as is'
        ? ''
        : 'The response ' + title + ' should be more ' + optionValue + '.';
    setEnhancedPrompt(prompt);
    setValue(optionValue);
  };

  return (
    <FormField label={title}>
      <Radio.Group
        value={value}
        onChange={(e) => enhancePrompt(e.target.value)}
        optionType="button"
        buttonStyle="solid"
      >
        {options.map((option) => (
          <Radio className={styles.equalSizeRadio} key={option} value={option}>
            {option}
          </Radio>
        ))}
      </Radio.Group>
    </FormField>
  );
};
