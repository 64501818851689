import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { ErrorFetching, Loading, NavigationIcon } from 'common';

import { Page } from 'app/src/components';
import { REFUNDS } from 'app/src/core-utils/routes';
import { useGetRefund } from 'app/src/services/api/refunds/refunds';
import { useInvoice } from 'app/src/services/api/invoices/invoiceList';
import { useCompanyInfo } from 'app/src/services/api/branding/companyInfo';
import { ActivityDrawer } from 'app/src/components/ActivityDrawer/ActivityDrawer';
import { useActivityDrawer } from 'app/src/components/ActivityDrawer/useActivityDrawer';

import { RefundSummaryCards } from '../components/RefundSummaryCards';
import { RefundActionsHeader } from '../components/RefundActionsHeader';
import { PaymentTransactionsTable } from '../../../components/PaymentTransactionsTable/PaymentTransactionsTable';

import styles from './RefundDetailsPage.module.scss';

export const RefundDetailsPage = () => {
  const { id: refundId } = useParams<{ id: string }>();

  const { data: companyInfo, isLoading: isCompanyInfoLoading } =
    useCompanyInfo();

  const { data: refundInfo, isLoading: isRefundInfoLoading } = useGetRefund(
    refundId ?? ''
  );

  const { data: invoice, isLoading: isInvoiceLoading } = useInvoice(
    refundInfo?.transfer.parentReferenceId ?? ''
  );

  const {
    activity,
    isActivityDrawerOpen,
    openActivityDrawer,
    closeActivityDrawer,
  } = useActivityDrawer('refund', refundInfo?.id ?? '');

  if (isCompanyInfoLoading || isRefundInfoLoading || isInvoiceLoading) {
    return <Loading />;
  }

  if (!companyInfo || !refundInfo || !invoice) {
    return <ErrorFetching />;
  }

  return (
    <Page
      leftWidget={
        <div className={styles.headerContainer}>
          <Link to={REFUNDS}>
            <NavigationIcon.Refund />
          </Link>
          <div className={styles.rightHeader}>
            <div>{refundInfo.customer?.name}</div>
            <div
              className={styles.subtext}
            >{`Refund Id: ${refundInfo.id.substring(0, 8)}`}</div>
          </div>
        </div>
      }
      rightWidget={
        <RefundActionsHeader
          openActivityDrawer={openActivityDrawer}
          refund={refundInfo}
        />
      }
    >
      <RefundSummaryCards refund={refundInfo} />

      <div className={styles.transactionsTable}>
        <PaymentTransactionsTable schedule={invoice.schedule} />
      </div>

      <ActivityDrawer
        activityEvents={activity}
        isOpen={isActivityDrawerOpen}
        onClose={closeActivityDrawer}
        resourceType="refund"
      />
    </Page>
  );
};
