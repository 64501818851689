import {
  BillingPeriodsType,
  Contract,
  getDecimalScaleForCurrency,
  hasEntry,
  isMatchingEntryRequest,
  isString,
  Money,
  Nullable,
  PricebookEntryRequest,
  Product,
  ProductRequest,
  ProductSummary,
} from 'common';

const convertMoneyScale = (
  money: Money | null | undefined,
  extendedDecimals: boolean
): Money | undefined => {
  if (!money?.currency) {
    return undefined;
  }

  const scale = getDecimalScaleForCurrency(money.currency, extendedDecimals);
  const scaleDiff = scale - (money.scale ?? 0);
  return {
    ...money,
    amount: Math.round((money.amount ?? 0) * 10 ** scaleDiff),
    scale,
    formattedAmount: undefined,
  };
};

export const convertProductPricesScale = (
  p: Nullable<ProductRequest>,
  extendedDecimals: boolean
): Nullable<ProductRequest> => {
  return {
    ...p,
    entries: (p.entries ?? []).map((entry) => ({
      ...entry,
      price: convertMoneyScale(entry.price, extendedDecimals) ?? entry.price,
    })),
  };
};

const getPricingDescription = (
  product: Product | ProductSummary
): string | undefined => {
  return product.pricing;
};

const getTypeDescription = (
  product: Product | ProductSummary
): string | undefined => {
  if (product.recurrence === 'one_time') {
    return 'One time';
  }

  if (product.recurrence === 'recurring') {
    return product.consumption === 'multiple'
      ? 'Recurring quantity'
      : 'Recurring';
  }

  if (product.recurrence === 'usage') {
    return 'Usage';
  }

  return undefined;
};

export const getProductPricingSummary = (product: Product | ProductSummary) =>
  [getTypeDescription(product), getPricingDescription(product)]
    .filter(isString)
    .join(' ');

export const getEntriesWithUpdate = (
  entries: PricebookEntryRequest[],
  price: Money,
  billingPeriod?: Contract['billingPeriod'],
  level?: number
): PricebookEntryRequest[] => {
  if (hasEntry(entries, price.currency, billingPeriod, level)) {
    return entries.map((e) =>
      isMatchingEntryRequest(e, price.currency, billingPeriod, level)
        ? { ...e, price }
        : e
    );
  } else {
    return [...entries, { billingPeriod, level, price }];
  }
};

export const isBillingPeriodEnabled = (
  product: Nullable<ProductRequest>,
  billingPeriod: BillingPeriodsType
) =>
  product.billingPeriods &&
  !!product.billingPeriods.find((bp) => bp === billingPeriod);
