import React, { SVGProps, useState } from 'react';
import {
  capitalizeFirstLetter,
  ConnectorInfo,
  ExternalDeal,
  ProposalTemplateSummary,
} from 'common';
import { Page } from '../../components';
import ProposalFromDeal from './ProposalFromDeal';
import ExternalDealList from './ExternalDealList';

interface Props {
  dealLabel: string;
  dealQueryParamName: string;
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  sourceType: ConnectorInfo['source'];
}

function ExternalDealsPage({
  dealLabel,
  dealQueryParamName,
  icon,
  sourceType,
}: Props) {
  const [selectedDealId, setSelectedDealId] = useState<string | undefined>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<
    string | undefined
  >('');

  return (
    <Page leftWidget={`Create proposal from ${dealLabel}`}>
      <ProposalFromDeal
        sourceType={sourceType}
        selectedDealId={selectedDealId}
        dealQueryParamName={dealQueryParamName}
        onIsProcessing={setIsProcessing}
        onClose={() => setSelectedDealId('')}
        selectedTemplateId={selectedTemplateId}
      />
      <ExternalDealList
        dealLabel={capitalizeFirstLetter(dealLabel)}
        sourceType={sourceType}
        icon={icon}
        onClickCreate={(
          deal: ExternalDeal,
          template?: ProposalTemplateSummary
        ) => {
          setSelectedDealId(deal.externalId);
          setSelectedTemplateId(template?.id);
        }}
        isProcessing={isProcessing}
        selectedDealId={selectedDealId}
      />
    </Page>
  );
}

export default ExternalDealsPage;
