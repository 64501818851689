import {
  Card,
  CardHeader,
  CardRowValue,
  Contract,
  formatDateOrDefault,
  formatNullableFormattedAmount,
  getCurrencyLabelByCode,
  Proposal,
  TruncateOption,
  truncateText,
} from 'common';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import IDField from 'app/src/components/IDField';

interface Props {
  contract: Contract;
  contractProposals?: Proposal[];
}

const SubscriptionSummaryCard: React.FC<Props> = ({
  contract,
  contractProposals,
}) => {
  const navigate = useNavigate();
  // Our renewal date is the contract end date + 1 day
  const automaticRenewalDate = new Date(contract.endDate);
  automaticRenewalDate.setDate(automaticRenewalDate.getDate() + 1);

  return (
    <Card className="mb-2" throughline>
      <CardHeader name="Subscription" throughline />
      {contract.id && (
        <CardRowValue label="Id">
          <IDField documentID={contract.id}>
            <div className="tabular-nums">
              {truncateText(contract.id, 8, TruncateOption.WithoutDots)}
            </div>
          </IDField>
        </CardRowValue>
      )}
      <CardRowValue label="Total subscription value">
        <div className="font-bold">
          {formatNullableFormattedAmount(contract.totalAmountFormatted)}
        </div>
      </CardRowValue>
      <CardRowValue label="Subscription No.">
        <IDField documentID={contract.contractNumber}>
          <div className="tabular-nums">{contract.contractNumber}</div>
        </IDField>
      </CardRowValue>
      {contractProposals && contractProposals.length > 0 && (
        <CardRowValue label="Proposal">
          <div
            className="underline cursor-pointer hover:text-blue"
            onClick={() =>
              navigate(
                `/proposals/${
                  contractProposals[contractProposals.length - 1]?.id
                }`
              )
            }
          >
            Open proposal
          </div>
        </CardRowValue>
      )}

      <CardRowValue label="Start date">
        <div>{formatDateOrDefault(contract.startDate)}</div>
      </CardRowValue>
      <CardRowValue label="End date">
        <div>{formatDateOrDefault(contract.endDate)}</div>
      </CardRowValue>
      <CardRowValue label="Currency">
        <p>{getCurrencyLabelByCode(contract.currency)}</p>
      </CardRowValue>
    </Card>
  );
};

export default SubscriptionSummaryCard;
