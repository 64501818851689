import React from 'react';
import ErrorHandler from '../../../components/ErrorHandler';
import { useSystemUpcomingBilling } from '../../../services/api/system/upcomingTransfers';
import {
  Loading,
  SimpleCell,
  SimpleTable,
  UpcomingBillingResponse,
} from 'common';
import LoginAsButton from '../LoginAsButton/LoginAsButton';

interface Props {
  date: Date;
}

const UpcomingBilling: React.FC<Props> = ({ date }: Props) => {
  const {
    isLoading,
    error,
    isError,
    data: billing,
  } = useSystemUpcomingBilling(date);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorHandler error={error} />;
  }

  const cells: SimpleCell<UpcomingBillingResponse>[] = [
    {
      key: 'orgName',
      headerLabel: 'Org',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: UpcomingBillingResponse) =>
        data.orgId ? (
          <div className="flex items-center">
            <LoginAsButton orgId={data.orgId} dataTestId={data.orgName} />
            <div>{data.orgName}</div>
          </div>
        ) : null,
    },
    {
      key: 'orgId',
      headerLabel: 'Org ID',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: UpcomingBillingResponse) => <div>{data.orgId}</div>,
    },
    {
      key: 'contractName',
      headerLabel: 'Contract',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: UpcomingBillingResponse) => (
        <div>{data.contractName}</div>
      ),
    },
    {
      key: 'contractId',
      headerLabel: 'Contract ID',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: UpcomingBillingResponse) => (
        <div>{data.contractId}</div>
      ),
    },
    {
      key: 'contractStatus',
      headerLabel: 'Contract status',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: UpcomingBillingResponse) => (
        <div>{data.contractStatus}</div>
      ),
    },
    {
      key: 'contractTotalAmountFormatted',
      headerLabel: 'Total amount',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: UpcomingBillingResponse) => (
        <div>{data.contractTotalAmountFormatted}</div>
      ),
    },
    {
      key: 'paymentType',
      headerLabel: 'Payment type',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (data: UpcomingBillingResponse) => (
        <div>{data.paymentType}</div>
      ),
    },
    {
      key: 'billingMethodStatus',
      headerLabel: 'Billing status',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (data: UpcomingBillingResponse) => (
        <div>{data.billingMethodStatus}</div>
      ),
    },
  ];

  return (
    <SimpleTable<UpcomingBillingResponse>
      cells={cells}
      rows={billing}
      isLoading={isLoading}
      zeroStateMessage="There are currently no billings to display."
    />
  );
};

export default UpcomingBilling;
