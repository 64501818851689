import React from 'react';
import {
  AutoForm,
  emailValidator,
  OrgConfig,
  ValidationFormField,
} from 'common';
import {
  ORG_DEFAULTS_PATH,
  useFindOrgDefault,
} from '../../../services/orgDefaults';
import { apiClient } from '../../../services/httpClients/app';
import { useQueryClient } from '@tanstack/react-query';

const CONFIG_KEY = 'paymentErrorContact' as NonNullable<OrgConfig['configKey']>;

const PaymentErrorContact: React.FC = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useFindOrgDefault(CONFIG_KEY);
  const fields: ValidationFormField<OrgConfig>[] = [
    {
      fieldName: 'stringValue',
      humanReadableName: 'Payment error contact',
      infoText: 'Set an email address to receive payment error emails.',
      validator: emailValidator,
    },
  ];

  return (
    <AutoForm<OrgConfig>
      autoSave
      entity={data}
      entityName="company"
      fields={fields}
      isDisabled={isLoading}
      onSave={(payload) => apiClient.upsertOrgConfig(CONFIG_KEY, payload)}
      queryClient={queryClient}
      queryKey={[ORG_DEFAULTS_PATH]}
      showControls={false}
    />
  );
};

export default PaymentErrorContact;
