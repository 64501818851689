import React, { SVGProps } from 'react';

const CacheflowLogo: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Layer_1"
    aria-label="Cacheflow"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1203.73 250.01"
    {...props}
  >
    <title>Cacheflow</title>
    <path
      d="M112,141.47a35.59,35.59,0,0,1,28.48,13.46,3,3,0,0,0,3.85.77L164.89,144a3,3,0,0,0,1-4.35c-11.65-16.23-31.17-26-53.82-26-37.34-.46-67,28.77-66.34,65.65-.69,36.88,29,66.11,66.34,65.64,22.64,0,42.15-10,53.81-26a3,3,0,0,0-.95-4.35L144.37,202.7a3,3,0,0,0-3.93.84c-6.39,8.57-16.41,13.32-28.4,13.32-10.67,0-19.25-3.48-26-10.67-7.15-7.66-10.37-17.14-9.93-29C76.85,157.63,92.4,141.51,112,141.47Z"
      transform="translate(-45.69 -32.27)"
    />
    <path
      d="M303.21,116.88H277.44a3,3,0,0,0-3,3v14.4c-8.36-13.69-23.2-20.65-40.6-20.65-34.56-.69-61.24,29.23-60.77,65.65,0,36.88,26,65.64,60.77,65.64,17.4,0,32.24-7.19,40.6-20.87v14.63a3,3,0,0,0,3,3h25.77a3,3,0,0,0,3-3V119.88A3,3,0,0,0,303.21,116.88ZM238.94,217.32c-21.1,0-35.49-16.47-35.49-38q0-16.35,9.75-27.14c6.72-7.19,15.31-10.9,25.74-10.9,20.65,0,35.5,15.07,35.5,38S259.59,217.32,238.94,217.32Z"
      transform="translate(-45.69 -32.27)"
    />
    <path
      d="M387.72,141.47a35.59,35.59,0,0,1,28.48,13.46,3,3,0,0,0,3.84.77L440.65,144a3,3,0,0,0,1-4.35c-11.65-16.23-31.17-26-53.81-26-37.35-.46-67,28.77-66.34,65.65-.7,36.88,29,66.11,66.34,65.64,22.63,0,42.15-10,53.8-26a3,3,0,0,0-.94-4.35L420.13,202.7a3,3,0,0,0-3.92.84c-6.4,8.57-16.41,13.32-28.4,13.32-10.67,0-19.25-3.48-26-10.67-7.16-7.66-10.37-17.14-9.93-29C352.62,157.63,368.17,141.51,387.72,141.47Z"
      transform="translate(-45.69 -32.27)"
    />
    <path
      d="M528.16,113.63c-17.63,0-32,8.35-39.2,21.11V70.47a3,3,0,0,0-3-3H460.41a3,3,0,0,0-3,3V238.68a3,3,0,0,0,3,3H486a3,3,0,0,0,3-3V176.49c0-21.1,13.45-35.25,31.78-35.25s28.07,10.9,28.07,32.24v65.2a3,3,0,0,0,3,3h25.78a3,3,0,0,0,3-3V171.16C580.59,134.74,560.64,113.63,528.16,113.63Z"
      transform="translate(-45.69 -32.27)"
    />
    <path
      d="M660.4,113.63c-36.88,0-64.49,28.3-64.49,65.65s28.3,65.64,68.43,65.64c22.81,0,43.54-9.74,54.95-24.72a3,3,0,0,0-.81-4.34l-19.3-12.13a3,3,0,0,0-4,.7c-6.65,8.24-18.27,13.35-30.88,13.35-19.25,0-33.63-9.74-37.58-27.6h94.79a3,3,0,0,0,3-2.88c.18-4.14.33-7.82.33-11C724.88,139.38,697.51,113.63,660.4,113.63Zm-33.64,54.28c3.72-17.63,17.17-27.83,33.87-27.83,19.25,0,32.24,11.13,33.87,27.83Z"
      transform="translate(-45.69 -32.27)"
    />
    <path
      d="M895.38,241.26a13.19,13.19,0,1,1-26.37,0V89.75c0-31.13-24.76-57.07-55.89-57.48A56.63,56.63,0,0,0,755.75,88.9v28H732.07a3,3,0,0,0-3,3v21.6a3,3,0,0,0,3,3h23.68v94.2a3,3,0,0,0,3,3H784.3a3,3,0,0,0,3-3v-94.2h30.45a3,3,0,0,0,3-3v-21.6a3,3,0,0,0-3-3H787.3V85.76c0-14.07,11.51-25.92,25.57-25.65a25.09,25.09,0,0,1,24.6,25.08V236.82c0,24.53,19.44,45.05,44,45.46a44.74,44.74,0,0,0,45.5-44.73v-1.74a66.66,66.66,0,0,0,34.8,9.11c38.28,0,66.35-28.3,66.35-65.64v-62a13.18,13.18,0,0,1,26.36,0v70.36c0,31.44,25.72,57.8,57.16,57.51a56.49,56.49,0,0,0,40.33-17.44,56.49,56.49,0,0,0,40.33,17.44c31.44.29,57.16-26.07,57.16-57.51V124a3,3,0,0,0-3-3h-25.54a3,3,0,0,0-3,3v67.21c0,14.06-11.51,25.91-25.57,25.64a25.09,25.09,0,0,1-24.6-25.08V124a3,3,0,0,0-3-3h-25.55a3,3,0,0,0-3,3v67.21c0,14.06-11.51,25.91-25.57,25.64a25.09,25.09,0,0,1-24.6-25.08v-70c0-24.53-19.44-45-44-45.46A44.72,44.72,0,0,0,996.53,121v1.7a68.73,68.73,0,0,0-34.8-9.07c-37.34-.46-67,28.77-66.34,65.65Zm92.33-35.07c-6.72,7.19-15.31,10.67-26,10.67s-19.25-3.48-26-10.67c-7.22-7.73-10.43-17.32-9.92-29.36.84-19.62,16.78-35.6,36.42-35.36,21.29.26,35.67,16.64,35.67,37.81C997.92,190.18,994.44,199,987.71,206.19Z"
      transform="translate(-45.69 -32.27)"
    />
  </svg>
);

export default CacheflowLogo;
