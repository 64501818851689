import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { BillingSchedule, ProductUsage, ProductUsageRequest } from 'common';
import { invalidateContract, invalidateContractLists } from './contract';

const CREATE_PRODUCT_USAGE_KEY = 'CreateProductUsage';
const GET_BILLING_USAGE_KEY = 'GetBillingScheduleUsage';
const GET_BILLING_SCHEDULE_KEY = 'GetBillingSchedule';

const createProductUsage = async (
  productUsageRequests: ProductUsageRequest[]
) => {
  const { data } = await apiClient.createUsage(productUsageRequests);
  return data;
};

export const useCreateProductUsage = (
  contractId: string | undefined,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [CREATE_PRODUCT_USAGE_KEY],
    mutationFn: createProductUsage,
    onSuccess,
    onError,
    onSettled: async () => {
      // product usage can affect billing items in the contract, so
      // invalidate lists that may contain it as well as the contract itself
      await invalidateContractLists(qc);
      await invalidateContract(qc, contractId!);
    },
  });

export const useBillingScheduleUsage = (
  scheduleId: string
): UseQueryResult<ProductUsage[]> =>
  useQuery({
    queryKey: [GET_BILLING_USAGE_KEY],
    queryFn: async () => {
      const { data } = await apiClient.getBillingScheduleUsage(scheduleId);
      return data;
    },
  });

export const useBillingSchedule = (
  scheduleId: string | undefined
): UseQueryResult<BillingSchedule> =>
  useQuery({
    queryKey: [GET_BILLING_SCHEDULE_KEY, scheduleId],
    queryFn: async () => {
      const { data } = await apiClient.getBillingSchedule(scheduleId!);
      return data;
    },
    enabled: !!scheduleId,
  });
