import {
  AddressDisplay,
  Card,
  CardHeader,
  CardRowValue,
  Contract,
  ErrorToolTip,
  ReferenceIssue,
  useTranslation,
} from 'common';
import { useNavigate } from 'react-router-dom';
import React from 'react';

interface Props {
  contract: Contract;
  issues?: ReferenceIssue[];
}

const SubscriptionCustomerCard: React.FC<Props> = ({ contract, issues }) => {
  const { tk } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/customers/${contract.buyer.id}`);
  };

  const issueTexts = issues
    ? issues
        .filter((i) => i.referenceKey?.referenceType === 'customer')
        .map((i) => tk(`issues.${i.sourceType}.${i.issueType}`))
    : null;
  const alertIcon =
    issueTexts && issueTexts.length > 0 ? (
      <ErrorToolTip title={issueTexts.join('<br />')} />
    ) : null;

  return (
    <Card className="mb-2" throughline>
      <CardHeader name="Customer" throughline />
      <CardRowValue label="Legal name">
        {contract.buyer.name && (
          <div
            className="underline cursor-pointer font-bold"
            onClick={() => handleClick()}
          >
            {contract.buyer.name}
          </div>
        )}
      </CardRowValue>
      <CardRowValue label="Address">
        <div className="flex flex-row">
          <AddressDisplay address={contract.buyer.billingAddress} />
          {alertIcon}
        </div>
      </CardRowValue>
    </Card>
  );
};

export default SubscriptionCustomerCard;
