import React, { useContext } from 'react';
import clsx from 'clsx';

import { ApprovalRequest, Proposal, StickyContext } from 'common';

import RequestApproval from './RequestApproval';
import ApprovalActionBar from './ApprovalActionBar';
import { useGetProposalApprovalRequirements } from 'app/src/services/proposal';
import { useApprovalRequestsByUserGroups } from './hooks/useApprovalRequestsByUserGroups';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

import styles from './ApprovalBannerBody.module.scss';

interface Props {
  approvalRequest?: ApprovalRequest | null;
  proposal: Proposal;
}

export const ApprovalBannerBody = ({ proposal, approvalRequest }: Props) => {
  const isSticky = useContext(StickyContext);
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  const { getFilteredRequests, isLoadingFilteredRequests } =
    useApprovalRequestsByUserGroups(proposal);
  const filteredRequests = getFilteredRequests();

  const { data: allRequirements } = useGetProposalApprovalRequirements(
    proposal.id
  );

  const getContents = () => {
    // If the Proposal has approval requirements, but none for the current user to approve
    // Show the RequestApproval banner to kick off the pending_approval status
    // And to create the approval requests
    if (filteredRequests.length === 0 && allRequirements?.requiresApproval) {
      return (
        <RequestApproval
          isSticky={isSticky}
          lastApprovalRequest={approvalRequest}
          proposal={proposal}
        />
      );
    }

    // If the logged-in user has pending requests to approve
    // Show the approval action bar to allow them to action on them
    if (
      filteredRequests.some(
        (request: ApprovalRequest) => request.status === 'pending'
      )
    ) {
      return (
        <ApprovalActionBar
          filteredRequests={filteredRequests}
          proposal={proposal}
        />
      );
    }

    return null;
  };

  const userCanNotAct =
    proposal.status === 'pending_approval' && filteredRequests.length === 0;

  if (!canUpdate || isLoadingFilteredRequests || userCanNotAct) {
    return null;
  }

  return (
    <div className={clsx(styles.approvalSection, isSticky && styles.sticky)}>
      <div className={styles.contents}>{getContents()}</div>
    </div>
  );
};
