import React from 'react';
import { Icon, ProposalEvent } from 'common';
import { EventRecipient } from './EventPerson';
import ProposalApprovers from './ProposalApprovers';
import { EventDisplayProps } from 'app/src/components/events/EventDisplay';
import EventMessage from './EventMessage';
import { isEventSystemUser } from 'app/src/core-utils/helperFunctions/eventUtils';
import EventRules from './EventRules';

const getEventElements = (
  event: ProposalEvent,
  proposalId: string,
  previousEvent?: ProposalEvent
): Omit<EventDisplayProps, 'createdAt' | 'id'> | null => {
  const message = event.details?.message;
  const rules = event.details?.rules;
  if (!event.eventType) {
    throw Error('Event does not have eventType property');
  }

  switch (event.eventType) {
    case 'archived':
      return {
        icon: Icon.Locked,
        label: 'Proposal archived',
        person: event.user,
      };
    case 'restore':
      return {
        icon: Icon.Unlocked,
        label: 'Proposal restored',
        person: event.user,
      };
    case 'created':
      // renewal generated by system, show different message
      if (isEventSystemUser(event.user)) {
        return {
          icon: Icon.Plus,
          label: 'Auto-renewal created',
          person: event.user,
        };
      }
      return {
        icon: Icon.Plus,
        label: 'Created proposal',
        person: event.user,
      };
    case 'activated':
    case 'started':
      return {
        icon: Icon.Share,
        label: 'Shared proposal',
        person: event.user,
      };
    case 'contact_added':
      return {
        icon: Icon.UserAdd,
        label: 'Shared with customer',
        person: event.user,
        extra: <EventRecipient person={event.recipient} />,
      };
    case 'contact_removed':
      return {
        icon: Icon.UserRemove,
        label: 'Contact removed',
        person: event.recipient,
      };
    case 'set_to_draft':
      return {
        icon: Icon.Undo,
        label: 'Set to draft',
        person: event.user,
      };
    case 'viewed':
      return {
        icon: Icon.Eye,
        label: 'Viewed proposal',
        person: event.contact,
      };
    case 'accepted':
      // eslint-disable-next-line  no-case-declarations
      let label = 'Proposal accepted';

      if (previousEvent?.eventType === 'signed') {
        label = 'Payment method added';
      } else if (event.user && !isEventSystemUser(event.user)) {
        label = 'Proposal accepted by seller';
      }
      return {
        icon: Icon.Checkmark,
        label,
        person:
          event.user && !isEventSystemUser(event.user)
            ? event.user
            : event.contact,
      };
    case 'cancelled':
      return {
        icon: Icon.Close,
        label: 'Cancelled proposal',
        person: event.user,
      };
    case 'created_signing_doc':
      return {
        icon: Icon.DocumentAdd,
        label: 'Contract created',
        person: event.contact,
      };
    case 'signed':
      return {
        icon: Icon.DocumentSign,
        label: 'Contract signed',
        person: event.contact,
      };
    case 'cancelled_signing_doc':
      return {
        icon: Icon.Close,
        label: 'Signing cancelled',
        person: event.contact,
      };
    case 'invited':
      return {
        icon: Icon.UserAdd,
        label: 'Invited other contact',
        person: event.contact,
        extra: <EventRecipient person={event.recipient} />,
      };
    case 'approval_requested':
      return {
        icon: Icon.Action,
        label: 'Internal approval requested',
        person: event.createdBy,
        extra: (
          <div>
            {message && <EventMessage message={message} />}
            <ProposalApprovers proposalId={proposalId} />
            {rules && rules.length > 0 && <EventRules rules={rules} />}
          </div>
        ),
        dataTestId: 'proposal-approval-event-requested',
      };
    case 'approval_approved':
      return {
        icon: Icon.Checkmark,
        label: 'Proposal approved',
        person: event.user,
        extra: (
          <div>
            {message ? <EventMessage message={message} /> : undefined}
            {rules && rules.length > 0 && <EventRules rules={rules} />}
          </div>
        ),
        dataTestId: 'proposal-approval-event-approved',
      };
    case 'approval_rejected':
      return {
        icon: Icon.AlertVariant,
        label: 'Proposal rejected',
        person: event.user,
        extra: (
          <div>
            {message ? <EventMessage message={message} isError /> : undefined}
            {rules && rules.length > 0 && <EventRules rules={rules} />}
          </div>
        ),
        dataTestId: 'proposal-approval-event-rejected',
      };
    case 'approval_canceled':
      return {
        icon: Icon.Close,
        label: 'Internal approval canceled',
        person: event.user,
        extra: (
          <div>{rules && rules.length > 0 && <EventRules rules={rules} />}</div>
        ),
      };
    case 'expired':
      return {
        icon: Icon.Close,
        label: 'Expired',
        person: event.user,
      };
    case 'unviewable_status':
      return {
        icon: Icon.Close,
        label: 'Customer unable to view proposal',
        person: event.contact,
      };
    case 'request_new':
      return {
        icon: Icon.Close,
        label: 'Request new',
        person: event.contact,
      };
    case 'buyer_printed':
      return {
        icon: Icon.Print,
        label: 'Proposal printed',
        person: event.contact,
      };
    case 'buyer_downloaded':
      return {
        icon: Icon.Pdf,
        label: 'Proposal downloaded',
        person: event.contact,
      };
    case 'seller_downloaded':
      return {
        icon: Icon.Pdf,
        label: 'Proposal downloaded',
        person: event.user,
      };
    case 'renewal_pending':
      // renewal proposal was accepted by seller
      if (event.user && !isEventSystemUser(event.user)) {
        return {
          label: 'Renewal accepted by seller',
          icon: Icon.Checkmark,
          person: event.user,
        };
      }
      return {
        icon: Icon.Checkmark,
        label: 'Renewal accepted',
        person: event.contact,
      };
    default:
      return null;
  }
};

export default getEventElements;
