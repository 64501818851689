import { QueryClient, useMutation } from '@tanstack/react-query';
import { apiClient } from '../../httpClients/app';
import { PaymentSchedule } from 'common';
import { clearProposalCache } from '../../proposal';

const PAYMENT_SCHEDULE_UPDATE = 'PAYMENT_SCHEDULE_UPDATE';

const updatePaymentSchedule = async (body: PaymentSchedule) => {
  const { data } = await apiClient.updatePaymentSchedule(body.id!, body);
  return data;
};

/**
 * Updates a payment schedule.
 * Uses the optional input proposalId to clear usage of the owning Proposal in cache, since
 * it nests the schedule.
 */
export const useUpdatePaymentSchedule = (
  proposalId: string | undefined,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [PAYMENT_SCHEDULE_UPDATE],
    mutationFn: (body: PaymentSchedule) => updatePaymentSchedule(body),
    onSuccess,
    onError,
    onSettled: async () => {
      if (proposalId) {
        await clearProposalCache(proposalId, qc);
      }
    },
  });
