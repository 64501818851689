import React from 'react';
import './styles.css';

interface Props {
  dataTestId?: string;
  isConnected: boolean;
  message?: string | React.ReactElement;
}

const ConnectionStatus: React.FC<Props> = ({
  isConnected,
  message,
  dataTestId = 'ConnectionStatus',
}) => {
  return (
    <div className="flex items-center">
      <div
        className={`pulse-circle ${isConnected ? 'connected' : 'disconnected'}`}
        data-testid={
          dataTestId ||
          `ConnectionStatus-${isConnected ? 'connected' : 'disconnected'}`
        }
      />
      {message}
    </div>
  );
};

export default ConnectionStatus;
