import { RouteDef } from './Routes';
import {
  LOGIN_ROUTE,
  MAINTENANCE,
  ORG_SELECTION_ROUTE,
  PATH_MAP,
  SYSTEM_DASHBOARD_ROUTE,
  SYSTEM_LOGIN_ROUTE,
  SYSTEM_ROUTE,
} from 'app/src/core-utils/routes';

export const formatPath = (routeDef: RouteDef): string => {
  if (routeDef.hasNestedRoutes) {
    if (routeDef.path.endsWith('/')) {
      return `${routeDef.path}*`;
    }
    return `${routeDef.path}/*`;
  }

  return routeDef.path;
};

const trimSubRoute = (parent: RouteDef, child: RouteDef): RouteDef => ({
  ...child,
  path: child.path.replace(parent.path.replace('*', ''), ''),
});

export const trimSubRoutes = (
  parent: RouteDef,
  children: RouteDef[]
): RouteDef[] => {
  return children.map((child) => trimSubRoute(parent, child));
};

export const trimRouteSubRoutes = (route: RouteDef): RouteDef[] | undefined => {
  if (!route.routes) {
    return undefined;
  }

  return trimSubRoutes(route, route.routes);
};

export const getSearchParams = (
  locationSearch: string | undefined
): string | undefined => {
  if (!locationSearch) {
    return undefined;
  }
  const searchParams = new URLSearchParams(locationSearch);
  searchParams.delete('token');
  searchParams.delete('prevPath');
  // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/toString
  const searchValue = searchParams.toString();
  if (searchValue.length > 0) {
    return `?${searchValue}`; // A string, without the question mark is returned by url search params
  }
  return searchValue;
};

export const isNonEntryPath = (path: string) => {
  const stripTrailingSlash = (pathInput: string): string => {
    return pathInput.endsWith('/') && pathInput !== '/'
      ? pathInput.slice(0, -1)
      : pathInput;
  };

  const normalizedPath = stripTrailingSlash(path);

  return ![
    '/',
    LOGIN_ROUTE,
    SYSTEM_LOGIN_ROUTE,
    SYSTEM_DASHBOARD_ROUTE,
    SYSTEM_ROUTE,
    MAINTENANCE,
    ORG_SELECTION_ROUTE,
  ].includes(normalizedPath);
};

export const getPrevPath = (locationPath: string): string | undefined => {
  if (!isNonEntryPath(locationPath)) {
    return undefined;
  }
  return locationPath;
};

export const getDetailLink = (
  referenceType: string,
  referenceId: string
): string | null => {
  const path = PATH_MAP[referenceType];
  return path ? `${path}/${referenceId}` : null;
};
