import { ConnectorInfo, OrgConfig } from 'common';

const HUBSPOT: string = 'HubSpot';
export const HUBSPOT_CONFIG: OrgConfig['configKey'] = 'hubspotConfig';
const HUBSPOT_SOURCE: ConnectorInfo['source'] = 'hubspot';

export const HUBSPOT_INFO = {
  configType: HUBSPOT_CONFIG,
  source: HUBSPOT_SOURCE,
  name: HUBSPOT,
  showSaveButton: true,
};

export const accountContactsConfig = {
  integration: 'HubSpot',
  description:
    'The HubSpot connector automatically imports companies and contacts relevant to the available deals into Cacheflow. Customer and contact changes in Cacheflow are automatically pushed to HubSpot.',
  cacheflowToIntegrationSection: [
    {
      left: 'Customers',
      right: 'Companies',
    },
    {
      left: 'Customer contacts',
      right: 'Contacts',
    },
    {
      left: 'Billing contact',
      right: 'Contacts',
    },
    {
      left: 'Proposal signer',
      right: 'Contacts',
    },
  ],
  integrationToCacheflowSection: [
    {
      left: 'Companies',
      right: 'Customers',
    },
    {
      left: 'Contacts',
      right: 'Customer contacts',
    },
  ],
};
