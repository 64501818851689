import React from 'react';
import clsx from 'clsx';

import {
  Button,
  ButtonBar,
  Drawer,
  Icon,
  pluralize,
  Proposal,
  pushIf,
  Type,
} from 'common';

import { useValidateProposal, ValidationAction } from '../proposalUtilities';
import styles from './VerifyProposal.module.scss';

interface Props {
  action?: ValidationAction;
  isOpen: boolean;
  onClose: () => void;
  proposal: Proposal;
}

const VerifyProposal: React.FC<Props> = ({
  isOpen,
  onClose,
  proposal,
  action,
}) => {
  const {
    customerError,
    expirationError,
    isValid,
    productError,
    proposalNumberError,
    noManagedPaymentMethodError,
    noSigningRequiredError,
  } = useValidateProposal(proposal, action);

  const createSubscription = action === 'create-subscription';

  const items: { checked: boolean; label: string }[] = [
    {
      checked: !customerError,
      label:
        customerError ||
        `You have chosen ${proposal.customer?.name} as your customer`,
    },
    {
      checked: !productError,
      label:
        productError ||
        `You have chosen ${pluralize(proposal.proposalItems.length, 'product')}`,
    },
    {
      checked: !expirationError,
      label:
        expirationError || 'Expiration date must be in the future or blank.',
    },
    {
      checked: !proposalNumberError,
      label: proposalNumberError || 'A proposal number has been set.',
    },
    ...pushIf(createSubscription, {
      checked: !noManagedPaymentMethodError,
      label: noManagedPaymentMethodError || 'Payment methods are set properly.',
    }),
    ...pushIf(createSubscription, {
      checked: !noSigningRequiredError,
      label:
        noSigningRequiredError ||
        'Docusign is unsupported for this feature, please remove.',
    }),
  ];

  const checkedFirst = (a: { checked: boolean }, b: { checked: boolean }) =>
    Number(b.checked) - Number(a.checked);

  return (
    <Drawer
      footer={
        <ButtonBar>
          <Button block label="OK" onClick={onClose} />
        </ButtonBar>
      }
      header={createSubscription ? 'Create subscription' : 'Build proposal'}
      isOpen={!isValid && isOpen}
      onClose={onClose}
    >
      <div className={styles.verifyProposal}>
        <Type bold>
          {createSubscription
            ? 'Before you are able to create a subscription, please complete the following steps:'
            : 'Before you are able to share a proposal, please complete the following steps:'}
        </Type>
        <div>
          {items.sort(checkedFirst).map((item) => (
            <div
              className={clsx(styles.item, item.checked && styles.checked)}
              key={item.label}
            >
              {item.checked ? <Icon.Checkmark /> : <Icon.ArrowRight />}
              <Type className={styles.label}>{item.label}</Type>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default VerifyProposal;
