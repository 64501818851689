import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import {
  ConnectorInfo,
  CreateProposalFromDealRequest,
  CrmObjectProperty,
  CrmPipeline,
  CrmStage,
  DomainField,
  ExternalDeal,
  ExternalObjectReference,
  Proposal,
} from 'common';
import { GET_ALL_PROPOSALS_KEY } from './proposal';

const GET_ALL_DEALS = '/data/external-crm/deals';
const GET_ALL_STAGES = '/data/external-crm/stages';

export const useGetAllExternalDeals = (
  sourceType: ConnectorInfo['source']
): UseQueryResult<ExternalDeal[]> =>
  useQuery({
    queryKey: [GET_ALL_DEALS + sourceType],
    queryFn: async () => {
      const { data } = await apiClient.getExternalDeals(sourceType!);
      return data;
    },
    enabled: !!sourceType,
  });

export const getExternalDeal = async (
  sourceType: ConnectorInfo['source'],
  dealId: string
) => {
  const { data } = await apiClient.getExternalDeal(sourceType!, dealId);
  return data;
};

export const useGetCrmStages = (
  sourceType: ConnectorInfo['source']
): UseQueryResult<CrmStage[]> =>
  useQuery({
    queryKey: [GET_ALL_STAGES + sourceType],
    queryFn: async () => {
      const { data } = await apiClient.getCrmStages(sourceType!);
      return data as CrmStage[];
    },
  });

export const useGetCrmPipelines = (
  sourceType: ConnectorInfo['source']
): UseQueryResult<CrmPipeline[]> =>
  useQuery({
    queryFn: async () => {
      const { data } = await apiClient.getCrmPipelines(sourceType!);
      return data as CrmPipeline[];
    },
    queryKey: ['crmPipelines'],
    enabled: sourceType === 'hubspot',
  });

const createProposalFromDeal = async (
  sourceType: ConnectorInfo['source'],
  params: CreateProposalFromDealRequest
) => {
  const { data } = await apiClient.createProposalFromDeal(sourceType!, params);
  return data;
};

export const useCreateProposalFromDeal = (
  sourceType: ConnectorInfo['source'],
  onSuccess: (data: Proposal) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['createProposalFromDeal'],
    mutationFn: (params: CreateProposalFromDealRequest) =>
      createProposalFromDeal(sourceType, params),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [GET_ALL_PROPOSALS_KEY] });
    },
  });

export const useDealProperties = (): UseQueryResult<CrmObjectProperty[]> =>
  useQuery({
    queryKey: ['DEAL_PROPERTIES'],
    queryFn: async () => {
      const { data } = await apiClient.getCrmFields('proposal', 'hubspot');
      return data;
    },
  });

export const useCfProposalProperties = (): UseQueryResult<DomainField[]> =>
  useQuery({
    queryKey: ['CF_PROPOSAL_PROPERTIES'],
    queryFn: async () => {
      const { data } = await apiClient.getDomainFields('proposal');
      return data;
    },
  });

const unlinkExternalObject = async (
  reference: Parameters<typeof apiClient.unlinkFromExternal>[0],
  id: string,
  data: ExternalObjectReference
) => {
  const { data: responseData } = await apiClient.unlinkFromExternal(
    reference,
    id,
    data
  );
  return responseData;
};

export const useUnlinkExternalObject = (
  reference: Parameters<typeof unlinkExternalObject>[0],
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['UNLINK_EXTERNAL_OBJECT'],
    mutationFn: (data: ExternalObjectReference) =>
      unlinkExternalObject(reference, id, data),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries();
    },
  });

const linkObjectToExternal = async (
  reference: Parameters<typeof apiClient.linkObjectToExternal>[0],
  id: string,
  data: ExternalObjectReference
) => {
  const { data: responseData } = await apiClient.linkObjectToExternal(
    reference,
    id,
    data
  );
  return responseData;
};

export const useLinkObjectToExternal = (
  reference: Parameters<typeof linkObjectToExternal>[0],
  id: string,
  onSuccess: (externalObjects: ExternalObjectReference[]) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['LINK_EXTERNAL_OBJECT'],
    mutationFn: (data: ExternalObjectReference) =>
      linkObjectToExternal(reference, id, data),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries();
    },
  });
