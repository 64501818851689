import React from 'react';
import { Icon } from 'common';

const IACActivated = () => {
  return (
    <div className="iac-activated-tag">
      <Icon.Checkmark width={25} height={25} className="text-green" />
      <h3>Activated</h3>
    </div>
  );
};

export default IACActivated;
