import React from 'react';
import * as Sentry from '@sentry/react';

interface Props {
  children: React.ReactNode;
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const fallback = (
    <div className="absolute flex flex-col items-center justify-center w-full h-full">
      An error has occurred
    </div>
  );

  return (
    <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
