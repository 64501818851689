import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  arrayTop,
  BILLING_PERIODS,
  BillingPeriodConfig,
  BillingPeriodsType,
  BundleItemRequest,
  Button,
  CardRowValue,
  ConfirmDeleteModal,
  ConfirmModal,
  formatTimeAgo,
  formatUnit,
  FormField,
  getErrorMessage,
  getLengthValidator,
  Icon,
  Input,
  InputError,
  isDefined,
  isEmpty,
  isNumber,
  isObject,
  NavigationIcon,
  Nullable,
  NumberInput,
  NumberInputAutosize,
  PopOutMenu,
  PopOutMenuOption,
  PricebookEntryRequest,
  Product,
  ProductRequest,
  ProductStats,
  ProductSummary,
  SimpleTable,
  SolidRadio,
  TaxCodeRest,
  TaxConfig,
  ToggleSwitch,
  TruncateOption,
  truncateText,
  Unit,
  useFlags,
  useFormValidation,
  useToast,
  useTranslation,
} from 'common';

import { RichTextInput } from '../../../../components';
import { PRODUCTS } from '../../../../core-utils/routes';
import Page from '../../../../components/Page';
import Editable from '../../../../components/Editable';
import ActionBar from '../../../../components/ActionBar';
import ActionBarButton from '../../../../components/ActionBarButton';
import { useFindOrgDefault } from 'app/src/services/orgDefaults';
import { PricingConfiguration } from './PricingConfiguration';
import TaxCodeSelect from './TaxCodeSelect';
import NewBundleItem from './NewBundleItem';
import ProductRadioButtonOption from './ProductRadioButtonOption';
import UnitsSelect from './UnitsSelect';

import styles from './ProductForm.module.scss';
import IDField from 'app/src/components/IDField';
import { apiClient } from 'app/src/services/httpClients/app';
import {
  useLinkObjectToExternal,
  useUnlinkExternalObject,
} from '../../../../services/externalCrm';
import { useQueryClient } from '@tanstack/react-query';
import LinkCustomerToCrmDrawer from '../../LinkEntityToCrmDrawer';
import ExternalEntityLink from 'app/src/components/ExternalEntityLink';

interface Props {
  isProcessing: boolean;
  onClickDelete: () => void;
  onClickSave: (newVersion: boolean, product: ProductRequest) => void;
  productId: string;
  productBeingEdited: Product;
  stats: ProductStats;
}

export interface ItemRequestAndProduct {
  itemRequest: BundleItemRequest;
  product: Product | ProductSummary;
}

const ProductForm: React.FC<Props> = ({
  isProcessing,
  onClickDelete,
  onClickSave,
  productBeingEdited,
  productId,
  stats,
}) => {
  const { tk } = useTranslation();
  const {
    taxyTurby,
    taxyTurbyMode,
    salesforceSyncLineItems,
    hideQuantityOnInvoice,
    hubspotSyncLineItems,
    enhancedSpans,
  } = useFlags();
  const showToast = useToast();
  const [pricingIsValid, setPricingIsValid] = useState(true);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState<boolean>(false);
  const externalEntityDetails = arrayTop(productBeingEdited.sources);
  const productInUse = stats.proposalCount > 0 || stats.bundleCount > 0;
  const blockKeyPropEdit =
    stats.anyVersionProposalCount > 0 || stats.anyVersionBundleCount > 0;
  const queryClient = useQueryClient();

  const convertToRequest = (product: Product): ProductRequest => {
    return {
      ...product,
      // Unset customizable to respect new customization flags
      // TODO(Kevin Lloyd): Remove when parameter is removed
      customizable: undefined,
      unitId: product.unit?.id,
      taxConfig: product.taxConfig
        ? {
            taxCode: product.taxConfig.taxCode?.code,
          }
        : undefined,
    };
  };

  const convertToItemRequests = (product: Product): ItemRequestAndProduct[] => {
    if (product.bundleItems) {
      return product.bundleItems.map((bi) => ({
        itemRequest: {
          productId: bi.product.id,
          quantity: bi.quantity,
          bundlePercent: bi.bundlePercent,
        },
        product: bi.product,
      }));
    } else {
      return [];
    }
  };

  const [productDraft, setProductDraft] = useState<Nullable<ProductRequest>>(
    convertToRequest(productBeingEdited)
  );

  const [itemRequests, setItemRequests] = useState<
    ItemRequestAndProduct[] | undefined
  >(convertToItemRequests(productBeingEdited));

  const isBundle = productBeingEdited.productType !== 'single';

  const { data: serverDefaultBillingPeriod } = useFindOrgDefault(
    'billingPeriodConfig'
  );

  const isTaxConfig = (value: TaxConfig | unknown | null): value is TaxConfig =>
    isObject(value);

  useEffect(() => {
    // update draft when actual product updates
    setProductDraft(convertToRequest(productBeingEdited));
    setItemRequests(convertToItemRequests(productBeingEdited));
  }, [productBeingEdited]);

  const emptyInactiveBundle =
    productBeingEdited.status === 'inactive' &&
    ['bundleTopDown', 'bundleBottomUp'].includes(productDraft.productType!) &&
    (!itemRequests || itemRequests.length === 0);

  const { isFormValid: isNameValid } = useFormValidation(
    [
      {
        fieldName: 'name',
        isRequired: true,
        validator: getLengthValidator(254, 'name'),
      },
    ],
    productDraft
  );

  const isFormValid = isNameValid && pricingIsValid;

  const handleSelectUnit = (unit: Unit) => {
    setProductDraft((prev) => ({ ...prev, unitId: unit.id }));
  };

  const handleSelectTaxCode = (code: TaxCodeRest) => {
    setProductDraft((prev) => ({
      ...prev,
      taxConfig: { ...prev.taxConfig, taxCode: code.code },
    }));
  };

  const handleClearSelectedUnit = () => {
    setProductDraft((prev) => ({
      ...prev,
      unit: undefined,
      unitId: undefined,
    }));
  };

  const getEntryRequests = (): PricebookEntryRequest[] | undefined => {
    const currencies = productDraft.currencies || [];
    return productDraft.entries?.reduce<PricebookEntryRequest[]>((acc, e) => {
      if (currencies.find((currency) => currency === e.price?.currency)) {
        acc.push({
          billingPeriod: e.billingPeriod,
          level: e.level,
          price: e.price,
        });
      }
      return acc;
    }, []);
  };

  const save = () => {
    const request: Nullable<ProductRequest> = {
      ...productDraft,
      billingPeriods:
        productDraft.recurrence === 'one_time'
          ? null
          : productDraft.billingPeriods,
      entries: productDraft.entries ? getEntryRequests() : undefined,
      levels: productDraft.pricing === 'fixed' ? null : productDraft.levels,
      bundleItems:
        isBundle && itemRequests
          ? itemRequests.map((pair) => pair.itemRequest)
          : undefined,
      taxConfig:
        taxyTurby && isTaxConfig(productDraft.taxConfig)
          ? productDraft.taxConfig
          : null,
    };

    onClickSave(productInUse, request as ProductRequest);
  };

  const handlePricingChange = (pricing: Product['pricing']) => {
    if (pricing === 'fixed') {
      setProductDraft((prev) => {
        return {
          ...prev,
          pricing,
          levels: undefined,
          entries: [], // clear levelled pricebook entries.
        };
      });
    } else if (productDraft.pricing === 'fixed') {
      setProductDraft((prev) => {
        return {
          ...prev,
          pricing,
          entries: [], // clear levelled pricebook entries.
          levels: [],
        };
      });
    } else {
      setProductDraft((prev) => {
        return {
          ...prev,
          pricing,
        };
      });
    }
  };

  const getDefaultBillingPeriods = (): BillingPeriodsType[] => {
    const configValue = serverDefaultBillingPeriod?.configValue as
      | BillingPeriodConfig
      | undefined;
    const proposalBillingPeriods = configValue?.newProposalBillingPeriods;
    if (proposalBillingPeriods && proposalBillingPeriods.length > 0) {
      return proposalBillingPeriods;
    } else {
      return BILLING_PERIODS;
    }
  };

  const handleRecurrenceChange = (recurrence: Product['recurrence']) => {
    const consumption =
      recurrence === 'usage' ? 'multiple' : productDraft.consumption;

    setProductDraft((prev) => ({
      ...prev,
      billingPeriods:
        recurrence === 'one_time' ? undefined : getDefaultBillingPeriods(),
      billing: recurrence === 'one_time' ? 'scheduled' : prev.billing,
      entries: [],
      recurrence,
      consumption,
    }));

    // TODO: be less drastic
    setItemRequests([]);
  };

  const handleConsumptionChange = (consumption: Product['consumption']) => {
    if (consumption === 'single') {
      // Force pricing to 'fixed', clear out levels, and set a price.
      setProductDraft((prev) => {
        return {
          ...prev,
          consumption,
          pricing: 'fixed' as Product['pricing'],
          entries: [],
          levels: undefined,
        };
      });
    } else {
      setProductDraft((prev) => ({
        ...prev,
        consumption,
      }));
    }
  };

  const { mutate: linkProductToCrm } = useLinkObjectToExternal(
    'product',
    productId,
    () => {
      showToast.info('Product linked to CRM');
      setLinkToCrmDrawerOpen(false);
    },
    (err: unknown) => {
      const errorMessage = getErrorMessage(err);
      if (errorMessage) {
        showToast.error(errorMessage);
      } else {
        showToast.error('Failed to link product from CRM');
      }

      setLinkToCrmDrawerOpen(false);
    },
    queryClient
  );

  const { mutate: unlinkProductFromCRM } = useUnlinkExternalObject(
    'product',
    productId,
    () => {
      setShowConfirm(false);
      showToast.info('Product unlinked from CRM');
    },
    (err: unknown) => {
      setShowConfirm(false);
      const errorMessage = getErrorMessage(err);
      if (errorMessage) {
        showToast.error(errorMessage);
      } else {
        showToast.error('Failed to unlink product from CRM');
      }
    },
    queryClient
  );

  const formIsDisabled = isProcessing;

  const [linkToCrmDrawerOpen, setLinkToCrmDrawerOpen] =
    useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const nameComponent = (
    <div className={styles.headerSection}>
      <div className={styles.nameComponent}>
        <Link to={PRODUCTS}>
          {productDraft.productType === 'single' ? (
            <NavigationIcon.Products />
          ) : (
            <NavigationIcon.Bundles />
          )}
        </Link>
      </div>
      <div className={styles.nameComponent}>
        <Editable
          as="p"
          classNames={styles.editableProductName}
          content={productDraft.name ?? ''}
          dataTestId="productName"
          maxContentLength={100}
          onValueChange={(value) =>
            setProductDraft((prev) => ({ ...prev, name: value }))
          }
        />
      </div>
      <span className={styles.timeAgo}>
        {formatTimeAgo(productBeingEdited.updatedAt)}
      </span>
    </div>
  );

  const statusAndSaveComponent = (
    <div className={styles.headerSection}>
      <ToggleSwitch
        dataTestId="productIsActive"
        isDisabled={isProcessing}
        label={productDraft.status === 'active' ? tk('Active') : tk('Inactive')}
        onChange={(value) => {
          if (emptyInactiveBundle) {
            showToast.error('A bundle must have at least one product.');
            return;
          }

          const newActiveness = value ? 'active' : 'inactive';
          setProductDraft((prev) => ({
            ...prev,
            status: newActiveness,
          }));
        }}
        value={productDraft.status === 'active'}
      />

      <ActionBar>
        <ActionBarButton
          dataTestId="product-delete-button"
          disabledTooltip="Only unused products can be deleted."
          icon={Icon.Trash}
          isDisabled={productInUse}
          onClick={() => setIsConfirmingDelete(true)}
          tooltip="Delete"
        />
      </ActionBar>
      {(salesforceSyncLineItems || hubspotSyncLineItems) &&
        productBeingEdited.sources && (
          <PopOutMenu>
            {productBeingEdited.sources.length > 0 ? (
              <PopOutMenuOption
                dataTestId="product-unlink-from-crm-button"
                icon={Icon.Unlink}
                onClick={() => {
                  unlinkProductFromCRM({
                    sourceId: productId,
                    sourceType: externalEntityDetails?.sourceType,
                  });
                }}
                title={tk('Unlink from CRM')}
              />
            ) : (
              <PopOutMenuOption
                dataTestId="product-link-to-crm-button"
                icon={Icon.Link}
                onClick={() => {
                  setLinkToCrmDrawerOpen(true);
                }}
                title={tk('Link to CRM')}
              />
            )}
          </PopOutMenu>
        )}
      <Button
        dataTestId="saveProduct"
        isDisabled={!isFormValid}
        label={tk('Save changes')}
        onClick={save}
      />
    </div>
  );

  const isBundlePercentagesValid: boolean =
    itemRequests?.reduce(
      (acc, itemProductPair) =>
        acc + (itemProductPair.itemRequest.bundlePercent || 0),
      0
    ) === 10000 || itemRequests?.length === 0;

  return (
    <Page
      className={styles.productForm}
      leftWidget={nameComponent}
      rightWidget={statusAndSaveComponent}
    >
      {productBeingEdited.sources && productBeingEdited.sources.length > 0 && (
        <div className="flex justify-end">
          <ExternalEntityLink entity={productBeingEdited} showId />
        </div>
      )}

      <LinkCustomerToCrmDrawer
        entityLabel="Product"
        fetchOptions={apiClient.getExternalProducts}
        headerLabel="Link product to CRM"
        isOpen={linkToCrmDrawerOpen}
        isUpdating={false}
        linkEntityToCRM={linkProductToCrm}
        onClose={() => setLinkToCrmDrawerOpen(false)}
      />
      <ConfirmModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => {}}
      >
        <div>Are you sure you want to unlink this product from CRM?</div>
      </ConfirmModal>
      <ConfirmDeleteModal
        isOpen={isConfirmingDelete}
        onClose={() => setIsConfirmingDelete(false)}
        onConfirm={onClickDelete}
        typeName={tk(isBundle ? 'bundle' : 'product')}
      />

      {productBeingEdited.id && (
        <CardRowValue
          className={styles.productObjectId}
          label="Id:"
          leftClassName={styles.productIdLabel}
        >
          <IDField documentID={productBeingEdited.id}>
            <div className="tabular-nums">
              {truncateText(
                productBeingEdited.id,
                8,
                TruncateOption.WithoutDots
              )}
            </div>
          </IDField>
        </CardRowValue>
      )}
      <div className={styles.formWrapper}>
        <FormField
          infoText={tk(
            'The internal name will not appear on customer proposals and bills.'
          )}
          isOptional
          label={tk('Internal name')}
        >
          <Input
            maxLength={254}
            onChange={(internalName) =>
              setProductDraft((prev) => ({
                ...prev,
                internalName,
              }))
            }
            value={productDraft.internalName ?? undefined}
          />
        </FormField>

        <FormField
          infoText={tk(
            'The description will appear on customer proposals and bills.'
          )}
          isOptional
          label={tk('Description')}
        >
          <RichTextInput
            dataTestId="description"
            featureSet="product.description"
            onChange={(description) =>
              setProductDraft((prev) => ({ ...prev, description }))
            }
            value={productDraft.description ?? undefined}
          />
        </FormField>
        <FormField
          infoTooltip={tk('Charge type cannot be changed on an active product')}
          label={tk('Is this a one-time or a recurring charge?')}
        >
          <ProductRadioButtonOption
            dataTestId="one_time"
            description={tk(
              'Charges are billed once at the beginning of the contract and do not repeat. Examples include setup and onboarding fees.'
            )}
            isChecked={productDraft.recurrence === 'one_time'}
            isDisabled={formIsDisabled || blockKeyPropEdit}
            label={tk('One-time charge')}
            onCheck={() => handleRecurrenceChange('one_time')}
          />

          <ProductRadioButtonOption
            dataTestId="recurring"
            description={tk(
              'A charge that repeats at a specified interval over the term of the agreement.'
            )}
            isChecked={productDraft.recurrence === 'recurring'}
            isDisabled={formIsDisabled || blockKeyPropEdit}
            label={tk('Recurring charge')}
            onCheck={() => handleRecurrenceChange('recurring')}
          />

          {!isBundle && (
            <ProductRadioButtonOption
              dataTestId="usage"
              description={tk(
                'The quantity varies based on how much a customer uses within a given period. ' +
                  'The product is billed at the end of the billing cycle after the usage is calculated. ' +
                  'Examples: API calls, events.'
              )}
              isChecked={productDraft.recurrence === 'usage'}
              isDisabled={formIsDisabled || blockKeyPropEdit}
              label={tk('Usage-based charge')}
              onCheck={() => handleRecurrenceChange('usage')}
            />
          )}
        </FormField>

        {productDraft.recurrence !== 'usage' && (
          <ToggleSwitch
            alignLabel="right"
            dataTestId="multiple"
            isDisabled={formIsDisabled || blockKeyPropEdit}
            label={tk('Multiple quantities')}
            onChange={() =>
              handleConsumptionChange(
                productDraft.consumption === 'multiple' ? 'single' : 'multiple'
              )
            }
            value={productDraft.consumption === 'multiple'}
          />
        )}

        {productDraft.consumption !== 'single' && (
          <FormField
            infoTooltip={tk(
              'Price type cannot be changed on an active product'
            )}
            label={tk('How is the price determined?')}
          >
            <ProductRadioButtonOption
              dataTestId="fixed"
              description={tk(
                'All units have the same fixed price no matter the quantity purchased.'
              )}
              isChecked={productDraft.pricing === 'fixed'}
              isDisabled={formIsDisabled || blockKeyPropEdit}
              label={tk('Fixed')}
              onCheck={() => handlePricingChange('fixed')}
            />
            <ProductRadioButtonOption
              dataTestId="graduated"
              description={tk(
                'Each unit is priced based on the level of consumption. The first 500 units may be charged at USD 1.00, whereas units used above 500 are charged at a different price.'
              )}
              isChecked={productDraft.pricing === 'graduated'}
              isDisabled={formIsDisabled || blockKeyPropEdit}
              label={tk('Graduated')}
              onCheck={() => handlePricingChange('graduated')}
            />
            <ProductRadioButtonOption
              dataTestId="volume"
              description={tk(
                'As quantity consumed increases, the price per unit changes. All units consumed within a billing period will be charged at the same price.'
              )}
              isChecked={productDraft.pricing === 'volume'}
              isDisabled={formIsDisabled || blockKeyPropEdit}
              label={tk('Volume based')}
              onCheck={() => handlePricingChange('volume')}
            />
            <ProductRadioButtonOption
              dataTestId="stairstep"
              description={tk(
                'There is a fixed cost for a range of units purchased. For example, 1,000-2,000 units may cost total USD 100.00, so purchasing 1,200 or 1,600 units will have the same cost. You can specify multiple steps, or tiers with different charges.'
              )}
              isChecked={productDraft.pricing === 'stairstep'}
              isDisabled={formIsDisabled || blockKeyPropEdit}
              label={tk('Stair-step')}
              onCheck={() => handlePricingChange('stairstep')}
            />
          </FormField>
        )}

        {productDraft.recurrence !== 'one_time' && !isBundle && (
          <FormField
            infoTooltip={tk(
              'Billing behaviour cannot be changed on products with any version in-use.'
            )}
            label={tk('Billing period')}
            labelClassName={styles.labelWithInfo}
          >
            <ProductRadioButtonOption
              dataTestId="billing-scheduled"
              description={tk(
                'Product price and schedule will be determined from the billing periods available on the proposal.'
              )}
              isChecked={productDraft.billing === 'scheduled'}
              isDisabled={formIsDisabled || blockKeyPropEdit}
              label={tk('Scheduled')}
              onCheck={() =>
                setProductDraft((prev) => ({
                  ...prev,
                  billing: 'scheduled',
                }))
              }
            />
            <ProductRadioButtonOption
              dataTestId="billing-fixed"
              description={tk(
                'Product price and schedule will be fixed to the one billing period price configured below.'
              )}
              isChecked={productDraft.billing === 'fixed'}
              isDisabled={formIsDisabled || blockKeyPropEdit}
              label={tk('Fixed')}
              onCheck={() =>
                setProductDraft((prev) => {
                  const billingPeriods = [prev.billingPeriods?.[0]].filter(
                    isDefined
                  );
                  // remove prices for other billing periods that may have been set
                  const entries =
                    prev.entries?.filter(
                      (entry) => entry.billingPeriod === billingPeriods[0]
                    ) ?? [];
                  return {
                    ...prev,
                    billing: 'fixed',
                    billingPeriods,
                    entries,
                  };
                })
              }
            />
          </FormField>
        )}

        {taxyTurby && !isBundle && (
          <div>
            <ToggleSwitch
              alignLabel="right"
              className={styles.productToggleSwitchWrapper}
              isDisabled={formIsDisabled}
              label={tk('Taxable')}
              onChange={(checked) =>
                setProductDraft((prev) => ({
                  ...prev,
                  taxConfig: checked ? {} : null,
                }))
              }
              value={isTaxConfig(productDraft.taxConfig)}
            />

            {isTaxConfig(productDraft.taxConfig) && (
              <>
                {taxyTurbyMode && (
                  <FormField label={tk('Tax mode')}>
                    <SolidRadio
                      onChange={(value) => {
                        // eslint-disable-next-line no-console
                        console.log(value);
                      }}
                      options={[
                        { name: tk('Tax exclusive'), value: 'exclusive' },
                        { name: tk('Tax inclusive'), value: 'inclusive' },
                      ]}
                      value="exclusive"
                    />
                  </FormField>
                )}
                <FormField label={tk('Tax code')}>
                  <TaxCodeSelect
                    onClearSelected={handleClearSelectedUnit}
                    onSelect={handleSelectTaxCode}
                    selected={productDraft.taxConfig.taxCode}
                  />
                </FormField>
              </>
            )}
          </div>
        )}

        {!isBundle && (
          <FormField label={tk('Unit of measure')}>
            <UnitsSelect
              dataTestId="unit-select"
              onClearSelected={handleClearSelectedUnit}
              onUnitSelect={handleSelectUnit}
              selectedUnitId={productDraft.unitId ?? undefined}
            />
          </FormField>
        )}

        <div className={styles.toggles}>
          <div className={styles.toggleColumn}>
            <div className={styles.labelWithInfo}>{tk('Proposals')}</div>
            <ToggleSwitch
              alignLabel="right"
              className={styles.productToggleSwitchWrapper}
              dataTestId="allow-discounting"
              isDisabled={formIsDisabled}
              label={tk('Allow price adjustments of this product on proposals')}
              onChange={(discountable) =>
                setProductDraft((prev) => ({
                  ...prev,
                  discountable,
                }))
              }
              value={productDraft.discountable ?? undefined}
            />

            <ToggleSwitch
              alignLabel="right"
              className={styles.productToggleSwitchWrapper}
              isDisabled={formIsDisabled}
              label={tk(
                'Allow list price adjustments of this product on proposals'
              )}
              onChange={(listPriceAdjustable) =>
                setProductDraft((prev) => ({
                  ...prev,
                  listPriceAdjustable,
                }))
              }
              value={productDraft.listPriceAdjustable ?? undefined}
            />

            <ToggleSwitch
              alignLabel="right"
              className={styles.productToggleSwitchWrapper}
              dataTestId="allow-name-customization"
              isDisabled={formIsDisabled}
              label={tk('Allow product name to be edited on proposals')}
              onChange={(nameCustomizable) => {
                setProductDraft((prev) => ({
                  ...prev,
                  nameCustomizable,
                }));
              }}
              value={!!productDraft.nameCustomizable}
            />

            <ToggleSwitch
              alignLabel="right"
              className={styles.productToggleSwitchWrapper}
              dataTestId="allow-description-customization"
              isDisabled={formIsDisabled}
              label={tk('Allow product description to be edited on proposals')}
              onChange={(descriptionCustomizable) => {
                setProductDraft((prev) => ({
                  ...prev,
                  descriptionCustomizable,
                }));
              }}
              value={!!productDraft.descriptionCustomizable}
            />

            {isBundle && !enhancedSpans && (
              <ToggleSwitch
                alignLabel="right"
                className={styles.productToggleSwitchWrapper}
                isDisabled={formIsDisabled}
                label={tk('Show bundled products broken out on proposals')}
                onChange={(showProductsOnProposal) => {
                  setProductDraft((prev) => ({
                    ...prev,
                    showProductsOnProposal,
                  }));
                }}
                value={productDraft.showProductsOnProposal ?? undefined}
              />
            )}
          </div>

          <div className={styles.toggleColumn}>
            {isBundle && (
              <FormField
                label={tk('Invoices')}
                labelClassName={styles.labelWithInfo}
              >
                <ToggleSwitch
                  alignLabel="right"
                  className={styles.productToggleSwitchWrapper}
                  isDisabled={formIsDisabled}
                  label={tk('Show bundle products broken out on invoices')}
                  onChange={(showProductsOnInvoice) => {
                    setProductDraft((prev) => ({
                      ...prev,
                      showProductsOnInvoice,
                    }));
                  }}
                  value={productDraft.showProductsOnInvoice ?? undefined}
                />

                <ToggleSwitch
                  alignLabel="right"
                  className={styles.productToggleSwitchWrapper}
                  isDisabled={formIsDisabled}
                  label={tk('Show bundle product prices on invoices')}
                  onChange={(showProductPricesOnInvoice) => {
                    setProductDraft((prev) => ({
                      ...prev,
                      showProductPricesOnInvoice,
                    }));
                  }}
                  value={productDraft.showProductPricesOnInvoice ?? undefined}
                />
              </FormField>
            )}
          </div>
        </div>

        {isBundle && (
          <div className="w-full">
            <div className={styles.labelWithInfo}>{tk('Items')}</div>
            {!isEmpty(itemRequests) && (
              <SimpleTable
                cells={[
                  {
                    key: 'product',
                    allowWrap: true,
                    renderCell: (itemProductPair) =>
                      itemProductPair.product.name,
                  },
                  {
                    key: 'quantity',
                    renderCell: (itemProductPair) => {
                      if (itemProductPair.product.consumption === 'single') {
                        return null;
                      }

                      return (
                        <div className="flex justify-end">
                          <NumberInputAutosize
                            defaultValue={0}
                            onChange={(value) => {
                              setItemRequests((prev) => {
                                if (prev) {
                                  return prev.map((pair) => {
                                    if (
                                      pair.product.id ===
                                      itemProductPair.product.id
                                    ) {
                                      return {
                                        ...pair,
                                        itemRequest: {
                                          ...pair.itemRequest,
                                          quantity: value,
                                        },
                                      };
                                    }
                                    return pair;
                                  });
                                }
                                return prev;
                              });
                            }}
                            suffix={
                              'unit' in itemProductPair.product
                                ? formatUnit(
                                    itemProductPair.product,
                                    itemProductPair.itemRequest.quantity
                                  )
                                : undefined
                            }
                            value={itemProductPair.itemRequest.quantity}
                          />
                        </div>
                      );
                    },
                  },
                  {
                    key: 'percent',
                    renderCell: (itemProductPair) => {
                      const bundleItemRequest: BundleItemRequest = {
                        ...itemProductPair.itemRequest,
                      };

                      return (
                        <div className="flex gap-2 items-baseline justify-end">
                          <div className="whitespace-nowrap">Revenue split</div>
                          <NumberInput
                            dataTestId="bundle-percent"
                            isDisabled={formIsDisabled}
                            onChange={(value) => {
                              setItemRequests((prev) => {
                                if (prev) {
                                  return prev.map((pair) => {
                                    if (
                                      pair.product.id ===
                                      itemProductPair.product.id
                                    ) {
                                      return {
                                        ...pair,
                                        itemRequest: {
                                          ...pair.itemRequest,
                                          bundlePercent: isNumber(value)
                                            ? value * 100
                                            : undefined,
                                        },
                                      };
                                    }
                                    return pair;
                                  });
                                }
                                return prev;
                              });
                            }}
                            status={
                              isBundlePercentagesValid ? undefined : 'error'
                            }
                            suffix="%"
                            value={
                              isNumber(bundleItemRequest.bundlePercent)
                                ? bundleItemRequest.bundlePercent / 100
                                : undefined
                            }
                          />
                        </div>
                      );
                    },
                  },
                  {
                    key: 'actions',
                    renderCell: (itemProductPair) => (
                      <PopOutMenu>
                        <PopOutMenuOption
                          icon={Icon.Close}
                          onClick={() => {
                            setItemRequests((prev) =>
                              prev?.filter(
                                (_existing) =>
                                  _existing.product.id !==
                                  itemProductPair.product.id
                              )
                            );
                          }}
                          title="Remove item"
                        />
                      </PopOutMenu>
                    ),
                    width: '60px',
                  },
                ]}
                disableHeader
                disableSearch
                disableShadow
                rows={itemRequests}
              />
            )}
            <div className={styles.bundleProductInput}>
              <InputError
                errorToShow={
                  isBundlePercentagesValid
                    ? undefined
                    : 'Bundle item percentages must add up to 100%'
                }
              />

              <NewBundleItem
                existingProducts={(itemRequests || []).map(
                  (pair) => pair.product
                )}
                onCreate={(pair) => {
                  setItemRequests((prev) => (prev ? [...prev, pair] : [pair]));
                }}
                recurrence={productDraft.recurrence || 'recurring'}
              />
            </div>
          </div>
        )}

        <PricingConfiguration
          bottomUpEntries={productBeingEdited.bundleBottomUpEntries}
          isDisabled={formIsDisabled}
          productDraft={productDraft}
          setPricingIsValid={setPricingIsValid}
          updateProductDraft={(newProduct) =>
            setProductDraft(() => ({ ...newProduct }))
          }
        />

        {hideQuantityOnInvoice && (
          <>
            <div className={styles.labelWithInfo}>
              {tk('Invoice PDF display options')}
            </div>
            <ToggleSwitch
              alignLabel="right"
              className={styles.productToggleSwitchWrapper}
              dataTestId="show-quantity"
              isDisabled={formIsDisabled}
              label={tk('Show quantity on invoice lines')}
              onChange={(value) =>
                setProductDraft((prev) => ({
                  ...prev,
                  displayQuantityOnInvoicePdf: value,
                }))
              }
              value={productDraft?.displayQuantityOnInvoicePdf ?? undefined}
            />
          </>
        )}
      </div>
    </Page>
  );
};

export default ProductForm;
