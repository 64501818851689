import React from 'react';
import { Link } from 'react-router-dom';

import {
  adjustDateByDays,
  arrayTop,
  Card,
  CardHeader,
  CardRowValue,
  Contract,
  formatDateOrDefault,
  getBillingPeriodLabelAdjective,
  isAcceptedProposalStatus,
  isCancelledContractStatus,
  isDeletedProposalStatus,
  isRenewalProposalType,
  LinkButton,
  pluralize,
  Proposal,
} from 'common';

interface Props {
  contract: Contract;
  contractProposals: Proposal[] | [];
}

const RenewalsCard: React.FC<Props> = ({ contract, contractProposals }) => {
  const hasEligibleRenewalProposal = contractProposals.filter(
    (proposal) =>
      isRenewalProposalType(proposal) &&
      !isAcceptedProposalStatus(proposal) &&
      !isDeletedProposalStatus(proposal)
  );

  const returnNull =
    isCancelledContractStatus(contract) ||
    (!contract.renewalConfiguration && hasEligibleRenewalProposal.length === 0);

  if (returnNull) {
    return null;
  }

  let content;

  // Manual renewal takes precedence over automatic renewal if both are present
  const nonAppliedRenewalProposal = arrayTop(hasEligibleRenewalProposal);
  if (nonAppliedRenewalProposal) {
    content = (
      <div>
        <CardRowValue label="Type">One-time</CardRowValue>
        <CardRowValue label="Proposal">
          <Link to={`/proposals/${nonAppliedRenewalProposal.id}`}>
            <LinkButton>#{nonAppliedRenewalProposal.proposalNumber}</LinkButton>
          </Link>
        </CardRowValue>
        <CardRowValue label="Effective date">
          {formatDateOrDefault(contract.endDate)}
        </CardRowValue>
        <CardRowValue label="Renewal term length">
          {pluralize(
            nonAppliedRenewalProposal.termQty,
            nonAppliedRenewalProposal.termType
          )}
        </CardRowValue>
        <CardRowValue label="Payments">
          {getBillingPeriodLabelAdjective(contract.billingPeriod)}
        </CardRowValue>
      </div>
    );
  } else if (contract.renewalConfiguration) {
    content = (
      <div>
        <CardRowValue label="Type">Automatic</CardRowValue>
        <CardRowValue label="Effective date">
          {formatDateOrDefault(adjustDateByDays(contract.endDate, 1))}
        </CardRowValue>
        <CardRowValue label="Renewal term length">
          {pluralize(contract.renewalConfiguration.termLengthMonths, 'month')}
        </CardRowValue>
        <CardRowValue label="Cancellation deadline">
          {pluralize(
            contract.renewalConfiguration.cancellationDeadlineDays,
            'day'
          )}
        </CardRowValue>
        {contract.renewalConfiguration.priceIncrease && (
          <CardRowValue label="Auto price increase">
            {contract.renewalConfiguration.priceIncrease + '%'}
          </CardRowValue>
        )}
        <CardRowValue label="Payments">
          {getBillingPeriodLabelAdjective(contract.billingPeriod)}
        </CardRowValue>
      </div>
    );
  }

  return (
    <Card>
      <CardHeader name="Renewal" />
      {content}
    </Card>
  );
};

export default RenewalsCard;
