import React from 'react';
import { Modal } from 'common';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoiceVoidConfirmationModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  return (
    <Modal
      header="RE-SYNC INVOICE"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      You must re-sync invoice to QuickBooks online after changing the status
      from void to un-voided.
    </Modal>
  );
};

export default InvoiceVoidConfirmationModal;
