import React, { SVGProps } from 'react';

const MicrosoftSVG: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          fill="#26A6D1"
          d="M117.547,266.156L0,249.141v-94.296h117.547V266.156z"
        />
        <path
          fill="#3DB39E"
          d="M291.346,136.51H136.31l0.055-114.06L291.346,0.009V136.51z"
        />
        <path
          fill="#F4B459"
          d="M291.346,291.337l-155.091-22.459l0.182-114.015h154.909V291.337z"
        />
        <path
          fill="#E2574C"
          d="M117.547,136.51H0V42.205l117.547-17.024V136.51z"
        />
      </g>
    </g>
  </svg>
);

export default MicrosoftSVG;
