import React, { useState } from 'react';
import { Select } from 'antd';

import {
  ApprovalRule,
  Button,
  ConfirmDeleteModal,
  Icon,
  isNumber,
  NumberInput,
  ProposalDiscountPercentRuleConfig,
  ProposalSummary,
} from 'common';

import { GroupOption, searchFilterOption } from './_approvalRulesConfig';

import styles from './ApprovalRuleStyles.module.scss';
import { getConfig } from './ruleUtils';

interface Props {
  allGroupOptions: GroupOption[];
  approvalRule: ApprovalRule;
  conditionOperator: string | string[];
  configKey: string;
  description: string;
  onRuleChange: (id: string, changedRule: ApprovalRule | null) => void;
  proposalType: ProposalSummary['proposalType'];
}

const DEFAULT_PERCENT = 3000;

export const ApprovalRulePercentRow = ({
  approvalRule,
  proposalType,
  onRuleChange,
  allGroupOptions,
  configKey,
  conditionOperator,
  description,
}: Props) => {
  const getValue = (): number => {
    const config = getConfig<ProposalDiscountPercentRuleConfig>(approvalRule);

    const configValue =
      config && typeof config === 'object' && configKey in config
        ? config[configKey as keyof ProposalDiscountPercentRuleConfig]
        : undefined;

    return [configValue, DEFAULT_PERCENT].find(isNumber) || 0;
  };

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const [value, setValue] = useState<number>(getValue());
  const [showSelectError, setShowSelectError] = useState<boolean>(false);

  const updateRule = (newValue: number): ApprovalRule => ({
    id: approvalRule.id,
    name: approvalRule.name,
    config: {
      ruleType: approvalRule.config?.ruleType!,
      proposalType,
      [configKey]: newValue,
    },
  });

  const handleValueChange = (newValue: number | undefined = 0) => {
    const convertedValued = newValue * 100;
    setValue(convertedValued);
  };

  const handleSave = () => {
    onRuleChange(approvalRule.id!, updateRule(value));
  };

  const onGroupsChange = (groupIds: string[]) => {
    setShowSelectError(groupIds.length === 0);

    const payload = {
      ...updateRule(value),
      approvalGroupIds: groupIds,
    };

    onRuleChange(approvalRule.id!, payload);
  };

  return (
    <div className={styles.ruleRow}>
      <div className={styles.name}>
        <Button
          primaryIcon={Icon.CloseCircle}
          onClick={() => setIsDeleteConfirmationOpen(true)}
          type="secondary"
          className={styles.removeIcon}
        />
        <span className={styles.ruleName}>{description}</span>
      </div>

      <div className={styles.condition}>{conditionOperator}</div>

      <div className={styles.input}>
        <NumberInput
          defaultValue={0}
          value={value / 100}
          isDisabled={!approvalRule}
          onChange={handleValueChange}
          onBlur={handleSave}
          suffix="%"
          max={100}
          dataTestId={`${approvalRule.name}-value`}
        />
      </div>

      <div className={styles.groups}>
        {allGroupOptions.length > 0 && (
          <Select
            onChange={onGroupsChange}
            value={approvalRule.approvalGroupIds?.map((id) => id)}
            options={allGroupOptions}
            mode="multiple"
            filterOption={searchFilterOption}
            className={styles.groupWidth}
            placeholder="Select approval group"
            disabled={!approvalRule}
            data-testid={`${approvalRule.name}-dropdown`}
            status={showSelectError ? 'error' : ''}
          />
        )}
      </div>

      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onConfirm={() => onRuleChange(approvalRule.id!, null)}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        typeName="condition"
      />
    </div>
  );
};
