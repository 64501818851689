import React from 'react';
import {
  BillingItem,
  BillingSchedule,
  Card,
  CardHeader,
  Cell,
  Contract,
  formatCurrencyOrDefault,
  formatDateOrDefault,
  formatNullableFormattedAmount,
  formatNumberOrDefault,
  formatRichText,
  getOrDefault,
  Invoice,
  SimpleTable,
  useFlags,
} from 'common';
import { useBatchProducts } from 'app/src/services/api/products/products';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  invoice: Invoice;
  schedule: BillingSchedule;
  subscription: Contract;
}

const InvoiceChargesCard: React.FC<Props> = ({
  invoice,
  schedule,
  subscription,
}) => {
  if (!schedule.billingItems.length) {
    return null;
  }

  const { taxyTurby } = useFlags();
  const queryClient = useQueryClient();

  const cells: Cell<InvoiceItem>[] = [
    {
      key: 'name',
      width: '20%',
      headerLabel: 'Products',
      allowWrap: true,
      renderCell: (item: InvoiceItem) => (
        <>
          <div className="font-semibold truncate">
            {getOrDefault(item.name)}
          </div>
          <div className="text-xs">{formatRichText(item.description)}</div>
        </>
      ),
    },
    {
      key: 'servicePeriod',
      headerLabel: 'Service period',
      width: '20%',
      align: 'center',
      allowWrap: true,
      renderCell: (item: InvoiceItem) => {
        const target = item.billingItem
          ? item.billingItem
          : item.bundleItems?.[0];

        if (!target) {
          return null;
        }

        return target.serviceStartDate === target.serviceEndDate
          ? formatDateOrDefault(target.serviceStartDate)
          : `${formatDateOrDefault(
              target.serviceStartDate
            )} - ${formatDateOrDefault(target.serviceEndDate)}`;
      },
    },
    {
      key: 'quantity',
      headerLabel: 'Quantity',
      width: '10%',
      align: 'center',
      allowWrap: true,
      renderCell: (item: InvoiceItem) =>
        formatNumberOrDefault(
          item.billingItem ? item.billingItem.quantity : undefined
        ),
    },
    {
      key: 'subtotal',
      headerLabel: 'Subtotal',
      width: '10%',
      align: 'center',
      renderCell: (item: InvoiceItem) => {
        let subtotal: number;
        if (item.billingItem) {
          return item.billingItem.amountWithoutTaxFormatted;
        } else {
          subtotal = item.bundleItems!.reduce(
            (sum, current) => sum + current.amountWithoutTax,
            0
          );
        }

        return formatCurrencyOrDefault(item.currency, subtotal);
      },
    },
  ];
  if (taxyTurby) {
    cells.push({
      key: 'tax',
      headerLabel: 'Tax',
      width: '10%',
      align: 'center',
      renderCell: (item: InvoiceItem) => {
        let subtotal: number;
        if (item.billingItem) {
          return formatNullableFormattedAmount(
            item.billingItem.taxAmountFormatted
          );
        } else {
          subtotal = item.bundleItems!.reduce(
            (sum, current) => sum + current.taxAmount!,
            0
          );
        }

        return formatCurrencyOrDefault(item.currency, subtotal);
      },
    });
  }

  cells.push({
    key: 'total',
    headerLabel: 'Total',
    width: '10%',
    align: 'center',
    renderCell: (item: InvoiceItem) => {
      let subtotal: number;
      if (item.billingItem) {
        return formatNullableFormattedAmount(
          item.billingItem.amountDueFormatted
        );
      } else {
        subtotal = item.bundleItems!.reduce(
          (sum, current) => sum + current.amountDue,
          0
        );
      }

      return formatCurrencyOrDefault(item.currency, subtotal);
    },
  });

  interface InvoiceItem {
    billingItem?: BillingItem;
    bundleItemId?: string;
    bundleItems?: BillingItem[];
    currency: string;
    description?: string;
    name?: string;
  }

  const bundleIds = new Set(
    schedule.billingItems.filter((bi) => bi.bundle).map((bi) => bi.bundle!.id!)
  );

  const { data: products, isLoading } = useBatchProducts(
    queryClient,
    [...bundleIds],
    bundleIds.size > 0
  );

  const closedBundles = new Map(
    products?.filter((p) => !p.showProductsOnInvoice).map((p) => [p.id, p])
  );

  const items: InvoiceItem[] = [];
  if (!isLoading) {
    schedule.billingItems.forEach((bi) => {
      if (bi.bundle?.id && closedBundles.has(bi.bundle.id)) {
        const existing = items.find((ii) => ii.bundleItemId === bi.itemId);
        if (existing) {
          existing.bundleItems!.push(bi);
        } else {
          items.push({
            bundleItems: [bi],
            bundleItemId: bi.itemId,
            name: bi.bundleName,
            description: bi.bundleDescription,
            currency: bi.currency,
          });
        }
      } else {
        items.push({
          billingItem: bi,
          name: bi.name,
          description: bi.description,
          currency: bi.currency,
        });
      }
    });
  }

  return (
    <Card>
      <CardHeader name="Charges" />
      <SimpleTable
        disableSearch
        disableShadow
        cells={cells}
        rows={items}
        isLoading={isLoading}
        zeroStateMessage="There are currently no items for invoice."
      />

      <div className="w-full flex flex-col justify-end items-end pr-4 text-md pt-4">
        <div className="flex flex-row">
          Subtotal:{' '}
          <span className="w-[150px] text-right pr-2">
            {formatNullableFormattedAmount(schedule.amountWithoutTaxFormatted)}
          </span>{' '}
          {subscription.currency}
        </div>
        {taxyTurby && (
          <div className="flex flex-row">
            Tax:{' '}
            <span className="w-[150px] text-right pr-2">
              {schedule.taxAmountFormatted}
            </span>
            {subscription.currency}
          </div>
        )}
        <div className="flex flex-row">
          Total:{' '}
          <span className="w-[150px] text-right pr-2">
            {formatNullableFormattedAmount(schedule.amountDueFormatted)}
          </span>{' '}
          {subscription.currency}
        </div>
        <div className="flex flex-row">
          Transactions:{' '}
          <span className="w-[150px] text-right pr-2">
            (
            {formatNullableFormattedAmount(
              schedule.transfer?.paidAmountFormatted
            )}
            )
          </span>{' '}
          {subscription.currency}
        </div>
        <div className="flex flex-row">
          Balance:{' '}
          <span className="w-[150px] text-right pr-2">
            {formatNullableFormattedAmount(invoice.balanceFormatted)}
          </span>{' '}
          {subscription.currency}
        </div>
      </div>
    </Card>
  );
};

export default InvoiceChargesCard;
