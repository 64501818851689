import React from 'react';
import PaymentInfoCard from './PaymentInfoCard';
import { PaymentItemProps } from './PaymentList';
import styles from './PaymentInfoSection.module.scss';

interface Props {
  paymentInfoData: PaymentItemProps[];
  snapshotData?: PaymentItemProps[] | undefined;
}

const PaymentInfoSection: React.FC<Props> = ({
  paymentInfoData,
  snapshotData,
}) => {
  return (
    <div className={styles.paymentInfoSectionContainer}>
      <PaymentInfoCard
        title="Payment information"
        paymentItem={paymentInfoData}
      />
      {snapshotData ? (
        <PaymentInfoCard title="Snapshot" paymentItem={snapshotData} />
      ) : null}
    </div>
  );
};

export default PaymentInfoSection;
