import React, { useState } from 'react';
import {
  BillingPeriodsType,
  CustomizeNonTieredPriceModal,
  CustomizeTieredPriceModal,
  isProductNonTiered,
  isProductTiered,
  Item,
  ItemSchedule,
  Proposal,
  Tabs,
  ModeTypes,
} from 'common';
import ProposalSection from '../ProposalSection/ProposalSection';
import { AddItemScheduleProvider } from './AddItemScheduleProvider';
import { ConfirmDisableInvoiceInAdvanceModal } from './ConfirmDisableInvoiceInAdvanceModal';
import { DeleteItemScheduleProvider } from './DeleteItemScheduleProvider';
import ProductDeltas from './ProductDeltas';
import { UpdateItemScheduleProvider } from './UpdateItemScheduleProvider';
import { UpdateProposalItemProvider } from './UpdateProposalItemProvider';
import { useAddProduct } from './useAddProduct';
import ProductItemEditorTab from './ProductItemEditorTab';
import styles from './ProductsSection.module.scss';
import { useProposalConfig } from '../../../../services/orgDefaults';
import { useGetProposalDeltas } from '../../../../services/proposal';

type ModalDefaults =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      item: Item;
      selectedSchedule: ItemSchedule;
      initialMode: ModeTypes;
    };

const priceModalDefaults: ModalDefaults = {
  isOpen: false,
};

interface Props {
  billingPeriod: BillingPeriodsType;
  isDisabled: boolean;
  proposal: Proposal;
}

const ProductDeltasTab = ({
  billingPeriod,
  proposal,
}: {
  proposal: Proposal;
  billingPeriod: BillingPeriodsType;
}) => (
  <div className={styles.productsSection}>
    <ProductDeltas billingPeriod={billingPeriod} proposal={proposal} />
  </div>
);

const ProductsSection: React.FC<Props> = ({
  billingPeriod,
  isDisabled,
  proposal,
}) => {
  const [priceModal, setPriceModal] =
    useState<ModalDefaults>(priceModalDefaults);

  const [selectedTab, setSelectedTab] = useState<string>('all');

  const { showDiscountViewAsDefault: showDiscount } = useProposalConfig();

  const {
    isInvoiceInAdvanceModalVisible,
    hideInvoiceInAdvanceModal,
    addProductHandler,
  } = useAddProduct(proposal);

  const handleShowPriceModalClick = (
    selectedSchedule: ItemSchedule,
    item: Item,
    initialMode: ModeTypes = 'view'
  ) => {
    setPriceModal({
      isOpen: true,
      selectedSchedule,
      item,
      initialMode,
    });
  };

  const tabs = (
    <div className={styles.tabs}>
      <Tabs
        defaultActiveTab={selectedTab}
        onChange={setSelectedTab}
        tabs={[
          {
            label: 'All',
            key: 'all',
            component: null,
          },
          {
            label: 'Changes only',
            key: 'changes',
            component: null,
          },
        ]}
      />
    </div>
  );

  const { data: deltas } = useGetProposalDeltas(proposal.id);

  const isProposalChanged =
    proposal.proposalType === 'change' && (deltas ?? []).length > 0;

  return (
    <AddItemScheduleProvider proposalId={proposal.id}>
      <DeleteItemScheduleProvider proposalId={proposal.id}>
        <UpdateItemScheduleProvider proposalId={proposal.id}>
          <UpdateProposalItemProvider proposalId={proposal.id}>
            <ProposalSection
              headerActions={isProposalChanged ? tabs : null}
              isDisabled={isDisabled}
              isRemovable={false}
              noPadding
              openOnLoad
              section="Products"
              sectionRef="ProductsV2"
              title="Products"
            >
              {selectedTab === 'all' ? (
                <ProductItemEditorTab
                  addProductHandler={addProductHandler}
                  ariaLabeledBy="ProductsV2"
                  billingPeriod={billingPeriod}
                  handleShowPriceModalClick={handleShowPriceModalClick}
                  isDisabled={isDisabled}
                  proposal={proposal}
                />
              ) : (
                <ProductDeltasTab
                  billingPeriod={billingPeriod}
                  proposal={proposal}
                />
              )}
            </ProposalSection>

            {priceModal.isOpen && isProductTiered(priceModal.item.product) && (
              <CustomizeTieredPriceModal
                closeModal={() => setPriceModal(priceModalDefaults)}
                initialMode={priceModal.initialMode}
                isOpen={priceModal.isOpen}
                isViewOnlyMode={isDisabled}
                selectedItem={priceModal.item}
                selectedSchedule={priceModal.selectedSchedule}
                showDiscountAsDefault={showDiscount}
              />
            )}

            {priceModal.isOpen &&
              isProductNonTiered(priceModal.item.product) && (
                <CustomizeNonTieredPriceModal
                  closeModal={() => setPriceModal(priceModalDefaults)}
                  isOpen={priceModal.isOpen}
                  proposal={proposal}
                  selectedSchedule={priceModal.selectedSchedule}
                  showDiscountAsDefault={showDiscount}
                />
              )}

            <ConfirmDisableInvoiceInAdvanceModal
              isOpen={isInvoiceInAdvanceModalVisible}
              onConfirm={hideInvoiceInAdvanceModal}
            />
          </UpdateProposalItemProvider>
        </UpdateItemScheduleProvider>
      </DeleteItemScheduleProvider>
    </AddItemScheduleProvider>
  );
};

export default ProductsSection;
