import React from 'react';
import clsx from 'clsx';
import Header from './Header/Header';
import styles from './Page.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  leftWidget?: React.ReactNode;
  rightWidget?: React.ReactNode;
}

const Page: React.FC<Props> = ({
  leftWidget,
  rightWidget,
  children,
  className,
}) => {
  const hasHeader: boolean = Boolean(leftWidget || rightWidget);

  return (
    <>
      {hasHeader && (
        <Header leftWidget={leftWidget} rightWidget={rightWidget} />
      )}

      <div
        className={clsx(
          className,
          styles.content,
          hasHeader && styles.hasHeader
        )}
      >
        {children}
      </div>
    </>
  );
};

export default Page;
