import React, { useState } from 'react';
import { Select } from 'antd';

import {
  ApprovalRule,
  Button,
  ConfirmDeleteModal,
  Icon,
  ProposalSummary,
} from 'common';

import { GroupOption, searchFilterOption } from './_approvalRulesConfig';

import styles from './ApprovalRuleStyles.module.scss';

interface Props {
  allGroupOptions: GroupOption[];
  approvalRule: ApprovalRule | undefined;
  conditionOperator: string | string[];
  description: string;
  onRuleChange: (id: string, changedRule: ApprovalRule | null) => void;
  proposalType: ProposalSummary['proposalType'];
}

export const ApprovalRuleBooleanRow = ({
  approvalRule,
  description,
  onRuleChange,
  proposalType,
  conditionOperator,
  allGroupOptions,
}: Props) => {
  const updateRule = (): ApprovalRule => ({
    id: approvalRule?.id,
    name: approvalRule?.name,
    config: {
      ruleType: approvalRule?.config?.ruleType!,
      proposalType,
    },
  });

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [showSelectError, setShowSelectError] = useState<boolean>(false);

  const onGroupsChange = (groupIds: string[]) => {
    setShowSelectError(groupIds.length === 0);

    const payload = {
      ...updateRule(),
      approvalGroupIds: groupIds,
    };

    onRuleChange(approvalRule?.id!, payload);
  };

  return (
    <div className={styles.ruleRow}>
      <div className={styles.name}>
        <Button
          primaryIcon={Icon.CloseCircle}
          onClick={() => setIsDeleteConfirmationOpen(true)}
          type="secondary"
          className={styles.removeIcon}
        />
        <span className={styles.ruleName}>{description}</span>
      </div>

      <div className={styles.condition}>{conditionOperator}</div>

      <div className={styles.input} />

      <div className={styles.groups}>
        {allGroupOptions.length > 0 && (
          <Select
            onChange={onGroupsChange}
            value={approvalRule?.approvalGroupIds?.map((id) => id)}
            options={allGroupOptions}
            mode="multiple"
            filterOption={searchFilterOption}
            className={styles.groupWidth}
            placeholder="Select approval group"
            disabled={!approvalRule}
            status={showSelectError ? 'error' : ''}
          />
        )}
      </div>

      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onConfirm={() => onRuleChange(approvalRule?.id!, null)}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        typeName="condition"
      />
    </div>
  );
};
