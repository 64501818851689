import React from 'react';
import {
  capitalizeFirstLetter,
  Icon,
  isAutoRenewedGeneratedProposal,
  LinkButton,
  Proposal,
} from 'common';
import { useNavigate } from 'react-router-dom';
import { useContract } from '../../../services/contract';

interface Props {
  proposal: Proposal;
}

const SubscriptionLink: React.FC<Props> = ({ proposal }) => {
  const contractId: string | undefined = proposal.contract?.id;
  const { proposalType } = proposal;

  const isAutoGenerated = isAutoRenewedGeneratedProposal(proposal);

  if (!contractId || proposalType === 'initial') {
    return null;
  }

  const navigate = useNavigate();
  const { data: contract, isLoading } = useContract(contractId);
  const onClick = () => navigate(`/subscriptions/${contractId}`);

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex items-center justify-center mb-4">
      <LinkButton onClick={onClick}>
        Subscription No. {contract?.contractNumber}
      </LinkButton>{' '}
      <Icon.ArrowRight height="22px" width="22px" />{' '}
      {isAutoGenerated
        ? 'Auto-renewal proposal'
        : `${capitalizeFirstLetter(proposalType)} proposal`}
    </div>
  );
};

export default SubscriptionLink;
