import React, { useState } from 'react';
import EditContactDrawer from './EditContactDrawer';
import NewContactDrawer from './NewContactDrawer';
import {
  captureError,
  Contact,
  Drawer,
  DrawerHeader,
  hasAnAcceptedProposalStatus,
  isDefined,
  Proposal,
} from 'common';
import ShareProposalView from './ShareProposalView';
import { clearProposalCache } from '../../../../services/proposal';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  onClose: () => void;
  onRecipientsChange: (recipientIds: string[]) => void;
  proposal: Proposal;
}

enum DrawerView {
  none,
  editContact,
  createContact,
}

const ShareProposalPanel: React.FC<Props> = ({
  onClose,
  proposal,
  onRecipientsChange,
}) => {
  const [activeViewKey, setActiveViewKey] = useState(DrawerView.none);
  const [newContactStarterName, setNewContactStarterName] = useState<
    string | undefined
  >(undefined);
  const [contactBeingEdited, setContactBeingEdited] = useState<
    Contact | undefined
  >(undefined);
  const queryClient = useQueryClient();

  const handleContactEditClick = (contact: Contact) => {
    setContactBeingEdited(contact);
    setActiveViewKey(DrawerView.editContact);
  };

  const closeDrawer = () => {
    setActiveViewKey(DrawerView.none);
    onClose();
  };

  const addCreatedContactToRecipients = (contactId: string) => {
    const recipientIds = proposal.proposalContacts
      .map((c) => c.contact?.id)
      .filter(isDefined);
    // dedupe contact ids
    onRecipientsChange([...new Set([...recipientIds, contactId])]);
    setActiveViewKey(DrawerView.none);
  };

  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);

  return (
    <div data-testid="drawer-share-proposal">
      <DrawerHeader onClose={onClose}>Share proposal</DrawerHeader>
      <div className="mt-6">
        <ShareProposalView
          proposal={proposal}
          toCreateContact={(contactStarterName) => {
            setNewContactStarterName(contactStarterName);
            setActiveViewKey(DrawerView.createContact);
          }}
          handleContactEditClick={handleContactEditClick}
          handleContactsChange={onRecipientsChange}
          onClose={onClose}
        />
      </div>
      <Drawer
        isOpen={activeViewKey !== DrawerView.none}
        header={hasAnAcceptedStatus ? 'View recipients' : 'Share proposal'}
        onClose={closeDrawer}
        noPaddingX
      >
        <EditContactDrawer
          onClose={async () => {
            if (contactBeingEdited) {
              await clearProposalCache(proposal.id, queryClient);
            }
            setActiveViewKey(DrawerView.none);
          }}
          contactBeingEdited={contactBeingEdited}
          proposal={proposal}
          isOpen={activeViewKey === DrawerView.editContact}
        />

        <NewContactDrawer
          onClose={() => {
            setActiveViewKey(DrawerView.none);
            setNewContactStarterName(undefined);
          }}
          onCreate={(contact) => {
            if (!contact.id) {
              captureError('Contact created without an id');
              setActiveViewKey(DrawerView.none);
            } else {
              addCreatedContactToRecipients(contact.id);
            }
            setNewContactStarterName(undefined);
          }}
          proposal={proposal}
          isOpen={activeViewKey === DrawerView.createContact}
          contactStarterName={newContactStarterName}
        />
      </Drawer>
    </div>
  );
};

export default ShareProposalPanel;
