import { useOrgDefaults } from 'app/src/services/orgDefaults';
import { usePaymentMethods } from 'app/src/services/paymentMethods';
import {
  CreateProposalRequest,
  getTermTypeLength,
  Icon,
  Input,
  isChangeProposalType,
  Loading,
  Nullable,
  NumberInput,
  PaymentSchedule,
  PaymentTerm,
  Proposal,
  ProposalLabel,
  PurchaseOrderConfig,
  ToggleSwitch,
  ToolTip,
  useFlags,
  useTranslation,
} from 'common';
import React, { useState } from 'react';
import './paymentPlans.scss';
import BillToSelect from '../BillTo/BillToSelect';
import PaymentTermSelect from '../PaymentTerm/PaymentTermSelect';
import ShipFromSelect from '../ShipFrom/ShipFromSelect';
import { InvoiceInAdvance } from './InvoiceInAdvance';
import PaymentPlanOptions from './PaymentPlanOptions';
import PaymentPlanOptionsEditDrawer from './PaymentPlanOptionsEditDrawer';
import Payments from './Payments';

interface Props {
  isDisabled: boolean;
  paymentSchedule: PaymentSchedule;
  proposal: Proposal;
  selectBillTo: (billTo?: string) => void;
  selectPaymentPlanIndex: (index: number) => void;
  selectPaymentTerm?: (paymentTerm: PaymentTerm['termType'] | null) => void;
  selectShipFrom: (shipFrom?: string) => void;
  setProposalField: (
    field: keyof CreateProposalRequest,
    value: Nullable<CreateProposalRequest>[keyof CreateProposalRequest]
  ) => void;
}

const PaymentPlanWrapper: React.FC<Props> = ({
  isDisabled,
  paymentSchedule,
  proposal,
  selectPaymentPlanIndex,
  selectPaymentTerm,
  selectBillTo,
  selectShipFrom,
  setProposalField,
}) => {
  const { data: orgDefaults } = useOrgDefaults();

  const { enabled: poCollectionEnabled } =
    (orgDefaults?.get('purchaseOrderConfig')
      ?.configValue as PurchaseOrderConfig) || false;

  const { tk } = useTranslation();

  const { enableChangeProposalPaymentMethods } = useFlags();

  const [isEditingPo, setIsEditingPo] = React.useState<boolean | undefined>(
    proposal.options?.collectPurchaseOrderNumber
  );

  const [isPaymentOptionsEditPanelOpen, setIsPaymentOptionsEditPanelOpen] =
    useState<boolean>(false);

  const openPaymentOptionsEditPanel = () => {
    setIsPaymentOptionsEditPanelOpen(true);
  };

  const closePaymentOptionsEditPanel = () => {
    setIsPaymentOptionsEditPanelOpen(false);
  };
  const isChange = isChangeProposalType(proposal);
  const onFreeMonthsChange = (value: number | undefined) => {
    setProposalField('freeMonths', value === undefined ? null : value);
  };

  const {
    data: paymentMethods,
    isLoading: isLoadingPaymentMethods,
    isError: isErrorPaymentMethods,
  } = usePaymentMethods(true, proposal.currency);

  const onPurchaseOrderChange = (value: string | undefined) => {
    if (value !== proposal.purchaseOrderNumber) {
      setProposalField(
        'purchaseOrderNumber',
        value === undefined ? null : value
      );
    }
  };

  const checkStatesPriorToRenderingPo = () => {
    if (
      (proposal.proposalType === 'change' ||
        proposal.proposalType === 'renewal') &&
      proposal.displayStatus === 'draft' &&
      proposal.options?.collectPurchaseOrderNumber
    ) {
      return true;
    } else if (
      (proposal.displayStatus === 'completed' ||
        proposal.displayStatus === 'shared_active' ||
        proposal.displayStatus === 'approved') &&
      proposal.options?.collectPurchaseOrderNumber
    ) {
      return true;
    } else if (
      proposal.displayStatus !== 'completed' &&
      proposal.displayStatus !== 'shared_active' &&
      proposal.displayStatus !== 'approved' &&
      (proposal.options?.collectPurchaseOrderNumber || poCollectionEnabled)
    ) {
      return true;
    }
    return false;
  };

  const onPOneededChange = (value: boolean) => setIsEditingPo(value);

  if (isLoadingPaymentMethods) {
    return <Loading />;
  }

  if (isErrorPaymentMethods || !paymentMethods) {
    return (
      <div className="payment-schedules-error flex-col flex items-center">
        <Icon.Exclamationmark />
        <p>Error loading payment methods...</p>
      </div>
    );
  }

  const renderPo = () => (
    <>
      <div className="payment-terms-wrapper mainPoWrapper">
        <ToggleSwitch
          isDisabled={isDisabled}
          dataTestId="productIsActive"
          onChange={(value) => {
            setProposalField('options', {
              collectPurchaseOrderNumber: value,
            });
            onPOneededChange(value);
          }}
          value={isEditingPo}
        />
        <p className="ml-2 font-medium">Collect PO number</p>

        <ToolTip
          placement="top"
          title="Enable or disable purchase order number collection"
        >
          <Icon.Information className="ml-1.5" height={30} width={30} />
        </ToolTip>
      </div>

      <div className="payment-terms-wrapper poInputWrapper">
        <div className="w-[200px]">
          {isEditingPo && (
            <>
              <ProposalLabel name={tk('PO Number')} />
              <Input
                isDisabled={isDisabled}
                value={proposal.purchaseOrderNumber}
                onBlur={onPurchaseOrderChange}
                maxLength={20}
              />
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div className="payment-plans">
      <div className="flex gap-6">
        <PaymentTermSelect
          proposal={proposal}
          isDisabled={isDisabled}
          selectPaymentTerm={selectPaymentTerm}
        />
        <div className="payment-terms-wrapper pb-4 flex-col">
          <ProposalLabel name={tk('Free months')} />
          <div style={{ width: '140px' }}>
            <NumberInput
              dataTestId="freeMonthQty"
              isDisabled={isDisabled || isChange}
              min={0}
              defaultValue={undefined}
              max={proposal.termQty * getTermTypeLength(proposal.termType)}
              value={proposal.freeMonths}
              onBlur={onFreeMonthsChange}
            />
          </div>
        </div>

        {checkStatesPriorToRenderingPo() ? renderPo() : null}
      </div>
      <div className="flex gap-6">
        <ShipFromSelect
          proposal={proposal}
          selectShipFrom={selectShipFrom}
          isDisabled={isDisabled}
        />
        <BillToSelect
          proposal={proposal}
          selectBillTo={selectBillTo}
          isDisabled={isDisabled}
        />
      </div>

      <InvoiceInAdvance proposal={proposal} />

      <div className="payment-plans-wrapper">
        <PaymentPlanOptions
          allPaymentMethods={paymentMethods}
          isDisabled={
            enableChangeProposalPaymentMethods
              ? isDisabled
              : isDisabled || isChange
          }
          focusedPaymentSchedule={paymentSchedule}
          openPaymentOptionsEditPanel={openPaymentOptionsEditPanel}
          proposal={proposal}
          selectPaymentPlanIndex={selectPaymentPlanIndex}
        />
        <div className="payment-plans-details">
          <div className="payment-plans-details-body">
            <div>
              <Payments paymentSchedule={paymentSchedule} proposal={proposal} />
            </div>
          </div>
        </div>
      </div>

      <PaymentPlanOptionsEditDrawer
        allPaymentMethods={paymentMethods}
        closePaymentOptionsEditPanel={closePaymentOptionsEditPanel}
        isPaymentOptionsEditPanelOpen={isPaymentOptionsEditPanelOpen}
        proposal={proposal}
        setProposalField={setProposalField}
      />
    </div>
  );
};

export default PaymentPlanWrapper;
