import React from 'react';
import {
  formatDatetime,
  Loading,
  ProposalSummaryAnalytics,
  SimpleCell,
  SimpleTable,
} from 'common';
import ErrorHandler from '../../components/ErrorHandler';
import { useSystemTodayProposals } from '../../services/api/system/proposalsToday';
import Page from '../../components/Page';

const SystemProposalsPage: React.FC = () => {
  const {
    isLoading,
    error,
    isError,
    data: proposals,
  } = useSystemTodayProposals();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorHandler error={error} />;
  }

  const cells: SimpleCell<ProposalSummaryAnalytics>[] = [
    {
      key: 'orgId',
      headerLabel: 'Org ID',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: ProposalSummaryAnalytics) => <div>{data.orgId}</div>,
    },
    {
      key: 'orgName',
      headerLabel: 'Org name',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: ProposalSummaryAnalytics) => <div>{data.orgName}</div>,
    },
    {
      key: 'customername',
      headerLabel: 'Customer name',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: ProposalSummaryAnalytics) => (
        <div>{data.customerName}</div>
      ),
    },
    {
      key: 'paymentType',
      headerLabel: 'Payment type',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (data: ProposalSummaryAnalytics) => (
        <div>{data.paymentType || data.billingMethodName}</div>
      ),
    },

    {
      key: 'amount',
      headerLabel: 'Amount',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (data: ProposalSummaryAnalytics) => (
        <div>{data.amountFormatted}</div>
      ),
    },

    {
      key: 'status',
      headerLabel: 'Status',
      width: '15%',
      searchable: true,
      sortable: true,
      renderCell: (data: ProposalSummaryAnalytics) => <div>{data.status}</div>,
    },

    {
      key: 'signedAt',
      headerLabel: 'Signed at',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (data: ProposalSummaryAnalytics) => (
        <div>{data.signedAt && formatDatetime(data.signedAt)}</div>
      ),
    },
    {
      key: 'viewedAt',
      headerLabel: 'Customer first viewed at',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (data: ProposalSummaryAnalytics) => (
        <div>{data.viewedAt && formatDatetime(data.viewedAt)}</div>
      ),
    },
    {
      key: 'timeToClose',
      headerLabel: 'Time to close',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (data: ProposalSummaryAnalytics) => (
        <div>{data.timeToClose}</div>
      ),
    },
  ];

  return (
    <Page leftWidget="Proposals dashboard">
      <SimpleTable<ProposalSummaryAnalytics>
        cells={cells}
        rows={proposals}
        isLoading={isLoading}
        zeroStateMessage="There are currently no proposals to display for today."
      />
    </Page>
  );
};

export default SystemProposalsPage;
