import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { systemClient } from './httpClients/systemclient';
import axios from 'axios';
import { VersionInfo } from '../core-utils/helperFunctions/version';
import { getCheckoutBase } from './helper';
import { EventResults, KnexDbMigration, Status } from 'common';

const appVersionAxios = axios.create({
  baseURL: '/',
});

const checkoutAxios = axios.create({
  baseURL: getCheckoutBase('cacheflow'),
});
const VERSION_PATH = '/status.json';
const CHECKOUT_VERSION_KEY = 'checkoutVersion';

export const useEventList = (): UseQueryResult<EventResults> =>
  useQuery({
    queryKey: ['events-status'],
    queryFn: async () => {
      const { data } = await systemClient.getStatusEvents();
      return data;
    },
  });

export const useMigrationList = (): UseQueryResult<KnexDbMigration[]> =>
  useQuery({
    queryKey: ['migration-status'],
    queryFn: async () => {
      const { data } = await systemClient.getStatusMigrations();
      return data;
    },
  });

export const getFlowStatus = () => {
  return apiClient.getStatus();
};

export const useFlowStatus = (): UseQueryResult<Status> =>
  useQuery({
    queryKey: ['flow-status'],
    queryFn: async () => {
      const { data } = await getFlowStatus();
      return data;
    },
  });

export const usePaymentServiceStatus = (): UseQueryResult<VersionInfo> =>
  useQuery({
    queryKey: ['payment-service-status'],
    queryFn: async () => {
      const { data } = await systemClient.getPaymentServiceStatus();
      return data as unknown as VersionInfo;
    },
  });

export const getCFAppVersion = () => {
  return appVersionAxios.get(
    VERSION_PATH + '?timestamp=' + new Date().getTime()
  );
};

export const useCFAppVersion = (): UseQueryResult<VersionInfo> =>
  useQuery({
    queryKey: [VERSION_PATH],
    queryFn: async () => {
      const { data } = await getCFAppVersion();
      return data as VersionInfo;
    },
  });

export const useCheckoutVersion = (): UseQueryResult<VersionInfo> =>
  useQuery({
    queryKey: [CHECKOUT_VERSION_KEY],
    queryFn: async () => {
      const { data } = await checkoutAxios.get(
        VERSION_PATH + '?timestamp=' + new Date().getTime()
      );
      return data as VersionInfo;
    },
  });
