import React from 'react';
import Highlight from '@tiptap/extension-highlight';
import EditorButton from '../EditorButton';
import { EditorFeature } from '../editorCommon';
import { EditorIconHighlight } from '../editorIcons';

const highlightFeature: EditorFeature = {
  extensions: [Highlight],
  contextButtons: (editor) => (
    <EditorButton
      isSelected={editor.isActive('highlight')}
      label="Highlight"
      onClick={() => editor.chain().focus().toggleHighlight().run()}
      icon={EditorIconHighlight}
    />
  ),
  key: 'highlight',
};

export default highlightFeature;
