import React from 'react';
import {
  isDefined,
  isNumber,
  Item,
  OverrideRange,
  ScheduleLine,
  useShowDiscountAsDefault,
  useTranslation,
} from 'common';
import TimeBasedScheduleRow from './TimeBasedScheduleRow';

import styles from './TimeBasedPricing.module.scss';
import { clsx } from 'clsx';
import TimeBasedSubBar from '../TimeBasedSubBar';
import { updateRowItemPrice } from '../TimeBasedPricingTieredDrawer/utils';

const TimeBasedSchedule = ({
  item,
  scheduleRows,
  unitPrice,
  quantity,
  setDraftSchedule,
  currency,
  overrideRange,
}: {
  currency?: string;
  item: Item;
  overrideRange?: OverrideRange;
  quantity?: number;
  scheduleRows: ScheduleLine[];
  setDraftSchedule: (scheduleDraft: ScheduleLine[]) => void;
  unitPrice: number;
}) => {
  const { tk } = useTranslation();

  const showDiscountAsDefault = useShowDiscountAsDefault();

  const updateQuantityOverride = (value: number, startInd: number) => {
    // with a change proposal, the starting index may not be zero
    const index = scheduleRows.findIndex((row) => row.startIndex === startInd);
    // @ts-ignore TODO: Fix
    scheduleRows[index].quantity = value;
    setDraftSchedule([...scheduleRows]);
  };

  const firstRowIndex =
    overrideRange && isDefined(overrideRange.startIndex)
      ? overrideRange.startIndex
      : 0;
  const rowsToShow =
    overrideRange &&
    isDefined(overrideRange.startIndex) &&
    isDefined(overrideRange.endIndex)
      ? scheduleRows.slice(overrideRange.startIndex, overrideRange.endIndex + 1)
      : scheduleRows;

  return (
    <div className={styles.scheduleWrapper}>
      <TimeBasedSubBar
        item={item}
        scheduleLines={scheduleRows}
        onUpdateSchedules={setDraftSchedule}
      />
      <div
        className={clsx(
          styles.scheduleHeader,
          isNumber(quantity) && styles.withQty
        )}
      >
        <p className={styles.scheduleHeaderItemNarrowest} />
        <p className={styles.scheduleHeaderItemNarrow}>{tk('Payment')}</p>
        <span className={styles.scheduleHeaderItem}>
          <div />
        </span>
        {isNumber(quantity) && (
          <p className={styles.scheduleHeaderItemNarrow}>{tk('Qty')}</p>
        )}
        <p className={styles.scheduleHeaderItemNarrow}>{tk('List price')}</p>
        <p className={styles.scheduleHeaderItemNarrow}>{tk('List adj.')}</p>
        <p className={styles.scheduleHeaderItem}>{tk('Unit price')}</p>
      </div>

      {rowsToShow.map((rowItem, index) => {
        return (
          <TimeBasedScheduleRow
            key={index + 1}
            item={item}
            rowItem={rowItem}
            unitPrice={unitPrice}
            period={index + firstRowIndex + 1}
            updateRowItemPrice={(listPrice, salesPrice, idx) => {
              setDraftSchedule(
                updateRowItemPrice(
                  scheduleRows,
                  showDiscountAsDefault,
                  listPrice,
                  salesPrice,
                  idx
                )
              );
            }}
            updateQuantityOverride={updateQuantityOverride}
            quantity={isNumber(rowItem.quantity) ? rowItem.quantity : quantity}
            currency={currency}
          />
        );
      })}
    </div>
  );
};

export default TimeBasedSchedule;
