import React, { useEffect, useState } from 'react';
import { Button, Card, CompanyInfo, Tabs, useFlags } from 'common';
import CompanyTimeZone from './CompanyTimeZone';
import PaymentErrorContact from './PaymentErrorContact';
import ReferenceNumberPrefix from './ReferenceNumberPrefix';
import { useAllCompanyInfo } from 'app/src/services/api/branding/companyInfo';
import ErrorHandler from 'app/src/components/ErrorHandler';
import CompanyEditForm from './CompanyEditForm';

interface Props {}

const CompanyDetailsForm: React.FC<Props> = () => {
  const { shipFrom } = useFlags();
  const { data: fetchedCompanyInfos, error, isLoading } = useAllCompanyInfo();
  const [companyInfos, setCompanyInfos] = useState<CompanyInfo[]>([]);
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null);
  const [defaultCompanyInfo, setDefaultCompanyInfo] =
    useState<CompanyInfo | null>(null);

  useEffect(() => {
    if (fetchedCompanyInfos) {
      setCompanyInfos(fetchedCompanyInfos);
    }
  }, [fetchedCompanyInfos]);

  const sortCompanyInfos = (a: CompanyInfo, b: CompanyInfo): number => {
    if (a.defaultInfo === b.defaultInfo) {
      return 0;
    }

    return a.defaultInfo ? -1 : 1;
  };

  useEffect(() => {
    if (companyInfos && companyInfos.length > 0) {
      const defaultCompany: CompanyInfo | null =
        companyInfos.find((i) => i.defaultInfo) ?? companyInfos[0] ?? null;

      if (!companyInfo && defaultCompany) {
        setCompanyInfo(defaultCompany);
      }

      setDefaultCompanyInfo(defaultCompany);
      companyInfos.sort(sortCompanyInfos);
    }
  }, [companyInfos]);

  const updateCompanyInfo = (updatedCompanyInfo: CompanyInfo) => {
    setCompanyInfos((prevCompanyInfos) =>
      prevCompanyInfos.map((ci) =>
        ci.id === updatedCompanyInfo.id ? updatedCompanyInfo : ci
      )
    );
    setCompanyInfo(updatedCompanyInfo);
  };

  const handleOnMakeDefault = (selectedCompany: CompanyInfo) => {
    const updatedCompanyInfos = companyInfos.map((ci) => ({
      ...ci,
      defaultInfo: ci.id === selectedCompany.id,
    }));

    setCompanyInfos(updatedCompanyInfos);
    setDefaultCompanyInfo(selectedCompany);
  };

  const isNewCompanyInfo = companyInfo?.id === undefined;

  if (error || (!companyInfos && !isLoading)) {
    return <ErrorHandler error={error} />;
  }

  if (!companyInfo) {
    return null;
  }

  return (
    <>
      {shipFrom && (
        <div className="flex">
          <Button
            className="ml-auto mb-4"
            label={isNewCompanyInfo ? 'Cancel' : 'Add company'}
            onClick={() => {
              isNewCompanyInfo
                ? setCompanyInfo(defaultCompanyInfo)
                : setCompanyInfo({ name: '', internalName: '' });
            }}
          />
        </div>
      )}
      <Card>
        {shipFrom && (
          <div className="flex flex-row gap-4 justify-between">
            {companyInfos && companyInfos.length > 1 && !isNewCompanyInfo ? (
              <Tabs
                defaultActiveTab={companyInfo.id}
                onChange={(tab) => {
                  const info = companyInfos?.find((i) => i.id === tab);
                  if (info) {
                    setCompanyInfo(info);
                  }
                }}
                tabs={companyInfos.map((info) => ({
                  label: info.internalName || info.name || '',
                  key: info.id!,
                }))}
              />
            ) : (
              <div />
            )}
          </div>
        )}
        <CompanyEditForm
          companyInfo={companyInfo}
          isDefault={companyInfo.id === defaultCompanyInfo?.id}
          onMakeDefault={handleOnMakeDefault}
          removeCompanyInfo={() => setCompanyInfo(defaultCompanyInfo)}
          setCompanyInfo={updateCompanyInfo}
        />
      </Card>

      <Card header="Organization settings">
        <CompanyTimeZone />
        <PaymentErrorContact />
        <ReferenceNumberPrefix />
      </Card>
    </>
  );
};

export default CompanyDetailsForm;
