import { useMutation } from '@tanstack/react-query';
import { apiClient } from './httpClients/app';

const subscriptionSync = async (contractId: string) => {
  const { data } = await apiClient.syncContract(contractId);
  return data;
};

export const useSubscriptionSync = (
  onSuccess: () => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationFn: subscriptionSync,
    onSuccess,
    onError,
  });
