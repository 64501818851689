import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { getErrorStatus, Signature } from 'common';

const USER_SIGNATURE_DATA_PATH = 'api/latest/settings/users';
const SIGNATURE_DATA_PATH = 'api/latest/settings/signatures';

export const useFindByUserSignatureData = (
  userId: string
): UseQueryResult<Signature | null> =>
  useQuery({
    queryKey: [USER_SIGNATURE_DATA_PATH],
    queryFn: async () => {
      try {
        const { data } = await apiClient.getUserSignature(userId);
        return data;
      } catch (error: unknown) {
        if (getErrorStatus(error) === 404) {
          return null;
        } else {
          throw error;
        }
      }
    },
  });

export const useFindSignatureData = (
  id: string
): UseQueryResult<Signature | null> =>
  useQuery({
    queryKey: [SIGNATURE_DATA_PATH],
    queryFn: async () => {
      try {
        const { data } = await apiClient.getSignature(id);
        return data;
      } catch (error: unknown) {
        if (getErrorStatus(error) === 404) {
          return null;
        } else {
          throw error;
        }
      }
    },
  });

export const useCreateSignatureData = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [USER_SIGNATURE_DATA_PATH],
    mutationFn: async (params: { signingData: Signature; userId: string }) => {
      const { userId, signingData } = params;
      const { data } = await apiClient.createUserSignature(userId, signingData);
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [USER_SIGNATURE_DATA_PATH] });
    },
  });

export const useUpdateSignatureData = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [USER_SIGNATURE_DATA_PATH],
    mutationFn: async (params: { signingData: Signature; userId: string }) => {
      const { userId, signingData } = params;
      const { data } = await apiClient.updateUserSignature(userId, signingData);
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [USER_SIGNATURE_DATA_PATH] });
    },
  });

export const useDeleteSignatureData = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [USER_SIGNATURE_DATA_PATH],
    mutationFn: async (userId: string) => {
      const { data } = await apiClient.deleteUserSignature(userId);
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [USER_SIGNATURE_DATA_PATH] });
    },
  });
