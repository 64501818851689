import { ConnectorInfo, OrgConfig } from 'common';

const WARMLY: string = 'warmlyConfig';
const WARMLY_CONFIG: OrgConfig['configKey'] = 'warmlyConfig';
const WARMLY_SOURCE: ConnectorInfo['source'] = 'warmly';

export const WARMLY_INFO = {
  configType: WARMLY_CONFIG,
  source: WARMLY_SOURCE,
  name: WARMLY,
  showSaveButton: true,
};

export const accountContactsConfig = {
  integration: 'warmlyConfig',
  description:
    'The Warmly AI connector adds the warmly chatbot to the checkout experience.',
  cacheflowToIntegrationSection: [],
  integrationToCacheflowSection: [],
};
