import { PaymentMethod } from 'common';

interface MetaDataField {
  label: string;
  name: string;
}

export interface MethodProps {
  isSuperAdmin?: boolean;
  onModifyClick?: () => void;
  paymentMethod: PaymentMethod;
  setIdentifier?: (id: string) => void;
}

export const ACHFields = [
  {
    name: 'accountName',
    label: 'Account name',
  },
  {
    name: 'bankName',
    label: 'Bank name',
  },
  {
    name: 'routingNumber',
    label: 'Routing/ABA #',
  },
  {
    name: 'accountNumber',
    label: 'Account #',
  },
] as MetaDataField[];

const isOnlyNumbers = (value: string) => value.match(/^[0-9]+$/) !== null;

export const achRoutingNumberValidator = (value: string) => {
  return value.length <= 9 && isOnlyNumbers(value)
    ? null
    : 'Routing number can only consist of numbers and be 9 digits in length';
};

// The Depositor Account number can have up to 17 digits
// https://www.fiscal.treasury.gov/files/sps/payment-identification.pdf
export const achAccountNumberValidator = (value: string) => {
  return value.length <= 17 && isOnlyNumbers(value)
    ? null
    : 'Account number can only consist of numbers and be 17 digits in length';
};

export type ACHDetails = {
  accountName?: string;
  accountNumber?: string;
  bankName?: string;
  routingNumber?: string;
};

export const hasValidAchDetails = (m: PaymentMethod): boolean => {
  if (!m.name || !m.configuration) {
    return false;
  }
  const {
    accountName = '',
    bankName = '',
    routingNumber = '',
    accountNumber = '',
  } = m.configuration as ACHDetails;

  if (accountName.trim() === '' || bankName.trim() === '') {
    return false;
  }

  if (routingNumber.trim() === '' || achRoutingNumberValidator(routingNumber)) {
    return false;
  }

  if (accountNumber.trim() === '' || achAccountNumberValidator(accountNumber)) {
    return false;
  }

  return true;
};
