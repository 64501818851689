import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ConfirmDeleteModal,
  ErrorFetching,
  InternalAppLink,
  Loading,
  useToast,
  useTranslation,
} from 'common';
import { USER_NOTIFICATIONS } from '../../../core-utils/routes';
import { useQueryClient } from '@tanstack/react-query';
import {
  getSlackAppAuthRedirectUri,
  useDeleteCurrentSlackWorkspace,
  useGetSlackAppInfo,
} from '../../../services/slack';
import { useCreateStateToken } from '../../../services/state-tokens';
import SlackLogoSVG from '../../../assets/integration-logos/slackLogoSVG';

interface Props {
  hasAddedAppToWorkspace: boolean;
}

const SlackAddToWorkspace: React.FC<Props> = ({ hasAddedAppToWorkspace }) => {
  const queryClient = useQueryClient();
  const showToast = useToast();
  const { tk } = useTranslation();
  const [stateTokenKey, setStateTokenKey] = useState<string>();
  const [isCreatingSlackToken, setIsCreatingSlackToken] =
    useState<boolean>(true);
  const [isConfirmingWorkspaceDeletion, setIsConfirmingWorkspaceDeletion] =
    useState<boolean>(false);
  const isOnMountCalled = useRef(false);

  const {
    data: appInfo,
    isPending: isLoadingAppInfo,
    isError: isErrorAppInfo,
  } = useGetSlackAppInfo();

  const { mutate: createSlackToken, isError: isErrorCreatingSlackToken } =
    useCreateStateToken(
      (token: string) => {
        setStateTokenKey(token);
        setIsCreatingSlackToken(false);
      },
      () => {
        setIsCreatingSlackToken(false);
      },
      queryClient
    );

  const { mutate: deleteSlackWorkspace, isPending: isDeletingSlackWorkspace } =
    useDeleteCurrentSlackWorkspace(
      () => {
        showToast.success(tk('Successfully disabled Cacheflow Slack app'));
        setIsConfirmingWorkspaceDeletion(false);
      },
      () => {
        showToast.error(
          tk('Failed to disable Cacheflow Slack app. Please try again later')
        );
        setIsConfirmingWorkspaceDeletion(false);
      },
      queryClient
    );

  useEffect(() => {
    if (isOnMountCalled.current) return;
    isOnMountCalled.current = true;

    createSlackToken();
  }, []);

  if (isErrorAppInfo || isErrorCreatingSlackToken) {
    return <ErrorFetching />;
  }

  if (
    isLoadingAppInfo ||
    isCreatingSlackToken ||
    appInfo === undefined ||
    stateTokenKey === undefined
  ) {
    return <Loading />;
  }

  const appScopes = ['chat:write', 'im:history'];

  const slackAddToWorkspaceUrl = `
	https://slack.com/oauth/v2/authorize?
	scope=${appScopes.join(',')}
	&client_id=${appInfo.clientId}
	&state=${stateTokenKey}
	&redirect_uri=${getSlackAppAuthRedirectUri()}`;

  return (
    <div>
      <ConfirmDeleteModal
        isOpen={isConfirmingWorkspaceDeletion}
        onClose={() => setIsConfirmingWorkspaceDeletion(false)}
        onConfirm={deleteSlackWorkspace}
        header={tk('Disable Cacheflow Slack App')}
        bodyText={tk(
          'Are you sure you want to disable the Cacheflow Slack app? This will disable Cacheflow Slack notifications for all users.'
        )}
        typeName="Slack workspace"
        deleteButtonText="Yes, disable"
      />
      {hasAddedAppToWorkspace ? (
        <div className="flex justify-between">
          <div>
            {tk(
              "Cacheflow's Slack app is now activated. Approval requesters and approvers must turn on notifications individually in "
            )}
            <InternalAppLink
              route={USER_NOTIFICATIONS}
              breadcrumbs={['notifications']}
            />
            .
          </div>
          <div>
            <Button
              isDangerous
              label="Disable"
              isLoading={isDeletingSlackWorkspace}
              onClick={() => setIsConfirmingWorkspaceDeletion(true)}
            />
          </div>
        </div>
      ) : (
        <div>
          <a
            href={slackAddToWorkspaceUrl}
            style={{
              alignItems: 'center',
              color: '#fff',
              backgroundColor: '#4A154B',
              border: 0,
              borderRadius: '6px',
              display: 'inline-flex',
              fontFamily: 'Lato, sans-serif',
              fontSize: '14px',
              fontWeight: 600,
              height: '44px',
              justifyContent: 'center',
              textDecoration: 'none',
              width: '204px',
              gap: '12px',
            }}
          >
            <SlackLogoSVG height={16} width={16} />
            Add to Slack
          </a>
          <p className="pt-4">
            {tk(
              "Add Cacheflow's Slack app to receive approval notifications and approve or reject proposals directly from your company's slack account."
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default SlackAddToWorkspace;
