import { Contract, ContractSummary } from 'common';
import { isOverdue } from 'app/src/components/Transfer/utils';

export const getContractDisplayStatus = (
  contract: ContractSummary | Contract
) => {
  if (contract.status === 'active') {
    const nextPayment = contract.nextPaymentDue;
    if (
      nextPayment?.scheduleDate &&
      new Date(nextPayment.scheduleDate) <= new Date(contract.createdAt)
    ) {
      return contract.status;
    }

    if (nextPayment) {
      if (
        isOverdue(nextPayment) &&
        contract.billingMethod?.paymentMethod?.managed
      ) {
        return 'overdue';
      }

      // NOTE: this used to return getPaymentStatusOnlyValue(nextPayment), but that
      // can return 'pending' or any other Transfer.status / Transaction.status value, yet
      // <StatusLabel /> for contract only knows how to display Contract.status values.
      return contract.status;
    }
  }
  return contract.status;
};
