import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { CreateRefundRequest, Refund } from 'common';
import { apiClient } from '../../httpClients/app';

const REFUND_PATH = '/api/latest/data/refunds';

const getRefundPathById = (id: string) => {
  return `${REFUND_PATH}/${id}`;
};

const getAllRefunds = async () => {
  const { data } = await apiClient.listRefunds();
  return data;
};

export const useGetAllRefunds = (): UseQueryResult<Refund[]> => {
  return useQuery({
    queryKey: [REFUND_PATH],
    queryFn: getAllRefunds,
  });
};

const getRefund = async (refundId: string) => {
  const { data } = await apiClient.getRefund(refundId);
  return data;
};

export const useGetRefund = (refundId: string) => {
  return useQuery({
    queryKey: [getRefundPathById(refundId)],
    queryFn: () => getRefund(refundId),
  });
};

const createRefund = async (request: CreateRefundRequest) => {
  const { data } = await apiClient.createRefund(request);
  return data;
};

export const useCreateRefund = (
  onSuccess: (data: Refund) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [REFUND_PATH],
    mutationFn: createRefund,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [REFUND_PATH],
      });
    },
  });
