import React, { useState } from 'react';
import UpcomingBilling from './UpcomingBilling';
import { formatDate, formatDateISO, Select } from 'common';
import Page from '../../../components/Page';

enum TransferType {
  billing = 'billing',
}

const dayOptions = [0, 30, 60, 90, 180, 365];

const addDaysToToday = (numoOfDays: number) => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() + numoOfDays));
};

const UpcomingTransfersPage: React.FC = () => {
  const [transferType, setTransferType] = useState<TransferType>(
    TransferType.billing
  );
  const [numDays, setNumDays] = useState(30);

  const leftWidget = (
    <div className="flex items-center">
      <div className="text-lg mr-4">Upcoming Transfers</div>
      <Select<TransferType>
        white
        onChange={(value) => setTransferType(value)}
        value={transferType}
        options={Object.keys(TransferType).map((key) => ({
          value: key as TransferType,
          name: TransferType[key as keyof typeof TransferType].valueOf(),
        }))}
      />
      <Select
        white
        onChange={(value) => setNumDays(Number(value))}
        value={String(numDays)}
        options={dayOptions.map((d) => ({
          value: `${d}`,
          name: `${d} days`,
        }))}
      />
      <div className="ml-4">
        Up until {formatDate(formatDateISO(addDaysToToday(numDays)))}
      </div>
    </div>
  );

  return (
    <Page leftWidget={leftWidget}>
      <UpcomingBilling date={addDaysToToday(numDays)} />
    </Page>
  );
};

export default UpcomingTransfersPage;
