import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import {
  useGetConnectionTest,
  useIsConnectorEnabled,
} from 'app/src/services/connectors';
import { ConnectorTestResult, OrgConfig, ReferenceIssue } from 'common/src';
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

export interface ConnectionState {
  isConnected: boolean;
  connectionStatus: ConnectorTestResult | undefined;
  error: Error | null;
  issue: ReferenceIssue[] | undefined;
  isLoading: boolean;
  isEnabled: boolean;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<ConnectorTestResult, Error>>;
}

export interface ConnectionContextProps {
  hubspotConnection: ConnectionState;
  salesforceConnection: ConnectionState;
  quickbooksConnection: ConnectionState;
  avalaraConnection: ConnectionState;
  closeConnection: ConnectionState;
  updateConnection: (
    connectionType: keyof ConnectionContextProps,
    newState: Partial<ConnectionState>
  ) => void;
}

const ConnectionContext = createContext<ConnectionContextProps | undefined>(
  undefined
);

export const useConnection = () => {
  const context = useContext(ConnectionContext);
  if (context === undefined) {
    throw new Error('useConnection must be used within a ConnectionProvider');
  }
  return context;
};

const useConnectionState = (
  connectorName: OrgConfig['configKey'],
  isEnabled: boolean
): ConnectionState => {
  const {
    data: connectionData,
    error,
    isLoading,
    refetch,
  } = useGetConnectionTest(connectorName?.replace('Config', ''));

  return useMemo(
    () => ({
      isConnected:
        !((connectionData?.issues?.length ?? 0) > 0) &&
        !error &&
        connectionData?.status === 'active',
      connectionStatus: connectionData,
      isEnabled,
      error,
      issue: connectionData?.issues,
      isLoading,
      refetch,
    }),
    [connectionData, error, isLoading, refetch, isEnabled]
  );
};

export const ConnectionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isHubspotEnabled = useIsConnectorEnabled('hubspotConfig');
  const isSalesforceEnabled = useIsConnectorEnabled('salesforceConfig');
  const isQuickbooksEnabled = useIsConnectorEnabled('quickbooksConfig');
  const isAvalaraEnabled = useIsConnectorEnabled('avalaraConfig');
  const isCloseEnabled = useIsConnectorEnabled('closeConfig');

  const hubspotConnection = useConnectionState(
    'hubspotConfig',
    isHubspotEnabled
  );
  const salesforceConnection = useConnectionState(
    'salesforceConfig',
    isSalesforceEnabled
  );
  const quickbooksConnection = useConnectionState(
    'quickbooksConfig',
    isQuickbooksEnabled
  );
  const avalaraConnection = useConnectionState(
    'avalaraConfig',
    isAvalaraEnabled
  );
  const closeConnection = useConnectionState('closeConfig', isCloseEnabled);

  const [connections, setConnections] = useState<ConnectionContextProps>(
    () => ({
      hubspotConnection,
      salesforceConnection,
      quickbooksConnection,
      avalaraConnection,
      closeConnection,
      updateConnection: () => {},
    })
  );

  const updateConnection = useCallback(
    (
      connectionType: keyof ConnectionContextProps,
      newState: Partial<ConnectionState>
    ) => {
      setConnections((prevState) => ({
        ...prevState,
        [connectionType]: {
          ...prevState[connectionType],
          ...newState,
        },
      }));
    },
    []
  );

  useEffect(() => {
    setConnections((prevState) => ({
      ...prevState,
      hubspotConnection,
      salesforceConnection,
      quickbooksConnection,
      avalaraConnection,
      closeConnection,
    }));
  }, [
    hubspotConnection.isConnected,
    isHubspotEnabled,
    salesforceConnection.isConnected,
    isSalesforceEnabled,
    quickbooksConnection.isConnected,
    isQuickbooksEnabled,
    avalaraConnection.isConnected,
    isAvalaraEnabled,
    closeConnection.isConnected,
    isCloseEnabled,
  ]);

  const value = useMemo(
    () => ({
      ...connections,
      updateConnection,
    }),
    [connections, updateConnection]
  );

  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  );
};
