import { OrgConfig } from 'common';

export type GatewayType = 'cacheflow' | 'paragon';

type CrmConnectorConfig = {
  enabled: boolean;
  gatewayType: GatewayType;
};

function getConnectorConfig(
  orgConfigs: Map<OrgConfig['configKey'], OrgConfig>,
  configKey:
    | 'salesforceConfig'
    | 'hubspotConfig'
    | 'quickbooksConfig'
    | 'warmlyConfig'
    | 'zapierConfig'
): CrmConnectorConfig | undefined {
  return orgConfigs.get(configKey)?.configValue as
    | CrmConnectorConfig
    | undefined;
}

export function isConnectorEnabled(
  orgConfigs: Map<OrgConfig['configKey'], OrgConfig>,
  configKey:
    | 'salesforceConfig'
    | 'hubspotConfig'
    | 'quickbooksConfig'
    | 'warmlyConfig'
    | 'zapierConfig'
) {
  const config = getConnectorConfig(orgConfigs, configKey);
  return !!config?.enabled;
}

export function getGatewayType(
  orgConfigs: Map<OrgConfig['configKey'], OrgConfig>,
  configKey:
    | 'salesforceConfig'
    | 'hubspotConfig'
    | 'quickbooksConfig'
    | 'zapierConfig'
) {
  const config = getConnectorConfig(orgConfigs, configKey);
  return config?.gatewayType;
}
