import './EventStyles.scss';
import {
  capitalizeFirstLetter,
  ErrorCell,
  formatDateOrDefault,
  NavigationIcon,
  QueryCell,
  QueryTable,
  SyncEvent,
  SyncEventsSummary,
} from 'common';
import React, { SVGProps } from 'react';
import { Page } from 'app/src/components';
import { systemClient } from 'app/src/services/httpClients/systemclient';
import { syncEventsFilters } from 'app/src/services/syncEvents';
import StatusLabel from 'app/src/components/StatusLabel';
import IDField from 'app/src/components/IDField';
import logos from '../../../assets/integration-logos';
import { useSearchParams } from 'react-router-dom';
import SyncSummaryCard from 'app/src/components/SyncSummaryCard';

function SyncEvents() {
  interface logoProps {
    logo: React.ComponentType<SVGProps<SVGSVGElement>>;
  }

  const RenderLogo = ({ logo: LogoComponent }: logoProps) => {
    return <LogoComponent height={20} />;
  };

  const getDirection = (data: SyncEvent) => {
    let logoName = logos.salesforce;
    if (data.externalSource && data.externalSource.sourceType in logos) {
      logoName = logos[data.externalSource.sourceType as keyof typeof logos];
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        {data.direction === 'to_cacheflow' ? (
          <>
            <RenderLogo logo={logoName} />
            <span>{` -> `}</span>
            <RenderLogo logo={logos.cacheflow} />
          </>
        ) : (
          <>
            <RenderLogo logo={logos.cacheflow} />
            <span>{` -> `}</span>
            <RenderLogo logo={logoName} />
          </>
        )}
      </div>
    );
  };

  const cells: QueryCell<SyncEvent>[] = [
    {
      key: 'createdAt',
      headerLabel: 'Created at',
      width: '9%',
      sortable: true,
      overflowCell: true,
      renderCell: (data: SyncEvent) => (
        <div>
          <div>{formatDateOrDefault(data.createdAt)}</div>
        </div>
      ),
    },
    {
      key: 'type',
      headerLabel: 'Type',
      width: '7%',
      allowWrap: true,
      renderCell: (item: SyncEvent) => (
        <div className="flex relative item-center justify-between">
          <div className="text-md top-1/2 bottom-1/2">
            {item.referenceType && capitalizeFirstLetter(item.referenceType)}
          </div>
        </div>
      ),
    },
    {
      key: 'referenceId',
      headerLabel: 'CacheflowID',
      width: '18%',
      allowWrap: true,
      renderCell: (item: SyncEvent) => (
        <div className="underline text-blue">
          <IDField documentID={item.referenceId ? item.referenceId : ''}>
            {item.referenceId ? item.referenceId.slice(0, 25) : ''}
          </IDField>
        </div>
      ),
    },
    {
      key: 'direction',
      headerLabel: 'Direction',
      width: '11%',
      allowWrap: true,
      renderCell: (item: SyncEvent) => getDirection(item),
    },
    {
      key: 'source',
      headerLabel: 'Source',
      width: '10%',
      allowWrap: true,
      renderCell: (item: SyncEvent) => (
        <>
          <div className="font-semibold truncate" />
          <div className="text-md">
            {item.externalSource?.sourceType &&
              capitalizeFirstLetter(item.externalSource.sourceType)}
          </div>
        </>
      ),
    },
    {
      key: 'type',
      headerLabel: 'Event type',
      width: '8%',
      allowWrap: true,
      renderCell: (item: SyncEvent) => (
        <>
          <div className="font-semibold truncate" />
          <div className="text-md">
            {item.eventType && capitalizeFirstLetter(item.eventType)}
          </div>
        </>
      ),
    },
    {
      key: 'action',
      headerLabel: 'Action',
      width: '8%',
      allowWrap: true,
      renderCell: (item: SyncEvent) => (
        <>
          <div className="font-semibold truncate" />
          <div className="text-md">
            {item.action && capitalizeFirstLetter(item.action)}
          </div>
        </>
      ),
    },
    {
      renderCell: (data: SyncEvent) => {
        if (data.status === 'success') {
          return <StatusLabel status="success" feature="invoice" />;
        } else if (data.status === 'error') {
          return (
            <ErrorCell
              error={data.error}
              statusLabel={<StatusLabel status="error" feature="invoice" />}
            />
          );
        }
        return 'Not synced';
      },
      key: 'status',
      headerLabel: 'Sync status',
      width: '12%',
      align: 'center',
    },
  ];

  const [searchParams] = useSearchParams();

  return (
    <Page
      leftWidget={
        <span className="eventPageHeading">
          <NavigationIcon.Products height="40px" width="40px" />
          <h1 className="innerHeading">Sync Events</h1>
        </span>
      }
    >
      <SyncSummaryCard<SyncEventsSummary>
        fetchCountList={systemClient.getSyncEventsCount}
        searchParams={searchParams.get('search') ?? ''}
        queryKey="syncEventCount"
      />

      <div className="mt-10">
        <QueryTable<SyncEvent>
          queryKey="payment-sync-events-summary"
          fetchList={systemClient.getSyncEvents}
          filters={syncEventsFilters(true)}
          cells={cells}
          dataTestId="payment-sync-table"
          minWidth="900px"
        />
      </div>
    </Page>
  );
}

export default SyncEvents;
