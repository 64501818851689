import React from 'react';
import {
  Card,
  CardHeader,
  CardRowValue,
  Customer,
  getExternalPaymentLink,
  getPaymentTypeFormatted,
  Transfer,
  Type,
} from 'common';
import styles from './PaymentSummaryCard.module.scss';
import IDField from 'app/src/components/IDField';
import DateTimeCell from '../../../components/DateTimeCell';

interface Props {
  payment: Transfer;
  customer: Customer;
}

export const PaymentSummaryCard: React.FC<Props> = ({ payment, customer }) => {
  return (
    <div className={styles.paymentSummaryCard}>
      <div className={styles.side}>
        <Card bottomMargin grow throughline>
          <CardHeader name="Payment" throughline />
          {payment.id && (
            <CardRowValue label="Id">
              <IDField documentID={payment.id}>
                <div>{payment.id.substring(0, 8)}</div>
              </IDField>
            </CardRowValue>
          )}
          <CardRowValue label="Customer name">
            <Type bold>{customer.name}</Type>
          </CardRowValue>
          <CardRowValue label="Currency">{payment.currency}</CardRowValue>
        </Card>
      </div>

      <div className={styles.side}>
        <Card bottomMargin grow throughline>
          <CardRowValue label="Payment date">
            <DateTimeCell date={payment.createdAt} showTime={false} />
          </CardRowValue>
          <CardRowValue label="Processed date">
            <DateTimeCell date={payment.processedAt} showTime={false} />
          </CardRowValue>
          <CardRowValue label="Payment type">
            {getPaymentTypeFormatted(payment.paymentMethod?.paymentType)}
          </CardRowValue>
          <CardRowValue label="Reference Id">
            {getExternalPaymentLink(payment)}
          </CardRowValue>
          <CardRowValue label="Amount">{payment.amountFormatted}</CardRowValue>
        </Card>
      </div>
    </div>
  );
};
