import React from 'react';

import { useGetGroupUsers } from 'app/src/services/groups';

import styles from './UsersInGroup.module.scss';

interface Props {
  id: string;
}

export const UsersInGroup = ({ id }: Props) => {
  const { data: usersInGroupList, isLoading } = useGetGroupUsers(id);

  if (isLoading) {
    return null;
  }

  return (
    <p
      className={styles.users}
    >{`${usersInGroupList?.length} user(s) in group`}</p>
  );
};
