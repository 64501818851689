import React, { SVGProps } from 'react';

const QuickbooksSVG: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="40 40 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="#2CA01C"
      d="M90 135.226c24.853 0 45-20.147 45-45s-20.147-45-45-45-45 20.147-45 45 20.147 45 45 45Z"
    />
    <path
      fill="#fff"
      d="M57.496 90.224c0 9.665 7.835 17.5 17.5 17.5h2.5v-6.5h-2.5c-6.066 0-11-4.934-11-11 0-6.065 4.934-11 11-11h6.007v34a6.5 6.5 0 0 0 6.5 6.5v-47H74.996c-9.665 0-17.5 7.836-17.5 17.5Zm47.51-17.497h-2.5v6.5h2.5c6.064 0 11 4.935 11 11s-4.936 11-11 11h-6.01v-34a6.5 6.5 0 0 0-6.5-6.5v47h12.51c9.664 0 17.5-7.835 17.5-17.5s-7.836-17.5-17.5-17.5Z"
    />
  </svg>
);

export default QuickbooksSVG;
