import React from 'react';
import { Button, CardRowValue, Flex, Icon } from 'common';
import clsx from 'clsx';

interface Props {
  isEditDisabled?: boolean;
  label: string;
  poDisplayValue: string;
  setEditingPO: React.Dispatch<React.SetStateAction<boolean>>;
}

const POLabelWithValue: React.FC<Props> = ({
  label,
  poDisplayValue,
  setEditingPO,
  isEditDisabled,
}) => (
  <CardRowValue alignLabel="center" label={label}>
    <Flex align="center" gap>
      {poDisplayValue}
      <Button
        className={clsx({
          invisible: isEditDisabled,
        })}
        iconBefore={Icon.Edit}
        isDisabled={isEditDisabled}
        label="Edit"
        onClick={() => setEditingPO(true)}
        size="small"
        type="secondary"
      />
    </Flex>
  </CardRowValue>
);

export default POLabelWithValue;
