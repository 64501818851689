import {
  arrayReplace,
  BillingPeriodsType,
  Currency,
  formatMoneyOrDefault,
  getEntry,
  getZeroMoney,
  isGreater,
  isNumber,
  Item,
  ItemLevelOverride,
  ItemOverride,
  Money,
  PricebookEntry,
  ProductLevel,
  ScheduleLine,
} from 'common';

export const getLevelPrice = (
  entries: PricebookEntry[] | null | undefined,
  currency: Currency['code'] | undefined,
  billingPeriod: BillingPeriodsType,
  index: number,
  overrideItemLevels: ItemOverride['levels'] | undefined
): {
  billingPeriodMoney: PricebookEntry['price'] | undefined;
  initialPrice: PricebookEntry['price'] | undefined;
  levelOverridePrice: ItemLevelOverride['price'] | undefined;
} => {
  const billingPeriodMoney = getEntry(
    entries,
    currency,
    billingPeriod,
    index
  )?.price;
  const levelOverridePrice = overrideItemLevels?.find(
    (oiLevel) => oiLevel.level === index
  )?.price;
  const initialPrice = levelOverridePrice || billingPeriodMoney;
  return { initialPrice, billingPeriodMoney, levelOverridePrice };
};

export const getBaseLevelsByBillingPeriod = (
  levels: ProductLevel[],
  billingPeriod: BillingPeriodsType | undefined,
  currency: Currency['code'],
  entries: PricebookEntry[]
): ItemLevelOverride[] => {
  return levels.map((_level, index) => {
    const entry = getEntry(entries, currency, billingPeriod, index);
    return {
      level: index,
      price: entry?.price || getZeroMoney(currency),
    };
  });
};

const getRowItemLevel = (
  rowLevels: ScheduleLine['levels'],
  levelIndex: number
): ItemLevelOverride | undefined =>
  rowLevels?.find((level) => level.level === levelIndex);

export const getItemOverrideWithLevelsDraft = (
  item: Item,
  currency: string,
  billingPeriod?: BillingPeriodsType
) => {
  const { product } = item;
  const isOneTime = product.recurrence === 'one_time';
  const { levels } = product;

  const baseLevels = levels
    ? getBaseLevelsByBillingPeriod(
        levels,
        billingPeriod,
        currency,
        product.entries
      )
    : [];
  return isOneTime
    ? (item.overrides?.[0] ?? {
        levels: baseLevels,
        discount: false,
      })
    : (item.overrides?.find(
        (override: ItemOverride) => override.billingPeriod === billingPeriod
      ) ?? {
        billingPeriod,
        levels: baseLevels,
        discount: false,
      });
};

const updateMoneyAmount = (
  value: Money | undefined,
  amount: Money['amount']
): Money => ({
  ...value,
  amount,
  formattedAmount: formatMoneyOrDefault({
    ...value,
    amount,
    formattedAmount: undefined,
  }),
});

export const updateRowItemPrice = (
  scheduleRows: ScheduleLine[],
  showDiscountAsDefault: boolean,
  listPrice: Money,
  salesPrice: Money,
  startInd: number,
  levelIndex?: number
) => {
  // with a change proposal, the starting index may not be zero
  const index = scheduleRows.findIndex((row) => row.startIndex === startInd);
  // @ts-ignore TODO: Fix
  const row: ScheduleLine = { ...scheduleRows[index] };
  const existingOverride: ItemLevelOverride | undefined =
    isNumber(levelIndex) && row.levels
      ? getRowItemLevel(row.levels, levelIndex)
      : undefined;

  if (existingOverride) {
    const rowLevels: ItemLevelOverride[] = [...(row.levels ?? [])];
    const rowLevel = { ...existingOverride };

    rowLevel.price = updateMoneyAmount(rowLevel.price, salesPrice.amount);
    rowLevel.listPrice = updateMoneyAmount(
      rowLevel.listPrice,
      listPrice.amount
    );

    if (isGreater(rowLevel.price, rowLevel.listPrice)) {
      row.discount = false;
    } else if (showDiscountAsDefault) {
      row.discount = true;
    }

    const rowLevelIndex = rowLevels.findIndex(
      (lev) => lev.level === levelIndex
    );
    row.levels = arrayReplace(rowLevels, rowLevelIndex, rowLevel);
    return arrayReplace(scheduleRows, index, row);
  }

  const scheduleRow = scheduleRows[index];

  if (scheduleRow) {
    scheduleRow.price = updateMoneyAmount(scheduleRow.price, salesPrice.amount);

    scheduleRow.listPrice = updateMoneyAmount(
      scheduleRow.listPrice,
      listPrice.amount
    );

    if (isGreater(scheduleRow.price, scheduleRow.listPrice)) {
      scheduleRow.discount = false;
    } else if (showDiscountAsDefault) {
      scheduleRow.discount = true;
    }
  }

  return [...scheduleRows];
};

export const isActiveTier = (
  item: Item,
  index: number,
  quantity: number
): boolean => {
  const { product } = item;
  const { levels } = product;
  const level = levels?.[index];
  const nextLevel = levels?.[index + 1];

  if (
    nextLevel &&
    level &&
    isNumber(quantity) &&
    isNumber(nextLevel.start) &&
    isNumber(level.start)
  ) {
    switch (product.pricing) {
      case 'graduated':
        // multiple tiers can be active at the same time
        return level.start <= quantity;
      default:
        // one tier active at a time for both volume and stairstep
        return nextLevel.start > quantity && level.start <= quantity;
    }
  } else if (
    level &&
    isNumber(level.start) &&
    isNumber(quantity) &&
    !nextLevel
  ) {
    return level.start <= quantity;
  }
  return false;
};
