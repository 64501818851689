import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import {
  isProposalPendingApproval,
  isProposalPublished,
  isProposalTemplate,
} from '../proposalUtilities';
import {
  ApprovalRequest,
  Button,
  ConfirmModal,
  CreateProposalRequest,
  formatDatetime,
  getPersonFullName,
  hasAnAcceptedProposalStatus,
  isApprovedProposalStatus,
  isDraftProposalStatus,
  isProposalAllowedToEnterDraft,
  isSignedProposal,
  Proposal,
  Type,
  useTranslation,
} from 'common';
import clsx from 'clsx';
import { useApprovalRequiredForProposal } from '../../../../services/proposal';
import StatusBar from 'app/src/components/StatusBar';
import ApprovalCancelDrawer from '../Approvals/ApprovalCancelDrawer';
import styles from './ProposalStatus.module.scss';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

interface Props {
  approvalRequest?: ApprovalRequest | null;
  isDisabled: boolean;
  onChange: (updateProposalRequest: CreateProposalRequest) => void;
  onShareClick: () => void;
  proposal: Proposal;
}

const ProposalStatus: React.FC<Props> = ({
  isDisabled,
  proposal,
  approvalRequest,
  onChange,
  onShareClick,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const isPublished = isProposalPublished(proposal.status);
  const isTemplate = isProposalTemplate(proposal);
  const isApproved = isApprovedProposalStatus(proposal);
  const isPendingApproval = isProposalPendingApproval(proposal.status);
  const isSigned = isSignedProposal(proposal);
  const isAllowedToEnterDraft = isProposalAllowedToEnterDraft(proposal);
  const isDraft = isDraftProposalStatus(proposal);
  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);
  const proposalRecipients = proposal.proposalContacts;
  const numProposalRecipients = proposalRecipients.length;
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  const { tk } = useTranslation();

  const approvalRequiredForProposal = useApprovalRequiredForProposal(
    proposal.id
  );

  const handleToggleProposalStatus = (status: Proposal['status']) => {
    onChange({ status });
    setShowConfirmModal(false);
  };

  const handleClickEdit = () => {
    if (proposal.status === 'expired') {
      handleToggleProposalStatus('draft');
    } else {
      setShowConfirmModal(true);
    }
  };

  const getEnterDraftMessage = () => {
    const prefix = isTemplate ? 'Template ' : 'Proposal ';
    if (isPublished) {
      return (
        <>
          {tk(`${prefix} cannot be edited while `)}
          <b>{tk('shared.')}</b>
        </>
      );
    }
    if (isApproved) {
      return (
        <>
          {tk(`${prefix} cannot be edited when `)}
          <b>{tk('approved.')}</b>
        </>
      );
    }
    return (
      <>
        {tk(`${prefix} is `)}
        <b>{tk('expired.')}</b>
      </>
    );
  };

  const renderStatusBar = () => {
    if (!canUpdate) {
      return null;
    }
    if (hasAnAcceptedStatus && !isSigned) {
      return (
        <StatusBar
          message={<>{tk('Accepted proposals cannot be edited.')}</>}
          type="informative"
        />
      );
    } else if (isPendingApproval && approvalRequest) {
      return (
        <>
          <StatusBar
            message={
              <span>
                Approval requested by{' '}
                {getPersonFullName(approvalRequest.requester)}{' '}
                {approvalRequest.createdAt &&
                  formatDatetime(approvalRequest.createdAt)}
              </span>
            }
            type="caution"
          >
            <button
              className={clsx(styles.statusButton, {
                invisible: !canUpdate,
              })}
              onClick={() => setShowCancelModal(true)}
              type="button"
            >
              {tk('Cancel approval request')}
            </button>
          </StatusBar>
          <ApprovalCancelDrawer
            action="cancel"
            approvalRequest={approvalRequest}
            closeDrawer={() => {
              setShowCancelModal(false);
            }}
            isShowing={showCancelModal}
            proposal={proposal}
          />
        </>
      );
    } else if (isAllowedToEnterDraft) {
      return (
        <StatusBar
          message={<span>{getEnterDraftMessage()}</span>}
          type="informative"
        >
          {!hasAnAcceptedStatus && (
            <button
              className={clsx(styles.statusButton, {
                invisible: !canUpdate,
              })}
              disabled={!!proposal.archived}
              onClick={handleClickEdit}
              type="button"
            >
              {proposal.archived ? tk('Archived') : tk('Edit in draft mode')}
            </button>
          )}
        </StatusBar>
      );
    } else if (isPublished && isSigned) {
      return (
        <StatusBar
          message={<span>{tk('Signed proposals cannot be edited.')}</span>}
          type="informative"
        />
      );
    } else if (
      isDraft &&
      !approvalRequiredForProposal &&
      (numProposalRecipients > 0 || isTemplate)
    ) {
      return (
        <StatusBar
          message={
            <Trans>
              <b className="mr-2">Editing shared draft.</b>
              Click<b>&nbsp;'Done'&nbsp;</b> to make the
              {isTemplate
                ? ' template available for use.'
                : ' updated proposal available to recipients.'}
            </Trans>
          }
          type="informative"
        >
          <Button isDisabled={isDisabled} label="Done" onClick={onShareClick} />
        </StatusBar>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {renderStatusBar()}

      <ConfirmModal
        cancelText="Cancel, do nothing"
        confirmText="Confirm, enable draft mode"
        header="Confirm withdrawing to draft mode"
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => handleToggleProposalStatus('draft')}
        width={550}
      >
        <>
          {isApproved && (
            <Type paragraph>
              <b>Important: </b>If you withdraw the{' '}
              {isTemplate ? 'template' : 'proposal'} to draft mode you cannot
              give customers access to the{' '}
              {isTemplate ? 'template' : 'proposal'} before it has been approved
              again.
            </Type>
          )}

          {numProposalRecipients > 0 && (
            <Type paragraph>
              {numProposalRecipients} customer contacts will lose access to this{' '}
              {isTemplate ? 'template' : 'proposal'} until it is shared again.
            </Type>
          )}
          <Type paragraph>Please confirm you want to proceed.</Type>
        </>
      </ConfirmModal>
    </>
  );
};

export default ProposalStatus;
