import React from 'react';

import { ApprovalRequest, Proposal, StickyBanner } from 'common';

import {
  useApprovalRequiredForProposal,
  useIsProposalApprover,
} from '../../../../services/proposal';

import { ApprovalBannerBody } from './ApprovalBannerBody';

const PROPOSAL_EDITOR_HEADER_OFFSET = 72;

interface Props {
  approvalRequest?: ApprovalRequest | null;
  proposal: Proposal;
}

const ApprovalBanner = ({ proposal, approvalRequest }: Props) => {
  const isApprover = useIsProposalApprover(approvalRequest);
  const isApprovalRequiredForProposal = useApprovalRequiredForProposal(
    proposal.id
  );

  if (proposal.status !== 'pending_approval' && proposal.status !== 'draft') {
    return null;
  }

  if (proposal.status === 'pending_approval' && !isApprover) {
    return null;
  }

  if (!isApprovalRequiredForProposal) {
    return null;
  }

  return (
    <StickyBanner topOffset={PROPOSAL_EDITOR_HEADER_OFFSET}>
      <ApprovalBannerBody
        approvalRequest={approvalRequest}
        proposal={proposal}
      />
    </StickyBanner>
  );
};

export default ApprovalBanner;
