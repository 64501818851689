import React, { useEffect, useRef } from 'react';
import { RefSelectProps, Select } from 'antd';
import clsx from 'clsx';

import {
  CreateProposalApprovalRuleRequest,
  ProposalApprovalRuleConfig,
  ProposalSummary,
} from 'common';

import {
  GroupOption,
  RuleInfo,
  searchFilterOption,
} from '../rules/_approvalRulesConfig';

import styles from './ProposalRuleBuilderStyles.module.scss';

interface Props {
  allGroupOptions: GroupOption[];
  createRule: (payload: CreateProposalApprovalRuleRequest) => void;
  proposalType: ProposalSummary['proposalType'];
  selectedRule: RuleInfo;
}

export const ProposalBooleanBuilder = ({
  proposalType,
  createRule,
  selectedRule,
  allGroupOptions,
}: Props) => {
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, []);

  const handleSave = (groupId: string[]) => {
    const payload: CreateProposalApprovalRuleRequest = {
      name: `${selectedRule.name}-${proposalType}`,
      config: {
        proposalType,
        ruleType: selectedRule.name as ProposalApprovalRuleConfig['ruleType'],
      },
      approvalGroupIds: groupId,
    };

    createRule(payload);
  };

  const handleSaveIfValid = (id: string[]) => {
    if (id.length > 0) {
      handleSave(id);
    }
  };

  return (
    <div className={clsx(styles.ruleRow, styles.boolRuleRow)}>
      <div className={styles.boolCondition}>
        {selectedRule.conditionOperator}
      </div>

      <div className={styles.input} />

      <div className={styles.groups}>
        {allGroupOptions.length > 0 && (
          <Select
            ref={selectRef}
            onChange={handleSaveIfValid}
            showAction={['focus', 'click']}
            value={[]}
            options={allGroupOptions}
            mode="multiple"
            filterOption={searchFilterOption}
            className={styles.boolGroupWidth}
            placeholder="Select approval group"
            popupMatchSelectWidth={false}
          />
        )}
      </div>
    </div>
  );
};
