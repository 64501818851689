import React, { useState } from 'react';

import { Button, ContentType, Input } from 'common';

import { useAuthState } from '../../Auth';
import { apiClient } from '../../services/httpClients/app';

const PlaywrightTriggers = () => {
  const { auth } = useAuthState();

  const orgId = auth.organizationId;

  const [featureFlag, setFeatureFlag] = useState<string | undefined>();

  const handleCreateTransferRequests = async () => {
    await apiClient.upcomingStatementsByOrg(orgId!, {
      statementDate: 'today',
    });
    await apiClient.createTransferRequests({ orgId });
  };

  const handleFlagOff = async () => {
    await toggleFlag(featureFlag!, false);
  };

  const handleFlagOn = async () => {
    await toggleFlag(featureFlag!, true);
  };

  const toggleFlag = async (flag: string, value: boolean) => {
    const data = {
      overrides: {
        [flag]: value,
      },
    };

    return await apiClient.request<string, any>({
      path: `/api/latest/system/organizations/${orgId}/features`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
    });
  };

  return (
    <>
      <h1>Playwright End 2 End Triggers</h1>

      <Button
        dataTestId="trigger-create-transfer-requests"
        label="createTransferRequests"
        onClick={handleCreateTransferRequests}
      />

      <Input
        value={featureFlag}
        onChange={(value) => setFeatureFlag(value)}
        dataTestId="override-flag-input"
      />

      <Button
        dataTestId="override-flag-on"
        label="override-flag-on"
        onClick={handleFlagOn}
      />

      <Button
        dataTestId="override-flag-off"
        label="override-flag-off"
        onClick={handleFlagOff}
      />
    </>
  );
};

export default PlaywrightTriggers;
