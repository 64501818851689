import React, { useEffect, useRef } from 'react';
import { getFlowStatus } from '../../services/status';
import { log, Type } from 'common';
import styles from './maintenancePage.module.scss';
import { APP } from '../../core-utils/routes';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';
import ReactGA from 'react-ga';
import { getSearchParams } from '../../services/routes/routeUtils';
import CacheflowLogo from '../../assets/CacheflowLogo';
import SmallPrint from '../Login/SmallPrint/SmallPrint';

const MaintenancePage: React.FC = () => {
  const isOnMountCalled = useRef(false);

  function checkStatus() {
    setTimeout(() => {
      getFlowStatus()
        .then((resp) => {
          log.info(resp);
          const status = resp.data;
          if (resp.status === 200 && status.maintenance === false) {
            // we want to do a full refresh here so not using navigate
            window.location.href = APP;
          } else {
            checkStatus();
          }
        })
        .catch((error) => {
          log.info(error);
          checkStatus();
        });
    }, 10 * 1000);
  }

  if (ENVIRONMENT_CONFIG.isProduction) {
    ReactGA.pageview(location.pathname + getSearchParams(location.search));
  }

  useEffect(() => {
    if (isOnMountCalled.current) return;
    isOnMountCalled.current = true;

    checkStatus();
  }, []);

  return (
    <div className={styles.maintenancePage}>
      <div className={styles.logo}>
        <CacheflowLogo />
      </div>
      <div className={styles.container}>
        <Type header={2}>Maintenance</Type>
        <Type paragraph>Cacheflow is being upgraded</Type>
        <Type paragraph>
          We expect to be finished in a few minutes. We apologize for any
          inconvenience.
        </Type>
        <Type paragraph>You will be redirected when complete.</Type>
      </div>
      <SmallPrint />
    </div>
  );
};

export default MaintenancePage;
