import React from 'react';
import ExternalDealsCallback from '../ExternalDealsCallback';
import { HUBSPOT_DEALS } from '../../../core-utils/routes';

const HubspotDealsCallback: React.FC = () => {
  return (
    <ExternalDealsCallback
      dealRoute={HUBSPOT_DEALS}
      dealQueryParamName="hubspotRedirectDealId"
    />
  );
};

export default HubspotDealsCallback;
