import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  getMilliseconds,
  Product,
  ProductRequest,
  ProductStats,
  ProductSummary,
} from 'common';
import { apiClient } from '../../httpClients/app';
import { useEffect } from 'react';

const PRODUCT_BASE_PATH = '/api/latest/settings/products';
const CREATE_PRODUCT_KEY = 'createProduct';
const UPDATE_PRODUCT_KEY = 'updateProduct';
const VERSION_PRODUCT_KEY = 'versionProduct';
const DELETE_PRODUCT_KEY = 'deleteProduct';

const getProductPath = (id: string) => `${PRODUCT_BASE_PATH}/${id}`;

const ALL_PRODUCTS_BASE_KEY = PRODUCT_BASE_PATH;

// tuple key, so all variants can be cleared using the first part
const getAllProductsKey = (filterBy: string) => [
  ALL_PRODUCTS_BASE_KEY,
  filterBy,
];

const getBatchProductsKey = (ids: string[]) =>
  `${PRODUCT_BASE_PATH}/batch/${ids.join(',')}`;

export const useVisibleProducts = (): UseQueryResult<ProductSummary[]> =>
  useProducts('visible');

export const useEligibleProducts = (): UseQueryResult<ProductSummary[]> =>
  useProducts('eligible');

const cacheProduct = (queryClient: QueryClient, product: Product) => {
  queryClient.setQueryData([getProductPath(product.id)], product);
};

const cacheIndividualProducts = (
  queryClient: QueryClient,
  products: Product[]
) => {
  products.forEach((p) => cacheProduct(queryClient, p));
};

export const useProducts = (
  filterBy: string = 'all'
): UseQueryResult<ProductSummary[]> =>
  useQuery({
    queryKey: getAllProductsKey(filterBy),
    queryFn: async () => {
      const { data } = await apiClient.listProducts({ filterBy });
      return data;
    },
  });

export const useBatchProducts = (
  queryClient: QueryClient,
  ids: string[],
  enabled?: boolean
): UseQueryResult<Product[]> => {
  const queryResult = useQuery({
    queryKey: [getBatchProductsKey(ids)],
    queryFn: async () => {
      // TODO: use a data loader to fulfill individual products from cache when available.
      const { data } = await apiClient.getProductsBatch(ids.join(','));
      return data;
    },
    enabled,
    // use staleTime so result gets cached across useBatchProducts() invocations
    staleTime: getMilliseconds({ seconds: 60 }),
  });

  useEffect(() => {
    if (!queryResult.data) {
      return;
    }

    cacheIndividualProducts(queryClient, queryResult.data);
  }, [queryResult.data]);

  return queryResult;
};

export const useProduct = (id: string): UseQueryResult<Product> =>
  useQuery({
    queryKey: [getProductPath(id)],
    queryFn: async () => {
      const { data } = await apiClient.getProduct(id);
      return data;
    },
  });

export const useLatestProduct = (rootId: string): UseQueryResult<Product> =>
  useQuery({
    queryKey: ['latest-by-root', rootId],
    queryFn: async () => {
      const { data } = await apiClient.getLatestByRoot(rootId);
      return data;
    },
  });

export const useProductStats = (id: string): UseQueryResult<ProductStats> =>
  useQuery({
    queryKey: [getProductPath(id), 'stats'],
    queryFn: async () => {
      const { data } = await apiClient.getProductStats(id);
      return data;
    },
  });

const createProduct = async (request: ProductRequest) => {
  const { data } = await apiClient.createProduct(request);
  return data;
};

export const useCreateProduct = (
  onSuccess: (data: Product) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [CREATE_PRODUCT_KEY],
    mutationFn: createProduct,
    onSuccess: (data) => {
      onSuccess(data);
      cacheProduct(qc, data);
    },
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_PRODUCTS_BASE_KEY] });
    },
  });

const versionProduct = async (id: string, request: ProductRequest) => {
  const { data: versionResult } = await apiClient.versionProduct(id, request);
  return versionResult;
};

export const useVersionProduct = (
  id: string,
  onSuccess: (data: Product) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [VERSION_PRODUCT_KEY],
    mutationFn: (request: ProductRequest) => versionProduct(id, request),
    onSuccess: (data) => {
      onSuccess(data);
      cacheProduct(qc, data);
    },
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_PRODUCTS_BASE_KEY] });
    },
  });

const updateProduct = async (id: string, request: ProductRequest) => {
  const { data: updateResult } = await apiClient.updateProduct(id, request);
  return updateResult;
};

export const useUpdateProduct = (
  id: string,
  onSuccess: (data: Product) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [UPDATE_PRODUCT_KEY],
    mutationFn: (request: ProductRequest) => updateProduct(id, request),
    onSuccess: (data) => {
      onSuccess(data);
      cacheProduct(qc, data);
    },
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_PRODUCTS_BASE_KEY] });
    },
  });

const deleteProduct = async (id: string) => {
  const { data } = await apiClient.deleteProduct(id);
  return data;
};

export const useDeleteProduct = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [DELETE_PRODUCT_KEY],
    mutationFn: deleteProduct,
    // TODO: invalidate cache entry for this product
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_PRODUCTS_BASE_KEY] });
    },
  });
