import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { ErrorFetching, Icon, Loading, useFlags } from 'common';

import { ActivityDrawer } from 'app/src/components/ActivityDrawer/ActivityDrawer';
import { useActivityDrawer } from 'app/src/components/ActivityDrawer/useActivityDrawer';

import { Page } from '../../components';
import IDField from '../../components/IDField';
import { PAYMENTS } from '../../core-utils/routes';
import { PaymentSummaryCard } from './Details/PaymentSummaryCard';
import { PaymentInvoicesCard } from './Details/PaymentInvoicesCard';
import { PaymentActionsHeader } from './Details/PaymentActionsHeader';
import { useInvoice } from '../../services/api/invoices/invoiceList';
import { useIssueRefundDrawer } from '../Refunds/hooks/useIssueRefundDrawer';
import { IssueRefundDrawer } from '../Refunds/components/IssueRefundDrawer';
import { useTransaction } from '../../services/api/transactions/transactionsList';

import styles from './PaymentDetailPage.module.scss';

export const PaymentDetailPage: React.FC = () => {
  const { refunds: refundsFlag } = useFlags();

  const { id: transactionId } = useParams<{ id: string }>();
  const { data: payment } = useTransaction(transactionId ?? '');

  const {
    data: invoice,
    isLoading: invoiceLoading,
    isError: invoiceError,
  } = useInvoice(payment?.parentReferenceId ?? '');

  const {
    isIssueRefundDrawerOpen,
    selectedTransferId,
    openIssueRefundDrawer,
    closeIssueRefundDrawer,
  } = useIssueRefundDrawer();

  const {
    activity,
    isActivityDrawerOpen,
    openActivityDrawer,
    closeActivityDrawer,
  } = useActivityDrawer('transfer', transactionId ?? '');

  const isLoading =
    !transactionId || !payment?.parentReferenceId ? true : invoiceLoading;
  const isError = !transactionId || invoiceError;

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorFetching />;
  }

  return (
    <Page
      className={styles.paymentsDetailPage}
      leftWidget={
        <div className={styles.headerContainer}>
          <Link to={PAYMENTS}>
            <Icon.CurrencyUsd />
          </Link>
          <div className={styles.rightHeader}>
            <div>{invoice?.header.customer.name ?? 'Payment Details'}</div>
            <div className={styles.subtext}>
              <IDField documentID={payment?.id}>
                <div>{payment?.id?.substring(0, 8) ?? '-'}</div>
              </IDField>
            </div>
          </div>
        </div>
      }
      rightWidget={
        <PaymentActionsHeader
          onIssueRefundClick={() => {
            if (invoice?.schedule.transfer) {
              openIssueRefundDrawer(invoice.schedule.transfer.id ?? '');
            }
          }}
          openDialog={openActivityDrawer}
          payment={payment}
        />
      }
    >
      {payment && invoice && (
        <PaymentSummaryCard
          customer={invoice.header.customer}
          payment={payment}
        />
      )}

      {invoice && <PaymentInvoicesCard invoices={[invoice]} />}

      {refundsFlag && isIssueRefundDrawerOpen && selectedTransferId && (
        <IssueRefundDrawer
          customerName={invoice?.header.customer.name ?? ''}
          isOpen={isIssueRefundDrawerOpen}
          onClose={closeIssueRefundDrawer}
          transferId={selectedTransferId}
        />
      )}

      <ActivityDrawer
        activityEvents={activity}
        isOpen={isActivityDrawerOpen}
        onClose={closeActivityDrawer}
        resourceType="transfer"
      />
    </Page>
  );
};
