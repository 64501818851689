import { DefaultOptionType } from 'antd/es/select';
import { Proposal, ProposalApprovalRuleConfig } from 'common';

export interface GroupOption extends Omit<DefaultOptionType, 'label'> {
  label: React.ReactNode;
}

export interface RuleInfo {
  conditionOperator: string | string[];
  configKey: string;
  description: string;
  id?: string;
  label?: string;
  name: string;
  rootProductId?: string;
  selectedProductName?: string;
}

// proposalChangeTCV is a special case where the rule entry point becomes and saves as one of two rules
// internally it uses the two rules below when saving, but this is not a rule in itself, just an entry
// TVC decreases (proposalAmountDecrease)
// TVC exceeds (proposalAmount)
type ProposalApprovalRuleTypesCustom = 'proposalChangeTCV';

type ProposalApprovalRuleTypesWithCustoms =
  | ProposalApprovalRuleConfig['ruleType']
  | ProposalApprovalRuleTypesCustom;

export const getRuleInfo = (
  ruleType: ProposalApprovalRuleTypesWithCustoms
): RuleInfo => {
  switch (ruleType) {
    case 'proposalAmount':
      return {
        name: 'proposalAmount',
        description: 'Total contract value',
        configKey: 'amount',
        conditionOperator: 'exceeds',
      };
    case 'proposalDiscountPercent':
      return {
        name: 'proposalDiscountPercent',
        description: 'Total discount',
        configKey: 'discount',
        conditionOperator: 'exceeds',
      };
    case 'proposalCountry':
      return {
        name: 'proposalCountry',
        description: 'Shipping country',
        configKey: 'country',
        conditionOperator: 'is not',
      };
    case 'proposalCustomTerms':
      return {
        name: 'proposalCustomTerms',
        description: 'Additional terms',
        configKey: 'additionalTerms',
        conditionOperator: 'have changed',
      };
    case 'proposalAutoRenewal':
      return {
        name: 'proposalAutoRenewal',
        description: 'Auto-renewal',
        configKey: 'autoRenewal',
        conditionOperator: 'is not enabled',
      };
    case 'proposalExternalCrm':
      return {
        name: 'proposalExternalCrm',
        description: 'External CRM',
        configKey: 'externalCrm',
        conditionOperator: 'is not linked',
      };
    case 'proposalDocusign':
      return {
        name: 'proposalDocusign',
        description: 'Docusign',
        configKey: 'docusign',
        conditionOperator: 'is not enabled',
      };
    case 'proposalAttachments':
      return {
        name: 'proposalAttachments',
        description: 'Attachments',
        configKey: 'attachments',
        conditionOperator: 'have changed',
      };
    case 'proposalChangeTCV':
      return {
        name: 'proposalChangeTCV',
        description: 'Total contract value',
        configKey: 'amount',
        conditionOperator: ['exceeds', 'decreases'],
      };
    case 'proposalAmountDecrease':
      return {
        name: 'proposalAmountDecrease',
        description: 'Total contract value',
        configKey: 'decreases',
        conditionOperator: 'decreases',
      };
    case 'proposalFreeMonths':
      return {
        name: 'proposalFreeMonths',
        description: 'Free months',
        configKey: 'freeMonths',
        conditionOperator: 'exceeds',
      };
    case 'proposalTermLength':
      return {
        name: 'proposalTermLength',
        description: 'Term length',
        configKey: 'termLength',
        conditionOperator: 'exceeds',
      };
    case 'proposalPaymentTerms':
      return {
        name: 'proposalPaymentTerms',
        description: 'Payment terms',
        configKey: 'paymentTerms',
        conditionOperator: 'matches',
      };
    case 'proposalBillingPeriods':
      return {
        name: 'proposalBillingPeriods',
        description: 'Billing period',
        configKey: 'billingPeriods',
        conditionOperator: 'matches',
      };
    case 'proposalDuplicateProducts':
      return {
        name: 'proposalDuplicateProducts',
        description: 'Duplicate product',
        configKey: 'duplicateProducts',
        conditionOperator: ['is added'],
      };

    // Product rules
    case 'proposalProductDiscountPercent':
      return {
        name: 'proposalProductDiscountPercent',
        description: 'discount',
        configKey: '',
        conditionOperator: [
          'discount exceeds',
          'discount is between',
          'quantity exceeds',
        ],
      };

    case 'proposalItemQuantity':
      return {
        name: 'proposalItemQuantity',
        description: 'quantity',
        configKey: 'quantity',
        conditionOperator: ['exceeds'],
      };

    default:
      throw new Error(`Unknown rule type: ${ruleType}`);
  }
};

export const getProductRules = (): RuleInfo[] => {
  const baseRules: RuleInfo[] = [getRuleInfo('proposalProductDiscountPercent')];

  return baseRules;
};

export const getRulesForProposalType = (
  proposalType: Proposal['proposalType']
): RuleInfo[] => {
  // Applies to all proposal types
  const baseRules: RuleInfo[] = [
    getRuleInfo('proposalDiscountPercent'),
    getRuleInfo('proposalCountry'),
    getRuleInfo('proposalCustomTerms'),
    getRuleInfo('proposalAutoRenewal'),
    getRuleInfo('proposalDocusign'),
    getRuleInfo('proposalExternalCrm'),
    getRuleInfo('proposalDuplicateProducts'),
  ];

  if (proposalType === 'initial') {
    baseRules.push(getRuleInfo('proposalAmount'));
    baseRules.push(getRuleInfo('proposalFreeMonths'));
    baseRules.push(getRuleInfo('proposalTermLength'));
    baseRules.push(getRuleInfo('proposalPaymentTerms'));
    baseRules.push(getRuleInfo('proposalBillingPeriods'));
    baseRules.push(getRuleInfo('proposalAttachments'));
  }

  if (proposalType === 'change') {
    baseRules.push(getRuleInfo('proposalChangeTCV'));
  }

  if (proposalType === 'renewal') {
    baseRules.push(getRuleInfo('proposalAmount'));
    baseRules.push(getRuleInfo('proposalFreeMonths'));
    baseRules.push(getRuleInfo('proposalTermLength'));
    baseRules.push(getRuleInfo('proposalPaymentTerms'));
    baseRules.push(getRuleInfo('proposalBillingPeriods'));
  }

  return baseRules;
};

export const searchFilterOption = (input: string, option?: GroupOption) => {
  if (option && typeof option.label === 'string') {
    return option.label.toLowerCase().includes(input.toLowerCase());
  }

  return false;
};
