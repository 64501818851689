import React, { SVGProps } from 'react';
import clsx from 'clsx';
import { ToolTip } from 'common';
import styles from './ActionBarButton.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  dataTestId?: string;
  disabledTooltip?: string;
  icon?: React.ComponentType<SVGProps<SVGSVGElement>>;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  tooltip?: string;
}

const ActionBarButton: React.FC<Props> = ({
  children,
  className,
  dataTestId,
  disabledTooltip,
  icon: IconComponent,
  isDisabled,
  isLoading,
  onClick,
  tooltip,
}) => {
  return (
    <ToolTip
      isDisabled={isDisabled ? !disabledTooltip : !tooltip}
      title={isDisabled ? disabledTooltip : tooltip}
    >
      <button
        className={clsx(
          styles.actionBarButton,
          isDisabled && styles.disabled,
          isLoading && styles.isLoading,
          className
        )}
        data-testid={dataTestId}
        onClick={onClick}
        type="button"
      >
        {IconComponent ? <IconComponent className={styles.icon} /> : null}
        {children}
      </button>
    </ToolTip>
  );
};

export default ActionBarButton;
