import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Cell,
  Customer,
  DefaultZeroState,
  formatNullableFormattedAmount,
  getIconBasedOnProposalType,
  getIconCell,
  Icon,
  pluralize,
  ProposalSummary,
  SimpleTable,
} from 'common';
import StatusLabel from 'app/src/components/StatusLabel';
import { useCustomerProposals } from 'app/src/services/customer';
import ActivityCell from '../../../../components/ActivityCell';

interface Props {
  customer: Customer;
}

const CustomerProposalCard: React.FC<Props> = ({ customer }) => {
  const navigate = useNavigate();
  const { data: proposals, isLoading } = useCustomerProposals(customer.id);

  const handleTableRowClick = (selectedProposal: ProposalSummary) => {
    navigate(`/proposals/${selectedProposal.id}`);
  };

  const cells: Cell<ProposalSummary>[] = [
    getIconCell(null, {
      renderCell: (value: ProposalSummary) => {
        const draftClasses = clsx({
          'opacity-30 grayscale': value.status === 'draft',
        });

        const IconComponent = getIconBasedOnProposalType(value);

        return <IconComponent className={draftClasses} />;
      },
    }),
    {
      key: 'customer',
      headerLabel: ['Customer', 'Doc. title.'],
      renderCell: ({ customer: c, name }: ProposalSummary) => (
        <div>
          <div className="truncate">{c ? c.name : '-'}</div>
          <div className="text-xs text truncate">{name || '-'}</div>
        </div>
      ),
      overflowCell: true,
    },
    {
      key: 'amount',
      headerLabel: ['Value', 'Term'],
      width: '15%',
      align: 'right',
      renderCell: ({ amountFormatted, termQty, termType }: ProposalSummary) => (
        <div>
          <div>{formatNullableFormattedAmount(amountFormatted)}</div>
          <div className="text-xs text-black">
            {pluralize(termQty, termType)}
          </div>
        </div>
      ),
    },
    {
      key: 'lastEvent.createdAt',
      headerLabel: 'Last activity',
      width: '150px',
      overflowCell: true,
      renderCell: (data: ProposalSummary) => (
        <ActivityCell
          timeAgo={data.lastEvent?.createdAt}
          person={data.lastEvent?.contact || data.lastEvent?.user}
        />
      ),
      clickable: true,
    },
    {
      key: 'status',
      headerLabel: 'Status',
      width: '250px',
      renderCell: ({ status, displayStatus }: ProposalSummary) => (
        <StatusLabel
          status={status}
          displayStatus={displayStatus}
          feature="proposal"
          classNames="w-full"
        />
      ),
    },
    {
      key: 'signed',
      headerLabel: 'Signed',
      width: '60px',
      renderCell: ({ options, signingDocument }: ProposalSummary) => {
        if (options?.requiresSigning) {
          if (signingDocument && signingDocument.signedAt) {
            return <Icon.SignableSigned />;
          }

          return <Icon.SignableUnsigned />;
        }

        return null;
      },
    },
  ];

  return (
    <Card>
      <CardHeader name="Proposals" />
      <SimpleTable<ProposalSummary>
        cells={cells}
        disableSearch
        disableShadow
        onRowClick={handleTableRowClick}
        rows={proposals}
        minWidth="900px"
        zeroState={
          <DefaultZeroState message="There are no proposals to display." />
        }
        isLoading={isLoading}
      />
    </Card>
  );
};

export default CustomerProposalCard;
