import { useAuthState } from 'app/src/Auth';
import { useClearLegacyCheckoutProposals } from 'app/src/services/proposal';
import { Button, Modal, useToast } from 'common';
import React from 'react';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
}

export const ClearLegacyModal: React.FC<Props> = ({
  handleClose,
  isOpen,
}: Props) => {
  const { auth } = useAuthState();
  const orgId = auth.organizationId;
  const showToast = useToast();

  const handleClearError = () => {
    showToast.error('Failed to clear legacy checkout proposals.');
  };

  const handleClearSuccess = () => {
    showToast.info('Legacy checkout proposals cleared successfully.');
  };

  const { mutate: clearLegacyCheckout } = useClearLegacyCheckoutProposals(
    orgId ?? '',
    handleClearSuccess,
    handleClearError
  );

  return (
    <Modal
      header="Clear legacy checkout proposals"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div>
        This will make all existing proposals in this org use the Oxbow view in
        checkout. Are you sure?
      </div>
      <Button
        block
        label="Clear legacy checkout"
        onClick={() => clearLegacyCheckout()}
      />
    </Modal>
  );
};
