import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Button,
  ButtonBar,
  CreateRefundRequest,
  DatePicker,
  Drawer,
  formatDateOrDefault,
  FormField,
  getToday,
  TextArea,
  useToast,
} from 'common';

import { useCreateRefund } from 'app/src/services/api/refunds/refunds';
import { useTransaction } from 'app/src/services/api/transactions/transactionsList';

interface Props {
  isOpen: boolean;
  transferId: string;
  customerName: string;
  onClose: () => void;
}

export const IssueRefundDrawer = ({
  isOpen,
  transferId,
  onClose,
  customerName,
}: Props) => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { data: transfer, isLoading } = useTransaction(
    transferId,
    !!transferId
  );

  const { mutate: createRefundMutation, isPending } = useCreateRefund(
    () => {
      onClose();
      showToast.success('Refund initiated');
    },
    () => {
      showToast.error('Refund failed');
    },
    queryClient
  );

  const [note, setNote] = useState<string>('');
  const [processDate, setProcessDate] = useState<string>(getToday());

  const handleSubmit = () => {
    if (transfer?.id) {
      const refundRequest: CreateRefundRequest = {
        amount: transfer.amount,
        transferId: transfer.id,
        processDate,
        note,
      };

      createRefundMutation(refundRequest);
    }
  };

  if (isLoading) return null;
  if (!transfer) return null;

  return (
    <Drawer header="Refund" isOpen={isOpen} onClose={onClose}>
      <FormField label="Customer name">{customerName}</FormField>

      <FormField label="Payment Id">{transfer.id}</FormField>

      <FormField label="Payment amount">{transfer.amountFormatted}</FormField>

      <FormField label="Payment date">
        {formatDateOrDefault(transfer.processedAt)}
      </FormField>

      <FormField label="Refund amount">{transfer.amountFormatted}</FormField>

      <FormField label="Refund date">
        <DatePicker
          onChange={(value) => setProcessDate(value)}
          value={processDate}
        />
      </FormField>

      <FormField label="Notes">
        <TextArea
          id="notes"
          onChange={(value) => setNote(value)}
          value={note}
        />
      </FormField>

      <ButtonBar>
        <Button label="Cancel" onClick={onClose} type="secondary" />
        <Button isDisabled={isPending} label="Refund" onClick={handleSubmit} />
      </ButtonBar>
    </Drawer>
  );
};
