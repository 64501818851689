import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import BaseTemplate from './BaseTemplate';
import {
  adminBottomContent,
  adminTopContent,
} from 'app/src/components/Navigation/sideNav/sideNavBar';
import { SYSTEM_LOGIN_ROUTE } from '../../core-utils/routes';
import SideNavBar from 'app/src/components/Navigation/sideNav';
import { useAuth } from '../../Auth';
import { canAccessSystemAdminRoutesWithContext } from '../../core-utils/helperFunctions/userServiceHelper';

interface Props {
  mainContent: React.ReactElement;
  startRoute: string;
}

const SystemBasePage: React.FC<Props> = ({ startRoute, mainContent }) => {
  const location = useLocation();
  const auth = useAuth();

  // if we don't have access redirect to system login, with params if they exist
  if (!canAccessSystemAdminRoutesWithContext(auth)) {
    return (
      <Navigate
        replace
        to={{ pathname: SYSTEM_LOGIN_ROUTE, search: location.search }}
      />
    );
  }

  return (
    <BaseTemplate
      leftNav={
        <SideNavBar
          startRoute={startRoute}
          topContent={adminTopContent}
          setupContent={adminBottomContent}
        />
      }
      mainContent={mainContent}
    />
  );
};

export default SystemBasePage;
