import { useQueryClient } from '@tanstack/react-query';
import TriggerUpdateTax from 'app/src/pages/Subscriptions/SubscriptionDetail/PaymentSchedule/TriggerUpdateTax';
import {
  InvoiceRequest,
  useUpdateInvoiceStatus,
} from 'app/src/services/api/invoices/invoiceList';

import { useUnlinkExternalObject } from 'app/src/services/externalCrm';
import {
  ConfirmModal,
  getErrorMessage,
  Notification,
  Icon,
  Invoice,
  InvoiceSummary,
  InvoiceWithSyncSummaryRest,
  PopOutMenu,
  PopOutMenuOption,
  useFlags,
  useToast,
  ToolTip,
} from 'common';
import React, { useState } from 'react';
import {
  useIsCurrentUserAdmin,
  useIsSuperAdmin,
} from '../../../core-utils/helperFunctions/userServiceHelper';
import { useSyncInvoice } from '../../../services/contract';
import { apiClient } from '../../../services/httpClients/app';
import { useResendInvoiceEmail } from '../../../services/emails';
import InvoiceArchiveOption from './InvoiceArchiveOption';
import { useGetConnectionTest } from 'app/src/services/connectors';

interface Props {
  borderless: boolean;
  invoice: Invoice | InvoiceSummary | InvoiceWithSyncSummaryRest;
  scheduleId: string;
  sourceId?: string;
  subscriptionId: string;
  setBillingScheduleId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setSelectedInvoiceCustomer?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setIsConfirmUnvoidModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  openActivityDrawer?: () => void;
  setSelectedInvoice?: React.Dispatch<
    React.SetStateAction<
      Invoice | InvoiceSummary | InvoiceWithSyncSummaryRest | null
    >
  >;
  setIsStatusUpdateInProgress?: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoiceOptionsButton: React.FC<Props> = ({
  invoice,
  scheduleId,
  subscriptionId,
  sourceId,
  borderless,
  setBillingScheduleId,
  setSelectedInvoiceCustomer,
  setIsConfirmUnvoidModalOpen,
  openActivityDrawer,
  setSelectedInvoice,
  setIsStatusUpdateInProgress,
}) => {
  const { quickbooks, taxyTurby, markInvoiceAsPaid } = useFlags();
  const showToast = useToast();
  const queryClient = useQueryClient();
  const isSuperAdmin = useIsSuperAdmin();
  const isAdminRole = useIsCurrentUserAdmin();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showVoidConfirmModal, setShowVoidConfirmModal] = useState(false);

  const { data, error: qberror } = useGetConnectionTest('quickbooks');

  const isQuickbooksConnected =
    !((data?.issues?.length ?? 0) > 0) && !qberror && data?.status === 'active';

  const { mutate: sendInvoiceEmail } = useResendInvoiceEmail(
    invoice.id,
    (resent: Notification) =>
      resent.success
        ? showToast.info('Invoice email resend successfully')
        : showToast.error(
            resent.errorMessage || 'Failed to send invoice email.'
          ),
    () => showToast.error('Failed to send invoice email'),
    queryClient
  );

  const { mutateAsync } = useUpdateInvoiceStatus(queryClient);

  const updateInvoiceStatus = (updateInvoiceRequest: InvoiceRequest) => {
    setIsStatusUpdateInProgress?.(true);
    mutateAsync(updateInvoiceRequest)
      .then(() => {
        setIsStatusUpdateInProgress?.(false);
        if (updateInvoiceRequest.updateRequest.status === 'open') {
          setIsConfirmUnvoidModalOpen?.(true);
        } else {
          showToast.success('Updated invoice successfully');
        }
      })
      .catch(() => {
        setIsStatusUpdateInProgress?.(false);
        showToast.error(`Error while updating the invoice`);
      });
  };

  const handleSyncSuccess = () => {
    showToast.success('Sync started');
  };

  const handleSyncError = (error: unknown) => {
    const msg = getErrorMessage(error);
    showToast.error(`Sync failed: ${msg}`);
  };
  const { mutate: syncInvoice } = useSyncInvoice(
    subscriptionId,
    handleSyncSuccess,
    handleSyncError,
    queryClient
  );

  const { mutate: unlinkInvoiceFromErp } = useUnlinkExternalObject(
    'billingschedule',
    scheduleId,
    () => {
      setShowConfirm(false);
      showToast.info('Invoice removed from ERP');
    },
    (err: unknown) => {
      setShowConfirm(false);
      const errorMessage = getErrorMessage(err);
      if (errorMessage) {
        showToast.error(errorMessage);
      } else {
        showToast.error('Failed remove invoice from ERP');
      }
    },
    queryClient
  );

  const renumberInvoice = async () => {
    const localInvoice = { ...invoice }; // Create a local copy of the invoice object

    const response = await apiClient.renumberInvoice(localInvoice.id);
    localInvoice.invoiceNumber = response.data.header.invoiceNumber;

    // Apply the changes back to the original invoice object
    Object.assign(invoice, localInvoice);
  };

  const resetAndSync = async () => {
    await apiClient.resetAndSyncObjectToExternal(
      'billingschedule',
      scheduleId,
      {
        sourceType: 'quickbooks',
      }
    );
    handleSyncSuccess();
  };

  const isOpen = () => {
    return invoice.status === 'open' || invoice.status === 'past_due';
  };

  if (!isAdminRole && !isSuperAdmin) {
    return null;
  }

  return (
    <>
      <PopOutMenu borderless={borderless}>
        {taxyTurby && isOpen() && <TriggerUpdateTax scheduleId={scheduleId} />}
        {quickbooks && (
          <ToolTip
            title={!isQuickbooksConnected ? 'QuickBooks is not connected' : ''}
          >
            <PopOutMenuOption
              isDisabled={!isQuickbooksConnected}
              icon={Icon.Repeat}
              onClick={() => syncInvoice(scheduleId)}
              title="Sync invoice/payment to ERP"
            />
          </ToolTip>
        )}
        {isSuperAdmin && (
          <>
            <PopOutMenuOption
              icon={Icon.Alert}
              isSuper
              onClick={renumberInvoice}
              title="Reissue number"
            />
            <PopOutMenuOption
              icon={Icon.Alert}
              isSuper
              onClick={resetAndSync}
              title="Reset and sync"
            />

            <PopOutMenuOption
              icon={Icon.Alert}
              isDisabled={!(quickbooks && sourceId)}
              isSuper
              onClick={() => {
                setShowConfirm(true);
              }}
              title="Delete invoice in QuickBooks"
            />
            <PopOutMenuOption
              icon={Icon.Alert}
              isSuper
              onClick={() => sendInvoiceEmail({})}
              title="Resend invoice email"
            />
          </>
        )}

        <PopOutMenuOption
          icon={Icon.Activity}
          onClick={() => {
            setSelectedInvoice?.(invoice);
            openActivityDrawer?.();
          }}
          title="Invoice activity"
        />

        <InvoiceArchiveOption invoice={invoice} />
        {invoice.status !== 'draft' && invoice.status !== 'paid' && (
          <PopOutMenuOption
            icon={Icon.Cancel}
            onClick={() => {
              setShowVoidConfirmModal(true);
            }}
            title={invoice.status === 'voided' ? 'Un-void' : 'Void'}
          />
        )}
        {markInvoiceAsPaid &&
          (invoice.status === 'open' || invoice.status === 'past_due') && (
            <PopOutMenuOption
              icon={Icon.TriangleUp}
              onClick={() => {
                setSelectedInvoiceCustomer?.(
                  'customerName' in invoice ? invoice.customerName : undefined
                );
                setBillingScheduleId?.(scheduleId);
              }}
              title="Receive payment"
            />
          )}
      </PopOutMenu>

      <ConfirmModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() =>
          unlinkInvoiceFromErp({
            sourceId,
            sourceType: 'quickbooks',
          })
        }
      >
        <div>Are you sure you want to remove this invoice from Quickbooks?</div>
      </ConfirmModal>

      <ConfirmModal
        cancelText="No cancel"
        confirmText={invoice.status === 'voided' ? 'Yes, un-void' : 'Yes, void'}
        header={
          invoice.status === 'voided' ? 'Un-void Invoice' : 'Void Invoice'
        }
        isOpen={showVoidConfirmModal}
        onClose={() => setShowVoidConfirmModal(false)}
        onConfirm={() => {
          updateInvoiceStatus({
            id: invoice.id,
            updateRequest: {
              status: invoice.status === 'voided' ? 'open' : 'voided',
            },
          });

          setShowVoidConfirmModal(false);
        }}
      >
        <div>
          Are you sure that you want to{' '}
          {invoice.status === 'voided' ? 'un-void' : 'void'} this invoice?
        </div>
      </ConfirmModal>
    </>
  );
};

export default InvoiceOptionsButton;
