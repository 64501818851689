import {
  QueryClient,
  QueryObserverResult,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { getErrorCode, InviteRequest, UserEmail, UserProfile } from 'common';

export enum UserStatus {
  active = 'Active',
  inactive = 'Inactive',
  pending = 'Pending',
}

const GET_ALL_USERS_PATH = '/api/latest/data/users';
const GET_ALL_USERS_BY_ROLE_PATH = '/api/latest/data/roles';

const createUserInvite = async (params: InviteRequest) => {
  const { data } = await apiClient.createInvite(params);
  return data;
};

export const useCreateUserInvite = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [GET_ALL_USERS_PATH + 'invite'],
    mutationFn: createUserInvite,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [GET_ALL_USERS_PATH] });
      await qc.invalidateQueries({ queryKey: [GET_ALL_USERS_BY_ROLE_PATH] });
    },
  });

export const useCreateUserEmail = (
  userId: string,
  onSuccess: (data: UserEmail) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getUserPath(userId)],
    mutationFn: async (params: UserEmail) => {
      const result = await apiClient.addUserEmail(userId, params);
      return result.data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [getUserPath(userId)] });
    },
  });

const getUserPath = (userId: string) => {
  return GET_ALL_USERS_PATH + '/' + userId;
};
export const useGetAllUsers = (): QueryObserverResult<UserProfile[]> =>
  useQuery<UserProfile[]>({
    queryKey: [GET_ALL_USERS_PATH],
    queryFn: async () => {
      const { data } = await apiClient.getAllUsers();
      return data;
    },
  });

export const useGetAllUsersByRole = (
  roleType: NonNullable<UserProfile['roleType']>
): UseQueryResult<UserProfile[]> =>
  useQuery({
    queryKey: ['apiClient.getUsersForRole', roleType],
    queryFn: async () => {
      const { data } = await apiClient.getUsersForRole(roleType);
      return data;
    },
  });

export const useGetUser = (userId: string): UseQueryResult<UserProfile> => {
  return useQuery({
    queryKey: [getUserPath(userId)],
    queryFn: async () => {
      const { data } = await apiClient.getUserById(userId);
      return data;
    },
    enabled: !!userId,
  });
};

export const useGetCurrentUser = (
  currentUserId: string
): UseQueryResult<UserProfile> => {
  return useQuery({
    queryKey: ['apiClient.getUserById', currentUserId],
    queryFn: async () => {
      const { data } = await apiClient.getUserById(currentUserId);
      return data;
    },
  });
};

const submitUser = async (
  params: Parameters<typeof apiClient.createUser>[0]
) => {
  const { data } = await apiClient.createUser(params);
  return data;
};

export const useCreateUser = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [GET_ALL_USERS_PATH],
    mutationFn: submitUser,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [GET_ALL_USERS_PATH] });
      await qc.invalidateQueries({ queryKey: [GET_ALL_USERS_BY_ROLE_PATH] });
    },
  });

const updateUser = async ({ ...postData }: any) => {
  const { data } = await apiClient.updateUser(postData.id, postData);
  return data;
};

export const useUpdateUser = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['UpdateUser'],
    mutationFn: updateUser,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [GET_ALL_USERS_PATH] });
      await qc.invalidateQueries({ queryKey: [GET_ALL_USERS_BY_ROLE_PATH] });
    },
  });

const deleteUser = async ({ ...postData }: any) => {
  const { data } = await apiClient.deleteUser(postData.id);
  return data;
};

export const useDeleteUser = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['DeleteUser'],
    mutationFn: deleteUser,
    onSuccess,
    onError: (error: unknown) => {
      const errorMessage =
        getErrorCode(error) === 'CONSTRAINT_ERROR'
          ? 'Cannot delete user'
          : 'Failed to delete user';
      onError(errorMessage);
    },
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [GET_ALL_USERS_PATH] });
      await qc.invalidateQueries({ queryKey: [GET_ALL_USERS_BY_ROLE_PATH] });
    },
  });
