import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Card,
  CardHeader,
  Loading,
  log,
  OrgConfig,
  PaymentTerm,
  Type,
  useToast,
  useTranslation,
} from 'common';
import React, { useEffect, useState } from 'react';
import {
  setOrgConfigDefault,
  useFindOrgDefault,
} from '../../../services/orgDefaults';
import {
  useBatchUpdatePaymentTerms,
  usePaymentTerms,
} from '../../../services/paymentTerms';
import PaymentTermCard from './PaymentTermCard';

const paymentTermTypes = [
  'upon_receipt',
  'end_of_month',
  'net_15',
  'on_15th',
  'net_30',
  'net_45',
  'net_60',
  'net_75',
  'net_90',
];

export const sortPaymentTerms = (
  terms: PaymentTerm[],
  enabledOnly: boolean
) => {
  const termList = terms.sort((p1, p2) => {
    const p1Type = paymentTermTypes.indexOf(p1.name || p1.termType || '');
    const p2Type = paymentTermTypes.indexOf(p2.name || p2.termType || '');

    if (p1Type > p2Type) {
      return 1;
    }

    if (p1Type !== -1 && p1Type < p2Type) {
      return -1;
    }

    return 0;
  });

  if (enabledOnly) {
    return termList.filter((item) => item.enabled);
  }
  return termList;
};

const PaymentTerms: React.FC = () => {
  const qc = useQueryClient();
  const showToast = useToast();
  const { tk } = useTranslation();

  const [defaultPaymentTerm, setDefaultPaymentTerm] = useState<
    string | undefined
  >();

  const [changed, setChanged] = useState<boolean>(false);

  const [draftPaymentTerms, setDraftPaymentTerms] = useState<
    PaymentTerm[] | undefined
  >(undefined);

  const {
    data: paymentTerms,
    isLoading: paymentTermsLoading,
    refetch: refetchTerms,
  } = usePaymentTerms();

  const CONFIG_KEY = 'defaultPaymentTerm' as OrgConfig['configKey'];

  const { data: serverDefaultPaymentTerm, isLoading: defaultConfigLoading } =
    useFindOrgDefault(CONFIG_KEY);

  // TODO: Remove once System Test flakiness is resolved
  log.debug(
    'render',
    paymentTerms,
    draftPaymentTerms,
    serverDefaultPaymentTerm
  );

  useEffect(() => {
    // TODO: Remove once System Test flakiness is resolved
    log.debug('paymentTerms useEffect', paymentTerms, draftPaymentTerms);
    if (paymentTerms && paymentTerms.length && !draftPaymentTerms?.length) {
      paymentTerms.forEach((t) => {
        if (serverDefaultPaymentTerm === t.termType) {
          t.enabled = true;
        }
      });

      setDraftPaymentTerms(sortPaymentTerms(paymentTerms, false));
    }
  }, [paymentTerms, paymentTerms?.length, paymentTermsLoading]);

  useEffect(() => {
    // TODO: Remove once System Test flakiness is resolved
    log.debug('serverDefaultPaymentTerm useEffect', serverDefaultPaymentTerm);
    if (serverDefaultPaymentTerm && serverDefaultPaymentTerm.stringValue) {
      setDefaultPaymentTerm(serverDefaultPaymentTerm.stringValue);
    }
  }, [serverDefaultPaymentTerm, defaultConfigLoading]);

  const onActiveToggle = (term: any) => {
    setChanged(true);
    let validToggle = true;
    if (draftPaymentTerms) {
      const tempTerms = draftPaymentTerms;
      const count = tempTerms.filter((t) => t.enabled).length;

      let resetDefault = false;

      validToggle =
        tempTerms
          .map((t) => {
            if (t.id === term.id) {
              t.enabled = !t.enabled;
              if (count <= 1 && !t.enabled) {
                // if they are disabling last one, show a warning, reset default and reenable term
                t.enabled = true;
                resetDefault = true;
                showToast.warning(tk('Need to have at least one payment term'));
                return false;
              }
              // if we are 0, set the new one as default
              // if we are removing default term, reset default
              if (
                (t.enabled && count === 0 && t.termType) ||
                (!t.enabled && defaultPaymentTerm === t.termType)
              ) {
                resetDefault = true;
              }
            }
            return true;
          })
          .filter((t) => !t).length === 0;

      // This is throwing always-false because we are setting a let inside a map
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (resetDefault) {
        const enabledOnes = tempTerms.filter(
          (t) =>
            defaultPaymentTerm && t.enabled && defaultPaymentTerm !== t.termType
        );
        const defaultTerm = enabledOnes[0]?.termType;
        setDefaultPaymentTerm(defaultTerm);
      }

      setDraftPaymentTerms(tempTerms);
    }
    return validToggle;
  };

  const { mutate: updatePaymentTerms } = useBatchUpdatePaymentTerms(
    draftPaymentTerms || [],
    () => {
      showToast.info(tk('Updated payment terms'));
    },
    async () => {
      showToast.error(tk('Failure saving payment terms'));
      await refetchTerms();
    },
    qc
  );

  const onSave = async () => {
    await setOrgConfigDefault(
      CONFIG_KEY,
      {
        configKey: CONFIG_KEY,
        stringValue: defaultPaymentTerm,
      },
      qc
    ).then(() => {
      setChanged(false);
      updatePaymentTerms();
    });
  };

  const getLabel = (term: PaymentTerm) => {
    // TODO do tk lookup
    return term.name || term.termType || '';
  };

  if (paymentTermsLoading || defaultConfigLoading || !draftPaymentTerms) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader name="Payment terms">
        <Button label="Save" onClick={onSave} isDisabled={!changed} />
      </CardHeader>
      <Type paragraph className="mb-6">
        <p>
          Here is where you set the payment terms supported by your
          organization.
        </p>
        Toggling payment terms will not have any effect on shared proposals and
        subscriptions.
      </Type>

      <div className="bg-slate-lightest rounded-md p-6">
        <div className="font-semibold text-md pb-2">
          Term options for proposals
        </div>
        <div className="text-xs pb-8">
          Selected terms will be selectable options for proposals.
        </div>
        <div />
        {draftPaymentTerms.map((term) => (
          <PaymentTermCard
            key={term.id}
            isDefault={term.termType === defaultPaymentTerm}
            name={getLabel(term)}
            isActive={!!term.enabled}
            onActiveToggle={() => onActiveToggle(term)}
            onSetDefault={() => {
              setChanged(true);
              setDefaultPaymentTerm(term.termType);
            }}
          />
        ))}
      </div>
    </Card>
  );
};

export default PaymentTerms;
