import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ApprovalRequestSummary, Button, Icon } from 'common';

import styles from './ApprovalItem.module.scss';

export const ApprovalItem = ({ item }: { item: ApprovalRequestSummary }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.approvalItem}>
      <div className={styles.approvalIcon}>
        <Icon.ApprovalRequest height={36} width={36} />
      </div>
      <div>
        <div className={styles.approvalItemName}>{item.proposal?.name}</div>
        <div className={styles.approvalItemMessage}>
          {`${item.requester.firstName} ${item.requester.lastName} requested your
        approval on proposal ${item.proposal?.name} to ${item.proposal?.customer?.name}`}
        </div>

        <Button
          className={styles.approvalItemButton}
          type="primary"
          label="View proposal"
          onClick={() => {
            navigate(`/proposals/${item.proposal?.id}`);
          }}
        />
      </div>
    </div>
  );
};
