import { useState } from 'react';

type IssueRefundDrawerType = {
  isIssueRefundDrawerOpen: false | true;
  selectedTransferId: string | null;
};

export const useIssueRefundDrawer = () => {
  const [state, setState] = useState<IssueRefundDrawerType>({
    isIssueRefundDrawerOpen: false,
    selectedTransferId: null,
  });

  const openIssueRefundDrawer = (transferId: string) => {
    setState({
      isIssueRefundDrawerOpen: true,
      selectedTransferId: transferId,
    });
  };

  const closeIssueRefundDrawer = () => {
    setState({
      isIssueRefundDrawerOpen: false,
      selectedTransferId: null,
    });
  };

  return {
    openIssueRefundDrawer,
    closeIssueRefundDrawer,
    selectedTransferId: state.selectedTransferId,
    isIssueRefundDrawerOpen: state.isIssueRefundDrawerOpen,
  };
};
