import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  useDeleteAttachmentConfig,
  useUpdateAttachmentConfig,
} from '../../../services/api/attachments/attachments';
import EditProposalAttachmentOrAttachmentConfig from './EditProposalAttachmentOrAttachmentConfig';
import {
  AttachmentConfig,
  capitalizeFirstLetter,
  getErrorMessage,
  useToast,
  useTranslation,
} from 'common';

interface Props {
  attachmentConfig?: AttachmentConfig;
  isShowing: boolean;
  onClose: () => void;
  onDeleteSuccess: (config: AttachmentConfig) => void;
  onSaveSuccess: (config: AttachmentConfig) => void;
}

const EditAttachmentConfig: React.FC<Props> = ({
  isShowing,
  attachmentConfig,
  onClose,
  onSaveSuccess,
  onDeleteSuccess,
}) => {
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { mutate: updateAttachmentConfig } = useUpdateAttachmentConfig(
    (config: AttachmentConfig) => {
      onSaveSuccess(config);
      showToast.info('Success!');
    },
    (error: unknown) => {
      const errorMessage =
        getErrorMessage(error) ||
        tk('There was a problem uploading this attachment, please try again.');
      showToast.error(capitalizeFirstLetter(errorMessage), 5);
    },
    queryClient
  );

  const { mutate: deleteAttachmentConfig } = useDeleteAttachmentConfig(
    (config: AttachmentConfig) => {
      onDeleteSuccess(config);
      showToast.info('Success!');
    },
    (error: unknown) => {
      const errorMessage =
        getErrorMessage(error) ||
        tk('There was a problem deleting this attachment, please try again.');
      showToast.error(capitalizeFirstLetter(errorMessage), 5);
    },
    queryClient
  );

  const handleClickSave = (
    name: string,
    inEnvelope: boolean,
    autoAdd: boolean
  ) => {
    updateAttachmentConfig({
      id: attachmentConfig?.id,
      type: attachmentConfig?.type,
      name,
      inEnvelope,
      autoAdd,
    });
  };

  const handleClickDelete = () => {
    if (attachmentConfig?.id === undefined) return;
    deleteAttachmentConfig(attachmentConfig.id);
  };

  const isLinkAttachment = attachmentConfig?.type === 'link';

  return (
    <EditProposalAttachmentOrAttachmentConfig
      isShowing={isShowing}
      name={attachmentConfig?.name}
      inEnvelope={attachmentConfig?.inEnvelope}
      autoAdd={attachmentConfig?.autoAdd}
      onClickSave={handleClickSave}
      onClickDelete={handleClickDelete}
      onClose={onClose}
      isLinkAttachment={isLinkAttachment}
      readOnlyUrlOrFileName={
        isLinkAttachment
          ? attachmentConfig.linkUrl
          : attachmentConfig?.document?.fileName
      }
    />
  );
};

export default EditAttachmentConfig;
