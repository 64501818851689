import {
  capitalizeFirstLetter,
  Checkbox,
  cleanOptions,
  CrmPipeline,
  CrmStage,
  FormField,
  HubspotConfig,
  Icon,
  Loading,
  NumberInput,
  OrgConfig,
  SalesforceConfig,
  Select,
  SelectOption,
  ToggleSwitch,
  ToolTip,
  useFlags,
  useTranslation,
  ValidationFormField,
} from 'common';
import React, { useEffect, useState } from 'react';
import {
  ConnectorConfiguration,
  ConnectorInfoObject,
} from '../ConnectorConstants/constants';
import {
  useGetCrmPipelines,
  useGetCrmStages,
} from '../../../../services/externalCrm';
import styles from './ConnectorProposalEquivalent.module.scss';

interface Props {
  configuration?: ConnectorConfiguration;
  connectorConfigValue: HubspotConfig | SalesforceConfig;
  connectorInfo: ConnectorInfoObject;
  setConnectorConfigValue: React.Dispatch<
    React.SetStateAction<OrgConfig['configValue']>
  >;
  setSaveableChanges: (arg: boolean) => void;
}

interface RenewalDealsDate {
  renewalDealCloseDays: number | string;
  renewalDealDaysBeforeEndDate: number | string;
}

const ConnectorProposalEquivalent = ({
  setSaveableChanges,
  connectorInfo,
  configuration,
  setConnectorConfigValue,
  connectorConfigValue,
}: Props) => {
  const { tk } = useTranslation();

  const { data, isError, isLoading } = useGetCrmStages(connectorInfo.source);

  const { hubspotRenewalDeals, salesforceRenewalDeals } = useFlags();

  const { data: crmPipelines, isLoading: loading } = useGetCrmPipelines(
    connectorInfo.source
  );

  const [crmStagesOptions, setCrmStagesOptions] = useState<
    SelectOption<string>[]
  >([]);

  const crmPipelineOptions = cleanOptions(
    crmPipelines?.map((pipeline: CrmPipeline) => ({
      value: pipeline.id,
      name: pipeline.name,
    })),
    'crmPipelineOptions'
  );

  const dropdownValues = {
    zero: '0',
    fifty: '15',
    thirty: '30',
    sixty: '60',
    ninty: '90',
    custom: 'custom',
  };

  const [renewalDealsDate, setRenewalDealsDate] = useState<RenewalDealsDate>({
    renewalDealCloseDays: '',
    renewalDealDaysBeforeEndDate: '',
  });

  useEffect(() => {
    if (crmPipelines) {
      handleUpdateCrmStageOptions(
        (connectorConfigValue as HubspotConfig).renewalDealPipelineId ||
          crmPipelineOptions[0]?.value
      );
    }

    setRenewalDealsDate({
      renewalDealDaysBeforeEndDate: Object.values(dropdownValues).includes(
        String(connectorConfigValue.renewalDealDaysBeforeEndDate)
      )
        ? connectorConfigValue.renewalDealDaysBeforeEndDate!
        : 'custom',
      renewalDealCloseDays: Object.values(dropdownValues).includes(
        String(connectorConfigValue.renewalDealCloseDays)
      )
        ? connectorConfigValue.renewalDealCloseDays!
        : 'custom',
    });
  }, [crmPipelines, connectorConfigValue]);

  if (isError) {
    return <div>Error loading stages</div>;
  }

  const getStageName = (stage: CrmStage) => {
    if (stage.pipelineName) {
      return `${stage.name} (${stage.pipelineName})`;
    }
    return stage.name;
  };

  const stageOptions = cleanOptions(
    data?.map((stage) => ({
      value: stage.id,
      name: getStageName(stage),
    })),
    'stageOptions'
  );

  const handleUpdateCrmStageOptions = (value: string | undefined) => {
    const updatedPipeline = crmPipelines?.find(
      (pipeline: CrmPipeline) => value === pipeline.id
    );
    const stagesOptions = cleanOptions(
      updatedPipeline?.stages?.map((stage) => ({
        value: stage.id,
        name: stage.name,
      })),
      'stageOptions'
    );

    setCrmStagesOptions(stagesOptions);
  };

  const getDealOrOpportunity = (plural: boolean = false) => {
    return connectorInfo.name === 'HubSpot'
      ? plural
        ? 'deals'
        : 'deal'
      : plural
        ? 'opportunities'
        : 'opportunity';
  };

  if (loading || isLoading) {
    return <Loading />;
  }

  const getToggleValue = (line: ValidationFormField<any>): boolean => {
    if (line.nestedKey) {
      return (
        !!(connectorConfigValue as Record<string, any>)[line.nestedKey] &&
        !!(connectorConfigValue as Record<string, any>)[line.nestedKey][
          line.fieldName
        ]
      );
    } else {
      return !!(connectorConfigValue as Record<string, any>)[line.fieldName];
    }
  };

  function handleToggleChange(
    line: ValidationFormField<any>,
    checked: boolean
  ) {
    if (line.fieldName === 'syncLineItems' && !checked) {
      setConnectorConfigValue((prev) => ({
        ...prev,
        lineItemSyncConfig: {
          ignoreQuantityForStairStep: false,
        },
      }));
    }

    setSaveableChanges(true);
    line.nestedKey
      ? setConnectorConfigValue((prev) => ({
          ...prev,
          [line.nestedKey!]: {
            [line.fieldName]: checked,
          },
        }))
      : setConnectorConfigValue((prev) => ({
          ...prev,
          [line.fieldName]: checked,
        }));
  }

  const shouldShowToggle = (line: ValidationFormField<any>) =>
    line.type === 'toggle' &&
    ((line.fieldName !== 'ignoreQuantityForStairStep' &&
      line.fieldName !== 'syncProducts') ||
      connectorConfigValue.syncLineItems);

  return (
    <div className="connector-opportunities">
      <section className="bottom">
        {configuration?.lines
          .filter((line) => line.include !== false)
          .map((line) => (
            <React.Fragment key={`connector-config-${line.fieldName}`}>
              {shouldShowToggle(line) && (
                <FormField>
                  <div className={styles.toggleContainer}>
                    <div className={styles.headerLabel}>
                      {tk(
                        line.humanReadableName ||
                          `${connectorInfo.source}.${line.fieldName}`
                      )}
                      {line.infoText && (
                        <ToolTip title={line.infoText}>
                          <Icon.Information />
                        </ToolTip>
                      )}
                    </div>
                    <ToggleSwitch
                      label="Enabled"
                      name={`input-${line.fieldName}`}
                      onChange={(checked) => {
                        handleToggleChange(line, checked);
                      }}
                      value={getToggleValue(line)}
                    />
                  </div>
                </FormField>
              )}
            </React.Fragment>
          ))}

        {(hubspotRenewalDeals || salesforceRenewalDeals) && (
          <div className={styles.dealsContainer}>
            <FormField>
              <div className={styles.toggleContainer}>
                <p>
                  Automatically create renewal {getDealOrOpportunity()} in{' '}
                  {connectorInfo.name}
                </p>
                <ToggleSwitch
                  label="Enabled"
                  onChange={(e) => {
                    setConnectorConfigValue(
                      (prev) =>
                        ({
                          ...prev,
                          renewalDealDaysBeforeEndDate: 0,
                          renewalDealCloseDays: 0,
                          renewalDealStage: crmStagesOptions[0]?.value,
                          renewalDealPipelineId: crmPipelineOptions[0]?.value,
                          renewalDealCreation: e,
                          renewalDealCreationForAutoRenewals: false,
                          configType: connectorInfo.configType,
                        }) as HubspotConfig | SalesforceConfig
                    );
                    setSaveableChanges(true);
                  }}
                  value={connectorConfigValue.renewalDealCreation}
                />
              </div>
            </FormField>
            {connectorConfigValue.renewalDealCreation && (
              <>
                <div className={styles.deal}>
                  <p>Create renewal {getDealOrOpportunity()}</p>
                  <div className={styles.selectMainContainer}>
                    <div className={styles.selectContainer}>
                      <Select
                        onChange={(e: string) => {
                          setConnectorConfigValue(
                            (prev) =>
                              ({
                                ...prev,
                                renewalDealDaysBeforeEndDate:
                                  e === 'custom' ? undefined : parseInt(e),
                                configType: connectorInfo.configType,
                              }) as HubspotConfig | SalesforceConfig
                          );
                          setRenewalDealsDate(
                            (prev) =>
                              ({
                                ...prev,
                                renewalDealDaysBeforeEndDate: e,
                              }) as RenewalDealsDate
                          );
                          setSaveableChanges(true);
                        }}
                        options={Object.keys(dropdownValues).map((key) => ({
                          value:
                            dropdownValues[key as keyof typeof dropdownValues],
                          name: dropdownValues[
                            key as keyof typeof dropdownValues
                          ],
                        }))}
                        value={renewalDealsDate.renewalDealDaysBeforeEndDate.toString()}
                      />
                    </div>
                    {renewalDealsDate.renewalDealDaysBeforeEndDate ===
                      'custom' && (
                      <div className={styles.numberInput}>
                        <NumberInput
                          maxScale={1}
                          onChange={(e) => {
                            setConnectorConfigValue(
                              (prev) =>
                                ({
                                  ...prev,
                                  renewalDealDaysBeforeEndDate: e,
                                  configType: connectorInfo.configType,
                                }) as HubspotConfig | SalesforceConfig
                            );
                            setSaveableChanges(true);
                          }}
                          placeholder="days"
                          value={
                            connectorConfigValue.renewalDealDaysBeforeEndDate
                          }
                        />
                      </div>
                    )}
                  </div>
                  <ToolTip
                    className={styles.tooltip}
                    title={`Select 0 to create ${getDealOrOpportunity()} on the same day the subscription ends`}
                  >
                    <Icon.Information />
                  </ToolTip>
                  <p>days before subscription term ends,</p>
                </div>

                <div className={styles.deal}>
                  {connectorInfo.name === 'HubSpot' && (
                    <div className={styles.deal}>
                      <div>In the pipeline</div>
                      <div className={styles.selectInput}>
                        <Select
                          onChange={(e) => {
                            setConnectorConfigValue(
                              (prev) =>
                                ({
                                  ...prev,
                                  renewalDealPipelineId: e,
                                  configType: connectorInfo.configType,
                                }) as HubspotConfig
                            );
                            handleUpdateCrmStageOptions(e);
                            setSaveableChanges(true);
                          }}
                          options={crmPipelineOptions}
                          placeholder="Select pipeline"
                          value={
                            (connectorConfigValue as HubspotConfig)
                              .renewalDealPipelineId ||
                            crmPipelineOptions[0]?.name
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div>In the stage</div>
                  <div className={styles.selectInput}>
                    <Select
                      onChange={(e) => {
                        setConnectorConfigValue(
                          (prev) =>
                            ({
                              ...prev,
                              renewalDealStage: e.toString(),
                              configType: connectorInfo.configType,
                            }) as HubspotConfig
                        );
                        setSaveableChanges(true);
                      }}
                      options={
                        connectorInfo.name === 'HubSpot'
                          ? crmStagesOptions
                          : stageOptions
                      }
                      placeholder="Select stage"
                      value={connectorConfigValue.renewalDealStage}
                    />
                  </div>
                </div>
                <div className={styles.deal}>
                  <div>With the close date</div>
                  <div className={styles.selectMainContainer}>
                    <div className={styles.selectContainer}>
                      <Select
                        onChange={(e) => {
                          setConnectorConfigValue(
                            (prev) =>
                              ({
                                ...prev,
                                renewalDealCloseDays:
                                  e === 'custom' ? undefined : parseInt(e),
                                configType: connectorInfo.configType,
                              }) as HubspotConfig | SalesforceConfig
                          );
                          setRenewalDealsDate(
                            (prev) =>
                              ({
                                ...prev,
                                renewalDealCloseDays: e,
                              }) as RenewalDealsDate
                          );
                          setSaveableChanges(true);
                        }}
                        options={Object.keys(dropdownValues).map((key) => ({
                          value:
                            dropdownValues[key as keyof typeof dropdownValues],
                          name: dropdownValues[
                            key as keyof typeof dropdownValues
                          ],
                        }))}
                        value={renewalDealsDate.renewalDealCloseDays.toString()}
                      />
                    </div>
                    {renewalDealsDate.renewalDealCloseDays === 'custom' && (
                      <div className={styles.numberInput}>
                        <NumberInput
                          maxScale={1}
                          onChange={(e) => {
                            setConnectorConfigValue(
                              (prev) =>
                                ({
                                  ...prev,
                                  renewalDealCloseDays: e,
                                  configType: connectorInfo.configType,
                                }) as HubspotConfig | SalesforceConfig
                            );
                            setSaveableChanges(true);
                          }}
                          placeholder="days"
                          value={connectorConfigValue.renewalDealCloseDays}
                        />
                      </div>
                    )}
                  </div>
                  <ToolTip
                    className={styles.tooltip}
                    title="Select 0 to set close date as subscription term end date"
                  >
                    <Icon.Information />
                  </ToolTip>
                  <p>days before subscription end date.</p>
                </div>
                <div>
                  <div>
                    <Checkbox
                      label={`Create ${getDealOrOpportunity(true)} for auto-renewed subscriptions`}
                      name="Upfront"
                      onChange={(e) => {
                        setConnectorConfigValue(
                          (prev) =>
                            ({
                              ...prev,
                              renewalDealCreationForAutoRenewals: e,
                              configType: connectorInfo.configType,
                            }) as HubspotConfig | SalesforceConfig
                        );
                        setSaveableChanges(true);
                      }}
                      value={
                        connectorConfigValue.renewalDealCreationForAutoRenewals
                      }
                    />
                  </div>
                  <div className={styles.captionText}>
                    {tk(
                      `${capitalizeFirstLetter(getDealOrOpportunity(true))} will be created in a closed won state with a close date the same as the subscription renewal date`
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default ConnectorProposalEquivalent;
