import { Icon } from 'common';
import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

export interface NavItemProps {
  className?: string;
  isError?: boolean;
  label: string;
  logo?: React.ReactElement;
  path: string;
  tag?: React.ReactElement;
}

const NavItem: React.FC<NavItemProps> = ({
  isError,
  label,
  logo,
  path,
  className,
  tag,
}) => {
  return (
    <NavLink
      to={path}
      key={path}
      className={({ isActive }) =>
        clsx('nav-item', className, { active: isActive })
      }
      data-testid={label}
    >
      {logo && <div className="nav-link-logo">{logo}</div>}
      {label}
      {tag && <div className="nav-link-tag">{tag}</div>}
      {isError && (
        <Icon.Alert
          className="error-icon"
          fill="var(--red)"
          width={24}
          height={24}
        />
      )}
    </NavLink>
  );
};

export default NavItem;
