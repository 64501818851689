import React, { useRef, useState } from 'react';
import { RefSelectProps, Select } from 'antd';
import clsx from 'clsx';

import {
  CreateProposalApprovalRuleRequest,
  LinkButton,
  ProposalApprovalRuleConfig,
  ProposalSummary,
} from 'common';

import {
  GroupOption,
  RuleInfo,
  searchFilterOption,
} from '../rules/_approvalRulesConfig';
import {
  MultiSelectRuleOptions,
  useMultiSelectRuleData,
} from '../rules/useMultiSelectRuleData';

import styles from './ProposalRuleBuilderStyles.module.scss';
interface Props {
  allGroupOptions: GroupOption[];
  createRule: (payload: CreateProposalApprovalRuleRequest) => void;
  onCancelAdd: () => void;
  proposalType: ProposalSummary['proposalType'];
  selectedRule: RuleInfo;
}

export const ProposalMultiselectBuilder = ({
  proposalType,
  createRule,
  selectedRule,
  onCancelAdd,
  allGroupOptions,
}: Props) => {
  const [values, setValues] = useState<string[]>([]);

  const selectRef = useRef<RefSelectProps>(null);

  const { multiSelectRuleOptions } = useMultiSelectRuleData();
  const selectOptions =
    multiSelectRuleOptions[selectedRule.name as keyof MultiSelectRuleOptions];

  const handleSave = (newValue: string[], groupId: string[]) => {
    const payload = {
      name: `${selectedRule.name}-${proposalType}`,
      config: {
        [selectedRule.configKey]: newValue,
        proposalType,
        ruleType: selectedRule.name as ProposalApprovalRuleConfig['ruleType'],
      },
      approvalGroupIds: groupId,
    };

    createRule(payload);
  };

  const handleSaveIfValid = (id: string[]) => {
    if (values.length > 0 && id.length > 0) {
      handleSave(values, id);
    }
  };

  const handleSelectChange = (selectedValues: string[]) => {
    setValues(selectedValues);
  };

  const handleEnter = () => selectRef.current?.focus();

  return (
    <div
      className={clsx(
        styles.ruleRow,
        values.length > 0 && styles.showingGroupsSelect
      )}
    >
      <div className={styles.condition}>{selectedRule.conditionOperator}</div>

      <div className={styles.input}>
        <Select
          autoFocus
          onChange={handleSelectChange}
          onBlur={handleEnter}
          onInputKeyDown={handleEnter}
          value={values}
          mode="multiple"
          className={styles.input}
          options={selectOptions}
        />
      </div>

      {values.length < 1 && (
        <LinkButton className={styles.cancel} onClick={onCancelAdd}>
          cancel
        </LinkButton>
      )}

      {values.length > 0 && (
        <div className={styles.groups}>
          {allGroupOptions.length > 0 && (
            <Select
              ref={selectRef}
              onChange={handleSaveIfValid}
              value={[]}
              options={allGroupOptions}
              mode="multiple"
              filterOption={searchFilterOption}
              className={styles.groupWidth}
              placeholder="Select approval group"
              popupMatchSelectWidth={false}
            />
          )}
        </div>
      )}
    </div>
  );
};
