import React, { Fragment } from 'react';
import { BubbleMenu, Editor } from '@tiptap/react';
import {
  ButtonFunction,
  EditorFeature,
  EditorFeatureKey,
} from './editorCommon';
import { Popover as AntdPopover } from 'antd';
import clsx from 'clsx';
import styles from './EditorBar.module.scss';

interface Props {
  editor: Editor;
  features: EditorFeature[];
}

type FunctionKeyPair = [ButtonFunction, EditorFeatureKey];

const EditorBar: React.FC<Props> = ({ features, editor }) => {
  const barButtons: FunctionKeyPair[] = features.reduce<FunctionKeyPair[]>(
    (previousValue, feature) =>
      feature.barButtons
        ? [...previousValue, [feature.barButtons, feature.key]]
        : previousValue,
    []
  );

  const contextButtons: FunctionKeyPair[] = features.reduce<FunctionKeyPair[]>(
    (previousValue, feature) =>
      feature.contextButtons
        ? [...previousValue, [feature.contextButtons, feature.key]]
        : previousValue,
    []
  );

  if (!barButtons.length && !contextButtons.length) {
    return null;
  }

  return (
    <div className={clsx(styles.bar, styles.buttons)}>
      {barButtons.map(([buttonFunction, key]) => (
        <Fragment key={key}>{buttonFunction(editor)}</Fragment>
      ))}

      {contextButtons.length > 0 && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{
            duration: 100,
            moveTransition: 'transform 0.1s ease-out',
            offset: [0, 0],
          }}
        >
          <AntdPopover
            overlayClassName={styles.popover}
            open
            color="var(--text-black)"
            getPopupContainer={(triggerNode) =>
              // This is to keep the popover inside the moving bubble menu
              triggerNode.parentElement || document.body
            }
            content={
              <div className={clsx(styles.bubbleMenu, styles.buttons)}>
                {contextButtons.map(([buttonFunction, key]) => (
                  <Fragment key={key}>{buttonFunction(editor)}</Fragment>
                ))}
              </div>
            }
          />
        </BubbleMenu>
      )}
    </div>
  );
};

export default EditorBar;
