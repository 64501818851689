import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  StripeAccount,
  StripeConnectRequest,
  StripeConnectResponse,
} from 'common';
import { apiClient } from '../../httpClients/app';
import { getBaseHostUrl } from '../../helper';
import { SETTINGS_STRIPE } from '../../../core-utils/routes';

const STRIPE_PATH = '/api/latest/settings/stripe';
const STRIPE_CONNECT_ACCOUNT_PATH = `${STRIPE_PATH}/init`;
const STRIPE_CONNECT_ACCOUNT_UPDATE_PATH = `${STRIPE_PATH}/update`;
const STRIPE_CONNECT_ACCOUNT_FINISH_PATH = `${STRIPE_PATH}/finish`;

export const useStripeAccount = (): UseQueryResult<StripeAccount | undefined> =>
  useQuery({
    queryKey: [STRIPE_PATH],
    queryFn: async () => {
      const { data } = await apiClient.getStripeAccount();
      return data;
    },
  });

interface StripeSetup {
  accountId?: string;
  connectExisting?: boolean;
  reconnect?: boolean;
  country: string;
  currency: string;
}

const connectHook = async (setup: StripeSetup) => {
  const body: StripeConnectRequest = createRequest(setup);
  const { data } = await apiClient.initConnectStripe(body);
  return data;
};

const createRequest = (setup: StripeSetup): StripeConnectRequest => {
  return {
    refreshUrl: `${getBaseHostUrl()}${SETTINGS_STRIPE}`,
    returnUrl: `${getBaseHostUrl()}${SETTINGS_STRIPE}`,
    country: setup.country,
    currency: setup.currency,
    accountId: setup.accountId,
    connectExisting: setup.connectExisting,
    reconnect: setup.reconnect,
  };
};

const updateHook = async (setup: StripeSetup) => {
  const body: StripeConnectRequest = createRequest(setup);
  const { data } = await apiClient.updateConnectStripe(body);
  return data;
};

const removeAccount = async () => {
  const { data } = await apiClient.deleteConnectStripe();
  return data;
};

export const useConnectStripeAccount = (
  onSuccess: (data: StripeConnectResponse) => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationKey: [STRIPE_CONNECT_ACCOUNT_PATH],
    mutationFn: connectHook,
    onSuccess,
    onError,
  });

export const useRemoveConnectStripeAccount = (
  onSuccess: (data: unknown) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [STRIPE_PATH],
    mutationFn: removeAccount,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [
          STRIPE_PATH,
          STRIPE_CONNECT_ACCOUNT_UPDATE_PATH,
          STRIPE_CONNECT_ACCOUNT_PATH,
          STRIPE_CONNECT_ACCOUNT_FINISH_PATH,
        ],
      });
    },
  });

export const useUpdateConnectStripeAccount = (
  onSuccess: (data: StripeConnectResponse) => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationKey: [STRIPE_CONNECT_ACCOUNT_UPDATE_PATH],
    mutationFn: updateHook,
    onSuccess,
    onError,
  });

export const finishStripeConnectPost = async (code: string) => {
  const { data } = await apiClient.finish({ code });
  return data;
};
