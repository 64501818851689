import React, { useEffect, useState } from 'react';
import {
  emailValidator,
  FormField,
  getErrorMessage,
  Input,
  useFlags,
  UserEmail,
  UserProfile,
  useToast,
  useTranslation,
} from 'common';
import Label from '../../../components/Label';
import { useCreateUserEmail } from '../../../services/users';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  profile: UserProfile;
}

const UserEmailAliasForm: React.FC<Props> = ({ profile }: Props) => {
  const { userAlias } = useFlags();
  const { tk } = useTranslation();
  const showToast = useToast();
  const queryClient = useQueryClient();

  const [userEmails, setUserEmails] = useState<UserEmail[] | undefined>(
    profile.emails
  );
  const [altEmail, setAltEmail] = useState('');

  const handleSuccess = (data: UserEmail) => {
    showToast.success(tk('Email saved'));
    const arr = userEmails || [];
    arr.push(data);
    setUserEmails(arr);
    setAltEmail('');
  };

  const handleError = (responseError: unknown) => {
    const responseMsg =
      getErrorMessage(responseError) ||
      'There was an issue updating the user. Please try again.';
    showToast.error(responseMsg);
  };

  const { mutate: createNewEmail } = useCreateUserEmail(
    profile.user!.id!,
    handleSuccess,
    handleError,
    queryClient
  );

  useEffect(() => {
    setUserEmails(profile.emails);
  }, [profile]);

  if (!userAlias) return null;

  return (
    <div>
      <Label name={tk('Alternative Email')} />
      {userEmails?.map((e) => {
        return (
          <FormField label={tk('Alternative email')} key={e.id}>
            <Input dataTestId="alt-email" value={e.email} isDisabled />
          </FormField>
        );
      })}
      <FormField label={tk('Add new')}>
        <Input
          dataTestId="alt-email-newer"
          value={altEmail}
          onBlur={(value) => {
            const results = emailValidator(value);
            if (!results) {
              createNewEmail({
                status: 'active',
                email: value,
              });
            } else {
              showToast.error(tk('Invalid email address'));
            }
          }}
          onChange={(value) => {
            setAltEmail(value);
          }}
        />
      </FormField>
    </div>
  );
};

export default UserEmailAliasForm;
