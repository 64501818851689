import './EventStyles.scss';
import {
  ErrorCell,
  formatDateOrDefault,
  QueryTable,
  SimpleCell,
  TransferSyncSummary,
} from 'common';
import React from 'react';
import { systemClient } from 'app/src/services/httpClients/systemclient';
import {
  getLatestEvent,
  reconciliationFilters,
} from '../../../services/reoconcile';
import IDField from 'app/src/components/IDField';
import StatusLabel from 'app/src/components/StatusLabel';
import { Link } from 'react-router-dom';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';

const SystemPaymentReconciliation: React.FC = () => {
  const getLink = (data: TransferSyncSummary) => {
    const event = getLatestEvent(data);
    if (event) {
      const href = !ENVIRONMENT_CONFIG.isProduction
        ? 'https://app.sandbox.qbo.intuit.com/app/invoice?txnId=' +
          event.externalId
        : 'https://app.qbo.intuit.com/app/invoice?txnId=' + event.externalId;
      return (
        <div className="underline text-blue">
          <Link to={href}>{event.externalId}</Link>
        </div>
      );
    }
    return null;
  };

  const cells: SimpleCell<TransferSyncSummary>[] = [
    {
      key: 'orgName',
      headerLabel: 'Org name',
      overflowCell: true,
      renderCell: (data: TransferSyncSummary) => (
        <div>
          <div>{data.orgName}</div>
        </div>
      ),
    },
    {
      renderCell: (data: TransferSyncSummary) => (
        <div className="underline text-blue">
          <Link
            to={`/customers/${data.customerReference?.id}`}
            className=" block truncate"
          >
            {data.customerReference?.name}
          </Link>
        </div>
      ),
      key: 'contract.buyer.name',
      headerLabel: 'Customer name',
      sortable: true,
      overflowCell: true,
    },
    {
      renderCell: (data: TransferSyncSummary) => (
        <IDField documentID={data.id}>{data.id.substring(0, 30)}</IDField>
      ),
      width: 350,
      key: 'id',
      headerLabel: 'Transaction no.',
    },
    {
      renderCell: (data: TransferSyncSummary) => (
        <div>{formatDateOrDefault(data.createdAt)}</div>
      ),
      key: 'createdAt',
      headerLabel: 'Created date',
      width: 170,
      sortable: true,
    },
    {
      renderCell: (data: TransferSyncSummary) =>
        formatDateOrDefault(data.updatedAt),
      key: 'updatedAt',
      headerLabel: 'Updated date',
      width: 170,
      sortable: true,
    },
    {
      renderCell: (data: TransferSyncSummary) => <>{getLink(data)}</>,
      key: 'paymentMethod.name',
      headerLabel: 'QuickBooks transaction no.',
    },
    {
      renderCell: (data: TransferSyncSummary) =>
        formatDateOrDefault(getLatestEvent(data)?.createdAt),
      key: 'lastSyncedAt',
      headerLabel: 'Last sync date',
      sortable: true,
    },
    {
      renderCell: (data: TransferSyncSummary) => {
        const eventDetail = getLatestEvent(data);
        if (eventDetail?.status === 'success') {
          return <StatusLabel status="success" feature="invoice" />;
        } else if (eventDetail?.status === 'error') {
          return (
            <ErrorCell
              error={eventDetail.error}
              statusLabel={<StatusLabel status="error" feature="invoice" />}
            />
          );
        }
        return 'Not synced';
      },
      key: 'lastEvent.status',
      headerLabel: 'Sync status',
      width: 180,
      align: 'center',
      sortable: true,
    },
  ];

  return (
    <QueryTable<TransferSyncSummary>
      queryKey="payment-sync-summary"
      fetchList={systemClient.getSystemEventsByTransfer}
      filters={reconciliationFilters(true, [
        {
          field: 'id',
          operation: 'equals',
        },
      ])}
      cells={cells}
      dataTestId="payment-sync-table"
      minWidth="900px"
      tooltipText="Search for Cacheflow payment id"
    />
  );
};

export default SystemPaymentReconciliation;
