import React from 'react';

import styles from './AutoRenewalWarningBanner.module.scss';

export const AutoRenewalWarningBanner = () => {
  return (
    <div className={styles.autoRenewalWarningBanner}>
      Auto renewal will be cancelled if change proposals or manual renewals are
      not accepted before the effective date of the next scheduled auto-renewal.
    </div>
  );
};
