import { useAuth } from 'app/src/Auth';

import {
  Icon,
  PopOutMenu,
  PopOutMenuOption,
  Proposal,
  ProposalSummary,
  useTranslation,
} from 'common';
import React from 'react';

import { useIsCurrentUserAdmin } from '../../../core-utils/helperFunctions/userServiceHelper';
import ProposalArchiveOption from '../ProposalEditor/EditorHeader/ProposalArchiveOption';
import ProposalDeleteOption from '../ProposalEditor/EditorHeader/ProposalDeleteOption';
import ProposalSyncToButton from '../ProposalEditor/ProposalSyncToButton';
import ProposalUpdatePricingButton from '../ProposalEditor/ProposalUpdatePricingButton';
import BuyerCheckoutOption from './BuyerCheckoutOption';
import CopyOptions from './CopyOptions';
import ToggleLegacyCheckoutButton from '../ProposalEditor/ToggleLegacyCheckoutButton';

interface Props {
  editorContext?: boolean;
  onClickSettings?: () => void;
  onClickViewActivity?: () => void;
  onClickViewPreview?: () => void;
  proposal: Proposal | ProposalSummary;
  showDelete?: boolean;
}

const ProposalOptionsButton: React.FC<Props> = ({
  proposal,
  editorContext,
  onClickViewPreview,
  onClickViewActivity,
  onClickSettings,
  showDelete,
}) => {
  const { tk } = useTranslation();
  const currentUserAdmin = useIsCurrentUserAdmin();
  const auth = useAuth();

  return (
    <PopOutMenu
      aria-label={tk('Proposal Options')}
      aria-role="button"
      borderless={editorContext}
      dataTestId="proposal-options"
      name={tk('Proposal Options')}
    >
      {proposal.status !== 'draft' && onClickViewPreview && (
        <PopOutMenuOption
          dataTestId={`open-preview-window-${proposal.id}`}
          icon={Icon.Preview}
          onClick={onClickViewPreview}
          title={tk('Preview')}
        />
      )}

      {onClickViewActivity && (
        <PopOutMenuOption
          icon={Icon.Activity}
          title={tk('Activity')}
          onClick={onClickViewActivity}
          dataTestId={`open-activity-window-${proposal.id}`}
        />
      )}

      <CopyOptions proposal={proposal} editorContext={editorContext} />

      {currentUserAdmin && onClickSettings && (
        <PopOutMenuOption
          icon={Icon.Edit}
          onClick={onClickSettings}
          title={tk('Change owner')}
        />
      )}

      <BuyerCheckoutOption proposal={proposal} editorContext={editorContext} />

      {auth.isSystemAdmin && (
        <>
          <ProposalUpdatePricingButton proposal={proposal} />
          <ToggleLegacyCheckoutButton proposal={proposal} />
        </>
      )}

      <ProposalSyncToButton proposal={proposal} />

      <ProposalArchiveOption proposal={proposal} />

      {editorContext || showDelete ? (
        <ProposalDeleteOption proposal={proposal} />
      ) : null}
    </PopOutMenu>
  );
};

export default ProposalOptionsButton;
