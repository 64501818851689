import React from 'react';
import { Contract, ContractSummary, Icon } from 'common';
import { useContractProposals } from '../../../services/contract';

const useSubscriptionProposal = (contract: Contract) => {
  const { data: contractProposals } = useContractProposals(contract.id);
  return contractProposals && contractProposals.length > 0
    ? contractProposals[0]
    : null;
};

export default useSubscriptionProposal;

export const getPaymentMethodName = (
  contractDetails: Contract | ContractSummary
) => {
  // contract may not have a billing method
  if (contractDetails.billingMethod?.paymentMethod) {
    return (
      <span className="text-xs text-slate">
        {contractDetails.billingMethod.paymentMethod.name}
      </span>
    );
  }

  return (
    <div className="flex">
      <Icon.AlertVariant
        color="red"
        width={21}
        height={21}
        style={{ marginLeft: '-4px', marginTop: '1px' }}
      />
      <span className="text-red truncate flex-1">No payment method</span>
    </div>
  );
};

export const getPaymentMethodDisplay = (contract: Contract) => {
  if (!contract.billingMethod) {
    return (
      <>
        <Icon.AlertVariant
          color="red"
          width="23px"
          height="23px"
          style={{ marginLeft: '-4px' }}
        />
        <span className="text-red">No payment method</span>
      </>
    );
  }

  const paymentMethodName = contract.billingMethod.paymentMethod.name;
  const mask = contract.billingMethod.account?.mask ?? '';
  const paymentType = contract.billingMethod.paymentMethod.paymentType;

  const directDebitAccountNumber =
    contract.billingMethod.account?.accountNumber;

  switch (paymentType) {
    case 'ach':
    case 'manual':
      return `${paymentMethodName}`;

    case 'direct_debit':
      return directDebitAccountNumber
        ? `${paymentMethodName} ****** ${directDebitAccountNumber.slice(-4)}`
        : paymentMethodName;

    default:
      return `${paymentMethodName} ****** ${mask}`;
  }
};
