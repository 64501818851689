import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  CreatePaymentMethodRequest,
  Drawer,
  getErrorMessage,
  log,
  PaymentMethod,
  UpdatePaymentMethodRequest,
  useToast,
  useTranslation,
} from 'common';
import CustomPaymentForm from './CustomPaymentForm';
import ACHForm from './ACHForm';
import {
  useCreatePaymentMethod,
  useUpdatePaymentMethod,
} from '../../../services/paymentMethods';

interface Props {
  closeDrawer: () => void;
  isShowing: boolean;
  methodBeingEdited?: PaymentMethod;
}

const PaymentMethodDrawer: React.FC<Props> = ({
  closeDrawer,
  isShowing,
  methodBeingEdited,
}) => {
  const { tk } = useTranslation();
  const isEditing = !!methodBeingEdited?.id;
  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleSubmitSuccess = () => {
    showToast.info(
      tk(
        isEditing
          ? 'Successfully updated payment method.'
          : 'Successfully created payment method'
      )
    );
    closeDrawer();
  };

  const handleSubmitError = (responseError: unknown) => {
    const errorMsg = getErrorMessage(responseError);
    log.error(responseError);

    if (!errorMsg) {
      return;
    }

    if (errorMsg.indexOf('payment_method_configs_org_id_name_unique') > 0) {
      showToast.error('Name is already in use');
      return;
    }

    showToast.error(errorMsg);
  };

  const { mutate: createPaymentMethod } = useCreatePaymentMethod(
    handleSubmitSuccess,
    handleSubmitError,
    queryClient
  );

  const { mutate: updatePaymentMethod } = useUpdatePaymentMethod(
    methodBeingEdited?.id!,
    handleSubmitSuccess,
    handleSubmitError,
    queryClient
  );

  const submitForm = (paymentMethod: CreatePaymentMethodRequest) => {
    if (isEditing) {
      updatePaymentMethod(paymentMethod as UpdatePaymentMethodRequest);
    } else {
      createPaymentMethod(paymentMethod);
    }
  };

  const isNewCustomMethod = !methodBeingEdited;
  return (
    <Drawer
      onClose={closeDrawer}
      isOpen={isShowing}
      header={isEditing ? 'Edit payment method' : 'Add payment method'}
    >
      <div>
        {isNewCustomMethod && (
          <CustomPaymentForm closeForm={closeDrawer} submit={submitForm} />
        )}

        {!isNewCustomMethod && methodBeingEdited.paymentType === 'ach' && (
          <ACHForm
            paymentMethod={methodBeingEdited}
            closeForm={closeDrawer}
            submit={submitForm}
          />
        )}

        {!isNewCustomMethod && methodBeingEdited.paymentType === 'custom' && (
          <CustomPaymentForm
            methodBeingEdited={methodBeingEdited}
            closeForm={closeDrawer}
            submit={submitForm}
          />
        )}
      </div>
    </Drawer>
  );
};

export default PaymentMethodDrawer;
