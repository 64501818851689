import { ApprovalRequestSummary } from 'common';

export const sortByUpdatedAt = (
  a: ApprovalRequestSummary,
  b: ApprovalRequestSummary
) => {
  if (!a.updatedAt || !b.updatedAt) {
    return 0;
  }

  return a.updatedAt.localeCompare(b.updatedAt);
};

export const isOlder = (date: string) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return new Date(date) < yesterday;
};
