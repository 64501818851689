import React, { useState } from 'react';
import { Icon, useToast } from 'common';
import { saveAs } from 'file-saver';
import { AxiosPromise } from 'axios';
import { useFlowDomain } from 'app/src/core-utils/helperFunctions/userServiceHelper';
import ActionBarButton from 'app/src/components/ActionBarButton';

interface Props {
  fetchFunction: () => AxiosPromise<Blob>;
  fileName: string;
  isDisabled: boolean;
  isCancelled?: boolean;
  objectName: string;
  postDownload?: () => void;
}

const PdfButton: React.FC<Props> = ({
  objectName,
  fileName,
  fetchFunction,
  postDownload,
  isDisabled,
  isCancelled,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const orgName = useFlowDomain();
  const showToast = useToast();

  const downloadPdfSsrAndSave = async () => {
    try {
      const { data } = await fetchFunction();
      saveAs(data, fileName);
    } catch (error) {
      showToast.error(
        `Failed to download ${objectName} pdf. Please try again later.`
      );
      throw error;
    }
  };

  const download = async () => {
    if (!isLoading && orgName) {
      setIsLoading(true);
      try {
        await downloadPdfSsrAndSave();
        postDownload?.();
      } catch (error) {
        showToast.error(`Something went wrong: ${error}`);
      }
      setIsLoading(false);
    }
  };

  const tooltipText = isCancelled
    ? `${objectName} is cancelled and cannot be downloaded`
    : `${objectName} must be valid before it can be downloaded`;

  return (
    <ActionBarButton
      disabledTooltip={tooltipText}
      icon={Icon.Pdf}
      isDisabled={isDisabled || isCancelled}
      isLoading={isLoading}
      onClick={download}
      tooltip="PDF"
    />
  );
};

export default PdfButton;
