import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useFindOrgDefault,
  useOrgConfigDefaults,
} from '../../../../services/orgDefaults';
import { Checkbox, Signature, useToast, useTranslation } from 'common';
import DefaultSignaturePreview from './DefaultSignaturePreview';

interface Props {
  currentUserSignature?: Signature;
}

const OrgDefaultSignature = ({ currentUserSignature }: Props) => {
  const [isSignatureOrgDefault, setIsSignatureOrgDefault] = useState(false);
  const [defaultOrgSignatureId, setDefaultOrgSignatureId] = useState<
    string | undefined
  >(undefined);
  const queryClient = useQueryClient();
  const showToast = useToast();
  const { tk } = useTranslation();

  const { data } = useFindOrgDefault('defaultSignature');
  useEffect(() => {
    if (data) {
      setDefaultOrgSignatureId(data.referenceId);
      setIsSignatureOrgDefault(data.referenceId === currentUserSignature?.id);
    } else {
      setIsSignatureOrgDefault(false);
    }
  }, [data]);

  const onSuccess = () => {
    showToast.info('Setting updated.');
  };
  const onError = () => {
    showToast.info('Error updating setting.');
  };

  const { setConfig, unsetConfig } = useOrgConfigDefaults(
    'defaultSignature',
    onSuccess,
    onError,
    queryClient
  );

  const toggleDefaultSignature = (checked: boolean) => {
    if (checked && currentUserSignature?.id !== undefined) {
      setDefaultOrgSignatureId(currentUserSignature.id);
      setConfig({
        referenceId: currentUserSignature.id,
        referenceType: 'signature',
      });
    } else {
      setDefaultOrgSignatureId(undefined);
      unsetConfig();
    }
  };

  return (
    <div className="mt-4">
      {currentUserSignature && (
        <div className="mb-4">
          <Checkbox
            name="defaultSignatureForOrg"
            value={isSignatureOrgDefault}
            onChange={toggleDefaultSignature}
            label="Make this the default signature for all contracts in the organization"
          />
        </div>
      )}
      <div className="flex gap-4">
        <div>Current organization default:</div>

        {defaultOrgSignatureId ? (
          <DefaultSignaturePreview signatureId={defaultOrgSignatureId} />
        ) : (
          tk('Not set')
        )}
      </div>
    </div>
  );
};

export default OrgDefaultSignature;
