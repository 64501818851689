import { getDaysLeft } from '../../core-utils/helperFunctions/formatDate';
import {
  BillingSchedule,
  Contract,
  isNumber,
  NextPaymentDueSummary,
  Transaction,
  Transfer,
  useTranslation,
} from 'common';

const failedStatuses = ['cancelled', 'failed', 'declined'];
const beingPaidStatuses = ['pending', 'in_progress', 'posted'];

const getTransPaymentStatusOnlyValue = (
  transfer: Transfer | Transaction
): string | undefined => {
  if (isNumber(transfer.amount) && transfer.amount <= 0) {
    return undefined;
  }
  const { status } = transfer;
  return status;
};

export const getPaymentStatusOnlyValue = (
  data: BillingSchedule | NextPaymentDueSummary | undefined,
  contract: Contract | undefined = undefined
): string | undefined => {
  const transfer = data?.transfer;
  if (transfer) {
    return getTransPaymentStatusOnlyValue(transfer);
  } else if (
    contract?.status === 'cancelled' &&
    data &&
    'invoiceStatus' in data &&
    data.invoiceStatus
  ) {
    return data.invoiceStatus;
  } else {
    return '';
  }
};

const paymentNeeded = (
  data: BillingSchedule | NextPaymentDueSummary | undefined
): boolean => {
  return !!data && isNumber(data.amountDue) && data.amountDue > 0;
};

export const isOverdue = (
  data: BillingSchedule | NextPaymentDueSummary | undefined
) => {
  if ((data as any)?.invoiceStatus === 'cancelled') {
    return false;
  }
  if (
    data &&
    data.dueDate &&
    !isPaymentBeingPaid(data) &&
    paymentNeeded(data)
  ) {
    const daysLeftProcess = getDaysLeft(data.dueDate);
    if (daysLeftProcess < 0) return true;
  }
  return false;
};

export const reasonCodeMessage = (
  data: BillingSchedule | undefined
): string => {
  const trans = data?.transfer;
  if (trans) {
    const { tk } = useTranslation();
    const { reasonCode } = trans;
    return reasonCode ? tk(reasonCode) : '';
  }
  return '';
};

export const isPaymentBeingPaid = (
  data: BillingSchedule | NextPaymentDueSummary | undefined
): boolean => {
  if (!paymentNeeded(data)) {
    return true;
  }

  const trans = data?.transfer;
  if (trans) {
    if (isNumber(trans.amount) && trans.amount <= 0) {
      return true;
    }
    const { status: transferStatus } = trans;
    return (
      !!transferStatus &&
      beingPaidStatuses.includes(transferStatus.toLowerCase())
    );
  }
  return false;
};

export const isPosted = (statusVal: string) => statusVal === 'posted';

export const isCancellable = (statusVal: string) => {
  return (
    (isRetryable(statusVal) || statusVal === 'pending') &&
    statusVal !== 'cancelled'
  );
};

export const isRetryable = (statusVal: string) => {
  // allow anyone to retry declined or cancelled transfers
  return ['declined', 'cancelled', 'failed'].includes(statusVal);
};

export const paymentFailed = (statusVal: string) => {
  return statusVal && failedStatuses.includes(statusVal.toLowerCase());
};
