import { useCallback, useMemo, useRef } from 'react';

export function useHeightObserverRef(
  onHeightChanged: (height: number) => void
) {
  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const entry = entries.at(0);

        if (entry) {
          onHeightChanged(entry.contentRect.height);
        }
      }),
    []
  );

  const previousNode = useRef<Element | null>(null);

  return useCallback((node: Element | null) => {
    if (node !== null) {
      if (previousNode.current !== null) {
        observer.unobserve(previousNode.current);
      }
      previousNode.current = node;
      observer.observe(node);
    } else {
      observer.disconnect();
    }
  }, []);
}
