import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Drawer, getCurrencyLabelByCode } from 'common';
import styles from './PricingConfiguration.module.scss';

import {
  useAllCurrencies,
  useOrgCurrencies,
} from 'app/src/services/api/currency/currency';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  productCurrencies: string[];
  saveProductCurrencies: (currencies: string[]) => void;
}
interface CurrencyCheckOptions {
  code: string;
  description?: string;
  selected: boolean;
}
const ManageCurrencyDrawer = ({
  isOpen,
  onClose,
  saveProductCurrencies,
  productCurrencies,
}: Props) => {
  const { data: orgCurrencies } = useOrgCurrencies();
  const { data: currencies } = useAllCurrencies();

  const [availableCurrencies, setAvailableCurrencies] = useState<
    CurrencyCheckOptions[]
  >([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>([
    ...productCurrencies,
  ]);

  useEffect(() => {
    if (!orgCurrencies || !currencies) return;
    const availCurrencies: CurrencyCheckOptions[] = [];
    orgCurrencies.forEach((currency) => {
      if (currency.active) {
        const description = currencies.find(
          (c) => c.code === currency.code
        )?.description;
        availCurrencies.push({
          code: currency.code,
          description,
          selected: selectedCurrencies.includes(currency.code),
        });
      }
    });
    setAvailableCurrencies(availCurrencies);
  }, [productCurrencies, orgCurrencies, currencies, selectedCurrencies]);

  const handleChange = (code: string, checked: boolean) => {
    if (checked) {
      setSelectedCurrencies([...selectedCurrencies, code]);
    } else {
      setSelectedCurrencies(selectedCurrencies.filter((c) => c !== code));
    }
  };

  const handleSave = () => {
    saveProductCurrencies(selectedCurrencies);
    onClose();
  };

  return (
    <Drawer
      className={styles.pricingConfiguration}
      dataTestId="manage-currency-drawer"
      footer={
        <Button
          block
          dataTestId="save-currencies-button"
          label="Done"
          onClick={handleSave}
        />
      }
      header="Configure currencies"
      isOpen={isOpen}
      onClose={onClose}
    >
      <p className={styles.currencyDescription}>
        Configure what currencies are available on this product. You must have
        at least one currency, but it does not need to be the default on your
        organization.
      </p>
      {availableCurrencies.map((currency) => {
        return (
          <Checkbox
            className={styles.currencyCheckbox}
            dataTestId={`currency-${currency.code}`}
            isDisabled={selectedCurrencies.length === 1 && currency.selected}
            key={currency.code}
            label={getCurrencyLabelByCode(currency.code)}
            name={currency.code}
            onChange={(checked) => handleChange(currency.code, checked)}
            value={currency.selected}
          />
        );
      })}
    </Drawer>
  );
};

export default ManageCurrencyDrawer;
