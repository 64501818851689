import React from 'react';
import BankAccountDetailCard from './BankAccountDetailCard';
import AddBankAccountCard from './AddBankAccountCard';
import { Loading } from 'common';
import { useAccounts } from '../../../services/accounts';
import styles from './BankAccounts.module.scss';

const accountType = new Map([
  ['savings', 'Savings account'],
  ['checking', 'Checking account'],
]);

const BankAccounts = () => {
  const { data: accountsData, isLoading } = useAccounts();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.bankAccounts}>
      <AddBankAccountCard />
      {accountsData?.map((account) => (
        <BankAccountDetailCard
          accountNumber={account.mask}
          accountType={accountType.get(account.subtype!)}
          bankName={account.institutionName}
          key={account.id}
        />
      ))}
    </div>
  );
};

export default BankAccounts;
