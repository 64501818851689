import { ConfirmModal, Type } from 'common';
import React from 'react';

export const ConfirmDisableInvoiceInAdvanceModal = ({
  isOpen,
  onConfirm,
}: {
  isOpen: boolean;
  onConfirm: () => void;
}) => {
  return (
    <ConfirmModal isOpen={isOpen} onConfirm={onConfirm}>
      <Type paragraph>
        Adding usage-based products disables the invoice in advance setting on
        the proposal.
      </Type>
    </ConfirmModal>
  );
};
