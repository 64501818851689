import React, { SVGProps } from 'react';

const AvalaraLogoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={
      {
        // enableBackground: 'new 0 0 576 576',
      }
    }
    viewBox="0 0 576 576"
    {...props}
  >
    <style>{'.st1{fill:#fc6600}'}</style>
    <path
      d="M288.92 423.36c-.03.1-26.98-32.94-42.72-50.77l-22.3 52.59c32.06 47.77 53.25 88.83 64.88 108.54 22.75-38.5 78.19-152.82 193.08-259.77l-12.68-29.89c-74.59 55.13-138.11 125.58-180.26 179.3"
      style={{
        fill: '#059bd2',
      }}
    />
    <path
      d="M456.16 549.31h91.01l-80.29-192.16a856.545 856.545 0 0 0-61.27 72.94l50.55 119.22zM328.94 27.03h-80.38L30.31 549.31h91.02l157.82-372.23 7.47-20.2h4.25l7.47 20.2L345.1 287.4c20.83-20.71 43.45-41.25 67.35-60.53L328.94 27.03z"
      className="st1"
    />
  </svg>
);

export default AvalaraLogoSVG;
