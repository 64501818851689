import React, { useState } from 'react';

import {
  ApprovalRequest,
  Button,
  formatDatetime,
  getPersonFullName,
  isArrayNonEmpty,
  Proposal,
} from 'common';

import { ApprovalActionDrawer } from './ApprovalActionDrawer';

import styles from './ApprovalActionBar.module.scss';

interface Props {
  filteredRequests?: ApprovalRequest[];
  proposal: Proposal;
}

const ApprovalActionBar: React.FC<Props> = ({ proposal, filteredRequests }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const getRuleDescriptions = () => {
    if (!isArrayNonEmpty(filteredRequests)) {
      return null;
    }

    return (
      <>
        <br />
        <div>
          {filteredRequests.map((request) => {
            return request.rules?.map((r) => {
              return (
                <p className="mb-1" key={r.name}>
                  {r.description}
                </p>
              );
            });
          })}
        </div>
      </>
    );
  };

  // Even though there can now be multiple approval requests (one for each rule),
  // There all from the same user, so just use the first requests info
  const requester = getPersonFullName(filteredRequests?.[0]?.requester);
  const createdAt = filteredRequests?.[0]?.createdAt;

  return (
    <div className={styles.approvalActionBar}>
      <div className={styles.leftColumn}>
        <div className={styles.bold}>
          Approval requested by {requester}{' '}
          {createdAt ? formatDatetime(createdAt) : null}
        </div>
        {getRuleDescriptions()}
      </div>

      <div className={styles.rightColumn}>
        <Button
          dataTestId="handle-approvals"
          label="Handle approvals"
          onClick={() => setIsDrawerOpen(true)}
        />
      </div>

      <ApprovalActionDrawer
        closeDrawer={() => setIsDrawerOpen(false)}
        isShowing={isDrawerOpen}
        proposal={proposal}
      />
    </div>
  );
};

export default ApprovalActionBar;
