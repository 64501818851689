import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BufferLoginButton } from 'react-social-login-buttons';
import { LOGIN_ROUTE } from '../../../core-utils/routes';
import { useAuthToken } from './hooks';
import { AutoFormModal, ValidationFormField } from 'common';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  clientId?: string;
  isSystem?: boolean;
  prevPath?: string;
  route?: string;
  searchSuffix?: string;
}

interface DevTokenRequest {
  firstName: string;
  forceOrgList?: boolean;
  lastName: string;
  orgDomain?: string;
  orgType?: string;
  role: string;
  source?: string;
  subject: string;
}

const DevButton: React.FC<Props> = ({
  clientId,
  isSystem,
  prevPath,
  searchSuffix,
  route,
}: Props) => {
  const navigate = useNavigate();
  const [fetchDevToken, setFetchDevToken] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [params, setParams] = useState<DevTokenRequest>({
    subject: isSystem ? 'bob@getcacheflow.com' : 'bob@cacheflow.com',
    firstName: 'Bob',
    lastName: 'Barker',
    role: 'admin',
    source: clientId,
  });

  const redirectRoute = () => {
    let tempRoute = LOGIN_ROUTE;
    if (route) {
      tempRoute = route;
    }
    return tempRoute;
  };

  const queryClient = useQueryClient();
  // only fetched if fetchDevToken is true
  const { data, error } = useAuthToken(fetchDevToken, params);

  useEffect(() => {
    if (error) {
      setFetchDevToken(false);
      handleClose();
    }
    if (data && data.token) {
      navigate(
        `${redirectRoute()}?token=${data.token}${
          prevPath ? `&prevPath=${prevPath}` : ''
        }${searchSuffix ? `&searchSuffix=${searchSuffix}` : ''}`
      );
    }
  }, [fetchDevToken, data || error]);

  const handleSave = (req: DevTokenRequest) => {
    setParams(req);
    setFetchDevToken(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const formFields: ValidationFormField<DevTokenRequest>[] = [
    {
      fieldName: 'subject',
      humanReadableName: 'Username',
      isRequired: true,
    },
    {
      fieldName: 'firstName',
      humanReadableName: 'First Name',
      isRequired: true,
    },
    {
      fieldName: 'lastName',
      humanReadableName: 'Last Name',
      isRequired: true,
    },
    {
      fieldName: 'role',
      humanReadableName: 'Role',
      isRequired: true,
    },
    {
      fieldName: 'source',
      humanReadableName: 'Client id',
      isRequired: false,
    },
    {
      fieldName: 'orgType',
      humanReadableName: 'Org Type',
      isRequired: false,
    },
    {
      fieldName: 'orgDomain',
      humanReadableName: 'Alternative org domain',
      isRequired: false,
    },
    {
      fieldName: 'forceOrgList',
      humanReadableName: 'Force org list',
      type: 'toggle',
      isRequired: false,
    },
  ];

  return (
    <div data-testid="dev-token-button-section">
      <BufferLoginButton
        onClick={handleOpen}
        text="Dev token"
        data-testid="dev-button"
      />

      <AutoFormModal
        header="Log in with dev token"
        entityName="DevTokenRequest"
        entity={params}
        fields={formFields}
        isOpen={open}
        onSave={handleSave}
        onSuccess={handleClose}
        queryClient={queryClient}
        onClose={handleClose}
        hideToastMessage
      />
    </div>
  );
};

export default DevButton;
