import React, { useEffect, useRef, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useQueryClient } from '@tanstack/react-query';
import { CreateAccountsWithPlaidRequest, nameRegex } from 'common';
import { generatePlaidToken, useLinkAccount } from '../../../services/accounts';
import { useAuth } from '../../../Auth';

const usePlaid = () => {
  const [token, setToken] = useState<string | null>(null);
  const [generateToken, setGenerateToken] = useState<boolean>(false);
  const [openPlaid, setOpenPlaid] = useState<boolean>(false);
  const {
    isPending,
    isError,
    data: tokenResponse,
  } = generatePlaidToken(generateToken);

  useEffect(() => {
    if (!isPending && !isError) {
      setToken(tokenResponse.linkToken);
      setGenerateToken(false);
    }
  }, [isPending]);

  const qc = useQueryClient();
  const auth = useAuth();
  const [orgLegalName, setOrgLegalName] = useState(`${auth.flowDomain} LLC`);
  const [isOpen, setIsOpen] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [textFieldError, setTextFieldError] = useState({
    inputError: false,
    disabled: false,
    errorMessage: '',
  });

  const plaidMetadata = useRef<any>({});
  const plaidPublicToken = useRef('');

  const onMutateSuccess = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const onMutateError = React.useCallback(() => {
    setTimeout(() => {
      setApiError(false);
    }, 2000);
    setApiError(true);
  }, []);

  const { mutate } = useLinkAccount(onMutateSuccess, onMutateError, qc);

  const onSuccess = React.useCallback((publicToken: string, metadata: any) => {
    plaidPublicToken.current = publicToken;
    plaidMetadata.current = metadata;
    setIsOpen(true);
  }, []);

  const handleChange = (currentValue: string) => {
    setOrgLegalName(currentValue);
    if (currentValue === '' || currentValue.trim() === '') {
      setTextFieldError({
        inputError: true,
        disabled: true,
        errorMessage: 'Field cannot be empty',
      });
    } else if (!nameRegex.test(currentValue)) {
      setTextFieldError({
        inputError: true,
        disabled: true,
        errorMessage: 'The legal name is not correct',
      });
    } else {
      setTextFieldError({
        inputError: false,
        disabled: false,
        errorMessage: '',
      });
    }
  };

  const handleSubmit = () => {
    const param: CreateAccountsWithPlaidRequest = {
      publicToken: plaidPublicToken.current,
      institutionName: plaidMetadata.current.institution.name,
      accounts: plaidMetadata.current.accounts,
      legalName: orgLegalName,
    };

    mutate(param);
  };

  const { open: openPlaidLink, ready: plaidReady } = usePlaidLink({
    token,
    onSuccess,
  });

  useEffect(() => {
    if (token && openPlaid && plaidReady) {
      openPlaidLink();
      setOpenPlaid(false);
    }
  }, [token, openPlaid, plaidReady]);

  const handleOpen = () => {
    if (!token) {
      setGenerateToken(true);
    }
    setOpenPlaid(true);
  };

  return {
    apiError,
    handleChange,
    handleSubmit,
    isLoading: isPending && generateToken,
    isOpen,
    handleOpen,
    orgLegalName,
    ready: token && openPlaid ? plaidReady : true,
    setIsOpen,
    setOrgLegalName,
    textFieldError,
  };
};

export default usePlaid;
