import React from 'react';

import { Icon, NavigationIcon, Transfer, useFlags } from 'common';

import ActionBar from '../../../components/ActionBar';
import StatusLabel from '../../../components/StatusLabel';
import ActionBarButton from '../../../components/ActionBarButton';

import styles from './PaymentActionsHeader.module.scss';

interface Props {
  payment?: Transfer;
  openDialog?: () => void;
  onIssueRefundClick?: () => void;
}

export const PaymentActionsHeader: React.FC<Props> = ({
  payment,
  openDialog,
  onIssueRefundClick,
}) => {
  const { refunds: refundsFlag } = useFlags();

  return (
    <div className={styles.headerContainer}>
      <StatusLabel feature="transaction" status={payment?.status ?? ''} />
      <ActionBar>
        <ActionBarButton
          icon={Icon.Activity}
          onClick={openDialog}
          tooltip="Activity"
        />
        {refundsFlag && (
          <ActionBarButton
            disabledTooltip="Cannot issue refund on non-posted payment"
            icon={NavigationIcon.Refund}
            isDisabled={payment?.status !== 'posted'}
            onClick={onIssueRefundClick}
            tooltip="Refund"
          />
        )}
      </ActionBar>
    </div>
  );
};
