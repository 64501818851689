import React from 'react';
import { Button, getExternalPaymentLink, Modal, useTranslation } from 'common';
import IDField from 'app/src/components/IDField';

import { useTransaction } from '../../services/api/transactions/transactionsList';
import Label from '../../components/Label';
import TransactionActivity from './TransactionActivity';
import TransactionChildren from './TransactionChildren';

interface Props {
  detailLink: string;
  isOpen: boolean;
  setOpenState: (isOpen: boolean) => void;
  transactionId?: string;
}

const TransactionDialog: React.FC<Props> = ({
  setOpenState,
  transactionId,
  detailLink,
  isOpen,
}) => {
  const { data: transfer } = transactionId
    ? useTransaction(transactionId)
    : { data: undefined };
  const { tk } = useTranslation();

  const onClose = () => {
    setOpenState(false);
  };

  const getBody = () => {
    return (
      <div>
        {transfer && (
          <div>
            <div className="flex flex-row">
              <div className="flex flex-col w-1/3">
                <Label name="Transaction id" />
                {transfer.id && (
                  <IDField documentID={transfer.id}>
                    <div>{transfer.id.substring(0, 8)}</div>
                  </IDField>
                )}
              </div>

              {transfer.externalId && (
                <div className="flex flex-col w-1/3">
                  <Label name={`External id (${transfer.processor})`} />
                  <IDField documentID={transfer.externalId}>
                    <div>{getExternalPaymentLink(transfer)}</div>
                  </IDField>
                </div>
              )}

              <div className="flex flex-col w-1/3">
                <Label name="Type" />
                {transfer.type}
              </div>
            </div>
            {(transfer.reasonCode || transfer.reason) && (
              <div className="flex flex-row w-full">
                <div className="flex flex-col">
                  <Label name="Payment Reason" />
                  {transfer.reasonCode
                    ? tk(transfer.reasonCode)
                    : transfer.reason}
                </div>
              </div>
            )}
          </div>
        )}
        <div className="w-full flex flex-row pt-4">
          {transactionId && (
            <TransactionActivity
              transactionId={transactionId}
              detailLink={detailLink}
            />
          )}
        </div>
        <div className="w-full flex flex-row pt-4">
          {transactionId && (
            <TransactionChildren transactionId={transactionId} />
          )}
          {!transactionId && <>No activity found for transaction.</>}
        </div>
      </div>
    );
  };

  return (
    <Modal
      width={!transactionId ? 300 : Math.max(800, window.innerWidth - 300)}
      isOpen={isOpen}
      onClose={() => setOpenState(false)}
      header="Transaction activity"
      footer={
        <div className="flex justify-end">
          <Button label="Close" onClick={onClose} />
        </div>
      }
    >
      {getBody()}
    </Modal>
  );
};

export default TransactionDialog;
