import React, { useEffect, useState } from 'react';
import { Contact, Customer, Drawer, useTranslation } from 'common';
import ContactForm from './ContactForm';
import EditCustomer from './EditCustomer';
import { useNavigate } from 'react-router-dom';
import { CUSTOMERS } from 'app/src/core-utils/routes';

interface Props {
  customerBeingEdited?: Customer;
  customerStarterName?: string;
  isInProposalEditor?: boolean;
  isOpen: boolean;
  onCloseForm: () => void;
  onCreateCustomer: (customer: Customer) => void;
  onDeleteCustomer?: () => void;
}

const CustomerFormDrawer: React.FC<Props> = ({
  customerBeingEdited,
  isOpen,
  onCloseForm,
  onCreateCustomer,
  customerStarterName,
  onDeleteCustomer,
  isInProposalEditor = false,
}) => {
  const navigate = useNavigate();
  const [contactBeingEdited, setContactBeingEdited] = useState<
    Contact | undefined
  >(undefined);
  const getCustomerDraft = () => {
    return customerBeingEdited || { name: customerStarterName };
  };
  const [customerDraft, setCustomerDraft] =
    useState<Partial<Customer>>(getCustomerDraft());
  const [activeDrawerViewKey, setActiveDrawerViewKey] = useState<
    'main' | 'contacts'
  >('main');
  const isContactsDrawerOpen = activeDrawerViewKey === 'contacts';

  const { tk } = useTranslation();

  useEffect(() => {
    setCustomerDraft(getCustomerDraft());
  }, [customerBeingEdited, isOpen]);

  const closeContactForm = (): void => {
    setActiveDrawerViewKey('main');
    setContactBeingEdited(undefined);
  };

  const onSaveContactSuccess = (contact: Contact) => {
    if (contact.id && contact.id === customerDraft.billingContact?.id) {
      setCustomerDraft({
        ...customerDraft,
        billingContact: contact,
      });
    }

    closeContactForm();
  };

  const editContact = (contact: Contact) => {
    setContactBeingEdited(contact);
    setActiveDrawerViewKey('contacts');
  };

  const closeForm = () => {
    setContactBeingEdited(undefined);
    setActiveDrawerViewKey('main');
    onCloseForm();
  };

  const handleCustomerDeleteSuccess = () => {
    closeForm();
    if (onDeleteCustomer) {
      onDeleteCustomer();
    }

    navigate(CUSTOMERS);
  };

  const handleCustomerSaveSuccess = (customer: Customer) => {
    if (customerBeingEdited?.id) {
      closeForm();
      return;
    }
    onCreateCustomer(customer);
    customerDraft.id && closeForm();
  };

  return (
    <Drawer
      header={customerBeingEdited?.name || tk('Create customer')}
      isOpen={isOpen}
      onClose={closeForm}
    >
      <EditCustomer
        customerDraft={customerDraft}
        isBeingViewed={activeDrawerViewKey === 'main' && isOpen}
        isInProposalEditor={isInProposalEditor}
        onClickAddContact={() => setActiveDrawerViewKey('contacts')}
        onClickEditContact={editContact}
        onDeleteSuccess={handleCustomerDeleteSuccess}
        onSaveSuccess={handleCustomerSaveSuccess}
        setCustomerDraft={setCustomerDraft}
      />
      <Drawer
        header={contactBeingEdited ? tk('Edit contact') : tk('Add contact')}
        onClose={closeContactForm}
        isOpen={isContactsDrawerOpen}
      >
        {isContactsDrawerOpen && customerBeingEdited?.id && (
          <ContactForm
            customerId={customerBeingEdited.id}
            contactToEdit={contactBeingEdited}
            onSaveSuccess={onSaveContactSuccess}
            onClose={closeContactForm}
            onDeleteSuccess={closeContactForm}
            isBeingViewed={isOpen}
          />
        )}
      </Drawer>
    </Drawer>
  );
};

export default CustomerFormDrawer;
