import React from 'react';
import { Button, CardRowValue, Flex, Input } from 'common';

interface Props {
  label: string;
  onCancel: () => void;
  onChange: (value: string) => void;
  onSubmit: () => Promise<void> | void;
  value: string;
}

const POInputBox: React.FC<Props> = ({
  onCancel,
  label,
  value,
  onChange,
  onSubmit,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      await onSubmit();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <CardRowValue alignLabel="center" label={label}>
      <Flex gap="small">
        <Input
          isDisabled={isSaving}
          maxLength={20}
          onChange={onChange}
          size="small"
          value={value}
        />
        <Button
          isDisabled={isSaving}
          label="Cancel"
          onClick={onCancel}
          size="small"
          type="secondary"
        />
        <Button
          isLoading={isSaving}
          label="Submit"
          onClick={handleSubmit}
          size="small"
        />
      </Flex>
    </CardRowValue>
  );
};

export default POInputBox;
