import React from 'react';
import clsx from 'clsx';
import styles from './proposalCard.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  isDisabled: boolean;
  noPadding?: boolean;
  sectionRef?: string;
}

const ProposalCard: React.FC<Props> = ({
  children,
  className,
  isDisabled,
  noPadding,
  sectionRef,
}) => (
  <div
    data-section-ref={sectionRef}
    className={clsx(
      className,
      styles.proposalCard,
      isDisabled ? styles.disabled : [styles.notDisabled, 'card-shadow'],
      !noPadding && styles.padding
    )}
  >
    {children}
  </div>
);

export default ProposalCard;
