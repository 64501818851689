import { useState } from 'react';

const useLoadingScreen = (isOpen: boolean) => {
  const [open, setOpen] = useState(isOpen);
  const handleClick = () => {
    setOpen(false);
  };
  return { open, handleClick };
};

export default useLoadingScreen;
