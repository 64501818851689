import { useQueryClient } from '@tanstack/react-query';

import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { useOrgDefaults } from 'app/src/services/orgDefaults';
import {
  useDeleteRenewalConfig,
  usePatchRenewalConfig,
  usePostRenewalConfig,
} from 'app/src/services/proposal';

import {
  AutoRenewalChange,
  AutoRenewalConfig,
  Button,
  ChangePopover,
  ChangesPill,
  getErrorCode,
  getErrorStatus,
  isAcceptedProposalStatus,
  isNumber,
  LinkButton,
  Loading,
  pluralize,
  Proposal,
  RenewalConfiguration,
  RenewalConfigurationRequest,
  useDebounce,
  useToast,
} from 'common';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { TokenTextArea } from '../../../../components';
import ProposalSection from '../ProposalSection/ProposalSection';
import { AutoRenewalDrawer } from './AutoRenewalDrawer';
import styles from './AutoRenewalSection.module.scss';

interface Props {
  isDisabled?: boolean;
  proposal: Proposal;
}

const AutoRenewalSection: React.FC<Props> = ({ isDisabled, proposal }) => {
  const showToast = useToast();
  const queryClient = useQueryClient();
  const isAcceptedStatus = isAcceptedProposalStatus(proposal);
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  const [formState, setFormState] = useState<RenewalConfiguration | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const {
    data: orgConfig,
    isFetching: isOrgDefaultsFetching,
    isRefetching: isOrgDefaultsRefetching,
    refetch,
  } = useOrgDefaults();

  let autoRenewalConfig: AutoRenewalConfig | undefined = undefined;
  if (orgConfig?.has('autoRenewalConfig')) {
    autoRenewalConfig = orgConfig.get('autoRenewalConfig')
      ?.configValue! as AutoRenewalConfig;
  }

  useEffect(() => {
    setFormState(proposal.renewalConfiguration ?? null);
  }, [proposal.renewalConfiguration]);

  const postRenewalMutation = usePostRenewalConfig(
    proposal.id,
    () => {},
    handleError,
    queryClient
  );

  const patchRenewalMutation = usePatchRenewalConfig(
    proposal.renewalConfiguration?.id!,
    proposal.id,
    () => {},
    handleError,
    queryClient
  );

  const deleteRenewalMutation = useDeleteRenewalConfig(
    proposal.id,
    () => {},
    handleError,
    queryClient
  );

  const { debouncedFunction: debouncedMutateAutoRenewal } = useDebounce(
    (newState) => {
      patchRenewalMutation.mutate(newState as RenewalConfigurationRequest);
    },
    1000
  );

  const handleRestoreDefaultRenewalClause = async () => {
    const { data: updatedDefaults } = await refetch();

    const updatedConfig: AutoRenewalConfig | undefined =
      updatedDefaults?.get('autoRenewalConfig')?.configValue;

    if (updatedConfig) {
      const newState = {
        ...formState,
        renewalClause: updatedConfig.renewalClause,
        termLengthMonths: updatedConfig.termLengthMonths,
        priceIncrease: updatedConfig.priceIncrease,
        cancellationDeadlineDays: updatedConfig.cancellationDeadlineDays,
      };

      setFormState(newState);
      debouncedMutateAutoRenewal(newState);
    }
  };

  const handleChange = (field: string, value: string) => {
    const newState = {
      ...formState,
      [field]: value,
    };

    setFormState(newState);
    debouncedMutateAutoRenewal(newState);
  };

  function handleError(error: unknown) {
    if (
      getErrorStatus(error) === 400 &&
      getErrorCode(error) ===
        'RENEWAL_CONFIGURATION_ASSOCIATED_WITH_MULTIPLE_ENTITIES'
    ) {
      postRenewalMutation.mutate({ renewalClause: formState?.renewalClause });
    } else {
      showToast.error('Updating auto-renewal settings failed');
    }
  }

  const getTokensWithValues = () => {
    return {
      /* eslint-disable camelcase */
      renewal_term_length: proposal.renewalConfiguration?.termLengthMonths,
      renewal_cancellation_deadline:
        proposal.renewalConfiguration?.cancellationDeadlineDays,
      renewal_price_increase: formState?.priceIncrease,
      /* eslint-enable camelcase */
    };
  };

  const handleOpeningSection = () => {
    if (!proposal.renewalConfiguration) {
      postRenewalMutation.mutate({});
    }
  };

  const handleRemovingSection = () => {
    if (proposal.renewalConfiguration) {
      deleteRenewalMutation.mutate(proposal.renewalConfiguration.id!);
    }
  };

  const renderChangedChicklet = () => {
    const autoRenewalChange = proposal.changes?.renewalConfigurationChange;
    const { previous } = proposal;

    if (!proposal.changes || !autoRenewalChange || !previous) {
      return null;
    }

    return (
      <ChangePopover
        content={
          <Link to={`/proposals/${previous.id}`}>
            <LinkButton>See original</LinkButton>
          </Link>
        }
        title="Auto-renewal changed"
      >
        <ChangesPill>
          <AutoRenewalChange changes={proposal.changes} proposal={proposal} />
        </ChangesPill>
      </ChangePopover>
    );
  };

  if (
    postRenewalMutation.isPending ||
    deleteRenewalMutation.isPending ||
    isOrgDefaultsFetching ||
    isOrgDefaultsRefetching
  ) {
    return <Loading />;
  }

  // If proposal has renewalConfiguration, even if org has disabled auto renewal, show section
  if (
    !proposal.renewalConfiguration &&
    (isAcceptedStatus || !autoRenewalConfig?.enabled)
  ) {
    return null;
  }

  const formatLink = (text: string) =>
    isDisabled ? (
      text
    ) : (
      <LinkButton isDisabled={isDisabled} onClick={() => setIsDrawerOpen(true)}>
        {text}
      </LinkButton>
    );

  const formatPlural = (term: number | undefined, unit: string) =>
    formatLink(isNumber(term) ? pluralize(term, unit) : 'None');

  const formatPercentage = (percentage: number | undefined) =>
    formatLink(`${percentage ?? 0}%`);

  if (!canUpdate && !proposal.renewalConfiguration) {
    return null;
  }

  return (
    <ProposalSection
      isDisabled={isDisabled ?? false}
      onOpen={handleOpeningSection}
      onRemove={handleRemovingSection}
      openOnLoad={!!proposal.renewalConfiguration}
      section="Add auto-renewal"
      sectionRef="AutoRenewals"
      title="Auto-renewal"
      titleActions={renderChangedChicklet()}
    >
      {proposal.renewalConfiguration && (
        <>
          <div className={styles.configItemRow}>
            <p className={styles.text}>Renewal term length:</p>

            {formatPlural(formState?.termLengthMonths, 'month')}

            {!isDisabled && (
              <Button
                className={styles.restoreDefault}
                label="Restore default renewal settings"
                onClick={handleRestoreDefaultRenewalClause}
                size="small"
                type="secondary"
              />
            )}
          </div>

          <div className={styles.configItemRow}>
            <p className={styles.text}>Renewal cancellation deadline:</p>

            {formatPlural(formState?.cancellationDeadlineDays, 'day')}
          </div>

          <div className={styles.configItemRow}>
            <p className={styles.text}>Automatic renewal price increase:</p>

            {formatPercentage(formState?.priceIncrease)}
          </div>

          <div className={styles.clauseMargin}>
            <TokenTextArea
              id="renewalClause"
              isReadOnly={isDisabled}
              onChange={handleChange}
              placeholder="Add auto renewal terms. If the terms are left empty then this section will not be shown to the buyer in the proposal that they receive."
              tokensWithValues={getTokensWithValues()}
              value={formState?.renewalClause?.trim()}
            />
          </div>

          {formState && isDrawerOpen && (
            <AutoRenewalDrawer
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              proposal={proposal}
              renewalConfig={formState}
            />
          )}
        </>
      )}
    </ProposalSection>
  );
};

export default AutoRenewalSection;
