import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'common';

interface Props {
  path: string;
  title?: string;
}

const BackArrow = ({ path, title }: Props) => {
  return (
    <Link to={path} className="flex justify-center items-center">
      <Icon.ArrowLeft width={50} height={50} />
      <h3 className="text-lg font-normal tracking-tight xs:pb-2 sm:pb-0">
        {title}
      </h3>
    </Link>
  );
};

export default BackArrow;
