import React from 'react';
import { InternalAppLink, useTranslation } from 'common';
import {
  SETTINGS_BANK_ACCOUNTS,
  SETTINGS_STRIPE,
} from '../../../core-utils/routes';
import { MethodProps } from './utilities';

const DirectDebitCard = ({ paymentMethod }: MethodProps) => {
  const { tk } = useTranslation();
  const stripeLink = (
    <InternalAppLink
      route={SETTINGS_STRIPE}
      breadcrumbs={[tk('Integrations and Connectors'), tk('Stripe')]}
    />
  );

  return (
    <div className="w-full flex justify-center">
      {!paymentMethod.configured && (
        <p>
          To enable direct debit, you must first add a bank account in{' '}
          <InternalAppLink
            route={SETTINGS_BANK_ACCOUNTS}
            breadcrumbs={[tk('Settings'), tk('Bank accounts')]}
          />{' '}
          and connect to Stripe in {stripeLink}
        </p>
      )}

      {paymentMethod.configured && (
        <p>Configured with Stripe in {stripeLink}</p>
      )}
    </div>
  );
};

export default DirectDebitCard;
