import React from 'react';
import { NavigationIcon } from 'common';
import ExternalDealsPage from '../ExternalDealsPage';

const HubspotDeals: React.FC = () => {
  return (
    <ExternalDealsPage
      sourceType="hubspot"
      dealLabel="deal"
      icon={NavigationIcon.Deal}
      dealQueryParamName="hubspotRedirectDealId"
    />
  );
};

export default HubspotDeals;
