import React from 'react';
import { Link } from 'react-router-dom';
import {
  ChangePopover,
  ChangesPill,
  CustomTermsChange,
  LinkButton,
  Proposal,
} from 'common';
import ProposalSection from '../ProposalSection/ProposalSection';
import { RichTextEditor } from '../../../../components';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

interface Props {
  isDisabled: boolean;
  onTermsContentChange: (html: string | null) => void;
  proposal: Proposal;
  termsContentHtml: string | undefined;
}

const AdditionalTerms: React.FC<Props> = ({
  isDisabled,
  onTermsContentChange,
  proposal,
  termsContentHtml,
}) => {
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  const renderChangedChicklet = () => {
    const customTermsChange = proposal.changes?.customTermsChange;
    const { previous } = proposal;

    if (!proposal.changes || !customTermsChange || !previous) {
      return null;
    }

    return (
      <ChangePopover
        className="cursor-pointer"
        content={
          <Link to={`/proposals/${previous.id}`}>
            <LinkButton>See original</LinkButton>
          </Link>
        }
      >
        <ChangesPill>
          <CustomTermsChange proposal={proposal} changes={proposal.changes} />
        </ChangesPill>
      </ChangePopover>
    );
  };

  if (!canUpdate && !termsContentHtml) {
    return null;
  }
  return (
    <ProposalSection
      section="Add additional terms"
      sectionRef="CustomTerms"
      openOnLoad={!!termsContentHtml}
      titleActions={renderChangedChicklet()}
      title={<>Additional terms</>}
      onRemove={() => onTermsContentChange(null)}
      isDisabled={isDisabled}
    >
      <RichTextEditor
        dataTestId="additional-terms"
        featureSet="proposal.terms"
        fireOnBlur
        fireOnDebounce
        value={termsContentHtml}
        isDisabled={isDisabled}
        onChange={onTermsContentChange}
        placeholder="Add additional terms"
      />
    </ProposalSection>
  );
};

export default AdditionalTerms;
