import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'app/src/services/httpClients/app';
import { Organization } from 'common';

const fetchOrganization = async (organizationId: string) => {
  const { data } = await apiClient.getOrganization(organizationId);
  return data;
};

export const useGetOrganization = (organizationId: string) => {
  return useQuery<Organization>({
    queryKey: ['organization', organizationId],
    queryFn: () => fetchOrganization(organizationId),
    enabled: !!organizationId,
  });
};
