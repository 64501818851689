import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ReferenceIssue, Filter, isDropdownFilter, cleanOptions } from 'common';
import { apiClient } from './httpClients/app';
import { useOrganizations } from './api/system/organizations';
import { AxiosResponse } from 'axios';

export const useReferenceIssues = (
  referenceType: any,
  enabled: boolean,
  referenceId?: string
): UseQueryResult<ReferenceIssue[]> =>
  useQuery({
    queryKey: [`apiClient.getReferenceIssues-${referenceId}`],
    queryFn: async () => {
      const { data } = await apiClient.getFlagsByReference(
        referenceType,
        referenceId!
      );
      return data;
    },
    enabled: enabled && !!referenceId,
  });

export const useSyncSummary = <T, E = unknown>(
  queryKey: string,
  fetchCountList: (params: { search: string }) => Promise<AxiosResponse<T>>,
  searchParams: string
): UseQueryResult<T, E> => {
  return useQuery<T, E>({
    queryKey: [queryKey, searchParams],
    queryFn: () =>
      fetchCountList({ search: searchParams }).then(
        (response) => response.data
      ),
  });
};

enum SyncStatus {
  error = 'Error',
  success = 'Success',
}

enum ReferenceType {
  all = 'All',
  account = 'Account',
  apiwebhook = 'Apiwebhook',
  apitoken = 'Apitoken',
  approvalgroup = 'Approvalgroup',
  attachmentconfig = 'Attachmentconfig',
  billingschedule = 'Billingschedule',
  companyInfo = 'CompanyInfo',
  connectorSyncEvent = 'ConnectorSyncEvent',
  contact = 'Contact',
  contentTemplate = 'ContentTemplate',
  contract = 'Contract',
  contractitem = 'Contractitem',
  contractitemlevel = 'Contractitemlevel',
  currency = 'Currency',
  customer = 'Customer',
  docusignaccount = 'Docusignaccount',
  document = 'Document',
  documentversion = 'Documentversion',
  group = 'Group',
  invite = 'Invite',
  invoice = 'Invoice',
  invoiceConfiguration = 'InvoiceConfiguration',
  organization = 'Organization',
  orgconfig = 'Orgconfig',
  orgidentitytoken = 'Orgidentitytoken',
  payment = 'Payment',
  paymentMethod = 'PaymentMethod',
  paymentSchedule = 'PaymentSchedule',
  paymentTerm = 'PaymentTerm',
  pricebook = 'Pricebook',
  pricebookentry = 'Pricebookentry',
  product = 'Product',
  productlevel = 'Productlevel',
  productusage = 'Productusage',
  proposal = 'Proposal',
  proposalapprovalrule = 'Proposalapprovalrule',
  proposalevent = 'Proposalevent',
  proposalitem = 'Proposalitem',
  proposaliteminterval = 'Proposaliteminterval',
  proposalitemlevel = 'Proposalitemlevel',
  proposaloption = 'Proposaloption',
  renewalconfiguration = 'Renewalconfiguration',
  signature = 'Signature',
  signingdocument = 'Signingdocument',
  transfer = 'Transfer',
  transferevent = 'Transferevent',
  unit = 'Unit',
  user = 'User',
}

enum connecter {
  avalara = 'Avalara',
  cacheflow_checkout = 'Cacheflow_checkout',
  close = 'Close',
  hubspot = 'Hubspot',
  manual = 'Manual',
  netsuite = 'Netsuite',
  plaid = 'Plaid',
  quickbooks = 'Quickbooks',
  salesforce = 'Salesforce',
  stripe = 'Stripe',
}

enum direction {
  from_cacheflow = 'From cacheflow',
  to_cacheflow = 'To cacheflow',
}

enum action {
  create = 'Create',
  delete = 'Delete',
  noop = 'Noop',
  update = 'Update',
  upsert = 'Upsert',
}

export const syncEventsFilters = (isSystemAdmin: boolean): Filter[] => {
  const filters: Filter[] = [];

  if (isSystemAdmin) {
    const { data: orgs } = useOrganizations(isSystemAdmin);
    const newData = cleanOptions(
      orgs?.map((org) => {
        return { value: org.id, name: org.name };
      }),
      'syncEventsFilters'
    );

    filters.push({
      type: 'dropdown',
      key: 'orgId',
      humanReadableName: 'Organization',
      fieldName: 'orgId',
      options: newData,
      searchable: true,
    });
  }

  filters.push({
    type: 'dropdown',
    key: 'referenceType',
    humanReadableName: 'Reference type',
    fieldName: 'referenceType',
    options: Object.keys(ReferenceType).map((key) => ({
      value: key,
      name: ReferenceType[key as keyof typeof ReferenceType].valueOf(),
    })),
    searchable: true,
  });

  filters.push({
    type: 'dropdown',
    key: 'source',
    humanReadableName: 'Connectors',
    fieldName: 'source',
    options: Object.keys(connecter).map((key) => ({
      value: key,
      name: connecter[key as keyof typeof connecter].valueOf(),
    })),
  });

  filters.push({
    type: 'searchV2',
    key: 'search',
    searchFields: [
      {
        field: 'referenceId',
        operation: 'equals',
      },
    ],
  });

  filters.push({
    type: 'dateRadio',
    key: 'createdDate',
    humanReadableName: 'Created date',
    fieldName: 'createdAt',
    optionsType: 'currentAndPast',
  });

  filters.push({
    type: 'dropdown',
    key: 'direction',
    humanReadableName: 'Direction',
    fieldName: 'direction',
    options: Object.keys(direction).map((key) => ({
      value: key,
      name: direction[key as keyof typeof direction].valueOf(),
    })),
  });

  filters.push({
    type: 'dropdown',
    key: 'action',
    humanReadableName: 'Action',
    fieldName: 'action',
    options: Object.keys(action).map((key) => ({
      value: key,
      name: action[key as keyof typeof action].valueOf(),
    })),
  });

  filters.push({
    type: 'dropdown',
    key: 'status',
    humanReadableName: 'Status',
    fieldName: 'status',
    options: Object.keys(SyncStatus).map((key) => ({
      value: key,
      name: SyncStatus[key as keyof typeof SyncStatus].valueOf(),
    })),
  });

  const dropdownFilter = filters[0];

  if (dropdownFilter && isDropdownFilter(dropdownFilter)) {
    const sortedOptions = dropdownFilter.options.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    sortedOptions.unshift({
      key: 'All',
      name: 'All organizations',
      value: '',
    });
    dropdownFilter.options = sortedOptions;
    return filters;
  }

  return filters;
};
