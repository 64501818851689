import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { INVOICES } from '../../../core-utils/routes';
import {
  useArchiveInvoice,
  useRestoreInvoice,
} from 'app/src/services/api/invoices/invoiceList';
import {
  ConfirmModal,
  Icon,
  Invoice,
  InvoiceSummary,
  PopOutMenuOption,
  useToast,
} from 'common';

interface Props {
  invoice: Invoice | InvoiceSummary;
}

const InvoiceArchiveOption: React.FC<Props> = ({ invoice }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const showToast = useToast();
  const isArchived: boolean = !!invoice.archived;

  const handleError = () => {
    showToast.error(`Invoice ${isArchived ? 'restoring' : 'archiving'} failed`);
  };

  const handleSuccess = () => {
    showToast.info(`Invoice ${isArchived ? 'restored' : 'archived'} `);
    navigate(INVOICES);
  };

  const { mutate: archive } = useArchiveInvoice(
    invoice.id,
    handleSuccess,
    handleError,
    queryClient
  );

  const { mutate: restore } = useRestoreInvoice(
    invoice.id,
    handleSuccess,
    handleError,
    queryClient
  );

  return (
    <>
      <PopOutMenuOption
        dataTestId="invoicen-archive-button"
        icon={isArchived ? Icon.Unlocked : Icon.Locked}
        isDisabled={invoice.status !== 'paid'}
        onClick={() => (isArchived ? restore() : setShowConfirm(true))}
        title={isArchived ? 'Restore' : 'Archive'}
      />

      <ConfirmModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => archive()}
      >
        <div>Are you sure you want to archive this invoice?</div>
      </ConfirmModal>
    </>
  );
};

export default InvoiceArchiveOption;
