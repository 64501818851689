import { useState } from 'react';
import { Proposal } from 'common';
import { useQueryClient } from '@tanstack/react-query';
import { isProposalTemplate } from './proposalUtilities';
import { PROPOSALS } from 'app/src/core-utils/routes';
import { useNavigate } from 'react-router-dom';
import {
  CopyProposalStrategy,
  useCopyProposal,
} from 'app/src/services/proposal';

export function useProposalCopy() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [copyError, setCopyError] = useState<unknown>();

  const handleCopySuccess = (createdProposal: Proposal) => {
    if (isProposalTemplate(createdProposal)) {
      navigate(`${PROPOSALS}/${createdProposal.id}?first=true`);
    } else {
      navigate(`${PROPOSALS}/${createdProposal.id}`);
    }
  };

  const { mutate: copyProposal, isPending: isLoading } = useCopyProposal(
    handleCopySuccess,
    (err) => setCopyError(err),
    queryClient
  );

  const handleClose = () => {
    setCopyError(undefined);
  };

  return {
    makeCopy: (proposalId: string, strategy?: CopyProposalStrategy) => {
      setCopyError(undefined);
      copyProposal({ id: proposalId, strategy });
    },
    isLoading,
    copyError,
    handleClose,
  };
}
