import { useQueryClient } from '@tanstack/react-query';
import { useSyncProposalToCRM } from 'app/src/services/proposal';

import {
  Icon,
  PopOutMenuOption,
  Proposal,
  ProposalSummary,
  useToast,
} from 'common';
import React from 'react';
import {
  crmNameFromSource,
  CrmSource,
} from '../../../core-utils/helperFunctions/types';

interface Props {
  proposal: Proposal | ProposalSummary;
}

const ProposalSyncToButton: React.FC<Props> = ({ proposal }) => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { id } = proposal;
  const source = proposal.source as CrmSource;

  const handleSyncError = () => {
    showToast.error(`Sync to ${crmNameFromSource(source)} failed.`);
  };

  const handleSyncSuccess = () => {
    showToast.info(
      `Sync to ${crmNameFromSource(source)} completed successfully.`
    );
  };

  const { mutate: sync } = useSyncProposalToCRM(
    id,
    handleSyncSuccess,
    handleSyncError,
    queryClient
  );

  if (proposal.source !== 'salesforce' && proposal.source !== 'hubspot') {
    return null;
  }

  return (
    <PopOutMenuOption
      dataTestId="proposal-sync-button"
      icon={Icon.Repeat}
      onClick={() => sync(proposal.id)}
      title={`Sync to ${crmNameFromSource(source)}`}
    />
  );
};

export default ProposalSyncToButton;
