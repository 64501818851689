import React from 'react';

import { ActivityEvent, Drawer } from 'common';

import {
  clusterByDate,
  getEventsPropsClusters,
  sortByDateAsc,
} from 'app/src/core-utils/helperFunctions/eventUtils';
import EventCluster from '../events/EventCluster';
import EventClusters from '../events/EventClusters';
import EventDisplay, { EventDisplayProps } from '../events/EventDisplay';

import {
  ActivityResourceTypes,
  getEventElementsByResource,
  getResourceLabelForResource,
} from './activityDrawerUtils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activityEvents: ActivityEvent[] | null;
  resourceType: ActivityResourceTypes;
}

export const ActivityDrawer = ({
  isOpen,
  onClose,
  activityEvents,
  resourceType,
}: Props) => {
  if (!activityEvents) return null;

  const eventToEventDisplayPropsConverter = (
    event: ActivityEvent,
    _previousEvent?: ActivityEvent
  ): EventDisplayProps | null => {
    const eventElements = getEventElementsByResource(event, resourceType);

    if (eventElements === null || !event.id || !event.createdAt) {
      return null;
    }

    return {
      id: event.id,
      createdAt: event.createdAt,
      person: event.actor?.name,
      ...eventElements,
    };
  };

  const activitiesSortedByDate = sortByDateAsc(activityEvents);

  const activitiesClusteredByDate = clusterByDate<ActivityEvent>(
    activitiesSortedByDate
  );

  const activityPropsClusteredByDate = getEventsPropsClusters(
    activitiesClusteredByDate,
    eventToEventDisplayPropsConverter
  );

  return (
    <Drawer
      header={`${getResourceLabelForResource(resourceType)} Activity`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <EventClusters>
        {activityPropsClusteredByDate.map((cluster) => (
          <EventCluster date={cluster.date} key={cluster.date}>
            {cluster.propObjects.map((props) => (
              <EventDisplay key={props.id} {...props} />
            ))}
          </EventCluster>
        ))}
      </EventClusters>
    </Drawer>
  );
};
