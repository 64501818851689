import React, { useEffect, useRef, useState } from 'react';

import {
  FormField,
  FormModal,
  getLengthValidator,
  Input,
  InputRef,
  Product,
  ProductRequest,
  useFormValidation,
  useToast,
  useTranslation,
} from 'common';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../../../Auth';
import { useCreateProduct } from 'app/src/services/api/products/products';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const freshDraft = { name: '' };

const NewBundle: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const { tk } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const showToast = useToast();
  const [draft, setDraft] = useState<{ name: string }>({ ...freshDraft });
  const profile = useAuth();

  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<{ name: string }>(
      [
        {
          fieldName: 'name',
          isRequired: true,
          humanReadableName: 'Product name',
          validator: getLengthValidator(254, 'name'),
        },
      ],
      draft
    );

  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    if (isOpen) {
      resetUIState();
      setDraft({ ...freshDraft });
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 250);
    }
  }, [isOpen]);

  const handleSubmitError = () => {
    showToast.error(tk('Bundle could not be created, please try again.'));
  };

  const handleSubmitSuccess = (created: Product) => {
    showToast.info(tk('Bundle has been successfully created'));
    navigate(`/products/${created.id}`);
  };

  const { mutate: createBundle, isPending: isCreatingBundle } =
    useCreateProduct(handleSubmitSuccess, handleSubmitError, queryClient);

  const save = () => {
    const freshBundle: ProductRequest = {
      productType: 'bundleTopDown',
      description: '',
      status: 'inactive',
      consumption: 'single',
      pricing: 'fixed',
      recurrence: 'recurring',
      currencies: [profile.organizationDefaultCurrency!],
    };
    createBundle({ ...freshBundle, name: draft.name });
  };

  const saveIfValid = () => {
    if (isFormValid) {
      save();
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      isNewEntity
      onClose={onClose}
      entityTypeName={tk('bundle')}
      onSubmit={save}
      isSubmitDisabled={!isFormValid}
      isProcessing={isCreatingBundle}
      width={500}
      submitDataTestId="saveBundle"
    >
      <FormField
        label={tk('Bundle name')}
        infoText={tk(
          'This will be the name as it appears to you and to customers'
        )}
        errorToShow={getErrorToShow('name')}
      >
        <Input
          dataTestId="bundleName"
          onChange={(value) => setDraft({ name: value })}
          onEnter={saveIfValid}
          isDisabled={isCreatingBundle}
          onBlur={() => setHasVisitedField('name')}
          ref={inputRef}
        />
      </FormField>
    </FormModal>
  );
};

export default NewBundle;
