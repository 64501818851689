import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useManageScrolling() {
  // NB: I put this in b/c it seemed like the quickest solution to the problem of the
  // scroll position of a page influencing the scroll position of the next page after
  // navigating... I'm not sure if there's a simpler solution or if it's expected
  // behavior that pages are influencing the next page's scroll position - if we
  // discover a better solution, please change this
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
}

export default useManageScrolling;
