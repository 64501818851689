import {
  QueryClient,
  QueryObserverResult,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import {
  capitalizeFirstLetter,
  Config,
  ConnectorInfo,
  Connectors,
  ConnectorSetup,
  ConnectorTestResult,
  OrgConfig,
} from 'common';
import { useEffect, useState } from 'react';
import { setOrgConfigDefault, useFindOrgDefault } from './orgDefaults';
import { Option } from '../pages/Settings/Connectors/ConnectorConstants/constants';

const GET_CONNECTOR_KEY = 'CONNECTOR_KEY';
const GET_CONNECTORS_PATH = '/api/latest/settings/integrations/connectors';

const sourceToConfigMap: Map<
  ConnectorInfo['source'],
  | 'salesforceConfig'
  | 'hubspotConfig'
  | 'quickbooksConfig'
  | 'closeConfig'
  | 'warmlyConfig'
> = new Map([
  ['salesforce', 'salesforceConfig'],
  ['hubspot', 'hubspotConfig'],
  ['quickbooks', 'quickbooksConfig'],
  ['close', 'closeConfig'],
  ['warmly', 'warmlyConfig'],
]);

async function updateConfig(
  userId: string,
  sourceType: ConnectorInfo['source'],
  configPatch: Partial<OrgConfig['configValue']>,
  qc: QueryClient
) {
  const configKey = sourceToConfigMap.get(sourceType);
  if (!configKey) {
    throw new Error('configType not found for: ' + sourceType);
  }

  const configBody: OrgConfig['configValue'] = {
    configType: configKey,
    connectedUserId: userId,
    ...configPatch,
  } as Config;

  await setOrgConfigDefault(
    configKey,
    {
      configKey,
      configValue: configBody,
    },
    qc
  );
}

export type ConnectResult = 'connected' | 'disconnected';

export async function disableConnectorConfig(
  sourceType: ConnectorInfo['source'],
  userId: string,
  onClose: (connectResult: ConnectResult | undefined) => void,
  onError: (e: any) => void,
  qc: QueryClient,
  additionalConfigPatch?: Partial<OrgConfig['configValue']>
) {
  await updateConfig(
    userId,
    sourceType,
    { enabled: false, ...additionalConfigPatch } as any,
    qc
  ).catch(onError);
  onClose('disconnected');
}

export const useConnectorConfig = (configType: OrgConfig['configKey']) => {
  const { data: configData } = useFindOrgDefault(configType);
  return configData;
};
export const useIsConnectorEnabled = (configType: OrgConfig['configKey']) => {
  const [enabled, setEnabled] = useState(false);
  const configData = useConnectorConfig(configType);

  useEffect(() => {
    const configValue = configData?.configValue;
    if (configValue) {
      setEnabled('enabled' in configValue ? !!configValue.enabled : false);
    }
  }, [configData]);

  return enabled;
};

export const useGetConnectionTest = (
  sourceType: any
): UseQueryResult<ConnectorTestResult> =>
  useQuery({
    queryKey: [GET_CONNECTOR_KEY, sourceType, 'AUTH'],
    queryFn: async () => {
      const { data } = await apiClient.testConnector(sourceType);
      return data as ConnectorTestResult;
    },
  });

const saveAuthorization = async (params: ConnectorSetup) => {
  const { data } = await apiClient.saveIntegrationAuthCallback(
    params.type!,
    params
  );
  return data;
};

export const useSaveAuthorizeConnector = (
  sourceType: any,
  onSuccess: () => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationKey: [GET_CONNECTOR_KEY, sourceType, 'AUTH'],
    mutationFn: saveAuthorization,
    onSuccess,
    onError,
  });

const createAuthorization = async (params: ConnectorSetup) => {
  const { data } = await apiClient.startIntegrationLogin(params.type!, params);
  return data;
};
export const useAuthorizeConnector = (
  onSuccess: (data: ConnectorSetup) => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationKey: [GET_CONNECTOR_KEY, 'INIT'],
    mutationFn: createAuthorization,
    onSuccess,
    onError,
  });

const refreshAuthorization = async (type: ConnectorInfo['source']) => {
  const { data } = await apiClient.getIntegrationAuthRefresh(type!);
  return data;
};

export const useRefreshAuthConnector = (
  sourceType: any,
  onSuccess: () => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationKey: [GET_CONNECTOR_KEY, sourceType, 'AUTH'],
    mutationFn: async () => await refreshAuthorization(sourceType),
    onSuccess,
    onError,
  });

export const useGetConnectors = (): QueryObserverResult<Connectors> =>
  useQuery<Connectors>({
    queryKey: [GET_CONNECTORS_PATH],
    queryFn: async () => {
      const { data } = await apiClient.getConnectors();
      return data;
    },
  });

const isCrm = (connectorType: string) => {
  return (
    connectorType && ['close', 'hubspot', 'salesforce'].includes(connectorType)
  );
};

export const filterCrmConnectors = (
  connectors:
    | { integrations?: Record<string, { status?: string }> }
    | null
    | undefined
): Option[] => {
  const externalSources: Option[] = [];

  Object.entries(connectors?.integrations || {}).forEach(
    ([connectorType, integration]) => {
      if (integration.status === 'active' && isCrm(connectorType)) {
        externalSources.push({
          name:
            connectorType === 'hubspot'
              ? 'HubSpot'
              : capitalizeFirstLetter(connectorType),
          value: connectorType,
        });
      }
    }
  );

  return externalSources;
};
