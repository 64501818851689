import React from 'react';
import { useFindSignatureData } from '../../../../services/signature';
import { formatDatetimeOrDefault, Loading, useTranslation } from 'common';
import { useIsSuperAdmin } from '../../../../core-utils/helperFunctions/userServiceHelper';

interface Props {
  signatureId: string;
}

const DefaultSignaturePreview = ({ signatureId }: Props) => {
  const { data, isLoading } = useFindSignatureData(signatureId);
  const isSuperAdmin = useIsSuperAdmin();
  const { tk } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {data ? (
        <div>
          <div>{data.name}</div>
          <div>{data.title}</div>
          <div>{formatDatetimeOrDefault(data.updatedAt)}</div>
          {isSuperAdmin && (
            <img className="h-24 w-48 mt-2" src={data.signatureImageData} />
          )}
        </div>
      ) : (
        tk('Not set')
      )}
    </div>
  );
};

export default DefaultSignaturePreview;
