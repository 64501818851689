import React, { useEffect } from 'react';
import { ErrorFetching, Loading, log, noop, ProposalEvent } from 'common';
import { useGetProposalEvents } from '../../../../services/proposal';
import {
  clusterByDate,
  getEventsPropsClusters,
} from '../../../../core-utils/helperFunctions/eventUtils';
import EventClusters from 'app/src/components/events/EventClusters';
import EventCluster from 'app/src/components/events/EventCluster';
import EventDisplay, {
  EventDisplayProps,
} from 'app/src/components/events/EventDisplay';
import getEventElements from './getEventElements';
import NoEvents from 'app/src/components/events/NoEvents';
import { ApprovalEvents } from './ApprovalEvents';

import styles from './ProposalEvents.module.scss';

interface Props {
  isOpen: boolean;
  proposalId: string;
}

const ProposalEvents: React.FC<Props> = ({ proposalId, isOpen }) => {
  const {
    data: events,
    isLoading: isLoadingEvents,
    isError: isErrorGettingEvents,
    refetch,
  } = useGetProposalEvents(proposalId);

  useEffect(() => {
    if (isOpen) {
      refetch().then(noop, log.error);
    }
  }, [isOpen]);

  if (isLoadingEvents) {
    return <Loading />;
  }

  if (isErrorGettingEvents) {
    return <ErrorFetching />;
  }

  if (!events) {
    return null;
  }

  const filteredEvents: ProposalEvent[] = events.filter((e) => {
    if (e.eventType === 'updated') {
      return false;
    }

    if (e.eventType === 'buyer_updated') {
      return false;
    }

    return true;
  });

  if (filteredEvents.length === 0) {
    return <NoEvents />;
  }

  const eventToEventDisplayPropsConverter = (
    event: ProposalEvent,
    previousEvent?: ProposalEvent
  ): EventDisplayProps | null => {
    const elements = getEventElements(event, proposalId, previousEvent);
    if (elements === null) {
      return null;
    }

    return {
      id: event.id!,
      createdAt: event.createdAt!,
      ...elements,
    };
  };

  const eventsClusteredByDate = clusterByDate<ProposalEvent>(filteredEvents);
  const eventPropsClusteredByDate = getEventsPropsClusters(
    eventsClusteredByDate,
    eventToEventDisplayPropsConverter
  );

  return (
    <EventClusters>
      <ApprovalEvents proposalId={proposalId} isOpen={isOpen} />

      <div className={styles.events}>
        {eventPropsClusteredByDate.map((cluster) => (
          <EventCluster key={cluster.date} date={cluster.date}>
            {cluster.propObjects.map((props) => (
              <EventDisplay key={props.id} {...props} />
            ))}
          </EventCluster>
        ))}
      </div>
    </EventClusters>
  );
};

export default ProposalEvents;
