import { getUrl } from '../helper';
import {
  Api as AutoGeneratedApi,
  logError,
  logRejected,
  logResponse,
  prepareRequest,
  SHARED_AXIOS_CONFIG,
} from 'common';

import { ENVIRONMENT_CONFIG } from '../../../config/hosts';

export const systemClient = new AutoGeneratedApi({
  baseURL: getUrl(ENVIRONMENT_CONFIG.cfAppHost),
  ...SHARED_AXIOS_CONFIG,
});

systemClient.instance.interceptors.request.use(prepareRequest, logRejected);
systemClient.instance.interceptors.response.use(logResponse, logError);
