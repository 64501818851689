import React from 'react';
import { Tag, Tooltip } from 'antd';

import { useGetGroupUsers } from 'app/src/services/groups';

import styles from './GroupUsersRow.module.scss';

type Group = {
  groupId: string;
  groupName?: string | undefined;
};
interface Props {
  group: Group;
}

export const GroupUsersRow = ({ group }: Props) => {
  const { data: usersInGroupList, isLoading } = useGetGroupUsers(group.groupId);

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <ul className={styles.users}>
        {usersInGroupList?.map((user) => (
          <li key={user.id}>
            <Tooltip title={user.username}>
              <Tag className={styles.tag}>
                {user.firstName} {user.lastName}
              </Tag>
            </Tooltip>
          </li>
        ))}
      </ul>
    </div>
  );
};
