import React from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { USER_NOTIFICATIONS, USER_PROFILE } from '../../core-utils/routes';
import UserProfile from './UserProfile';
import UserNotifications from './UserNotifications';
import Page from '../../components/Page';
import RenderRoutes from '../../services/routes/RenderRoutes';
import { USER_ROUTE } from '../../services/routes/Routes';
import { useTranslation } from 'common';

interface TabDefinition {
  label: string;
  path: string;
}

interface NavItemProps {
  label: string;
  navKey: string | number;
  path: string;
}

const CompanyNavItem: React.FC<NavItemProps> = ({ label, path, navKey }) => {
  return (
    <NavLink
      to={path}
      key={navKey}
      className={({ isActive }) => clsx('nav-item', { active: isActive })}
      data-testid={label}
    >
      {label}
    </NavLink>
  );
};

const UserPage: React.FC = () => {
  const { tk } = useTranslation();
  const tabs: TabDefinition[] = [
    {
      label: tk('Profile'),
      path: USER_PROFILE,
    },
    {
      label: tk('Slack notifications'),
      path: USER_NOTIFICATIONS,
    },
  ];

  return (
    <Page className="company-settings-container" leftWidget="User settings">
      <ul className="company-nav-list">
        {tabs.map(({ label, path }, index) => (
          <CompanyNavItem
            label={label}
            path={path}
            navKey={index}
            key={label}
          />
        ))}
      </ul>
      <div className="w-full justify-self-start">
        <RenderRoutes
          parentRoute={USER_ROUTE}
          routes={[
            { path: USER_PROFILE, component: UserProfile },
            { path: USER_NOTIFICATIONS, component: UserNotifications },
            {
              path: '*',
              component: () => <Navigate replace to={USER_PROFILE} />,
            },
          ]}
        />
      </div>
    </Page>
  );
};

export default UserPage;
