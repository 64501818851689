import React from 'react';

import {
  ActivityEvent,
  Icon,
  NavigationIcon,
  TransactionActivityDetails,
} from 'common';

import StatusLabel from '../StatusLabel';

export type ActivityResourceTypes = 'invoice' | 'refund' | 'transfer';

const getReadableReasonCode = (
  reasonCode: TransactionActivityDetails['reasonCode']
) => {
  switch (reasonCode) {
    case 'flagged_account':
      return 'Flagged account';
    case 'insufficient_funds':
      return 'Insufficient funds';
    case 'invalid_account':
      return 'Invalid account';
    case 'invalid_address':
      return 'Invalid address';
    case 'region_not_supported':
      return 'Region not supported';
    case 'requires_buyer_approval':
      return 'Buyer approval required';
    case 'transaction_failure':
      return 'Transaction failed';

    default:
      return '';
  }
};

const getCreatedIconByResource = (resource: ActivityResourceTypes) => {
  switch (resource) {
    case 'refund':
      return NavigationIcon.Refund;

    case 'invoice':
      return NavigationIcon.Agreements;

    case 'transfer':
      return Icon.CurrencyUsd;

    default:
      return Icon.Activity;
  }
};

export const getResourceLabelForResource = (
  resource: ActivityResourceTypes
) => {
  switch (resource) {
    case 'refund':
      return 'Refund';

    case 'invoice':
      return 'Invoice';

    case 'transfer':
      return 'Payment';

    default:
      return '';
  }
};

const getStatusPillForResource = (
  status: TransactionActivityDetails['status'],
  resource: ActivityResourceTypes
) => {
  return (
    <StatusLabel
      classNames="!p-2 !rounded !h-[18px]"
      feature={resource}
      status={status}
    />
  );
};

export const getEventElementsByResource = (
  event: ActivityEvent,
  resource: ActivityResourceTypes
) => {
  const { eventType } = event;

  switch (eventType) {
    case 'created':
      return {
        label: `${getResourceLabelForResource(resource)} created`,
        icon: getCreatedIconByResource(resource),
      };

    case 'transfer': {
      const details = event.details as TransactionActivityDetails;
      const { status, reasonCode } = details;

      switch (status) {
        case 'pending':
        case 'in_progress':
          return {
            label: getResourceLabelForResource(resource),
            icon: Icon.Hourglass,
            statusPill: getStatusPillForResource(status, resource),
          };
        case 'posted':
          return {
            label: getResourceLabelForResource(resource),
            icon: Icon.Checkmark,
            statusPill: getStatusPillForResource(status, resource),
          };
        case 'cancelled':
        case 'failed':
        case 'declined':
        case 'reversed':
          return {
            label: getResourceLabelForResource(resource),
            icon: Icon.Close,
            statusPill: getStatusPillForResource(status, resource),
            message: getReadableReasonCode(reasonCode),
          };

        default:
          return null;
      }
    }

    default:
      break;
  }

  return null;
};
