import React, { useState } from 'react';
import {
  Button,
  ConfirmModal,
  Contact,
  getPersonFullName,
  hasAnAcceptedProposalStatus,
  isDefined,
  isSignedProposal,
  Proposal,
  SearchSelect,
  useTranslation,
} from 'common';
import { useContacts } from '../../../../services/contacts';
import ProposalContact from './ProposalContact';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { useDocusignEnvelopeQuotaStatus } from '../../../../services/api/docusign/docusign';
import { Alert } from 'antd';

interface Props {
  handleContactEditClick: (contact: Contact) => void;
  handleContactsChange: (recipientIds: string[]) => void;
  onClose: () => void;
  proposal: Proposal;
  toCreateContact: (contactStarterName?: string) => void;
}

const ShareProposalView: React.FC<Props> = ({
  proposal,
  toCreateContact,
  handleContactEditClick,
  handleContactsChange,
  onClose,
}) => {
  const { tk } = useTranslation();
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  const [showConfirm, setShowConfirm] = useState(false);
  const [contactToRemove, setContactToRemove] = useState<Contact | undefined>(
    undefined
  );
  const signerEmail = proposal.signingDocument?.signer.email;
  const isSigned = isSignedProposal(proposal);
  const customerId = proposal.customer?.id;
  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);
  const proposalContacts = proposal.proposalContacts.map((c) => c.contact);
  const recipientIds = proposal.proposalContacts
    .map((c) => c.contact?.id)
    .filter(isDefined);
  const { data: contacts = [] } = useContacts(customerId);
  const unselectedContacts: Contact[] = contacts.filter(
    (c) => c.id && !recipientIds.includes(c.id)
  );
  const { envelopeQuotaStatus: envelopeQuota } =
    useDocusignEnvelopeQuotaStatus();

  const onSelectContact = (contactId: string) => {
    const newRecipients = [...recipientIds, contactId];
    handleContactsChange(newRecipients);
  };

  const onRemoveContact = () => {
    handleContactsChange(
      recipientIds.filter((id: string) => id !== contactToRemove?.id)
    );
    setContactToRemove(undefined);
    setShowConfirm(false);
  };

  const handleContactRemoveClick = (contact: Contact) => {
    setShowConfirm(true);
    setContactToRemove(contact);
  };

  const handleClose = () => {
    setContactToRemove(undefined);
    setShowConfirm(false);
  };

  return (
    <div>
      <div className="pb-6 px-6">
        <SearchSelect
          addNewPlaceHolder={(currentlyTyped) =>
            currentlyTyped.replace(/\s/g, '').length
              ? `Create ${currentlyTyped.replace(/\s/g, '')}`
              : 'Create new customer contact'
          }
          className={canUpdate ? undefined : 'invisible'}
          dataTestId="contactId"
          displayField={getPersonFullName}
          onAddNew={(currentlyTyped) =>
            toCreateContact(
              currentlyTyped.replace(/\s/g, '') === ''
                ? undefined
                : currentlyTyped
            )
          }
          onChange={(c) => onSelectContact(c.id)}
          options={unselectedContacts}
          placeholder="Select a customer contact"
        />
      </div>

      <div className="px-8 pb-4">
        <p>
          {tk('This will generate a unique link. ')}
          <span className="font-semibold">
            {tk('You must manually send the unique link to the customer.')}
          </span>
        </p>
      </div>

      {envelopeQuota.quotaStatus && (
        <div className="px-6 pb-4">
          <Alert
            description={envelopeQuota.quotaDescription}
            message={envelopeQuota.quotaStatus}
            showIcon
            type="warning"
          />
        </div>
      )}

      {proposalContacts.length > 0 && (
        <>
          <div className="px-6 pt-4">
            <div className="uppercase bold text-med font-bold pb-2 border-b border-gray-lines">
              {tk('Added Recipients')}
            </div>
          </div>
          <div className="flex flex-col gap-2 pt-4">
            {proposalContacts.filter(isDefined).map((contact) => (
              <ProposalContact
                contact={contact}
                isDisabled={!canUpdate}
                isProposalSigned={isSigned}
                key={contact.email}
                onClick={() => handleContactEditClick(contact)}
                onClickRemove={() => handleContactRemoveClick(contact)}
                proposalId={proposal.id}
                proposalIsAccepted={hasAnAcceptedStatus}
                signerEmail={signerEmail ?? ''}
              />
            ))}
          </div>
        </>
      )}

      {hasAnAcceptedStatus && (
        <p className="p-6 mx-6 my-3 rounded bg-slate-lightest">
          This proposal has been{' '}
          <span className="font-semibold text-green">Completed</span>. You can
          no longer edit this proposal.
        </p>
      )}

      <div className="w-full flex justify-end p-6">
        <Button
          dataTestId="close-copy-checkout-modal"
          label="Close"
          onClick={onClose}
        />
      </div>

      <ConfirmModal
        confirmText="Yes, remove"
        isDanger
        isOpen={showConfirm}
        onClose={handleClose}
        onConfirm={onRemoveContact}
        width={400}
      >
        <div className="p-2">
          Are you sure you want to remove{' '}
          <b>
            {contactToRemove?.firstName} {contactToRemove?.lastName}
          </b>
          ?
          <div className="mt-4">
            Removing a contact will remove their access to your proposal, but
            you can add contacts back at any time.
          </div>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default ShareProposalView;
