import React from 'react';

interface Props {
  // Anything passed in as a child, will be
  // rendered to the right of the message
  children?: React.ReactNode;
  // A message can be either a React element or a string. Will be
  // rendered to the left of the children, or in the center if no child node is provided
  message?: React.ReactElement | string;
  // One of three types to be passed in to determine the background
  // color of the status bar
  type: 'danger' | 'informative' | 'caution';
}

const StatusBar: React.FC<Props> = ({ children, message, type }) => {
  const getBackground = () => {
    switch (type) {
      case 'danger':
        return 'bg-red-lighter';
      case 'informative':
        return 'bg-blue-lightest';
      default:
        return 'bg-orange-lightest';
    }
  };
  return (
    <div
      className={`p-2 flex flex-row items-center shadow-md justify-center ${getBackground()}`}
    >
      {message}
      <div className="pl-4">{children}</div>
    </div>
  );
};

export default StatusBar;
