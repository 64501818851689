import React, { useEffect, useRef, useState } from 'react';
import { RefSelectProps, Select } from 'antd';
import clsx from 'clsx';

import {
  CreateProposalApprovalRuleRequest,
  InputRef,
  LinkButton,
  NumberInput,
  ProposalApprovalRuleConfig,
  ProposalSummary,
} from 'common';

import {
  GroupOption,
  RuleInfo,
  searchFilterOption,
} from '../rules/_approvalRulesConfig';

import styles from './ProposalRuleBuilderStyles.module.scss';

interface Props {
  allGroupOptions: GroupOption[];
  createRule: (payload: CreateProposalApprovalRuleRequest) => void;
  onCancelAdd: () => void;
  proposalType: ProposalSummary['proposalType'];
  selectedRule: RuleInfo;
}

const DEFAULT_VALUE = 0;

export const ProposalNumberBuilder = ({
  proposalType,
  createRule,
  selectedRule,
  onCancelAdd,
  allGroupOptions,
}: Props) => {
  const [value, setValue] = useState<number>(DEFAULT_VALUE);

  const ref = useRef<InputRef>(null);
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    ref.current?.focus({ cursor: 'all' });
  }, []);

  const handleSave = (newValue: number, groupId: string[]) => {
    const payload: CreateProposalApprovalRuleRequest = {
      name: `${selectedRule.name}-${proposalType}`,
      config: {
        [selectedRule.configKey]: newValue,
        proposalType,
        ruleType: selectedRule.name as ProposalApprovalRuleConfig['ruleType'],
      },
      approvalGroupIds: groupId,
    };

    createRule(payload);
  };

  const handleSaveIfValid = (id: string[]) => {
    if (value && value > 0 && id.length > 0) {
      handleSave(value, id);
    }
  };

  const handleChange = (newValue: number | undefined) => {
    if (newValue !== undefined) {
      setValue(newValue);
    }
  };

  const handleEnter = () => selectRef.current?.focus();

  return (
    <div
      className={clsx(styles.ruleRow, !!value && styles.showingGroupsSelect)}
    >
      <div className={styles.condition}>{selectedRule.conditionOperator}</div>

      <div className={styles.input}>
        <NumberInput
          ref={ref}
          defaultValue={DEFAULT_VALUE}
          value={value}
          onChange={handleChange}
          onEnter={handleEnter}
          suffix="Months"
          max={1000}
        />
      </div>

      {!value && (
        <LinkButton className={styles.cancel} onClick={onCancelAdd}>
          cancel
        </LinkButton>
      )}

      {!!value && (
        <div className={styles.groups}>
          {allGroupOptions.length > 0 && (
            <Select
              ref={selectRef}
              onChange={handleSaveIfValid}
              value={[]}
              options={allGroupOptions}
              mode="multiple"
              filterOption={searchFilterOption}
              className={styles.groupWidth}
              placeholder="Select approval group"
              popupMatchSelectWidth={false}
            />
          )}
        </div>
      )}
    </div>
  );
};
