import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { PaymentTerm } from 'common';
import { apiClient } from './httpClients/app';

const GET_PAYMENT_TERMS_DATA = '/api/latest/settings/payment-terms';

export const usePaymentTerms = (): UseQueryResult<PaymentTerm[]> =>
  useQuery({
    queryKey: [GET_PAYMENT_TERMS_DATA],
    queryFn: async () => {
      const { data } = await apiClient.getPaymentTerms();
      return data;
    },
  });

const batchUpdatePaymentTerms = async (paymentTerms: PaymentTerm[]) => {
  const { data } = await apiClient.updatePaymentTermBatch(paymentTerms);
  return data;
};

export const useBatchUpdatePaymentTerms = (
  paymentTerms: PaymentTerm[],
  onSuccess: (data: PaymentTerm[]) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [GET_PAYMENT_TERMS_DATA],
    mutationFn: () => batchUpdatePaymentTerms(paymentTerms),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [GET_PAYMENT_TERMS_DATA] });
    },
  });
