import React from 'react';
import clsx from 'clsx';
import { Icon, SIZE_SMALL, useToast } from 'common';

interface Props {
  children: React.ReactNode;
  className?: string;
  documentID: string | undefined;
}

const IDField: React.FC<Props> = ({ children, className, documentID }) => {
  const showToast = useToast();
  if (!documentID) {
    return null;
  }

  const handleClick = async () => {
    await navigator.clipboard.writeText(documentID);
    showToast.info('Copied to clipboard!');
  };

  return (
    <button
      type="button"
      className={clsx(
        'flex items-top cursor-pointer hover:text-blue whitespace-nowrap',
        className
      )}
      onClick={handleClick}
    >
      {children}
      <div className="text-blue" style={{ position: 'relative', top: '-2px' }}>
        <Icon.Copy {...SIZE_SMALL} />
      </div>
    </button>
  );
};

export default IDField;
