import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

import { LinkButton, Proposal, ProposalApprovalRuleConfig } from 'common';

import {
  getRuleInfo,
  getRulesForProposalType,
  GroupOption,
  RuleInfo,
  searchFilterOption,
} from '../rules/_approvalRulesConfig';
import { useGetProposalApprovalRules } from 'app/src/services/approvals';

import { ProposalTextBuilder } from './ProposalTextBuilder';
import { ProposalMoneyBuilder } from './ProposalMoneyBuilder';
import { ProposalNumberBuilder } from './ProposalNumberBuilder';
import { ProposalPercentBuilder } from './ProposalPercentBuilder';
import { ProposalBooleanBuilder } from './ProposalBooleanBuilder';
import { ProposalChangeTCVBuilder } from './ProductChangeTCVBuilder';
import { ProposalMultiselectBuilder } from './ProposalMultiselectBuilder';

import styles from './RuleSelector.module.scss';

const ruleComponentsMap = {
  proposalAmount: ProposalMoneyBuilder,
  proposalDiscountPercent: ProposalPercentBuilder,
  proposalCountry: ProposalTextBuilder,
  proposalCustomTerms: ProposalBooleanBuilder,
  proposalAutoRenewal: ProposalBooleanBuilder,
  proposalDocusign: ProposalBooleanBuilder,
  proposalExternalCrm: ProposalBooleanBuilder,
  proposalAttachments: ProposalBooleanBuilder,
  proposalAmountDecrease: ProposalBooleanBuilder,
  proposalFreeMonths: ProposalNumberBuilder,
  proposalTermLength: ProposalNumberBuilder,
  proposalPaymentTerms: ProposalMultiselectBuilder,
  proposalBillingPeriods: ProposalMultiselectBuilder,
  proposalChangeTCV: ProposalChangeTCVBuilder,
  proposalDuplicateProducts: ProposalBooleanBuilder,
};

type RuleComponentKey = keyof typeof ruleComponentsMap;

interface Props {
  allGroupOptions: GroupOption[];
  isPending?: boolean;
  onCancelAdd: () => void;
  onRuleAdded: (addedRule: any) => void;
  proposalType: Proposal['proposalType'];
}

export const RuleSelector = ({
  proposalType,
  onRuleAdded,
  onCancelAdd,
  isPending,
  allGroupOptions,
}: Props) => {
  const [selectedRule, setSelectedRule] = useState<RuleInfo | null>(null);
  const [selectableRules, setSelectableRules] = useState<RuleInfo[]>([]);

  const { data: proposalApprovalRules } = useGetProposalApprovalRules();

  useEffect(() => {
    const availableRules = getRulesForProposalType(proposalType);
    setSelectableRules(availableRules);
  }, [proposalType]);

  const handleRuleChange = (value: ProposalApprovalRuleConfig['ruleType']) => {
    if (value in ruleComponentsMap) {
      setSelectedRule(getRuleInfo(value));
    }
  };

  const excludeDuplicateBooleanRuleTypes = [
    'proposalCustomTerms',
    'proposalAutoRenewal',
    'proposalDocusign',
    'proposalExternalCrm',
    'proposalAttachments',
    'proposalAmountDecrease',
    'proposalDuplicateProducts',
  ];

  const filteredApprovalRulesByProposalType = proposalApprovalRules?.filter(
    (rule) => rule.config?.proposalType === proposalType
  );

  const filteredSelectableRules = selectableRules.filter((selectableRule) => {
    const matchingApprovalRule = filteredApprovalRulesByProposalType?.find(
      (approvalRule) => {
        return approvalRule.config?.ruleType === selectableRule.name;
      }
    );

    return (
      !matchingApprovalRule ||
      (matchingApprovalRule.config?.ruleType &&
        !excludeDuplicateBooleanRuleTypes.includes(
          matchingApprovalRule.config.ruleType
        ))
    );
  });

  const selectableRuleOptions = filteredSelectableRules.map((rule) => {
    return {
      ...rule,
      value: rule.name,
      label: rule.description,
    };
  });

  const SelectedRuleComponent =
    selectedRule && selectedRule.name in ruleComponentsMap
      ? ruleComponentsMap[selectedRule.name as RuleComponentKey]
      : null;

  return (
    <div className={styles.ruleSelector}>
      <Select
        autoFocus
        className={styles.select}
        data-testid="proposal-condition-selector"
        filterOption={searchFilterOption}
        onChange={handleRuleChange}
        optionFilterProp="children"
        options={selectableRuleOptions}
        placeholder="Add proposal condition"
        popupMatchSelectWidth={false}
        showAction={['focus', 'click']}
        showSearch
      />

      {SelectedRuleComponent && selectedRule && (
        <SelectedRuleComponent
          allGroupOptions={allGroupOptions}
          createRule={onRuleAdded}
          onCancelAdd={onCancelAdd}
          proposalType={proposalType}
          selectedRule={selectedRule}
        />
      )}

      {!isPending && !selectedRule && (
        <LinkButton className={styles.cancel} onClick={onCancelAdd}>
          cancel
        </LinkButton>
      )}
    </div>
  );
};
