import React from 'react';
import {
  Button,
  getErrorMessage,
  log,
  PaymentMethod,
  ToggleSwitch,
  useToast,
} from 'common';
import ACH from './ACH';
import DirectDebitCard from './DirectDebitCard';
import CreditCard from 'app/src/pages/Settings/PaymentMethods/CreditCard';
import PaymentMethodName from './PaymentMethodName';
import { useUpdatePaymentMethod } from '../../../services/paymentMethods';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  isDisabled?: boolean;
  method: PaymentMethod;
  onModifyClick: () => void;
}

const PaymentMethodCard: React.FC<Props> = ({
  method,
  onModifyClick,
  isDisabled = false,
}) => {
  const queryClient = useQueryClient();
  const showToast = useToast();
  const { mutate: togglePaymentMethod } = useUpdatePaymentMethod(
    method.id!,
    (res: PaymentMethod) =>
      showToast.info(
        `Successfully ${res.enabled ? 'enabled' : 'disabled'} payment method`
      ),
    (err: unknown) => {
      showToast.error(
        'There was an issue updating the payment method. Please try again'
      );
      log.error(getErrorMessage(err));
    },
    queryClient
  );

  const handlePaymentToggle = () => {
    const { enabled, name, billingPeriods } = method;

    if (enabled && billingPeriods?.length! > 0) {
      showToast.warning(
        `Unable to disable payment method. Please deactivate ${name} for billing periods.`
      );
      return;
    }

    togglePaymentMethod({ enabled: !enabled });
  };
  const getBody = () => {
    if (method.paymentType === 'cc') {
      return <CreditCard paymentMethod={method} />;
    }

    if (method.paymentType === 'direct_debit') {
      return <DirectDebitCard paymentMethod={method} />;
    }

    return (
      <div className="w-full">
        {method.paymentType === 'ach' ? (
          <ACH paymentMethod={method} />
        ) : (
          method.customText
        )}
        <div className="flex justify-end">
          <Button label="Configure" onClick={onModifyClick} />
        </div>
      </div>
    );
  };

  return (
    <div className="card-shadow mb-6 max-w-[700px] rounded-md" key={method.id}>
      <div
        className={`bg-[var(--slate-lightest)] flex items-center justify-between p-6 rounded-t-md ${
          method.enabled ? 'active' : ''
        }`}
      >
        <div className="flex flex-col text-md">
          <div className="flex items-center text-lg font-semibold">
            <PaymentMethodName
              method={method}
              internalNameClassName="text-base"
            />
          </div>
          {method.description}
        </div>

        <ToggleSwitch
          value={method.enabled}
          name={method.name}
          label={method.enabled && !isDisabled ? 'Enabled' : 'Disabled'}
          onChange={handlePaymentToggle}
          isDisabled={isDisabled}
          dataTestId={method.paymentType + '-toggle'}
        />
      </div>
      <div className="flex h-full bg-white px-6 py-10 rounded-md">
        {getBody()}
      </div>
    </div>
  );
};

export default PaymentMethodCard;
