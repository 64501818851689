import React, { useEffect } from 'react';
import { Tooltip } from 'antd';

import {
  ApprovalRequest,
  Group,
  Icon,
  Loading,
  log,
  noop,
  User,
  useToast,
} from 'common';

import { apiClient } from 'app/src/services/httpClients/app';
import { GroupUsersRow } from '../Approvals/GroupUsersRow';
import { useGetAllProposalApprovalRequests } from 'app/src/services/proposal';
import { UsersInGroup } from './UsersInGroup';

import styles from './ApprovalEvents.module.scss';
import { useGetAllApprovalGroups } from 'app/src/services/api/approvalGroups/approvalGroups';

type GroupRowProps = {
  groupId: string;
  groupName: string;
};

type ApprovalRequestWithGroup = ApprovalRequest & {
  group: Group;
};
interface Props {
  isOpen: boolean;
  proposalId: string;
}

export const ApprovalEvents = ({ proposalId, isOpen }: Props) => {
  const showToast = useToast();

  const {
    data: approvalRequests,
    isLoading,
    refetch,
  } = useGetAllProposalApprovalRequests(proposalId);

  const { data: approvalGroups, isLoading: isApprovalGroupsLoading } =
    useGetAllApprovalGroups();

  useEffect(() => {
    refetch().then(noop, log.error);
  }, [isOpen]);

  const handleCopyEmailsToClipboard = async (groupId: string) => {
    const { data } = await apiClient.getUsersByGroupId(groupId);

    const emailList = data.map((user: User) => user.username).join(',');

    await navigator.clipboard.writeText(emailList);
    showToast.info('Emails copied to clipboard!');
  };

  const buildGroup = (group: Group): GroupRowProps => {
    return {
      groupId: group.id!,
      groupName: group.name!,
    };
  };

  if (isLoading || isApprovalGroupsLoading) {
    return <Loading />;
  }

  if (!approvalRequests || !approvalGroups) {
    return null;
  }

  const pendingApprovalRequests: ApprovalRequestWithGroup[] = approvalRequests
    .filter((ag: ApprovalRequest) => ag.status === 'pending')
    .map((request: ApprovalRequest) => {
      const updatedRequest: ApprovalRequestWithGroup = {
        ...request,
        group: {} as Group,
      };
      request.approvers?.forEach((approver) => {
        const group = approvalGroups.find(
          (g) => g.group?.id === approver.groupId
        );
        if (group?.group) {
          updatedRequest.group = group.group;
        } else {
          throw new Error(
            `No approval user groups found for ApprovalRequest ${request.id}`
          );
        }
      });
      return updatedRequest;
    });

  if (pendingApprovalRequests.length === 0) {
    return null;
  }

  return (
    <div className={styles.approvalEvents}>
      <div className={styles.title}>Pending activities</div>
      {pendingApprovalRequests.map((ag: ApprovalRequestWithGroup) => (
        <div key={ag.id} className={styles.event}>
          <div className={styles.icon}>
            <Icon.Hourglass />
          </div>
          <div className={styles.eventBody}>
            <div className={styles.group}>
              Approval required: {ag.group.name}
              <Tooltip title="Copy all emails">
                <Icon.Copy
                  width={22}
                  height={22}
                  className={styles.copyIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyEmailsToClipboard(ag.group.id || '')
                      .then(noop)
                      .catch(log.error);
                  }}
                />
              </Tooltip>
            </div>
            <div className={styles.usersList}>
              <UsersInGroup id={ag.group.id!} />
              <GroupUsersRow group={buildGroup(ag.group)} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
