import React from 'react';
import {
  CardHeader,
  Cell,
  SimpleTable,
  SystemEvent,
  useTranslation,
} from 'common';
import DateTimeCell from '../../../components/DateTimeCell';

interface Props {
  events: SystemEvent[];
  title: string;
}

const SystemEventList: React.FC<Props> = ({ events, title }) => {
  const { tk } = useTranslation();

  const cells: Cell<SystemEvent>[] = [
    {
      key: 'id',
      headerLabel: tk('Id'),
      renderCell: (data: SystemEvent) => <div>{data.id}</div>,
    },
    {
      key: 'processedAtFormatted',
      headerLabel: tk('Date / Time'),
      renderCell: (data: SystemEvent) => <DateTimeCell date={data.updatedAt} />,
    },
    {
      key: 'name',
      headerLabel: tk('Event name'),
      renderCell: (data: SystemEvent) => (
        <div>
          <div>{data.name}</div>
          <div className="text-xs">{data.type}</div>
        </div>
      ),
    },

    {
      key: 'reason',
      headerLabel: tk('Reason'),
      renderCell: (data: SystemEvent) => <div>{data.reason}</div>,
    },

    {
      key: 'status',
      headerLabel: tk('Status'),
      renderCell: (data: SystemEvent) => <div>{data.status}</div>,
    },
  ];

  return (
    <div className="w-full">
      <CardHeader name={title} />
      <SimpleTable<SystemEvent>
        disableSearch
        disableShadow
        cells={cells}
        rows={events}
        isLoading={false}
        zeroStateMessage="There is no activity for this org."
      />
    </div>
  );
};

export default SystemEventList;
