import { Proposal } from 'common';
import React from 'react';
import styles from './proposalDebugger.module.scss';

interface Props {
  proposal: Proposal;
}

const ProposalBasics: React.FC<Props> = ({ proposal }) => {
  function getRow(name: string, value: any) {
    return (
      <tr>
        <td>{name}</td>
        <td>{value}</td>
      </tr>
    );
  }

  return (
    <div>
      <table className={styles.fieldTable}>
        <tbody>
          {getRow('id', proposal.id)}
          {getRow('created at', proposal.createdAt)}
          {getRow('created by', proposal.createdBy)}
          {getRow('updated at', proposal.updatedAt)}
          {getRow('updated by', proposal.updatedBy)}
          {getRow('type', proposal.proposalType)}
          {getRow('status', proposal.status)}
          {getRow('displayStatus', proposal.displayStatus)}
          {getRow('amount', proposal.amount)}
          {getRow('baseAmount', proposal.baseAmount)}
          {getRow(
            'options',
            <pre>{JSON.stringify(proposal.options, null, 2)}</pre>
          )}
          {getRow('customerId', proposal.customer?.id)}
        </tbody>
      </table>
    </div>
  );
};

export default ProposalBasics;
