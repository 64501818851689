import React from 'react';
import { Button, Icon, PaymentIcon, useTranslation } from 'common';
import usePlaid from './usePlaid';
import CreateOrgNameModal from './CreateOrgNameModal';
import BankAccountsCard from './BankAccountsCard';
import styles from './AddBankAccountCard.module.scss';

const AddBankAccountCard = () => {
  const { tk } = useTranslation();

  const {
    handleOpen,
    ready,
    handleChange,
    handleSubmit,
    setIsOpen,
    isOpen,
    textFieldError,
    apiError,
    orgLegalName,
    isLoading,
  } = usePlaid();

  return (
    <>
      <CreateOrgNameModal
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        textFieldError={textFieldError}
        apiError={apiError}
        orgLegalName={orgLegalName}
      />
      <BankAccountsCard>
        <div className={styles.addBankAccountCard}>
          <PaymentIcon.Ach width={72} height={72} />
        </div>
        <div className={styles.addBankAccountCard}>
          <Button
            iconBefore={Icon.Plus}
            label={tk('Add bank account')}
            isDisabled={!ready}
            isLoading={isLoading}
            dataTestId="add-bank-account-button"
            onClick={handleOpen}
          />
        </div>
      </BankAccountsCard>
    </>
  );
};

export default AddBankAccountCard;
