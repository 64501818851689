import {
  ApprovalRequest,
  Button,
  Loading,
  Proposal,
  useTranslation,
} from 'common';
import React, { useState } from 'react';
import { useGetProposalApprovalRequirements } from '../../../../services/proposal';
import { RequestApprovalDrawer } from './RequestApprovalDrawer';

interface RequestApprovalProps {
  isSticky?: boolean;
  lastApprovalRequest?: ApprovalRequest | null;
  proposal: Proposal;
}

const RequestApproval = ({
  proposal,
  lastApprovalRequest,
  isSticky,
}: RequestApprovalProps) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { tk } = useTranslation();
  const wasRejected = lastApprovalRequest?.status === 'rejected';
  const rejectMessage =
    lastApprovalRequest?.actions?.find((a) => {
      return a.action === 'reject';
    })?.message || '';

  const { data: requirements, isLoading } = useGetProposalApprovalRequirements(
    proposal.id
  );

  if (isLoading) {
    return <Loading />;
  }

  const getRequirementDescription = () => {
    if (!requirements?.ruleResults?.length) {
      return tk('Approval is required before sharing with customer');
    }

    const validRequirements = requirements.ruleResults
      .filter((r) => r.required)
      .filter((r) => r.groups && r.groups.length > 0);

    const uniqueGroupCount = new Set(validRequirements.flatMap((r) => r.groups))
      .size;

    const validRequirementItems = validRequirements.map((r) => {
      return (
        <p className="mb-1" key={r.id}>
          - {r.description}
        </p>
      );
    });

    if (isSticky) {
      return (
        <div className="font-bold">{`Approvals required by ${uniqueGroupCount} group(s).`}</div>
      );
    }

    return (
      <div className="flex flex-col">
        <div className="flex mb-2">
          <p className="font-bold mr-1">{`Approvals required by ${uniqueGroupCount} group(s).`}</p>
          <p>Approvals triggered by:</p>
        </div>
        <div className="flex flex-col items-start">{validRequirementItems}</div>
      </div>
    );
  };

  return (
    <div className="flex gap-4 w-full items-center justify-between">
      <div>
        {wasRejected ? (
          <span>
            <strong>{tk('Approval rejected')}</strong>
            {rejectMessage ? ': ' : ''}
            {rejectMessage}
          </span>
        ) : (
          getRequirementDescription()
        )}
      </div>

      <div>
        <Button
          dataTestId="request-approval"
          label="Request approval"
          onClick={() => setShowDrawer(true)}
        />
      </div>

      {requirements && (
        <RequestApprovalDrawer
          closeDrawer={() => {
            setShowDrawer(false);
          }}
          isShowing={showDrawer}
          proposal={proposal}
          requirements={requirements}
        />
      )}
    </div>
  );
};
export default RequestApproval;
