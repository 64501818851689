import React, { SVGProps } from 'react';
import {
  ConnectorInfo,
  DefaultZeroState,
  ExternalDeal,
  formatMoneyOrDefault,
  getErrorCode,
  getIconCell,
  Icon,
  InternalAppLink,
  ProposalTemplateSummary,
  SimpleCell,
  SimpleTable,
} from 'common';
import { useGetAllExternalDeals } from '../../services/externalCrm';
import { INTEGRATIONS_AND_CONNECTORS } from '../../core-utils/routes';
import ExternalEntityToProposalButton from './ExternalEntityToProposalButton';
interface Props {
  dealLabel: string;
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  isProcessing: boolean;
  onClickCreate: (
    deal: ExternalDeal,
    template?: ProposalTemplateSummary
  ) => void;
  selectedDealId: string | undefined;
  sourceType: ConnectorInfo['source'];
}

const ExternalDealList: React.FC<Props> = ({
  onClickCreate,
  dealLabel,
  sourceType,
  icon,
  isProcessing,
  selectedDealId,
}: Props) => {
  const {
    data: deals,
    isLoading,
    isError,
    error,
  } = useGetAllExternalDeals(sourceType);

  if (isError) {
    if (getErrorCode(error) === 'USER_NOT_CONNECTED_TO_INTEGRATION') {
      return (
        <div>
          This page requires that a connector be configured. Please do so in{' '}
          <InternalAppLink
            route={INTEGRATIONS_AND_CONNECTORS}
            breadcrumbs={['connector settings']}
          />
          .
        </div>
      );
    }
    return (
      <div>
        Error loading {dealLabel} -{error.message}
      </div>
    );
  }

  const redirectToExternalLink = (externalLink: string | undefined) => {
    if (externalLink) {
      window.open(externalLink, '_blank');
    }
  };

  const getCRMLink = ({
    text,
    navigateTo,
  }: {
    navigateTo: () => void;
    text: string;
  }) => (
    <div
      className="flex items-center font-bold hover:text-primary hover:underline cursor-pointer"
      onClick={navigateTo}
    >
      <div title={text}>{text}</div>
      <Icon.Link width="24" height="24" />
    </div>
  );

  const cells: SimpleCell<ExternalDeal>[] = [
    getIconCell(icon),
    {
      key: 'name',
      headerLabel: 'Opportunity',
      overflowCell: true,
      allowWrap: true,
      renderCell: (deal: ExternalDeal) =>
        deal.externalLink
          ? getCRMLink({
              text: deal.name!,
              navigateTo: () => redirectToExternalLink(deal.externalLink),
            })
          : deal.name,
      className: 'font-semibold',
      searchable: true,
      sortable: true,
    },
    {
      key: 'customerName',
      headerLabel: 'Customer',
      overflowCell: true,
      renderCell: (deal: ExternalDeal) => {
        if (!deal.externalCustomerLink) {
          return deal.customerName;
        }
        return getCRMLink({
          text: deal.customerName!,
          navigateTo: () => redirectToExternalLink(deal.externalCustomerLink),
        });
      },
      searchable: true,
      sortable: true,
    },
    {
      key: 'Amount',
      headerLabel: 'Amount',
      width: '120px',
      renderCell: (deal: ExternalDeal) => formatMoneyOrDefault(deal.amount),
      align: 'right',
      searchable: true,
      sortable: true,
      searchValue: (deal: ExternalDeal) => deal.amount?.amount || 0,
      sortValue: (deal: ExternalDeal) => deal.amount?.amount || 0,
    },
    {
      key: 'stage',
      headerLabel: 'Stage',
      overflowCell: true,
      renderCell: (deal: ExternalDeal) => deal.stage,
      sortable: true,
    },
    {
      key: 'create',
      headerLabel: '',
      width: '300px',
      clickable: true,
      renderCell: (deal: ExternalDeal) => (
        <ExternalEntityToProposalButton
          onClickCreate={(template?: ProposalTemplateSummary) => {
            onClickCreate(deal, template);
          }}
          isDisabled={isProcessing || !deal.customerId}
          isLoading={
            isLoading || (isProcessing && selectedDealId === deal.externalId)
          }
          proposal={deal.proposal}
        />
      ),
    },
  ];

  return (
    <SimpleTable<ExternalDeal>
      cells={cells}
      rows={deals}
      isLoading={isLoading}
      dataTestId="deals-table"
      minWidth="900px"
      zeroState={
        <DefaultZeroState
          dataTestId="no-deals"
          message={`There are no ${dealLabel} associated with this user.`}
        />
      }
    />
  );
};

export default ExternalDealList;
