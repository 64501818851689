import React, { useState } from 'react';
import { Select } from 'antd';

import {
  ApprovalRule,
  Button,
  ConfirmDeleteModal,
  Icon,
  isNumber,
  NumberInput,
  ProposalItemQuantityRuleConfig,
  ProposalSummary,
  ToolTip,
} from 'common';

import { GroupOption, searchFilterOption } from './_approvalRulesConfig';

import { useLatestProduct } from 'app/src/services/api/products/products';
import { getConfig } from './ruleUtils';

import styles from './ApprovalRuleStyles.module.scss';

interface Props {
  allGroupOptions: GroupOption[];
  approvalRule: ApprovalRule;
  onRuleChange: (
    id: string | undefined,
    changedRule: ApprovalRule | null
  ) => void;
  proposalType: ProposalSummary['proposalType'];
}

const DEFAULT_VALUE = 0;

export const ProductRuleQuantityRow = ({
  approvalRule,
  proposalType,
  onRuleChange,
  allGroupOptions,
}: Props) => {
  const config = getConfig<ProposalItemQuantityRuleConfig>(approvalRule);

  const getValue = () => {
    return isNumber(config?.quantity) ? config.quantity : DEFAULT_VALUE;
  };

  const [value, setValue] = useState<number>(getValue());
  const [showSelectError, setShowSelectError] = useState<boolean>(false);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const { data: product, isLoading: isProductLoading } = useLatestProduct(
    config && 'rootProductId' in config ? config.rootProductId : ''
  );

  const updateRule = (newValue: number) => {
    if (!approvalRule.config) {
      throw new Error('ApprovalRule.config is required');
    }

    return {
      id: approvalRule.id,
      name: approvalRule.name,
      config: {
        ruleType: approvalRule.config.ruleType,
        proposalType,
        quantity: newValue,
        rootProductId: product?.rootId,
      },
    };
  };

  const handleValueChange = (newValue: number | undefined = 0) => {
    setValue(newValue);
  };

  const handleSave = (newValue: number) => {
    onRuleChange(approvalRule.id, updateRule(newValue));
  };

  const onGroupsChange = (groupIds: string[]) => {
    setShowSelectError(groupIds.length === 0);

    const payload = {
      ...updateRule(value),
      approvalGroupIds: groupIds,
    };

    onRuleChange(approvalRule.id, payload);
  };

  const handleSaveIfValid = () => {
    if (value && value > 0) {
      handleSave(value);
    }
  };

  if (isProductLoading) {
    return null;
  }

  return (
    <div className={styles.ruleRow}>
      <div className={styles.name}>
        <Button
          className={styles.removeIcon}
          onClick={() => setIsDeleteConfirmationOpen(true)}
          primaryIcon={Icon.CloseCircle}
          type="secondary"
        />

        <div className={styles.flex}>
          <ToolTip title={product?.name}>
            <div className={styles.ruleName}>{product?.name}</div>
          </ToolTip>
          <div className={styles.grey}>&gt;</div>
          <div className={styles.conditionLabel}>Quantity</div>
        </div>
      </div>

      <div className={styles.condition}>exceeds</div>

      <div className={styles.input}>
        <NumberInput
          isDisabled={!approvalRule}
          onBlur={handleSaveIfValid}
          onChange={handleValueChange}
          value={value}
        />
      </div>

      <div className={styles.groups}>
        {allGroupOptions.length > 0 && (
          <Select
            className={styles.groupWidth}
            disabled={!approvalRule}
            filterOption={searchFilterOption}
            mode="multiple"
            onChange={onGroupsChange}
            options={allGroupOptions}
            placeholder="Select approval group"
            status={showSelectError ? 'error' : ''}
            value={approvalRule.approvalGroupIds?.map((id) => id)}
          />
        )}
      </div>

      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => onRuleChange(approvalRule.id, null)}
        typeName="condition"
      />
    </div>
  );
};
