import React from 'react';
import ProposalSection from '../ProposalSection/ProposalSection';
import { SETTINGS_SIGNING } from '../../../../core-utils/routes';
import { useNavigate } from 'react-router-dom';

interface Props {
  isDisabled: boolean;
}

const ConfigureDocuSign: React.FC<Props> = ({ isDisabled }: Props) => {
  const navigate = useNavigate();

  return (
    <ProposalSection
      isDisabled={isDisabled}
      section="Configure DocuSign"
      title="Configure DocuSign"
      onOpen={() => navigate(SETTINGS_SIGNING)}
      dataTestId="configure-docusign-section"
    />
  );
};

export default ConfigureDocuSign;
