import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Icon, PopOutMenu, PopOutMenuOption, useToast } from 'common';
import { apiClient } from '../../../../services/httpClients/app';

interface Props {
  productId: string;
}

const ProductOptions: React.FC<Props> = ({ productId }) => {
  const showToast = useToast();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: async () => await apiClient.copyProduct(productId, {}),
    onSuccess: (response) => {
      showToast.success('Product copied');
      navigate(`/products/${response.data.id}`);
    },
  });

  return (
    <PopOutMenu key={productId}>
      <PopOutMenuOption
        icon={Icon.Copy}
        isDisabled={isPending}
        onClick={mutate}
        title="Make a copy"
      />
    </PopOutMenu>
  );
};

export default ProductOptions;
