// All the routes of the application go here

/*
  General routes
*/
export const APP = '/';
export const MAINTENANCE = '/maintenance';

/*
  Auth routes
*/
export const LOGIN_ROUTE = '/login';
export const ORG_SELECTION_ROUTE = '/org-selection';

/*
  Root-level nagivation routes

  These are routes accessed via the primary nav menu items for each resource
  */
export const USER = '/user';
export const REPORTS = '/reports';
export const BILLING = '/billing';
export const REFUNDS = '/refunds';
export const SETTINGS = '/settings';
export const PAYMENTS = '/payments';
export const INVOICES = '/invoices';
export const PROPOSALS = '/proposals';
export const CUSTOMERS = '/customers';
export const SUBSCRIPTIONS = '/subscriptions';
export const ALL_TRANSACTIONS = '/transactions';
export const HUBSPOT_DEALS = '/external/hubspot/deals';
export const CLOSE_OPPORTUNITIES = '/close/opportunities';
export const HELP_CENTER_URL = 'https://help.getcacheflow.com';
export const SALESFORCE_OPPORTUNITIES = '/external/salesforce/opportunities';

/*
  Sub-root level nagivation routes

  These are single resource routes based on a root level route
*/
export const SINGLE_REFUND = '/refunds/:id';
export const SINGLE_PAYMENT = '/payments/:id';
export const SINGLE_INVOICE = '/invoices/:id';
export const SINGLE_CUSTOMER = '/customers/:id';
export const PROPOSAL_EDITOR = '/proposals/:id';
export const SINGLE_SUBSCRIPTION = '/subscriptions/:id';

/*
  Setting page routes

  These routes are nested under the Account nav item
*/
export const SETTINGS_API = '/settings/api';
export const SETTINGS_CLOSE = '/settings/close';
export const SETTINGS_SLACK = '/settings/slack';
export const SETTINGS_USERS = '/settings/users';
export const SETTINGS_STRIPE = '/settings/stripe';
export const SETTINGS_WARMLY = '/settings/warmly';
export const SETTINGS_ZAPIER = '/settings/zapier';
export const SETTINGS_COMPANY = '/settings/company';
export const SETTINGS_AVALARA = '/settings/avalara';
export const SETTINGS_HUBSPOT = '/settings/hubspot';
export const SETTINGS_SIGNING = '/settings/signing';
export const SETTINGS_CURRENCY = '/settings/currency';
export const SETTINGS_TAXATION = '/settings/taxation';
export const SETTINGS_APPROVALS = '/settings/approvals';
export const SETTINGS_PROPOSALS = '/settings/proposals';
export const SETTINGS_QUICKBOOKS = '/settings/quickbooks';
export const SETTINGS_SALESFORCE = '/settings/salesforce';
export const SETTINGS_DISCOUNTING = '/settings/discounting';
export const SETTINGS_SUBSCRIPTIONS = '/settings/subscriptions';
export const SETTINGS_BANK_ACCOUNTS = '/settings/bank-accounts';
export const SETTINGS_AUTO_RENEWALS = '/settings/auto-renewals';
export const SETTINGS_PAYMENT_TERMS = '/settings/payment-terms';
export const SETTINGS_PURCHASE_ORDER = '/settings/purchase-order';
export const SETTINGS_PAYMENT_METHODS = '/settings/payment-methods';
export const SETTINGS_BILLING_PERIODS = '/settings/billing-periods';
export const SETTINGS_INVOICE_SETTINGS = '/settings/invoice-settings';
export const INTEGRATIONS_AND_CONNECTORS = '/integrations-and-connectors';
export const SETTINGS_ADDRESS_VERIFICATION = '/settings/address-verification';
export const SETTINGS_LOCATION_CODE_MAPPING =
  '/settings/location-code-mapping-settings';

/*
  Sales setup page routes

  These routes are nested under the Sales setup dropdown nav menu
*/
export const PRODUCTS = '/products';
export const ATTACHMENTS = '/attachments';
export const SINGLE_PRODUCT = '/products/:id';

/*
  User sub page routes

  These routes are nested under the User nav item
*/
export const USER_PROFILE = '/user/profile';
export const USER_NOTIFICATIONS = '/user/notifications';

/*
  External integration callback routes
*/
export const DOCUSIGN_CALLBACK = '/docusign/callback';
export const HUBSPOT_DEALS_CALLBACK = '/hubspot/deals';
export const SLACK_CALLBACK_USER = '/slack/callback/user';
export const SLACK_CALLBACK_WORKSPACE = '/slack/callback/workspace';
export const SALESFORCE_OPPORTUNITIES_CALLBACK = '/salesforce/opportunities';
export const SETTINGS_AUTH_CALLBACK = '/settings/auth/callback/:connectorType';

/*
  Misc routes - possibly unused

  publicly-available page linked to from hubspot app store
*/
export const HUBSPOT_SIGNUP = '/hubspot/signup';

/*
  Playwright page routes

  This is a helper page to modify launch darkly flags, kick off events, etc during a playwright test
*/
export const PLAYWRIGHT_TRIGGERS = '/playwright-triggers';

/*
  System routes

  These are protected super-admin accessible routes for troubleshooting and monitoring
*/
export const SYSTEM_ROUTE = '/system';
export const STATUS = '/system/status';
export const EMAILS_SENT = '/system/emails';
export const SYSTEM_TODAY = '/system/today';
export const SYNC_EVENTS = '/system/syncevents';
export const SYSTEM_LOGIN_ROUTE = '/system/login';
export const EVENT_STATUS = '/system/status/events';
export const SYSTEM_DASHBOARD_ROUTE = '/system/dashboard';
export const UPCOMING_TRANSFERS = '/system/upcoming-transfers';
export const QUICKBOOKS_RECONCILIATION = '/system/reconciliation';
export const SYSTEM_ORGANIZATION_ROUTE = '/system/organizations/:id';

export const isSystemRoute = () => {
  return window.location.pathname.startsWith('/system');
};

export const PATH_MAP: Record<string, string | undefined> = {
  invoice: INVOICES,
  proposal: PROPOSALS,
  customer: CUSTOMERS,
  product: PRODUCTS,
  contract: SUBSCRIPTIONS,
  transfer: PAYMENTS,
};
