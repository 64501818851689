import React, { useState } from 'react';
import { ConnectorInfo, Tabs } from 'common';
import InvoiceReconcile from '../Reconcile/InvoiceReconcile';
import PaymentReconcile from '../Reconcile/PaymentReconcile';

interface Props {
  connectorName: ConnectorInfo['source'];
}

const ConnectorReconciliation = ({ connectorName }: Props) => {
  const [activeTab, setActiveTab] = useState<string>('invoices');

  //  Customer
  //  Subscription - Hyperlink to the subscription.

  //  Type (Invoice or Payment)
  // link various sync events

  //  Object - Our invoice or payment number.  If invoicing flag is disabled or the object is a payment, no hyperlink.  If invoicing flag is enabled and the object is an invoice, hyperlink to the invoice.
  //  QBO Object - QBO invoice or payment number and hyperlink to QBO
  //  Status (Success or Error)
  //  Reason (If error)
  //  Resync - This should have a button in each row to click to trigger a resync.

  return (
    <div className="connector-settings-tab min-h-[1200px]">
      <div className="flex">
        <Tabs
          tabs={[
            { label: 'Invoices', key: 'invoices' },
            { label: 'Payments', key: 'payments' },
          ]}
          onChange={(num) => setActiveTab(num)}
          defaultActiveTab="invoices"
        />
      </div>
      {activeTab === 'invoices' ? (
        <InvoiceReconcile connectorName={connectorName} />
      ) : (
        <PaymentReconcile connectorName={connectorName} />
      )}
    </div>
  );
};

export default ConnectorReconciliation;
