import React from 'react';
import { ErrorFetching, Loading } from 'common';
import { useGetCurrentUser } from '../../services/users';
import UserForm from './UserForm';
import { useAuth } from '../../Auth';

const UserProfile: React.FC = () => {
  const auth = useAuth();
  const currentUserId = auth.user?.id;

  if (!currentUserId) {
    return <ErrorFetching />;
  }

  const { data, isLoading, isError } = useGetCurrentUser(currentUserId);

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !data) {
    return <ErrorFetching />;
  }

  return <UserForm userProfile={data} />;
};

export default UserProfile;
