import React, { SVGProps } from 'react';

const SalesforceLogoSVG: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 256.0 256.0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="salesforce-outer">
          <path d="M256,0 L256,256 L0,256 L0,0 L256,0 Z" />
        </clipPath>
        <clipPath id="salesforce-inner">
          <path d="M90.3543969,16.6413196 L90.408696,16.6955716 C98.8073035,7.92248176 110.888919,3.67554055 122.935375,5.26176245 C134.98183,6.84798436 145.549677,14.0773029 151.386615,24.724864 C157.34498,22.0468026 163.803513,20.6600709 170.33701,20.6559659 C196.183394,20.6559659 217.142857,41.8142364 217.142857,67.8551849 C217.142857,93.8961333 196.183394,115.054404 170.33701,115.054404 C167.236849,115.05787 164.144244,114.748878 161.106159,114.13212 C155.064854,124.948605 143.639105,131.65281 131.241639,131.655508 C125.866026,131.655508 120.816207,130.461965 116.25508,128.237634 C110.139048,142.62973 96.0125803,151.982257 80.3633576,152 C63.9922985,152.002831 49.3582102,141.800356 43.7114472,126.447319 C41.2670701,126.97761 38.7737789,127.250361 36.2724669,127.261098 C26.5998924,127.218062 17.3414188,123.333928 10.5375499,116.464731 C3.73368092,109.595534 -0.0571066837,100.305114 0,90.6410147 C0,77.0780207 7.33103252,65.25109 18.1365587,58.9578608 C15.8397689,53.6671594 14.6568709,47.9609394 14.6614146,42.1940003 C14.6219266,24.102601 26.1552674,8.008812 43.3097086,2.21776051 C60.4641498,-3.57329097 79.403582,2.23340116 90.3543969,16.6413196 Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#salesforce-outer)">
        <g transform="translate(19.428571428571388 51.5)">
          <g clipPath="url(#salesforce-inner)">
            <polygon
              points="-0.000635982681,0 217.142857,0 217.142857,152.000001 -0.000635982681,152.000001 -0.000635982681,0"
              stroke="none"
              fill="#00A1E0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SalesforceLogoSVG;
