import React, { useEffect, useRef, useState } from 'react';
import { InputRef, RefSelectProps, Select, Space } from 'antd';
import clsx from 'clsx';

import {
  CreateProposalApprovalRuleRequest,
  isArray,
  LinkButton,
  NumberInput,
  ProposalApprovalRuleConfig,
  ProposalSummary,
} from 'common';

import {
  GroupOption,
  RuleInfo,
  searchFilterOption,
} from '../rules/_approvalRulesConfig';

import styles from './ProductRuleBuilderStyles.module.scss';

type ConditionKey =
  | 'discount exceeds'
  | 'discount is between'
  | 'quantity exceeds';

type ConditionLabel = 'Discount' | 'Quantity';

type Condition = 'exceeds' | 'between';

const conditionLabelMap: Record<ConditionKey, ConditionLabel> = {
  'discount exceeds': 'Discount',
  'discount is between': 'Discount',
  'quantity exceeds': 'Quantity',
};

const conditionMap: Record<ConditionKey, Condition> = {
  'discount exceeds': 'exceeds',
  'discount is between': 'between',
  'quantity exceeds': 'exceeds',
};

interface Props {
  allGroupOptions: GroupOption[];
  createRule: (payload: CreateProposalApprovalRuleRequest) => void;
  onCancelAdd: () => void;
  proposalType: ProposalSummary['proposalType'];
  selectedRule: RuleInfo;
}

export const ProductBuilder = ({
  proposalType,
  createRule,
  selectedRule,
  onCancelAdd,
  allGroupOptions,
}: Props) => {
  const [condition, setCondition] = useState<ConditionKey | ''>('');
  const [value, setValue] = useState<number>(0);
  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(0);

  const ref = useRef<InputRef>(null);
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    ref.current?.focus({ cursor: 'all' });
  }, [condition]);

  const createPayload = (
    config: Partial<CreateProposalApprovalRuleRequest['config']>,
    groupId: string[]
  ) => {
    return {
      name: `${config?.ruleType!}-${proposalType}`,
      config: {
        ...config,
        proposalType,
        ruleType: config?.ruleType!,
        rootProductId: selectedRule.rootProductId,
      },
      approvalGroupIds: groupId,
    };
  };

  const handleSaveExceeds = (groupId: string[]) => {
    const config: Partial<CreateProposalApprovalRuleRequest['config']> = {
      ...(isDiscountExceedsRule && { discount: value }),
      ...(isQuantityExceedsRule && { quantity: value }),
      ruleType: isQuantityExceedsRule
        ? 'proposalItemQuantity'
        : 'proposalProductDiscountPercent',
    };

    createRule(createPayload(config, groupId));
  };

  const handleSaveBetween = (groupId: string[]) => {
    const config: Partial<CreateProposalApprovalRuleRequest['config']> = {
      discount: minValue,
      maxDiscount: maxValue,
      ruleType: selectedRule.name as ProposalApprovalRuleConfig['ruleType'],
    };

    createRule(createPayload(config, groupId));
  };

  const handleSaveIfValid = (groupId: string[]) => {
    if (!condition) return;

    if (isDiscountExceedsRule || isQuantityExceedsRule) {
      if (value < 0) return;

      handleSaveExceeds(groupId);
    }

    if (isDiscountBetweenRule) {
      if (minValue < 0 || maxValue <= 0 || minValue >= maxValue) return;

      handleSaveBetween(groupId);
    }
  };

  const handleConditionChange = (conditionValue: ConditionKey) => {
    setCondition(conditionValue);
  };

  const handlePercentChange = (newValue: number | undefined = 0) => {
    const convertedValued = newValue * 100;
    if (newValue !== convertedValued) {
      setValue(convertedValued);
    }
  };

  const handleQuantityChange = (newValue: number | undefined = 0) => {
    if (newValue !== value) {
      setValue(newValue);
    }
  };

  const handleMinBetweenChange = (newValue: number | undefined = 0) => {
    const convertedValued = newValue * 100;
    if (newValue !== convertedValued) {
      setMinValue(convertedValued);
    }
  };

  const handleMaxBetweenChange = (newValue: number | undefined = 0) => {
    const convertedValued = newValue * 100;
    if (newValue !== convertedValued) {
      setMaxValue(convertedValued);
    }
  };

  const handleEnter = () => selectRef.current?.focus();

  const selectableRuleOptions = isArray(selectedRule.conditionOperator)
    ? selectedRule.conditionOperator.map((selectedCondition) => {
        return {
          value: selectedCondition,
          label: selectedCondition,
        };
      })
    : [];

  const containerClass = clsx(
    styles.conditionsContainer,
    !condition && styles.noWidth
  );

  const isDiscountExceedsRule = condition === 'discount exceeds';
  const isQuantityExceedsRule = condition === 'quantity exceeds';
  const isDiscountBetweenRule = condition === 'discount is between';

  const isExceedsTypeRule = isDiscountExceedsRule || isQuantityExceedsRule;

  const showExceedsCancel = isExceedsTypeRule && !value;
  const showBetweenCancel = isDiscountBetweenRule && (!minValue || !maxValue);
  const showGroupSelect =
    (isExceedsTypeRule && (!!value || value === 0)) ||
    (isDiscountBetweenRule && (!!minValue || minValue === 0) && !!maxValue);

  return (
    <div
      className={clsx(
        styles.ruleRow,
        showGroupSelect && styles.showingGroupsSelect
      )}
    >
      <div className={containerClass}>
        {condition ? (
          <div className={styles.flex}>
            <div className={styles.productName}>
              {selectedRule.selectedProductName}
            </div>

            <div className={styles.grey}>&gt;</div>

            <div className={styles.conditionLabel}>
              {conditionLabelMap[condition]}
            </div>
          </div>
        ) : (
          <div className={styles.flex}>
            <div>
              {selectedRule.selectedProductName}
              <span className={styles.grey}>&gt;</span>
            </div>

            <div>
              <Select
                autoFocus
                showSearch
                placeholder="Select condition"
                optionFilterProp="children"
                onChange={handleConditionChange}
                filterOption
                options={selectableRuleOptions}
                className={styles.conditionSelect}
                popupMatchSelectWidth={false}
                popupClassName="product-condition-dropdown-popup"
                data-testid="product-condition-dropdown"
              />
            </div>
          </div>
        )}
      </div>

      {condition && (
        <div className={styles.condition}>{conditionMap[condition]}</div>
      )}

      {isDiscountExceedsRule && (
        <div className={styles.input}>
          <NumberInput
            ref={ref}
            defaultValue={0}
            value={value / 100}
            onEnter={handleEnter}
            onChange={handlePercentChange}
            suffix="%"
            max={100}
            dataTestId="product-exceeds-value"
          />
        </div>
      )}

      {isQuantityExceedsRule && (
        <div className={styles.input}>
          <NumberInput
            ref={ref}
            value={value}
            onEnter={handleEnter}
            onChange={handleQuantityChange}
          />
        </div>
      )}

      {isDiscountBetweenRule && (
        <div className={styles.input}>
          <Space.Compact block>
            <NumberInput
              ref={ref}
              suffix="%"
              onChange={handleMinBetweenChange}
              onEnter={handleEnter}
              min={0}
            />
            <span className={styles.betweenDash}>{' - '}</span>
            <NumberInput
              suffix="%"
              onChange={handleMaxBetweenChange}
              onEnter={handleEnter}
              max={100}
            />
          </Space.Compact>
        </div>
      )}

      {showExceedsCancel ||
        (showBetweenCancel && (
          <LinkButton className={styles.cancel} onClick={onCancelAdd}>
            cancel
          </LinkButton>
        ))}

      {showGroupSelect && (
        <div className={styles.groups}>
          {allGroupOptions.length > 0 && (
            <Select
              ref={selectRef}
              onChange={handleSaveIfValid}
              value={[]}
              options={allGroupOptions}
              mode="multiple"
              filterOption={searchFilterOption}
              className={styles.groupWidth}
              placeholder="Select approval group"
              popupMatchSelectWidth={false}
            />
          )}
        </div>
      )}
    </div>
  );
};
