import React from 'react';
import { formatDateOrDefault, formatTimeOrDefault } from 'common';

interface Props {
  date?: string | Date;
  showTime?: boolean;
}

const DateTimeCell: React.FC<Props> = ({ date, showTime = true }) => {
  return (
    <div className={showTime ? 'text-xs' : undefined}>
      {formatDateOrDefault(date)}

      {showTime && (
        <div className="text-slate whitespace-normal">
          {formatTimeOrDefault(date)}
        </div>
      )}
    </div>
  );
};

export default DateTimeCell;
