import React, { useState } from 'react';
import {
  ConfirmModal,
  FormField,
  Icon,
  isNumber,
  NumberInput,
  useToast,
  useTranslation,
} from 'common';
import { useUpdateIssueDates } from 'app/src/services/contract';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  contractId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const UpdateIssueDatesModal = ({
  contractId,
  isOpen,
  onClose,
}: Props) => {
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const showToast = useToast();
  const [gracePeriod, setGracePeriod] = useState<number | undefined>();

  const handleUpdateIssueDatesError = () => {
    showToast.error('Update issue dates failed');
  };

  const handleUpdateIssueDatesSuccess = () => {
    showToast.info('Update issue dates succeeded');
  };

  const { mutate: doUpdateIssueDates } = useUpdateIssueDates(
    contractId,
    handleUpdateIssueDatesSuccess,
    handleUpdateIssueDatesError,
    queryClient
  );

  return (
    <ConfirmModal
      confirmText="Update Billing Schedules"
      isDanger
      isDisabled={!isNumber(gracePeriod)}
      isOpen={isOpen}
      onClose={() => {
        setGracePeriod(undefined);
        onClose();
      }}
      onConfirm={() => {
        doUpdateIssueDates({ gracePeriod });
        onClose();
      }}
    >
      <div className="p-4 flex flex-col gap-2 items-center justify-center">
        <Icon.Alert className="text-red" height={100} width={100} />
        <div>
          {tk(
            'Are you sure you want to update issue dates for this subscription?'
          )}
        </div>
        <div>
          {tk(
            'This will update billing schedule issue dates where there is no invoice yet, invoicing in advance is not active, and external invoicing is not active.'
          )}
        </div>
        <div style={{ width: 200 }}>
          <FormField label="Grace Period">
            <NumberInput
              dataTestId="grace-period-value"
              min={0}
              onChange={setGracePeriod}
              type="number"
              value={gracePeriod}
            />
          </FormField>
        </div>
      </div>
    </ConfirmModal>
  );
};
