import React from 'react';
import {
  AttachmentConfig,
  Cell,
  Icon,
  ProposalAttachment,
  SimpleTable,
} from 'common';
import { prepareExternalLink } from '../../../../core-utils/helperFunctions/link';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

interface Props {
  attachments: ProposalAttachment[];
  isDisabled?: boolean;
  onClickAttachment: (attachment: AttachmentConfig) => void;
  onRemoveAttachment: (attachmentConfig: AttachmentConfig) => void;
  proposalIsInDraft: boolean;
}

function AddedAttachments({
  attachments,
  onRemoveAttachment,
  proposalIsInDraft,
  onClickAttachment,
  isDisabled = false,
}: Props): React.ReactElement {
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  const cells: Cell<ProposalAttachment>[] = [
    {
      key: 'fileTypeIcon',
      headerLabel: '',
      width: '70px',
      renderCell: (data: ProposalAttachment) => {
        const fileExtension =
          data.documentVersion?.fileName?.match(/\.[0-9a-z]+$/i)?.[0];

        if (data.type === 'document') {
          return (
            <div className="flex items-center justify-center">
              <div className="file-type-tag">
                {fileExtension?.substring(1) || 'file'}
              </div>
            </div>
          );
        } else
          return (
            <div className="flex items-center justify-center">
              <Icon.Link />
            </div>
          );
      },
    },
    {
      key: 'name',
      headerLabel: 'Name',
      renderCell: (data: ProposalAttachment) => (
        <div>
          <div className="attachment-name">{data.name}</div>
        </div>
      ),
    },
    {
      key: 'signing',
      headerLabel: 'In signing',
      align: 'center',
      renderCell: (data: AttachmentConfig) => (
        <div className="flex items-center justify-center text-green">
          {data.inEnvelope ? <Icon.Checkmark /> : null}
        </div>
      ),
    },
    {
      key: 'preview',
      width: '70px',
      headerLabel: 'Preview',
      renderCell: (data: ProposalAttachment) => (
        <a
          href={
            data.type === 'document'
              ? data.documentVersion?.url
              : prepareExternalLink(data.linkUrl)
          }
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center cursor-pointer text-slate-lighter hover:text-blue"
          onClick={(e) => e.stopPropagation()}
        >
          <Icon.Preview name="Preview" />
        </a>
      ),
    },
  ];

  if (proposalIsInDraft && canUpdate && !isDisabled) {
    cells.push({
      key: 'delete',
      width: '70px',
      headerLabel: 'Remove',
      renderCell: (data: ProposalAttachment) => {
        return (
          <button
            type="button"
            className="flex items-center justify-center cursor-pointer text-slate-lighter hover:text-red"
            onClick={(e) => {
              e.stopPropagation();
              onRemoveAttachment(data);
            }}
          >
            <Icon.Close name="Delete" />
          </button>
        );
      },
    });
  }

  return (
    <SimpleTable
      cells={cells}
      rows={attachments}
      disableSearch
      disableShadow
      zeroStateMessage="No attachments added"
      onRowClick={isDisabled ? undefined : onClickAttachment}
    />
  );
}

export default AddedAttachments;
