import { useState, useCallback, useEffect } from 'react';

import { ActivityEvent } from 'common';

import {
  useInvoiceActivity,
  useRefundActivity,
  useTransferActivity,
} from 'app/src/services/transfer';
import { ActivityResourceTypes } from './activityDrawerUtils';

export enum ResourceType {
  Refund = 'refund',
  Transfer = 'transfer',
  Invoice = 'invoice',
}

type ActivityDrawerType = {
  isActivityDrawerOpen: false | true;
  events: ActivityEvent[] | null;
  isLoading: boolean;
  error: string | null;
};

const activityHooksMap = {
  [ResourceType.Refund]: useRefundActivity,
  [ResourceType.Transfer]: useTransferActivity,
  [ResourceType.Invoice]: useInvoiceActivity,
};

export const useActivityDrawer = (
  resourceType: ActivityResourceTypes,
  resourceId: string
) => {
  const [state, setState] = useState<ActivityDrawerType>({
    isActivityDrawerOpen: false,
    events: null,
    isLoading: false,
    error: null,
  });

  const activityHook = activityHooksMap[resourceType];

  const {
    isLoading,
    error,
    isError,
    data: activity,
    refetch,
  } = activityHook(resourceId);

  const openActivityDrawer = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isActivityDrawerOpen: true,
      isLoading: true,
      error: null,
    }));
  }, [refetch]);

  const closeActivityDrawer = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isActivityDrawerOpen: false,
      events: [],
    }));
  }, []);

  useEffect(() => {
    if (!isLoading && state.isActivityDrawerOpen) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        events: activity ?? null,
        error: isError ? error.message : null,
      }));
    }
  }, [isLoading, activity, isError, state.isActivityDrawerOpen]);

  return {
    activity: state.events,
    openActivityDrawer,
    closeActivityDrawer,
    isActivityDrawerOpen: state.isActivityDrawerOpen,
    isActivityDrawerLoading: state.isLoading,
    ActivityDrawerError: state.error,
  };
};
