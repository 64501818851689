interface AddressDraft {
  streetAddress?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}

const addMissingAddressFields = (address: AddressDraft) => {
  return {
    city: '',
    streetAddress: '',
    postalCode: '',
    country: '',
    ...address,
  };
};

export default addMissingAddressFields;
