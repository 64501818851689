import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSendoso = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 97 23"
    role="img"
    name="Sendoso"
    {...props}
  >
    <mask
      id="Sendoso_svg__a"
      width={97}
      height={23}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
    >
      <path fill="#fff" d="M97 .76H.34v21.48H97z" />
    </mask>
    <g fill="currentColor" mask="url(#Sendoso_svg__a)">
      <path d="m23.661 13.74-3.75-.013c.397-1.01 1.19-1.822 2.424-1.818.863.004 1.355.597 1.353 1.386-.001.15-.027.445-.027.445m-1.24-5.039c-4.196-.015-7.34 3.305-7.356 7.723-.011 2.987 2.126 5.56 6.47 5.575 2.887.01 5.138-1.634 5.138-1.634l-1.074-3.188s-1.93 1.277-3.78 1.27c-1.58-.006-2.49-.848-2.485-2.279l8.02.028a8.9 8.9 0 0 0 .478-2.49c.01-2.937-1.809-4.992-5.411-5.005M38.078 8.758c-1.752-.007-3.533 1.05-4.252 2.181h-.05q.196-.62.326-1.257l.125-.642-4.12-.014-2.513 12.7 4.269.015 1.106-5.623c.352-1.849 1.789-3.497 3.467-3.491.765.002 1.134.324 1.13 1.089a5 5 0 0 1-.075.74l-1.458 7.299 4.27.016 1.583-7.99q.151-.695.178-1.406c.009-2.64-1.79-3.61-3.986-3.617M48.829 18.568c-1.234-.004-1.996-.87-1.99-2.252.008-2.492 1.74-3.917 3.196-3.913 1.383.006 1.97 1.043 1.966 2.253-.008 2.394-1.815 3.917-3.173 3.912m5.208-14.392-1.03 5.202c-.05.248-.076.569-.076.569h-.048c-.714-.866-2.144-1.144-3.205-1.148-3.776-.012-7.142 3.11-7.16 7.823-.011 3.307 1.807 5.46 4.916 5.47a4.57 4.57 0 0 0 3.66-1.837h.048s-.075.32-.15.715l-.176.839 3.997.014 3.493-17.63zM63.87 18.597c-1.358-.005-2.341-.871-2.336-2.352.008-2.32 1.888-3.843 3.567-3.838 1.333.006 2.316.922 2.31 2.428-.007 2.319-1.838 3.768-3.541 3.762m1.293-9.742c-3.974-.015-7.933 3.105-7.948 7.572-.012 3.48 2.743 5.71 6.568 5.723 3.95.014 7.933-3.08 7.948-7.572.012-3.454-2.744-5.71-6.568-5.723M76.695 13.016c.001-.469.767-.663 1.384-.662 1.9.008 2.958.925 2.958.925l1.837-2.906S81.15 8.911 78.09 8.9c-2.714-.009-5.335 1.634-5.344 4.102-.013 3.752 5.02 3.966 5.016 5.101-.002.445-.717.64-1.384.637a5.3 5.3 0 0 1-3.204-1.146l-2.132 2.731a8.9 8.9 0 0 0 5.323 1.87c3.184.011 5.361-1.61 5.37-4.078.014-3.825-5.043-4.113-5.04-5.101M89.139 18.687c-1.358-.006-2.342-.872-2.337-2.352.008-2.32 1.89-3.844 3.568-3.838 1.333.005 2.316.92 2.311 2.427-.008 2.32-1.84 3.768-3.542 3.762m1.293-9.743c-3.974-.014-7.933 3.105-7.949 7.572-.012 3.48 2.745 5.71 6.569 5.723 3.949.014 7.933-3.08 7.948-7.572.013-3.454-2.743-5.709-6.568-5.723" />
      <path d="M21.82 5.485 16.702.76l-.496 2.524-6.849-.024C6.024 3.25 2.474 5.28 2.461 9.065c-.01 3.217 2.657 4.642 4.603 5.682 1.087.581 2.025 1.082 2.023 1.647-.005 1.31-1.82 1.318-1.898 1.317l-5.907-.02-.941 4.525 6.757.024c3.854.013 6.884-2.547 6.895-5.83.012-3.243-2.653-4.628-4.597-5.64-1.117-.579-2.08-1.08-2.077-1.689.003-.983 1.313-1.295 1.948-1.294l6.046.022-.483 2.45z" />
    </g>
  </svg>
);
export default SvgSendoso;
