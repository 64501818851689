import React, { useEffect, useRef, useState } from 'react';
import { RefSelectProps, Select } from 'antd';
import clsx from 'clsx';

import {
  CreateProposalApprovalRuleRequest,
  InputRef,
  isArray,
  LinkButton,
  Money,
  MoneyInput,
  ProposalSummary,
} from 'common';

import {
  GroupOption,
  RuleInfo,
  searchFilterOption,
} from '../rules/_approvalRulesConfig';

import styles from './ProposalRuleBuilderStyles.module.scss';

type ConditionKey = 'decreases' | 'exceeds';
type ConditionLabel = 'decreases' | 'exceeds';

const conditionLabelMap: Record<ConditionKey, ConditionLabel> = {
  exceeds: 'exceeds',
  decreases: 'decreases',
};

interface Props {
  allGroupOptions: GroupOption[];
  createRule: (payload: CreateProposalApprovalRuleRequest) => void;
  onCancelAdd: () => void;
  proposalType: ProposalSummary['proposalType'];
  selectedRule: RuleInfo;
}

export const ProposalChangeTCVBuilder = ({
  proposalType,
  createRule,
  selectedRule,
  onCancelAdd,
  allGroupOptions,
}: Props) => {
  const [condition, setCondition] = useState<ConditionKey | ''>('');
  const [value, setValue] = useState<Money>({ amount: 0 });

  const ref = useRef<InputRef>(null);
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    ref.current?.focus(isTcvExceedsRule ? { cursor: 'all' } : undefined);
  }, [condition]);

  const createPayload = (
    config: Partial<CreateProposalApprovalRuleRequest['config']>,
    groupId: string[]
  ) => {
    return {
      name: `${config?.ruleType!}-${proposalType}`,
      config: {
        ...config,
        proposalType,
        ruleType: config?.ruleType!,
      },
      approvalGroupIds: groupId,
    };
  };

  const handleSaveIfValid = (groupId: string[]) => {
    if (!condition || groupId.length === 0) return;
    if (isTcvExceedsRule && value.amount === 0) return;
    // No checks for isTcvDecreasesRule

    handleSave(groupId);
  };

  const handleSave = (groupId: string[]) => {
    const config: Partial<CreateProposalApprovalRuleRequest['config']> = {
      ...(isTcvExceedsRule && { [selectedRule.configKey]: value }),
      ruleType: isTcvDecreasesRule
        ? 'proposalAmountDecrease'
        : 'proposalAmount',
    };

    createRule(createPayload(config, groupId));
  };

  const handleConditionChange = (conditionValue: ConditionKey) => {
    setCondition(conditionValue);
  };

  const handleChange = (newValue: Money) => {
    setValue(newValue);
  };

  const handleEnter = () => selectRef.current?.focus();

  const selectableRuleOptions = isArray(selectedRule.conditionOperator)
    ? selectedRule.conditionOperator.map((selectedCondition) => {
        return {
          value: selectedCondition,
          label: selectedCondition,
        };
      })
    : [];

  const isTcvDecreasesRule = condition === 'decreases';
  const isTcvExceedsRule = condition === 'exceeds';

  const showCancel = !condition;

  const showGroupSelect =
    isTcvDecreasesRule ||
    (isTcvExceedsRule && (!!value.amount || value.amount === 0));

  const rowRuleClass = clsx(
    styles.ruleRow,
    !condition && styles.smallWidth,
    isTcvExceedsRule && !!value.amount && styles.showingGroupsSelect,
    isTcvDecreasesRule && styles.showingGroupsSelectDecrease
  );

  return (
    <div className={rowRuleClass}>
      <div>
        {condition ? (
          <div className={styles.condition}>{conditionLabelMap[condition]}</div>
        ) : (
          <div>
            <Select
              autoFocus
              className={styles.conditionSelect}
              filterOption
              onChange={handleConditionChange}
              optionFilterProp="children"
              options={selectableRuleOptions}
              placeholder="Select condition"
              popupMatchSelectWidth={false}
              showSearch
            />
          </div>
        )}
      </div>

      {isTcvExceedsRule && (
        <div className={styles.input}>
          <MoneyInput
            defaultCurrency="USD"
            onChange={handleChange}
            onEnter={handleEnter}
            ref={ref}
            value={value}
          />
        </div>
      )}

      {showCancel && (
        <LinkButton className={styles.cancel} onClick={onCancelAdd}>
          cancel
        </LinkButton>
      )}

      {showGroupSelect && (
        <div
          className={clsx(
            styles.groups,
            isTcvDecreasesRule && styles.showingGroupsDecrease
          )}
        >
          {allGroupOptions.length > 0 && (
            <Select
              className={styles.groupWidth}
              filterOption={searchFilterOption}
              mode="multiple"
              onChange={handleSaveIfValid}
              options={allGroupOptions}
              placeholder="Select approval group"
              popupMatchSelectWidth={false}
              ref={selectRef}
              showAction={['focus', 'click']}
              value={[]}
            />
          )}
        </div>
      )}
    </div>
  );
};
