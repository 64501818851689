import React from 'react';
import {
  CustomizeItemDateAction,
  CustomizeProductAction,
  DeleteItemAction,
  OneTimeProductRemovalTooltip,
  useIsItemRemovable,
} from './ItemActions';

export const ItemActionsMenu = () => {
  const { isRemoveItemTooltipVisible } = useIsItemRemovable();

  // If the item is a one-time product in a change proposal, it is locked in
  if (isRemoveItemTooltipVisible) {
    return <OneTimeProductRemovalTooltip />;
  }

  return (
    <>
      <DeleteItemAction />
      <CustomizeProductAction />
      <CustomizeItemDateAction />
    </>
  );
};
