import React, { useLayoutEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Button } from 'common';

interface Props {
  onSignatureChange: (rv: string) => void;
  signatureImageData?: string;
}

const Pad: React.FC<Props> = ({
  onSignatureChange,
  signatureImageData,
}: Props) => {
  let sigPad: any;

  useLayoutEffect(() => {
    if (signatureImageData) {
      sigPad.fromDataURL(signatureImageData);
    }
  }, []);

  const clear = () => {
    sigPad.clear();
    onSignatureChange('');
  };

  const onSignatureStrokeEnd = () => {
    onSignatureChange(sigPad.getTrimmedCanvas().toDataURL('image/png'));
  };

  return (
    <>
      <div className="mt-4 grid place-content-center">
        <div>
          <SignaturePad
            canvasProps={{
              className: 'cf-default-sig-pad border border-black border-solid',
              width: '500',
              height: '250',
            }}
            onEnd={onSignatureStrokeEnd}
            ref={(ref) => {
              sigPad = ref;
            }}
          />
        </div>
      </div>
      <div className="flex justify-end pt-2">
        <Button
          className="h-8 !py-1.5"
          type="secondary"
          label="Clear"
          onClick={clear}
        />
      </div>
    </>
  );
};

export default Pad;
