import React, { useState } from 'react';
import { SearchSelect, Unit, useTranslation } from 'common';
import { useUnits } from '../../../../services/api/products/unit';
import UnitForm from './UnitForm';

interface Props {
  dataTestId?: string;
  onClearSelected: () => void;
  onUnitSelect: (unit: Unit) => void;
  selectedUnitId?: string;
}

const UnitsSelect: React.FC<Props> = ({
  onUnitSelect,
  onClearSelected,
  selectedUnitId,
  dataTestId,
}) => {
  const { tk } = useTranslation();
  const [isAddingNewUnit, setIsAddingNewUnit] = useState<boolean>(false);
  const [startingNameOfNewUnit, setStartingNameOfNewUnit] = useState<
    string | undefined
  >(undefined);

  const { data: allUnits, isLoading, isError } = useUnits();

  if (isError) {
    // TODO: error component
    return <div>{tk('Could not load data')}</div>;
  }

  const selectedUnit = allUnits?.find((unit) => unit.id === selectedUnitId);
  const getAddNewPlaceHolder = (currentlyTyped: string) => {
    if (currentlyTyped) {
      return `${tk('Add')} ${currentlyTyped}`;
    }
    return tk('Add new');
  };

  const closeUnitForm = () => {
    setIsAddingNewUnit(false);
    setStartingNameOfNewUnit(undefined);
  };

  const handleCreateUnit = (created: Unit) => {
    closeUnitForm();
    onUnitSelect(created);
  };

  return (
    <>
      <SearchSelect<Unit>
        options={allUnits || []}
        displayField="name"
        isLoading={isLoading}
        value={selectedUnit}
        onChange={onUnitSelect}
        onClearSelected={onClearSelected}
        onAddNew={(currentlyTyped) => {
          setStartingNameOfNewUnit(currentlyTyped);
          setIsAddingNewUnit(true);
        }}
        addNewPlaceHolder={getAddNewPlaceHolder}
        dataTestId={dataTestId}
      />

      {isAddingNewUnit && (
        <UnitForm
          isOpen={isAddingNewUnit}
          onSaveSuccess={handleCreateUnit}
          onClose={closeUnitForm}
          singularUnitStarterName={startingNameOfNewUnit}
        />
      )}
    </>
  );
};

export default UnitsSelect;
