import { ComponentType, SVGProps } from 'react';
import { Flags, Icon, NavigationIcon, Profile } from 'common';
import {
  ALL_TRANSACTIONS,
  ATTACHMENTS,
  BILLING,
  CLOSE_OPPORTUNITIES,
  CUSTOMERS,
  EMAILS_SENT,
  EVENT_STATUS,
  HELP_CENTER_URL,
  HUBSPOT_DEALS,
  INVOICES,
  PAYMENTS,
  PRODUCTS,
  PROPOSALS,
  QUICKBOOKS_RECONCILIATION,
  REFUNDS,
  REPORTS,
  SALESFORCE_OPPORTUNITIES,
  SETTINGS,
  STATUS,
  SUBSCRIPTIONS,
  SYNC_EVENTS,
  SYSTEM_DASHBOARD_ROUTE,
  SYSTEM_TODAY,
  UPCOMING_TRANSFERS,
} from '../../../core-utils/routes';
import { FLAG_TYPES } from '../../../core-utils/helperFunctions/userServiceHelper';

interface NavItemBase {
  authKey?: (typeof FLAG_TYPES)[keyof typeof FLAG_TYPES];
  flag?: keyof Flags;
  hidden?: boolean;
  hideFlag?: keyof Flags;
  label: string;
  roles?: Profile['role'][];
}

export interface NavRoute extends NavItemBase {
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  route: string;
  isExternalLink?: boolean;
}

export interface NavSubMenu extends NavItemBase {
  navMenuItems: NavRoute[];
}

export type NavItem = NavRoute | NavSubMenu;

// NAV ITEMS

export const topContent: NavRoute[] | NavSubMenu[] = [
  {
    route: PROPOSALS,
    authKey: FLAG_TYPES.PROPOSAL,
    label: 'Proposals',
    icon: NavigationIcon.Proposals,
  },
  {
    route: SUBSCRIPTIONS,
    authKey: FLAG_TYPES.CONTRACT,
    label: 'Subscriptions',
    icon: NavigationIcon.Subscriptions,
  },
  {
    route: INVOICES,
    label: 'Invoices',
    flag: 'invoices',
    icon: NavigationIcon.Agreements,
  },
  {
    route: PAYMENTS,
    authKey: FLAG_TYPES.TRANSFER,
    flag: 'paymentsSideNav',
    label: 'Payments',
    icon: Icon.CurrencyUsd,
  },
  {
    route: REFUNDS,
    flag: 'refunds',
    label: 'Refunds',
    icon: NavigationIcon.Refund,
  },
  {
    route: BILLING,
    authKey: FLAG_TYPES.BILLING,
    label: 'Usage billing',
    icon: NavigationIcon.Billing,
    roles: ['admin'],
  },
  {
    route: ALL_TRANSACTIONS,
    authKey: FLAG_TYPES.TRANSFER,
    label: 'Transactions',
    icon: NavigationIcon.Transactions,
  },
  {
    route: CUSTOMERS,
    authKey: FLAG_TYPES.CUSTOMER,
    label: 'Customers',
    icon: NavigationIcon.Customers,
  },
  {
    route: REPORTS,
    label: 'Reports',
    icon: NavigationIcon.Dashboard,
    roles: ['admin'],
  },
];

export const salesforceNavItems: NavRoute[] = [
  {
    route: SALESFORCE_OPPORTUNITIES,
    authKey: FLAG_TYPES.PROPOSAL,
    flag: 'salesforceConnector',
    label: 'Opportunities',
    hidden: true,
    icon: NavigationIcon.SalesforceOpportunities,
  },
];

topContent.push(...salesforceNavItems);

export const hubspotNavItem: NavRoute = {
  route: HUBSPOT_DEALS,
  authKey: FLAG_TYPES.PROPOSAL,
  label: 'Deals',
  icon: NavigationIcon.Deal,
  flag: 'hubspotConnector',
  hidden: true,
};

topContent.push(hubspotNavItem);

export const closeNavItem: NavRoute = {
  route: CLOSE_OPPORTUNITIES,
  authKey: FLAG_TYPES.PROPOSAL,
  label: 'Close opportunities',
  icon: NavigationIcon.CloseOpportunity,
  flag: 'closeConnector',
  hidden: true,
};

topContent.push(closeNavItem);

export const setupContent: NavItem[] = [
  {
    label: 'Sales setup',
    roles: ['admin'],
    navMenuItems: [
      {
        route: PRODUCTS,
        authKey: FLAG_TYPES.PRODUCT,
        label: 'Products',
        icon: NavigationIcon.Products,
      },
      {
        route: ATTACHMENTS,
        authKey: FLAG_TYPES.ATTACHMENT_CONFIG,
        label: 'Attachments',
        icon: NavigationIcon.Agreements,
      },
    ],
  },
  {
    route: HELP_CENTER_URL,
    label: 'Help',
    icon: NavigationIcon.Help,
    isExternalLink: true,
  },
  {
    route: SETTINGS,
    roles: ['admin'],
    authKey: FLAG_TYPES.UNKNOWN,
    label: 'Account',
    icon: NavigationIcon.Account,
  },
];

// ADMIN NAV ITEMS

export const adminTopContent: NavItem[] = [
  {
    label: 'Organizations',
    icon: NavigationIcon.Dashboard,
    authKey: FLAG_TYPES.SYSTEM,
    route: SYSTEM_DASHBOARD_ROUTE,
  },
  {
    route: SYSTEM_TODAY,
    authKey: FLAG_TYPES.SYSTEM,
    label: 'Proposals today',
    icon: NavigationIcon.Proposals,
  },
];

export const adminBottomContent: NavItem[] = [
  {
    route: SYNC_EVENTS,
    authKey: FLAG_TYPES.SYNC_EVENTS,
    label: 'Sync events',
    icon: NavigationIcon.Products,
  },
  {
    route: QUICKBOOKS_RECONCILIATION,
    authKey: FLAG_TYPES.SYNC_EVENTS,
    label: 'Reconciliation',
    icon: NavigationIcon.Products,
  },
  {
    route: EVENT_STATUS,
    authKey: FLAG_TYPES.SYSTEM,
    label: 'Event status',
    icon: NavigationIcon.Account,
  },
  {
    route: UPCOMING_TRANSFERS,
    authKey: FLAG_TYPES.SYSTEM,
    label: 'Upcoming transfers',
    icon: NavigationIcon.Billing,
  },
  {
    route: EMAILS_SENT,
    authKey: FLAG_TYPES.SYSTEM,
    label: 'Emails',
    icon: Icon.Email,
  },
  {
    route: STATUS,
    authKey: FLAG_TYPES.SYSTEM,
    label: 'System status',
    icon: NavigationIcon.Account,
  },
];
