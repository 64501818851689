import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFlags, useTranslation } from 'common';
import {
  isSystemRoute,
  PROPOSALS,
  SYSTEM_DASHBOARD_ROUTE,
} from '../../../core-utils/routes';
import CacheflowLogo from '../../../assets/CacheflowLogo';
import {
  isAuthorized,
  isAuthorizedInRoles,
  useIsSuperAdmin,
} from '../../../core-utils/helperFunctions/userServiceHelper';
import ProfileNavItem from './ProfileNavItem';
import { NavItem, NavRoute, NavSubMenu } from './sideNavBar';
import NavRouteComponent from './NavRouteComponent';
import SubMenu from './SubMenu';
import { useAuth } from 'app/src/Auth';
import { useGetOrganization } from 'app/src/core-utils/helperFunctions/organizationService';

interface Props {
  setupContent: NavItem[];
  startRoute: string;
  topContent: NavItem[];
}

const SideNavBar: React.FC<Props> = ({
  startRoute,
  topContent,
  setupContent,
}: Props) => {
  const { tk } = useTranslation();
  const isSuperAdmin = useIsSuperAdmin();
  const systemRoute = isSystemRoute();
  const auth = useAuth();
  const flags = useFlags();

  const { data: organization, isLoading: isOrganizationLoading } =
    useGetOrganization(auth.organizationId!);

  // hook for changing the style of a tab in navbar once selected
  const [selectedRoute, setSelectedRoute] = useState(startRoute);

  const handleRouteChange = (selectedNavItem: NavRoute): void => {
    if (!selectedNavItem.isExternalLink) {
      setSelectedRoute(selectedNavItem.route);
    }
  };

  const getRoute = (selectedNavItem: NavRoute): string | undefined => {
    if (isSuperAdmin && selectedNavItem.route === SYSTEM_DASHBOARD_ROUTE) {
      return SYSTEM_DASHBOARD_ROUTE;
    } else if (selectedNavItem.route) {
      return selectedNavItem.route;
    }
    return undefined;
  };

  const isNavRoute = (value: NavItem): value is NavRoute => {
    return 'route' in value;
  };

  const isNavSubMenu = (value: NavItem): value is NavSubMenu => {
    return 'navMenuItems' in value;
  };

  const isEnabled = (item: NavItem): boolean =>
    [
      !item.roles || isAuthorizedInRoles(item.roles),
      !item.authKey || isAuthorized(item.authKey),
      !item.flag || flags[item.flag],
      !item.hideFlag || !flags[item.hideFlag],
    ].every(Boolean);

  const renderNavItems = (navItems: NavItem[], isNested = false) => {
    return navItems
      .filter((item) => isEnabled(item))
      .filter((item) => !item.hidden)
      .flatMap((item) => {
        if (isNavRoute(item)) {
          const route = getRoute(item);
          if (!route) {
            return null;
          }
          return (
            <NavRouteComponent
              key={route}
              navRoute={item}
              route={route}
              onRouteChange={handleRouteChange}
              selectedRoute={selectedRoute}
              isNested={isNested}
            />
          );
        } else if (isNavSubMenu(item)) {
          return (
            <SubMenu
              key={item.label}
              navSubMenu={item}
              selectedRoute={selectedRoute}
            >
              {renderNavItems(item.navMenuItems, true)}
            </SubMenu>
          );
        }

        return null as never;
      });
  };

  return (
    <div
      id="drawer-wrapper"
      className="w-[220px] h-full shrink-0 grow-0 font-medium"
    >
      <div
        id="drawer"
        className="fixed top-0 left-0 z-[998] w-[220px] h-full overflow-x-hidden overflow-y-auto flex flex-col grow shrink-0 basis-auto bg-white border-solid border-r border-gray-lines px-2"
      >
        <div className="-mb-4">
          {isSuperAdmin && (
            <div className="h-4 text-xs text-center text-white bg-red">
              {tk('Super admin')}
            </div>
          )}
          {!isOrganizationLoading &&
            organization?.status !== 'active' &&
            !systemRoute && (
              <div className="h-4 text-xs text-center text-white bg-red">
                {tk('Inactive organization')}
              </div>
            )}
        </div>

        <div className="w-full h-[72px] mb-2 flex items-center pt-6 justify-evenly">
          <Link to={PROPOSALS}>
            <CacheflowLogo width={108} data-testid="cacheflowicon" />
          </Link>
        </div>

        {renderNavItems(topContent)}
        <div className="mt-auto mb-0">
          {renderNavItems(setupContent)}
          <ProfileNavItem />
        </div>
      </div>
    </div>
  );
};
export default SideNavBar;
