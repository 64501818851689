import { useQueryClient } from '@tanstack/react-query';

import {
  AdvanceDateOptions,
  INVOICE_ADVANCE_DATE_OPTIONS,
} from 'app/src/core-utils/helperFunctions/types';

import { useFindOrgDefault } from 'app/src/services/orgDefaults';

import {
  useDeleteInvoiceConfig,
  usePatchInvoiceConfig,
  usePostInvoiceConfig,
} from 'app/src/services/proposal';
import clsx from 'clsx';

import {
  FormField,
  hasUsage,
  InfoToolTip,
  InvoiceConfig,
  InvoiceConfiguration,
  InvoiceConfigurationRequest,
  isDraftProposalStatus,
  NumberInput,
  Proposal,
  Select,
  ToggleSwitch,
  useDebounce,
  useFlags,
  useToast,
} from 'common';
import React, { useEffect, useState } from 'react';

import styles from './InvoiceInAdvance.module.scss';

interface Props {
  proposal: Proposal;
}

type PluckedInvoiceConfig = Pick<InvoiceConfig, 'invoiceInAdvanceDateOption'>;

type PluckedInvoiceConfiguration = Pick<
  InvoiceConfiguration,
  'invoiceInAdvanceDays'
>;

type InvoiceStateType = PluckedInvoiceConfig & PluckedInvoiceConfiguration;

export const InvoiceInAdvance = ({ proposal }: Props) => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const hasUsageProduct = hasUsage(proposal);
  const isDraftStatus = isDraftProposalStatus(proposal);

  const isInputsDisabled = hasUsageProduct || !isDraftStatus;

  const { invoiceInAdvance: invoiceInAdvanceFlag } = useFlags();

  const { data: configData } = useFindOrgDefault('invoiceConfig');

  const [formState, setFormState] = useState<InvoiceStateType>({
    invoiceInAdvanceDays: undefined,
    invoiceInAdvanceDateOption: undefined,
  });

  useEffect(() => {
    // If the proposal contains the invoice configuration, we will use
    // the proposals overridden values for our form instead of the org settings.
    if (
      proposal.invoiceConfiguration &&
      proposal.invoiceConfiguration.invoiceInAdvanceDate
    ) {
      const configValues = proposal.invoiceConfiguration;

      setFormState({
        invoiceInAdvanceDateOption: configValues.invoiceInAdvanceDate,
        invoiceInAdvanceDays: configValues.invoiceInAdvanceDays,
      });
      // If the proposal is yet to have an invoice configuration, we will
      // pull the values from the org setting defaults.
    } else if (configData) {
      const configValues = configData.configValue as InvoiceConfig;

      setFormState({
        invoiceInAdvanceDateOption: configValues.invoiceInAdvanceDateOption,
        invoiceInAdvanceDays: configValues.invoiceInAdvanceDays,
      });
    }
  }, [proposal, configData]);

  useEffect(() => {
    // When a useage product is added to the proposal
    // We need to remove our invoiceConfig and disable the section
    if (hasUsageProduct && proposal.invoiceConfiguration) {
      deleteInvoiceConfigMutation.mutate(proposal.invoiceConfiguration.id!);
    }
  }, [hasUsageProduct]);

  const handleError = () => {
    showToast.error('Updating invoice in advance failed');
  };

  const postInvoiceConfigMutation = usePostInvoiceConfig(
    proposal.id,
    () => {},
    handleError,
    queryClient
  );

  const patchInvoiceConfigMutation = usePatchInvoiceConfig(
    proposal.invoiceConfiguration?.id!,
    proposal.id,
    () => {},
    handleError,
    queryClient
  );

  const deleteInvoiceConfigMutation = useDeleteInvoiceConfig(
    proposal.id,
    () => {},
    handleError,
    queryClient
  );

  const { debouncedFunction: debouncedMutateInvoiceConfig } = useDebounce(
    (newState) => {
      patchInvoiceConfigMutation.mutate(
        newState as InvoiceConfigurationRequest
      );
    },
    1000
  );

  const isFormValid = (newState: InvoiceStateType) => {
    return (
      newState.invoiceInAdvanceDays && newState.invoiceInAdvanceDays > 0 && true
    );
  };

  const getPayload = (
    newState: InvoiceStateType
  ): InvoiceConfigurationRequest => {
    return {
      invoiceInAdvanceDate: newState.invoiceInAdvanceDateOption,
      invoiceInAdvanceDays: newState.invoiceInAdvanceDays,
      invoiceInAdvance: true,
    };
  };

  const handleChange = (field: string, value: string | number | undefined) => {
    const newState = {
      ...formState,
      [field]: value,
    };

    setFormState(newState);

    if (isFormValid(newState)) {
      debouncedMutateInvoiceConfig(newState);
    }
  };

  if (
    !invoiceInAdvanceFlag ||
    (configData?.configValue &&
      'invoiceInAdvanceEnabled' in configData.configValue &&
      !configData.configValue.invoiceInAdvanceEnabled)
  ) {
    return null;
  }

  return (
    <div
      className={clsx(
        styles.invoiceInAdvanceSection,
        hasUsageProduct && styles.showTooltip
      )}
    >
      <ToggleSwitch
        id="invoiceInAdvance"
        label="Invoice in advance"
        onChange={(newValue) => {
          const payload = getPayload(formState);

          newValue
            ? postInvoiceConfigMutation.mutate(payload)
            : deleteInvoiceConfigMutation.mutate(
                proposal.invoiceConfiguration?.id!
              );
        }}
        value={!!proposal.invoiceConfiguration}
        name="invoiceInAdvance"
        className={styles.bold}
        isDisabled={isInputsDisabled}
      />
      {hasUsageProduct && (
        <InfoToolTip title="Invoicing in advance cannot be enabled with usage products in the proposal." />
      )}

      {proposal.invoiceConfiguration && !proposal.renewalConfiguration && (
        <div className={styles.warning}>
          <div className={styles.small}>
            Warning: auto-renew is not enabled for this subscription.
          </div>
          <div className={styles.small}>
            Invoices cannot be sent in advance for future terms unless the
            subscription is set to auto-renew or is manually renewed.
          </div>
        </div>
      )}

      {proposal.invoiceConfiguration && (
        <div className={styles.invoiceInAdvance}>
          <FormField label="Days in advance">
            <NumberInput
              type="number"
              value={formState.invoiceInAdvanceDays}
              onChange={(newValue) =>
                handleChange('invoiceInAdvanceDays', newValue)
              }
              min={1}
              className={clsx(
                styles.input,
                !isFormValid(formState) && styles.inputError
              )}
              isDisabled={isInputsDisabled}
            />
          </FormField>

          <FormField label="Set invoice date to">
            <Select
              className={styles.input}
              onChange={(newValue: AdvanceDateOptions) => {
                handleChange('invoiceInAdvanceDate', newValue);
              }}
              value={formState.invoiceInAdvanceDateOption}
              options={INVOICE_ADVANCE_DATE_OPTIONS}
              isDisabled={isInputsDisabled}
            />
          </FormField>
        </div>
      )}
    </div>
  );
};
