import React from 'react';
import {
  Checkbox,
  PaymentMethod,
  PaymentSchedule,
  PillButton,
  ToolTip,
} from 'common';
import PaymentMethodName from '../PaymentMethods/PaymentMethodName';

interface Props {
  allPaymentMethods: PaymentMethod[] | undefined;
  billingPeriod: PaymentSchedule['payEvery'];
  isActive: boolean;
  isDefault: boolean;
  isLastDefault: boolean;
  name: string;
  onActiveToggle: () => void;
  onPaymentMethodToggle: (pm: PaymentMethod) => void;
  onToggleDefault: () => void;
  selectedPaymentMethods: PaymentMethod[] | undefined;
}

const BillingPeriod: React.FC<Props> = ({
  name,
  billingPeriod,
  isActive,
  isDefault,
  isLastDefault,
  allPaymentMethods,
  selectedPaymentMethods,
  onActiveToggle,
  onToggleDefault,
  onPaymentMethodToggle,
}) => {
  const selectedIds: string[] = (selectedPaymentMethods || []).map(
    (spm) => spm.id!
  );

  return (
    <>
      <div className="flex flex-row items-center justify-start content-center mb-4 h-6">
        <div className="mr-2">
          <ToolTip
            title={
              isDefault && isLastDefault
                ? 'Please select another default billing period before disabling.'
                : null
            }
          >
            <Checkbox
              value={isActive}
              label={name}
              name={name}
              onChange={onActiveToggle}
              isReadOnly={isLastDefault}
              dataTestId={`billing-period-toggle-${billingPeriod}`}
            />
          </ToolTip>
        </div>
        {isActive && (
          <PillButton
            active={isDefault}
            onClick={() => {
              onToggleDefault();
            }}
            dataTestId={
              isDefault
                ? `billing-period-default-${billingPeriod}`
                : `billing-period-set-default-${billingPeriod}`
            }
          >
            {isDefault ? 'Included by Default' : 'Set by default'}
          </PillButton>
        )}
      </div>
      <div className="mb-8 ml-8 p-6 pb-0 bg-slate-lightest">
        {allPaymentMethods &&
          allPaymentMethods.map((pm) => {
            const selected = selectedIds.includes(pm.id!) && pm.enabled;
            const label = (
              <>
                <PaymentMethodName method={pm} />
                {pm.enabled ? null : (
                  <span
                    className="ml-2 text-xs text-slate"
                    data-testid={`billing-period-${billingPeriod}-${pm.name}-disabled`}
                  >
                    <i>Disabled</i>
                  </span>
                )}
              </>
            );
            return (
              <Checkbox
                value={selected}
                className="pb-6"
                isDisabled={!pm.enabled || (selectedIds.length < 2 && selected)}
                key={name + pm.id}
                label={label}
                name={name + pm.id}
                onChange={() => onPaymentMethodToggle(pm)}
                dataTestId={`billing-period-${billingPeriod}-payment-toggle-${pm.name}`}
              />
            );
          })}
      </div>
    </>
  );
};

export default BillingPeriod;
