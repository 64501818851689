import React, { useRef } from 'react';
import { clsx } from 'clsx';
import {
  arraySafeAccess,
  Button,
  FormField,
  isNumber,
  LevelRequest,
  Nullable,
  NumberInput,
  ProductRequest,
  useTranslation,
} from 'common';
import styles from './PricingConfiguration.module.scss';

interface Props {
  isDisabled: boolean;
  productDraft: Nullable<ProductRequest>;
  setPricingLevelsAreValid: (val: boolean) => void;
  updateLevels: (levelsUpdate: LevelRequest[]) => void;
}

const ProductRangeInputs = ({
  productDraft,
  updateLevels,
  isDisabled,
  setPricingLevelsAreValid,
}: Props) => {
  const { tk } = useTranslation();
  const addLevelRef = useRef<null | HTMLDivElement>(null);

  const priceLevels = productDraft.levels ?? [];
  const priceLevelsSorted: LevelRequest[] = priceLevels.sort((a, b) => {
    return (a.start ?? 0) - (b.start ?? 0);
  });

  const getStart = (level: number): number => {
    return arraySafeAccess(priceLevelsSorted, level)?.start ?? 0;
  };

  const addNewLevel = () => {
    const newLevelSpread =
      priceLevelsSorted.length > 1
        ? getStart(priceLevelsSorted.length - 1) -
          getStart(priceLevelsSorted.length - 2)
        : 100;

    let startOfNewLevel = 0;
    if (priceLevelsSorted.length > 0) {
      startOfNewLevel = getStart(priceLevelsSorted.length - 1) + newLevelSpread;
    }
    updateLevels([
      ...priceLevelsSorted,
      {
        start: startOfNewLevel,
      },
    ]);

    addLevelRef.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  };
  const levelEnds: Array<number | undefined> = priceLevelsSorted.map(
    (_, index) => {
      if (index < priceLevelsSorted.length - 1) {
        return getStart(index + 1) - 1;
      }
      return undefined;
    }
  );

  const handleStartChange = (levelIndex: number, amount: number) => {
    if (levelIndex > 0 && amount < getStart(levelIndex - 1) + 1) {
      setPricingLevelsAreValid(false);
    } else {
      setPricingLevelsAreValid(true);
    }

    const shouldBumpNextLevelStart =
      levelIndex < priceLevelsSorted.length - 1 &&
      amount >= getStart(levelIndex + 1);
    updateLevels(
      priceLevelsSorted.map((level, index) => {
        if (shouldBumpNextLevelStart && index === levelIndex + 1) {
          return { ...level, start: amount + 1 };
        }
        return index === levelIndex
          ? {
              ...level,
              start: amount,
            }
          : level;
      })
    );
  };

  return (
    <div className={clsx(styles.rangeWrapper)}>
      {productDraft.levels?.map((level, index) => {
        return (
          <div
            key={level.start}
            className={clsx(index === 0 && styles.firstRow)}
          >
            <FormField
              className={styles.pricingTableFormField}
              label={tk('Range')}
              labelClassName={styles.labelClassName}
              showLabel={index === 0}
            >
              <NumberInput
                value={level.start}
                defaultValue={level.start}
                onBlur={(amount) => {
                  if (isNumber(amount)) {
                    handleStartChange(index, amount);
                  }
                }}
                selectAllContentOnFocus
                isDisabled={isDisabled || index === 0}
                suffix={
                  'to ' +
                  (levelEnds[index] ? String(levelEnds[index]) : '\u221E')
                }
                dataTestId={`level-start-${index}`}
              />
            </FormField>
          </div>
        );
      })}
      {!isDisabled && (
        <div ref={addLevelRef} className={styles.addLevelButton}>
          <Button
            type="link"
            onClick={addNewLevel}
            dataTestId="add-level"
            label={tk('Add')}
          />
        </div>
      )}
    </div>
  );
};

export default ProductRangeInputs;
