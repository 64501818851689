import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useCancelProposal } from '../../../../services/proposal';
import { PROPOSALS } from '../../../../core-utils/routes';

import { ConfirmModal, Icon, useToast } from 'common';
import ActionBarButton from '../../../../components/ActionBarButton';

interface Props {
  isDisabled?: boolean;
  proposalId: string;
}

const ProposalCancelButton: React.FC<Props> = ({ proposalId, isDisabled }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const showToast = useToast();

  const handleCancelError = () => {
    showToast.error('Proposal cancellation failed.');
  };

  const handleCancelSuccess = () => {
    showToast.info('Proposal cancelled.');
    navigate(PROPOSALS);
  };

  const { mutate: cancelProposal } = useCancelProposal(
    proposalId,
    handleCancelSuccess,
    handleCancelError,
    queryClient
  );
  return (
    <>
      <ActionBarButton
        icon={Icon.DocumentCancel}
        isDisabled={isDisabled}
        onClick={() => setShowCancelModal(true)}
        tooltip="Cancel"
      />

      <ConfirmModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={() => cancelProposal()}
      >
        <div>Are you sure you want to cancel this proposal?</div>
      </ConfirmModal>
    </>
  );
};

export default ProposalCancelButton;
