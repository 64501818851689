import React from 'react';
import handleAccountProfileName from '../../../core-utils/helperFunctions/profileName';
import {
  LOGIN_ROUTE,
  ORG_SELECTION_ROUTE,
  SYSTEM_DASHBOARD_ROUTE,
  SYSTEM_ROUTE,
  USER_PROFILE,
} from '../../../core-utils/routes';
import { LOGGED_OUT_STATE, useAuthState } from '../../../Auth';
import { log, MdiIcon } from 'common';
import {
  adminLogoutGet,
  logoutGet,
} from '../../../services/api/profile/logout';
import LogoutLoader from '../../../pages/Login/LogoutLoader/LogoutLoader';
import { Link, useNavigate } from 'react-router-dom';
import { canAccessSystemAdminRoutesWithContext } from '../../../core-utils/helperFunctions/userServiceHelper';
import {
  getPrevPath,
  getSearchParams,
} from '../../../services/routes/routeUtils';

const ProfileNavItem: React.FC = () => {
  const navigate = useNavigate();

  const handlePageRouting = (path: string) => {
    navigate(path);
  };

  const { isLoading, refetch } = logoutGet();
  const { isLoading: adminLoading } = adminLogoutGet();

  const { auth, setAuth } = useAuthState();

  const handleLogoutHandler = () => {
    if (canAccessSystemAdminRoutesWithContext(auth)) {
      if (auth.organizationName) {
        const resetContextState = {
          isAuthenticated: false,
          isSystemAdmin: true,
          organizationName: '',
          prevPath: undefined,
          systemToken: auth.systemToken,
          identity: undefined,
          role: 'anonymous' as const,
        };

        setAuth(resetContextState);
        handlePageRouting(SYSTEM_DASHBOARD_ROUTE);
      } else {
        setAuth(LOGGED_OUT_STATE);
        handlePageRouting(SYSTEM_ROUTE);
      }
    } else {
      if (auth.identityToken) {
        setAuth({
          ...auth,
          user: undefined,
          token: auth.identityToken,
          identity: undefined,
          prevPath: getPrevPath(location.pathname),
          searchSuffix: getSearchParams(location.search),
        });
        handlePageRouting(ORG_SELECTION_ROUTE);
        return;
      }

      refetch()
        .then(() => {
          log.debug('logged out success');
        })
        .catch((err) => {
          log.error(err);
        })
        .finally(() => {
          setAuth({
            ...LOGGED_OUT_STATE,
            prevPath: getPrevPath(location.pathname),
            searchSuffix: undefined,
          });
          handlePageRouting(LOGIN_ROUTE);
        });
    }
  };

  const { fullName, firstChar, lastNameFirstChar } = handleAccountProfileName(
    auth.user?.firstName,
    auth.user?.lastName
  );

  const orgName = auth.flowDomain;

  return (
    <div className="flex flex-row items-center w-full h-12 mb-2">
      <Link data-testid="user-profile" to={USER_PROFILE}>
        <div className="grid w-8 h-8 mx-2 text-white rounded-full flex-0 bg-black place-content-center">
          {firstChar}
          {lastNameFirstChar}
        </div>
      </Link>
      <div className="flex flex-col justify-center leading-tight">
        <div className="text-sm">{fullName}</div>
        <div className="-mt-1 text-xs">{orgName}</div>
      </div>
      <button
        type="button"
        className="grid w-8 h-8 ml-auto mr-0 flex-0 place-content-center"
        onClick={handleLogoutHandler}
      >
        <MdiIcon.Logout className="w-5 h-5" />
      </button>
      {isLoading && <LogoutLoader />}
      {adminLoading && <LogoutLoader />}
    </div>
  );
};

export default ProfileNavItem;
