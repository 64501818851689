import {
  getBillingPeriodLabelAdjective,
  isString,
  PaymentMethod,
  PaymentTerm,
} from 'common';

import { usePaymentTerms } from 'app/src/services/paymentTerms';
import { usePaymentMethods } from 'app/src/services/paymentMethods';

interface SelectOption<T> extends Record<string, any> {
  label: string;
  value: T;
}

interface MultiSelectRuleData {
  proposalBillingPeriods: PaymentMethod[];
  proposalPaymentTerms: PaymentTerm[];
}

export interface MultiSelectRuleOptions {
  proposalBillingPeriods: SelectOption<string>[];
  proposalPaymentTerms: SelectOption<string>[];
}

interface UseMultiSelectRuleOptionsReturn {
  multiSelectRuleOptions: MultiSelectRuleOptions;
}

type RuleTypeKey = keyof MultiSelectRuleData;

const multiSelectRuleDataMap: Record<
  RuleTypeKey,
  typeof usePaymentTerms | typeof usePaymentMethods
> = {
  proposalPaymentTerms: usePaymentTerms,
  proposalBillingPeriods: usePaymentMethods,
};

const multiSelectRuleDataTransformMap: Record<
  RuleTypeKey,
  (data: PaymentTerm[] | PaymentMethod[] | undefined) => SelectOption<string>[]
> = {
  proposalPaymentTerms: (
    paymentTerms: PaymentTerm[] | undefined
  ): SelectOption<string>[] => {
    return (paymentTerms || [])
      .map((p) => p.name)
      .filter(isString)
      .map((value) => ({
        label: value,
        value,
      }));
  },
  proposalBillingPeriods: (
    paymentMethods: PaymentMethod[] | undefined
  ): SelectOption<string>[] => {
    function filterUnique<T>(value: T, index: number, self: T[]) {
      return self.indexOf(value) === index;
    }

    const uniqueBillingPeriods = paymentMethods
      ?.flatMap((p) => p.billingPeriods ?? [])
      .filter(filterUnique);

    return (
      uniqueBillingPeriods?.map((value) => ({
        label: getBillingPeriodLabelAdjective(value, true),
        value,
      })) || []
    );
  },
};

export const useMultiSelectRuleData = (): UseMultiSelectRuleOptionsReturn => {
  const multiSelectRuleOptions: Partial<MultiSelectRuleOptions> = {};

  for (const [ruleType, useDataHook] of Object.entries(
    multiSelectRuleDataMap
  )) {
    const typedRuleType = ruleType as RuleTypeKey;
    const { data } = useDataHook() as {
      data: PaymentTerm[] | PaymentMethod[];
    };

    const transformData = multiSelectRuleDataTransformMap[typedRuleType];
    multiSelectRuleOptions[typedRuleType] = transformData(data);
  }

  return {
    multiSelectRuleOptions: multiSelectRuleOptions as MultiSelectRuleOptions,
  };
};
