import { getLdOptions, getSubDomain } from 'common';
import { ENVIRONMENT_CONFIG } from '../config/hosts';

import { ProviderConfig } from 'launchdarkly-react-client-sdk';

const env = ENVIRONMENT_CONFIG.env.toLowerCase();

export const launchDarklyContext: ProviderConfig = {
  clientSideID: ENVIRONMENT_CONFIG.ldClientSecret || '',
  context: {
    name: getSubDomain(),
    key: 'guest',
    env,
    org: getSubDomain(),
  },
  deferInitialization: true,
  timeout: 3,
  reactOptions: {
    useCamelCaseFlagKeys: true,
    sendEventsOnFlagRead: false,
  },
  options: getLdOptions(ENVIRONMENT_CONFIG.isProduction),
};
