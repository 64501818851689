import {
  Card,
  CardHeader,
  Cell,
  DefaultZeroState,
  getOrDefault,
  InvoiceDetail,
  pushIf,
  SimpleTable,
} from 'common';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { INVOICES } from '../../../core-utils/routes';
import DateTimeCell from '../../../components/DateTimeCell';
import { useIsConnectorEnabled } from '../../../services/connectors';
import { getStatus } from '../../Invoices/helpers';
import StatusLabel from '../../../components/StatusLabel';
import ExternalLink from '../../../components/Sync/ExternalLink';
import styles from './PaymentInvoicesCard.module.scss';
import InvoiceOptionsButton from '../../Invoices/ActionBar/InvoiceOptionsButton';
import InvoiceVoidConfirmationModal from '../../Invoices/InvoiceVoidConfirmationModal';
import {
  useIsCurrentUserAdmin,
  useIsSuperAdmin,
} from '../../../core-utils/helperFunctions/userServiceHelper';

interface Props {
  invoices: InvoiceDetail[];
}

const renderTransactionTypeCell = () => {
  // TODO(Kevin|Sean): Support refund
  return 'Invoice';
};

const renderTransactionLinkCell = (transaction: InvoiceDetail) => {
  return (
    <Link to={`${INVOICES}/${transaction.header.id}`}>
      {transaction.header.invoiceNumber}
    </Link>
  );
};

const renderTransactionDateCell = (transaction: InvoiceDetail) => {
  return (
    <DateTimeCell date={transaction.schedule.invoiceDate} showTime={false} />
  );
};

const renderTransactionAmountCell = (transaction: InvoiceDetail) => {
  return getOrDefault(transaction.header.amountFormatted);
};

const renderTransactionStatusCell = (transaction: InvoiceDetail) => {
  return (
    <StatusLabel
      feature="invoice"
      status={getStatus(transaction.header, transaction.schedule.dueDate)}
    />
  );
};

const renderSyncStatusCell = (invoice: InvoiceDetail) => {
  return (
    <div className={styles.syncStatus}>
      {invoice.schedule.sources?.map((s) => (
        <ExternalLink
          key={s.sourceId}
          externalSource={s}
          lastSyncEvent={invoice.schedule.lastSyncEvent}
        />
      ))}
    </div>
  );
};

export const PaymentInvoicesCard: React.FC<Props> = ({ invoices }) => {
  const isQuickbooksConnected = useIsConnectorEnabled('quickbooksConfig');
  const isAdminRole = useIsCurrentUserAdmin();
  const isSuperAdmin = useIsSuperAdmin();
  const [isConfirmUnvoidModalOpen, setIsConfirmUnvoidModalOpen] =
    useState<boolean>(false);

  const cells: Cell<InvoiceDetail>[] = [
    {
      key: 'type',
      headerLabel: 'Transaction Type',
      renderCell: renderTransactionTypeCell,
    },
    {
      key: 'header.invoiceNumber',
      width: '30%',
      headerLabel: 'Transaction',
      renderCell: renderTransactionLinkCell,
    },
    {
      key: 'header.sentAt',
      headerLabel: 'Date',
      renderCell: renderTransactionDateCell,
    },
    {
      key: 'header.amountFormatted',
      headerLabel: 'Amount',
      renderCell: renderTransactionAmountCell,
    },
    {
      key: 'status',
      headerLabel: 'Status',
      renderCell: renderTransactionStatusCell,
    },
    ...pushIf(isQuickbooksConnected, {
      key: 'syncStatus',
      headerLabel: 'Sync Status',
      renderCell: renderSyncStatusCell,
    }),
    ...pushIf(isAdminRole || isSuperAdmin, {
      key: 'options',
      headerLabel: 'Options',
      renderCell: (transaction: InvoiceDetail) => (
        <div className={styles.options}>
          <InvoiceOptionsButton
            borderless
            invoice={transaction.header}
            scheduleId={transaction.schedule.id}
            setIsConfirmUnvoidModalOpen={setIsConfirmUnvoidModalOpen}
            sourceId={transaction.schedule.sourceDisplayId}
            subscriptionId={transaction.subscription.id}
          />
        </div>
      ),
    }),
  ];

  return (
    <>
      <Card>
        <CardHeader name="Transactions" />
        <SimpleTable
          cells={cells}
          disableSearch
          disableShadow
          rows={invoices}
          zeroState={
            <DefaultZeroState message="There are currently no transactions linked to this payment" />
          }
        />
      </Card>
      <InvoiceVoidConfirmationModal
        isModalOpen={isConfirmUnvoidModalOpen}
        setIsModalOpen={setIsConfirmUnvoidModalOpen}
      />
    </>
  );
};
