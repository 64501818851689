import React from 'react';
import {
  Icon,
  PopOutMenuOption,
  useCustomizeItemActionClickHandler,
  useRowItem,
} from 'common';

export const CustomizeProductAction = () => {
  const item = useRowItem();
  const onCustomizeProductClick = useCustomizeItemActionClickHandler(item);

  return item.product.nameCustomizable ||
    item.product.descriptionCustomizable ? (
    <PopOutMenuOption
      icon={Icon.Edit}
      onClick={onCustomizeProductClick}
      title="Customize product"
    />
  ) : null;
};
