import React, { useState } from 'react';
import APITokens from './APITokens';
import WebHooks from './Webhooks/WebHooks';
import {
  ButtonBar,
  Card,
  CardHeader,
  CardRowValue,
  Icon,
  PopOutMenu,
  PopOutMenuOption,
  Tabs,
} from 'common';
import { getOrgApiBase, getOrgAppBase } from '../../../services/helper';
import { useFlowDomain } from '../../../core-utils/helperFunctions/userServiceHelper';
import CreateAPITokenModal from './CreateAPITokenModal';
import AddWebhook from './Webhooks/addWebhook';
import WebHookEvents from './Webhooks/WebHookEvents';

const APISettings: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('apikeys');
  const [isCreateTokenModalOpen, setIsCreateTokenModalOpen] =
    useState<boolean>(false);
  const [isCreateHookOpen, setIsCreateHookOpen] = useState<boolean>(false);
  const flowDomain = useFlowDomain();

  const tabs: any[] = [
    {
      key: 'apikeys',
      label: 'API keys',
    },
    {
      key: 'webhooks',
      label: 'Webhooks',
    },
    {
      key: 'events',
      label: 'Events',
    },
  ];

  const getTabContent = () => {
    switch (activeTab) {
      case 'apikeys':
        return <APITokens />;
      case 'webhooks':
        return <WebHooks />;
      case 'events':
        return <WebHookEvents />;
    }
    return <APITokens />;
  };

  return (
    <>
      <Card>
        <CardHeader name="API integrations">
          <ButtonBar>
            <PopOutMenu>
              <PopOutMenuOption
                onClick={() => {
                  setIsCreateTokenModalOpen(true);
                }}
                title="Create API Token"
                icon={Icon.Locked}
              />
              <PopOutMenuOption
                onClick={() => {
                  setIsCreateHookOpen(true);
                }}
                title="Add Webhook"
                icon={Icon.DocumentFlip}
              />
            </PopOutMenu>
          </ButtonBar>
        </CardHeader>

        <CardRowValue label="Organization Domain">
          <div className="pb-4">{getOrgAppBase(flowDomain!)}</div>
        </CardRowValue>

        <CardRowValue label="API Endpoint">
          <div className="pb-4">{getOrgApiBase(flowDomain!)}</div>
        </CardRowValue>

        <Tabs
          tabs={tabs}
          onChange={(tab) => {
            setActiveTab(tab);
          }}
          defaultActiveTab={activeTab}
        />
        <section className="connector-tab-content">{getTabContent()}</section>
      </Card>
      <CreateAPITokenModal
        isOpen={isCreateTokenModalOpen}
        closeModal={() => {
          setIsCreateTokenModalOpen(false);
          setActiveTab('apikeys');
        }}
      />
      <AddWebhook
        open={isCreateHookOpen}
        closeModal={() => {
          setIsCreateHookOpen(false);
          setActiveTab('webhooks');
        }}
      />
    </>
  );
};

export default APISettings;
