import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';
import { LOGGED_OUT_STATE, useAuthState } from '../../Auth';
import { LOGIN_ROUTE } from '../../core-utils/routes';
import { getPrevPath, getSearchParams } from '../../services/routes/routeUtils';

interface Props {
  children: React.ReactNode;
}

// BasePage provides theming for the children and auth checks for a logged-in user
const BasePage: React.FC<Props> = ({ children }) => {
  const { auth, setAuth } = useAuthState();
  const location = useLocation();

  const getBody = () => {
    if (!auth.isAuthenticated && !auth.isSystemAdmin) {
      setAuth({
        ...LOGGED_OUT_STATE,
        prevPath: getPrevPath(location.pathname),
        searchSuffix: getSearchParams(location.search),
      });
      return <Navigate replace to={LOGIN_ROUTE} />;
    }

    if (ENVIRONMENT_CONFIG.isProduction) {
      if (auth.user) {
        ReactGA.set({ userId: auth.user.id });
      }
      ReactGA.pageview(location.pathname + getSearchParams(location.search));

      // if ((window as any).zE) {
      //   (window as any).zE('webWidget', 'updatePath', {
      //     title: location.pathname,
      //     url: window.location.href,
      //   });
      // }
    }

    return children;
  };

  return getBody();
};

export default BasePage;
