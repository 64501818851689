import React, { useEffect, useRef } from 'react';
import sanitizeHtml from 'sanitize-html';
import { CommonInputProps, RichTextStyle, useDebounce, useFlags } from 'common';

import { AnyExtension, EditorContent, useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Gapcursor from '@tiptap/extension-gapcursor';
import Placeholder from '@tiptap/extension-placeholder';
import BubbleMenu from '@tiptap/extension-bubble-menu';

import { ALL_FEATURES, EDITOR_FEATURES } from './editorFeatures';
import { EditorFeature, EditorFeatureKey } from './editorCommon';
import EditorBar from './EditorBar';

type FeatureSet =
  | 'product.description'
  | 'proposal.terms'
  | 'proposal.coverLetter'
  | 'invoice.paymentInfo';

export interface RichTextEditorProps extends CommonInputProps<string> {
  featureSet: FeatureSet;
  fireDebounceMs?: number;
  fireOnBlur?: boolean;
  fireOnDebounce?: boolean;
  id?: string;
  inputClassName?: string;
  placeholder?: string;
}

const FEATURE_SETS = new Map<FeatureSet, EditorFeatureKey[]>([
  ['product.description', ['bold', 'bullet', 'highlight']],
  ['proposal.coverLetter', ['bold', 'bullet', 'highlight']],
  ['proposal.terms', ALL_FEATURES],
  ['invoice.paymentInfo', ['bullet']],
]);

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  className,
  dataTestId,
  featureSet,
  fireDebounceMs = 1000,
  fireOnBlur: shouldFireOnBlur = false,
  fireOnDebounce: shouldFireOnDebounce = false,
  id,
  inputClassName,
  isDisabled = false,
  onChange,
  placeholder = 'Add something',
  value: valueProp,
}) => {
  const flags = useFlags();
  const [isFocused, setIsFocused] = React.useState(false);
  const featureKeys = FEATURE_SETS.get(featureSet) || [];
  const lastSubmittedValue = useRef<string | null>(null);
  const features: EditorFeature[] = EDITOR_FEATURES.filter(
    (feature) =>
      featureKeys.includes(feature.key) &&
      // While implementing support in all the required areas
      // We will hide the button behind a flag so that we don't have
      // non-rendering elements in other areas that don't support it yet
      // This just hides the button, but the markdown can still be used
      (!feature.flag || flags[feature.flag])
  );

  const submitContentChanges = (value: null | string): void => {
    if (!value || !onChange) {
      return;
    }

    const sanitizedHtml = sanitizeHtml(value, {
      allowedAttributes: {
        a: ['href', 'name', 'target'],
        tr: ['colspan', 'rowspan'],
        td: ['colspan', 'rowspan'],
      },
      selfClosing: ['br'],
    });

    if (sanitizedHtml !== lastSubmittedValue.current) {
      lastSubmittedValue.current = sanitizedHtml;
      onChange(sanitizedHtml);
    }
  };

  const { debouncedFunction: debouncedSave, flush } = useDebounce(
    submitContentChanges,
    fireDebounceMs
  );

  const extensions: AnyExtension[] = features.reduce(
    (accu, cur) => [...accu, ...cur.extensions],
    [] as AnyExtension[]
  );

  const editor = useEditor({
    content: valueProp || '',

    onUpdate(eventData) {
      if (shouldFireOnDebounce) {
        debouncedSave(eventData.editor.getHTML());
      }
    },

    onFocus() {
      setIsFocused(true);
    },

    onBlur(eventData) {
      setIsFocused(false);

      if (shouldFireOnBlur) {
        flush(eventData.editor.getHTML());
      }
    },

    extensions: [
      ...extensions,
      Document,
      Placeholder.configure({
        placeholder,
      }),
      Paragraph,
      Text,
      Gapcursor,
      BubbleMenu,
    ],
  });

  useEffect(() => {
    if (editor && !isFocused && valueProp && valueProp !== editor.getHTML()) {
      editor.commands.setContent(valueProp);
    }
  }, [valueProp, editor]);

  if (!editor) {
    return null;
  }

  // setting editable-ness here as opposed to in useEditor
  // call b/c it wasn't working the way I expected it to
  // https://github.com/ueberdosis/tiptap/issues/111#issuecomment-448764378
  editor.setOptions({ editable: !isDisabled });

  return (
    <div className={className}>
      <RichTextStyle isDisabled={isDisabled}>
        <EditorContent
          className={inputClassName}
          id={id}
          data-testid={dataTestId}
          editor={editor}
        />
      </RichTextStyle>
      {!isDisabled && <EditorBar features={features} editor={editor} />}
    </div>
  );
};

export default RichTextEditor;
