import React from 'react';
import { useUrgentContractCount } from '../../../services/contract';

const NbUrgentContractsChiclet = () => {
  const { data: urgentCount, isLoading, isError } = useUrgentContractCount();

  if (isLoading || isError || urgentCount === 0) {
    return null;
  }

  return (
    <div className="block absolute right-3 top-1/2 transform -translate-y-1/2 bg-red text-white rounded leading-0 px-1 text-xs h-[20px] min-w-[20px] grid place-items-center">
      {urgentCount}
    </div>
  );
};

export default NbUrgentContractsChiclet;
