import React from 'react';
import {
  isAcceptedProposalStatus,
  PaymentSchedule,
  Proposal,
  ScheduleChart,
  useTranslation,
} from 'common';

const Payments = ({
  paymentSchedule,
  proposal,
}: {
  paymentSchedule: PaymentSchedule;
  proposal: Proposal;
}) => {
  const { tk } = useTranslation();
  const isAccepted = isAcceptedProposalStatus(proposal);
  const startDateIsTodayForProposal =
    proposal.options?.startDateIsSigningDate || !proposal.startDate;

  return (
    <div className="plan-set-schedule">
      <ScheduleChart
        schedule={paymentSchedule}
        proposal={proposal}
        infoSection={
          !isAccepted && startDateIsTodayForProposal ? (
            <div className="text-xs">
              {tk('Based on a start date of today')}
            </div>
          ) : undefined
        }
      />
    </div>
  );
};
export default Payments;
