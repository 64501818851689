import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { systemClient } from '../../httpClients/systemclient';
import { ProposalSummaryAnalytics } from 'common';

export const useSystemTodayProposals = (): UseQueryResult<
  ProposalSummaryAnalytics[]
> => {
  return useQuery({
    queryKey: ['today-proposals'],
    queryFn: async () => {
      const { data } = await systemClient.getProposalsForToday();
      return data;
    },
  });
};
