import React from 'react';
import { useFindByUserSignatureData } from '../../../../services/signature';
import {
  Button,
  CardHeader,
  formatDatetime,
  getDatetime,
  Type,
  useTranslation,
} from 'common';
import OrgDefaultSignature from './OrgDefaultSignature';
import Label from '../../../../components/Label';

interface Props {
  openSignatureModal: () => void;
  userId: string;
}

const SellerSignaturePreview: React.FC<Props> = ({
  userId,
  openSignatureModal,
}: Props) => {
  const { tk } = useTranslation();

  const {
    data: signatureData,
    isFetching,
    isError,
  } = useFindByUserSignatureData(userId);

  if (isFetching) {
    return <div>Loading</div>;
  }

  if (isError) {
    return <div>Error loading</div>;
  }

  const nowDateTime = formatDatetime(getDatetime());

  return (
    <div>
      <div className="flex justify-between w-full mb-4">
        <CardHeader name="Contract signature">
          <Button
            type={signatureData === null ? 'primary' : 'secondary'}
            label={
              signatureData === null ? 'Create signature' : 'Edit signature'
            }
            onClick={openSignatureModal}
          />
        </CardHeader>
      </div>
      {!signatureData ? (
        <Type paragraph>
          {tk(
            'Contracts created from proposals can be automatically signed by someone from your organization. ' +
              "Set up the default signature to add to your customers' contracts here."
          )}
        </Type>
      ) : (
        <div className="max-w-[600px]">
          <Label name="Contract signature" className="pt-0 pb-5 my-0" />
          <img
            className="h-24 w-48 mt-2"
            src={signatureData.signatureImageData}
          />
          <div className="h-0 mt-1 border w-60 border-black" />
          <div className="font-bold">
            {signatureData.name}
            {signatureData.title ? `, ${signatureData.title}` : ''}
          </div>
          <div className="text-xs">{nowDateTime}</div>
        </div>
      )}
      <div className="max-w-[600px] mt-4">
        <OrgDefaultSignature currentUserSignature={signatureData!} />
      </div>
    </div>
  );
};

export default SellerSignaturePreview;
