import React, { useState } from 'react';
import { Select, Space } from 'antd';

import {
  ApprovalRule,
  Button,
  ConfirmDeleteModal,
  Icon,
  isNumber,
  NumberInput,
  ProposalProductDiscountPercentRuleConfig,
  ProposalSummary,
  ToolTip,
} from 'common';

import { useLatestProduct } from 'app/src/services/api/products/products';

import { GroupOption, searchFilterOption } from './_approvalRulesConfig';

import { getConfig } from './ruleUtils';

import styles from './ApprovalRuleStyles.module.scss';

interface Props {
  allGroupOptions: GroupOption[];
  approvalRule: ApprovalRule;
  onRuleChange: (
    id: string | undefined,
    changedRule: ApprovalRule | null
  ) => void;
  proposalType: ProposalSummary['proposalType'];
}

const DEFAULT_MIN = 1000;
const DEFAULT_MAX = 3000;

export const ProductRuleDiscountRangeRow = ({
  approvalRule,
  onRuleChange,
  allGroupOptions,
  proposalType,
}: Props) => {
  const config =
    getConfig<ProposalProductDiscountPercentRuleConfig>(approvalRule);

  const getMinValue = () => {
    return isNumber(config?.discount) ? config.discount : DEFAULT_MIN;
  };

  const getMaxValue = () => {
    return isNumber(config?.maxDiscount) ? config.maxDiscount : DEFAULT_MAX;
  };

  const [minValue, setMinValue] = useState<number>(getMinValue());
  const [maxValue, setMaxValue] = useState<number>(getMaxValue());
  const [showSelectError, setShowSelectError] = useState<boolean>(false);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const { data: product, isLoading: isProductLoading } = useLatestProduct(
    approvalRule.config && 'rootProductId' in approvalRule.config
      ? (approvalRule.config as ProposalProductDiscountPercentRuleConfig)
          .rootProductId
      : ''
  );

  const updateRule = (
    newMinValue: number,
    newMaxValue: number
  ): ApprovalRule => {
    if (!approvalRule.config) {
      throw new Error('ApprovalRule.config is required');
    }
    if (!product?.rootId) {
      throw new Error('Product is required');
    }
    const updatedConfig: ProposalProductDiscountPercentRuleConfig = {
      ...approvalRule.config,
      ruleType: approvalRule.config.ruleType,
      discount: newMinValue,
      maxDiscount: newMaxValue,
      rootProductId: product.rootId,
      proposalType,
    };

    return {
      ...approvalRule,
      config: updatedConfig,
    };
  };

  const handleMinChange = (newValue: number | undefined = 0) => {
    const convertedValued = newValue * 100;
    setMinValue(convertedValued);
  };

  const handleMaxChange = (newValue: number | undefined = 0) => {
    const convertedValued = newValue * 100;
    setMaxValue(convertedValued);
  };

  const handleSave = (newMinValue: number, newMaxValue: number) => {
    onRuleChange(approvalRule.id, updateRule(newMinValue, newMaxValue));
  };

  const handleSaveIfValid = () => {
    if (
      minValue &&
      minValue > 0 &&
      maxValue &&
      maxValue > 0 &&
      minValue < maxValue
    ) {
      handleSave(minValue, maxValue);
    }
  };

  const onGroupsChange = (groupIds: string[]) => {
    setShowSelectError(groupIds.length === 0);

    const payload = {
      ...updateRule(minValue, maxValue),
      approvalGroupIds: groupIds,
    };

    onRuleChange(approvalRule.id, payload);
  };

  if (isProductLoading) {
    return null;
  }

  return (
    <div className={styles.ruleRow}>
      <div className={styles.name}>
        <Button
          className={styles.removeIcon}
          onClick={() => setIsDeleteConfirmationOpen(true)}
          primaryIcon={Icon.CloseCircle}
          type="secondary"
        />

        <div className={styles.flex}>
          <ToolTip title={product?.name}>
            <div className={styles.ruleName}>{product?.name}</div>
          </ToolTip>
          <div className={styles.grey}>&gt;</div>
          <span className={styles.conditionLabel}>Discount</span>
        </div>
      </div>

      <div className={styles.condition}>between</div>

      <div className={styles.input}>
        <Space.Compact block>
          <NumberInput
            min={0}
            onBlur={handleSaveIfValid}
            onChange={handleMinChange}
            onEnter={handleSaveIfValid}
            suffix="%"
            value={minValue / 100}
          />
          <span className={styles.betweenDash}>{' - '}</span>
          <NumberInput
            max={100}
            onBlur={handleSaveIfValid}
            onChange={handleMaxChange}
            onEnter={handleSaveIfValid}
            suffix="%"
            value={maxValue / 100}
          />
        </Space.Compact>
      </div>

      <div className={styles.groups}>
        {allGroupOptions.length > 0 && (
          <Select
            className={styles.groupWidth}
            disabled={!approvalRule}
            filterOption={searchFilterOption}
            mode="multiple"
            onChange={onGroupsChange}
            options={allGroupOptions}
            placeholder="Select approval group"
            status={showSelectError ? 'error' : ''}
            value={approvalRule.approvalGroupIds?.map((id) => id)}
          />
        )}
      </div>

      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => onRuleChange(approvalRule.id, null)}
        typeName="condition"
      />
    </div>
  );
};
