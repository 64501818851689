import React from 'react';

import { NavigationIcon, PopOutMenu, PopOutMenuOption } from 'common';

interface Props {
  onIssueRefundClick: () => void;
  isIssueRefundDisabled: boolean;
}

export const TransferOptionsButton: React.FC<Props> = ({
  onIssueRefundClick,
  isIssueRefundDisabled,
}) => {
  return (
    <PopOutMenu>
      <PopOutMenuOption
        icon={NavigationIcon.Refund}
        isDisabled={isIssueRefundDisabled}
        onClick={onIssueRefundClick}
        title="Refund"
      />
    </PopOutMenu>
  );
};
