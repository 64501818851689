import React from 'react';
import { parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import {
  ConnectorInfo,
  ErrorCell,
  formatDateOrDefault,
  QueryTable,
  SimpleCell,
  TransferSyncSummary,
} from 'common';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';
import IDField from 'app/src/components/IDField';
import { apiClient } from '../../../../services/httpClients/app';
import {
  getLatestEvent,
  reconciliationFilters,
} from '../../../../services/reoconcile';
import StatusLabel from 'app/src/components/StatusLabel';

interface Props {
  connectorName: ConnectorInfo['source'];
}

const PaymentReconcile = ({ connectorName }: Props) => {
  const getLink = (data: TransferSyncSummary) => {
    const event = data.eventDetails
      ?.filter((d) => d.source === connectorName && d.externalId)
      // TODO: Replace with compareChronological or compareReverseChronological
      .sort(
        (a, b) =>
          parseISO(b.createdAt!).getTime() - parseISO(a.createdAt!).getTime()
      )
      .find(Boolean);
    if (event) {
      const href = !ENVIRONMENT_CONFIG.isProduction
        ? `https://app.sandbox.qbo.intuit.com/app/invoice?txnId=${
            event.externalId
          }`
        : `https://app.qbo.intuit.com/app/invoice?txnId=${event.externalId}`;
      return (
        <div className="underline text-blue">
          <Link to={href}>{event.externalId}</Link>
        </div>
      );
    }
    return null;
  };

  const cells: SimpleCell<TransferSyncSummary>[] = [
    {
      renderCell: (data: TransferSyncSummary) => (
        <div className="underline text-blue">
          <Link
            className=" block truncate"
            to={`/customers/${data.customerReference?.id}`}
          >
            {data.customerReference?.name}
          </Link>
        </div>
      ),
      key: 'contract.buyer.name',
      headerLabel: 'Customer name',
      sortable: true,
      overflowCell: true,
    },
    {
      renderCell: (data: TransferSyncSummary) => (
        <IDField
          className="justify-between w-full box-border"
          documentID={data.id}
        >
          {data.id.substring(0, 28)}
        </IDField>
      ),
      width: 250,
      key: 'id',
      headerLabel: 'Transaction no.',
    },
    {
      renderCell: (data: TransferSyncSummary) => (
        <div>{formatDateOrDefault(data.createdAt)}</div>
      ),
      key: 'createdAt',
      headerLabel: 'Created date',
      sortable: true,
    },
    {
      renderCell: (data: TransferSyncSummary) =>
        formatDateOrDefault(data.updatedAt),
      key: 'updatedAt',
      headerLabel: 'Updated date',
      sortable: true,
    },
    {
      renderCell: (data: TransferSyncSummary) => <>{getLink(data)}</>,
      key: 'paymentMethod.name',
      headerLabel: 'QuickBooks transaction no.',
    },
    {
      renderCell: (data: TransferSyncSummary) =>
        formatDateOrDefault(getLatestEvent(data)?.createdAt),
      key: 'lastSyncedAt',
      headerLabel: 'Last sync date',
      sortable: true,
    },
    {
      renderCell: (data: TransferSyncSummary) => {
        const eventDetail = getLatestEvent(data);
        if (eventDetail?.status === 'success') {
          return <StatusLabel feature="invoice" status="success" />;
        } else if (eventDetail?.status === 'error') {
          return (
            <ErrorCell
              error={eventDetail.error}
              statusLabel={<StatusLabel feature="invoice" status="error" />}
            />
          );
        }
        return 'Not synced';
      },
      key: 'lastEvent.status',
      headerLabel: 'Sync status',
      width: 180,
      align: 'center',
      sortable: true,
    },
  ];

  return (
    <QueryTable<TransferSyncSummary>
      cells={cells}
      fetchList={apiClient.getEventsByTransfer}
      filters={reconciliationFilters(false, [
        {
          field: 'id',
          operation: 'equals',
        },
      ])}
      getRowClassName={() => 'text-xs'}
      persistInUrl={false}
      queryKey="getEventsByTransfer"
      tooltipText="Search for Cacheflow payment id"
      zeroStateMessage="There are currently no payments"
    />
  );
};

export default PaymentReconcile;
