import React, { useEffect } from 'react';
import { ContractEvent, ErrorFetching, Icon, Loading, log, noop } from 'common';
import { useGetContractEvents } from '../../../services/contract';
import {
  clusterByDate,
  getEventsPropsClusters,
  isEventSystemUser,
  sortByDateAsc,
} from '../../../core-utils/helperFunctions/eventUtils';
import EventClusters from 'app/src/components/events/EventClusters';
import EventCluster from 'app/src/components/events/EventCluster';
import EventDisplay, {
  EventDisplayProps,
} from 'app/src/components/events/EventDisplay';
import NoEvents from 'app/src/components/events/NoEvents';

interface Props {
  contractId: string;
  isOpen: boolean;
}

const SubscriptionEventList: React.FC<Props> = ({ contractId, isOpen }) => {
  const {
    data: events,
    isPending: isLoadingEvents,
    isError: isErrorGettingEvents,
    refetch,
  } = useGetContractEvents(contractId);

  useEffect(() => {
    if (isOpen) {
      refetch().then(noop, log.error);
    }
  }, [isOpen]);

  if (isLoadingEvents) {
    return <Loading />;
  }

  if (isErrorGettingEvents) {
    return <ErrorFetching />;
  }

  const getEventElements = (
    event: ContractEvent
  ): Omit<EventDisplayProps, 'createdAt' | 'id' | 'person'> | null => {
    if (!event.eventType) {
      throw Error('Event does not have eventType property');
    }

    switch (event.eventType) {
      case 'created':
        return {
          label: 'Subscription created',
          icon: Icon.DocumentAdd,
        };
      case 'created_change':
        return {
          label: 'Change created',
          icon: Icon.Plus,
        };
      case 'created_renewal':
        // renewal generated by system, hide it
        if (isEventSystemUser(event.user)) {
          return null;
        }
        return {
          label: 'Renewal created',
          icon: Icon.Plus,
        };
      case 'accepted_change':
        // change proposal was accepted by seller
        if (event.user) {
          return {
            label: 'Change accepted by seller',
            icon: Icon.Checkmark,
          };
        }
        return {
          label: 'Change accepted',
          icon: Icon.Checkmark,
        };
      case 'accepted_renewal':
        // renewal generated by system, hide it
        if (isEventSystemUser(event.user)) {
          return null;
        }
        // renewal proposal was accepted by seller
        if (event.user) {
          return {
            label: 'Renewal accepted by seller',
            icon: Icon.Checkmark,
          };
        }
        return {
          label: 'Renewal accepted',
          icon: Icon.Checkmark,
        };
      case 'renewal_applied':
        // renewal generated by system, show different message
        if (isEventSystemUser(event.user)) {
          return {
            label: 'Subscription auto-renewed',
            icon: Icon.Calendar,
          };
        }
        return {
          label: 'Subscription renewed',
          icon: Icon.Calendar,
        };
      case 'billing_added':
        return {
          label: 'Billing added',
          icon: Icon.Plus,
        };
      case 'billing_updated':
        return {
          label: 'Billing updated',
          icon: Icon.Edit,
        };
      case 'cancelled':
        return {
          label: 'Subscription cancelled',
          icon: Icon.Close,
        };
      case 'ended':
        return {
          label: 'Subscription ended',
          icon: Icon.Action,
        };
      default:
        return null;
    }
  };

  if (events.length === 0) {
    return <NoEvents />;
  }

  const eventToEventDisplayPropsConverter = (
    event: ContractEvent,
    _previousEvent?: ContractEvent
  ): EventDisplayProps | null => {
    const eventElements = getEventElements(event);

    if (eventElements === null) {
      return null;
    }

    return {
      id: event.id!,
      createdAt: event.createdAt!,
      person: event.contact || event.user,
      ...eventElements,
    };
  };

  const sortedEventsbyDate = sortByDateAsc(events);

  const eventsClusteredByDate =
    clusterByDate<ContractEvent>(sortedEventsbyDate);
  const eventPropsClusteredByDate = getEventsPropsClusters(
    eventsClusteredByDate,
    eventToEventDisplayPropsConverter
  );

  return (
    <EventClusters>
      {eventPropsClusteredByDate.map((cluster) => (
        <EventCluster key={cluster.date} date={cluster.date}>
          {cluster.propObjects.map((props) => (
            <EventDisplay key={props.id} {...props} />
          ))}
        </EventCluster>
      ))}
    </EventClusters>
  );
};

export default SubscriptionEventList;
