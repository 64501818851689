import React, { useState } from 'react';
import { Button, Contract, Proposal } from 'common';
import StatusLabel from '../../../../components/StatusLabel';
import ChangeRenewDrawer from './ChangeRenewDrawer';
import { getContractDisplayStatus } from '../../../../services/contractView';
import SubscriptionActivityButton from './SubscriptionActivityButton';
import SubscriptionOptionsButton from './SubscriptionOptionsButton';
import { useIsCurrentUserAdmin } from '../../../../core-utils/helperFunctions/userServiceHelper';
import ActionBar from '../../../../components/ActionBar';
import ActionBarButton from '../../../../components/ActionBarButton';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

interface Props {
  contract: Contract;
  contractProposals?: Proposal[];
}

const SubscriptionHeader: React.FC<Props> = ({
  contract,
  contractProposals,
}) => {
  const [isChangeOrRenewOpen, setIsChangeOrRenewOpen] = useState(false);
  const [activityDialogOpen, setActivityDialogOpen] = useState(false);
  const isAdmin = useIsCurrentUserAdmin();
  const canUpdate = isActionAllowed(FLAG_TYPES.CONTRACT, 'update');

  const showChangeOrRenew: boolean =
    // Dont allow creation of a change or renewal propsoal on a cancelled contract
    contract.status !== 'cancelled' && canUpdate;

  return (
    <>
      <div className="box-border flex flex-row items-center justify-between bg-white border-b border-solid border-gray-lines h-[72px]">
        <div className="flex items-center gap-4">
          <StatusLabel
            status={getContractDisplayStatus(contract)}
            feature="contract"
          />
          <ActionBar>
            <SubscriptionActivityButton
              contractId={contract.id}
              activityDialogOpen={activityDialogOpen}
              setActivityDialogOpen={setActivityDialogOpen}
            />

            {/* contract.status !== 'cancelled' &&
              contract.status !== 'ended' && */}
            {isAdmin && (
              <ActionBarButton>
                <SubscriptionOptionsButton contract={contract} borderless />
              </ActionBarButton>
            )}
          </ActionBar>

          {showChangeOrRenew && (
            <Button
              dataTestId="change-button"
              label="Change or Renew"
              onClick={() => setIsChangeOrRenewOpen(true)}
            />
          )}
        </div>
      </div>
      <ChangeRenewDrawer
        closeDrawer={() => setIsChangeOrRenewOpen(false)}
        contract={contract}
        contractProposals={contractProposals}
        isShowing={isChangeOrRenewOpen}
      />
    </>
  );
};

export default SubscriptionHeader;
