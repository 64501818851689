import { ENVIRONMENT_CONFIG } from '../../../config/hosts';

import { getCFAppVersion } from '../../services/status';
import { log, Status } from 'common';

const lastSegment = (str: string) => str.substring(str.indexOf('/') + 1);

// the last segment in the branch name is the version. ex release/0.11.2
const version = lastSegment(process.env.BRANCH || 'dev');

export interface VersionInfo extends Status {
  environment?: string;
}

type OnNewVersionCb = (versionInfo: VersionInfo) => void;

const STARTUP_VERSION_INFO: VersionInfo = {
  name: 'cfapp',
  version,
  environment: ENVIRONMENT_CONFIG.env,
  branch: process.env.BRANCH,
  sha: process.env.COMMIT_HASH || 'local',
  buildTime: process.env.BUILD_TIME,
};

let CURRENT_VERSION_INFO: VersionInfo = STARTUP_VERSION_INFO;

const listeners = new Map<string, OnNewVersionCb>();
listeners.set('logger', (versionInfo) => {
  log.info('new version detected.');
  log.info(versionInfo);
});

const checkCurrentVersion = () => {
  getCFAppVersion()
    .then(({ data: latestVersion }) => {
      if (latestVersion) {
        if (latestVersion.sha !== CURRENT_VERSION_INFO.sha) {
          CURRENT_VERSION_INFO = latestVersion;
          listeners.forEach((value) => {
            value(latestVersion);
          });
        }
      }
    })
    .catch((reason) => {
      log.error('Error fetching status: ' + reason);
    });
};
/**
 * Check for new version every 60 seconds
 */
export const pollForNewVersion = () => {
  log.debug('polling for version');
  setInterval(() => {
    checkCurrentVersion();
  }, 60 * 1000);
};

export const getCurrentVersion = () => CURRENT_VERSION_INFO;

export const registerNewVersionListener = (
  key: string,
  versionListener: OnNewVersionCb
) => {
  listeners.set(key, versionListener);
};
