import React, { FC, useState } from 'react';
import clsx from 'clsx';

import styles from './Editable.module.scss';

type AcceptableTagTypes =
  | 'div'
  | 'span'
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5';

interface Props {
  as: AcceptableTagTypes;
  classNames?: string;
  content: string;
  dataTestId?: string;
  isDisabled?: boolean;
  maxContentLength?: number;
  onValueChange: (name: string) => void;
}

const Editable: FC<Props> = ({
  as: Tag,
  classNames,
  content,
  dataTestId,
  isDisabled,
  maxContentLength = 140,
  onValueChange,
}) => {
  const [currentInput, setCurrentInput] = useState(content);
  const [isMaxLength, setIsMaxLength] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.innerText.length <= maxContentLength) {
      setIsMaxLength(false);
      setCurrentInput(e.target.innerText);
    } else {
      setIsMaxLength(true);
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      () => onValueChange(currentInput);
      (e.target as HTMLInputElement).blur();
    }
  };

  const getError = () => {
    if (isMaxLength) {
      return (
        <p className={styles.errorMessage} id="error">
          Error: Max length is {maxContentLength} characters
        </p>
      );
    }
    return null;
  };

  const tagClasses = clsx(
    classNames,
    styles.tag,
    isMaxLength && styles.tagError,
    isDisabled && styles.tagDisabled,
    currentInput.length >= maxContentLength / 2 && styles.isHalfMaxLength,
    currentInput.length >= maxContentLength - 10 && styles.isNearMaxLength
  );

  return (
    <div className={styles.editable} data-testid={dataTestId}>
      <Tag
        suppressContentEditableWarning={!isDisabled}
        className={tagClasses}
        contentEditable={!isDisabled}
        onInput={handleChange}
        onBlur={() => {
          onValueChange(currentInput);
        }}
        onKeyDown={handleEnter}
      >
        {content}
      </Tag>

      {getError()}
    </div>
  );
};

export default Editable;
