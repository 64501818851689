import React from 'react';
import { isString } from 'common';

interface Props {
  children: React.ReactNode;
}

const getDefaultLeftSection = (content: string) => (
  <div className="flex justify-start">
    <p className="text-lg">{content}</p>
  </div>
);

const LeftSection: React.FC<Props> = ({ children }) => {
  if (isString(children)) {
    return getDefaultLeftSection(children);
  }

  return <div>{children}</div>;
};

export default LeftSection;
