import React from 'react';
import { Card, CardHeader, Contract, Proposal } from 'common';
import { AppProducts } from '../../Proposals/ProposalEditor/Products/AppProducts';

interface Props {
  contract: Contract;
  contractProposals?: Proposal[];
  isSaving: boolean;
}

export const ProductsCardDeprecated: React.FC<Props> = ({
  contract,
  contractProposals,
  isSaving,
}) => {
  if (
    contract.items.length === 0 ||
    !contractProposals ||
    contractProposals.length === 0
  ) {
    return null;
  }

  // TODO: add contract.contractOptions to control whether to hide discounts here
  // rather than looking at proposals.
  let lastAcceptedProposal: Proposal | undefined = undefined;
  for (let i = contractProposals.length - 1; i >= 0; i--) {
    if (contractProposals[i]?.status === 'accepted') {
      lastAcceptedProposal = contractProposals[i];
      break;
    }
  }

  if (!lastAcceptedProposal) return null;

  const hideDiscounts = lastAcceptedProposal.options?.hideDiscounts ?? false;

  return (
    <Card>
      <CardHeader name="Products" />
      <AppProducts
        billingPeriod={contract.billingPeriod}
        hideDiscounts={hideDiscounts}
        isDisabled
        isSaving={isSaving}
        items={contract.items}
        proposal={lastAcceptedProposal}
        readOnly
      />
    </Card>
  );
};
