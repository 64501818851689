import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, OrganizationSummary, TokenResponse } from 'common';
import { LOGIN_ROUTE } from '../../core-utils/routes';
import {
  createAuthTokenRequest,
  useAuthTokenExchange,
} from '../../services/api/profile/user';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthState } from '../../Auth';
import styles from './OrgSelectionRow.module.scss';

interface Props {
  organization: OrganizationSummary;
}

const OrgSelectionRow: React.FC<Props> = ({ organization }) => {
  const { auth, setAuth } = useAuthState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleSubmitError = () => {
    navigate(LOGIN_ROUTE, { replace: true });
  };

  const handleSubmitSuccess = (data: TokenResponse) => {
    const authNew = {
      ...auth,
      token: data.token,
      isAuthenticated: true,
    };
    if (!auth.identityToken) {
      authNew.identityToken = auth.token;
    }
    setAuth(authNew);
    navigate(`${LOGIN_ROUTE}?token=${data.token}`, { replace: true });
  };

  const { mutate: exchangeOrgToken } = useAuthTokenExchange(
    handleSubmitSuccess,
    handleSubmitError,
    queryClient
  );

  const authToOrg = () => {
    const params = createAuthTokenRequest(
      organization.id!,
      (auth.identityToken || auth.token)!
    );
    exchangeOrgToken(params);
  };

  return (
    <div className={styles.row} onClick={authToOrg}>
      <div className={styles.logo}>
        {organization.logoUrl && (
          <img
            src={organization.logoUrl}
            alt={organization.name || 'Company logo'}
            className={styles.image}
          />
        )}
        {!organization.logoUrl && (
          <div className={styles.icon}>
            <Icon.Company width="50" height="50" />
          </div>
        )}
      </div>
      <div className={styles.colname}>
        {organization.internalName || organization.name}
      </div>{' '}
      <div className={styles.col}>
        <Icon.CaretRight />
      </div>
    </div>
  );
};

export default OrgSelectionRow;
