import React, { useEffect, useState } from 'react';
import {
  AttachmentConfig,
  isString,
  ProposalAttachment,
  SearchSelect,
} from 'common';
import EditProposalAttachmentOrAttachmentConfig from '../../../Settings/Attachments/EditProposalAttachmentOrAttachmentConfig';
import AttachmentDocumentAndLinkForm from '../../../Settings/Attachments/AttachmentDocumentAndLinkForm';
import AddedAttachments from './AddedAttachments';

interface AttachmentConfigInfo {
  id: string;
  name: string;
}

interface Props {
  attachmentConfigs: AttachmentConfig[];
  attachments: ProposalAttachment[];
  isDisabled?: boolean;
  onAttachmentsChange: (attachments: ProposalAttachment[]) => void;
  proposalIsInDraft: boolean;
}

const SelectAttachments: React.FC<Props> = ({
  isDisabled,
  attachmentConfigs,
  attachments,
  onAttachmentsChange,
  proposalIsInDraft,
}) => {
  const [isAddingNewAttachment, setIsAddingNewAttachment] = useState(false);
  const [attachmentBeingEdited, setAttachmentBeingEdited] = useState<
    ProposalAttachment | undefined
  >(undefined);
  const configMap = new Map<string, AttachmentConfig>();
  attachmentConfigs.forEach((attachmentConfig: AttachmentConfig) => {
    configMap.set(attachmentConfig.id!, attachmentConfig);
  });

  // previousAttachmentIds and this effect are used to update the id of attachmentBeingEdited...
  // doing this because we only get the attachment id after the attachment is added to
  // the proposal with onAttachmentsChange() and the newly-added attachment comes back
  // to this component in props.attachments
  // ...if this kind of thing is something we have to do more and more, we can make
  // the onAttachmentsChange-like methods return promises with the created/updated
  // entities  so we don't have to do listen to props like this
  const [previousAttachmentIds, setPreviousAttachmentIds] = useState<string[]>(
    []
  );
  useEffect(() => {
    if (attachmentBeingEdited) {
      let newAttachmentId: string | undefined = undefined;
      attachments.forEach((a) => {
        if (!previousAttachmentIds.includes(a.id!)) {
          newAttachmentId = a.id;
        }
      });
      setAttachmentBeingEdited((prev) => ({ ...prev, id: newAttachmentId }));
    }
    setPreviousAttachmentIds(attachments.map((a) => a.id!));
  }, [attachments.length]);

  attachments.forEach((attachment: ProposalAttachment) => {
    if (attachment.attachmentConfigId) {
      configMap.delete(attachment.attachmentConfigId);
    }
  });

  const unselectedConfigInfos: AttachmentConfigInfo[] = Array.from(
    configMap.values()
  ).reduce((acc: AttachmentConfigInfo[], config: AttachmentConfig) => {
    if (isString(config.id) && isString(config.name)) {
      acc.push({ id: config.id, name: config.name });
    }

    return acc;
  }, []);

  const onAddAttachmentFromConfigInfo = (
    info: AttachmentConfigInfo | AttachmentConfigInfo[]
  ) => {
    if (Array.isArray(info)) {
      const newAttachments: ProposalAttachment[] = info.map((configInfo) => {
        return { attachmentConfigId: configInfo.id };
      });
      onAttachmentsChange(attachments.concat(newAttachments));
    } else {
      onAttachmentsChange([...attachments, { attachmentConfigId: info.id }]);
    }
  };

  const createProposalAttachment = (
    attachmentName: string,
    documentId?: string,
    linkUrl?: string
  ) => {
    let newAttachment: ProposalAttachment;
    if (documentId) {
      newAttachment = {
        type: 'document',
        name: attachmentName,
        documentId,
        inEnvelope: false,
      };
    } else {
      newAttachment = {
        type: 'link',
        name: attachmentName,
        linkUrl,
      };
    }

    setAttachmentBeingEdited(newAttachment);
    onAttachmentsChange([...attachments, newAttachment]);
    setIsAddingNewAttachment(false);
  };

  const updateProposalAttachment = (name: string, inEnvelope: boolean) => {
    onAttachmentsChange(
      attachments.map((attachment) => {
        if (attachment.id === attachmentBeingEdited?.id) {
          return {
            ...attachmentBeingEdited,
            name,
            inEnvelope,
          };
        }
        return attachment;
      })
    );
    setAttachmentBeingEdited(undefined);
  };

  const removeAttachment = (attachmentToRemove: ProposalAttachment) => {
    onAttachmentsChange(
      attachments.filter(
        (attachment: ProposalAttachment) =>
          attachment.id !== attachmentToRemove.id
      )
    );
  };

  const isLinkAttachment = attachmentBeingEdited?.type === 'link';
  return (
    <>
      <AddedAttachments
        attachments={attachments}
        onRemoveAttachment={removeAttachment}
        proposalIsInDraft={proposalIsInDraft}
        onClickAttachment={setAttachmentBeingEdited}
        isDisabled={isDisabled}
      />
      {!isDisabled && (
        <div className="flex mt-6 w-[300px]">
          <SearchSelect<AttachmentConfigInfo>
            isDisabled={isDisabled}
            options={unselectedConfigInfos}
            displayField="name"
            placeholder="Add attachment"
            onChange={onAddAttachmentFromConfigInfo}
            addNewPlaceHolder="Create new attachment"
            onAddNew={() => setIsAddingNewAttachment(true)}
            dataTestId="add-attachment-input"
          />
        </div>
      )}
      {isAddingNewAttachment && (
        <AttachmentDocumentAndLinkForm
          // note these two components are being rendered conditionally (as opposed to using isShowing prop)
          // to bluntly fix a bug - https://app.clickup.com/t/2p2jz38
          isShowing
          onClose={() => setIsAddingNewAttachment(false)}
          onSaveSuccess={createProposalAttachment}
        />
      )}
      {attachmentBeingEdited && (
        <EditProposalAttachmentOrAttachmentConfig
          isShowing
          name={attachmentBeingEdited.name}
          inEnvelope={attachmentBeingEdited.inEnvelope}
          isLinkAttachment={isLinkAttachment}
          readOnlyUrlOrFileName={
            isLinkAttachment
              ? attachmentBeingEdited.linkUrl
              : attachmentBeingEdited.documentVersion?.fileName
          }
          onClickSave={updateProposalAttachment}
          onClickDelete={() => {
            setAttachmentBeingEdited(undefined);
            removeAttachment(attachmentBeingEdited);
          }}
          onClose={() => setAttachmentBeingEdited(undefined)}
          isProposalAttachment
        />
      )}
    </>
  );
};

export default SelectAttachments;
