import React from 'react';

import {
  Accordion,
  Button,
  ButtonBar,
  Drawer,
  Icon,
  log,
  noop,
  Proposal,
  TextArea,
  useTranslation,
} from 'common';

import { GroupUsersRow } from './GroupUsersRow';
import VerifyProposal from '../ProposalShare/VerifyProposal';
import { useApprovalActionDrawer } from './hooks/useApprovalActionDrawer';

import styles from './ApprovalActionDrawer.module.scss';

interface Props {
  closeDrawer: () => void;
  isShowing: boolean;
  proposal: Proposal;
}

export const ApprovalActionDrawer: React.FC<Props> = ({
  proposal,
  isShowing,
  closeDrawer,
}) => {
  const { tk } = useTranslation();

  const {
    onClose,
    handleCopyEmailsToClipboard,
    draft,
    setDraft,
    isProposalValid,
    rulesByGroupForUser,
    handleApprovalActions,
  } = useApprovalActionDrawer({ proposal, closeDrawer });

  if (!isProposalValid) {
    return (
      <VerifyProposal
        isOpen={isShowing}
        onClose={onClose}
        proposal={proposal}
      />
    );
  }

  return (
    <Drawer
      className={styles.approvalActionDrawer}
      footer={
        <ButtonBar>
          <Button
            block
            isDangerous
            label={tk('Reject')}
            onClick={() => handleApprovalActions('reject')}
            type="secondary"
          />
          <Button
            block
            className={styles.button}
            dataTestId="submit-approval-action"
            label={tk('Approve')}
            onClick={() => handleApprovalActions('approve')}
          />
        </ButtonBar>
      }
      header={tk('Handle approvals')}
      isOpen={isShowing}
      onClose={onClose}
    >
      <div className={styles.primaryText}>
        {tk(
          'Submit a single approval for all groups you can approve on behalf of.'
        )}
      </div>
      <div className={styles.card}>
        <div className={styles.groups}>
          {rulesByGroupForUser.map((group) => {
            return (
              <div className={styles.group} key={group.groupId}>
                <Accordion
                  expandIconPosition="start"
                  header={
                    <span className={styles.groupName}>
                      {group.groupName}
                      <Icon.Copy
                        className={styles.copyIcon}
                        height={22}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyEmailsToClipboard(group.groupId)
                            .then(noop)
                            .catch(log.error);
                        }}
                        width={22}
                      />
                    </span>
                  }
                >
                  <GroupUsersRow group={group} />
                </Accordion>
                <ul>
                  {group.rules.map((rule) => {
                    return <li key={rule.id}>{rule.description}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>

        <Accordion
          expandIconPosition="start"
          header={
            <div className={styles.messageText}>Add message to requester</div>
          }
        >
          <TextArea
            className={styles.message}
            dataTestId="approval-action-message"
            onChange={(value) =>
              setDraft({
                ...draft,
                message: value,
              })
            }
            value={draft.message}
          />
        </Accordion>
      </div>
    </Drawer>
  );
};
