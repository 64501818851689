import React, { useState } from 'react';
import {
  Button,
  Card,
  ConfirmDeleteModal,
  DefaultZeroState,
  Loading,
} from 'common';
import { useDeleteWebhook } from './hook';
import { WebhookTestWrapper } from './testWebhook';
import { useGetAPIWebhooks } from '../../../../services/api/webhook/webhook';
import ErrorHandler from '../../../../components/ErrorHandler';

const WebHooks: React.FC = () => {
  const { data: webhooks, isPending, isError, error } = useGetAPIWebhooks();

  const {
    deleteState,
    setDeleteState,
    webhookId,
    setWebhookId,
    deleteHandler,
  } = useDeleteWebhook();

  const [hookTestState, setHookTestState] = useState<Map<string, Date>>(
    new Map()
  );

  if (isPending || isError) {
    return (
      <div>
        <Card>
          {isPending && <Loading />}
          {isError && <ErrorHandler error={error} />}
        </Card>
      </div>
    );
  }

  return (
    <div className="connector-configuration">
      <section className="bottom">
        {webhooks.length === 0 && (
          <DefaultZeroState message="You currently do not have any active webhooks." />
        )}
        {webhooks.map((hook) => (
          <Card key={'webhook' + hook.id}>
            <div className="flex justify-between">
              <p className="font-semibold">{hook.name}</p>
              <div className="pl-4 flex gap-2">
                <Button
                  isDangerous
                  type="link"
                  label="Delete"
                  className="h-8 !py-1.5"
                  onClick={() => {
                    setDeleteState(true);
                    setWebhookId(hook.id!);
                  }}
                />
                <Button
                  type="secondary"
                  label="Test"
                  className="h-8 !py-1.5"
                  onClick={() => {
                    setHookTestState(
                      new Map(hookTestState.set(hook.id!, new Date()))
                    );
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col mt-6 ml-4">
              <span className="mb-4">
                <div className="font-semibold">Endpoint:</div>
                {hook.url}
              </span>
              <span>
                <div className="font-semibold">Event type:</div>{' '}
                {hook.eventType}
              </span>
            </div>

            <WebhookTestWrapper
              testTime={hookTestState.get(hook.id!)}
              hookId={hook.id!}
            />
          </Card>
        ))}

        <ConfirmDeleteModal
          isOpen={deleteState}
          onClose={() => setDeleteState(false)}
          onConfirm={() => deleteHandler(webhookId!)}
          typeName="webhook"
        />
      </section>
    </div>
  );
};

export default WebHooks;
