import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Loading,
  OrgConfig,
  ToggleSwitch,
  useToast,
  useTranslation,
} from 'common';
import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../services/orgDefaults';

interface Props {
  dataTestId?: string;
  orgConfigKey: NonNullable<OrgConfig['configKey']>;
}

const OrgConfigToggle: React.FC<Props> = ({ orgConfigKey, dataTestId }) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useFindOrgDefault(orgConfigKey);
  const { tk } = useTranslation();
  const showToast = useToast();

  const { mutate } = useSetOrgConfigDefault(
    orgConfigKey,
    () => showToast.info(tk('Setting saved.')),
    () => showToast.error(tk('Failed to save setting. Please try again.')),
    queryClient
  );

  if (isLoading || data === undefined) {
    return <Loading />;
  }

  return (
    <ToggleSwitch
      value={!!data?.intValue}
      dataTestId={dataTestId}
      label="Enable"
      name={orgConfigKey}
      onChange={(checked) => mutate({ intValue: checked ? 1 : 0 })}
    />
  );
};

export default OrgConfigToggle;
