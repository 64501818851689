import React, { useEffect } from 'react';
import { Loading, log } from 'common';
import { hoursToMilliseconds } from 'date-fns';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { USER_PROFILE_KEY, userProfileGet } from './services/api/profile/user';
import { AuthStateInterface, useAuthState } from './Auth';
import { ENVIRONMENT_CONFIG } from '../config/hosts';
import {
  getPrevPath,
  getSearchParams,
  isNonEntryPath,
} from './services/routes/routeUtils';
import OrgSelectionPage from './pages/Login/OrgSelectionPage';

const PROFILE_REFRESH_RATE = hoursToMilliseconds(1);

const ProfileWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const { auth, setAuth, isLoading: isLoadingAuth } = useAuthState();
  const isProfileEnabled = !isLoadingAuth && auth.isAuthenticated;

  const { data: profile } = useQuery({
    queryKey: [USER_PROFILE_KEY, auth.token],
    queryFn: userProfileGet,
    retry: false,
    refetchInterval: PROFILE_REFRESH_RATE,
    enabled: isProfileEnabled,
  });

  useEffect(() => {
    const savePath: boolean = isNonEntryPath(location.pathname);

    if (!profile) {
      setAuth({
        ...auth,
        prevPath: savePath ? getPrevPath(location.pathname) : auth.prevPath,
        searchSuffix: savePath
          ? getSearchParams(location.search)
          : auth.searchSuffix,
      });
      return;
    }

    const newAuth: AuthStateInterface = {
      ...auth,
      ...profile,
      prevPath: savePath ? getPrevPath(location.pathname) : auth.prevPath,
      searchSuffix: savePath
        ? getSearchParams(location.search)
        : auth.searchSuffix,
    };

    if (ENVIRONMENT_CONFIG.isMonitored) {
      Sentry.setContext('app_context', {
        flowDomain: profile.flowDomain,
        email: profile.identity?.username || profile.user?.username,
        orgId: profile.organizationId,
      });
    }

    setAuth(newAuth);
  }, [profile]);

  if (isLoadingAuth) {
    log.info('Rendering Loading for AuthContext.isLoading:', isLoadingAuth);
    return <Loading />;
  }

  if (
    profile &&
    !auth.user &&
    auth.identity?.identityType === 'authidentity' &&
    auth.isAuthenticated
  ) {
    return <OrgSelectionPage />;
  }

  const isProfileSet = !!auth.flowDomain;
  if (isProfileEnabled && !isProfileSet) {
    log.info('Rendering Loading for userProfile');
    return <Loading />;
  }

  return children;
};

export default ProfileWrapper;
