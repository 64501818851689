import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Icon,
  Loading,
  StripeConfig,
  ToggleSwitch,
  ToolTip,
  useToast,
  useTranslation,
} from 'common';
import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';
import { clearPaymentMethodsCache } from '../../../services/paymentMethods';

const StripePaymentRetryToggle: React.FC = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useFindOrgDefault('stripeConfig');
  const { tk } = useTranslation();
  const showToast = useToast();

  const onSuccess = async () => {
    showToast.info(
      tk('Stripe automatic payments retry setting saved successfully.')
    );
    await clearPaymentMethodsCache(queryClient);
  };

  const [stripePaymentRetryConfigValue, setStripePaymentRetryConfigValue] =
    useState<boolean>(true);

  useEffect(() => {
    if (data?.configValue) {
      setStripePaymentRetryConfigValue(
        !!(data.configValue as StripeConfig | undefined)?.paymentRetryEnabled
      );
    }
  }, [data]);

  const { mutate } = useSetOrgConfigDefault(
    'stripeConfig',
    onSuccess,
    () =>
      showToast.error(
        tk('Failed to save Stripe payment retry setting. Please try again.')
      ),
    queryClient
  );

  if (isLoading || data === undefined) {
    return <Loading />;
  }

  const updateStripeRetryConfig = (paymentRetryEnabled: boolean) => {
    mutate({
      configValue: {
        ...data?.configValue,
        paymentRetryEnabled,
      } as StripeConfig,
    });
  };

  return (
    <div className="flex gap-2">
      <ToggleSwitch
        value={stripePaymentRetryConfigValue}
        label={tk('Automatically retry payments on failures.')}
        onChange={() => {
          setStripePaymentRetryConfigValue(!stripePaymentRetryConfigValue);
          updateStripeRetryConfig(!stripePaymentRetryConfigValue);
        }}
      />
      <ToolTip
        className="whitespace-break-spaces"
        title={`Cacheflow will automatically retry failed
		payments for both credit card and direct debit 
		transactions according to the schedules below.
    Credit Card - 1 hour, 1 day, 3 days, 1 week.
		Direct Debit - 3 days, 1 week.`}
      >
        <Icon.Information width={28} height={28} />
      </ToolTip>
    </div>
  );
};

export default StripePaymentRetryToggle;
