import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import {
  useOrganization,
  useUpdateOrganizationOption,
} from '../../../services/api/system/organizations';

import {
  Card,
  CardHeader,
  CardRowValue,
  ErrorFetching,
  Icon,
  Loading,
  Select,
  useToast,
} from 'common';
import LoginAsButton from '../LoginAsButton/LoginAsButton';

import { SYSTEM_DASHBOARD_ROUTE } from '../../../core-utils/routes';
import StatusLabel from '../../../components/StatusLabel';
import SystemEventList from './SystemEventList';
import Page from '../../../components/Page';
import { ForceMigration } from './ForceMigration/ForceMigration';

const OrganizationDetailPage: React.FC = () => {
  const { id: orgId } = useParams<{ id: string }>();

  const showToast = useToast();
  const { data: organization, isLoading } = useOrganization(orgId!);
  const queryClient = useQueryClient();

  if (!orgId) {
    return <ErrorFetching />;
  }

  const handleSubmitError = () => {
    showToast.error('Failed to update option for organization.');
  };

  const handleSubmitSuccess = () => {
    // TODO: close modal
    // navigate(SYSTEM_DASHBOARD_ROUTE);
    showToast.info('Organization option has been updated successfully.');
  };

  const { mutate: updateOrganizationOption } = useUpdateOrganizationOption(
    orgId,
    handleSubmitSuccess,
    handleSubmitError,
    queryClient
  );

  const options = organization?.options || {};

  const nameComponent = () => {
    if (!organization) {
      return undefined;
    }

    return (
      <div className="flex h-10 gap-2">
        <div className="flex flex-col justify-center">
          <Link to={SYSTEM_DASHBOARD_ROUTE}>
            <Icon.ArrowLeft height="3rem" width="3rem" />
          </Link>
        </div>
        <div className="flex flex-col justify-center">
          {organization.org.name}
          <div className="text-xs">{organization.org.flowDomain}</div>
        </div>
      </div>
    );
  };

  const statusComponent = () => {
    if (!organization) {
      return undefined;
    }

    return (
      <StatusLabel
        status={organization.org.status || ''}
        feature="organization"
      />
    );
  };

  const handleOption = (value: string, optionName: string) => {
    const body: any = {
      id: orgId,
      options: {},
    };

    body.options[optionName] = value;

    updateOrganizationOption(body);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!organization) {
    return <ErrorFetching />;
  }

  return (
    <Page
      className="w-full"
      leftWidget={nameComponent()}
      rightWidget={statusComponent()}
    >
      <Card>
        <CardHeader name="Org Details" />
        <CardRowValue label="Status">
          <div>{organization.org.status}</div>
        </CardRowValue>
        {organization.org.id && (
          <CardRowValue label="Login as">
            <div>
              <LoginAsButton
                orgId={organization.org.id}
                dataTestId={organization.org.id}
              />
            </div>
          </CardRowValue>
        )}
      </Card>

      <Card>
        <CardHeader name="Org options" />
        <>
          {Object.keys(options).map((key) => {
            return (
              <CardRowValue key={key} label={key}>
                <Select<string>
                  options={[
                    { name: 'true', value: 'true' },
                    { name: 'false', value: 'false' },
                  ]}
                  onChange={(newValue) => handleOption(newValue, key)}
                  value={options[key]?.toString()}
                />
              </CardRowValue>
            );
          })}
        </>
      </Card>

      <Card>
        <CardHeader name="Org configs" />
        {organization.configs?.map((config) => {
          return (
            <CardRowValue key={config.configKey} label={config.configKey!}>
              <div>{config.stringValue}</div>
            </CardRowValue>
          );
        })}
      </Card>

      <SystemEventList
        title="Scheduled Events"
        events={organization.scheduleJobs || []}
      />

      <ForceMigration orgId={orgId} />

      <SystemEventList
        title="Migrations"
        events={organization.migrationJobs || []}
      />
    </Page>
  );
};

export default OrganizationDetailPage;
