import { useState } from 'react';

import {
  ApprovalAction,
  ApprovalRequest,
  ApprovalRule,
  ApprovalRuleResult,
  Approver,
  getErrorMessage,
  Proposal,
  User,
  useToast,
} from 'common';

import { usePerformBatchApprovalActions } from 'app/src/services/proposal';
import { apiClient } from 'app/src/services/httpClients/app';
import { useValidateProposal } from '../../proposalUtilities';
import { useGetAllApprovalGroups } from 'app/src/services/api/approvalGroups/approvalGroups';
import { useApprovalRequestsByUserGroups } from './useApprovalRequestsByUserGroups';

type RulesByGroup = {
  [groupId: string]: {
    groupId: string;
    groupName?: string;
    rules: ApprovalRuleResult[];
  };
};

type GroupMap = {
  [groupId: string]: {
    groupId: string;
    groupName: string;
  };
};

type Props = {
  closeDrawer: () => void;
  proposal: Proposal;
};

export const useApprovalActionDrawer = ({ proposal, closeDrawer }: Props) => {
  const showToast = useToast();
  const [draft, setDraft] = useState<ApprovalAction>({ message: '' });

  const { data: allApprovalGroups, isLoading: isApprovalGroupsLoading } =
    useGetAllApprovalGroups();

  const { mutate: submitActions } = usePerformBatchApprovalActions(
    proposal.id,
    handleActionSuccess,
    handleActionError
  );

  const { getFilteredRequests } = useApprovalRequestsByUserGroups(proposal);
  const filteredApprovalRequests = getFilteredRequests();
  const rulesByGroupForUser = getRulesByGroupForUser();

  function handleApprovalActions(action: ApprovalAction['action']) {
    const ruleIds = new Set(
      rulesByGroupForUser.flatMap((group) => group.rules.map((rule) => rule.id))
    );

    const approvalRequestIds = filteredApprovalRequests
      .filter((request) => request.rules?.some((rule) => ruleIds.has(rule.id)))
      .map((request) => request.id)
      .filter((id) => id !== undefined);

    submitActions({
      approvalRequestIds,
      action,
      message: draft.message ?? '',
    });
  }

  function handleActionSuccess() {
    showToast.info('Success');
    closeDrawer();
  }

  function handleActionError(responseError: unknown) {
    const responseMsg =
      getErrorMessage(responseError) ||
      'There was an issue making the request. Please try again.';
    showToast.error(responseMsg);
  }

  async function handleCopyEmailsToClipboard(groupId: string) {
    const { data } = await apiClient.getUsersByGroupId(groupId);

    const emailList = data.map((user: User) => user.username).join(',');

    await navigator.clipboard.writeText(emailList);
    showToast.info('Email(s) copied to clipboard!');
  }

  function getRulesOrganizedByGroup() {
    if (isApprovalGroupsLoading) {
      return [];
    }

    const groupMap = (allApprovalGroups || []).reduce<GroupMap>(
      (acc, group) => {
        if (group.id && group.group && group.group.id && group.group.name) {
          acc[group.group.id] = {
            groupName: group.group.name,
            groupId: group.group.id,
          };
        }

        return acc;
      },
      {}
    );

    const pendingRules = filteredApprovalRequests.filter(
      (r: ApprovalRequest) => r.status === 'pending'
    );

    const rulesByGroupObject = pendingRules.reduce<RulesByGroup>(
      (acc, request: ApprovalRequest) => {
        request.approvers?.forEach((approver: Approver) => {
          const approvalGroupId = approver.groupId ?? '';

          if (!acc[approvalGroupId]) {
            acc[approvalGroupId] = {
              // @ts-ignore TODO: Fix
              groupName: groupMap[approvalGroupId].groupName,
              // @ts-ignore TODO: Fix
              groupId: groupMap[approvalGroupId].groupId,
              rules: [],
            };
          }

          request.rules?.forEach((approvalRule: ApprovalRule) => {
            // @ts-ignore TODO: Fix
            acc[approvalGroupId].rules.push(approvalRule);
          });
        });

        return acc;
      },
      {}
    );

    // Convert the grouped object back into an array
    return Object.values(rulesByGroupObject);
  }

  function getRulesByGroupForUser() {
    const rulesOrganizedByGroup = getRulesOrganizedByGroup();

    return rulesOrganizedByGroup.filter((rule) => {
      const isRuleApprover = (approver: Approver) =>
        approver.groupId === rule.groupId;

      const isRequestApproved = (request: ApprovalRequest) =>
        request.approvers?.some(isRuleApprover);

      return filteredApprovalRequests.some(isRequestApproved);
    });
  }

  return {
    onClose: closeDrawer,
    handleCopyEmailsToClipboard,
    draft,
    setDraft,
    isProposalValid: useValidateProposal(proposal).isValid,
    rulesByGroupForUser,
    submitActions,
    handleApprovalActions,
  };
};
