import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import {
  useGetPendingProposalApprovalRequest,
  useIsProposalEditable,
} from 'app/src/services/proposal';
import clsx from 'clsx';
import {
  BillingPeriodsType,
  CreateProposalRequest,
  getErrorStatus,
  hasAnAcceptedProposalStatus,
  isChangeProposalType,
  isInNextNDays,
  isNonEmpty,
  isRenewalProposalType,
  Nullable,
  PageHeaderHeightContext,
  PaymentSchedule,
  Proposal,
  ShowDiscountAsDefaultContext,
  useFlags,
} from 'common';
import React, { useCallback, useEffect, useState } from 'react';
import ProposalLoadError from '../ProposalLoadError';
import AdditionalTerms from './AdditionalTerms/AdditionalTerms';
import ApprovalBanner from './Approvals/ApprovalBanner';
import AttachmentSection from './AttachmentSection/AttachmentSection';
import AutoRenewalSection from './AutoRenewalSection/AutoRenewalSection';
import { AutoRenewalWarningBanner } from './AutoRenewalSection/AutoRenewalWarningBanner';
import BillingInfo from './BillingInfo';
import CoverLetter from './CoverLetter/CoverLetter';
import DocuSignSection from './DocusignSection';
import EditorHeader from './EditorHeader/EditorHeader';
import PaymentPlanSection from './PaymentPlanSection/PaymentPlanSection';
import ProductsSection from './Products/ProductsSection';
import { ProductsSectionDeprecated } from './Products/ProductsSectionDeprecated';
import ProposalCore from './ProposalCore/ProposalCore';
import ProposalDebugger from './ProposalDebugger/ProposalDebugger';

import styles from './ProposalEditor.module.scss';
import {
  findSchedule,
  isProposalPublished,
  isProposalReadonly,
  isProposalTemplate,
} from './proposalUtilities';
import SubscriptionLink from './SubscriptionLink';
import { useHeightObserverRef } from './useHeightObserverRef';
import { useProposalConfig } from '../../../services/orgDefaults';

interface Props {
  isSaving: boolean;
  onShare: () => void;
  proposal: Proposal;
  save: (updateProposalRequest: Nullable<CreateProposalRequest>) => void;
}

const ProposalEditor: React.FC<Props> = ({
  proposal,
  save,
  onShare,
  isSaving,
}) => {
  const { enhancedSpans } = useFlags();
  const [pageHeaderHeight, setPageHeaderHeight] = useState(0);
  const onHeightChanged = useCallback((height: number) => {
    document.documentElement.style.setProperty(
      '--pageHeaderHeight',
      `${height}px`
    );
    setPageHeaderHeight(height);
  }, []);
  const headerRef = useHeightObserverRef(onHeightChanged);

  const { data: approvalRequest, error: approvalRequestError } =
    useGetPendingProposalApprovalRequest(proposal.id);

  const proposalConfig = useProposalConfig();

  const showDiscountAsDefault =
    proposalConfig.showDiscountViewAsDefault ?? false;

  const isDisabled: boolean =
    isProposalReadonly(proposal) ||
    !useIsProposalEditable(proposal, approvalRequest);

  const setProposalField = (
    field: keyof CreateProposalRequest,
    value: Nullable<CreateProposalRequest>[keyof CreateProposalRequest]
  ) => {
    save({
      [field]: value,
    });
  };

  const setRequiresSigning = (isRequired: boolean) => {
    return save({
      options: {
        ...proposal.options,
        requiresSigning: isRequired,
      },
    });
  };

  const isPublished = isProposalPublished(proposal.status);
  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);
  const isProposalTypeChange = isChangeProposalType(proposal);
  const isProposalTypeRenewal = isRenewalProposalType(proposal);
  const isTemplate = isProposalTemplate(proposal);
  const isPublishedOrAccepted = isPublished || hasAnAcceptedStatus;
  const proposalRecipients = proposal.proposalContacts;
  const hasProposalRecipients = proposalRecipients.length > 0;
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  // Provides a message to our users to let them know if the proposal
  // is not accepted before the auto-renewal date, it will not renew
  const showAutoRenewalWarningBanner: boolean =
    proposal.termLengthMonths === 1 &&
    proposal.termQty === 1 &&
    !!proposal.renewalConfiguration &&
    isInNextNDays(proposal.endDate, 30) &&
    !hasAnAcceptedStatus &&
    (isChangeProposalType(proposal) || isRenewalProposalType(proposal));

  // only store the part that the user can choose
  const [selectedPeriod, setSelectedPeriod] = useState<
    BillingPeriodsType | undefined
  >();

  useEffect(() => {
    if (selectedPeriod) {
      const selectedSchedule = proposal.schedules.find(
        (ps) => ps.payEvery === selectedPeriod
      );

      if (!selectedSchedule) {
        setSelectedPeriod(undefined);
      }
    }
  }, [proposal]);

  const getPaymentSchedule = (): PaymentSchedule => {
    if (selectedPeriod) {
      const selectedSchedule = proposal.schedules.find(
        (ps) => ps.payEvery === selectedPeriod
      );
      if (selectedSchedule) {
        return selectedSchedule;
      }
    }

    return findSchedule(proposal)!;
  };

  const paymentSchedule = getPaymentSchedule();

  const errorStatus = getErrorStatus(approvalRequestError);
  if (errorStatus) {
    return <ProposalLoadError status={errorStatus} />;
  }

  // add this for oxbow testing: data-testid={enhancedSpans ? 'oxbow-v1-proposal-editor' : false}
  return (
    <div
      className={clsx(
        styles.proposalEditor,
        !isPublishedOrAccepted && styles.withHoverShadow
      )}
    >
      <ShowDiscountAsDefaultContext.Provider value={showDiscountAsDefault}>
        <PageHeaderHeightContext.Provider value={pageHeaderHeight}>
          <EditorHeader
            approvalRequest={approvalRequest}
            isDisabled={isDisabled}
            isSaving={isSaving}
            onChange={save}
            onShare={onShare}
            proposal={proposal}
            ref={headerRef}
          />
          <div
            className={clsx(
              styles.body,
              (isDisabled || hasProposalRecipients) && styles.moreMargin
            )}
          >
            {!isTemplate && <SubscriptionLink proposal={proposal} />}

            {!isTemplate && !isDisabled && canUpdate && (
              <ApprovalBanner
                approvalRequest={approvalRequest}
                proposal={proposal}
              />
            )}

            {showAutoRenewalWarningBanner && canUpdate && (
              <AutoRenewalWarningBanner />
            )}

            {(!isPublishedOrAccepted || proposal.coverLetter) && (
              <CoverLetter isDisabled={isDisabled} proposal={proposal} />
            )}

            <ProposalCore
              isAccepted={hasAnAcceptedStatus}
              isDisabled={isDisabled}
              isProposalTypeChange={isProposalTypeChange}
              isProposalTypeRenewal={isProposalTypeRenewal}
              isPublished={isPublished}
              onCoreChange={save}
              paymentSchedule={paymentSchedule}
              proposal={proposal}
            />

            {enhancedSpans && (
              <ProductsSection
                billingPeriod={paymentSchedule.payEvery}
                isDisabled={isDisabled}
                proposal={proposal}
              />
            )}

            {!enhancedSpans && (
              <ProductsSectionDeprecated
                billingPeriod={paymentSchedule.payEvery}
                isDisabled={isDisabled}
                isSaving={isSaving}
                proposal={proposal}
              />
            )}

            <PaymentPlanSection
              isDisabled={isDisabled}
              paymentSchedule={paymentSchedule}
              proposal={proposal}
              selectBillTo={(billTo) => {
                setProposalField('billToId', billTo);
              }}
              selectPaymentPlanIndex={(index) => {
                setSelectedPeriod(proposal.schedules[index]?.payEvery);
              }}
              selectPaymentTerm={(term) => {
                if (term) {
                  setProposalField('paymentTermType', term);
                }
              }}
              selectShipFrom={(shipFrom) => {
                setProposalField('shipFromId', shipFrom);
              }}
              setProposalField={setProposalField}
            />

            {(!isPublishedOrAccepted || proposal.renewalConfiguration) && (
              <AutoRenewalSection
                isDisabled={isDisabled || isPublished}
                proposal={proposal}
              />
            )}

            {(!isPublishedOrAccepted || proposal.customTerms) && (
              <AdditionalTerms
                isDisabled={isDisabled}
                onTermsContentChange={(value) =>
                  setProposalField('customTerms', value)
                }
                proposal={proposal}
                termsContentHtml={proposal.customTerms}
              />
            )}

            {(!isPublishedOrAccepted || isNonEmpty(proposal.attachments)) && (
              <AttachmentSection
                isDisabled={isDisabled}
                onAttachmentsChange={(value) =>
                  setProposalField('attachments', value)
                }
                proposal={proposal}
              />
            )}

            {!(isPublishedOrAccepted && !proposal.options?.requiresSigning) &&
              proposal.id && (
                <DocuSignSection
                  isDisabled={isDisabled}
                  proposalId={proposal.id}
                  requiresSigning={proposal.options?.requiresSigning}
                  setRequiresSigning={setRequiresSigning}
                  signingDocument={proposal.signingDocument}
                />
              )}

            {(hasAnAcceptedStatus || isProposalTypeChange) &&
              proposal.contract?.id && (
                <BillingInfo
                  contractId={proposal.contract.id}
                  isDisabled={isDisabled}
                />
              )}
          </div>
          <ProposalDebugger proposal={proposal} />
        </PageHeaderHeightContext.Provider>
      </ShowDiscountAsDefaultContext.Provider>
    </div>
  );
};

export default ProposalEditor;
