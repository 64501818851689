import { useEffect, useState } from 'react';

export interface ScriptProps {
  id: string;
  url: string;
  integrity?: string;
  async?: boolean;
  crossOrigin?: string;
  shouldLoad: boolean;
}

const useScript = ({
  id,
  url,
  integrity,
  async,
  crossOrigin,
  shouldLoad,
}: ScriptProps) => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(shouldLoad && url ? 'loading' : 'idle');

  useEffect(() => {
    // Allow falsy src value if waiting on other data needed for
    // constructing the script URL passed to this hook.
    if (!shouldLoad || !url) {
      setStatus('idle');
      return () => {};
    }
    // Fetch existing script element by src
    // It may have been added by another intance of this hook
    /* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
    let script = document.querySelector(
      `script[src="${url}"]`
    ) as HTMLScriptElement;
    /* eslint-enable @typescript-eslint/non-nullable-type-assertion-style */
    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = url;
      if (id) {
        script.id = id;
      }
      if (integrity) {
        script.integrity = integrity;
      }
      script.async = !!async;
      script.crossOrigin = crossOrigin ? crossOrigin : 'anonymous';
      script.setAttribute('data-status', 'loading');
      script.type = 'module';
      // Add script to document body
      document.body.appendChild(script);
      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = (event: any) => {
        script?.setAttribute(
          'data-status',
          event.type === 'load' ? 'ready' : 'error'
        );
      };
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      // Grab existing script status from attribute and set to state.
      setStatus(script?.getAttribute('data-status') || 'idle');
    }
    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: any) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };
    if (script) {
      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
    }
    // Remove event listeners on cleanup
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [url, shouldLoad]);

  return status;
};

export default useScript;
