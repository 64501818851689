import React from 'react';

interface Props {
  className?: string;
  name: string;
}

const Label: React.FC<Props> = ({ name, className }) => (
  <div
    className={`font-extrabold text-sm leading-6 uppercase tracking-wider my-[6px] py-[3px] ${
      className || ''
    }`}
  >
    {name}
  </div>
);

export default Label;
