import React from 'react';

import {
  CreateProposalRequest,
  Customer,
  Modal,
  Proposal,
  useToast,
} from 'common';
import CustomerFormInline from '../../../Sales/Customers/CustomerFormInline';
import { useUpdateProposal } from 'app/src/services/proposal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  proposal: Proposal;
}

export const SubscriptionCreateModal = ({
  isOpen,
  proposal,
  onClose,
  onConfirm,
}: Props) => {
  const showToast = useToast();

  const { mutate: doUpdateProposalAndCreateSub } = useUpdateProposal(
    proposal.id,
    {
      onSuccess: () => onConfirm(),
      onError: () => showToast.error('Failed to update proposal'),
    }
  );

  const updateProposalAndCreateSub = (
    customer: Customer | undefined,
    currencyChanged: boolean
  ) => {
    const updateRequest: CreateProposalRequest = {};

    if (currencyChanged) {
      // If the user explicitly chose a new currency on the Customer here then
      // set it on the Proposal too.
      updateRequest.currency = customer?.defaultCurrency;
    }

    if (
      proposal.name === 'Untitled' ||
      (proposal.customer && proposal.customer.name === proposal.name)
    ) {
      updateRequest.name = customer?.name || 'Untitled';
    }

    if (customer?.billingContact?.id) {
      updateRequest.contactIds = [customer.billingContact.id];
    }

    doUpdateProposalAndCreateSub(updateRequest);
  };

  const onUpdateOrSave = (customer: Customer, currencyChanged: boolean) => {
    updateProposalAndCreateSub(customer, currencyChanged);
  };

  return (
    <Modal header="Create subscription" isOpen={isOpen} onClose={onClose}>
      <CustomerFormInline
        isDisabled={false}
        customerBeingEdited={proposal.customer}
        onUpdateCustomer={onUpdateOrSave}
        onCloseForm={onClose}
        customerStarterName={proposal.customer?.name ?? ''}
        isInProposalEditor
      />
    </Modal>
  );
};
