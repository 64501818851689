import React from 'react';

import {
  FormField,
  NumberInput,
  PaymentGracePeriodConfig,
  ToggleSwitch,
} from 'common';

import styles from './GracePeriodSection.module.scss';

type Props = {
  gracePeriodState: PaymentGracePeriodConfig;
  onChange: (
    field: keyof PaymentGracePeriodConfig,
    value: boolean | number | string | undefined
  ) => void;
};

export const GracePeriodSection = ({ gracePeriodState, onChange }: Props) => {
  return (
    <div>
      <div className={styles.gracePeriodSection}>
        <div className={styles.sectionTitle}>
          Grace period for usage-based billing
        </div>
        <ToggleSwitch
          id="enabled"
          name="enabled"
          onChange={(v) => onChange('enabled', v)}
          value={gracePeriodState.enabled ?? false}
        />
      </div>

      {gracePeriodState.enabled && (
        <div className={styles.form}>
          <FormField
            infoText="Days allowed for usage addition post-schedule date before invoicing"
            label="Set grace period to"
          >
            <NumberInput
              className={styles.input}
              max={100}
              min={0}
              onChange={(v) => onChange('defaultGracePeriodInDays', v)}
              suffix="days"
              type="number"
              value={gracePeriodState.defaultGracePeriodInDays}
            />
          </FormField>
        </div>
      )}
    </div>
  );
};
