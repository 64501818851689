import { Contract } from 'common';
import React from 'react';
import ItemIntervalMetricsCard from './ItemIntervalMetricsCard';
import ItemMetricsCard from './ItemMetricsCard';

interface Props {
  contract: Contract;
}

const MetricsTab: React.FC<Props> = ({ contract }) => {
  return (
    <>
      <ItemIntervalMetricsCard contract={contract} />
      <ItemMetricsCard contract={contract} />
    </>
  );
};

export default MetricsTab;
