import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  formatDatetime,
  Notification,
  QueryCell,
  QueryTable,
  SelectOption,
  useToast,
} from 'common';
import Page from '../../../components/Page';
import { useResendEmail } from '../../../services/emails';
import NotificationErrorMessage from './NotificationErrorMessage';
import { systemClient } from '../../../services/httpClients/systemclient';

const convertEmailKeysToSelectOptions = (): SelectOption<string>[] => {
  const emailKeys: Notification['emailKey'][] = [
    'otp_created',
    'approval',
    'auto_renewal_reminder_buyer',
    'billing_invite',
    'billing_method_updated_buyer',
    'billing_method_updated_seller',
    'change_accepted_buyer',
    'change_accepted_seller',
    'contract_confirmation_buyer',
    'contract_confirmation_seller',
    'invite_created',
    'negative_charge',
    'payment_error_buyer',
    'payment_error_seller',
    'payment_receipt_buyer',
    'renewal_accepted_buyer',
    'renewal_accepted_seller',
    'renewal_upcoming_buyer',
    'renewal_upcoming_seller',
    'subscription_renewed_buyer',
    'subscription_renewed_seller',
    'invoice_pdf',
    'invoice_buyer',
    'billing_method_verification_buyer',
  ];

  return emailKeys.map((key) => ({
    name: key ?? '',
    value: key ?? '',
  }));
};

const EmailHistory: React.FC = () => {
  const showToast = useToast();
  const qc = useQueryClient();

  const { mutate } = useResendEmail(
    (resent: Notification) =>
      resent.success
        ? showToast.info('Resent successfully')
        : showToast.error('Failed to send again!'),
    () => showToast.error('Failed to resend'),
    qc
  );

  const resendEmail = (emailSentId: string) => {
    mutate({ emailSentId });
  };

  const cells: QueryCell<Notification>[] = [
    {
      key: 'orgName',
      headerLabel: 'Org',
      width: '20%',
      sortable: true,
      renderCell: (row: Notification) => <div>{row.orgName}</div>,
    },
    {
      key: 'subject',
      headerLabel: 'Subject',
      width: '10%',
      sortable: true,
      renderCell: (row: Notification) => <div>{row.subject}</div>,
    },
    {
      key: 'recipients',
      headerLabel: 'Recipients',
      width: '10%',
      sortable: true,
      renderCell: (row: Notification) => <div>{row.recipients.join(', ')}</div>,
    },
    {
      key: 'emailKey',
      headerLabel: 'Email key',
      width: '10%',
      sortable: true,
      renderCell: (row: Notification) => <div>{row.emailKey}</div>,
    },
    {
      key: 'processed',
      headerLabel: 'Proccessed at',
      width: '10%',
      sortable: true,
      renderCell: (row: Notification) => (
        <div>{formatDatetime(row.processedAt)}</div>
      ),
    },
    {
      key: 'success',
      headerLabel: 'Success',
      width: '10%',
      sortable: true,
      renderCell: (row: Notification) => (
        <div>{row.success ? 'Yes' : 'No'}</div>
      ),
    },
    {
      key: 'error',
      headerLabel: 'Error',
      width: '10%',
      renderCell: (row: Notification) =>
        row.errorMessage ? (
          <NotificationErrorMessage errorMessage={row.errorMessage} />
        ) : (
          <div>-</div>
        ),
    },
    {
      key: 'resend',
      headerLabel: 'Resend',
      width: '10%',
      sortable: true,
      renderCell: (row: Notification) => (
        <div>
          <Button label="Resend" onClick={() => resendEmail(row.id)} />
        </div>
      ),
    },
  ];

  return (
    <Page>
      <QueryTable<Notification>
        cells={cells}
        fetchList={systemClient.getEmailsSent}
        filters={[
          {
            key: 'search',
            type: 'search',
            searchFields: ['orgName', 'recipients'],
          },
          {
            key: 'emailKey',
            fieldName: 'emailKey',
            type: 'dropdown',
            humanReadableName: 'Email Key',
            options: convertEmailKeysToSelectOptions(),
          },
        ]}
        placeholder="Org name or recipient"
        queryKey="systemClient.getEmailsSent"
        zeroStateMessage="There are currently no emails to display."
      />
    </Page>
  );
};

export default EmailHistory;
