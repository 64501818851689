import { Icon, SIZE_SMALL, TextArea, useToast } from 'common';
import React, { useEffect, useRef, useState } from 'react';

import styles from './TokenTextArea.module.scss';
import { TokenTextWarning } from './TokenTextWarning';

interface Props {
  displayTokenWarning?: boolean;
  id: string;
  isReadOnly?: boolean;
  onChange?: (id: string, value: string) => void;
  placeholder?: string;
  tokensWithValues: { [key: string]: string | number | undefined };
  value: string | undefined;
}

const TokenTextArea = ({
  id,
  onChange,
  value = '',
  placeholder,
  tokensWithValues,
  isReadOnly = false,
  displayTokenWarning = true,
}: Props) => {
  const showToast = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [rawText, setRawText] = useState(value);

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setRawText(value);
  }, [value]);

  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleChange = (updatedText: string) => {
    setIsEditing(true);
    setRawText(updatedText);
    onChange?.(id, updatedText);
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  const getRealValue = (token: string | number) => {
    return tokensWithValues[token] || '';
  };

  const renderParsedText = () => {
    return rawText.split(/(\[[^\]]*\])/).map((token, index) => {
      // if the token starts with '[' and ends with ']', bold it
      // hard coding for now, we can make it dynamic in the future
      if (token.startsWith('[') && token.endsWith(']')) {
        return (
          <span key={index} className={styles.tokenValue}>
            {getRealValue(token.substring(1, token.length - 1))}{' '}
          </span>
        );
      } else {
        return token;
      }
    });
  };

  const renderTokenArea = () => {
    return (
      <div className={styles.placeholdersContainer}>
        <span className={styles.placeholdersText}>Placeholders:</span>
        {Object.keys(tokensWithValues).map((token, index) => {
          return (
            <span
              className={styles.tokenDisplay}
              key={index}
              onClick={async () => {
                await navigator.clipboard.writeText(`[${token}]`);
                showToast.info('Token copied to clipboard!');
              }}
            >
              <Icon.Copy {...SIZE_SMALL} />
              {`[${token}]`}
            </span>
          );
        })}
      </div>
    );
  };

  const parsedText = renderParsedText();

  // Visual only, no interaction
  if (isReadOnly) {
    return (
      <div className={styles.readOnly}>
        <p>{parsedText}</p>
      </div>
    );
  }

  // Primary editing mode
  if (isEditing) {
    return (
      <>
        <TextArea
          placeholder={placeholder}
          ref={textAreaRef}
          id={id}
          value={rawText}
          onBlur={handleBlur}
          onChange={(v) => handleChange(v)}
        />

        {renderTokenArea()}

        {displayTokenWarning && (
          <TokenTextWarning
            text={rawText}
            tokensWithValues={tokensWithValues}
          />
        )}
      </>
    );
  }

  // Empty display mode, with interaction to edit
  if (parsedText.length === 1 && parsedText[0] === '') {
    return (
      <TextArea
        ref={textAreaRef}
        id={id}
        value={rawText}
        placeholder={placeholder}
        onChange={(v) => handleChange(v)}
        className={styles.emptyDisplay}
      />
    );
  }

  // Non-focused text filled display mode, with interaction to edit
  return (
    <>
      <div onClick={handleClick} className={styles.display}>
        <p>{parsedText}</p>
      </div>

      {renderTokenArea()}

      {displayTokenWarning && (
        <TokenTextWarning text={rawText} tokensWithValues={tokensWithValues} />
      )}
    </>
  );
};

export default TokenTextArea;
