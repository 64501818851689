import React, { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Loading,
  OrgConfig,
  PurchaseOrderConfig,
  ToggleSwitch,
  useToast,
  useTranslation,
} from 'common';
import { useQueryClient } from '@tanstack/react-query';
import {
  setOrgConfigDefault,
  useFindOrgDefault,
  useOrgDefaults,
} from '../../../services/orgDefaults';

const CONFIG_TYPE = 'purchaseOrderConfig';

const PurchaseOrderSettings: React.FC = () => {
  const queryClient = useQueryClient();

  const toast = useToast();

  const { tk } = useTranslation();

  const { data: configData, isLoading } = useFindOrgDefault(CONFIG_TYPE);

  const { data: orgDefaults } = useOrgDefaults();

  const { enabled: poCollectionEnabled } =
    (orgDefaults?.get('purchaseOrderConfig')
      ?.configValue as PurchaseOrderConfig) || false;

  const [isPoEnable, setIsPoEnable] = useState<boolean>(poCollectionEnabled!);

  const saveConfig = async (value: PurchaseOrderConfig) => {
    const val: OrgConfig['configValue'] = {
      ...value,
      configType: CONFIG_TYPE,
    };
    toast.success(`Purchase order settings saved successfully.`);
    return await setOrgConfigDefault(
      CONFIG_TYPE,
      { ...configData, configKey: CONFIG_TYPE, configValue: val },
      queryClient
    );
  };

  if (isLoading && !configData) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader name="Purchase order settings">
        <Button
          onClick={async () => {
            await saveConfig({ enabled: isPoEnable });
          }}
          label="Save"
        />
      </CardHeader>
      <div className="flex justify-between mt-6">
        <div className="w-2/5">
          {tk('Collect purchase order number on proposals.')}
        </div>
        <div>
          <ToggleSwitch
            label="Enable"
            onChange={() => setIsPoEnable(!isPoEnable)}
            value={isPoEnable}
            name="accept"
          />
        </div>
      </div>
    </Card>
  );
};

export default PurchaseOrderSettings;
