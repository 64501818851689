import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from '../../httpClients/app';
import { Currency, CurrencyListing, getMilliseconds } from 'common';

const ALL_CURRENCIES_KEY = 'allcurrency';
const ORG_CURRENCIES_KEY = 'orgcurrency';
const ORG_CURRENCIES_UPDATE = 'orgcurrenciesupdate';
const ORG_CURRENCY_UPDATE = 'orgcurrencyupdate';

export const useAllCurrencies = (
  options = {}
): UseQueryResult<CurrencyListing[]> =>
  useQuery({
    queryKey: [ALL_CURRENCIES_KEY],
    queryFn: async () => {
      const { data } = await apiClient.listAllCurrencies();
      return data;
    },
    ...options,
    // use staleTime so result gets cached across invocations
    staleTime: getMilliseconds({ hours: 3 }),
  });

const getOrgCurrencies = async () => {
  const { data } = await apiClient.listCurrencies();
  return data;
};

export const useOrgCurrencies = (options = {}): UseQueryResult<Currency[]> =>
  useQuery<Currency[]>({
    queryKey: [ORG_CURRENCIES_KEY],
    queryFn: getOrgCurrencies,
    ...options,
  });

const createOrgCurrency = async (body: Currency) => {
  const { data } = await apiClient.createCurrency(body);
  return data;
};

export const useCreateOrgCurrency = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [ORG_CURRENCIES_UPDATE],
    mutationFn: (body: Currency) => createOrgCurrency(body),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ORG_CURRENCIES_UPDATE] });
      await qc.invalidateQueries({ queryKey: [ORG_CURRENCIES_KEY] });
    },
  });

const updateOrgCurrency = async (body: Currency) => {
  const { data } = await apiClient.updateCurrency(body.code, body);
  return data;
};

export const useUpdateOrgCurrency = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [ORG_CURRENCY_UPDATE],
    mutationFn: (body: Currency) => updateOrgCurrency(body),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ORG_CURRENCY_UPDATE] });
      await qc.invalidateQueries({ queryKey: [ORG_CURRENCIES_KEY] });
    },
  });
