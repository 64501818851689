import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  AutoFormModal,
  Icon,
  isChangeProposalType,
  isTemplateProposalType,
  Item,
  PopOutMenuOption,
  Proposal,
  ProposalItemRequest,
  useFlags,
  useTranslation,
} from 'common';

interface Props {
  item: Item;
  isDisabled?: boolean;
  onUpdateItem: (item: ProposalItemRequest) => void;
  proposal: Proposal;
}

const CustomizeDateButton: React.FC<Props> = ({
  proposal,
  item,
  isDisabled,
  onUpdateItem,
}) => {
  const { tk } = useTranslation();
  const { itemStartEndDates } = useFlags();
  const [isEditingDates, setIsEditingDates] = React.useState(false);
  const queryClient = useQueryClient();
  const isOneTime = item.product.recurrence === 'one_time';
  const isRecurring = item.product.recurrence === 'recurring';

  // a one-time scheduled item may not be altered or created in a Change
  const isChange = isChangeProposalType(proposal);

  const isTemplate = isTemplateProposalType(proposal);

  if (
    (!isOneTime && !(itemStartEndDates && isRecurring)) ||
    (isChange && isOneTime) ||
    isTemplate
  ) {
    return null;
  }

  return (
    <>
      {isOneTime && (
        <AutoFormModal
          header={tk('Edit date')}
          entityName="item"
          entity={item}
          fields={[
            {
              fieldName: 'startDate',
              type: 'date',
              humanReadableName: tk('Date'),
            },
          ]}
          isOpen={isEditingDates}
          onSave={onUpdateItem}
          onSuccess={() => setIsEditingDates(false)}
          queryClient={queryClient}
          onClose={() => setIsEditingDates(false)}
        />
      )}
      {isRecurring && (
        <AutoFormModal
          header={tk('Edit dates')}
          entityName="item"
          entity={item}
          fields={[
            {
              fieldName: 'startDate',
              type: 'date',
              humanReadableName: tk('Start Date'),
            },
            {
              fieldName: 'endDate',
              type: 'date',
              humanReadableName: tk('End Date'),
            },
          ]}
          isOpen={isEditingDates}
          onSave={onUpdateItem}
          onSuccess={() => setIsEditingDates(false)}
          queryClient={queryClient}
          onClose={() => setIsEditingDates(false)}
        />
      )}

      <PopOutMenuOption
        dataTestId={`customize-start-date-${item.name}`}
        icon={Icon.Calendar}
        onClick={() => setIsEditingDates(true)}
        title={tk('Customize date')}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default CustomizeDateButton;
