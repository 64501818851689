import React from 'react';
import { accountContactsConfig, HUBSPOT_INFO } from './constants';
import { useFlags, ValidationFormField } from 'common';

import ConnectorSettings, {
  ConnectorTab,
} from '../Connectors/ConnectorSettings';
import HubspotLogoSVG from '../../../assets/integration-logos/hubspotSVG';
import { ConnectorTabs } from '../../../core-utils/enums/ConnectorTabs';

const HubspotSettings = () => {
  const tabs: ConnectorTab[] = [
    {
      key: ConnectorTabs.proposalDealSettigs,
      label: 'Deal settings',
      systemOnly: false,
    },
    {
      key: ConnectorTabs.proposalFieldMappings,
      label: 'Deal properties',
      systemOnly: false,
    },
    {
      key: ConnectorTabs.proposalStageMappings,
      label: 'Stage mapping',
      systemOnly: false,
    },
    {
      key: ConnectorTabs.accountContacts,
      label: 'Companies/Contacts',
      systemOnly: false,
    },
    {
      key: ConnectorTabs.configuration,
      label: 'Configuration',
      systemOnly: false,
    },
    { key: ConnectorTabs.events, label: 'Sync logs', systemOnly: false },
  ];

  const { syncSignedDocument: syncSignedDocumentFlag, hubspotSyncLineItems } =
    useFlags();

  const elements: ValidationFormField<any>[] = [
    {
      fieldName: 'hsAccountId',
      humanReadableName: 'HubSpot account id',
      type: 'string',
    },
  ];

  if (syncSignedDocumentFlag) {
    elements.push({
      fieldName: 'syncSignedDocument',
      humanReadableName: 'Sync signed proposal as PDF to deal',
      type: 'toggle',
    });
  }

  if (hubspotSyncLineItems) {
    elements.push({
      fieldName: 'syncLineItems',
      humanReadableName: 'Sync proposal line items to deal',
      type: 'toggle',
    });
  }

  elements.push({
    fieldName: 'ignoreQuantityForStairStep',
    humanReadableName:
      'Ignore quantity and use applied tier for unit price and discount for products with stair-step pricing',
    type: 'toggle',
    nestedKey: 'lineItemSyncConfig',
    infoText:
      'Quantity will be sent with a value of 1 and unit price and discount for each tier will be included in the line item for products with Stair-step pricing',
  });

  elements.push({
    fieldName: 'syncProducts',
    humanReadableName: 'Sync products to HubSpot line items',
    type: 'toggle',
    infoText:
      'Sync products to HubSpot which is already linked to HubSpot product library, if disabled, only product name will be synced',
  });

  return (
    <ConnectorSettings
      accountContactsConfig={accountContactsConfig}
      configuration={{
        name: null,
        lines: elements,
      }}
      connectorInfo={HUBSPOT_INFO}
      logo={HubspotLogoSVG}
      tabs={tabs}
    />
  );
};

export default HubspotSettings;
