import React from 'react';
import { Contract, Proposal, ReferenceIssue, useFlags } from 'common';
import SubscriptionSummaryCard from './SubscriptionSummaryCard';
import SubscriptionCustomerCard from './SubscriptionCustomerCard';
import CustomTermsCard from './CustomTermsCard';
import BillingDetailsCard from './BillingDetailsCard';
import RenewalsCard from './RenewalsCard';
import { ProductsCard } from './ProductsCard';
import { ProductsCardDeprecated } from './ProductsCardDeprecated';

const ContractDetailsTab: React.FC<{
  contract: Contract;
  contractProposals?: Proposal[];
  issues?: ReferenceIssue[];
}> = ({ contract, contractProposals, issues }) => {
  const { enhancedSpans } = useFlags();

  return (
    <div className="max-w-screen-md">
      <SubscriptionSummaryCard
        contract={contract}
        contractProposals={contractProposals}
      />
      <SubscriptionCustomerCard contract={contract} issues={issues} />

      <RenewalsCard
        contract={contract}
        contractProposals={contractProposals || []}
      />

      <CustomTermsCard contract={contract} />
      <BillingDetailsCard contract={contract} />

      {enhancedSpans ? (
        <ProductsCard
          contract={contract}
          contractProposals={contractProposals}
        />
      ) : (
        <ProductsCardDeprecated
          contract={contract}
          contractProposals={contractProposals}
          isSaving={false}
        />
      )}
    </div>
  );
};

export default ContractDetailsTab;
