import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const DocusignCallbackPage: React.FC = () => {
  const location = useLocation();

  const codeParam = 'code';
  const errorParam = 'error';
  const errorMsgParam = 'error_message';
  const redirectUrl = '/settings/signing';

  // TODO: use 'state' param for CSRF protection.

  const queryParams = new URLSearchParams(location.search);
  const errorValue = queryParams.get(errorParam);
  const errorMsg = queryParams.get(errorMsgParam) || '';

  let fullRedirectUrl;
  if (errorValue && errorValue.length > 0) {
    fullRedirectUrl = `${redirectUrl}?dsError=${errorValue}&dsErrorMessage=${errorMsg}`;
  } else {
    const codeValue = queryParams.get(codeParam);
    fullRedirectUrl = `${redirectUrl}?dsCode=${codeValue}`;
  }

  return <Navigate replace to={fullRedirectUrl} />;
};

export default DocusignCallbackPage;
