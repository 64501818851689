import React, { PropsWithChildren, useCallback } from 'react';

import { ProposalItemRequest, UpdateProposalItemContext } from 'common';

import { useUpdateProposalItem } from 'app/src/services/proposal';

export const UpdateProposalItemProvider = ({
  proposalId,
  children,
}: PropsWithChildren<{ proposalId: string }>) => {
  const { mutate } = useUpdateProposalItem(proposalId);

  const update = useCallback(
    (proposalItemId: string, request: ProposalItemRequest) => {
      mutate({ id: proposalItemId, body: request });
    },
    [mutate]
  );

  return (
    <UpdateProposalItemContext.Provider value={update}>
      {children}
    </UpdateProposalItemContext.Provider>
  );
};
