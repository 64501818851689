import React from 'react';
import { useSearchParams } from 'react-router-dom';
import StripeForms from './StripeForms';
import SaveStripeCode from './SaveStripeCode';

const StripeSettings = () => {
  const [searchParams] = useSearchParams();
  const stripeCode = searchParams.get('code');

  if (stripeCode) {
    return <SaveStripeCode stripeCode={stripeCode} />;
  }

  return <StripeForms />;
};

export default StripeSettings;
