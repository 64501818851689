import React from 'react';
import {
  BillingPeriodsType,
  isArrayNonEmpty,
  isDraftProposalStatus,
  Item,
  ItemSchedule,
  PricebookEntryChange,
  ProductSummary,
  ProductUpgrade,
  Proposal,
} from 'common';
import { useGetProposalUpgrades } from '../../../../services/proposal';
import Products from './Products';
import ProposalProductSelect from './ProposalProductSelect';
import styles from './ProductsSection.module.scss';

interface Props {
  ariaLabeledBy?: string;
  proposal: Proposal;
  billingPeriod: BillingPeriodsType;
  isDisabled: boolean;
  addProductHandler: (product: ProductSummary) => void;
  handleShowPriceModalClick: (
    selectedSchedule: ItemSchedule,
    item: Item
  ) => void;
}

const ProductItemEditorTab = ({
  ariaLabeledBy,
  proposal,
  isDisabled,
  billingPeriod,
  addProductHandler,
  handleShowPriceModalClick,
}: Props) => {
  const { data: upgrades } = useGetProposalUpgrades(
    proposal.id,
    isDraftProposalStatus(proposal)
  );

  const isCurrentCurrency = (entry: PricebookEntryChange) =>
    entry.currency === proposal.currency;

  const filteredUpgrades: ProductUpgrade[] = (upgrades ?? []).reduce(
    (acc: ProductUpgrade[], upgrade) => {
      if (upgrade.changedFields.includes('entries')) {
        const entries = upgrade.entryChanges?.filter(isCurrentCurrency);

        if (isArrayNonEmpty(entries)) {
          acc.push({ ...upgrade, entryChanges: entries });
        }

        return acc;
      }

      acc.push(upgrade);
      return acc;
    },
    []
  );

  return (
    <div className={styles.productsSection}>
      <Products
        ariaLabeledBy={ariaLabeledBy}
        billingPeriod={billingPeriod}
        isDisabled={isDisabled}
        onShowPriceModalClick={handleShowPriceModalClick}
        proposal={proposal}
        upgrades={filteredUpgrades}
      />

      <div className={styles.productSelect}>
        <ProposalProductSelect
          isDisabled={isDisabled}
          onAddProduct={addProductHandler}
          proposal={proposal}
        />
      </div>
    </div>
  );
};
export default ProductItemEditorTab;
