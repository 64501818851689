import React from 'react';
import SlackAddToWorkspace from './SlackAddToWorkspace';
import { Card, ErrorFetching, Loading } from 'common';
import { useFindSlackWorkspace } from '../../../services/slack';
import LogoCardHeader from '../Connectors/LogoCardHeader';
import SlackLogoSVG from '../../../assets/integration-logos/slackLogoSVG';

const SlackSettings: React.FC = () => {
  const { data, isLoading, isError } = useFindSlackWorkspace();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorFetching />;
  }

  const hasAddedAppToWorkspace = !!data;

  return (
    <Card>
      <LogoCardHeader name="Slack" logo={SlackLogoSVG} />
      <SlackAddToWorkspace hasAddedAppToWorkspace={hasAddedAppToWorkspace} />
    </Card>
  );
};

export default SlackSettings;
