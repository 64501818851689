import React from 'react';
import { Drawer, Icon } from 'common';
import SubscriptionEventList from '../SubscriptionEventList';
import ActionBarButton from '../../../../components/ActionBarButton';

interface Props {
  activityDialogOpen: boolean;
  contractId: string;
  setActivityDialogOpen: (isOpen: boolean) => void;
}

const SubscriptionActivityButton: React.FC<Props> = ({
  contractId,
  activityDialogOpen,
  setActivityDialogOpen,
}) => {
  const onClick = () => {
    setActivityDialogOpen(true);
  };

  return (
    <>
      <ActionBarButton
        dataTestId="contract-open-activity-button"
        icon={Icon.Activity}
        onClick={onClick}
        tooltip="Activity"
      />

      <Drawer
        isOpen={activityDialogOpen}
        onClose={() => setActivityDialogOpen(false)}
        header="Activity"
      >
        <SubscriptionEventList
          isOpen={activityDialogOpen}
          contractId={contractId}
        />
      </Drawer>
    </>
  );
};

export default SubscriptionActivityButton;
