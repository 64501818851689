import React, { useState } from 'react';
import Label from '../../../components/Label';
import { useSettingsPaymentMethods } from '../../../services/paymentMethods';
import PaymentMethodCard from './PaymentMethodCard';
import PaymentMethodDrawer from './PaymentMethodDrawer';
import { Button, Loading, PaymentMethod } from 'common';

import { hasValidAchDetails } from './utilities';

const PaymentMethods: React.FC = () => {
  const [formIsVisible, setFormIsVisible] = useState<boolean>(false);
  const [paymentMethodBeingEdited, setPaymentMethodBeingEdited] = useState<
    PaymentMethod | undefined
  >(undefined);

  const { data, isPending, error } = useSettingsPaymentMethods();

  const createNewCustomMethod = () => {
    setPaymentMethodBeingEdited(undefined);
    setFormIsVisible(true);
  };

  const editMethod = (m: PaymentMethod) => {
    setPaymentMethodBeingEdited(m);
    setFormIsVisible(true);
  };

  const closeForm = () => {
    setPaymentMethodBeingEdited(undefined);
    setFormIsVisible(false);
  };

  if (error) {
    return error.message;
  }

  if (isPending) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Loading />
      </div>
    );
  }

  const isValid = (m: PaymentMethod) => {
    if (m.paymentType === 'ach') {
      return hasValidAchDetails(m);
    }
    return m.configured;
  };

  return (
    <div className="max-w-[700px]">
      <div className="flex justify-between mb-6">
        <Label name="Payment methods" />{' '}
        <Button label="Add custom" onClick={createNewCustomMethod} />
      </div>
      {data.map((m) => (
        <PaymentMethodCard
          key={m.id}
          method={m}
          isDisabled={!isValid(m)}
          onModifyClick={() => editMethod(m)}
        />
      ))}
      <PaymentMethodDrawer
        isShowing={formIsVisible}
        methodBeingEdited={paymentMethodBeingEdited}
        closeDrawer={closeForm}
      />
    </div>
  );
};

export default PaymentMethods;
