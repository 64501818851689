import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AutoForm, OrgConfig, ValidationFormField } from 'common';
import {
  ORG_DEFAULTS_PATH,
  useFindOrgDefault,
} from '../../../services/orgDefaults';
import { apiClient } from '../../../services/httpClients/app';

const CONFIG_KEY: OrgConfig['configKey'] = 'proposalNumberPrefix';

const ReferenceNumberPrefix: React.FC = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useFindOrgDefault(CONFIG_KEY);
  const fields: ValidationFormField<OrgConfig>[] = [
    {
      fieldName: 'stringValue',
      humanReadableName: 'Proposal number prefix',
      infoText:
        'Set a prefix to the autogenerated reference numbers that appear on proposals (max length 3).',
      validator: (value) => (value.length <= 3 ? undefined : 'Max length is 3'),
    },
  ];
  const onSave: React.ComponentProps<typeof AutoForm<OrgConfig>>['onSave'] = (
    payload
  ) =>
    payload.stringValue
      ? apiClient.upsertOrgConfig(CONFIG_KEY, payload)
      : apiClient.deleteOrgConfig(CONFIG_KEY);

  return (
    <AutoForm
      autoSave
      entity={data}
      entityName="company"
      fields={fields}
      isDisabled={isLoading}
      onSave={onSave}
      queryClient={queryClient}
      queryKey={[ORG_DEFAULTS_PATH]}
      showControls={false}
    />
  );
};

export default ReferenceNumberPrefix;
