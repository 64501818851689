import React from 'react';

import { ConnectorInfo, OrgConfig } from 'common';
import ConnectorSettings, {
  ConnectorTab,
} from '../Connectors/ConnectorSettings';
import { ConnectorTabs } from '../../../core-utils/enums/ConnectorTabs';
import AvalaraLogoSVG from '../../../assets/integration-logos/AvalaraLogoSVG';

const CONFIG: OrgConfig['configKey'] = 'avalaraConfig';
const SOURCE: ConnectorInfo['source'] = 'avalara';

const AVALARA_INFO = {
  configType: CONFIG,
  source: SOURCE,
  name: 'Avalara',
  showSaveButton: true,
};

const AvalaraSettings = () => {
  const tabs: ConnectorTab[] = [
    {
      key: ConnectorTabs.configuration,
      label: 'Configuration',
      systemOnly: false,
    },
    { key: ConnectorTabs.events, label: 'Sync logs', systemOnly: false },
  ];

  return (
    <ConnectorSettings
      configuration={{
        toggleSwitchForEnablement: true,
        name: 'taxConfig',
        lines: [
          {
            fieldName: 'licenseKey',
            humanReadableName: 'License key',
            type: 'password',
            isRequired: true,
          },
          {
            fieldName: 'accountId',
            humanReadableName: 'Account id',
            type: 'string',
            isRequired: true,
          },
          {
            fieldName: 'companyCode',
            humanReadableName: 'Company code',
            type: 'string',
            isRequired: true,
          },
          {
            fieldName: 'commitDocuments',
            humanReadableName: 'Commit documents',
            type: 'toggle',
          },
          {
            fieldName: 'enableLogging',
            humanReadableName: 'Enable logging',
            type: 'toggle',
          },
        ],
      }}
      accountContactsConfig={{
        integration: 'Avalara',
        description: 'The Avalara connector.',
        cacheflowToIntegrationSection: [],
        integrationToCacheflowSection: [],
      }}
      connectorInfo={AVALARA_INFO}
      tabs={tabs}
      logo={AvalaraLogoSVG}
      isAvalaraSaveButtonDisabled
    />
  );
};

export default AvalaraSettings;
