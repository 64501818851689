import { parseISO } from 'date-fns';
import { Invoice, InvoiceSummary } from 'common';

export const getStatus = (
  invoice: InvoiceSummary | Invoice | undefined,
  dueDate: string | undefined
): string => {
  if (invoice === undefined) {
    return '';
  }

  if (invoice.status !== 'open') {
    return invoice.status || '';
  }

  return (invoice.balance || 0) > 0 &&
    dueDate &&
    parseISO(dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    ? 'past_due'
    : 'open';
};
