import React from 'react';
import {
  BILLING_PERIODS,
  BillingPeriodsType,
  BundlePriceEntry,
  formatMoneyOrDefault,
  FormField,
  getBillingPeriodPriceLabel,
  getEntryRequest,
  InfoToolTip,
  Money,
  MoneyInput,
  Nullable,
  ProductRequest,
  ToggleSwitch,
  useFlags,
} from 'common';
import { isBillingPeriodEnabled } from './productUtils';
import styles from './PricingConfiguration.module.scss';

interface Props {
  bottomUpEntries?: BundlePriceEntry[];
  currency: string;
  isDisabled: boolean;
  orgEnabledBillingPeriods: BillingPeriodsType[];
  productDraft: Nullable<ProductRequest>;
  toggleBillingPeriod: (billingPeriod: BillingPeriodsType) => void;
  updatePeriodPrice: (price: Money, billingPeriod: BillingPeriodsType) => void;
  updatePrice: (price: Money) => void;
}

export const PricingConfigurationTableFixed: React.FC<Props> = ({
  bottomUpEntries,
  isDisabled,
  productDraft,
  currency,
  updatePrice,
  updatePeriodPrice,
  toggleBillingPeriod,
  orgEnabledBillingPeriods,
}: Props) => {
  const { entries } = productDraft;

  const hasExtendedDecimals = productDraft.extendedScale || false;

  const oneTime: boolean = productDraft.recurrence === 'one_time';
  const labelClassName: string = 'text-slate-darkest';

  const oneTimeBottomUpPrice = getEntryRequest(
    bottomUpEntries,
    currency
  )?.price;

  const { negativePriceSupport } = useFlags();

  return (
    <div className={styles.grid}>
      {oneTime && (
        <FormField label="Unit price" labelClassName={labelClassName}>
          <MoneyInput
            dataTestId="one-time-price"
            defaultCurrency={currency}
            hasExtendedDecimals={hasExtendedDecimals}
            isDisabled={isDisabled}
            isRequiredField
            negativeSupported={negativePriceSupport}
            onChange={(price) => updatePrice(price)}
            value={getEntryRequest(entries, currency)?.price}
          />
          {oneTimeBottomUpPrice && (
            <div>{formatMoneyOrDefault(oneTimeBottomUpPrice)}</div>
          )}
        </FormField>
      )}
      {!oneTime && (
        <>
          {BILLING_PERIODS.map((billingPeriod) => {
            const periodEnabled = isBillingPeriodEnabled(
              productDraft,
              billingPeriod
            );
            const isOrgEnabled =
              orgEnabledBillingPeriods.includes(billingPeriod);
            const periodToggle = (
              <div className={styles.billingPeriodToggle}>
                {isOrgEnabled && (
                  <InfoToolTip title="This billing period is active on your organization. Disabling it will prevent this product from being added to proposals with default billing period settings." />
                )}
                <ToggleSwitch
                  key={`product-period-toggle-${billingPeriod}`}
                  name={`product-period-toggle-${billingPeriod}`}
                  onChange={() => toggleBillingPeriod(billingPeriod)}
                  value={!!periodEnabled}
                />
              </div>
            );
            const bottomUpPrice = getEntryRequest(
              bottomUpEntries,
              currency,
              billingPeriod
            )?.price;

            return (
              <div key={`product-pricebox-${billingPeriod}`}>
                <FormField
                  key={`product-price-${billingPeriod}`}
                  label={getBillingPeriodPriceLabel(billingPeriod)}
                  labelChild={periodToggle}
                  labelClassName={labelClassName}
                >
                  <MoneyInput
                    dataTestId={`product-price-input-${billingPeriod}-${currency}`}
                    defaultCurrency={currency}
                    hasExtendedDecimals={hasExtendedDecimals}
                    isDisabled={!periodEnabled}
                    isRequiredField={!!periodEnabled}
                    negativeSupported={negativePriceSupport}
                    onChange={(price) =>
                      updatePeriodPrice(price, billingPeriod)
                    }
                    value={
                      getEntryRequest(entries, currency, billingPeriod)?.price
                    }
                  />
                  {bottomUpPrice && (
                    <div>{formatMoneyOrDefault(bottomUpPrice)}</div>
                  )}
                </FormField>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
