import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import {
  getErrorStatus,
  SlackAppInfo,
  SlackUser,
  SlackWorkspace,
} from 'common';
import {
  SLACK_CALLBACK_USER,
  SLACK_CALLBACK_WORKSPACE,
} from '../core-utils/routes';

const SLACK_USER_KEY = 'slackUser';
const SLACK_WORKSPACE_KEY = 'slackWorkspace';
const SLACK_APP_INFO_KEY = 'slackAppInfo';

export const getSlackAppAuthRedirectUri = () =>
  `${window.location.protocol}//${window.location.host}${SLACK_CALLBACK_WORKSPACE}`;

export const getSlackUserAuthRedirectUri = () =>
  `${window.location.protocol}//${window.location.host}${SLACK_CALLBACK_USER}`;

export const useFindCurrentSlackUser = (): UseQueryResult<SlackUser | null> =>
  useQuery({
    queryKey: [SLACK_USER_KEY],
    queryFn: async () => {
      try {
        const { data } = await apiClient.getCurrentSlackUser();
        return data as SlackUser;
      } catch (err: unknown) {
        if (getErrorStatus(err) === 404) {
          return null;
        } else {
          throw err;
        }
      }
    },
  });

export const useCreateSlackUser = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [SLACK_USER_KEY],
    mutationFn: async (params: any) => {
      const { data } = await apiClient.createSlackUser(params);
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [SLACK_USER_KEY] });
    },
  });

export const useDeleteCurrentSlackWorkspace = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [SLACK_WORKSPACE_KEY],
    mutationFn: async () => {
      const { data } = await apiClient.deleteCurrentSlackWorkspace();
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await Promise.all([
        qc.invalidateQueries({ queryKey: [SLACK_WORKSPACE_KEY] }),
        qc.invalidateQueries({ queryKey: [SLACK_USER_KEY] }),
      ]);
    },
  });

export const useDeleteCurrentSlackUser = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [SLACK_USER_KEY],
    mutationFn: async () => {
      const { data } = await apiClient.deleteCurrentSlackUser();
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [SLACK_USER_KEY] });
    },
  });

export const useGetSlackAppInfo = (): UseQueryResult<SlackAppInfo> =>
  useQuery<SlackAppInfo>({
    queryKey: [SLACK_APP_INFO_KEY],
    queryFn: async () => {
      const { data } = await apiClient.getSlackAppId();
      return data;
    },
  });

export const useFindSlackWorkspace =
  (): UseQueryResult<SlackWorkspace | null> =>
    useQuery({
      queryKey: [SLACK_WORKSPACE_KEY],
      queryFn: async () => {
        try {
          const { data } = await apiClient.getCurrentSlackWorkspace();
          return data;
        } catch (err) {
          if (getErrorStatus(err) === 404) {
            return null;
          } else {
            throw err;
          }
        }
      },
    });

export const useCreateSlackWorkspace = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [SLACK_WORKSPACE_KEY],
    mutationFn: createSlackWorkspace,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [SLACK_WORKSPACE_KEY] });
    },
  });

const createSlackWorkspace = async (params: any) => {
  const { data } = await apiClient.createSlackWorkspace(params);
  return data;
};
