import React, { ComponentProps } from 'react';
import {
  Button,
  ButtonBar,
  getErrorCode,
  getErrorMessage,
  Modal,
  Type,
} from 'common';
import { CopyProposalStrategy } from 'app/src/services/proposal';
import styles from './CopyErrorModal.module.scss';

interface Props {
  error: unknown;
  handleClose: () => void;
  handleTryWithStrategy: (strategy: CopyProposalStrategy) => void;
}

const CopyErrorModal: React.FC<Props> = ({
  error,
  handleTryWithStrategy,
  handleClose,
}: Props) => {
  if (!error) {
    return <Modal isOpen={false} />;
  }

  const errorCode = getErrorCode(error);
  const errorMessage = getErrorMessage(error);
  const sharedProps: ComponentProps<typeof Modal> = {
    isOpen: true,
    onClose: handleClose,
    footer: (
      <ButtonBar>
        <Button type="secondary" label="Close" onClick={handleClose} />
      </ButtonBar>
    ),
  };

  const skipInactive = (
    <Button
      block
      isDangerous
      label="Remove updated and inactive products"
      onClick={() => handleTryWithStrategy('skipInactiveProducts')}
      type="secondary"
    />
  );

  if (errorCode === 'CANT_USE_NON_ACTIVE_PRODUCTS') {
    return (
      <Modal
        {...sharedProps}
        header="Some products on this proposal are updated or inactive"
      >
        <div className={styles.bigButtons}>
          {skipInactive}
          <Button
            block
            label="Use latest version of products"
            onClick={() => handleTryWithStrategy('upgradeInactiveProducts')}
          />
        </div>
      </Modal>
    );
  }

  if (errorCode === 'CANT_UPGRADE_PRODUCTS') {
    return (
      <Modal
        {...sharedProps}
        header="Failed to find active versions of all products"
      >
        {skipInactive}
      </Modal>
    );
  }

  return (
    <Modal {...sharedProps} header="Proposal copy failed">
      <Type>{errorMessage ?? errorCode ?? 'Unexpected error'}</Type>
    </Modal>
  );
};

export default CopyErrorModal;
