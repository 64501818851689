import React, { useState } from 'react';
import { Editor } from '@tiptap/react';
import Link from '@tiptap/extension-link';
import EditorButton from '../EditorButton';
import { EditorFeature } from '../editorCommon';
import AddLinkModal from '../AddLinkModal';
import { EditorIconLink, EditorIconUnlink } from '../editorIcons';

const LinkButtons: React.FC<{ editor: Editor }> = ({ editor }) => {
  const empty = { isOpen: false, text: '', url: '' };
  const [linkBeingAddedData, setLinkBeingAddedData] = useState<{
    isOpen: boolean;
    text: string;
    url: string;
  }>(empty);

  const editorState = editor.view.state;
  const textSelection = editorState.selection;

  const openAddLinkModal = (): void => {
    const { from, to } = textSelection;
    const currentlySelectedText = editorState.doc.textBetween(from, to, ' ');
    const previousUrl = editor.getAttributes('link').href ?? '';

    setLinkBeingAddedData({
      isOpen: true,
      text: currentlySelectedText,
      url: previousUrl,
    });
  };

  const addLink = (linkText: string, url: string): void => {
    editor.commands.deleteRange(textSelection);
    editor.commands.insertContent(`<a href="${url}">${linkText}</a>`);
    setLinkBeingAddedData(empty);
  };

  const cancelAddLink = () => {
    setLinkBeingAddedData(empty);
  };

  return (
    <>
      <AddLinkModal
        draft={{ url: linkBeingAddedData.url, text: linkBeingAddedData.text }}
        isOpen={linkBeingAddedData.isOpen}
        onClose={cancelAddLink}
        onAddLink={addLink}
      />
      <EditorButton
        isSelected={editor.isActive('link')}
        label="Add Link"
        onClick={openAddLinkModal}
        icon={EditorIconLink}
      />
      <EditorButton
        label="Remove Link"
        onClick={() => editor.chain().focus().unsetLink().run()}
        icon={EditorIconUnlink}
      />
    </>
  );
};

const linkFeature: EditorFeature = {
  contextButtons: (editor) => <LinkButtons editor={editor} />,
  extensions: [
    Link.configure({
      autolink: false,
    }),
  ],
  key: 'link',
};

export default linkFeature;
