import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PROPOSALS, SYSTEM_DASHBOARD_ROUTE } from '../../core-utils/routes';
import useManageDocumentTitle from '../../useManageDocumentTitle';
import useManageScrolling from '../../useManageScrolling';
import { RouteDef } from './Routes';
import {
  isActionAllowed,
  isAuthorized,
  isAuthorizedInRoles,
  useCanAccessSystemAdminRoutes,
} from '../../core-utils/helperFunctions/userServiceHelper';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';
import { formatPath, trimRouteSubRoutes, trimSubRoutes } from './routeUtils';

const DefaultContentComponent = () => {
  if (useCanAccessSystemAdminRoutes()) {
    return <Navigate replace to={SYSTEM_DASHBOARD_ROUTE} />;
  }
  return <Navigate replace to={PROPOSALS} />;
};

interface Props {
  parentRoute?: RouteDef;
  routes: RouteDef[];
}

const RouteWithStuff = ({ children }: { children: React.ReactNode }) => {
  useManageDocumentTitle();
  useManageScrolling();
  return children;
};

const RenderRoutes = ({ routes, parentRoute }: Props) => {
  const authorizedRoutes = routes
    .filter((route: RouteDef) => {
      if (route.action) {
        return isActionAllowed(route.authKey!, route.action);
      }
      return route.roles
        ? isAuthorizedInRoles(route.roles)
        : isAuthorized(route.authKey);
    })
    .filter((route: RouteDef) => {
      // Prevents specific opt-in routes from being renderable in prod
      return !(
        route.excludedInProduction === true && ENVIRONMENT_CONFIG.isProduction
      );
    });
  const trimmedRoutes = parentRoute
    ? trimSubRoutes(parentRoute, authorizedRoutes)
    : authorizedRoutes;

  return (
    <Routes>
      {trimmedRoutes.map((route: RouteDef) => (
        <Route
          key={route.path}
          path={formatPath(route)}
          element={
            <RouteWithStuff>
              <route.component routes={trimRouteSubRoutes(route) || []} />
            </RouteWithStuff>
          }
        />
      ))}
      <Route path="*" element={<DefaultContentComponent />} />
    </Routes>
  );
};

export default RenderRoutes;
