import { ConnectorTabs } from '../../../../core-utils/enums/ConnectorTabs';
import { ConnectorTab } from '../../Connectors/ConnectorSettings';

export const closeTabs: ConnectorTab[] = [
  {
    key: ConnectorTabs.closeConfiguration,
    label: 'Configuration',
    systemOnly: false,
  },
  {
    key: ConnectorTabs.opportunities,
    label: 'Opportunities',
    systemOnly: false,
  },
  {
    key: ConnectorTabs.accountContacts,
    label: 'Accounts/Contacts',
    systemOnly: false,
  },
  { key: ConnectorTabs.events, label: 'Sync logs', systemOnly: false },
];
