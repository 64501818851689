import React from 'react';

import { Icon } from 'common';

import styles from './TokenTextWarning.module.scss';

interface Props {
  text: string | undefined;
  tokensWithValues: { [key: string]: string | number | undefined };
}

const findMissingTokens = (
  rawText: string | undefined,
  tokensObject: { [key: string]: string | number | undefined }
): string[] => {
  if (!rawText && Object.keys(tokensObject).length === 0) return [];

  const tokens = Object.keys(tokensObject);
  const missingTokens = tokens.filter((token) =>
    rawText ? !rawText.includes(`[${token}]`) : true
  );

  return missingTokens;
};

export const TokenTextWarning = ({ text, tokensWithValues }: Props) => {
  const missingTokens = findMissingTokens(text, tokensWithValues);

  if (missingTokens.length === 0) {
    return null;
  }

  return (
    <div className={styles.tokenTextWarning}>
      <p>
        The following are missing from your clause. Considering adding them so
        that your buyers know what is in the auto-renewal.
      </p>
      <ul className={styles.list}>
        {missingTokens.map((token) => (
          <div className={styles.listItem} key={token}>
            <Icon.Alert width={32} height={32} color="var(--orange)" />
            <li key={token}>{`[${token}]`}</li>
          </div>
        ))}
      </ul>
    </div>
  );
};
