import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useCopyClipboard } from '../../../core-utils/commonHooks/copyClipboard';
import {
  ApiToken,
  Button,
  Cell,
  ConfirmDeleteModal,
  DefaultZeroState,
  Icon,
  SimpleTable,
  useToast,
  useTranslation,
} from 'common';
import { useAPIToken, useDeleteAPIToken } from '../../../services/apiToken';

const APITokens: React.FC = () => {
  const { tk } = useTranslation();
  const showToast = useToast();
  const queryClient = useQueryClient();
  const { handleCopy } = useCopyClipboard();

  const [idOfTokenBeingDeleted, setIdOfTokenBeingDeleted] = useState<
    string | null
  >(null);

  const {
    data: tokens,
    isLoading,
    isError: isErrorLoadingTokens,
  } = useAPIToken();

  const { mutate: deleteToken } = useDeleteAPIToken(
    () => {
      showToast.info(tk('Successfully deleted API token'));
      setIdOfTokenBeingDeleted(null);
    },
    () => showToast.error(tk('Could not create API token')),
    queryClient
  );

  const handleCopyClick = async (id: string) => {
    await handleCopy(id);
    showToast.info('API token successfully copied to your clipboard.');
  };

  const APICells: Cell<ApiToken>[] = [
    {
      key: 'name',
      width: '30%',
      headerLabel: 'Token name',
      renderCell: (data: ApiToken) => <div>{data.name}</div>,
    },
    {
      key: 'token',
      width: '30%',
      headerLabel: 'Token',
      renderCell: (data: ApiToken) => (
        <div className="flex flex-row justify-end">
          ...{data.token?.substring(data.token.length - 20)}
          <button
            type="button"
            onClick={async () => {
              await handleCopyClick(data.token!);
            }}
          >
            <Icon.Copy width={25} height={25} className="ml-2" />
          </button>
        </div>
      ),
    },
    {
      key: 'createdAt',
      width: '20%',
      headerLabel: 'Created at',
      renderCell: (token: ApiToken) => token.createdAt?.split('T')[0],
    },
    {
      key: 'delete',
      width: '20%',
      renderCell: (token: ApiToken) => (
        <Button
          isDangerous
          type="link"
          label="Delete"
          className="h-8 !py-1.5"
          onClick={() => setIdOfTokenBeingDeleted(token.id!)}
        />
      ),
    },
  ];

  if (isErrorLoadingTokens) {
    return <div>{tk('There was an error loading tokens')}</div>;
  }

  return (
    <div className="connector-configuration">
      <section className="bottom">
        <SimpleTable
          disableSearch
          isLoading={isLoading}
          cells={APICells}
          rows={tokens}
          zeroState={<DefaultZeroState message="No API tokens available." />}
          numberOfSkeletonRows={3}
        />
        <ConfirmDeleteModal
          isOpen={!!idOfTokenBeingDeleted}
          onConfirm={() => deleteToken(idOfTokenBeingDeleted!)}
          onClose={() => setIdOfTokenBeingDeleted(null)}
          typeName="API token"
        />
      </section>
    </div>
  );
};

export default APITokens;
