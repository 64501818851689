import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import {
  BillingSchedule,
  Cell,
  ConfirmModal,
  Contract,
  contractHasInvoiceInAdvanceEnabled,
  ErrorToolTip,
  formatCurrencyOrDefault,
  formatDateOrDefault,
  formatNullableFormattedAmount,
  getDatetime,
  getErrorMessage,
  getIconCell,
  Icon,
  isDefined,
  LinkButton,
  NavigationIcon,
  PopOutMenuOption,
  pushIf,
  ReferenceIssue,
  Select,
  SimpleCell,
  SimpleTable,
  TaxCountryRulesConfig,
  TransferEvent,
  ToolTip,
  useFlags,
  useToast,
  useTranslation,
} from 'common';

import TransferOptionsButton from 'app/src/components/Transfer/TransferOptionsButton';
import PaymentInfo from 'app/src/components/Transfer/PaymentInfo';
import PaymentItemAccordion, {
  ScheduleAndBillingItem,
} from './PaymentItemAccordion';
import { usePostTransferEvent } from '../../../../services/transfer';

import {
  getPaymentStatusOnlyValue,
  isCancellable,
  isOverdue,
  isPosted,
  isRetryable,
  paymentFailed,
} from 'app/src/components/Transfer/utils';
import TriggerProcess from 'app/src/components/Transfer/TriggerProcess';
import { isContractManaged } from '../../contractUtils';

import {
  fixedTotal,
  getPaidAmount,
  getProductIdsForContractBilling,
  getUnbilledAmount,
  isUsageBasedContract,
  usageTotal,
} from '../../../BillingSchedules/billingUtilities';
import BillingScheduleForm from '../../../BillingSchedules/BillingScheduleForm';
import {
  FLAG_TYPES,
  isActionAllowed,
  isAuthorizedInRoles,
  useCurrentUser,
  useIsCurrentUserAdmin,
  useIsSuperAdmin,
} from '../../../../core-utils/helperFunctions/userServiceHelper';
import { getDetailLink } from 'app/src/services/routes/routeUtils';
import TransactionDialog from '../../../Transactions/TransactionDialog';
import PaymentForm from 'app/src/components/Transfer/PaymentForm';
import { getPaymentMethodDisplay } from '../hook';
import { useBatchProducts } from '../../../../services/api/products/products';
import { ENVIRONMENT_CONFIG } from '../../../../../config/hosts';
import { PaymentDetails } from './PaymentDetails';
import { PaymentUpdateForm } from './PaymentUpdateForm/PaymentUpdateForm';
import PaymentMethodForm from './PaymentMethodForm';
import {
  useBillingSchedules,
  useGenerateInvoice,
  useSyncInvoice,
  useUpdateBillingSchedule,
} from '../../../../services/contract';
import TriggerUpdateTax from './TriggerUpdateTax';
import { GracePeriodTooltip } from './GracePeriodTooltip/GracePeriodTooltip';
import { useFindOrgDefault } from '../../../../services/orgDefaults';
import PaymentInfoSection from './PaymentInfoSection';
import ExternalLink from 'app/src/components/Sync/ExternalLink';

interface Props {
  contract: Contract;
  issues?: ReferenceIssue[];
}

export type PaymentItemProps = {
  id: string;
  label: string;
  value?: React.ReactNode;
  type?: string;
  isDisabled?: boolean;
  onClick?: () => void;
};

export enum BillingScheduleDisplayType {
  viewPaymentSchedule,
  viewBillingScheduleByItem,
}

const PaymentList: React.FC<Props> = ({ contract, issues }: Props) => {
  const queryClient = useQueryClient();
  const [selectedBillingSchedule, setSelectedBillingSchedule] = useState<
    BillingSchedule | undefined
  >(undefined);
  const [selectedScheduleId, setSelectedScheduleId] = useState<
    string | undefined
  >(undefined);
  const [isUsageModalOpen, setIsUsageModalOpen] = useState<boolean>(false);
  const { quickbooks, taxyTurby, invoices, billingScheduleRevamp } = useFlags();
  const showToast = useToast();
  const user = useCurrentUser();
  const [editingPaymentMethod, setEditingPaymentMethod] =
    useState<boolean>(false);
  const { tk } = useTranslation();
  const [billingScheduleEditing, setBillingScheduleEditing] = useState<
    BillingSchedule | undefined
  >(undefined);

  const canUpdate = isActionAllowed(FLAG_TYPES.TRANSFER, 'update');
  const canUpdateSub = isActionAllowed(FLAG_TYPES.CONTRACT, 'update');
  const [transactionDialogState, setTransactionDialogState] = useState(false);
  const editable = isAuthorizedInRoles(['admin']);
  const [transactionId, setTransactionId] = useState<string | undefined>();
  const [paymentDetailsToShow, setPaymentDetailsToShow] = useState<
    BillingSchedule | undefined
  >();

  const {
    data: billingSchedules,
    isLoading: isLoadingBillingSchedules,
    refetch,
  } = useBillingSchedules(contract.id);

  const [selectedPayment, setSelectedPayment] = useState<
    BillingSchedule | undefined
  >(undefined);
  const isSuperAdmin = useIsSuperAdmin();
  const showPaymentDetails = isSuperAdmin || !ENVIRONMENT_CONFIG.isProduction;
  const collectedAmount = getPaidAmount(billingSchedules);
  const unbilledAmount = getUnbilledAmount(billingSchedules);
  const isAdminRole = useIsCurrentUserAdmin();
  const navigate = useNavigate();
  const { data: taxCountryRulesConfigData } = useFindOrgDefault(
    'taxCountryRulesConfig'
  );

  const taxCountryRulesConfig: TaxCountryRulesConfig | undefined =
    taxCountryRulesConfigData?.configValue as TaxCountryRulesConfig | undefined;

  const shouldCalculateTax = () => {
    if (taxCountryRulesConfig?.enabled) {
      if (
        taxCountryRulesConfig.includedCountriesIso &&
        contract.buyer.billingAddress?.countryIso
      ) {
        // Check if the countryIso is included in includedCountriesIso
        return taxCountryRulesConfig.includedCountriesIso.includes(
          contract.buyer.billingAddress.countryIso
        );
      }
    }
    // If any of the above checks fail, return true by default
    return true;
  };

  const handleSuccess = async (message: string) => {
    showToast.success(message);
    await refetch();
  };

  const handleError = (message: string, error: unknown) => {
    const msg = getErrorMessage(error);
    showToast.error(`${message} : ${msg}`);
  };

  const { mutate: syncInvoice, isPending } = useSyncInvoice(
    contract.id,
    () => handleSuccess('Invoice sync completed'),
    (error: unknown) => handleError('Invoice sync failed', error),
    queryClient
  );

  const { mutate: updateBilling } = useUpdateBillingSchedule(
    contract.id,
    () => handleSuccess('Payment updated'),
    (error: unknown) => handleError('Payment update failed', error),
    queryClient
  );

  const { mutate: generateInvoice } = useGenerateInvoice(
    contract.id,
    () => handleSuccess('Invoice generation completed'),
    (error: unknown) => handleError('Invoice generation failed', error),
    queryClient
  );

  const { mutate: postEvent } = usePostTransferEvent(
    () => {
      showToast.success('Payment cancelled');
    },
    (error: unknown) => {
      const msg = getErrorMessage(error);
      showToast.error(`Cancel payment failed: ${msg}`);
    },
    queryClient
  );

  const canGenerateInvoice = (schedule: BillingSchedule): boolean => {
    if (!billingSchedules) {
      return false;
    }

    const scheduleIndex = billingSchedules.findIndex(
      (item) => item.id === schedule.id
    );
    const nextScheduleIndex = getNextInvoiceIndex();

    return scheduleIndex === nextScheduleIndex;
  };

  const getStatus = () => {
    if (contract.billingMethod) {
      if (contract.billingMethod.status === 'external') {
        return 'Managed externally';
      }
      return contract.billingMethod.status;
    }
    return '';
  };

  const [billingScheduleDisplayType, setBillingScheduleDisplayType] =
    useState<BillingScheduleDisplayType>(
      BillingScheduleDisplayType.viewPaymentSchedule
    );

  const detailLink = getDetailLink('contract', contract.id);

  const productIds = getProductIdsForContractBilling(contract);

  const { data: products } = useBatchProducts(
    queryClient,
    productIds,
    productIds.length > 0
  );

  const requiresInvoiceIssueDateColumn = billingSchedules?.some(
    (billingSchedule) =>
      billingSchedule.scheduleDate !== billingSchedule.issueDate
  );

  const getNextInvoiceIndex = () => {
    const lastIndex =
      billingSchedules?.findLastIndex(
        (schedule) => schedule.invoiceId || schedule.externalInvoiced
      ) ?? -1;

    return lastIndex + 1;
  };

  const handleTransferCancel = (payment: BillingSchedule) => {
    const { transfer } = payment;
    const transferId = transfer?.id;

    if (!transfer || !transferId) {
      return;
    }

    const currency = payment.currency || transfer.currency;
    const transferEvent: TransferEvent = {
      amount: 0,
      currency,
      processedAt: getDatetime(),
      reason: `${user!.firstName} ${user!.lastName} cancelled payment.`,
      processor: 'manual',
      status: 'cancelled',
    };

    postEvent({ transferId, transferEvent });
  };

  const alertToolTip = (id: string) => {
    const issueTexts = issues
      ? issues
          .filter(
            (i) =>
              i.referenceKey?.referenceType === 'customer' ||
              i.referenceKey?.referenceId === id
          )
          .map((i) => tk(`issues.${i.sourceType}.${i.issueType}`))
      : null;
    return issueTexts && issueTexts.length > 0 ? (
      <ErrorToolTip title={issueTexts.join('<br />')} />
    ) : null;
  };

  // filter schedules of each billing item
  const itemIds = billingSchedules?.flatMap((schedule) =>
    schedule.billingItems.map((item) => item.itemId)
  );
  const uniqueItemIds = new Set(itemIds);

  const scheduleAndBillingItems: ScheduleAndBillingItem[][] = [...uniqueItemIds]
    .map((itemId) => {
      const schdulesOfBillingItem = billingSchedules
        ?.map((schedule) => {
          const billingItem = schedule.billingItems.find(
            (item) => itemId === item.itemId
          );
          if (billingItem === undefined) {
            return undefined;
          }
          return { schedule, billingItem };
        })
        .filter(isDefined);
      return schdulesOfBillingItem;
    })
    .filter(isDefined);

  const getNonExternalOptions = (billingSchedule: BillingSchedule) => {
    const { invoiceId } = billingSchedule;

    const showCancelOption =
      // Remove this line once mark as paid is live for all
      isAdminRole && isCancellable(billingSchedule.transferStatus ?? '');

    const invoiceVoided = billingSchedule.invoiceStatus === 'voided';

    function handleUsageBasedProduct(id: string) {
      setIsUsageModalOpen(true);
      setSelectedScheduleId(id);
    }

    const cells: React.ReactNode[] = [
      ...pushIf(
        invoices && !billingSchedule.externalInvoiced,
        <PopOutMenuOption
          icon={Icon.Document}
          isDisabled={!canGenerateInvoice(billingSchedule)}
          key="generate-invoice"
          onClick={() => {
            isUsageBasedContract(contract)
              ? handleUsageBasedProduct(billingSchedule.id)
              : generateInvoice(billingSchedule.id);
          }}
          title="Generate invoice"
        />
      ),
      ...pushIf(
        billingSchedule.usageEntryActive && editable,
        <PopOutMenuOption
          icon={Icon.Quantity}
          isDisabled={invoiceVoided}
          key="update-usage"
          onClick={() => setSelectedBillingSchedule(billingSchedule)}
          title="Update usage"
        />
      ),
      ...pushIf(
        showPaymentDetails && !billingSchedule.externalInvoiced,
        <PopOutMenuOption
          icon={Icon.TriangleDown}
          isDisabled={invoiceVoided}
          key="product-charges"
          onClick={() => setPaymentDetailsToShow(billingSchedule)}
          title="Product/charge details"
        />
      ),
      ...pushIf(
        quickbooks && !billingSchedule.externalInvoiced,
        <PopOutMenuOption
          icon={Icon.Repeat}
          isDisabled={!billingSchedule.invoiceId || invoiceVoided}
          key="sync-erp"
          onClick={() => syncInvoice(billingSchedule.id)}
          title="Sync invoice/payment to ERP"
        />
      ),
      ...pushIf(
        !!(invoices && invoiceId) && !billingSchedule.externalInvoiced,
        <PopOutMenuOption
          icon={NavigationIcon.Agreements}
          key="view-invoice"
          onClick={() => {
            const invoiceDetailLink = getDetailLink('invoice', invoiceId!);
            invoiceDetailLink && navigate(invoiceDetailLink);
          }}
          title="View invoice"
        />
      ),
      <PopOutMenuOption
        icon={Icon.TriangleUp}
        isDisabled={invoiceVoided}
        key="update-payment"
        onClick={() => setSelectedPayment(billingSchedule)}
        title="Update payment"
      />,
      ...pushIf(
        showCancelOption && !billingSchedule.externalInvoiced,
        <PopOutMenuOption
          icon={Icon.TriangleUp}
          key="cancel-payment"
          onClick={() => handleTransferCancel(billingSchedule)}
          title="Cancel payment processing"
        />
      ),
      ...pushIf(
        !invoiceVoided &&
          taxyTurby &&
          shouldCalculateTax() &&
          !billingSchedule.externalInvoiced,
        <TriggerUpdateTax
          key="trigger-update-tax"
          scheduleId={billingSchedule.id}
        />
      ),
      ...pushIf(
        !!(
          isAdminRole &&
          isContractManaged(contract) &&
          billingSchedule.transfer?.id &&
          isRetryable(billingSchedule.transfer.status || '') &&
          !billingSchedule.externalInvoiced
        ),
        <TriggerProcess
          detailLink={detailLink!}
          key="trigger-payment"
          transferId={billingSchedule.transfer?.id || ''}
        />
      ),
      ...pushIf(
        isSuperAdmin,
        <PopOutMenuOption
          icon={Icon.Document}
          isDisabled={!!billingSchedule.invoiceId}
          isSuper
          key="mark-as-invoiced"
          onClick={() =>
            updateBilling({
              id: billingSchedule.id,
              request: {
                externalInvoiced: true,
              },
            })
          }
          title="Mark as invoiced"
        />
      ),
      ...pushIf(
        isSuperAdmin && billingSchedule.externalInvoiced,
        <PopOutMenuOption
          icon={Icon.Document}
          isDisabled={!editable}
          isSuper
          onClick={() => {
            updateBilling({
              id: billingSchedule.id,
              request: {
                externalInvoiced: false,
              },
            });
          }}
          title="Unmark as invoiced"
        />
      ),
    ];

    return cells;
  };

  const getTextWithEllipsis = (text: string, size: number) => {
    if (text.length > size) {
      return text.slice(0, size) + '...';
    }
    return text;
  };

  const getPopoutMenuOptions = (billingSchedule: BillingSchedule) => {
    return (
      <TransferOptionsButton
        externalInvoiced={billingSchedule.externalInvoiced}
        onClickEvent={() => {
          setBillingScheduleEditing(billingSchedule);
        }}
        onClickViewActivity={() => {
          setTransactionId(billingSchedule.transfer?.id);
          setTransactionDialogState(true);
        }}
        referenceId={billingSchedule.id}
        referenceType="billingschedule"
        transfer={billingSchedule.transfer}
      >
        {getNonExternalOptions(billingSchedule)}
      </TransferOptionsButton>
    );
  };

  const cells: SimpleCell<BillingSchedule>[] = [
    getIconCell(null, {
      renderCell: (data: BillingSchedule) => {
        const statusVal = getPaymentStatusOnlyValue(data, contract) || '';
        if (isPosted(statusVal) || data.externalInvoiced) {
          return <Icon.Checkmark className="text-green" />;
        } else if (
          paymentFailed(statusVal) ||
          (statusVal === 'pending' && contract.status === 'cancelled')
        ) {
          return <Icon.Close className="text-red" />;
        } else if (
          isOverdue(data) &&
          contract.billingMethod?.paymentMethod.managed
        ) {
          return <Icon.Flag className="text-orange" />;
        }
        return null;
      },
    }),

    getIconCell(null, {
      key: 'invoiceId',
      renderCell: (data: BillingSchedule) => {
        if (data.externalInvoiced) {
          return (
            <ToolTip title="Invoiced outside of Cacheflow">
              <NavigationIcon.Agreements className="text-slate-lighter" />
            </ToolTip>
          );
        }
        return (
          data.invoiceId && (
            <Link
              className="text-blue underline"
              to={getDetailLink('invoice', data.invoiceId)!}
            >
              <NavigationIcon.Agreements />
            </Link>
          )
        );
      },
    }),
    {
      key: 'scheduleDate',
      headerLabel: 'Schedule date',
      sortable: true,
      sortValue: (data) => data.scheduleDate,
      renderCell: (data: BillingSchedule) => {
        return (
          <div className="flex items-center">
            {formatDateOrDefault(data.scheduleDate)}
            <GracePeriodTooltip
              contract={contract}
              scheduleDate={data.scheduleDate}
            />
          </div>
        );
      },
    },
    ...pushIf<SimpleCell<BillingSchedule>>(!!requiresInvoiceIssueDateColumn, [
      {
        key: 'invoiceIssueDate',
        headerLabel: 'Invoice issue date',
        sortable: true,
        sortValue: (data) => data.invoiceDate,
        renderCell: (data) =>
          !data.externalInvoiced && formatDateOrDefault(data.issueDate),
      },
    ]),
    ...pushIf<SimpleCell<BillingSchedule>>(
      !!contract.invoiceConfiguration?.invoiceInAdvance,
      [
        {
          key: 'invoiceDate',
          headerLabel: 'Invoice date',
          sortable: true,
          sortValue: (data) => data.invoiceDate,
          renderCell: (data) =>
            !data.externalInvoiced && formatDateOrDefault(data.invoiceDate),
        },
      ]
    ),
    {
      key: 'dueDate',
      headerLabel: 'Due date',
      sortable: true,
      sortValue: (data: BillingSchedule) => data.dueDate,
      renderCell: (data: BillingSchedule) =>
        // processDate can be undefined when billingMethod.status is external */
        // TODO revisit when we do net terms
        !data.externalInvoiced && formatDateOrDefault(data.dueDate),
    },
    ...pushIf<Cell<BillingSchedule>>(isUsageBasedContract(contract), [
      {
        key: 'fixed',
        headerLabel: 'Fixed',
        renderCell: (data) =>
          formatCurrencyOrDefault(
            contract.currency,
            fixedTotal(data, products)
          ),
      },
      {
        key: 'usage',
        headerLabel: 'Usage',
        renderCell: (data) => {
          return (
            <div className="flex items-center">
              <div>
                {formatCurrencyOrDefault(
                  data.currency,
                  usageTotal(data, products)
                )}
              </div>
              {canUpdateSub && data.usageEntryActive && editable && (
                <LinkButton
                  className="ml-2"
                  onClick={() => setSelectedBillingSchedule(data)}
                >
                  Update usage
                </LinkButton>
              )}
            </div>
          );
        },
      },
    ]),
    {
      key: 'amount',
      headerLabel: taxyTurby ? 'Subtotal' : 'Amount',
      renderCell: (data) =>
        formatNullableFormattedAmount(data.amountWithoutTaxFormatted),
    },
    ...pushIf<Cell<BillingSchedule>>(taxyTurby, [
      {
        key: 'taxAmount',
        headerLabel: 'Tax',
        renderCell: (data: BillingSchedule) =>
          formatNullableFormattedAmount(data.taxAmountFormatted),
      },
      {
        key: 'totalAmount',
        headerLabel: 'Total amount',
        renderCell: (data: BillingSchedule) =>
          formatNullableFormattedAmount(data.amountDueFormatted),
      },
    ]),
    {
      key: 'amountPaid',
      headerLabel: 'Paid',
      renderCell: (data: BillingSchedule) => {
        if (!data.transfer?.paidAmount) {
          return null;
        }
        return data.transfer.paidAmountFormatted;
      },
    },
    {
      key: 'paymentNote',
      headerLabel: 'Payment note',
      allowWrap: true,
      width: '12%',
      renderCell: (data: BillingSchedule) => {
        const statusVal = getPaymentStatusOnlyValue(data, contract) || '';
        const usageTotalValue = usageTotal(data, products);
        return (
          <div className="flex flex-row">
            <PaymentInfo
              contract={contract}
              reference={data}
              transfer={data.transfer}
              usageAmount={usageTotalValue}
            />
            {(isOverdue(data) || !isPosted(statusVal)) && alertToolTip(data.id)}
          </div>
        );
      },
    },
    ...pushIf(quickbooks, {
      key: 'syncStatus',
      width: '13%',
      headerLabel: 'Sync status',
      renderCell: (data: BillingSchedule) => (
        <div className="flex justify-start w-full">
          {data.sources?.map((s) => (
            <ExternalLink
              externalSource={s}
              key={s.sourceId}
              lastSyncEvent={data.lastSyncEvent}
              customDisplayText={getTextWithEllipsis(s.sourceId || '', 20)}
            />
          ))}
        </div>
      ),
    } as SimpleCell<BillingSchedule>),
    ...pushIf(canUpdate, {
      key: 'options',
      headerLabel: 'Options',
      width: 64,
      clickable: true,
      renderCell: (data: BillingSchedule) => (
        <div className="flex gap-2 items-center" data-testid="options-button">
          {getPopoutMenuOptions(data)}
        </div>
      ),
    }),
  ];

  const paymentInfoData: PaymentItemProps[] = [
    {
      id: uuidv4(),
      label: 'Payment method',
      value: getPaymentMethodDisplay(contract),
      type: 'button',
      isDisabled: contract.status !== 'active',
      onClick: () => setEditingPaymentMethod(true),
    },
    ...(!billingScheduleRevamp
      ? [
          {
            id: uuidv4(),
            label: 'Collected amount',
            value: formatCurrencyOrDefault(contract.currency, collectedAmount),
          },
        ]
      : []),
    {
      id: uuidv4(),
      label: 'Payment method status',
      value: getStatus(),
    },
    ...(contract.paymentTerm
      ? [
          {
            id: uuidv4(),
            label: 'Payment terms',
            value: tk(
              `term_${String(contract.paymentTerm)}_name`,
              String(contract.paymentTerm)
            ),
          },
        ]
      : []),
    ...(contractHasInvoiceInAdvanceEnabled(contract)
      ? [
          {
            id: uuidv4(),
            label: 'Invoice in advance days',
            value: contract.invoiceConfiguration?.invoiceInAdvanceDays,
          },
          {
            id: uuidv4(),
            label: 'Invoice date',
            value: tk(
              `payment_invoice_in_advance_value.${contract.invoiceConfiguration?.invoiceInAdvanceDate}`,
              ''
            ),
          },
        ]
      : []),
  ];

  const snapshotData: PaymentItemProps[] = [
    {
      id: uuidv4(),
      label: 'Total contract value',
      value: contract.totalAmountFormatted,
    },
    {
      id: uuidv4(),
      label: 'Unbilled amount',
      value: formatCurrencyOrDefault('USD', unbilledAmount),
    },
    ...(billingScheduleRevamp
      ? [
          {
            id: uuidv4(),
            label: 'Collected amount',
            value: formatCurrencyOrDefault(contract.currency, collectedAmount),
          },
        ]
      : []),
  ];
  return (
    <>
      <ConfirmModal
        cancelText="Cancel"
        confirmText="Yes, generate invoice"
        isOpen={isUsageModalOpen}
        onClose={() => setIsUsageModalOpen(false)}
        onConfirm={() => {
          generateInvoice(selectedScheduleId!);
          setIsUsageModalOpen(false);
        }}
      >
        This billing schedule line includes a usage-based product. Please
        confirm that all usage for this billing period has been added before
        generating an invoice.
      </ConfirmModal>

      <PaymentInfoSection
        paymentInfoData={paymentInfoData}
        snapshotData={billingScheduleRevamp ? snapshotData : undefined}
      />

      <PaymentForm
        billingSchedule={billingScheduleEditing}
        detailLink={detailLink!}
        isOpen={!!billingScheduleEditing}
        onCloseForm={() => {
          setBillingScheduleEditing(undefined);
        }}
        transfer={billingScheduleEditing?.transfer}
      />
      <BillingScheduleForm
        billingSchedule={selectedBillingSchedule}
        contract={contract}
        isOpen={!!selectedBillingSchedule}
        onClose={() => setSelectedBillingSchedule(undefined)}
        onSaveSuccess={() => {
          setSelectedBillingSchedule(undefined);
        }}
      />
      <PaymentUpdateForm
        isOpen={!!selectedPayment}
        onClose={() => setSelectedPayment(undefined)}
        selectedPayment={selectedPayment}
      />
      <PaymentDetails
        onClose={() => setPaymentDetailsToShow(undefined)}
        schedule={paymentDetailsToShow}
      />
      {isSuperAdmin && (
        <PaymentMethodForm
          contract={contract}
          isOpen={editingPaymentMethod}
          onCloseForm={() => setEditingPaymentMethod(false)}
        />
      )}

      {billingScheduleRevamp && (
        <div className="w-2/12">
          <Select
            onChange={(value) => {
              setBillingScheduleDisplayType(value);
            }}
            options={[
              {
                value: BillingScheduleDisplayType.viewBillingScheduleByItem,
                name: 'View billing schedule by item',
              },
              {
                value: BillingScheduleDisplayType.viewPaymentSchedule,
                name: 'View payment schedule',
              },
            ]}
            placeholder="Select billing schedule display type"
            value={billingScheduleDisplayType}
          />
        </div>
      )}
      {billingScheduleRevamp &&
      billingScheduleDisplayType ===
        BillingScheduleDisplayType.viewBillingScheduleByItem ? (
        <PaymentItemAccordion
          contract={contract}
          popoutMenuOptions={getPopoutMenuOptions}
          scheduleAndBillingItems={scheduleAndBillingItems}
        />
      ) : (
        <SimpleTable<BillingSchedule>
          cells={cells}
          disableSearch
          isLoading={isLoadingBillingSchedules || isPending}
          minWidth="900px"
          rows={billingSchedules}
          zeroStateMessage="There are no billing records to display."
        />
      )}
      {transactionDialogState && (
        <TransactionDialog
          detailLink={detailLink || 'na'}
          isOpen={transactionDialogState}
          setOpenState={setTransactionDialogState}
          transactionId={transactionId}
        />
      )}
    </>
  );
};

export default PaymentList;
