import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  AutoRenewalConfig,
  Card,
  CardHeader,
  floatValidator,
  FormField,
  Input,
  Loading,
  NumberInput,
  positiveIntValidator,
  ToggleSwitch,
  useDebounce,
  useFormValidation,
  useToast,
} from 'common';

import {
  useOrgDefaults,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';

import { TokenTextArea } from 'app/src/components';
import { AutoRenewalNotifications } from 'app/src/components/Notifications/AutoRenewalNotifications';

import styles from './AutoRenewals.module.scss';

const AutoRenewals: React.FC = () => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const initialState: AutoRenewalConfig = {
    termLengthMonths: 0,
    cancellationDeadlineDays: 0,
    enabled: false,
    notificationConfigurations: [],
    renewalClause: '',
    renewalClauseDescription: '',
    priceIncrease: 0,
  };

  const { data: orgDefaults, isLoading: isLoadingData } = useOrgDefaults();
  const [formState, setFormState] = useState<AutoRenewalConfig>(initialState);
  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    if (orgDefaults && orgDefaults.has('autoRenewalConfig')) {
      setFormState(
        orgDefaults.get('autoRenewalConfig')!.configValue as AutoRenewalConfig
      );
    }
  }, [orgDefaults]);

  const { mutate: mutateAutoRenewal, isPending: isSaving } =
    useSetOrgConfigDefault(
      'autoRenewalConfig',
      handleSuccess,
      handleError,
      queryClient
    );

  const { debouncedFunction: debouncedMutateAutoRenewal } = useDebounce(
    (newState) => {
      mutateAutoRenewal({
        configKey: 'autoRenewalConfig',
        configValue: {
          ...newState,
        },
      });
    },
    1000
  );

  const { getErrorToShow, isFormValid } = useFormValidation<AutoRenewalConfig>(
    [
      {
        fieldName: 'termLengthMonths',
        isRequired: true,
        humanReadableName: 'Term length',
        validator: positiveIntValidator,
      },
      {
        fieldName: 'cancellationDeadlineDays',
        isRequired: true,
        humanReadableName: 'Cancellation deadline',
        validator: positiveIntValidator,
      },
      {
        fieldName: 'priceIncrease',
        isRequired: false,
        humanReadableName: 'Automatic price increase',
        validator: floatValidator,
      },
    ],
    formState
  );

  useEffect(() => {
    if (changed && isFormValid) {
      setChanged(false);
      debouncedMutateAutoRenewal(formState);
    }
  }, [changed, formState]);

  function handleSuccess() {
    showToast.success('Auto-renewal settings saved');
  }

  function handleError() {
    showToast.error('Failed to update auto-renewal settings');
  }

  const handleChange = (
    field: string,
    value: boolean | number | string | null
  ) => {
    const newState = {
      ...formState,
      [field]: value,
    };

    setFormState(newState);
    setChanged(true);
  };

  const tokensWithValues = {
    /* eslint-disable camelcase */
    renewal_term_length: formState.termLengthMonths,
    renewal_cancellation_deadline: formState.cancellationDeadlineDays,
    renewal_price_increase: formState.priceIncrease,
    /* eslint-enable camelcase */
  };

  if (isLoadingData) {
    return <Loading />;
  }

  if (!formState.enabled) {
    return (
      <Card>
        <CardHeader name="Auto-renewal default settings">
          <ToggleSwitch
            id="enabled"
            label="Not available"
            name="enabled"
            onChange={(v) => handleChange('enabled', v)}
            value={formState.enabled}
          />
        </CardHeader>
        <p>
          Auto-renewals for your organization are currently deactivated. When
          you enable auto-renewals, proposals can include terms that enable
          subscriptions to renew automatically upon their expiration. The
          auto-renewal process is managed entirely by Cacheflow.
        </p>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader name="Auto-renewal default settings" showSpinner={isSaving}>
        <ToggleSwitch
          id="enabled"
          label="Available"
          name="enabled"
          onChange={(v) => handleChange('enabled', v)}
          value={formState.enabled}
        />
      </CardHeader>
      <div className={styles.autoRenewals}>
        <h2 className={styles.heading}>Conditions</h2>
        <div className={styles.inputRow}>
          <FormField
            errorToShow={getErrorToShow('termLengthMonths')}
            infoText="Renewal term length is the default term length applied to auto-renewals. This value can be updated on the proposal editor."
            label="Renewal term length"
          >
            <Input
              id="termLengthMonths"
              onChange={(v) => handleChange('termLengthMonths', v)}
              suffix="months"
              value={formState.termLengthMonths?.toString()}
            />
          </FormField>

          <FormField
            errorToShow={getErrorToShow('cancellationDeadlineDays')}
            label="Cancellation deadline"
          >
            <Input
              id="cancellationDeadlineDays"
              onChange={(v) => handleChange('cancellationDeadlineDays', v)}
              suffix="days"
              value={formState.cancellationDeadlineDays?.toString()}
            />
          </FormField>

          <FormField
            errorToShow={getErrorToShow('priceIncrease')}
            label="Automatic price increase"
          >
            <NumberInput
              id="priceIncrease"
              onChange={(v) => {
                handleChange('priceIncrease', v ?? 0);
              }}
              scale={2}
              suffix="%"
              value={formState.priceIncrease ?? 0}
            />
          </FormField>
        </div>
        <h2 className={styles.heading}>Clause</h2>
        <TokenTextArea
          id="renewalClause"
          onChange={handleChange}
          placeholder="Add auto-renewal terms. If the terms are left empty then the auto renewal section will not be shown to the buyer in proposals where auto-renewal is enabled. Ensure that the terms of your auto-renewal are explained in the MSA or additional terms section on your proposal."
          tokensWithValues={tokensWithValues}
          value={formState.renewalClause}
        />

        <h2 className={styles.heading}>Notifications</h2>
        <AutoRenewalNotifications
          data={formState}
          mutateAutoRenewal={mutateAutoRenewal}
        />
      </div>
    </Card>
  );
};

export default AutoRenewals;
