import React, { useEffect, useRef, useState } from 'react';
import { ErrorFetching, Loading, useToast, useTranslation } from 'common';
import { useQueryClient } from '@tanstack/react-query';
import {
  getSlackUserAuthRedirectUri,
  useDeleteCurrentSlackUser,
  useFindCurrentSlackUser,
  useGetSlackAppInfo,
} from '../../services/slack';
import { useCreateStateToken } from '../../services/state-tokens';
import NotificationOption from './NotificationOption';

interface Props {
  hasAddedAppToWorkspace: boolean;
}

const ApprovalNotifications: React.FC<Props> = ({ hasAddedAppToWorkspace }) => {
  const showToast = useToast();
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const [stateTokenKey, setStateTokenKey] = useState<string>();
  const [isCreatingSlackToken, setIsCreatingSlackToken] =
    useState<boolean>(true);
  const isOnMountCalled = useRef(false);

  const {
    data: currentSlackUser,
    isLoading: isLoadingSlackUser,
    isError: isErrorSlackUser,
  } = useFindCurrentSlackUser();

  const {
    data: appInfo,
    isLoading: isLoadingAppInfo,
    isError: isErrorAppInfo,
  } = useGetSlackAppInfo();

  const { mutate: createSlackToken, isError: isErrorCreatingSlackToken } =
    useCreateStateToken(
      (token: string) => {
        setStateTokenKey(token);
        setIsCreatingSlackToken(false);
      },
      () => {
        setIsCreatingSlackToken(false);
      },
      queryClient
    );

  const { mutate: deleteSlackUser, isPending: isDeleting } =
    useDeleteCurrentSlackUser(
      () => {
        showToast.success(tk('Successfully saved setting.'));
      },
      () => {
        showToast.error(tk('Failed to save setting. Please try again later.'));
      },
      queryClient
    );

  useEffect(() => {
    if (isOnMountCalled.current) return;
    isOnMountCalled.current = true;

    createSlackToken();
  }, []);

  if (isErrorSlackUser || isErrorAppInfo || isErrorCreatingSlackToken) {
    return <ErrorFetching />;
  }

  if (
    isLoadingSlackUser ||
    isLoadingAppInfo ||
    isCreatingSlackToken ||
    appInfo === undefined ||
    stateTokenKey === undefined
  ) {
    return <Loading />;
  }

  const userScopes = ['openid'];
  const slackAuthUserUrl = `
	https://slack.com/openid/connect/authorize?
	scope=${userScopes.join(' ')}
	&client_id=${appInfo.clientId}
	&team=${appInfo.teamId}
	&state=${stateTokenKey}
	&redirect_uri=${getSlackUserAuthRedirectUri()}
	&response_type=code`;

  const handleToggle = (isChecked: boolean) => {
    if (isChecked) {
      window.location.href = slackAuthUserUrl;
    } else {
      deleteSlackUser();
    }
  };

  return (
    <NotificationOption
      name={tk('Approvals')}
      description={tk(
        'Be notified about proposal approval requests if you are in the approvals group, and when your own approval requests are accepted or rejected.'
      )}
      isEnabled={!!currentSlackUser}
      onChange={handleToggle}
      isDisabled={isDeleting || !hasAddedAppToWorkspace || !appInfo}
    />
  );
};

export default ApprovalNotifications;
